import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.dashboards.detector-configuration-suggestions")
        .controller("detectorConfigSuggestions", detectorConfigSuggestions);

    function detectorConfigSuggestions($state, $rootScope, $scope, $stateParams, $interval, $mdSidenav, environmentConfig, searchBarService, signalService, userSettingsService) {
        var vm = this;
        //init variables
        vm.isFiltering = false;
        vm.options = null;
        vm.timeOptions = null;
        vm.loading = true;
        vm.totalQueryCount = 0;
        vm.suggestionsPromise = {};
        vm.arrayToShow = [];
        vm.tableContent = [];
        vm.sortTable = sortTable;
        vm.paginateTable = paginateTable;
        vm.searchBarService = searchBarService;
        // vm.purdueLinkPivotService = purdueLinkPivotService;
        //set functions
        vm.fetchSuggestions = fetchSuggestions;
        vm.getOptions = getOptions;
        vm.setDataTableOptions = setDataTableOptions;
        vm.setupSearchBar = setupSearchBar;
        vm.queryData = {
            orderBy: 'detector',
            pageSize: 10,
            pageIndex: 1,
        };
        vm.setupSearchBar(); 

        //setup data table and call server to get options
        vm.setDataTableOptions();
        function setupSearchBar() {
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                    case "signal":
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.configured = 0;
                            vm.notConfigured = 0;
                            vm.consistentCount = 0;
                            vm.inconsistentCount = 0;
                            vm.tableContent = [];
                            vm.arrayToShow = [];
                            vm.getOptions();
                        }
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                    case "state":
                        if (searchBarService.isFiltering() == false)
                            vm.isFiltering = false;
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Detector Configuration "
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Signals',
                showCurrentFilterDates: true,
                helpJsonPropertyPath: "METRIC." + 93,
                metricTypeId: 93,
                showExcel: true,
                metricTypeId: userSettingsService.dashboardTypeIds.DetectorConfigSuggestions,      
                dashboardType: "Signal",                  
                showSignalNotificationTabs: true,
                showHelp: true,
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TD",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    showWeekdayFilter: false,
                    enableAdvancedTod: false,
                    maxDayRange: 1
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: false,
                    showBinConfig: false,
                    skipStepsPerBin: true,
                    help: true,
                    getMetricData: true,
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function paginateTable (e) {
            var start = vm.queryData.pageSize * (e - 1);
            var end = vm.queryData.pageSize * (e - 1) + vm.queryData.pageSize;
            vm.arrayToShow = vm.tableContent.slice(start, end);
        }

        function dynamicSort(property) {
            if (!property) return;
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }
        function sortTable (property) {
            vm.tableContent.sort(dynamicSort(property))
            vm.paginateTable(vm.queryData.pageIndex)
        }

        function fetchSuggestions() {
            if (!vm.queryData) return;
            $rootScope.loadingProgress = true;
            vm.loading = true;
            var options = vm.searchBarService.getSearchOptions();
            var endDate = options.timeOptions.currentFilter.endDateAndTime;
            var query = {
                SignalID: options.signal.signalID,
                Date: moment(endDate).format('Y') + '-' + moment(endDate).format('M') + '-' + moment(endDate).format('D'),
                NumberOfDays: 7,
            }
            vm.suggestionsPromise = signalService.getAutomatedConfigResults(query).then(function (resp) {
                if (!resp.phasingResults) {
                    vm.loading = false;
                    return;
                };
                vm.tableContent = resp.phasingResults;
                vm.tableContent.sort(function(a, b) {
                    return a.detector - b.detector;
                });
                vm.configured = 0;
                vm.notConfigured = 0;
                vm.consistentCount = 0;
                vm.inconsistentCount = 0;
                vm.tableContent.forEach(function (row) {
                    if (row.detectorType) row.detectorType = row.detectorType.charAt(0).toUpperCase() + row.detectorType.slice(1);


                    row.subIssues = [];

                    if (row.configurationIssues.includes(1)) {
                        row.phaseColor = 'rgb(208, 2, 27)';
                        if (row.conflict1 && row.conflict1.length > 0) {
                            row.subIssues.push(`Appears to be phase ${row.conflict1[0]} or ${row.conflict1[1]}`);
                        } else {
                            row.subIssues.push(`Appears to be phase ${row.sanityCheck}`);
                        }
                    }
                    if (row.configurationIssues.includes(2)) {
                        row.typeColor = 'rgb(208, 2, 27)';
                        row.subIssues.push(`Appears to be ${row.detectorType} detector`);
                    }
                    if (row.configurationIssues.includes(3)) {
                        row.distanceColor = 'rgb(208, 2, 27)';
                        row.subIssues.push(`Appears to be ${row.distance} ft`);
                    }

                    if (row.duplicateOf) {
                        row.subIssues.push(`Appears to be duplicate of detector ${row.duplicateOf}`)
                    }
                    if (row.sanityCheck == 0) {
                        if (row.conflict1 && row.conflict1.length > 0) {
                            row.sanityCheck = row.conflict1[0] + ' or ' + row.conflict1[1];
                        } else {
                            row.sanityCheck = '-';
                        }
                    }
                    switch (row.configurationStatus) {
                        case 0:
                            vm.notConfigured += 1;
                            row.configStatus = "Not Configured";
                            row.iconName = "icon-close-circle";
                            row.iconColor = "#000";
                            break;
                        case 1:
                            vm.configured += 1;
                            vm.inconsistentCount += 1;
                            row.configStatus = "Inconsistent";
                            row.iconName = "icon-alert";
                            row.iconColor = "#D0021B";
                            break;
                        case 2:
                            vm.configured += 1;
                            vm.consistentCount += 1;
                            row.configStatus = "Consistent";
                            row.iconName = "icon-checkbox-marked-circle";
                            row.iconColor = "#4CAF50";
                            break;
                        case 3:
                            vm.configured += 1;
                            row.configStatus = "Duplicated Detector";
                            row.iconName = "icon-alert";
                            row.iconColor = "#F5A623";
                            break;
                        case 4:
                            vm.configured += 1;
                            row.configStatus = "Not able to make suggestion";
                            row.iconName = "icon-no";
                            row.iconColor = "#F5A623";
                            break;
                    }

                });
                vm.paginateTable(1)
                $rootScope.loadingProgress = false;
                vm.loading = false;
                vm.totalCount = resp.phasingResults.length;
            }).$promise
        }

        function getOptions() {

            vm.queryData = {
                orderBy: '',
                pageSize: 10,
                pageIndex: 1,
            };
            vm.fetchSuggestions();
        };

        function setDataTableOptions() {
            vm.totalCount = {};
            vm.options = {
                rowSelection: false,
                multiSelect: false,
                autoSelect: false,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: false,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };

        }
    }
}());