(function ()
{
    'use strict';
    angular
        .module('app.spm.dashboards.signal-performance')
        .controller('DashboardSignalPerformanceController', DashboardSignalPerformanceController);

    /** @ngInject */
    function DashboardSignalPerformanceController($scope, $state, searchBarService, signalService, signalPerformanceService, userSettingsService)
    {
        var vm = this;
        vm.searchBarService = searchBarService;
        vm.timeOptions = {};
        vm.signal = {};
        vm.setSearchOptions = setSearchOptions;
        vm.updateCards = updateCards;
        vm.setupSearchBar = setupSearchBar;
        vm.onCardApiInit = onCardApiInit;
        vm.getData = getData;
        vm.chartServerError = false;
        vm.areAllCardsInitialized = areAllCardsInitialized;

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })

        vm.performanceCards = [
            {
                metricType: signalPerformanceService.metricTypesEnum.Volume,
                aggregationName: signalPerformanceService.aggregationNames.Volumes,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.TotalDelay,
                aggregationName: signalPerformanceService.aggregationNames.VehicleDelay,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData: [],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function () {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.DelayPerVehicle,
                aggregationName: signalPerformanceService.aggregationNames.VehicleDelay,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.ArrivalsOnGreen,
                aggregationName: signalPerformanceService.aggregationNames.Pcd,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },           
            {
                metricType: signalPerformanceService.metricTypesEnum.SplitFails,
                aggregationName: signalPerformanceService.aggregationNames.SplitFails,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.PlatoonRatio,
                aggregationName: signalPerformanceService.aggregationNames.Pcd,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.PedCount,
                aggregationName: signalPerformanceService.aggregationNames.Peds,
                chartType: 'line',
                data: {
                    signalData: [],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.PedDelay,
                aggregationName: signalPerformanceService.aggregationNames.Peds,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.QueueLength,
                aggregationName: signalPerformanceService.aggregationNames.Queue,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.QueueSpillback,
                aggregationName: signalPerformanceService.aggregationNames.Queue,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
        ];

        vm.setupSearchBar();
        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return; 

                switch (changeType) {
                    case "time":
                    case "signal":
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.chartServerError = false;
                            vm.setSearchOptions();
                            vm.updateCards();
                        }
                        break;
                    case "state":
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Signal Performance Dashboard"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Signals',
                showCurrentFilterDates: true,
                helpJsonPropertyPath: "PERFORMANCE_METRIC_CARD." + "GENERAL_HELP",
                showHelp: true,
                showExcel: true,
                showSignalNotificationTabs: true,
                metricTypeId: userSettingsService.dashboardTypeIds.SignalPerformance,
                dashboardType: "Signal",                   
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TW",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "TimePeriod",
                    showWeekdayFilter: true,                   
                    maxDayRange: 31
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: true,
                    skipStepsPerBin: false,
                    notifyOnBinChange: true,
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function onCardApiInit(apiObj) {
            if (apiObj && apiObj.update)
                vm.updateCards();
        }

        function areAllCardsInitialized(){
            let res = true;
            for(let i = 0; i < vm.performanceCards.length; i++){
                let api = vm.performanceCards[i].api;
                if (!api || !api.update){
                    res = false;
                }
            }
            return res;
        }

        function updateCards() {
            if (vm.areAllCardsInitialized())
                vm.getData(vm.timeOptions);
        }

         function getData(query) {
            if (!vm.timeOptions || !vm.signal) {
                return;
            }
   
            //setup default parameters
            var daysOfWeek = [" "];
            var startHour = 0, startMinute = 0, endMinute = 0, endHour = 0;
            var start = new Date(query.currentFilter.startDateAndTime);
            var end = new Date(query.currentFilter.endDateAndTime);

            var aggregationTypesToQuery = [...new Set(vm.performanceCards.map(x => x.aggregationName))];
       
            if (vm.timeOptions.timeOption == "TimePeriod" || vm.timeOptions.currentFilter.selectedTodTemplate == "CT") {
                startHour = start.getHours();
                startMinute = start.getMinutes();
                endHour = end.getHours();
                endMinute = end.getMinutes();
                vm.timeOptions.timeOption = "TimePeriod";
                vm.searchBarService.setTimeOptionsNoNotify(vm.timeOptions);
            }
            //set the day of week list
            if (vm.timeOptions.daysOfWeekList) {
                daysOfWeek = vm.timeOptions.daysOfWeekList;
            }
            vm.loading = true;
            var opts = signalPerformanceService.createOptionsObject(vm.signal.signalID, aggregationTypesToQuery, start.toLocaleString(), end.toLocaleString(), startHour, startMinute, endHour, endMinute, daysOfWeek, vm.timeOptions.timeOption, vm.timeOptions.bin);
            signalPerformanceService.getMetricForSignal(opts)
                .then(function (res) {
                    vm.chartServerError = false;
                    if (!res || !res.signalData){
                        vm.chartServerError = true;
                        return;
                    }

                    let fullDataSet = res.signalData;
                    //go through each card in array and match with data from server
                    //do any pre-processing of the data that is needed, then pass into each card
                    //for display
                    for(let i = 0; i <  vm.performanceCards.length; i++ ){
                        let thisCard = vm.performanceCards[i];
                        let cardDataSet = fullDataSet[thisCard.aggregationName];
                        if (!cardDataSet) continue;


                        thisCard.data.detectionTypeId = cardDataSet.data.detectionTypeId;
                        thisCard.data.hasNeededDetection = cardDataSet.data.hasNeededDetection;
                        thisCard.data.signalData = cardDataSet.data.binsContainer;
                        thisCard.data.approachData = cardDataSet.approaches;
                        if (thisCard.api && thisCard.api.update)
                            thisCard.api.update(thisCard.data, vm.signal);
                    }
                    vm.loading = false;
                })
                .catch(function (error){
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
            });  
        }


        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.signal = {
                signalID: options.signal.signalID,
                description: options.signal.description,
            };
            vm.timeOptions = options.timeOptions;
        };

    }

})();