﻿import * as SIGNALCHART from 'raw-loader!./view/charts-signal.html';
import * as DETECTORCOUNTSCHART from 'raw-loader!./view/detector-counts-chart.html';
import * as DETECTORDURATIONSCHART from 'raw-loader!./view/detector-durations-chart.html';
import * as DETECTORCONFIGSUGGESTIONS from 'raw-loader!./../../dashboards/detector-config-suggestions/detector-config-suggestions.html'

(function ()
{
    angular
            .module('app.spm.charts.signal', [])
            .config(config);

    /** @ngInject */
    function config($stateProvider) {
        $stateProvider
            .state('app.spm.charts.phase-termination', {
                url: '/phase-terminations',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 1,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.split-monitor', {
                url: '/split-monitor',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 2,
                    stackSplitMonitor: true,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.purdue-split-monitor', {
                url: '/purdue-split-monitor',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 2,
                    stackSplitMonitor: false,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.ped-delay', {
                url: '/ped-delay',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 3,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.preempt-details', {
                url: '/preempt-details',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 4,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.turning-movements', {
                url: '/turning-movement-counts',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 5,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.purdue-coord-diagram', {
                url: '/purdue-coord-diagram',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 6,
                    stackPcd: true,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.red-light-runners', {
                url: '/red-light-runners',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 11,
                    stackPcd: true,
                    inputParameters: undefined
                },
            })
            // .state('app.spm.charts.purdue-coord-diagram-canvas', {
            //     url: '/purdue-coord-diagram-canvas',
            //     views: {
            //         'content@app': {
            //             template: SIGNALCHART,
            //             controller: 'chartsSignalController as vm'
            //         }
            //     },
            //     params: {
            //         metricTypeId: 66
            //     },
            // })
            .state('app.spm.charts.approach-volume', {
                url: '/approach-volume',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 7,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.approach-delay', {
                url: '/approach-delay',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 8,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.aor', {
                url: '/arrivals-on-red',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 9,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.approach-speed', {
                url: '/approach-speed',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 10,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.split-failure', {
                url: '/split-failure',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 12,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.link-pivot', {
                url: '/link-pivot',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 13,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.preempt-request', {
                url: '/preempt-request',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 14,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.preempt-service', {
                url: '/preempt-service',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 15,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.queue-length', {
                url: '/queue-length',
                views: {
                    'content@app': {
                        template: SIGNALCHART,
                        controller: 'chartsSignalController as vm'
                    }
                },
                params: {
                    metricTypeId: 20,
                    inputParameters: undefined
                },
            })
            .state('app.spm.charts.detector-counts', {
                url: '/detector-counts',
                views: {
                    'content@app': {
                        template: DETECTORCOUNTSCHART,
                        controller: 'detectorCountsController as vm'
                    }
                },
                params: {inputParameters: undefined}
            })
            .state('app.spm.charts.detector-durations', {
                url: '/detector-durations',
                views: {
                    'content@app': {
                        template: DETECTORDURATIONSCHART,
                        controller: 'detectorDurationsController as vm'
                    }
                },
                params: {inputParameters: undefined}
            })
            .state('app.spm.charts.detector-config-suggestions', {
                url: '/detector-config-suggestions',
                views: {
                    'content@app': {
                        template: DETECTORCONFIGSUGGESTIONS,
                        controller: 'detectorConfigSuggestions as vm'
                    }
                },
                params: {inputParameters: undefined}
            })
    }
}());
