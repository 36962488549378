import * as SIGNALTRENDS from 'raw-loader!./view/signal-trends.html';

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.signal-trends',
           []
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.signal-trends', {
                url: '/signal-trends',
                views: {
                    'content@app': {
                        template: SIGNALTRENDS,
                        controller: 'DashboardSignalTrendsController as vm'
                    }
                }
            });
        $translatePartialLoaderProvider.addPart('trends');
    }
})();