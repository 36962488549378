(function () {

    'use strict';

    angular
        .module('app.spm.core')
        .service('telemetryService', telemetryService);

    function telemetryService($q, segment, searchBarService) {

        function page(prevPage, nextPage, userID, userEmail, userName) {
            segment.page('page', {
                page: nextPage,
                referrer: prevPage.includes('access_token') ? 'Login Page' :  prevPage,
                user_email: userEmail,
                user_name: userName,
            });
        }

        function insightsDashboardTelemetry(action, action_type, extraProps) {
            if (!sessionStorage.getItem('segmentData')) return;
            var date_selection = searchBarService.getSearchOptions();
            var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));

            if (!currentSession.component) return;

            var insightCat = (extraProps && extraProps.insight_category) ? extraProps.insight_category : Number(currentSession.insight_category);
            var insightCatString = undefined;
            switch (insightCat) {
                case 3:
                    insightCatString = "Data Insights"
                    break;
                case 1:
                    insightCatString = "Maintenance Insights"
                    break;
                case 4:
                    insightCatString = "Configuration Insights"
                    break;
                case 5:
                    insightCatString = "Operation Insights"
                    break;
            }

            if (extraProps && extraProps.insight_category) {
                extraProps.insight_category = insightCatString;
            } else {
                currentSession.insight_category = insightCatString;
            }

            segment.track(currentSession.component, Object.assign({
                "org_name": sessionStorage.userName,
                "action": action,
                "action_type": action_type,
                "date_selection": {
                    "datetime_range": {
                        "timezone": JSON.parse(sessionStorage.getItem('userSettings')).agencyTimeZone.Id,
                        "utc_offset": JSON.parse(sessionStorage.getItem('userSettings')).agencyTimeZone.BaseUtcOffset,
                        "start_datetime": date_selection.timeOptions.currentFilter.startDateAndTime,
                        "end_datetime": date_selection.timeOptions.currentFilter.endDateAndTime
                    }
                }
            }, currentSession, extraProps));
        }

        function insightsComponentTelemetry(action, action_type, insight_category, signal, date_selection, card, ackItem, showAckParams) {
            if (!sessionStorage.getItem('segmentData')) return;

            var insightStateFilters = ["new", "acknowledged", "ignored", "all"];
            var insightPriorityFilters = ["all", "low", "high"];
            var categoryName = "";
            switch (Number(insight_category)) {
                case 3:
                    categoryName = "Data Insights"
                    break;
                case 1:
                    categoryName = "Maintenance Insights"
                    break;
                case 4:
                    categoryName = "Configuration Insights"
                    break;
                case 5:
                    categoryName = "Operation Insights"
                    break;
            }

            var ackActions = ["Confirmed in ATMS", "Investigated in ATMS", "Technician Dispatched", "Technician Repaired"]
            var ackReasons = [];
            if (ackItem && ackItem.currentAcknowledgment && ackItem.currentAcknowledgment.selectedAckReasons && ackItem.currentAcknowledgment.selectedAckReasons.length > 0) {
                ackItem.currentAcknowledgment.selectedAckReasons.forEach(function (ackReason) {
                    ackReasons.push(ackActions[ackReason - 1]);
                });
            }

            segment.track("insights_component", {
                "org_name": sessionStorage.userName,
                "action": action,
                "action_type": action_type,
                "insight_category": categoryName,
                "date_selection": {
                    "datetime_range": {
                        "start_datetime": date_selection.timeOptions.currentFilter.startDateAndTime,
                        "end_datetime": date_selection.timeOptions.currentFilter.endDateAndTime
                    }
                },
                "intersection_id": (signal && signal.signalID && signal.signalID != "") ? signal.signalID : undefined,
                "intersection_description": signal ? signal.signalDescription : undefined,
                "tab": card.level,
                "approach": ackItem ? ackItem.approachDescription : undefined,
                "detector": ackItem ? ackItem.detector : undefined,
                "filter_parameters": [{
                    "state": insightStateFilters[card.filteringBy.state - 1],
                    "priority": insightPriorityFilters[card.filteringBy.priority + 1]
                }],

                "number_of_occurrences": ackItem ? ackItem.occurrences.length : undefined,
                "card_name": card.title,
                "sort_order": card.orderBy[0] === '-' ? "descending" : "ascending",
                "card_page": [{
                    "current_page_number": card.pageData.pageIndex,
                    "total_pages": Math.ceil(card.list.items.length / card.pageData.size)
                }],
                "acknowledge_parameters": showAckParams ? [{
                    "ackowledge_expiration_dt": ackItem ? ackItem.currentAcknowledgment.acknowledgedExpirationTimestamp : undefined,
                    "comment": (ackItem && ackItem.currentAcknowledgment && ackItem.currentAcknowledgment.acknowledgedComment) ? ackItem.currentAcknowledgment.acknowledgedComment : undefined,
                    "other": ackReasons.length > 0 ? ackReasons : undefined
                }] : undefined
            });
        }

        return {
            page: page,
            insightsComponentTelemetry: insightsComponentTelemetry,
            insightsDashboardTelemetry: insightsDashboardTelemetry,
        }
    }
})();
