﻿(function ()
{
    angular
            .module('app.spm.charts',
            [
                'app.spm.charts.signal'
            ])
            .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.addPart('signals');

    }
}());