﻿(function () {
    'use strict';

    angular.module('app.spm.charts.signal')
    .directive('approachVolumeChart', approachVolumeChart);

    /** @ngInject */
    function approachVolumeChart($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'approachVolumeChartController as vm',
            templateUrl: function (elem, attrs) {
                 return 'assets/templates/approach-volume-widget.html';
            },
            scope: {
                loading: '=',
                fetchData:'<',
                hideButtons:'<',
                hidePlans:'<',
                hideDfactor:'<',
                useSimpleChart: '<',
                xaxeFormat: '@',
                spmChartOptions: '<',
                signal: '<',
                searchDates: '<',
                stacked: '<',
                onApiInit: '&',
                onApiDestroy: '&',
                api: '=',
            },
            link: function (scope) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        updateData: function (data) {
                            return scope.updateData(data);
                        }
                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();