(function () {
    angular
        .module('app.searchbar')
        .controller('corridorSearchController', corridorSearchController);

    function corridorSearchController($rootScope, $scope, searchBarService, corridor_list, on_corridor_set, on_close) {
        var vm = this;
        vm.options = {};
        vm.configuration = {};
        vm.searchBarService = searchBarService;
        vm.init = init;
        vm.apply = apply;
        vm.close= close;
        vm.corridorList = corridor_list;
        vm.filteredCorridorList = [];
        vm.pageData = {
            pageIndex: 1,
            size: 5,
        };

        vm.corridors = {
            selected: [],
            searchText: "",
            search: function () {
                vm.pageData.pageIndex = 1;
                vm.corridors.selected = [];
                if (!vm.corridors.searchText) {
                    vm.filteredCorridorList = vm.corridorList;
                }
                else {
                    let foundArray = [];
                    for (let i = 0; i < vm.corridorList.length; i++) {
                        if (vm.corridorList[i].description.toLowerCase().includes(vm.corridors.searchText.toLowerCase()))
                            foundArray.push(vm.corridorList[i]);
                    }
                        
                    vm.filteredCorridorList = foundArray;
                    if (vm.filteredCorridorList.length === 1){
                        vm.corridors.selected[0] = vm.filteredCorridorList[0];
                    }
                }

            },
        };

        function init() {
            vm.options = vm.searchBarService.getSearchOptions();
            vm.configuration = vm.searchBarService.getSearchBarConfig();
            vm.filteredCorridorList = vm.corridorList;
            //set selected signal
            if (vm.options.corridor.corridorID && vm.options.corridor.corridorID) {
                if (!vm.corridors.selected[0] || vm.corridors.selected[0].corridorID != vm.options.corridor.corridorID) {
                    var index = vm.corridorList.map(function (e) { return e.corridorID; }).indexOf(vm.options.corridor.corridorID);
                    vm.corridors.selected[0] = vm.corridorList[index];
                    vm.pageData.pageIndex = Math.floor((index / vm.pageData.size)+1);
                }
            }

        };

        function close(){
            if (on_close)
                on_close()
        }

        function apply() {
            if (vm.corridors.selected[0]){
                vm.searchBarService.setCorridor(vm.corridors.selected[0]);
            }
            else{
                vm.close();
                return;
            }
            if (on_corridor_set)
                on_corridor_set();
        }

        vm.init();
    }
}());
