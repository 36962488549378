(function () {
    'use strict';
    angular
        .module('app.spm.core')
        .controller('systemInsightsController', systemInsightsController);

    /** @ngInject */
    function systemInsightsController($rootScope, $scope, $stateParams, systemInsightsService, searchBarService, chartsService) {
        var vm = this;
        vm.setupSystemInsights = setupSystemInsights;
        vm.searchBarService = searchBarService;
        vm.renderChart = renderChart;
        vm.data = {};

        $scope.$on("$destroy", function () {
            sessionStorage.removeItem('segmentData');

            //remove any VM refs
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        });

        $rootScope.$on('signalStatesLoaded', function (event, args) {
            if (!vm) return;
            if (vm.signalStatesChart) vm.signalStatesChart.destroy();
            vm.data = args;
            vm.setupSystemInsights();
        });

        vm.systemInsightsWidget = {
            chartObject: {},
            donutChartConfig: {
                getChart: function (planData) {
                    var clonedChartSetup = angular.copy(vm.phaseTerminationWidget.chartSetup);
                    // clonedChartSetup.options.chart.planData = angular.copy(planData);
                    return clonedChartSetup;
                },
                isRendered: false,
            },
            processSystemData: renderChart,
        }

        vm.setSearchOptions = function () {
            var options = vm.searchBarService.getSearchOptions();

            if (options.signal) {
                vm.signal = {
                    enabled: options.signal.enabled,
                    signalID: options.signal.signalID,
                    description: options.signal.signalName,
                    latitude: options.signal.latitude,
                    longitude: options.signal.longitude,
                };
            } else {
                vm.signal = null;
            }
        }

        function renderChart() {
            var chart = [];

            var pieData = [
                {
                    fill: true,
                    backgroundColor: [vm.data.optimal.color, vm.data.subOptimal.color, vm.data.underperforming.color, vm.data.slightlyDegraded.color, vm.data.degraded.color, vm.data.offline.color],
                    data: [vm.data.optimal.count, vm.data.subOptimal.count, vm.data.underperforming.count, vm.data.slightlyDegraded.count, vm.data.degraded.count, vm.data.offline.count,],
                    borderWidth: 0
                }
            ]

            vm.legendClicked = false;

            var options = {
                elements: {
                   arc: {
                      borderWidth: 0
                   }
                },
                chartType: 'doughnut',
                customLegendClick: true,
                paddingBelowLegend: true,
                layout: {
                    padding: {
                        bottom: 30
                    }
                },
                onHover: function (event, data) {
                    //get the line datasets

                    if (!data || data.length == 0) {
                        systemInsightsService.pieChartHover('Restore State', { actionType: 'hover' });
                        return;
                    };
                    systemInsightsService.pieChartHover(data[0]._model.label, { actionType: 'hover' });
                },

                title: {
                    display: true,
                    position: 'top'
                },
                legend: {
                    reverse: true,
                    display: true,
                    labels: {
                        boxWidth: 5,
                        filter: function (item, chart) {
                            // Logic to remove a particular legend item goes here
                            return chart.datasets[0].data[item.datasetIndex] != 0
                        },
                    }
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    // callbacks: {
                    //     label: function (tooltipItems, data) {
                    //         var i = tooltipItems.index;
                    //         return data.labels[i] + " State: " + data.datasets[0].data[i] + " signals";
                    //     }
                    // } 
                },
                rotation: -0.7 * Math.PI,
                plugins: {
                    beforeInit: function (chart, options) {
                        chart.legend.afterFit = function () {
                            this.height = this.height + 300;
                        };
                    },
                    datalabels: {
                        align: 'end',
                        anchor: "end",
                        offset: 8,
                        display: function (context) {
                            let sum = 0;
                            let dataArr = context.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage = (context.dataset.data[context.dataIndex] * 100 / sum).toFixed(2);
                            return percentage >= 5; // or >= 1 or ...
                        },
                        formatter: (value, ctx) => {

                            let sum = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                            return percentage;


                        },
                        color: '#000',
                    },
                }
            };


            chart.push({
                title: "",
                // dataset: chartData,
                data: pieData,
                api: {},
                responsive: true,
                onDestroy: function () {
                    this.api = {};
                },
                onApiInit: function (apiObj) {
                    this.api = apiObj;
                    this.render(apiObj);
                },
                render: function (apiObj) {
                    apiObj.render(this.labels, this.data, this.chartOptions);
                    vm.chart =
                        vm.chartRendering = false;
                    this.isRendered = true;
                },
                isRendered: false,
                chartOptions: options,
                labels: [vm.data.optimal.title, vm.data.subOptimal.title, vm.data.underperforming.title, vm.data.slightlyDegraded.title, vm.data.degraded.title, vm.data.offline.title],
            });

            vm.systemInsightsWidget.chartArray = chart;
        }


        function setupSystemInsights() {
            vm.loading = true;
            vm.setSearchOptions();
            vm.systemInsightsWidget.processSystemData();
            vm.loading = false;
        }
    }
})();