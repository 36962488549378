﻿import * as COMPCHART from 'raw-loader!./comparison-chart.html'

(function () {
    'use strict';

    angular.module('app.spm.charts')
        .directive('comparisonChart', comparisonChartDirective);

    /** @ngInject */
    function comparisonChartDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            controller: 'comparisonChartController as vm',
            template: function (elem, attrs) {
                return COMPCHART;
            },
            scope: {
                onApiInit: '&',
                onApiDestroy: '&',
                filters: '=',
                onFilterChange: '&',
                leftChartLabels: '<',
                rightChartLabels: '<',
                showHeatMap: '<',
                overviewCard: '=',
                header: '<',
                subHeader: '<'
            },
        };
    }
})();