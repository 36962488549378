(function () {
    'use strict';
    angular
        .module('app.spm.core')
        .controller('insightsMapController', insightsMapController);

    /** @ngInject */
    function insightsMapController($rootScope, $q, $location, $stateParams, $document, breadcrumbNavigationService, $timeout, $scope, $interval, signalService, $element, systemInsightsService, insightsService, searchBarService, newWindowService) {
        var vm = this;
        vm.mapApi = {};
        vm.setDefaultLocation = setDefaultLocation;
        vm.initMap = initMap;
        vm.startMapLoad = startMapLoad;
        vm.defaultMapType = "roadmap";
        vm.isContextMenuPressed = false;
        vm.setInitialMarkers = setInitialMarkers;
        vm.searchBarService = searchBarService;
        vm.collectMapParams = collectMapParams;
        vm.setSearchOptions = setSearchOptions;
        vm.timeframeChange = timeframeChange;
        vm.drawInsights = drawInsights;
        vm.contextSignal = {};
        vm.addHoverBoxInfo = addHoverBoxInfo;
        vm.focusOnSignal = focusOnSignal;
        vm.selectingMarker = false;
        vm.findMarker = findMarker;
        vm.eventListeners = [];
        vm.hasSignalSelected = false;
        vm.loading = true;
        vm.MAP_MARKER = "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";

        // Context menu functions
        vm.previousMarker = null;
        vm.currentSignalState = null;
        vm.showContextMenu = showContextMenu;
        vm.contextClosed = true;
        vm.loadStateFromMenu = loadStateFromMenu;
        vm.openInNewTab = openInNewTab;
        vm.setContextmenuPosition = setContextmenuPosition;
        vm.showSideMenu = showSideMenu;
        vm.newWindowService = newWindowService;
        vm.closeContextMenu = closeContextMenu;
        vm.disableMenuItem = disableMenuItem;

        vm.insightsLayer = $location.$$url.includes('insight');
        vm.insightsLayerSelected = $location.$$url.includes('insight');
        vm.turnOffInsights = turnOffInsights;
        vm.changeMarkerIcon = changeMarkerIcon;
        vm.statesLoaded = false;

        function turnOffInsights() {
            if (!vm.statesLoaded) return;
            if (!vm.contextClosed) vm.closeContextMenu();

            vm.markers.forEach(function (marker) {
                marker.setVisible(true);
                marker.setIcon(vm.changeMarkerIcon(marker.state, null, !marker.enabled));
            });
            if (!vm.insightsLayer && vm.hasSignalSelected) {
                vm.setSearchOptions();
                vm.focusOnSignal(vm.signal);
            }

            vm.insightsLayerSelected = !vm.insightsLayerSelected;
            var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));
            if (JSON.parse(sessionStorage.getItem('segmentData')).map_parameters) {
                delete currentSession.map_parameters;
            }
            if (!vm.insightsLayerSelected) {
                delete currentSession.system_state_overview_selection;
            }
            var currentMapParams = vm.collectMapParams();
            sessionStorage.setItem('segmentData', JSON.stringify(Object.assign(
                {
                    map_parameters: currentMapParams
                },
                currentSession
            )));

            // telemetryService.insightsDashboardTelemetry("click", "map_parameter");
        }

        function disableMenuItem(menuItem) {
            if (vm.contextClosed == false) {
                return !vm.contextSignal.signal.availableCharts.some(x => x.chartName == menuItem)
            }
            return;
        }

        function closeContextMenu() {
            if (!vm.contextClosed) {
                var menu = angular.element($element.find("#map-context-menu"));
                menu.removeClass("md-active");
                menu.addClass("hide-menu")
                vm.contextClosed = true;

                if (!vm.isContextMenuPressed) {
                    // telemetryService.insightsDashboardTelemetry("cancel", "new_page_selection");
                }
            }
        }

        function showContextMenu(e, signal, state) {
            if (vm.isFullscreen) return;
            vm.currentSignalState = state;

            // telemetryService.insightsDashboardTelemetry("right_click", "intersection_selection", {
            //     intersection_id: signal.signalID,
            //     intersection_name: signal.signalName,
            //     intersection_state: vm.insightsLayerSelected ? state : undefined,
            // })
            vm.contextClosed = false;
            vm.contextSignal.signal = signal;

            $scope.$apply();

            var menu = angular.element($element.find("#insights-context-menu"));
            var position = vm.setContextmenuPosition(e, menu);

            // set the position of the ContextMenu and make it visible
            menu.css({ left: position.x, top: position.y });
            menu.removeClass("hide-menu")
            menu.addClass("md-active");
        }

        function showSideMenu($mdMenu, ev) {
            if (vm.contextClosed) return;
            var sideMenu = angular.element($document[0].getElementById('side-menu'));
            var mapEl = $element.find(".gm-style");
            var pushFromBottom = vm.bottomLeftContextMenu - 100;
            if (vm.bottomLeftContextMenu + 303 > mapEl.height()) {
                if (vm.topLeftContextMenu - 300 < 50) {
                    pushFromBottom = vm.bottomLeftContextMenu - 250;
                } else {
                    pushFromBottom = vm.bottomLeftContextMenu - 330;
                }
            }
            if (vm.sideMenuLeftPosition + vm.contextMenuWidth + 65 + 327 > mapEl.width() + mapEl.scrollLeft()) {
                sideMenu.css({ left: vm.sideMenuLeftPosition - 265 + 'px', top: pushFromBottom + 'px' })
            } else {
                sideMenu.css({ left: vm.sideMenuLeftPosition + vm.contextMenuWidth + 65 + 'px', top: pushFromBottom + 'px' })
            }
            $timeout(function () {
                $mdMenu.open(ev);
            }, 500);
        }

        function setContextmenuPosition(event, contextMenu) {
            var mousePosition = {};
            var menuPosition = {};
            var menuDimension = {};
            //boolean to signal if we are opening menu higher than mouse position or lower
            var openLow = false;

            vm.contextMenuWidth = contextMenu.outerWidth();
            menuDimension.x = contextMenu.outerWidth();
            menuDimension.y = contextMenu.outerHeight();
            mousePosition.x = event.domEvent.pageX;
            mousePosition.y = event.domEvent.pageY;
            var mapEl = $element.find(".gm-style");

            if (mousePosition.x + menuDimension.x > mapEl.width() + mapEl.scrollLeft()) {
                menuPosition.x = mousePosition.x - menuDimension.x;
            } else {
                menuPosition.x = mousePosition.x - 150;
            }

            if ((mapEl.height() - mousePosition.y) + menuDimension.y > mapEl.height()) {
                menuPosition.y = mousePosition.y - menuDimension.y;
            } else {
                menuPosition.y = mousePosition.y - menuDimension.y + 50;
                openLow = true;
            }

            var roomToBottom = mapEl.height() - event.domEvent.pageY;
            //detect if our menu is overlapping and move
            if (!openLow && event.domEvent.pageY - contextMenu.outerHeight() < 0) {
                //we are overlapping to the top
                //so move down a bit
                var diff = contextMenu.outerHeight() - event.domEvent.pageY;
                menuPosition.y = menuPosition.y + diff;
            } else if (openLow && roomToBottom - contextMenu.outerHeight() < 0) {
                //we are overlapping to the bottom
                //so add  difference from y
                var diff = contextMenu.outerHeight();
                menuPosition.y = menuPosition.y - diff;
            }
            vm.sideMenuLeftPosition = menuPosition.x;
            vm.topLeftContextMenu = menuPosition.y;
            vm.bottomLeftContextMenu = menuDimension.y + menuPosition.y;

            return menuPosition;
        }

        function loadStateFromMenu(e, state) {
            vm.isContextMenuPressed = true;
            if ($scope.hideContextMenu) {
                return;
            }
            var stateArr = state.split('.');

            // telemetryService.insightsDashboardTelemetry("click", "new_page_selection", {
            //     intersection_id: vm.contextSignal.signal && vm.contextSignal.signal.signalID != "" ? vm.contextSignal.signal.signalID : undefined,
            //     intersection_name: vm.contextSignal.signal && vm.contextSignal.signal.signalID != "" ? vm.contextSignal.signal.signalName : undefined,
            //     intersection_state: (vm.contextSignal.signal && vm.contextSignal.signal.signalID != "" && vm.insightsLayerSelected) ? vm.markers.find(x => x.id == vm.contextSignal.signal.signalID).state : undefined,
            //     other_page_selection: {
            //         page: stateArr[stateArr.length - 1],
            //         new_tab: false
            //     }
            // });

            e.stopPropagation();
            if (vm.contextSignal) {
                breadcrumbNavigationService.navigateToState(
                    state,
                    undefined,
                    vm.contextSignal.signal
                );
            } else {
                breadcrumbNavigationService.navigateToState(state);
            }
            vm.currentSignalState = undefined;
            vm.isContextMenuPressed = false;
        }

        function openInNewTab(metricType) {
            if (!vm || !metricType || !vm.contextSignal || !vm.contextSignal.signal) return;
            vm.isContextMenuPressed = true;
            vm.closeContextMenu();

            // telemetryService.insightsDashboardTelemetry("click", "open_in_new_tab", {
            //     intersection_id: vm.contextSignal.signal && vm.contextSignal.signal.signalID != "" ? vm.contextSignal.signal.signalID : undefined,
            //     intersection_name: vm.contextSignal.signal && vm.contextSignal.signal.signalID != "" ? vm.contextSignal.signal.signalName : undefined,
            //     intersection_state: vm.insightsLayerSelected ? vm.currentSignalState : undefined,
            //     other_page_selection: {
            //         page: metricType.toLowerCase().trim().split(/\s+/).join('-'),
            //         new_tab: true
            //     }
            // });

            if (metricType == 'Signal Overview') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-overview', undefined, vm.contextSignal.signal);
            if (metricType == 'Performance Dashboard') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-performance', undefined, vm.contextSignal.signal);
            if (metricType == 'Trends Dashboard') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-trends', undefined, vm.contextSignal.signal);
            if (metricType == 'Signal Events') vm.newWindowService.openNewTabTimeAndSignal('app.spm.signal-events', undefined, vm.contextSignal.signal);
            if (metricType == 'Split Monitor') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.split-monitor', undefined, vm.contextSignal.signal);
            if (metricType == 'Purdue Split Monitor') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.purdue-split-monitor', undefined, vm.contextSignal.signal);
            if (metricType == 'Phase Termination') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.phase-termination', undefined, vm.contextSignal.signal);
            if (metricType == 'Pedestrian Delay') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.ped-delay', undefined, vm.contextSignal.signal);
            if (metricType == 'Preemption Details') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.preempt-details', undefined, vm.contextSignal.signal);
            if (metricType == 'Turning Movement Counts') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.turning-movements', undefined, vm.contextSignal.signal);
            if (metricType == 'Purdue Coordination') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.purdue-coord-diagram', undefined, vm.contextSignal.signal);
            if (metricType == 'Approach Volume') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.approach-volume', undefined, vm.contextSignal.signal);
            if (metricType == 'Approach Delay') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.approach-delay', undefined, vm.contextSignal.signal);
            if (metricType == 'Arrivals on Red') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.aor', undefined, vm.contextSignal.signal);
            if (metricType == 'Red Light Runners') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.red-light-runners', undefined, vm.contextSignal.signal);
            if (metricType == 'Purdue Split Failure') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.split-failure', undefined, vm.contextSignal.signal);
            if (metricType == 'Queue Length') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.queue-length', undefined, vm.contextSignal.signal);
            if (metricType == 'Detector Counts') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.detector-counts', undefined, vm.contextSignal.signal);
            if (metricType == 'Detector Durations') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.detector-durations', undefined, vm.contextSignal.signal);
            vm.isContextMenuPressed = false;
        };


        $scope.$on("$destroy", function () {
            sessionStorage.removeItem('segmentData');
            if ($scope.isSessionStorage) {
                saveMapPreferences();
            }
            if (vm.mapLoadInterval)
                $interval.cancel(vm.mapLoadInterval);

            //cleanup any click or event handlers we registered
            for (var i in vm.eventListeners) {
                google.maps.event.removeListener(vm.eventListeners[i]);
            }
            if (vm.mapWheelOnEvent)
                vm.mapWheelOnEvent.off();
            if (vm.infoWindowWheelEvent)
                vm.infoWindowWheelEvent.off();
            //remove any VM refs
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        });

        function findMarker(id) {
            return vm.markers.filter((mark) => {
                return mark.id === id;
            })[0];
        }

        $rootScope.$on('system-insights-clicked', function (e, options) {
            if (vm) {
                options.allItems.forEach(function (item) {
                    if (item.hidden) {
                        vm.markers.filter(x => x.state == item.text).forEach(function (marker) {
                            marker.setMap(null)
                        })
                    } else {
                        vm.markers.filter(x => x.state == item.text).forEach(function (marker) {
                            marker.setMap(vm.map)
                        })
                    }
                });
            }
        });

        // Pie chart interactions with the insight map
        systemInsightsService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm) return;

            if (changeType.actionType == 'hover') {
                if (changeType.signalState == "Restore State") {
                    vm.markers.forEach(function (marker) {
                        marker.setVisible(true);
                    });
                    return;
                }
                vm.markers.forEach(function (marker) {
                    if (marker.state == changeType.signalState) {
                        marker.setVisible(true);
                    } else {
                        marker.setVisible(false);
                    }
                });
            }
        });

        // Set up DOM elements as controls on the map
        const layerSwitch = document.getElementById('layerSwitch');
        const legend = document.getElementById('legend');

        vm.markers = [];
        vm.startMapLoad();

        vm.searchBarService.setSearchBarConfig(
            (vm.searchBarConfig = {
                //header information
                header: {
                    show: true,
                    text: "Insights Map",
                },
                //search bar options
                showSearchBar: true,
                searchType: "Signals",
                showCurrentFilterDates: true,
                showSignalNotificationTabs: false,
                helpJsonPropertyPath: "INSIGHTS_SIDEPANEL." + "INSIGHTS_MAP_HELP",
                showHelp: true,
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TD",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    showWeekdayFilter: false,
                    enableAdvancedTod: false,
                    maxDayRange: 31,
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: false,
                    showBinConfig: false,
                    skipStepsPerBin: true,
                },
            })
        );

        function changeMarkerIcon(signalState, focusingProps, disabled) {
            var markerIcon = {};
            if (disabled) {
                if (vm.insightsLayer) {
                    markerIcon = {
                        url: `../assets/icons/insights-map-icons/Offline.png`,
                        fillOpacity: 1,
                        anchor: { x: 12, y: 29 },
                    }
                } else {
                    markerIcon = {
                        url: '../assets/images/spm/marker.png',
                        fillOpacity: 1,
                        anchor: { x: 12, y: 29 },
                    };
                }
                // markerIcon = {
                //     url: `../assets/icons/insights-map-icons/cactus.png`,
                //     fillOpacity: 1,
                //     anchor: { x: 18, y: 44 },
                // }
                return markerIcon;
            }
            if (focusingProps && focusingProps.shouldFocus) {
                markerIcon = {
                    path: vm.MAP_MARKER,
                    fillColor: focusingProps.fColor,
                    strokeColor: focusingProps.sColor,
                    scale: focusingProps.scaleSize,
                    fillOpacity: 1,
                    anchor: { x: 12, y: 24 },
                }
                return markerIcon;
            }
            if (vm.insightsLayer) {
                var anchorPoint = signalState === 'Optimal' ? { x: 18, y: 14 } : { x: 18, y: 44 };
                markerIcon = {
                    url: `../assets/icons/insights-map-icons/${signalState}.png`,
                    fillOpacity: 1,
                    anchor: anchorPoint,
                }
            } else {
                markerIcon = {
                    url: '../assets/images/spm/marker.png',
                    fillOpacity: 1,
                    anchor: { x: 12, y: 29 },
                }
            }
            return markerIcon;
        }

        function setInitialMarkers(latitude, longitude, zIndex, signalID, disabled) {
            // If Map Layer is open initially, show default image
            // If Insights Layer is initially open, set the image and hide the marker
            var markerIcon = {};
            if (signalID == vm.signal.signalID) {
                markerIcon = {
                    path: vm.MAP_MARKER,
                    fillColor: '##0254A5',
                    strokeColor: '#FFFFFF',
                    scale: 2.2,
                    fillOpacity: 1,
                    anchor: { x: 12, y: 24 },
                }
            } else {
                if (disabled) {
                    if (vm.insightsLayer) {
                        markerIcon = {
                            url: `../assets/icons/insights-map-icons/Offline.png`,
                            fillOpacity: 1,
                            anchor: { x: 12, y: 29 },
                        }
                    } else {
                        markerIcon = {
                            url: '../assets/images/spm/marker.png',
                            fillOpacity: 1,
                            anchor: { x: 12, y: 29 },
                        };
                    }
                } else {
                    markerIcon = {
                        url: '../assets/images/spm/marker.png',
                        fillOpacity: 1,
                        anchor: { x: 12, y: 29 },
                    };
                }
            }
            var markerObj = new google.maps.Marker({
                icon: markerIcon,
                id: signalID,
                zIndex: zIndex,
                position: { lat: latitude, lng: longitude },
                map: vm.map
            });

            if (vm.insightsLayer) markerObj.setVisible(false);
            return markerObj;
        }

        vm.queryData = {
            orderBy: '',
            pageSize: 10,
            pageIndex: 1,
            filter: '',
            start: '',
            end: '',
            signalID: '',
        };

        function timeframeChange() {
            // Reset markers any time the timeframe changes
            vm.markers.forEach(function (marker) {
                marker.setMap(null);
            });
            vm.drawInsights();
        }

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm) return;
            switch (changeType) {
                case "time":
                    vm.timeframeChange();
                    if (searchBarService.isFiltering() && vm.signal && vm.signal.signalID !== "") {
                        vm.hasSignalSelected = true;
                        vm.focusOnSignal(vm.signal);
                    } else {
                        vm.hasSignalSelected = false;
                    }
                    break;
                // when another signal is selected,
                // revert the previous to default marker icon
                case "signal":
                    if (searchBarService.isFiltering()) {
                        vm.hasSignalSelected = true;
                        vm.setSearchOptions();
                        vm.focusOnSignal(vm.signal);

                        if (vm.isContextMenuPressed) return;

                        signalService.getSignalById(vm.signal.signalID).then(function (signal) {
                            if (vm.selectingMarker) {
                                vm.selectingMarker = false;

                                // telemetryService.insightsDashboardTelemetry("click", "intersection_selection", {
                                //     intersection_state: vm.insightsLayerSelected ? vm.markers.find(x => x.id == signal.signalID).state : undefined,
                                //     intersection_id: signal.signalID,
                                //     intersection_name: signal.signalName
                                // });
                            } else {
                                // telemetryService.insightsDashboardTelemetry("apply", "intersection_selection", {
                                //     intersection_state: vm.insightsLayerSelected ? vm.markers.find(x => x.id == signal.signalID).state : undefined,
                                //     intersection_id: signal.signalID,
                                //     intersection_name: signal.signalName
                                // });
                            }
                        })
                    } else {
                        vm.hasSignalSelected = false;
                    }
                    break;
                // FOCUS ON SIGNAL
                case "configuration": {
                    break;
                }
                case "state": {
                    // remove selected signal if any and fit bounds to default of map
                    vm.isFiltering = searchBarService.isFiltering();
                    vm.hasSignalSelected = true;
                    if (!vm.isFiltering) {
                        if (vm.previousMarker && !vm.insightsLayer) {
                            vm.previousMarker.setIcon(vm.changeMarkerIcon(null, null, !vm.previousMarker.enabled));
                            vm.previousMarker = null;
                        }
                        vm.hasSignalSelected = false;
                        vm.map.setOptions({ maxZoom: 13 });
                        vm.map.fitBounds(vm.bounds, {
                            right: $location.$$url.includes('insight') ? 300 : 0,
                        });
                        vm.map.setOptions({ maxZoom: 19 });
                        signalService.getSignalById(vm.signal.signalID).then(function (signal) {
                            // telemetryService.insightsDashboardTelemetry("remove", "intersection_selection", {
                            //     intersection_id: signal.signalID,
                            //     intersection_name: signal.signalName,
                            //     intersection_state: vm.insightsLayerSelected ? vm.markers.find(x => x.id == signal.signalID).state : undefined
                            // });
                        });
                    }
                    break;
                }
            }
        });

        function getSignalDescription(customId, primaryName, secondaryName) {

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '') {
                res += " - " + secondaryName;
            }

            return res;
        }

        function getSignalDescriptionWithoutId(primaryName, secondaryName) {

            var res = primaryName;
            if (secondaryName != null && secondaryName != '') {
                res += " - " + secondaryName;
            }

            return res;
        }

        function addHoverBoxInfo(marker, signal, state) {
            // Set content for the InfoBox
            var content = `<h3 style="padding-top: 0; margin-top: 0;"><strong>Signal: ${signal.customID}</strong></h3>
                          <p style="color:black;">${getSignalDescriptionWithoutId(signal.primaryName, signal.secondaryName)}</p>
                         ${signal.enabled ? `` : `<p style="color:black;"><strong>Signal is disabled!</strong></p>`}
                         `;

            vm.infoWindow = new google.maps.InfoWindow({
                content: content,
            });

            // Open the info window
            vm.infoWindow.open(vm.map, marker);

            // Close the info window on mouse out
            vm.eventListeners.push(google.maps.event.addListener(marker, "mouseout", function () {
                vm.infoWindow.close(vm.map, marker);
            }));
            vm.eventListeners.push(google.maps.event.addListener(marker, "click", function (event) {
                vm.infoWindow.close(vm.map, marker);
            }));

            vm.eventListeners.push(google.maps.event.addListener(marker, "zoom_changed", function () {
                vm.infoWindow.close(vm.map, marker);
            }));
            vm.infoWindowWheelEvent = angular.element($element.find("#insightmap")).on("wheel mousewheel DOMMouseScroll", function (e, marker) {
                vm.infoWindow.close(vm.map, marker);
            })
        }

        function focusOnSignal(signal) {
            if (signal) {
                vm.hasSignalSelected = true;
                vm.map.setCenter(
                    new google.maps.LatLng(signal.latitude, signal.longitude)
                );
                vm.map.setZoom(19);

                if (!vm.insightsLayer) {
                    if (vm.previousMarker) {
                        vm.previousMarker.setIcon(vm.changeMarkerIcon(null, null, !vm.previousMarker.enabled));
                        vm.previousMarker = null;
                    }
                    // if (signal.enabled) {
                    var currentMarker = vm.findMarker(signal.signalID);
                    currentMarker.setIcon(vm.changeMarkerIcon(null, { shouldFocus: true, fColor: "#0254A5", sColor: "#fff", scaleSize: 2.2 }, !signal.enabled));
                    vm.previousMarker = currentMarker;
                    // }
                }
            }
        }

        function drawInsights() {
            if (!vm) return;

            // set bounds from all of the markers
            vm.bounds = new google.maps.LatLngBounds();
            vm.setSearchOptions();

            vm.loading = true;
            // draw every marker in the forEach loop
            signalService.getAllSignalsWithApproaches().then(function (data) {

                vm.markers = [];

                data.signals.forEach(function (signal) {
                    if (signal.enabled) {
                        var marker = null, zIndex = null;
                        vm.bounds.extend({ lat: signal.latitude, lng: signal.longitude });
                        signal.description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                        // At this point, we do not have signal states.
                        // Draw default markers (blue icon)
                        marker = vm.setInitialMarkers(signal.latitude, signal.longitude, zIndex, signal.signalID, !signal.enabled);
                        marker.enabled = signal.enabled;
                        vm.eventListeners.push(google.maps.event.addListener(marker, "mouseover", () => {
                            vm.addHoverBoxInfo(marker, signal);
                        }));

                        vm.eventListeners.push(google.maps.event.addListener(marker, "click", (event) => {
                            vm.infoWindow.close(vm.map, marker);

                            vm.selectingMarker = true;

                            vm.searchBarService.setSignal(signal);
                        }));

                        vm.eventListeners.push(google.maps.event.addListener(marker, 'rightclick', (event) => {
                            vm.showContextMenu(event, signal, marker.state);

                            // call even.stop() and return false
                            // to prevent the right click from opening the default browser menu on right click
                            event.stop();
                            return false;
                        }))

                        vm.markers.push(marker);
                    }
                });
            }).then(function () {

                vm.loading = false;

                vm.map.setOptions({ maxZoom: 13 });
                vm.map.fitBounds(vm.bounds, {
                    right: $location.$$url.includes('insight') ? 300 : 0
                });
                vm.map.setOptions({ maxZoom: 19 });
                if (vm.signal && vm.signal.signalID != "") {
                    vm.focusOnSignal(vm.signal);
                }

                insightsService.getAllSignalStates(vm.queryData).then(function (data) {

                    // Go through all existing markers and add signal states to all of them
                    // At this point we're ready to show signal state markers
                    // So if insight layer is set, set all markers to visible

                    vm.markers.forEach(function (marker) {
                        var signalWithState = data.filter(x => x.signalID == marker.id);
                        if ((!signalWithState || signalWithState.length == 0))
                            return;
                        var stateProps = null;
                        if (marker.enabled) {
                            marker.state = signalWithState[0].state;
                            stateProps = marker.state;
                        }
                        marker.setIcon(vm.changeMarkerIcon(stateProps, null, !marker.enabled));
                        if (vm.insightsLayer) marker.setVisible(true);
                    });

                    // Initialize signal states counts and colors
                    // This data is needed for the Pie Chart
                    vm.signalStatesToPass = {
                        optimal: {
                            color: '#00a300',
                            title: 'Optimal',
                            count: 0
                        },
                        subOptimal: {
                            color: '#F5A623',
                            title: 'Sub-Optimal',
                            count: 0
                        },
                        underperforming: {
                            color: '#B19ECA',
                            title: 'Underperforming',
                            count: 0
                        },
                        offline: {
                            color: '#6A7B8B',
                            title: 'Offline',
                            count: 0
                        },
                        slightlyDegraded: {
                            color: '#B87D1A',
                            title: 'Slightly Degraded',
                            count: 0
                        },
                        degraded: {
                            color: '#e6511f',
                            title: 'Degraded',
                            count: 0
                        },
                    };

                    data.forEach(function (signalWithState) {
                        switch (signalWithState.state) {
                            case "Slightly Degraded":
                                vm.signalStatesToPass.slightlyDegraded.count++;
                                break;
                            case "Degraded":
                                vm.signalStatesToPass.degraded.count++;
                                break;
                            case "Underperforming":
                                vm.signalStatesToPass.underperforming.count++;
                                break;
                            case "Optimal":
                                vm.signalStatesToPass.optimal.count++;
                                break;
                            case "Offline":
                                vm.signalStatesToPass.offline.count++;
                                break;
                            case "Sub-Optimal":
                                vm.signalStatesToPass.subOptimal.count++;
                                break;
                        }
                    });
                }).then(function () {
                    if (vm.signal.signalID !== "") vm.focusOnSignal(vm.signal);

                    var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));
                    sessionStorage.setItem('segmentData', JSON.stringify(Object.assign(
                        {
                            component: 'map_component',
                            intersection_state: (vm.signal && vm.signal.signalID != "" && vm.insightsLayerSelected) ? vm.markers.find(x => x.id == vm.signal.signalID).state : undefined,
                            map_parameters:
                            {
                                map_imagery: vm.map.mapTypeId,
                                layer_view: vm.insightsLayerSelected ? "insights" : "map",
                                map_view: "browser",
                                open_street_view: false
                            }
                            ,
                        },
                        currentSession
                    )));

                    $rootScope.$emit('signalStatesLoaded', vm.signalStatesToPass);
                    vm.statesLoaded = true;
                });
            });
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.queryData.start = options.timeOptions.currentFilter.startDateAndTime.toLocaleString();
            vm.queryData.end = options.timeOptions.currentFilter.endDateAndTime.toLocaleString();

            if (options.signal) {
                vm.signal = {
                    enabled: options.signal.enabled,
                    signalID: options.signal.signalID,
                    description: options.signal.description,
                    latitude: options.signal.latitude,
                    longitude: options.signal.longitude,
                };
            } else {
                vm.signal = null;
            }
        }

        function setDefaultLocation() {
            if (sessionStorage.getItem("user_metadata")) {
                var userData = JSON.parse(sessionStorage.getItem("user_metadata"));
                if (userData && userData.map_bounds && userData.map_bounds.lat && userData.map_bounds.lon) {
                    vm.userlat = userData.map_bounds.lat;
                    vm.userlon = userData.map_bounds.lon;
                }
            }
        }
        //============================ PREPARING FOR SHOWING THE MAP =================================//
        //We need to setup an interval to wait for all the Google map objects to be created before we start loading our map
        function startMapLoad() {
            //check every 10ms if google.maps objects are ready
            var mapLoadInterval = $interval(function () {
                var isLoadedMap = google !== undefined && google.maps !== undefined && google.maps.event !== undefined;
                if (vm) {
                    vm.mapLoadInterval = mapLoadInterval;
                }
                else {
                    //vm is not loaded, parent scope is likely being destroyed. cancel the interval and return
                    $interval.cancel(mapLoadInterval);
                    return;
                }

                if (isLoadedMap) {
                    $interval.cancel(vm.mapLoadInterval);
                    vm.initMap();
                    // vm.callApiInit();
                }
            }, 10);
        }
        //============================================================================================//

        function collectMapParams() {
            return {
                map_imagery: vm.map.mapTypeId,
                layer_view: vm.insightsLayerSelected ? "insights" : "map",
                map_view: "browser",
                open_street_view: vm.map.streetView.visible
            }
        }

        function initMap() {

            sessionStorage.setItem('initial_load', {});

            vm.setDefaultLocation();
            // set first view to USA lat/lng if there's no Auth0 coords set
            if (!vm.userlat || !vm.userlon) {
                vm.userlat = 37.0902;
                vm.userlon = -95.7129;
                vm.defaultZoom = 5;
            }

            // set the view if there are things saved from the mapPreferences
            if ($scope.isSessionStorage && vm.savedZoomLevel && vm.savedTypeId && vm.savedCenter) {
                vm.defaultZoom = vm.savedZoomLevel;
                vm.defaultMapType = vm.savedTypeId;
                vm.userlat = vm.savedCenter.lat();
                vm.userlon = vm.savedCenter.lng();
                vm.isSessionStorage = true;
            }

            vm.drawInsights();

            // set initial map Options
            vm.mapOptions = {
                center: { lat: vm.userlat, lng: vm.userlon },
                styles: [{
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                },
                // userService.getUserRoles().some(x => x == 'MARKETING') ? {
                //     featureType: "all",
                //     elementType: 'labels',
                //     stylers: [{ visibility: 'off' }]
                // } : {}
                ],
                mapTypeId: 'roadmap'
            };

            // Draw the map
            if (vm.map === void 0) {
                vm.map = new google.maps.Map(document.getElementById("insightmap"), vm.mapOptions);
            }

            vm.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);

            vm.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(layerSwitch);

            vm.mapWheelOnEvent = angular.element($element.find("#googlemap")).on("wheel mousewheel DOMMouseScroll", function (e) {
                e.stopPropagation();
            });
            vm.eventListeners.push(google.maps.event.addListener(vm.map, "click", function () {
                if (!vm.contextClosed) vm.closeContextMenu();
            }));

            vm.eventListeners.push(google.maps.event.addListener(vm.map, "maptypeid_changed", function () {
                if (!vm.contextClosed) vm.closeContextMenu();
                var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));

                currentSession.map_parameters = vm.collectMapParams();

                sessionStorage.setItem('segmentData', JSON.stringify(currentSession));
            }));

            vm.eventListeners.push(google.maps.event.addListener(vm.map, "zoom_changed", function () {
                if (!vm.contextClosed) vm.closeContextMenu();
            }));

            vm.eventListeners.push(google.maps.event.addListener(vm.map, "drag", function () {
                if (!vm.contextClosed) vm.closeContextMenu();
            }));
            document.body.addEventListener("contextmenu", function (ev) {
                ev.preventDefault();
                return false;
            });
            vm.eventListeners.push(google.maps.event.addListener(vm.map.getStreetView(), "visible_changed", function () {
                if (!vm.contextClosed) vm.closeContextMenu();
                if (vm.map.getStreetView().visible) {
                    // telemetryService.insightsDashboardTelemetry("open", "map_parameter");
                }
            }));
            vm.eventListeners.push(google.maps.event.addListener(vm.map.getStreetView(), "pano_changed", function () {
                if (!vm.contextClosed) vm.closeContextMenu();
            }));
            vm.eventListeners.push(google.maps.event.addListener(vm.map.getStreetView(), "pov_changed", function () {
                if (!vm.contextClosed) vm.closeContextMenu();
            }));
        }
    }
})();