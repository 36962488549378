import * as DASHBOARDINSIGHTSSETTINGS from 'raw-loader!./insights-settings/dashboard-insights-settings.html';
import * as DASHBOARDEMAILSETTINGS from 'raw-loader!./insights-settings/dashboard-email-settings.html';
import * as DASHBOARDREPROTSETTINGS from 'raw-loader!./report-settings/dashboard-report-settings.html';
import * as CHARTSSETTINGS from 'raw-loader!./charts-settings/charts-settings.html';

(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.user-settings')
        .controller('UserSettingsController', UserSettingsController);

    /** @ngInject */
    function UserSettingsController($rootScope, $scope, $state, authService, searchBarService, $mdPanel) {
        var vm = this;
        vm.searchBarService = searchBarService;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        $scope.loading = false;
        vm.openPanelRef = undefined;
        vm.authService = authService;
        vm.username = sessionStorage.getItem('userName');
        vm.close = close;
        vm.filterBy = 'all';
        vm.filterChange = filterChange;

        function filterChange(type) {
            vm.filterBy = type;
            vm.whiteText = 'white-text';
            if (type == 'all') vm.whiteText = '';
        }


        vm.goToDashboardInsightsSettings = function (ev) {
            var position = $mdPanel.newPanelPosition().absolute().center();
            var config = {
                attachTo: angular.element(document.body),
                template: DASHBOARDINSIGHTSSETTINGS,
                controller: 'DashboardInsightSettingsController as vm',
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: true,
                preserveScope: false,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                locals: {
                    close: vm.close
                }
            };

            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        };


        vm.goToDashboardEmailSettings = function (ev) {
            var position = $mdPanel.newPanelPosition().absolute().center();
            var config = {
                attachTo: angular.element(document.body),
                controller: 'DashboardInsightSettingsController as vm',
                template: DASHBOARDEMAILSETTINGS,
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: true,
                preserveScope: false,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                locals: {
                    close: vm.close
                }
            };

            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        };

        vm.goToReportSettings = function (ev) {
            var position = $mdPanel.newPanelPosition().absolute().center();
            var config = {
                attachTo: angular.element(document.body),
                controller: 'DashboardReportSettingsController as vm',
                template: DASHBOARDREPROTSETTINGS,
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: true,
                preserveScope: false,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                locals: {
                    close: vm.close
                }
            };
            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        };


        vm.goToChartSettings = function (ev) {
            var position = $mdPanel.newPanelPosition().absolute().center();
            var config = {
                attachTo: angular.element(document.body),
                controller: 'DashboardChartsSettingsController as vm',
                template: CHARTSSETTINGS,
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: true,
                preserveScope: false,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                locals: {
                    close: vm.close
                }
            };
            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        };

        function close() {
            if (vm.openPanelRef) {
                vm.openPanelRef.close();
                vm.openPanelRef.destroy();
            }

        }


        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
                text: "User Settings"
            },
            //search bar options
            showSearchBar: false,
            searchType: 'NoSearch',
            showCurrentFilterDates: false,
        });
    }

})();