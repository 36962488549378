import * as QUERYFORM from 'raw-loader!./insights-query-form.html';
import * as QUERYDETAILS from 'raw-loader!./insights-query-details.html';

(function () {
    'use strict';
    var app = angular
        .module('app.spm.dashboards.insights')
        .controller('InsightsQueryController', InsightsQueryController);


    /** @ngInject */
    function InsightsQueryController($scope, $state, $mdDialog, $mdPanel, searchBarService, signalService,  insightsService, InsightConstants) {
        var vm = this;

        $scope.$on("$destroy", function () {
            vm = null;
        });
        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.insightsService = insightsService;
        vm.cardServerError = false;
        vm.setupSearchBar = setupSearchBar;
        vm.getData = getData;
        vm.runQuery = runQuery;
        vm.deleteInsightQuery = deleteInsightQuery;
        vm.editInsightQuery = editInsightQuery;
        vm.addInsightQuery = addInsightQuery;
        vm.getDefinitions = getDefinitions;
        vm.deleteInsightQueryById = deleteInsightQueryById
        vm.definitions = [];
        vm.insightResults = [];
        vm.insightResultsFiltered = [];
        vm.insightPromise = undefined;
        vm.signalSearch = "";
        vm.pageData = {
            pageIndex: 1,
            size: 10
        };
        vm.filterInsightType = undefined;
        vm.filterAlgorithm = undefined;

        vm.openQueryDetails = openQueryDetails;

        vm.filterResults = filterResults;

        vm.detectorIssueTypes = [
            {
                name: "Stuck on",
                id: 2
            },
            {
                name: "Intermittent Stuck on",
                id: 12
            },
            {
                name: "Chatter",
                id: 4
            },
            {
                name: "Intermittent Chatter",
                id: 13
            },
        ];  
        vm.pedPhaseIssueTypes = [
            {
                name: "Stuck on",
                id: 3
            },
            {
                name: "Intermittent Stuck on",
                id: 6
            },
        ];  
        vm.signalIssueTypes = [
            {
                name: "No Data",
                id: 11
            },
            {
                name: "Missing Data",
                id: 10
            },
            {
                name: "Unreliable Data",
                id: 14
            },
        ];  

        //setup searchbar now always needs to be called first
        signalService.getAllSignalsWithApproaches().then(function(){
            vm.getData();
        });
        vm.getDefinitions();
        vm.setupSearchBar();

        function setupSearchBar() {
            vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
                //header information
                header: {
                    show: true,
                    text: "Insights Tester",
                    badgeText: "beta"
                },
                //search bar options
                showSearchBar: false,
                searchType: 'NoSearch',
                showCurrentFilterDates: false,
                helpJsonPropertyPath: "INSIGHTS_DASHBOARD." + "GENERAL_HELP",
                showHelp: true,                
            });
        }

        function getData() {
            vm.insightsService.getInsightQueryResults() 
            .then(function (result) {
                if (!result)
                    return;

                vm.insightResults = [];

                for(let i = 0; i < result.length; i++){
                    var queryResult = result[i];
                    var resultSignal = signalService.getSignalByIDFromDict(queryResult.signalID);

                    var signalResultRow = vm.insightResults.find(x => x.signal.signalID == resultSignal.signalID);
                    if (!signalResultRow){
                        signalResultRow = {
                            signal: resultSignal,
                            queryExecutedDate: new Date(queryResult.queryExecutedDate).toLocaleString(),
                            queryResults: [],
                            queryResultsFiltered: []
                        };
                        vm.insightResults.push(signalResultRow);
                    }
                    signalResultRow.queryResults.push({
                        passOrFail: queryResult.passOrFail,
                        expectedPositiveResult: queryResult.expectedPositiveResult,
                        resultTypeID: parseInt(queryResult.resultInsightTypeID),
                        issueTypeID: queryResult.queryIssueTypeID,
                        detectorChannel: queryResult.detectorChannel > 0 ? queryResult.detectorChannel : '-',
                        phaseNumber: queryResult.phaseNumber > 0 ? queryResult.phaseNumber : '-',
                        algorithmVersion: queryResult.algorithmVersion,
                        issueDate: new Date(queryResult.issueDate).toLocaleString(),
                        resultUniqueId: queryResult.queryResultUniqueID,
                        occurrences: [],
                    })
                }
                vm.filterResults();
            });
        }

        function runQuery(item) {
            vm.insightPromise = vm.insightsService.runInsightQuery(item.query)
            .then(function (data) {
                if (data && data.results){
                    var newResults = [];
                    for(let i = 0; i < data.results.length; i ++){
                        var result = data.results[i];
                        var resultSignal = signalService.getSignalByIDFromDict(result.signalID);
                    
                        var signalResultRow = vm.insightResults.find(x => x.signal.signalID == resultSignal.signalID);
                        if (!signalResultRow){
                            signalResultRow = {
                                signal: resultSignal,
                                queryResults: [],
                                queryResultsFiltered: [],
                            };
                            vm.insightResults.unshift(signalResultRow);
                        }
                        signalResultRow.queryExecutedDate = new Date(result.queryExecutedDate).toLocaleString();
                       
                        newResults.push({
                            expectedPositiveResult: result.expectedPositiveResult,
                            passOrFail: result.passOrFail,
                            resultTypeID: parseInt(result.resultInsightTypeID),
                            issueTypeID: result.queryIssueTypeID,
                            detectorChannel: result.detectorChannel > 0 ? result.detectorChannel : '-',
                            phaseNumber: result.phaseNumber > 0 ? result.phaseNumber : '-',
                            algorithmVersion: result.algorithmVersion,
                            issueDate: new Date(result.issueDate).toLocaleString(),
                            occurrences: result.occurrences,
                            resultUniqueId: result.queryResultUniqueID,
                        });
                    }
      
                    for(let i = 0; i<newResults.length; i++){
                        signalResultRow.queryResults.unshift(newResults[i]);
                    }
                    vm.filterResults();
                }
            });
        }

        function deleteInsightQuery(signalQuery){
            if (signalQuery && signalQuery.queryResultsFiltered && signalQuery.queryResultsFiltered.length > 0){
                var ids = signalQuery.queryResultsFiltered.map(x => x.resultUniqueId);
                vm.insightPromise = vm.insightsService.deleteInsightQuery(ids)
                .then(function(data){
                    if (data){
                        var removeIdx = vm.insightResults.indexOf(signalQuery);
                        if (removeIdx > -1) {
                            vm.insightResults.splice(removeIdx, 1);
                            vm.filterResults();
                        }
                    }
                });
            }
        }

        function deleteInsightQueryById(signalQuery, resultId){
            if (signalQuery && resultId && signalQuery.queryResultsFiltered && signalQuery.queryResultsFiltered.length > 0){
                vm.insightPromise = vm.insightsService.deleteInsightQuery([resultId])
                .then(function(data){
                    if (data){
                        var removeIdx = signalQuery.queryResults.findIndex(x => x.resultUniqueId == resultId);
                        if (removeIdx > -1) {
                            signalQuery.queryResults.splice(removeIdx, 1);
                            vm.filterResults();
                        }
                    }
                });
            }
        }

        function editInsightQuery(){

        }

        function filterResults(){
            vm.insightResultsFiltered = vm.insightResults.filter(item => item.signal.signalName.toLowerCase().includes(vm.signalSearch.toLowerCase()));
            for(let i = 0; i < vm.insightResultsFiltered.length; i++){
                var current = vm.insightResultsFiltered[i];
                //set filtered array
                current.queryResultsFiltered = current.queryResults;

                if (vm.filterInsightType){
                    current.queryResultsFiltered = current.queryResults.filter(item => item.issueTypeID == vm.filterInsightType || item.resultTypeID == vm.filterInsightType);
                }
                if (vm.filterAlgorithm){
                    current.queryResultsFiltered = current.queryResults.filter(item => item.algorithmVersion == vm.filterAlgorithm);
                }
                current.successPercent = ((current.queryResultsFiltered.filter(x => x.passOrFail == "PASS").length / current.queryResultsFiltered.length)*100).toFixed(2);
            }
        }

        function addInsightQuery(ev){
            var confirm = $mdDialog.show({
                controller: 'insightQueryFormController as vm',
                template: QUERYFORM,
                parent: angular.element(document.body),
                targetEvent: ev,
                locals: {
                    on_save: function (item) {
                        vm.runQuery(item);
                    },
                    detectorIssueTypes: vm.detectorIssueTypes,
                    pedPhaseIssueTypes: vm.pedPhaseIssueTypes,
                    signalIssueTypes: vm.signalIssueTypes
                }
            });
        }

        function openQueryDetails(item){
            var confirm = $mdDialog.show({
                controller: 'insightQueryDetailsController as vm',
                template: QUERYDETAILS,
                parent: angular.element(document.body),
                // targetEvent: ev,
                locals: {
                    signalQuery: item,
                    filterInsightType: vm.filterInsightType,
                    filterAlgorithm: vm.filterAlgorithm,
                    insightDefinitions: vm.definitions,
                    detectorIssueTypes: vm.detectorIssueTypes,
                    pedPhaseIssueTypes: vm.pedPhaseIssueTypes,
                    signalIssueTypes: vm.signalIssueTypes,
                    runQuery: function (item){
                        vm.runQuery(item);
                    },
                    deleteInsightQueryById: function(signalQuery, resultId){
                        vm.deleteInsightQueryById(signalQuery, resultId);
                        return vm.insightPromise;
                    }
                }
            });
        }

        function getDefinitions() {
            insightsService.getInsightsDefintions()
                .then(function (definitions) {
                    vm.definitions = definitions;              
                })
        }
    }
})();