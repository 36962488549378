(function () {
    'use strict';

    angular
        .module('app.spm.dashboards.insights')
        .controller('insightQueryFormController', insightQueryFormController);

    /** @ngInject */
    function insightQueryFormController($rootScope, $scope, $state, $mdDialog, signalService,on_save, detectorIssueTypes, pedPhaseIssueTypes, signalIssueTypes) {
        var vm = this;
        vm.signal = {};
        vm.close = close;
        vm.saveInsightQuery = saveInsightQuery;
        vm.deleteInsightQuery = deleteInsightQuery;
        vm.isPhaseIssueType = isPhaseIssueType;
        vm.isDetectorIssueType = isDetectorIssueType;
        vm.approachSelected = approachSelected;
        vm.detectorSelected = detectorSelected;
        vm.selectedApproach = undefined;
        vm.selectedDetector = undefined;
        vm.runFullSignal = false;
        vm.detectorIssueTypes = detectorIssueTypes;
        vm.pedPhaseIssueTypes = pedPhaseIssueTypes;
        vm.signalIssueTypes = signalIssueTypes;

        vm.currentData = {
        };

        vm.signals = {
            selected: null,
            intialId: null,
            //just need this to populate the textbox while we're searching
            initialName: null,
            queryData: {
                orderBy: 'customID',
                pageSize: 0,
                pageIndex: 1,
                filter: ''
            },
            search: function () {
                //we have a specific id we want to query, so set that in our filter
                if (vm.signals.intialId) {
                    vm.signals.queryData.filter = vm.signals.intialId;
                }
                //query the server for our signals data source for autocomplete
                var res = signalService.querySignals(this.queryData).then(function (data) {
                    data.signals.forEach(function (item, index) {
                        item.description = getSignalDescription(item.customID, item.primaryName, item.secondaryName);
                    });
                    //if we had an initial id to load, lets check our dataset and set it to the selected object
                    if (vm.signals.intialId && vm.signals.intialId > -1) {
                        vm.signals.selected = data.signals.find(function (signal) {
                            return signal.signalID == vm.signals.intialId
                        });
                    }
                    //clear initials
                    vm.signals.intialId = null;
                    vm.signals.initialName = null;
                    return data.signals;
                });
                //set the autocomplete text while we're loading in the background
                if (vm.signals.initialName) {
                    vm.signals.queryData.filter = vm.signals.initialName;
                }
                return res;
            },
            signalChange: function (item) {
                if (!vm.signals.selected) {
                    vm.signals.queryData.filter = "";
                    vm.currentData = {};
                }
                else{
                    vm.signals.selected = signalService.getSignalByIDFromDict(vm.signals.selected.signalID);
                    vm.signals.selected.description = vm.signals.selected.signalName;
                }
            }
        };

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function close()  {
            $mdDialog.cancel(false);
        };

        $scope.ok = function () {
            $mdDialog.hide({ message: 'Success' });
        };

        function saveInsightQuery() {
            //var object to return
            
            var newObj = {
                query:{
                    signalID: vm.signals.selected.signalID,
                    issueTypeID: vm.currentData.issueTypeId,
                    expectedPositiveResult: vm.currentData.expectedPositiveResult == 1 ? true : false,
                    phaseNumber: vm.currentData.phaseNumber,
                    detectorChannel: vm.currentData.detectorChannel,
                    issueDate: new Date(vm.currentData.date).toLocaleString()
                },
                signal: vm.signals.selected,
            }
            //sanity check to make sure all properties are set right if run full signal set
            if (vm.runFullSignal){
                newObj.query.issueTypeID = -1;
                newObj.query.phaseNumber = undefined;
                newObj.query.detectorChannel = undefined;
            }
            if (on_save)
                on_save(newObj);
            
            $mdDialog.cancel();
        };

        function approachSelected(){
            if (vm.selectedApproach){
                vm.currentData.phaseNumber = vm.selectedApproach.protectedPhaseNumber;
            }
            else{
                vm.currentData.phaseNumber = undefined;
            }
        }
        function detectorSelected(){
            if (vm.selectedDetector){
                vm.currentData.detectorChannel = vm.selectedDetector.detChannel;
            }
            else{
                vm.currentData.detectorChannel = undefined;
            }
        }
        function deleteInsightQuery(){}

        function isPhaseIssueType(insightTypeId){
            return vm.pedPhaseIssueTypes.filter(x => x.id == insightTypeId).length > 0;
        }

        function isDetectorIssueType(insightTypeId){
            return vm.detectorIssueTypes.filter(x => x.id == insightTypeId).length > 0;
        }
    }
})();
