import * as CORRIDORADD from 'raw-loader!../../view/corridor-add.html';

(function () {
    "use strict";

    angular
        .module("app.spm.corridors")
        .controller("corridorListController", corridorListController);

    function corridorListController($state, $scope, corridorService, $mdDialog, searchBarService , dataExportService) {
        var vm = this;
        vm.corridorPromise = undefined;
        vm.getCorridors = getCorridors;
        vm.goToAddCorridor = goToAddCorridor;
        vm.goToEditCorridor = goToEditCorridor;
        vm.deleteCorridor = deleteCorridor;
        vm.setupDataTable = setupDataTable;
        vm.totalCount = 0;
        vm.totalQueryCount = 0;
        vm.directionTypes = undefined;
        vm.setupDataTable();
        vm.excelLoading = false;

        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
            },
            //search bar options
            showSearchBar: false,
        });

        vm.excelExportFromMenu = function (obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            var corridorID = obj.corridorID;
            vm.excelLoading = true;
            vm.queryData.id = corridorID;
            vm.queryData.fileType = "xlsx";
            dataExportService.getCorridorConfigExcelFile(vm.queryData).then(function (e) {
                vm.excelLoading = false;
            });
        };

        vm.excelExportAllCorridors = function (obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            vm.excelLoading = true;
            vm.queryData.id = "";
            vm.queryData.fileType = "xlsx";
            dataExportService.getCorridorConfigExcelFile(vm.queryData).then(function (e) {
                vm.excelLoading = false;
            });
        };

        function getCorridors() {
            vm.corridorPromise = corridorService.queryCorridors(vm.queryData).then(function (data) {
                vm.totalCount = data.totalCount;
                vm.totalQueryCount = data.totalCountInQuery;
                vm.corridors = data.corridors;
                vm.directionTypes = data.directionTypes;
            });
        }


        function goToAddCorridor(ev) {
            /*TODO: go to add corridor */
            $mdDialog.show({
                locals: { directionTypes: vm.directionTypes ,  corridorID: null },
                controller: 'corridorAddController as vm',
                template: CORRIDORADD,
                parent: angular.element(document.body),
                targetEvent: ev,
            }).then(function () {
                vm.getCorridors();
            });
        };


        function goToEditCorridor(ev,obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            $mdDialog.show({
                locals: { directionTypes: vm.directionTypes, corridorID: obj.corridorID },
                controller: 'corridorAddController as vm',
                template: CORRIDORADD,
                parent: angular.element(document.body),
                targetEvent: ev,
                preserveScope: false,
                skipHide: true,
                multiple: false
            }).then(function () {
                vm.getCorridors();
            });
        };

        vm.removeFromCorridors = removeFromCorridors;

        function removeFromCorridors() {
            vm.corridors.forEach(function (elem,i){
                if(elem.corridorID == vm.selected[0].corridorID) vm.corridors.splice(i,1);
            });
            vm.selected.splice(0,1);
               
        };

        function deleteCorridor(obj) {
            corridorService.removeCorridor(vm.selected[0], removeFromCorridors)
        }

        var bookmark;
        vm.searchChange = function (newValue, oldValue) {
            if (!oldValue) {
                bookmark = vm.queryData.pageIndex;
            }

            if (newValue !== oldValue) {
                vm.queryData.pageIndex = 1;
            }

            if (!newValue) {
                vm.queryData.pageIndex = bookmark;
            }

            vm.getCorridors();
        };

        vm.getCorridors();

        function setupDataTable() {
            vm.selected = [];
            vm.totalCount = {};
            vm.options = {
                rowSelection: true,
                multiSelect: false,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: true,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };

            vm.queryData = {
                orderBy: 'corridorID',
                pageSize: 10,
                pageIndex: 1,
                filter: ''
            };
        }
    }
}());
