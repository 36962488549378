﻿(function () {
    'use strict';

    angular
        .module('app.searchbar', [])
        .config(config);

    /** @ngInject */
    function config($translatePartialLoaderProvider) {
    }
})();