(function () {
    "use strict";

    angular
        .module("app.spm.core")
        .controller("trendsOptionsController", trendsOptionsController)
        .config(function ($mdDateLocaleProvider) {

            // Can change week display to start on Monday.
            $mdDateLocaleProvider.firstDayOfWeek = 1;
            // Optional.

        });

    function trendsOptionsController($state, $rootScope, $filter, $element, $scope, searchBarService, signalTrendsService, TrendsConstants) {
        var vm = this;
        vm.signalTrendsService = signalTrendsService;
        vm.trendsConstants = TrendsConstants;
        vm.options = vm.signalTrendsService.getOptions();
        vm.currentState = vm.options.filterState;
        vm.filterStateChange = filterStateChange;
        vm.isFutureDate = isFutureDate;
        vm.searchBarService = searchBarService;
        vm.maxDate = new Date();
        vm.isDaily = true;
        vm.isValidDate = isValidDate;
        var selectedOptions = vm.searchBarService.getSearchOptions();
        var differenceInTime = selectedOptions.timeOptions.currentFilter.endDateAndTime.getTime() - selectedOptions.timeOptions.currentFilter.startDateAndTime.getTime();
        var differenceInDays = differenceInTime / (1000 * 3600 * 24);

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        
        if(vm.isValidDate(vm.options.baselineStartDate)){
            vm.useStartDate = vm.options.baselineStartDate;
        } else {
            vm.useStartDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
        }
        if(vm.isValidDate(vm.options.baselineEndDate) && !vm.isFutureDate(vm.options.baselineEndDate)){
            vm.useEndDate = vm.options.baselineEndDate;
        } else {
            vm.useEndDate = new Date()
        }
        vm.data = {
            dateStart: vm.useStartDate,
            dateEnd: vm.useEndDate
        }
        if (differenceInDays <= 32) {
            vm.isDaily = false;
        }


        if (differenceInDays >= 14) {
            vm.isWeekly = true;
        } else {
            vm.isWeekly = false;
        }

        function isValidDate(d) {
            return d instanceof Date && !isNaN(d);
          }

        function isFutureDate(d) {
            var date = d.$date ? d.$date : d;
            return date && date.getTime() > new Date().getTime()
        }


        function filterStateChange(filterIndex) {
            vm.currentState = filterIndex;
        }

        vm.searchBarService.subscribeToOptionsApply($scope, function () {

            //user hit apply on options
            if (differenceInDays >= 14) {
                if (vm.data.dateStart == vm.data.dateEnd) {
                    vm.options.baselineStartDate = vm.data.dateStart;
                    let currentEndDate = new Date(vm.data.dateEnd);
                    vm.options.baselineEndDate = new Date(currentEndDate.getTime() + 1 * 24 * 60 * 60 * 1000)
                }
                else {
                    vm.options.baselineStartDate = vm.data.dateStart;
                    vm.options.baselineEndDate = vm.data.dateEnd;
                }
            }
            else {
                vm.options.baselineStartDate = new Date(vm.options.baselineStartDate)
                let currentEndDate = new Date(vm.data.dateEnd);
                vm.options.baselineEndDate = new Date(currentEndDate.getTime() + 1 * 24 * 60 * 60 * 1000)
            }

            vm.options.filterState = vm.currentState;
            
            vm.signalTrendsService.notifyChange("Filter", vm.options.filterState);
        });
    }
}());
