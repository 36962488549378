(function () {
    'use strict';

    angular
        .module('app.core')
        .directive('repeatDone', function ($parse) {
            return function (scope, element, attrs) {
                    if (scope.$last) {
                        scope.$eval(attrs.repeatDone);
                    }
            }
        })
})();