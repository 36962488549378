
import './view/dashboard-insights.module';
import './view/dashboard-insights.service';
import './view/dashboard-insights.controller';
import './insights.resource';
import './insights.service';
import './view/details-dialog.controller';
import './view/insights-options.controller';
import './view/acknowledge-event.controller'
import './query-tester/insights-query.controller';
import './query-tester/insights-query-form.controller';
import './query-tester/insights-query-details.controller';