(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.signal-trends')
        .controller('DashboardSignalTrendsController', DashboardSignalTrendsController);

    /** @ngInject */
    function DashboardSignalTrendsController($rootScope, $scope, $state, searchBarService, signalService, signalTrendsService, TrendsConstants, signalPerformanceService, userSettingsService, insightsService) {
        var vm = this;
        vm.searchBarService = searchBarService;
        vm.timeOptions = {};
        vm.signal = {};
        vm.loading = false;
        vm.chartServerError = false;
        vm.setSearchOptions = setSearchOptions;
        vm.setupSearchBar = setupSearchBar;
        vm.signalTrendsService = signalTrendsService;
        vm.trendsConstants = TrendsConstants;
        vm.onCardApiInit = onCardApiInit;
        vm.areAllCardsInitialized = areAllCardsInitialized;
        vm.updateCards = updateCards;
        vm.getData = getData;
        vm.includeHourlyData = includeHourlyData;
        vm.isFiltering = false;
        vm.trackDailyInsightsIndexes = [];
        vm.trackHourlyInsightsIndexes = [];
        let insights = insightsService.getInsights("Other");
        vm.insightsLoaded = (insights != undefined && insights.insightGroups != undefined && insights.insightGroups.length > 0 && insights.insightGroups[0].insightTypes.length > 0)  ? true : false;

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })


        vm.cards = [{
            metricType: signalPerformanceService.metricTypesEnum.Volume,
            aggregationType: signalPerformanceService.aggregationNames.Volumes,
            title: "Volume",
            reverseColorScale: false,
            isSquareRoot: false,
            showTotal: true,
            isAverage: false,
            legendTitle: 'Counts',
            api: {},
            data: {
                signalData: [],
                approachData: [],
                hasNeededDetection: false,
            },
            onDestroy: function () {
                this.api = {};
            }
        },
        {
            metricType: signalPerformanceService.metricTypesEnum.SplitFails,
            aggregationType: signalPerformanceService.aggregationNames.SplitFails,
            title: "Split Failures",
            reverseColorScale: false,
            isSquareRoot: true,
            showTotal: true,
            isAverage: false,
            legendTitle: 'Counts',
            api: {},
            data: {
                signalData: [],
                approachData: [],
                hasNeededDetection: false,
            },
            onDestroy: function () {
                this.api = {};
            }
        },
        {
            metricType: signalPerformanceService.metricTypesEnum.ArrivalsOnGreen,
            aggregationType: signalPerformanceService.aggregationNames.Pcd,
            title: "Arrivals on Green Percentage",
            reverseColorScale: true,
            isSquareRoot: false,
            showTotal: false,
            isAverage: true,
            legendTitle: 'Percentage',
            api: {},
            dataLabel: "percentage",
            data: {
                signalData: [],
                approachData: [],
                hasNeededDetection: false,
            },
            onDestroy: function () {
                this.api = {};
            }
        },
        {
            metricType: signalPerformanceService.metricTypesEnum.DelayPerVehicle,
            aggregationType: signalPerformanceService.aggregationNames.VehicleDelay,
            title: "Delay Per Vehicle",
            reverseColorScale: false,
            isSquareRoot: false,
            showTotal: false,
            isAverage: true,
            legendTitle: 'Seconds',
            api: {},
            data: {
                signalData: [],
                approachData: [],
                hasNeededDetection: false,
            },
            onDestroy: function () {
                this.api = {};
            }
        }
        ];


        function onCardApiInit(apiObj) {
            if (apiObj && apiObj.update && vm.insightsLoaded)
                vm.updateCards();
        }

        function areAllCardsInitialized() {
            let res = true;
            for (let i = 0; i < vm.cards.length; i++) {
                let api = vm.cards[i].api;
                if (!api || !api.update) {
                    res = false;
                }
            }
            return res;
        }

        function updateCards() {
            if (vm.areAllCardsInitialized())
                vm.getData();
        }

        vm.setupSearchBar();

        //setup options change also
        insightsService.subscribe($scope, function onChange(ev, options) {
            if (!vm)
                return;

            switch (options) {
                case "InsightsFailed":
                        vm.insightsLoaded = false;
                        vm.trackDailyInsightsIndexes = [];
                        vm.trackHourlyInsightsIndexes = [];
                        vm.updateCards();
                        break;
                case "InsightsLoaded":
                    vm.setSearchOptions();
                    if(vm.signal.signalID != ''){
                        vm.insightsLoaded = true;
                        vm.trackDailyInsightsIndexes = [];
                        vm.trackHourlyInsightsIndexes = [];
                        vm.updateCards();
                    }
                break;
            }
        });

        //setup options change also
        vm.signalTrendsService.subscribe($scope, function onChange(ev, options) {
            if (!vm)
                return;

            if(options.name == "Filter") {
                vm.chartServerError = false;
            }
            if(options.name == "Filter" && options.value == 2 && vm.signal.signalID != ''){
                vm.trackDailyInsightsIndexes = [];
                vm.trackHourlyInsightsIndexes = [];
                vm.updateCards();
            }
        });
        
        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                    case "signal":
                        if (searchBarService.isFiltering()) {
                            vm.setSearchOptions();
                            vm.chartServerError = false;
                            vm.insightsLoaded = false;
                            vm.loading = true;
                        }
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.setSearchOptions();
                            vm.chartServerError = false;
                        }
                        break;
                    case "state":
                        vm.isFiltering = searchBarService.isFiltering();
                        vm.chartServerError = false;
                        break;
                    case "filter":
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Signal Trends Dashboard"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Signals',
                showCurrentFilterDates: true,
                showApproachesSelection: false,
                helpJsonPropertyPath: "SIGNAL_TRENDS_CARD." + "GENERAL_HELP",
                showHelp: true,
                showSignalNotificationTabs: true,
                metricTypeId: userSettingsService.dashboardTypeIds.SignalTrends,
                dashboardType: "Signal",                   
                showExcel: false,
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "L3M",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 31,
                    showWeekdayFilter: true,
                    enableAdvancedTod: true,
                    timeOptionForCustomTemplate: "TimePeriod",
                    minDayRange: 0,
                    maxDayRange: 366
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: false,
                    skipStepsPerBin: false,
                    bins: {
                        hour: {
                            value: "Hour",
                            range: [0, 8]
                        },
                        day: {
                            value: "Day",
                            range: [4, 90]
                        }
                    },
                    customHtmlPath: "assets/templates/trends-options/trends-options.html"
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function getData() {
            vm.setSearchOptions();
            if (!vm.timeOptions || !vm.signal) {
                return;
            }
            //setup default parameters
            var daysOfWeek = [" "];
            var startHour = 0, startMinute = 0, endMinute = 0, endHour = 0;
            var start = new Date(vm.timeOptions.currentFilter.startDateAndTime);
            var end = new Date(vm.timeOptions.currentFilter.endDateAndTime);

            var filterOptions = vm.signalTrendsService.getOptions();
            var baselineType = vm.trendsConstants.Filters.baselineAverage;

            var baselineStartDate = null;
            var baselineEndDate = null;

            if (filterOptions.filterState == vm.trendsConstants.Filters.baselineAverage) {
                baselineType = filterOptions.filterState;
            }
            else if (filterOptions.filterState == vm.trendsConstants.Filters.baselineDate) {
                baselineType = filterOptions.filterState;
                baselineStartDate = filterOptions.baselineStartDate.toLocaleString();
                baselineEndDate = filterOptions.baselineEndDate.toLocaleString();
            }

            //focus time frame and today time frame do not send "TimeOption". Send "StartToEnd" for timeOption
            if (vm.timeOptions.timeOption == "TimePeriod" || vm.timeOptions.currentFilter.selectedTodTemplate == "CT") {
                //commenting this out for now
                //implement AM/PM Peak or midday logic
                startHour = start.getHours();
                startMinute = start.getMinutes();
                endHour = end.getHours();
                endMinute = end.getMinutes();
                vm.timeOptions.timeOption = "TimePeriod";
                vm.searchBarService.setTimeOptionsNoNotify(vm.timeOptions);
            }

            //set the day of week list
            if (vm.timeOptions.daysOfWeekList) {
                daysOfWeek = vm.timeOptions.daysOfWeekList;
            }
            var aggregationTypesToQuery = [...new Set(vm.cards.map(x => x.aggregationType))];

            //Note: Server will return hourly and daily bin data regardless of what is passed in. We can keep track of bin still
            vm.currentBin = vm.timeOptions.bin;
            vm.loading = true;
            var opts = vm.signalTrendsService.createOptionsObject(vm.signal.signalID, aggregationTypesToQuery, start.toLocaleString(),
                end.toLocaleString(), startHour, startMinute, endHour, endMinute, daysOfWeek, vm.timeOptions.timeOption,
                vm.currentBin, baselineType, baselineStartDate, baselineEndDate, vm.includeHourlyData());
            signalTrendsService.getMetricForSignal(opts)
                .then(function (res) {
                    vm.chartServerError = false;
                    if (!res || !res.data) {
                        vm.chartServerError = true;
                        return;
                    }

                    let fullDataSet = res.data;
                    //go through each card in array and match with data from server
                    //do any pre-processing of the data that is needed, then pass into each card
                    //for display
                    for (let i = 0; i < vm.cards.length; i++) {
                        let thisCard = vm.cards[i];
                        let cardDataSet = fullDataSet[thisCard.aggregationType];
                        vm.dataInsights = vm.insightsLoaded ? insightsService.getInsights("Other").insightGroups[0] : null; // talk with team 

                        if(vm.insightsLoaded && thisCard.aggregationType == "volumes"){
                            if(cardDataSet.dailyBins.length > 0){
                                let res = findInsightsInData(cardDataSet.dailyBins,vm.dataInsights,true);
                                cardDataSet.dailyBinsWithInsights = res;
                            }
                            if(cardDataSet.hourlyBins.length > 0){
                                let res = findInsightsInData(cardDataSet.hourlyBins,vm.dataInsights,false);
                                cardDataSet.hourlyBinsWithInsights = res;
                            }
                        }
                        else if(vm.insightsLoaded && thisCard.aggregationType != "volumes" ) {
                            if(cardDataSet.dailyBins.length > 0){
                                let res = trackInsights(cardDataSet.dailyBins,vm.trackDailyInsightsIndexes);
                                cardDataSet.dailyBinsWithInsights = res;
                            }
                            if(cardDataSet.hourlyBins.length > 0){
                                let res = trackInsights(cardDataSet.hourlyBins,vm.trackHourlyInsightsIndexes);
                                cardDataSet.hourlyBinsWithInsights = res;
                            }
                        
                        }
                        if (!cardDataSet) continue;

                        if (thisCard.api && thisCard.api.update)
                            thisCard.api.update(cardDataSet);

                    }
                    vm.loading = false;
                })
                .catch(function (error) {
                    if (vm) {
                        vm.loading = false;
                        vm.chartServerError = true;
                    }

                });
        }

        function trackInsights(data,indexes){

            let res = [];

            data.forEach(function(elem,index){

                let missingData = false;
                let noData = false;
                let unrelabileData = false;
                if(indexes.some(x=> x == index)){
                    noData = true;
                }

                res.push({ date: elem.start, value:elem.value,
                    dataInsights: {
                        missingData: missingData,
                        noData: noData,
                        unrelabileData: unrelabileData
                    }  
                });

            });
            return res;
        }

        function findInsightsInData(data,dataInsights,isDaily) {
            var res = [];
            data.forEach(function(elem,index){

                let date = new Date(elem.start.toLocaleString());
                //make sure that end date is not next day but the same day but 23:59
                let e = new Date(elem.end.toLocaleString()).getTime() - 60000;
                let endDate = new Date(e);
                let missingData = false;
                let noData = false;
                let unrelabileData = false;

                if(dataInsights != undefined){
                    var noDataIns = dataInsights.insightTypes.filter(x=> x.insightTypeID == 11)[0];
                    var missingDataIns = dataInsights.insightTypes.filter(x=> x.insightTypeID == 10)[0];
                    
                    noDataIns.insights.forEach(function (ins) {
                        ins.occurrences.forEach(function (o){
                            let oStart = new Date(o.start);                        
                            let oEnd = new Date(o.end);
                            if(date >= oStart && endDate <= oEnd && elem.value == 0){ 
                                noData = true;
                                isDaily ? vm.trackDailyInsightsIndexes.push(index) : vm.trackHourlyInsightsIndexes.push(index);
                            }
                        });
                    });

                    missingDataIns.insights.forEach(function (ins) {
                        ins.occurrences.forEach(function (o){
                            let oStart = new Date(o.start);                        
                            let oEnd = new Date(o.end);
                            if(oStart.getDay() != oEnd.getDay() && date >= oStart && endDate <= oEnd && elem.value == 0) {
                                noData = true;
                                isDaily ? vm.trackDailyInsightsIndexes.push(index) : vm.trackHourlyInsightsIndexes.push(index);
                            }                       
                            // if(vm.isDaily && date>=oStart && endDate<=oEnd) missingData = true;
							// if(!vm.isDaily && date>=oStart && endDate<=oEnd) missingdata = true;                            
                        });
                    });
                }

                res.push({ date: elem.start, value:elem.value,
                        dataInsights: {
                            missingData: missingData,
                            noData: noData,
                            unrelabileData: unrelabileData
                        }  
                    });

            });
            return res;            
        }

        function includeHourlyData() {
            var res = false;
            var options = vm.searchBarService.getSearchOptions();
            var differenceInTime = options.timeOptions.currentFilter.endDateAndTime.getTime() - options.timeOptions.currentFilter.startDateAndTime.getTime();
            var differenceInDays = differenceInTime / (1000 * 3600 * 24);

            if (differenceInDays < 62) {
                res = true;
            }
            return res;
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.signal = {
                signalID: options.signal.signalID,
                description: options.signal.description,
                selectedApproaches: options.signal.selectedApproaches,
            };
            vm.timeOptions = options.timeOptions;
        };
    }
})();