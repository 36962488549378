import * as TMFMENU from 'raw-loader!./timeframe-menu.html';
(function () {
    angular
        .module('app.spm.core')
        .directive('timeframeMenu', timeframeMenuDirective)
        .controller('timeframeMenuCtrl', timeframeMenuCtrl);


    function timeframeMenuDirective() {
        return {
            scope: {
                autoConfirm: '=autoConfirm',
                ngDisabled: '=ngDisabled',
                showTemplate: '=',
                placeholder: '@',
                isDisabledDate: '&',
                localizationMap: '=?',
                customTemplates: '=?',
                disableTemplates: '@',
                mdOnSelect: '&',
                onePanel: '=?',
                format: '=?',
                firstDayOfWeek: '@',
                onTimeChange: '&',
                time: '=',
                api: '=',
            },
            link: function (scope, element, attributes, ctrl) {
                scope.ngModel = {};

                angular.extend(scope.api, {
                    update: function () {
                    },
                    openMenu: function () {
                        scope.ngDisabled = false;
                        scope.openMenu();
                        // angular.element('#md-menu-open-span').trigger('click');
                    }
                });
            },
            controller: 'timeframeMenuCtrl as vm',
            template: ['<md-menu md-offset="0 -150" ng-disabled="ngDisabled">',
                '<span id="md-menu-open-span" layout="row" class="md-select-value" ng-click="!ngDisabled && openMenu($mdOpenMenu, $event)">',
                '<md-tooltip >{{vm.getTooltipText()}}</md-tooltip>',
                '<md-icon md-font-icon="icon-calendar" class="s28 icon-calendar" style="margin-right: 10px; margin-left: 10px;"></md-icon>',
                '<div layout="column" flex="49" class="ph-8 mb-6">',
                '<div layout="row">{{vm.selectedDateTextParts[0]}}</div>',
                '</div>',
                '<div layout="column" flex="2"  class="ph-8 mb-6">',
                '-',
                '</div>',
                '<div layout="column" flex="49"  class="ph-8 mb-6">',
                '<div layout="row">{{vm.selectedDateTextParts[1]}}</div>',
                '</div>',
                '<span class="md-select-icon"></span>',
                '</span>',
                '<md-menu-content ms-scroll class="md-custom-menu-content" layout="column" flex style="max-height: none!important; height: auto!important; padding: 0!important; overflow-x: hidden;">',
                '    <timeframe-picker ms-scroll  first-day-of-week="firstDayOfWeek" ',
                '     date-start="ngModel.dateStart" ',
                '     date-end="ngModel.dateEnd" ',
                '     api="ngModel.api" ',
                '     selected-template="ngModel.selectedTemplate" ',
                '     selected-tod-template="ngModel.selectedTodTemplate" ',
                '     selected-weekdays="ngModel.selectedWeekdays" ',
                '     show-template="showTemplate" ',
                '     localization-map="localizationMap" ',
                '     custom-templates="customTemplates" ',
                '     disable-templates="{{disableTemplates}}" ',
                '     is-disabled-date="isDisabledDate({ $date: $date })" ',
                '     one-panel="onePanel" ',
                '     apply-callback="vm.apply()" ',
                '     format="format"></timeframe-picker> ',
                '<p ng-if="!autoConfirm" class="pr-20 " layout="row" layout-align="end center">',
                '<md-button class="md-raised" ng-click="vm.clear()">{{vm.getLocalizationVal("Cancel")}}</md-button>',
                '<md-button ng-disabled="vm.dirtyTimes" class="md-raised md-accent" ng-click="vm.apply()">{{vm.getLocalizationVal("Apply")}}</md-button>',
                '</p>',
                '</md-menu-content>',
                '</md-menu>'].join(''),

        };
    }
    function timeframeMenuCtrl($rootScope, $scope, $mdMenu, $timeout, searchBarService) {
        var vm = this;
        vm.selectedDateText = "";
        vm.searchBarService = searchBarService;
        vm.setSelectedDateText = setSelectedDateText;
        vm.selectedDateTextParts = [];
        vm.setSelectedDateTextParts = setSelectedDateTextParts;
        vm.init = init;
        vm.getTooltipText = getTooltipText;
        vm.apply = apply;
        vm.clear = clear;
        vm.getLocalizationVal = getLocalizationVal;
        vm.setupSearchBar = setupSearchBar;
        vm.dirtyTimes = false;
        vm.checkForValidTimes = checkForValidTimes;

        $scope.$on('destroy', function () {
            $rootScope.$off("mdpTimePickerUpdated", vm.checkForValidTimes);
        });
        $rootScope.$on("mdpTimePickerUpdated", vm.checkForValidTimes);

        vm.setupSearchBar();
        vm.init();


        function checkForValidTimes() {
            if (!$scope.ngModel || !$scope.ngModel.dateStart || !$scope.ngModel.dateEnd)
                return;
            //add 30 minutes to the start event to see if it's greater than end
            //if so, then disable apply button
            var newStart = new Date($scope.ngModel.dateStart.getTime() + 30 * 60000);
            if (newStart > $scope.ngModel.dateEnd) {
                vm.dirtyTimes = true;
            }
            else {
                vm.dirtyTimes = false;
            }
        }

        function getTooltipText() {
            var options = vm.searchBarService.getSearchOptions();
            if (options) {
                var timeOptions = options.timeOptions;
                vm.setSelectedDateText(timeOptions.currentFilter.startDateAndTime, timeOptions.currentFilter.endDateAndTime);
            }
            return vm.selectedDateText;

        }
        function apply() {
            //update our saved values in local storage, trigger update events then hide menu
            var options = vm.searchBarService.getSearchOptions();
            var timeOptions = options.timeOptions;

            timeOptions.currentFilter.startDateAndTime = $scope.ngModel.dateStart;
            timeOptions.currentFilter.endDateAndTime = $scope.ngModel.dateEnd;
            timeOptions.currentFilter.selectedDateTemplate = $scope.ngModel.selectedTemplate;
            timeOptions.currentFilter.selectedTodTemplate = $scope.ngModel.selectedTodTemplate;
            timeOptions.daysOfWeekList = $scope.ngModel.selectedWeekdays.filter(x => x.isSelected).map(y => y.value);

            //if we are filtering by week day we need to set the timeoption to TimePeriod
            //otherwise, we use StartToEnd
            if ($scope.ngModel.selectedWeekdays.filter(x => !x.isSelected).length > 0)
                timeOptions.timeOption = "TimePeriod";
            else
                timeOptions.timeOption = "StartToEnd";

            if ($scope.ngModel.selectedTodTemplate == "AM" || $scope.ngModel.selectedTodTemplate == "PM" || $scope.ngModel.selectedTodTemplate == "MD")
                timeOptions.timeOption = "TimePeriod";
                
            searchBarService.setTimeOptions(timeOptions);

            //this will update our text on the toolbar..
            vm.setSelectedDateTextParts(timeOptions.currentFilter.startDateAndTime, timeOptions.currentFilter.endDateAndTime);
            vm.setSelectedDateText(timeOptions.currentFilter.startDateAndTime, timeOptions.currentFilter.endDateAndTime);
            $mdMenu.hide();
        }

        $scope.openMenu = function ($mdOpenMenu, ev) {
            vm.init();
            if ($scope.ngModel && $scope.ngModel.api && $scope.ngModel.api.init) {
                $scope.ngModel.api.init();
            }
            if ($mdMenu && $mdMenu.open) {
                $mdMenu.open(ev)
            }
            else {
                $mdOpenMenu(ev)
            }
        }

        function clear() {
            $mdMenu.hide();
        }

        function setupSearchBar() {
            //set options for the search bar
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "configuration":
                        vm.init();
                        break;
                }
            });
        }

        function getLocalizationVal(val) {
            var ret = null;
            if ($scope.ngModel && $scope.ngModel.localizationMap != null && $scope.ngModel.localizationMap[val] != null) {
                ret = $scope.ngModel.localizationMap[val];
            } else {
                ret = val;
            }
            return ret;
        }


        function init() {
            vm.dirtyTimes = false;
            var options = vm.searchBarService.getSearchOptions();
            var timeOptions = options.timeOptions;
            //check time
            vm.setSelectedDateTextParts(timeOptions.currentFilter.startDateAndTime, timeOptions.currentFilter.endDateAndTime);
            vm.setSelectedDateText(timeOptions.currentFilter.startDateAndTime, timeOptions.currentFilter.endDateAndTime);
        }

        function setSelectedDateText(start, end) {
            var options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };

            var returnStr = new Date(start).toLocaleString("en-US", options) + " to ";
            returnStr += new Date(end).toLocaleString("en-US", options);
            vm.selectedDateText = returnStr;
        }

        function setSelectedDateTextParts(start, end) {
            var res = [];
            var options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };

            res.push(new Date(start).toLocaleString("en-US", options));
            res.push(new Date(end).toLocaleString("en-US", options));
            vm.selectedDateTextParts = res;
        }
    }
}());
