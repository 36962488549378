(function () {
    "use strict";

    angular.module("app.spm.core")
        .factory("signalsResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            var signalApiPath = environmentConfig.apiUrl + "/api/signals/";
            var detApiPath = environmentConfig.apiUrl + "/api/detectors/";
            var apprApiPath = environmentConfig.apiUrl + "/api/approaches/";
            var automatedConfigApiPath = environmentConfig.apiUrl + "/api/automatedConfiguration/";

            return {
                signals: $resource(signalApiPath, {}, {
                    query: { method: "GET", url: signalApiPath + "query?", cache: false, },
                    getAllSignals: { method: "GET" },
                    getAllSignalsWithApproaches: { method: "GET", url: signalApiPath + "withApproaches", cache: false },
                    getSignalById: { method: "GET", url: signalApiPath + ":id", cache: false },
                    update: { method: 'PUT' },
                    create: { method: "POST" },
                    remove: { method: "DELETE" },
                    getLatency: { method: "GET", url: signalApiPath + "latency" },

                }),
                detectors: $resource(detApiPath, {}, {
                    query: { method: "GET", cache: false },
                    update: { method: 'PUT' },
                    create: { method: "POST" },
                    get: { method: "GET" },
                    remove: { method: "DELETE" },
                }),
                approaches: $resource(apprApiPath, {}, {
                    query: {
                        method: "GET",
                        cache: false
                    },
                    update: { method: 'PUT' },
                    create: { method: "POST" },
                    get: { method: "GET" },
                    remove: { method: "DELETE" }
                }),
                automatedConfiguration: $resource(automatedConfigApiPath, {}, {
                    getAutomatedConfigurationResults: { method: "GET", url: automatedConfigApiPath + "getAutomatedConfigurationResultsWithStatus?", cache: false },
                }),
            };
        }]);
}());
