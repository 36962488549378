﻿(function () {
    'use strict';

    angular.module('app.spm.core')
    .directive('signalContextMenu', signalContextMenu);

    /** @ngInject */
    function signalContextMenu($mdMenu) {
        function link(scope) {
            //fix this
        //    //scope.openSplitMonitor = scope.vm.openSplitMonitor;
        //    //scope.openSplitMonitorLocal = function () {
        //    //    var test = 0;
        //    //    scope.openSplitMonitor();
        //    //};
        //    //scope.openPhaseTerminationLocal = function () {
        //    //    var test = 0;

        //    //};
        //    //scope.openSignalEditLocal = function () {
        //    //    var test = 0;
        //    //};
        //    //var splitBtn = element.find('#btn-open-split-monitor');
        //    //var phaseBtn = element.find('#btn-open-phase-term');
        //    //var signalBtn = element.find('#btn-open-signal-edit');

        //    //var splitMethod = $parse(attrs.openSplitMonitor);
        //    //if (splitMethod) {
        //    //    splitBtn.addEventListener('click', function (e) {
        //    //        splitMethod(scope);
        //    //    });
        //    //    splitBtn.addEventListener('click', function (e) {
        //    //        splitMethod(scope);
        //    //    });

        //    //}
        }

        return {
            restrict: 'AE',
            scope: {
                signalID: '=',
                showSlack: '<',
                slackMessage: '&'
            },
            link: link,
            controller: function ($scope) {
                $scope.openMenu = openMenu;
                $scope.openSplitMonitor = openSplitMonitor;
                $scope.openPhaseTermination = openPhaseTermination;
                $scope.openSignalEdit = openSignalEdit;
                $scope.pushToSlack = pushToSlack;

                function openMenu ($mdMenu, ev) {
                    $mdMenu.open(ev);
                };

                function openSplitMonitor() {
                    var test = 0;
                }

                function openPhaseTermination() {
                    var test = 0;
                }

                function openSignalEdit() {
                    var test = 0;
                }
            },
            templateUrl: 'app/main/spm/core/directives/menus/signal-context-menu.html',
        };
    }
})();