﻿import * as SIGNALEVENTS from 'raw-loader!./view/signal-events.html'

(function () {
    angular
        .module('app.spm.signal-events', [])
        .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider) {
        $stateProvider
            .state('app.spm.signal-events', {
                url: '/signal-events',
                views: {
                    'content@app': {
                        template: SIGNALEVENTS,
                        controller: 'signalEventsController as vm'
                    }
                }
            });
        // Translation
        $translatePartialLoaderProvider.addPart('signal-events');
    }
}());