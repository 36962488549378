import './split-monitor/split-monitor-charts.directive';
import './split-monitor/split-monitor-charts.controller';
import './split-monitor/split-monitor-stacked.directive';
import './split-monitor/split-monitor-stacked.controller';
import './split-monitor/split-monitor.directive';
import './split-monitor/split-monitor.controller';
import './phase-terminations/phase-termination.directive';
import './phase-terminations/phase-termination.controller';
import './phase-terminations/phase-term-count.directive';
import './phase-terminations/phase-term-count.controller';
import './tmc-chart/tmc.directive';
import './tmc-chart/tmc.controller';
import './aor-chart/aor-chart.controller';
import './aor-chart/aor-chart.directive';
import './approach-delay/approach-delay.controller';
import './approach-delay/approach-delay.directive';
import './approach-volume/approach-volume.controller';
import './approach-volume/approach-volume.directive';
import './custom-event-charts/custom-event.controller';
import './custom-event-charts/custom-event.directive';
import './custom-event-charts/custom-histogram.controller';
import './custom-event-charts/custom-histogram.directive';
import './pcd-chart/pcd-canvas.controller';
import './pcd-chart/pcd-canvas.directive';
import './pcd-chart/pcd-options.controller';
import './yellow-red-chart/yellow-red-canvas.directive';
import './yellow-red-chart/yellow-red-canvas.controller';
import './yellow-red-chart/yellow-red-options.controller';
import './purdue-split-failure/split-failure.directive';
import './purdue-split-failure/split-failure.controller';
import './ped-preemption-charts/ped-delay.controller';
import './ped-preemption-charts/ped-delay.directive';
import './ped-preemption-charts/preemption-details.controller';
import './ped-preemption-charts/preemption-details.directive';
import './queue-length/queue-length.directive';
import './queue-length/queue-length.controller';