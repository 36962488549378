(function () {

    'use strict';

    angular
        .module('app.spm.core')
        .service('newWindowService', newWindowService);

    // This service will be responsible for launching new windows and handling passing parameters
    //into new window to allow for proper state management and authentication
    function newWindowService($state, $window) {
        
        //this function will accept inputs of state, time config and signal
        //signal is the signal that will be focused to in the new window
        //time config is the selected time for the new window
        function openNewTabTimeAndSignal(state, timeConfig, signal, params){
            let newUrl = "https://" + $window.location.host + '/spm-open-new-window';
            let obj = {
                state: state,
                signal: signal,
                timeConfig: timeConfig,
                inputParameters: params
            }
            localStorage.setItem('__new_window_open_parameters', JSON.stringify(obj));
            $window.open(newUrl, '_blank');
        }

        //this function will basically open exactly what the user is looking at in a new window
        function openCurrentInNewTab(params){
            let newUrl = "https://" + $window.location.host + '/spm-open-new-window';
            let obj = {
                state: $state.current.name,
                inputParameters: params
            }
            localStorage.setItem('__new_window_open_parameters', JSON.stringify(obj));
            $window.open(newUrl, '_blank');
        }

        return {
            openCurrentInNewTab: openCurrentInNewTab,
            openNewTabTimeAndSignal: openNewTabTimeAndSignal
        }
    }
})();
