(function () {
    "use strict";

    angular.module("app.spm.dashboards.user-settings")
        .factory("userSettingsResource", ["$resource", "environmentConfig", "$cacheFactory", function ($resource, environmentConfig, $cacheFactory) {
            var userSettingsUrl = environmentConfig.apiUrl + "/api/userSettings/";
            return $resource(userSettingsUrl, {}, {
                getUserSettings: { method: "GET", url: userSettingsUrl + "getUserSettings" },
                setUserSettings: {method: "POST" , url: userSettingsUrl + "setUserSettings"},
                setReportSettings: {method: "POST" , url: userSettingsUrl + "setReportSettings"}
            });
        }]);
}()); 