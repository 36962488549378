﻿(function () {
    'use strict';

    angular.module('app.spm.charts')
        .directive('canvasChart', canvasChartDirective);

    /** @ngInject */
    function canvasChartDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'canvasChartController as vm',
            templateUrl: function (elem, attrs) {
                return 'assets/templates/canvas-chart.html';
            },
            scope: {
                plans: '=',
                type: '@',
                options: '=',
                labels: '=',
                onApiInit: '&',
                onDestroy: '&',
                showZoomControls: '<',
                zoomEnabled: '='
            },
            link: function (scope, element, attrs) {
                scope.api = {};

                if (scope.api) {
                    angular.extend(scope.api, {
                        render: function (labels, datasets, options) {
                            return scope.render(labels, datasets, options);
                        },
                        update: function (labels, datasets) {
                            scope.update(labels, datasets);
                        },
                        getChart: function () {
                            return scope.getChart();
                        }

                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();