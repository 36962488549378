import * as WAZETT from 'raw-loader!./waze-travel-time.html';

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.waze-travel-time',
            [
            ]
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.waze-travel-time', {
                url: '/waze-travel-times',
                views: {
                    'content@app': {
                        template: WAZETT,
                        controller: 'wazeTravelTimeController as vm'
                    }
                }
            });
        //  $translatePartialLoaderProvider.addPart('app/main/spm/dashboards/travel-time-historical');
    }
})();