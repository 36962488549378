﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("pedDelayController", pedDelayController);

    function pedDelayController($state, $scope, $attrs, $rootScope, $element, environmentConfig, chartsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 3;
        vm.chartServerError = false;
        vm.metricTypeId = 3;

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input != "{}") {
                    var jsonData = JSON.stringify(input, null, 4);
                    vm.pedDelayWidget.processPedDelay(JSON.parse(jsonData, JSON.dateParser).pedPhases);
                    $scope.loading = false;
                }
            }
        }


        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });


        vm.pedDelayWidget = {
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.pedDelayWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            addPlanLabels: function (primaryPlans, secondaryPlans, primaryLabel, secondaryLabel) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        var planActuations = "";
                        if (plan && plan.pedActuations) {
                            planActuations = "PA: " + plan.pedActuations.toLocaleString();
                        }
                        else {
                            planActuations = "PA: 0";
                        }

                        var averageDelay = "";
                        if (plan && plan.avgDelay > 0) {
                            averageDelay = "AD: " + plan.avgDelay.toFixed(2);
                        }
                        else {
                            averageDelay += "AD: 0"
                        }
                        plan.labels.push(planText, planActuations, averageDelay);
                        plansCopy[i] = plan;
                    }
                }
                return plansCopy;
            },
            //logic for taking server events and manipulating into format the chart is expecting
            processPedDelay: function (rawData) {
                var data = [];
                vm.phaseCount = 0;
                var searchObj = searchBarService.getSearchOptions();

                //loop through each phase item
                rawData.forEach(function (phaseItem) {
                    var planList = phaseItem.plans;
                    vm.phaseCount++;
                    var thisPhaseData = {
                        key: "Delay",
                        label: "Delay",
                        data: [],
                        bar: true,
                        type: "bar",
                        barThickness: 6,
                        backgroundColor: '#61B861',
                        borderColor: '#61B861',
                        yAxisID: '1'
                    };
                    var minDelay = 0;
                    var maxDelay = 0;
                    var totalDelay = 0;
                    //loop through each plan for each phase
                    phaseItem.cycles.forEach(function (cycleItem) {
                        //get cycle information for each plan
                        //begin walk will be the X axis and delay the Y
                        var beginWalk = new Date(cycleItem.beginWalk);
                        var callReg = new Date(cycleItem.callRegistered);
                        var delay = cycleItem.delay;

                        if (minDelay == 0)
                            minDelay = delay;
                        else if (minDelay > delay) {
                            minDelay = delay;
                        }
                        if (maxDelay == 0) {
                            maxDelay = delay;
                        }
                        else if (maxDelay < delay) {
                            maxDelay = delay;
                        }
                        totalDelay += delay;
                        thisPhaseData.data.push({
                            x: beginWalk,
                            y: delay,
                        });
                    });

                    var actuationCount = thisPhaseData.data.length;

                    if (actuationCount > 0) {
                        var avgDelay = (totalDelay / actuationCount).toFixed(2);
                        //get the chart object and do some setup for Xaxis and yaxis
                        thisPhaseData.data = thisPhaseData.data.concat(vm.fillBlanks(thisPhaseData));
                        //setup text for chart title
                        if (!$scope.signal.description) {
                            $scope.signal.description = getSignalDescription($scope.signal.customID, $scope.signal.primaryName, $scope.signal.secondaryName);
                        }
                        //add our chart with data to chart array which our view is binding too
                        data.push({
                            title: "Pedestrian Delay for Phase " + phaseItem.phaseNumber + " - " + $scope.signal.description,
                            subLine1: "Ped Actuations(PA): " + actuationCount,
                            subLine2: "Min Delay: " + minDelay + " (s) / Max Delay: " + maxDelay + " (s) / Avg Delay(AD):  " + avgDelay + " (s)",
                            plans: vm.pedDelayWidget.addPlanLabels(planList),
                            dataset: [thisPhaseData],
                            api: {},
                            planColor: "#000",
                            plansHidden: false,
                            onDestroy: function () {
                                this.api = {};
                            },
                            onApiInit: function (apiObj) {
                                this.api = apiObj;
                                this.render(apiObj);
                            },
                            render: function (apiObj) {
                                apiObj.render(undefined, this.dataset, this.chartOptions);
                                vm.chart =
                                    vm.chartRendering = false;
                                this.isRendered = true;
                            },
                            hidePlanLabels: function (e, chart) {
                                chart.plansHidden = !chart.plansHidden;
                                chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                                Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                                this.plans.visible = false;
                                if (chart.plansHidden) {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels2 = plan.labels;
                                        plan.labels = [];
                                    });
                                    chart.planColor = "#fff";
                                } else {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels = plan.labels2;
                                    });
                                    chart.planColor = "#000";
                                }
                                chart.api.update();
                            },
                            isRendered: false,
                            flex: 50,
                            isDataAvailable: actuationCount > 0,
                            chartOptions: {
                                hidePlans: false,
                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        type: 'time',
                                        id: 'x-axis-0',
                                        // time: {
                                        //     unit: 'hour'
                                        // },
                                        gridLines: {
                                            display: false
                                        },
                                        ticks: {
                                            autoSkip: true,
                                            autoSkipPadding: 50,
                                            minRotation: 0,
                                            maxRotation: 0,
                                        }
                                    },
                                    ],
                                    yAxes: [{
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Delay (seconds)',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                        id: '1',
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        // stacked: true,
                                        // type: 'linear',
                                        position: 'left',

                                    }
                                    ]
                                },
                                legend: {
                                    display: true,
                                    align: 'end',
                                },
                                hover: {
                                    mode: 'nearest'
                                },
                                tooltips: {
                                    mode: 'nearest'
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    zoom: {
                                        pan: {
                                            enabled: false,
                                            mode: 'x',

                                            rangeMin: {
                                                x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        },
                                        zoom: {
                                            enabled: true,
                                            drag: true,
                                            speed: 0.1,
                                            mode: 'x',

                                            rangeMin: {
                                                x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        }
                                    }
                                },
                            }
                        });
                    }
                    else {
                        //phase item exists w/events but no ped actuations
                        //This could possibly be due to ped recall. IN future we may want to detect this and display something to user

                        //setup text for chart title
                        if (!$scope.signal.description) {
                            $scope.signal.description = getSignalDescription($scope.signal.customID, $scope.signal.primaryName, $scope.signal.secondaryName); 
                        }
                        //add our chart with data to chart array which our view is binding too
                        data.push({
                            title: "Pedestrian Delay for Phase " + phaseItem.phaseNumber + " - " + $scope.signal.description,
                            dataset: [thisPhaseData],
                            flex: 50,
                            planColor: "#000",
                            plansHidden: false,
                            plans: vm.pedDelayWidget.addPlanLabels(planList),
                            isDataAvailable: false,
                            api: {},
                            onDestroy: function () {
                                this.api = {};
                            },
                            onApiInit: function (apiObj) {
                                this.api = apiObj;
                                this.render(apiObj);
                            },
                            render: function (apiObj) {
                                apiObj.render(undefined, this.dataset, this.chartOptions);
                                vm.chart =
                                    vm.chartRendering = false;
                                this.isRendered = true;
                            },
                            hidePlanLabels: function (e, chart) {
                                chart.plansHidden = !chart.plansHidden;
                                chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                                Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                                this.plans.visible = false;
                                if (chart.plansHidden) {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels2 = plan.labels;
                                        plan.labels = [];
                                    });
                                    chart.planColor = "#fff";
                                } else {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels = plan.labels2;
                                    });
                                    chart.planColor = "#000";
                                }
                                chart.api.update();
                            },
                            isRendered: false,
                            chartOptions: {
                                hidePlans: false,
                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        type: 'time',
                                        id: 'x-axis-0',
                                        // time: {
                                        //     unit: 'hour'
                                        // },
                                        gridLines: {
                                            display: false
                                        },
                                        ticks: {
                                            autoSkip: true,
                                            autoSkipPadding: 50,
                                            minRotation: 0,
                                            maxRotation: 0,
                                            callback: function (value, index, values) {
                                                return moment(values[index].value).format('hh:mm A');;
                                            }
                                        }
                                    },
                                    ],
                                    yAxes: [{
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Delay (seconds)',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                        id: '1',
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        position: 'left',

                                    }
                                    ]
                                },
                                legend: {
                                    display: true,
                                    align: 'end',
                                },
                                hover: {
                                    mode: 'nearest'
                                },
                                tooltips: {
                                    mode: 'nearest'
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    zoom: {
                                        pan: {
                                            enabled: false,
                                            mode: 'x',

                                            rangeMin: {
                                                x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        },
                                        zoom: {
                                            enabled: true,
                                            drag: true,
                                            speed: 0.1,
                                            mode: 'x',

                                            rangeMin: {
                                                x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        }
                                    }
                                },
                            }
                        });
                    }
                });

                if (data.length <= 0) {
                    data.push({
                        title: "Pedestrian Delay",
                        dataset: [],
                        flex: 100,
                        isDataAvailable: false
                    });
                }
                vm.chartArray = data;
                vm.chartRendering = true;
            },
        }

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        //In order for our multichart data to be interpolated correctly, we need to have
        //values every minute or two minutes for the X axis. so this method will add blank values every 2 minutes so
        //the chart can be correctly interpolated
        vm.fillBlanks = function (data) {
            var options = searchBarService.getSearchOptions();
            var from = new Date(options.timeOptions.currentFilter.startDateAndTime);
            var until = new Date(options.timeOptions.currentFilter.endDateAndTime);

            var ts = from;
            var date = new Date(ts);
            var ret = [];

            while (ts <= until) {
                var point = data.data.find(function (elem) {
                    return elem.x == ts;
                })
                if (point) {
                    ret.push(point);
                } else {
                    ret.push({ x: new Date(ts), y: 0 });
                }
                date.setMinutes(date.getMinutes() + 2);
                ts = date;
            }

            return ret;
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }
        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    // var rawData = JSON.stringify(data, null, 4);
                    vm.pedDelayWidget.processPedDelay(data.pedPhases);
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
}());
