﻿import * as EVENTPLAYER from 'raw-loader!./event-player.html'

(function () {
    angular
        .module('app.spm.event-player', [])
        .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider) {
        $stateProvider
            .state('app.spm.event-player', {
                url: '/event-player',
                views: {
                    'content@app': {
                        template: EVENTPLAYER,
                        controller: 'eventPlayerController as vm'
                    }
                }
            });
        // Translation
        $translatePartialLoaderProvider.addPart('event-player');
    }
}());