﻿import * as HOURLYHEATMAP from 'raw-loader!./hourly-heatmap.html';

(function () {
    'use strict';

    angular.module('app.spm.charts')
        .directive('hourlyHeatmap', hourlyHeatmapDirective);

    /** @ngInject */
    function hourlyHeatmapDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            template: function (elem, attrs) {
                return HOURLYHEATMAP
            },
            controller: 'hourlyHeatmapController as vm',
            bindToController: {
                onDestroy: '&',
            },
            scope: {
                api: '=',
                onApiInit: '&',
            },
            link: function (scope, element, attrs) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        update: function (data, daysOfWeek, chartType, chartText, legendTitle, roundNumbers, widthOfCard, chartName, isSquareRoot, reverseColorScale, chartSubheader, baselineOptions, selectedLegend) {
                            scope.update(data, daysOfWeek, chartType, chartText,legendTitle, roundNumbers, widthOfCard, chartName, isSquareRoot, reverseColorScale, chartSubheader, baselineOptions, selectedLegend);
                        },

                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }              
            }
        };
    }
})();