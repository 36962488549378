(function () {
  "use strict";
  angular
    .module("app.spm.core")
    .controller("googleMapController", googleMapController);

  function googleMapController($scope, $element, $interval, $document, $timeout, $rootScope, newWindowService, googleMapService, searchBarService, signalService, breadcrumbNavigationService) {
    var vm = this;
    vm.searchBarService = searchBarService;
    vm.newWindowService = newWindowService;
    vm.isContextMenuPressed = false;
    const USA_BOUNDS = {
      north: 49.5904,
      south: 24.9493,
      west: -125.0011,
      east: -66.9326,
    };

    $scope.$on("$destroy", function () {
      if ($scope.isSessionStorage) {
        saveMapPreferences();
      }
      if (vm.mapLoadInterval)
        $interval.cancel(vm.mapLoadInterval);

      if (vm.cleanup) vm.cleanup();
      if (vm.api) {
        if (vm.api.calculateDirections) delete vm.api["calculateDirections"];
        if (vm.api.updateHeatMap) delete vm.api["updateHeatMap"];
      }
      //cleanup any click or event handlers we registered
      for (var i in vm.eventListeners) {
        google.maps.event.removeListener(vm.eventListeners[i]);
      }
      if (vm.mapWheelOnEvent)
        vm.mapWheelOnEvent.off();
      if (vm.infoWindowWheelEvent)
        vm.infoWindowWheelEvent.off();

      //remove any VM refs
      for (let member in vm) {
        vm[member] = null;
      }
      vm = null;
    });

    vm.savedZoomLevel = null;
    vm.savedTypeId = null;
    vm.savedCenter = null;
    vm.trafficState = null;
    vm.savedStreetView = null;
    vm.savedStreetCenter = null;

    // If there are things saved, assign them for further use / reuse them
    if (googleMapService.model.zoomLevel && googleMapService.model.mapTypeId && googleMapService.model.center) {
      if ($scope.isSessionStorage) {
        vm.savedZoomLevel = googleMapService.model.zoomLevel;
        vm.savedCenter = googleMapService.model.center;
        vm.trafficState = googleMapService.model.traffic;
        vm.savedTypeId = googleMapService.model.mapTypeId;
        vm.savedStreetView = googleMapService.model.streetView;
        vm.savedStreetCenter = googleMapService.model.streetCenter;
        $rootScope.$broadcast("restorestate"); //let everything know we need to restore state
        googleMapService.RestoreState();
        sessionStorage.restorestate = false;
      }
    }

    // save map preferences when going from one page to another
    function saveMapPreferences() {
      if ($scope.isSessionStorage) {
        if (vm.thePanorama.getVisible() && vm.contextSignal.signal) {
          googleMapService.model.center = new google.maps.LatLng(vm.contextSignal.signal.latitude, vm.contextSignal.signal.longitude);
        } else {
          googleMapService.model.center = vm.map.getCenter();
        }
        googleMapService.model.zoomLevel = vm.map.getZoom();
        googleMapService.model.streetCenter = vm.thePanorama.getPano();
        googleMapService.model.mapTypeId = vm.map.getMapTypeId();
        googleMapService.model.streetView = vm.thePanorama.getVisible();
        googleMapService.model.traffic = vm.trafficState;
        $rootScope.$broadcast("savestate");
        googleMapService.SaveState();
      }
    }

    //======== INIT VARIABLES ========// 
    vm.startMapLoad = startMapLoad;
    vm.isSessionStorage = false;
    vm.addHeatMapLayer = addHeatMapLayer;
    vm.drawDefaultMarkers = drawDefaultMarkers;
    vm.changeMarkerIcon = changeMarkerIcon;
    vm.showInfoBoxHeatMap = showInfoBoxHeatMap;
    vm.routeInfoBox = routeInfoBox;
    vm.wazeInfoBox = wazeInfoBox;
    vm.currentHeatMapLayer = undefined;
    vm.toggleTrafficLayer = toggleTrafficLayer;
    vm.trafficLayer = null;
    vm.calculateAndDisplayRoute = calculateAndDisplayRoute;
    vm.signalDragendCallback = $scope.signalDragendCallback;
    vm.isFullscreen = false;
    vm.setupSearchBar = setupSearchBar;
    vm.loadStateFromMenu = loadStateFromMenu;
    vm.callApiInit = callApiInit;
    vm.focusOnSignal = focusOnSignal;
    vm.setSearchOptions = setSearchOptions;
    vm.showSignals = showSignals;
    vm.defaultZoom = 11;
    vm.updateSignalsOnMap = updateSignalsOnMap;
    vm.showDirectionSignals = showDirectionSignals;
    vm.defaultMapType = "roadmap";
    vm.hideMarkersButton = hideMarkersButton;
    vm.initMap = initMap;
    vm.setDefaultLocation = setDefaultLocation;
    vm.previousMarker = null;
    vm.getMarker = getMarker;
    vm.closeSideMenu = closeSideMenu;
    vm.disableDefaultUI = $scope.disableDefaultButtons == true ? true : false;
    vm.MAP_MARKER = "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";
    vm.FULL_MARKER = "m12,2c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7zm-1.26316,7.81579c2.51474,0.73684 5.18421,-1.33053 5.18421,-2.71053s-5.30105,-0.60526 -3.92105,-0.60526s2.5,1.12 2.5,2.5s-14.5,-9 -2.60526,2.5z";
    // var getGoogleClusterInlineSvg = function (color) {
    //   var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');

    //   return ('data:image/svg+xml;base64,' + encoded);
    // };

    // var cluster_styles = [
    //   {
    //     width: 40,
    //     height: 40,
    //     url: getGoogleClusterInlineSvg('#A317A1'),
    //     textColor: 'white',
    //     textSize: 12
    //   },
    //   {
    //     width: 50,
    //     height: 50,
    //     url: getGoogleClusterInlineSvg('#A317A1'),
    //     textColor: 'white',
    //     textSize: 14
    //   },
    //   {
    //     width: 60,
    //     height: 60,
    //     url: getGoogleClusterInlineSvg('#A317A1'),
    //     textColor: 'white',
    //     textSize: 16
    //   }
    //   //up to 5
    // ];
    vm.markers = [];
    vm.purduePins = [];
    vm.addHoverBoxInfo = addHoverBoxInfo;
    vm.showContextMenu = showContextMenu;
    vm.setContextmenuPosition = setContextmenuPosition;
    vm.closeContextMenu = closeContextMenu;
    vm.contextSignal = {};
    vm.contextClosed = true;
    vm.sideMenuClosed = true;
    vm.disableMenuItem = disableMenuItem;
    vm.eventListeners = [];
    vm.streetView = true;
    vm.controlSize = 40;
    vm.majorDirection = majorDirection;
    vm.findApproachOfSignal = findApproachOfSignal;
    vm.isSelected = isSelected;
    vm.isSignalClicked = isSignalClicked;
    vm.signalToCorridor = signalToCorridor;
    vm.showOptionsPanel = showOptionsPanel;
    vm.oldLocation = [];
    vm.counter = 0;
    vm.highlightSelectedSignals = highlightSelectedSignals;
    vm.signalsToBeRemoved = [];
    vm.approach = [];
    vm.showRouteLines = showRouteLines;
    vm.wazeMarkers = [];
    vm.drawSignalConfig = drawSignalConfig;
    vm.dragInfoBox = dragInfoBox;
    vm.openInNewTab = openInNewTab;
    vm.showSideMenu = showSideMenu;
    //========================// 

    //======== MAP INITIALIZES HERE ========// 
    vm.startMapLoad();
    //======================================// 


    // signalService.subscribe($scope, function onChange(ev, options) {
    //   if (!vm)
    //     return;
    //   switch (options.type) {
    //     case "signalCreated":
    //       break;
    //     case "signalUpdated":
    //     case "signalRemoved":
    //     case "detectorCreated":
    //     case "detectorUpdated":
    //       if (vm.markers && vm.markers.length > 0) {
    //         vm.markers.forEach(function (marker) {
    //           marker.setMap(null);
    //         });
    //         vm.showSignals();
    //       }
    //       break;
    //   }
    // });

    //======== FUNCTIONS FOR MARKERS ========//
    function getMarker(id) {
      vm.gettingMarker = vm.markers.filter((mark) => {
        return mark.id === id;
      })[0];
    }

    function changeMarkerIcon(fColor, sColor, scaleSize, usePng) {
      var markerObj = {
        path: usePng ? null : vm.MAP_MARKER,
        url: usePng ? '../assets/images/spm/marker.png' : null,
        fillColor: fColor,
        strokeColor: sColor,
        scale: scaleSize,
        fillOpacity: 1,
        anchor: { x: 12, y: 24 },
      }
      return markerObj;
    }

    function drawDefaultMarkers(signal, scaleSize, fOpacity, sOpacity, latitude, longitude) {
      var dragMarker = false;
      if ($scope.draggableMarker) {
        dragMarker = true;
      }
      var marker = new google.maps.Marker({
        id: signal ? signal.signalID : undefined,
        draggable: dragMarker,
        icon: {
          url: signal ? '../assets/images/spm/marker.png' : null,
          path: signal ? null : vm.MAP_MARKER,
          fillColor: "#0676DC",
          strokeColor: "#fff",
          scale: scaleSize,
          fillOpacity: fOpacity,
          strokeOpacity: sOpacity,
          anchor: { x: 12, y: 24 },
        },
        position: { lat: latitude, lng: longitude },
        map: vm.map,
      });

      if ($scope.draggableMarker && $scope.editSignal) {
        vm.eventListeners.push(google.maps.event.addListener(marker, 'dragend', function (e) {
          signalService.getSignalById(signal.signalID).then(function (signal) {
            vm.curlat = signal.latitude;
            vm.curlng = signal.longitude;
          })
          var latLng = e.latLng;
          var currentLatitude = latLng.lat();
          var currentLongitude = latLng.lng();
          signal.latitude = currentLatitude;
          signal.longitude = currentLongitude;
          signalService.updateSignal(signal, null, true).then(function (saved) {
            if (!saved) {
              marker.setPosition(new google.maps.LatLng(vm.curlat, vm.curlng))
            }
          })
        }));
      }
      return marker;
    }
    //=======================================//

    //============== DRAW MARKERS ==============//
    function showSignals() {
      if ($scope.showAllSignals) {
        signalService.getAllSignalsWithApproaches().then(function (data) {
          if (!vm) return;
          // set bounds from all of the markers
          vm.bounds = new google.maps.LatLngBounds();

          // draw every marker in the forEach loop
          data.signals.forEach(function (signal) {
            var marker = null;
            // if (vm.thePanorama.getVisible()) {
            //   marker = vm.drawDefaultMarkers(signal, 5, 1, 1, signal.latitude, signal.longitude);
            // } else {
            marker = vm.drawDefaultMarkers(signal, 0.2, 1, 1, signal.latitude, signal.longitude);
            // }
            // open the InfoBox when hovering over marker
            vm.eventListeners.push(google.maps.event.addListener(marker, "mouseover", () => {
              vm.addHoverBoxInfo(marker, signal);
            }));

            // set the available charts for the specific marker
            // signal.availableCharts = signal.availableCharts.map(function (a) {
            //   if (a && a.chartName)
            //     return a.chartName;
            // });

            // set the signal description as signalName for the references in other files
            signal.description = signal.signalName;

            // set event listener for opening the ContexMenu
            vm.eventListeners.push(google.maps.event.addListener(marker, "click", (event) => {
              if (!$scope.hideContextMenu) {
                vm.showContextMenu(event, signal);
              }
              vm.isSignalClicked(signal, marker);
              vm.isSignalClickedCheck = false;
              vm.infoWindow.close(vm.map, marker);
            }));

            // extend the bounds with current marker in the forEach loop
            vm.bounds.extend(marker.position);
            vm.markers.push(marker);
          });
          // if(vm.markers.length > 250 && $scope.useClustering) {
          //   vm.markerCluster = new MarkerClusterer(vm.map, vm.markers,
          //     {
          //       maxZoom: 19, minimumClusterSize: 5,
          //       ignoreHidden: false,
          //       styles: cluster_styles,
          //     });
          // }
          vm.setSearchOptions();

          // if there is no signal, set the map view to bounds
          // if (!vm.savedZoomLevel && !vm.savedCenter) {
          //   vm.map.fitBounds(vm.bounds);
          // }

          vm.setupSearchBar();
        });
      } else if ($scope.showOnlySignalId) {
        signalService
          .getSignalById($scope.showOnlySignalId)
          .then(function (signal) {
            if (!vm) return;

            signal.description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);

            vm.setSearchOptions();
            vm.setupSearchBar();
            if (!$scope.hideSignalMarker) {
              vm.focusOnSignal(signal);
            }
          });
      }

    }
    //=========================================//

    function getSignalDescription(customId, primaryName, secondaryName) {

      var res = customId + ": " + primaryName;
      if (secondaryName != null && secondaryName != '') {
        res += " - " + secondaryName;
      }

      return res;
    }

    //============= FUNCTIONS FOR GETTING SIGNAL, SELECTING IT AND FOCUSING IT =============//
    function setSearchOptions() {
      var options = vm.searchBarService.getSearchOptions();
      if (options.signal) {
        vm.signal = {
          signalID: options.signal.signalID,
          description: options.signal.description,
          latitude: options.signal.latitude,
          longitude: options.signal.longitude,
        };
      } else {
        vm.signal = null;
      }
    }

    function setupSearchBar() {

      //whenever our searchbar has changed update the cards
      if (searchBarService.isFiltering()) {
        vm.setSearchOptions();
        if (vm.signal) {
          vm.focusOnSignal(vm.signal);
        } else {
          vm.map.fitBounds(vm.bounds);
        }
      }
      vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
        if (!vm) return;

        switch (changeType) {
          case "time":
            break;
          // when another signal is selected,
          // revert the previous to default marker icon
          case "signal":
            if (!vm.isContextMenuPressed) {
              if (vm.previousMarker) {
                vm.previousMarker.setIcon(vm.changeMarkerIcon("#0676DC", "#fff", 1.4, true))
              }
              if (searchBarService.isFiltering()) {
                vm.setSearchOptions();
                vm.focusOnSignal(vm.signal);
              }
            }
            break;
          case "configuration": {
            break;
          }
          case "state": {
            // when no signals are selected,
            // revert the previous to default marker icon
            vm.isFiltering = searchBarService.isFiltering();
            if (!vm.isFiltering) {
              if (vm.previousMarker) {
                vm.previousMarker.setIcon(vm.changeMarkerIcon("#0676DC", "#fff", 1.4, true))
              }

              if (vm.thePanorama.getVisible()) {
                vm.map.get("streetView").setOptions({
                  visible: false,
                });
              }
              vm.map.fitBounds(vm.bounds);
            }
            break;
          }
        }
      });

      // set the searchbar settings
      // don't show the date picker in the system map
      if (!$scope.showOnlySignalId) {
        var showSearchBar = true;
        if ($scope.corridorConfig) showSearchBar = false;
        vm.searchBarService.setSearchBarConfig(
          (vm.searchBarConfig = {
            //header information
            header: {
              show: showSearchBar,
              text: "Map Overview",
            },
            //search bar options
            showSearchBar: showSearchBar,
            searchType: "Signals",
            showCurrentFilterDates: false,
            helpJsonPropertyPath: "MAP_OVERVIEW." + "GENERAL_HELP",
            showHelp: true,
            timeFrameConfig: {
              enableWithoutFiltering: false,
              defaultDateTemplate: "TD",
              defaultTodTemplate: "FD",
              dateTemplateMinimumDays: 0,
              timeOptionForCustomTemplate: "StartToEnd",
              showWeekdayFilter: false,
              enableAdvancedTod: false,
              maxDayRange: 7,
            },
            //right-side more options menu
            moreOptionsMenu: {
              show: false,
              showBinConfig: false,
              skipStepsPerBin: true,
            },
          })
        );
      }

      vm.isFiltering = searchBarService.isFiltering();
    }


    // Focus the view on the selected signal and change its icon
    function focusOnSignal(signal) {
      if (!$scope.showOnlySignalId) {
        vm.getMarker(signal.signalID);

        if (vm.thePanorama.getVisible()) {
          vm.gettingMarker.setIcon(vm.changeMarkerIcon("#0254A5", "#fff", 5, false))
        } else {
          vm.gettingMarker.setIcon(vm.changeMarkerIcon("#0254A5", "#fff", 2.2, false))
        }
        vm.previousMarker = vm.gettingMarker;
      } else if (!$scope.hideSignalMarker) {
        vm.drawDefaultMarkers(signal, 1.4, 1, null, signal.latitude, signal.longitude);
      }
      if (signal) {
        // If in street-view, set the panorama's position
        // and center the map so when we get out the street-view
        // the map is positioned correctly
        if (vm.thePanorama.getVisible()) {
          var newPosition = new google.maps.LatLng({
            lat: vm.signal.latitude,
            lng: vm.signal.longitude,
          });
          vm.thePanorama.setPosition(newPosition);
        }
        vm.map.setCenter(
          new google.maps.LatLng(signal.latitude, signal.longitude)
        );
        if (!$scope.preventFocus) vm.map.setZoom(19);
      }
    }
    //=================================================================================//

    //============= INFO BOXES =============//
    function addHoverBoxInfo(marker, signal) {
      // Check if there are approaches, but no detectors
      var counter = 0;
      if ($scope.showAllSignals) {
        if (signal.approaches.length > 0) {
          for (var i = 0; i < signal.approaches.length; i++) {
            if (signal.approaches[i].detectors.length === 0) {
              counter = counter + 1;
            }
          }
        }
      }

      // Set content for the InfoBox
      var content = `<h3 style="padding-top: 0; margin-top: 0;"><strong>Signal: ${signal.customID}</strong></h3>
                    <p style="color:black";>${getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName)}</p>
                    <p style="color: black; margin-bottom: 0; padding-bottom: 0;">Controller type: ${signal.controllerType.description}</p>
                    ${$scope.showAllSignals && signal.approaches.length === 0 ? '<p style="color: black;">Approaches not configured</p>' : '<p style="display: none"></p>'}
                    ${$scope.showAllSignals && counter === signal.approaches.length ? '<p style="color: black;">Detectors not configured</p>' : '<p style="display: none"></p>'}`;

      vm.infoWindow = new google.maps.InfoWindow({
        content: content,
      });

      // Open the info window
      vm.infoWindow.open(vm.map, marker);

      // Close the info window on mouse out
      vm.eventListeners.push(google.maps.event.addListener(marker, "mouseout", function () {
        vm.infoWindow.close(vm.map, marker);
      }));
      vm.eventListeners.push(google.maps.event.addListener(marker, "click", function () {
        vm.infoWindow.close(vm.map, marker);
      }));

      vm.eventListeners.push(google.maps.event.addListener(marker, "zoom_changed", function () {
        vm.infoWindow.close(vm.map, marker);
      }));
      vm.infoWindowWheelEvent = angular.element($element.find("#googlemap")).on("wheel mousewheel DOMMouseScroll", function (e, marker) {
        vm.infoWindow.close(vm.map, marker);
      })
    }

    function dragInfoBox(marker) {

      // Set content for the InfoBox
      var content = `Drag&Drop to change the suggested position on the map.`;

      vm.dragInfoWindow = new google.maps.InfoWindow({
        content: content,
      });

      // Open the info window
      vm.dragInfoWindow.open(vm.map, marker);

      // Close the info window on mouse out
      vm.eventListeners.push(google.maps.event.addListener(marker, 'drag', function () {
        vm.dragInfoWindow.close(vm.map, marker)
      }));
    }

    function showInfoBoxHeatMap(loc, pin) {
      // //Create an infobox at the center of the map but don't show it.
      vm.heatMapInfoBox = new google.maps.InfoWindow({
        content: `<div>
                    <h3 style="padding-top: 0; margin-top: 0;">${loc.customId}</h3>
                    <p style="padding-bottom: 0; margin-bottom: 0;">${loc.label}</p>
                  </div>`,
      });
      vm.heatMapInfoBox.open(vm.map, pin);
      vm.eventListeners.push(google.maps.event.addListener(pin, "mouseout", function () {
        vm.heatMapInfoBox.close(vm.map, pin);
      }));
    }

    function wazeInfoBox(signal, pin) {
      vm.wazeInfoWindow = new google.maps.InfoWindow({
        content: `<div>
                    <h3 style="padding-top: 0; margin-top: 0;">Signal: ${signal.customID}</h3>
                    <p style="padding-bottom: 0; margin-bottom: 0;">${signal.description}</p>
                  </div>`
      })
      vm.wazeInfoWindow.open(vm.map, pin);
      vm.eventListeners.push(google.maps.event.addListener(pin, "mouseout", function () {
        vm.wazeInfoWindow.close(vm.map, pin);
      }));
    }

    function routeInfoBox(line, location, name, description) {
      vm.routeInfoWindow = new google.maps.InfoWindow({
        content: `<div>
                    <h3 style="padding-top: 0; margin-top: 0;">Signal: ${name}</h3>
                    <p style="padding-bottom: 0; margin-bottom: 0;">${description}</p>
                  </div>`
      })
      vm.routeInfoWindow.setPosition(location);
      vm.routeInfoWindow.open(vm.map, line);
    }

    function showWarningBox(pin) {
      var content = `<h3> Warning ! ! !</h3>
                    <p style="color:black";>Please remove signals by order! :)</p>`;

      vm.warningOrderInfoWindow = new google.maps.InfoWindow({
        content: content,
      });

      vm.warningOrderInfoWindow.open(vm.map, pin);

      vm.eventListeners.push(google.maps.event.addListener(pin, "mouseout", function () {
        vm.warningOrderInfoWindow.close(vm.map, pin);
      }));

    }

    function showWarningNoApproachesBox(pin) {

      var content = `<h3> Warning ! ! !</h3>
                    <p style="color:black";>Please configure approaches for this signal! :) </p>`;

      vm.warningInfoWindow = new google.maps.InfoWindow({
        content: content,
      });

      vm.warningInfoWindow.open(vm.map, pin);

      vm.eventListeners.push(google.maps.event.addListener(pin, "mouseout", function () {
        vm.warningInfoWindow.close(vm.map, pin);
      }));
    }
    //====================================//

    //============= CONTEXT MENU STUFF =============//
    function showContextMenu(e, signal) {
      if (vm.isFullscreen) return;

      vm.contextClosed = false;
      vm.contextSignal.signal = signal;

      $scope.$apply();

      var menu = angular.element($element.find("#map-context-menu"));
      var position = vm.setContextmenuPosition(e, menu);

      // set the position of the ContextMenu and make it visible
      menu.css({ left: position.x, top: position.y });
      menu.removeClass("hide-menu")
      menu.addClass("md-active");
    }

    function showSideMenu($mdMenu, ev) {
      var sideMenu = angular.element($document[0].getElementById('side-menu'));
      var mapEl = $element.find(".gm-style");
      var pushFromBottom = vm.bottomLeftContextMenu - 100;
      if (vm.bottomLeftContextMenu + 303 > mapEl.height()) {
        if (vm.topLeftContextMenu - 300 < 50) {
          pushFromBottom = vm.bottomLeftContextMenu - 250;
        } else {
          pushFromBottom = vm.bottomLeftContextMenu - 330;
        }
      }
      if (vm.sideMenuLeftPosition + vm.contextMenuWidth + 65 + 327 > mapEl.width() + mapEl.scrollLeft()) {
        sideMenu.css({ left: vm.sideMenuLeftPosition - 265 + 'px', top: pushFromBottom + 'px' })
      } else {
        sideMenu.css({ left: vm.sideMenuLeftPosition + vm.contextMenuWidth + 65 + 'px', top: pushFromBottom + 'px' })
      }
      $timeout(function () {
        $mdMenu.open(ev);
      }, 500);
    }

    function setContextmenuPosition(event, contextMenu) {
      var mousePosition = {};
      var menuPosition = {};
      var menuDimension = {};
      //boolean to signal if we are opening menu higher than mouse position or lower
      var openLow = false;

      vm.contextMenuWidth = contextMenu.outerWidth();
      menuDimension.x = contextMenu.outerWidth();
      menuDimension.y = contextMenu.outerHeight();
      mousePosition.x = event.domEvent.pageX;
      mousePosition.y = event.domEvent.pageY;
      var mapEl = $element.find(".gm-style");

      if (mousePosition.x + menuDimension.x > mapEl.width() + mapEl.scrollLeft()) {
        menuPosition.x = mousePosition.x - menuDimension.x;
      } else {
        menuPosition.x = mousePosition.x - 150;
      }

      var scrollTop = mapEl[0].scrollHeight - mousePosition.y
      //if (mousePosition.y > heightTop / 3) {
      var heightTop = mapEl.height() + scrollTop;
      if ((mapEl.height() - mousePosition.y) + menuDimension.y > mapEl.height()) {
        menuPosition.y = mousePosition.y - menuDimension.y;
      } else {
        menuPosition.y = mousePosition.y - menuDimension.y + 50;
        openLow = true;
      }

      var roomToBottom = mapEl.height() - event.domEvent.pageY;
      //detect if our menu is overlapping and move
      if (!openLow && event.domEvent.pageY - contextMenu.outerHeight() < 0) {
        //we are overlapping to the top
        //so move down a bit
        var diff = contextMenu.outerHeight() - event.domEvent.pageY;
        menuPosition.y = menuPosition.y + diff;
      } else if (openLow && roomToBottom - contextMenu.outerHeight() < 0) {
        //we are overlapping to the bottom
        //so add  difference from y
        var diff = contextMenu.outerHeight();
        menuPosition.y = menuPosition.y - diff;
      }
      vm.sideMenuLeftPosition = menuPosition.x;
      vm.topLeftContextMenu = menuPosition.y;
      vm.bottomLeftContextMenu = menuDimension.y + menuPosition.y;

      return menuPosition;
    }

    function closeContextMenu() {
      if (!vm.contextClosed) {
        var menu = angular.element($element.find("#map-context-menu"));
        menu.removeClass("md-active");
        menu.addClass("hide-menu")
        vm.contextClosed = true;
      }
    }
    function closeSideMenu() {
      if (!vm.sideMenuClosed) {
        var menu = angular.element($element.find("#side-menu"));
        menu.removeClass("md-active");
        menu.addClass("hide-menu");
        vm.sideMenuClosed = true;
      }
    }

    function disableMenuItem(menuItem) {
      if (vm.contextClosed == false) {
        return !vm.contextSignal.signal.availableCharts.some(x => x.chartName == menuItem)
      }
      return;
    }
    //=======================================//

    //============================= GET EXTERNAL DATA FROM API ========================//
    function callApiInit() {
      if (vm.api) {
        //if api object has properties delete them
        if (vm.api.calculateDirections) delete vm.api["calculateDirections"];
        if (vm.api.updateHeatMap) delete vm.api["updateHeatMap"];
        if (vm.api.updateSignalsOnMap) delete vm.api["updateSignalsOnMap"];
        if (vm.api.highlightSelectedSignals) delete vm.api["highlightSelectedSignals"];
        if (vm.api.finishedLoadingSignals) delete vm.api["finishedLoadingSignals"];
        if (vm.api.updateRouteLines) delete vm.api["updateRouteLines"];
        if (vm.api.majorDirection) delete vm.api["updateRouteLines"];
        if (vm.api.drawNewSignal) delete vm.api["drawNewSignal"];

        angular.extend(vm.api, {
          calculateDirections: function () {
            if (vm.calculateDirections) vm.calculateDirections();
          },
          updateHeatMap: function (heatData, inverseHeatColors) {
            if (vm && vm.addHeatMapLayer)
              vm.addHeatMapLayer(heatData, inverseHeatColors);
          },
          drawNewSignal: function (signal) {
            if (vm && vm.drawSignalConfig)
              vm.drawSignalConfig(signal)
          },
          updateRouteLines: function (routes, signals) {
            if (vm && vm.showRouteLines) {
              vm.showRouteLines(routes, signals);
            }
          },
          updateSignalsOnMap: function (selectedRoute) {
            if (vm && vm.updateSignalsOnMap)
              vm.updateSignalsOnMap(selectedRoute);
          },
          highlightSelectedSignals: function (selectedSignals) {
            if (vm && vm.highlightSelectedSignals) {
              vm.highlightSelectedSignals(selectedSignals);
            }
          },
          finishedLoadingSignals: function () {
            return vm.finishedLoadingSignals;
          },
          majorDirection: function (majorDirectionDescription) {
            if (vm && vm.majorDirection) {
              vm.majorDirection(majorDirectionDescription);
            }
          },
        });
      }

      if ($scope.onApiInit) {
        $scope.onApiInit({ apiObj: vm.api });
      }
    }
    //==============================================================================//

    //==================== FUNCTIONS FOR DRAWING DIRECTIONS ====================//
    function updateSignalsOnMap(signals) {
      $scope.showDirectionSignals = signals;
      vm.showDirectionSignals(signals);
    }

    function showDirectionSignals(signals) {
      if (!$scope.showDirectionSignals) return;

      //Load the directions module.
      vm.directionsService = new google.maps.DirectionsService();

      if (vm.directionsRenderer != null) {
        vm.directionsRenderer.setMap(null);
        vm.directionsRenderer = null;
      }

      vm.directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        map: vm.map,
      });

      $scope.showDirectionSignals = [];
      $scope.showDirectionSignals = signals;
      var counter = 1;

      var locations = [];
      for (let i = 0; i < vm.purduePins.length; i++) {
        vm.purduePins[i].setMap(null);
      }

      vm.purduePins.length = 0;

      vm.purdueLinkBounds = new google.maps.LatLngBounds();
      $scope.showDirectionSignals.forEach(function (signal) {
        var description = "";
        if (!signal.description) description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
        else description = signal.description;

        var location = new google.maps.LatLng(signal.latitude, signal.longitude);
        locations.push(location);

        var pin = new google.maps.Marker({
          position: { lat: signal.latitude, lng: signal.longitude },
          icon: {
            path: vm.FULL_MARKER,
            scale: 1.4,
            fillColor: "#0254A5",
            fillOpacity: 1,
            strokeColor: "#0254A5",
            anchor: { x: 12, y: 24 },
            labelOrigin: new google.maps.Point(12, 9.6),
          },
          label: {
            color: "#fff",
            fontWeight: "bold",
            fontSize: "14px",
            text: counter.toString(),
          },
          map: vm.map,
        });

        vm.purduePins.push(pin);

        vm.eventListeners.push(google.maps.event.addListener(pin, "mouseover", () => {
          vm.addHoverBoxInfo(pin, signal);
        }));

        counter++;
        vm.purdueLinkBounds.extend(pin.position);
      });

      vm.map.fitBounds(vm.purdueLinkBounds);
      if (signals.length > 0) {
        var origin = new google.maps.LatLng(signals[0].latitude, signals[0].longitude, null, true);
        var destination = new google.maps.LatLng(signals[signals.length - 1].latitude, signals[signals.length - 1].longitude, null, true);
        vm.directionsRenderer.setMap(vm.map);
        vm.calculateAndDisplayRoute(origin, destination, signals);
      }
    }

    function calculateAndDisplayRoute(origin, destination, signals) {
      var waypts = [];
      for (let i = 1; i < signals.length - 1; i++) {
        waypts.push({
          location: new google.maps.LatLng(signals[i].latitude, signals[i].longitude, null, true),
          stopover: true,
        });
      }
      vm.directionsService.route(
        {
          origin: origin,
          destination: destination,
          avoidHighways: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            vm.directionsRenderer.setDirections(response);
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    }
    //=======================================================================//

    //============================ HEATMAPS =================================//
    function addHeatMapLayer(heatData, inverseHeatColors) {
      var data = undefined;
      if (!$scope.heatMapData || $scope.heatMapData.length <= 0) {
        if (heatData && heatData.length > 0) {
          data = heatData;
          vm.inverseHeatMapColors = inverseHeatColors;
        }
      } else {
        data = $scope.heatMapData;
      }

      if (!data || data.length <= 0 || !vm || !vm.map) return;

      if (vm.currentHeatMapLayer) {
        vm.currentHeatMapLayer.setMap(null);
      }

      vm.heatBounds = new google.maps.LatLngBounds();

      // /* locations can be the mix of Location and WeightedLocation */
      var totalCounts = data.reduce(function (prev, cur) {
        if (!cur.count) return prev;
        return prev + cur.count;
      }, 0);

      if (totalCounts === 0) return;

      var uniqueLocations = [];

      var locations = [];
      for (var i = 0; i < data.length; i++) {
        var thisLocation = data[i];
        if (thisLocation && thisLocation.latitude && thisLocation.longitude) {
          var location = new google.maps.LatLng(thisLocation.latitude, thisLocation.longitude);
          //setup labels
          if (!uniqueLocations.find(function (loc) { return loc.id == thisLocation.id; })) {
            var fullText;
            if (!thisLocation.fullName) {
              fullText = signalService.getSignalByIDFromDict(thisLocation.id);
              thisLocation.fullName = fullText.description;
            }
            //add to array if location does not exist yet
            uniqueLocations.push({
              id: thisLocation.id,
              customId: thisLocation.customId,
              label: thisLocation.fullName,
              location: location,
            });
          }
          //High number of data points causes performance problems (ie: volume - hundreds of thousands over a month)
          //so instead of adding a location for each count figure out the percentage of the total
          //and add the percentage decimal value * 1000. (so 15% would equate to 150 data points)
          //Using 1000 as the multiplier to ensure we have enough granularity for distinct color gradients
          var percentageOfTotal = thisLocation.count / totalCounts;
          var countTotal = Math.ceil(percentageOfTotal * 1000);
          // bounds.push(location);
          for (var k = 0; k < countTotal; k++) {
            locations.push(location);
          }
        }
      }
      var gradients = {
        good: [
          "rgba(0,255,0,0)",
          "rgba(0,255,0, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,255,0, 1)",
        ],
        bad: [
          "rgba(0,255,0,0)",
          "rgba(0,255,0,1)",
          "rgba(255,255,0,1)",
          "rgba(255,0,0,1)",
        ],
      };

      vm.currentHeatMapLayer = new google.maps.visualization.HeatmapLayer({
        data: locations,
        map: vm.map,
        radius: 25,
      });

      if (vm.inverseHeatMapColors) {
        vm.currentHeatMapLayer.set("gradient", vm.currentHeatMapLayer.get("gradient") ? null : gradients["good"]);
      } else {
        vm.currentHeatMapLayer.set("gradient", vm.currentHeatMapLayer.get("gradient") ? null : gradients["bad"]);
      }

      gradients = {};

      // // //add labels for unique locations
      uniqueLocations.forEach(function (loc) {
        var pin = vm.drawDefaultMarkers(null, 1.4, 0, 0, loc.location.lat(), loc.location.lng())
        vm.eventListeners.push(google.maps.event.addListener(pin, "mouseover", () => {
          vm.showInfoBoxHeatMap(loc, pin);
        }));
        vm.heatBounds.extend(pin.position);
      });

      vm.map.fitBounds(vm.heatBounds);
    }
    //====================================================================//

    //============================ PREPARING FOR SHOWING THE MAP =================================//
    //We need to setup an interval to wait for all the Google map objects to be created before we start loading our map
    function startMapLoad() {
      //check every 10ms if google.maps objects are ready
      var mapLoadInterval = $interval(function () {
        var isLoadedMap = google !== undefined && google.maps !== undefined && google.maps.event !== undefined;
        if (vm) {
          vm.mapLoadInterval = mapLoadInterval;
        }
        else {
          //vm is not loaded, parent scope is likely being destroyed. cancel the interval and return
          $interval.cancel(mapLoadInterval);
          return;
        }

        if (isLoadedMap) {
          $interval.cancel(vm.mapLoadInterval);
          vm.initMap();
          vm.callApiInit();
        }
      }, 10);
    }
    //============================================================================================//

    //==================== CUSTOM CONTROL BUTTONS ====================//
    function toggleTrafficLayer(controlDiv, map) {
      const controlUI = document.createElement("div");
      controlUI.style.marginRight = "10px";
      controlUI.style.marginTop = "10px";
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.textAlign = "center";
      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = document.createElement("div");
      controlText.style.color = "rgb(25,25,25)";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "16px";
      controlText.style.lineHeight = "38px";
      controlText.style.paddingLeft = "5px";
      controlText.style.paddingRight = "5px";
      controlText.innerHTML = "Toggle Traffic Layer";
      controlUI.appendChild(controlText);

      vm.eventListeners.push(controlUI.addEventListener("click", () => {
        if (vm.trafficLayer.getMap() == null) {
          //traffic layer is disabled.. enable it

          // for(let i = 0; i < vm.markers.length; i++){
          //   vm.markers[i].setVisible(false)
          // }
          // vm.markerCluster.refresh();
          vm.trafficLayer.setMap(map);
          vm.trafficState = true;
        } else {
          //traffic layer is enabled.. disable it
          vm.trafficLayer.setMap(null);
          // for(let i = 0; i < vm.markers.length; i++){
          //   vm.markers[i].setVisible(true)
          // }
          // vm.markerCluster.refresh();
          vm.trafficState = false;
        }
      }));
    }

    function hideMarkersButton(controlDiv, map) {
      const controlUI = document.createElement("div");
      controlUI.style.marginRight = "10px";
      controlUI.style.marginTop = "10px";
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.textAlign = "center";
      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = document.createElement("div");
      controlText.style.color = "rgb(25,25,25)";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "16px";
      controlText.style.lineHeight = "38px";
      controlText.style.paddingLeft = "5px";
      controlText.style.paddingRight = "5px";
      controlText.innerHTML = "Hide Signal Markers";
      controlUI.appendChild(controlText);

      vm.eventListeners.push(controlUI.addEventListener("click", () => {
        if (controlText.innerHTML == "Hide Signal Markers") {
          for (var i = 0; i < vm.wazeMarkers.length; i++) {
            vm.wazeMarkers[i].setVisible(false)
          }
          controlText.innerHTML = "Show Signal Markers";
        }
        else {
          for (var i = 0; i < vm.wazeMarkers.length; i++) {
            vm.wazeMarkers[i].setVisible(true)
          }
          controlText.innerHTML = "Hide Signal Markers";
        }
      }));
    }

    //===============================================================//

    //========================= AUTH0 DATA ==========================//
    function setDefaultLocation() {
      if (sessionStorage.getItem("user_metadata")) {
        var userData = JSON.parse(sessionStorage.getItem("user_metadata"));
        if (userData && userData.map_bounds && userData.map_bounds.lat && userData.map_bounds.lon) {
          vm.userlat = userData.map_bounds.lat;
          vm.userlon = userData.map_bounds.lon;
        }
      }
    }
    //===============================================================//

    //========================= SETUP THE MAP, BASICALLY THE MAIN FUNCTION =========================//
    function initMap() {
      vm.setDefaultLocation();

      vm.contextClosed = true;
      vm.sideMenuClosed = true;
      // set first view to USA lat/lng if there's no Auth0 coords set
      if (!vm.userlat || !vm.userlon) {
        vm.userlat = 37.0902;
        vm.userlon = -95.7129;
        vm.defaultZoom = 5;
      }

      // set the view if there are things saved from the mapPreferences
      if ($scope.isSessionStorage && vm.savedZoomLevel && vm.savedTypeId && vm.savedCenter) {
        vm.defaultZoom = vm.savedZoomLevel;
        vm.defaultMapType = vm.savedTypeId;
        vm.userlat = vm.savedCenter.lat();
        vm.userlon = vm.savedCenter.lng();
        vm.isSessionStorage = true;
      }

      vm.showSignals();

      if ($scope.mapType) {
        vm.defaultMapType = $scope.mapType;
      }

      if ($scope.controlSize) {
        vm.controlSize = 29.5;
      }

      // set initial map Options
      vm.mapOptions = {
        center: { lat: vm.userlat, lng: vm.userlon },
        zoom: $scope.zoomLevel ? $scope.zoomLevel : vm.defaultZoom,
        disableDefaultUI: vm.disableDefaultUI,
        styles: [{
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        }],
        rotateControl: $scope.rotateControls,
        padding: 75,
        mapTypeId: vm.defaultMapType,
        fullscreenControl: vm.disableDefaultUI == true ? false : $scope.fullScreen,
        draggable: $scope.draggableMap,
        controlSize: vm.controlSize,
      };

      // set the minimum zoom level if necessary
      if ($scope.zoomRestriction) {
        vm.mapOptions.minZoom = 17;
      }

      // Draw the map
      if (vm.map === void 0) {
        vm.map = new google.maps.Map($element.find("#googlemap")[0], vm.mapOptions);
      }

      if ($scope.showTrafficButton) {
        vm.trafficLayer = new google.maps.TrafficLayer();
        var toggleTrafficButton = document.createElement("div");
        vm.toggleTrafficLayer(toggleTrafficButton, vm.map);
        vm.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleTrafficButton);

        if (vm.trafficState) {
          vm.trafficLayer.setMap(vm.map);
        } else {
          vm.trafficLayer.setMap(null);
        }
      }

      if ($scope.showHideMarkersButton) {
        var hideMarkersButton = document.createElement("div");
        vm.hideMarkersButton(hideMarkersButton, vm.map);
        vm.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(hideMarkersButton);
      }

      // assign the street-view options to a variable
      vm.thePanorama = vm.map.getStreetView();
      if ($scope.disableZoomControl) {
        vm.map.get("streetView").setOptions({
          zoomControl: vm.isFullscreen,
        });
      }

      if (vm.savedStreetView) {
        vm.map.get("streetView").setOptions({
          visible: true,
        });
        vm.thePanorama.setPosition(vm.savedCenter);
      }

      //============ EVENT LISTENERS FOR CLOSING CONTEXT MENU ============//

      if (!$scope.hideContextMenu) {
        vm.eventListeners.push(google.maps.event.addListener(vm.map, "click", function () {
          if (!vm.contextClosed) vm.closeContextMenu();
        }));

        vm.eventListeners.push(google.maps.event.addListener(vm.map, "maptypeid_changed", function () {
          if (!vm.contextClosed) vm.closeContextMenu();
        }));

        vm.eventListeners.push(google.maps.event.addListener(vm.map, "zoom_changed", function () {
          if (!vm.contextClosed) vm.closeContextMenu();
        }));

        vm.eventListeners.push(google.maps.event.addListener(vm.map, "drag", function () {
          if (!vm.contextClosed) vm.closeContextMenu();
        }));
      }
      //==================================================================//

      //======================== EVENT LISTENERS FOR STREET-VIEW ======================//

      // On fullscreen enable +/- icon, otherwise disable it
      vm.eventListeners.push(document.addEventListener("fullscreenchange", function () {
        if (vm.thePanorama.getVisible() && $scope.disableZoomControl) {
          vm.map.get("streetView").setOptions({
            zoomControl: !vm.isFullscreen,
          });
        }
        vm.isFullscreen = !vm.isFullscreen;
      }));

      // whenever we go from the map view to street-view and the other way around, this listener is triggered
      vm.eventListeners.push(google.maps.event.addListener(vm.thePanorama, "visible_changed", function () {
        if (!vm.contextClosed) vm.closeContextMenu();
        // Make the markers bigger (scale: 5) for when in street-view
        if (vm.thePanorama.getVisible()) {
          for (let i = 0; i < vm.markers.length; i++) {
            vm.markers[i].setIcon(vm.changeMarkerIcon("#0676DC", "#fff", 5, false));
          }
          if (vm.previousMarker) {
            vm.gettingMarker.setIcon(vm.changeMarkerIcon("#0254A5", "#fff", 5, false))
          }
        } else {
          // Make the markers smaller (scale: 1.4 and 2.2) for when in map-view
          for (let i = 0; i < vm.markers.length; i++) {
            vm.markers[i].setMap(null);
            vm.markers[i].setIcon(vm.changeMarkerIcon("#0676DC", "#fff", 1.4, true));
            vm.markers[i].setMap(vm.map);
          }
          if (vm.previousMarker) {
            vm.previousMarker.setIcon(vm.changeMarkerIcon("#0254A5", "#fff", 2.2, false));
          }
        }
      }));
      // This event listener is triggered when moving through the street-view
      vm.eventListeners.push(google.maps.event.addListener(vm.thePanorama, "pano_changed", function () {
        if (!vm.contextClosed) vm.closeContextMenu();
      }));
      // This event listener is triggered when click-and-dragging in the street-view
      vm.eventListeners.push(google.maps.event.addListener(vm.thePanorama, "pov_changed", function () {
        if (!vm.contextClosed) vm.closeContextMenu();
      }));

      vm.mapWheelOnEvent = angular.element($element.find("#googlemap")).on("wheel mousewheel DOMMouseScroll", function (e) {
        e.stopPropagation();
      })
      //===================================================================================//

    }
    //============================================================================================//

    //============= FROM CONTEXT MENU TO SELECTED OPTION =============//
    function loadStateFromMenu(e, state) {
      vm.isContextMenuPressed = true;
      if ($scope.hideContextMenu) {
        return;
      }
      e.stopPropagation();
      if (vm.contextSignal) {
        breadcrumbNavigationService.navigateToState(
          state,
          undefined,
          vm.contextSignal.signal
        );
      } else {
        breadcrumbNavigationService.navigateToState(state);
      }
    }
    function openInNewTab(metricType, newWindow) {
      // if (occurrence == 'Signal Overview') vm.navigateToState('app.spm.dashboards.signal-overview', newTimeOptions, vm.signal, newWindow);
      if (!vm || !metricType || !vm.contextSignal || !vm.contextSignal.signal) return;
      if (metricType == 'Signal Overview') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-overview', undefined, vm.contextSignal.signal);
      if (metricType == 'Performance Dashboard') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-performance', undefined, vm.contextSignal.signal);
      if (metricType == 'Trends Dashboard') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-trends', undefined, vm.contextSignal.signal);
      if (metricType == 'Signal Events') vm.newWindowService.openNewTabTimeAndSignal('app.spm.signal-events', undefined, vm.contextSignal.signal);
      if (metricType == 'Split Monitor') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.split-monitor', undefined, vm.contextSignal.signalvm.contextSignal.signal);
      if (metricType == 'Purdue Split Monitor') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.purdue-split-monitor', undefined, vm.contextSignal.signal);
      if (metricType == 'Phase Termination') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.phase-termination', undefined, vm.contextSignal.signal);
      if (metricType == 'Pedestrian Delay') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.ped-delay', undefined, vm.contextSignal.signal);
      if (metricType == 'Preemption Details') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.preempt-details', undefined, vm.contextSignal.signal);
      if (metricType == 'Turning Movement Counts') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.turning-movements', undefined, vm.contextSignal.signal);
      if (metricType == 'Purdue Coordination') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.purdue-coord-diagram', undefined, vm.contextSignal.signal);
      if (metricType == 'Approach Volume') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.approach-volume', undefined, vm.contextSignal.signal);
      if (metricType == 'Approach Delay') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.approach-delay', undefined, vm.contextSignal.signal);
      if (metricType == 'Arrivals on Red') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.aor', undefined, vm.contextSignal.signal);
      if (metricType == 'Red Light Runners') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.red-light-runners', undefined, vm.contextSignal.signal);
      if (metricType == 'Purdue Split Failure') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.split-failure', undefined, vm.contextSignal.signal);
      if (metricType == 'Queue Length') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.queue-length', undefined, vm.contextSignal.signal);
      if (metricType == 'Detector Counts') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.detector-counts', undefined, vm.contextSignal.signal);
      if (metricType == 'Detector Durations') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.detector-durations', undefined, vm.contextSignal.signal);
    };

    //================================================================//

    vm.lastOrdered = 0;
    function highlightSelectedSignals() {
      var selectedSignals = $scope.showSelectedSignals
      if (!selectedSignals)
        return;

      var locations = [];
      vm.highlightBounds = new google.maps.LatLngBounds();
      for (var i = 0; i < vm.markers.length; i++) {
        var marker = vm.markers[i];
        selectedSignals.forEach(function (signal) {
          var description = "";
          if (!signal.description)
            description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
          else description = signal.description;

          if (marker.id == signal.signalID) {
            var location = new google.maps.LatLng(signal.latitude, signal.longitude);
            locations.push(location);
            if (vm.lastOrdered < signal.approachOrder) vm.lastOrdered = signal.approachOrder;
            signalToCorridor(signal, marker, signal.approachOrder, false);
          }
        });
      }

      for (var i = 0; i < locations.length; i++) {
        vm.highlightBounds.extend(locations[i]);
      }
      if (locations.length > 0) {

        vm.map.fitBounds(vm.highlightBounds);
      }
    }

    vm.majorDir = '';
    function majorDirection(majorDirectionDescription) {
      vm.majorDir = majorDirectionDescription;
      vm.highlightSelectedSignals()
    }

    function findApproachOfSignal(signal) {
      var approach = undefined;

      approach = signal.approaches.find(elem => elem.direction == vm.majorDir && elem.description.includes('T') && !elem.description.toLowerCase().includes('bike') && !elem.description.toLowerCase().includes('turn'));
      if (approach == undefined) approach = signal.approaches.find(elem => elem.direction == vm.majorDir && !elem.description.toLowerCase().includes('bike') && !elem.description.toLowerCase().includes('turn'));
      if (approach == undefined) approach = signal.approaches.find(elem => elem.direction != vm.majorDir && checkApproachDirection(elem, vm.majorDir));
      if (approach == undefined) approach = signal.approaches.find(elem => elem.direction == vm.majorDir && approach == undefined);
      if (approach == undefined) approach = signal.approaches.find(elem => approach == undefined);

      return approach;
    }

    function checkApproachDirection(approach, md) {

      var majorDir = md.toLowerCase();

      if (majorDir.includes('bound')) {
        majorDir = majorDir.replace('bound', '');
      }

      if (approach.direction.toLowerCase().includes(majorDir) && approach.description.includes('T') && !approach.description.toLowerCase().includes('bike') && !approach.description.toLowerCase().includes('turn')) {
        return true;
      }
      else {
        return false;
      }

    }

    function isSelected() {
      if ($scope.signalAddedToCorridor) {
        $scope.signalAddedToCorridor({ signal: vm.selectedSignal, approach: findApproachOfSignal(vm.selectedSignal) });
      }
    }

    function showWarningBox(pin) {
      var content = `<h3> Warning ! ! !</h3>
                    <p style="color:black";>Please remove signals by order! :)</p>`;

      vm.warningOrderInfoWindow = new google.maps.InfoWindow({
        content: content,
      });

      vm.warningOrderInfoWindow.open(vm.map, pin);

      vm.eventListeners.push(google.maps.event.addListener(pin, "mouseout", function () {
        vm.warningOrderInfoWindow.close(vm.map, pin);
      }));

    }

    function showWarningNoApproachesBox(pin) {

      var content = `<h3> Warning ! ! !</h3>
                    <p style="color:black";>Please configure approaches for this signal! :) </p>`;

      vm.warningInfoWindow = new google.maps.InfoWindow({
        content: content,
      });

      vm.warningInfoWindow.open(vm.map, pin);

      vm.eventListeners.push(google.maps.event.addListener(pin, "mouseout", function () {
        vm.warningInfoWindow.close(vm.map, pin);
      }));
    }

    vm.signalsInCorridor = [];
    var index = 0;

    vm.isSignalAdded = false;
    vm.isSignalClickedCheck = false;

    function isSignalClicked(signal, marker) {
      if (!$scope.addSignalToCorridor)
        return;
      var signal = signal;
      var marker = marker;
      vm.lastOrdered++;
      vm.isSignalClickedCheck = true;
      signalToCorridor(signal, marker, vm.lastOrdered, true);
    }


    function signalToCorridor(signal, marker, order, showApproaches) {
      if (!$scope.addSignalToCorridor)
        return;

      if (order || order > 0) vm.counter = order;

      var location = [];
      var isSameLocation;

      location = new google.maps.LatLng(signal.latitude, signal.longitude);
      vm.approaches = signal.approaches;
      if (location.latitude == vm.oldLocation.latitude || location.longitude == vm.oldLocation.longitude) {
        isSameLocation = true;
      }
      else {
        isSameLocation = false;
      }
      if (marker instanceof google.maps.Marker) {
        if ((!isSameLocation && (!marker.getLabel() || marker.getLabel() == '')) || (isSameLocation && (!marker.getLabel() || marker.getLabel() == ''))) {
          if (vm.approaches && vm.approaches.length) {
            vm.selectedSignal = signal;
            marker.setIcon({
              path: vm.FULL_MARKER,
              scale: 1.4,
              fillColor: "#0254A5",
              fillOpacity: 1,
              strokeColor: "#0254A5",
              anchor: { x: 12, y: 24 },
              labelOrigin: new google.maps.Point(12, 9.6)
            });
            marker.setLabel({
              color: "#fff",
              fontWeight: "bold",
              fontSize: "14px",
              text: vm.counter.toString(),
            })
            vm.signalsInCorridor[vm.counter - 1] = signal;
            vm.signalsToBeRemoved = [];
            index = 0;
            vm.counter++;
            if (showApproaches) {
              if ($scope.signalAddedToCorridor) {
                $scope.signalAddedToCorridor({ signal: vm.selectedSignal, approach: findApproachOfSignal(vm.selectedSignal) });
              }
            }
          }
          else {
            showWarningNoApproachesBox(marker);
            vm.lastOrdered--;
          }
          vm.isSignalAdded = true;
        }
        else {
          vm.counter = order - 1;
          if (marker.getLabel().text == vm.counter.toString()) {
            marker.setIcon(vm.changeMarkerIcon("#0676DC", "#fff", 1.4, true))
            marker.setLabel(null);
            vm.signalsToBeRemoved[index++] = vm.signalsInCorridor[vm.counter - 1];
            vm.signalsInCorridor.splice(vm.counter - 1, 1);
            vm.approach.splice(vm.counter, 1);
            vm.counter--;
            vm.lastOrdered = vm.counter;
            if ($scope.signalRemovedFromCorridor && vm.signalsToBeRemoved.length > 0) {
              $scope.signalRemovedFromCorridor({ signals: vm.signalsToBeRemoved });
              vm.isSignalAdded = true;
            }
          }
          else if (vm.isSignalClickedCheck) {
            showWarningBox(marker);
            vm.lastOrdered--;
          }
        }

        if (vm.isSignalClickedCheck && $scope.isSignalAdded) {
          $scope.isSignalAdded({ isSignalAdded: vm.isSignalAdded });
        }

        vm.oldLocation = location;
      }
    }
    function showOptionsPanel() {
      vm.show_filters = true;

      var position = $mdPanel.newPanelPosition()
        .absolute()
        .center();

      var config = {
        attachTo: angular.element(document.body),
        templateUrl: 'app/main/spm/core/directives/menus/approach.directions.panel.html',
        hasBackdrop: false,
        position: position,
        scope: $scope.$new(),
        trapFocus: true,
        zIndex: 150,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        origin: '#corridor-add-dialog'
      };

      $mdPanel.open(config).then(function (ref) {
        vm.currentOptionsPanel = ref;
      });
    }
    function showRouteLines(routes, signals) {
      if (!routes || !vm.map)
        return;

      //cleanup any click or event handlers we registered
      for (var i in vm.eventListeners) {
        google.maps.event.removeListener(vm.eventListeners[i]);
      }
      for (let i = 0; i < vm.markers.length; i++) {
        vm.markers[i].setMap(null);
      }

      var allLocations = new google.maps.LatLngBounds();
      for (let i = 0; i < routes.length; i++) {
        var locations = [];

        var thisRoute = routes[i]
        for (let v = 0; v < thisRoute.lineGeometry.length; v++) {
          var thisPoint = thisRoute.lineGeometry[v];
          if (thisPoint.x && thisPoint.y) {
            var location = new google.maps.LatLng(thisPoint.y, thisPoint.x);
            locations.push(location);
            allLocations.extend(location)
          }
        }
        //Create a polyline
        vm.line = new google.maps.Polyline({
          path: locations,
          strokeColor: thisRoute.color,
          strokeWeight: 8,
        });
        vm.eventListeners.push(google.maps.event.addListener(vm.line, "mouseover", (args) => {
          vm.lineBox = {
            name: routes[i].name,
            description: "Travel Time: " + routes[i].travelTimeString
          };
          vm.routeInfoBox(vm.line, args.latLng, vm.lineBox.name, vm.lineBox.description)
        }));
        vm.eventListeners.push(google.maps.event.addListener(vm.line, "mouseout", function () {
          vm.lineBox = {}
          vm.routeInfoWindow.close(vm.map, vm.line);
        }));
        vm.line.setMap(vm.map)
      }

      var counter = 1;
      signals.forEach(function (signal, idx, array) {
        var description = "";
        var locations = [];

        if (!signal.description)
          description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
        else
          description = signal.description;

        var location = new google.maps.LatLng(signal.latitude, signal.longitude);
        locations.push(location);
        var pin = new google.maps.Marker({
          position: location,
          icon: {
            path: vm.FULL_MARKER,
            scale: 1.4,
            fillColor: "#0254A5",
            fillOpacity: 1,
            strokeColor: "#0254A5",
            anchor: { x: 12, y: 24 },
            labelOrigin: new google.maps.Point(12, 9.6),
          },
          label: {
            color: "#fff",
            fontWeight: "bold",
            fontSize: "14px",
            text: counter.toString(),
          },
          map: vm.map,
        });
        vm.wazeMarkers.push(pin);
        vm.eventListeners.push(google.maps.event.addListener(pin, "mouseover", () => {
          vm.wazeInfoBox(signal, pin);
        }));
        counter++;
      });
      $timeout(function () {
        if (vm && vm.map) {
          vm.map.fitBounds(allLocations);
        }
      }, 1000);
    }
    function drawSignalConfig(signal) {
      for (let i = 0; i < vm.markers.length; i++) {
        vm.markers[i].setMap(null)
      }

      if (signal.latitude && signal.longitude) {
        var marker = new google.maps.Marker({
          id: signal ? signal.signalID : undefined,
          draggable: true,
          icon: {
            path: vm.MAP_MARKER,
            fillColor: "#0676DC",
            strokeColor: "#fff",
            scale: 1.4,
            fillOpacity: 1,
            strokeOpacity: 1,
            anchor: { x: 12, y: 24 },
          },
          position: { lat: Number(signal.latitude), lng: Number(signal.longitude) },
          map: vm.map,
        });
        vm.markers.push(marker)
        vm.eventListeners.push(google.maps.event.addListener(marker, 'dragend', function (e) {
          var latLng = e.latLng;
          var currentLatitude = latLng.lat();
          var currentLongitude = latLng.lng();
          signal.latitude = currentLatitude;
          signal.longitude = currentLongitude;
          if (vm.signalDragendCallback !== undefined) vm.signalDragendCallback({ signal: signal });
          vm.map.setCenter(new google.maps.LatLng(Number(signal.latitude), Number(signal.longitude)));
          vm.map.setZoom(19);
        }));
        vm.map.setCenter(new google.maps.LatLng(Number(signal.latitude), Number(signal.longitude)));
        vm.map.setZoom(16);
      }
      else {
        const geocoder = new google.maps.Geocoder();
        const address = signal.primaryName.toString() + ' / ' + signal.secondaryName.toString();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === "OK") {

            for (let i = 0; i < vm.markers.length; i++) {
              vm.markers[i].setMap(null)
            }
            vm.map.setCenter(results[0].geometry.location);
            var marker = new google.maps.Marker({
              id: signal ? signal.signalID : undefined,
              map: vm.map,
              draggable: true,
              icon: {
                path: vm.MAP_MARKER,
                fillColor: "#0676DC",
                strokeColor: "#fff",
                scale: 1.4,
                fillOpacity: 1,
                strokeOpacity: 1,
                anchor: { x: 12, y: 24 },
              },
              map: vm.map,
              position: results[0].geometry.location,
            });
            vm.dragInfoBox(marker)
            vm.markers.push(marker)
            signal.latitude = results[0].geometry.location.lat();
            signal.longitude = results[0].geometry.location.lng();
            vm.map.setZoom(16);
            if (vm.signalDragendCallback !== undefined) vm.signalDragendCallback({ signal: signal });
            vm.eventListeners.push(google.maps.event.addListener(marker, 'dragend', function (e) {
              var latLng = e.latLng;
              var currentLatitude = latLng.lat();
              var currentLongitude = latLng.lng();
              signal.latitude = currentLatitude;
              signal.longitude = currentLongitude;
              if (vm.signalDragendCallback !== undefined) vm.signalDragendCallback({ signal: signal });
              vm.map.setCenter(new google.maps.LatLng(Number(signal.latitude), Number(signal.longitude)));
              vm.map.setZoom(19);
            }));
          } else {
            alert("Geocode was not successful for the following reason: " + status);
            $rootScope.$broadcast('no results geocode');
          }
        });
      }
    }
  }
})();
