(function () {
    "use strict";

    angular
        .module("app.spm.core")
        .service("agencyService", agencyService);

    function agencyService(agencyResource) {
        /**
         * Get the agency view model containing the settings / configuration for the agency.
         *
         * @return {Promise} A promise that resolves to a response containing the JSON object of the agency's settings (view model).
         */
        function getAgencySettings() {
            return agencyResource.getAgencySettings(
                function (data) {
                    return data;
                }).$promise;
        }

        return {
            getAgencySettings: getAgencySettings
        }
    }
})();