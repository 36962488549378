import './dashboards.module';
import './user-settings';
import './insights';
import './travel-time';
import './travel-time-historical';
import './waze-travel-times';
import './corridor-performance';
import './detector-config-suggestions';
import './executive';
import './insights-map-dashboard';
import './system-map';
import './signal-overview';
import './signal-performance';
import './signal-trends';
import './monthly-intersection-report';