import * as SYSINS from 'raw-loader!./system-insights.html'

(function () {
    'use strict';

    angular.module('app.spm.core')
        .directive('systemInsights', systemInsightsDirective);


    function systemInsightsDirective() {
        function link(scope, element, attrs) {
        }
        return {
            link: link,
            restrict: 'AE',
            bindToController: {
                api: '=',
            },
            scope: {
                // api: '=',
                param: '=',
                data: '<',
                onApiInit: '&',
            },
            link: function (scope, element, attrs) {
                if (!scope.api) {
                    scope.api = {};
                }
                
                if (scope.onApiInit) {
                    scope.onApiInit({ apiObj: scope.api });
                }
            },
            template: SYSINS,
            controller: 'systemInsightsController as vm'
        }
    }
})();
