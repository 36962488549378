﻿import domtoimage from 'dom-to-image';
import * as FileSaver from 'file-saver';

(function () {
    "use strict";

    angular
        .module("app.spm.core")
        .controller("chartWidgetContextMenuController", chartWidgetContextMenuController);

    function chartWidgetContextMenuController($scope, dataExportService, $mdMenu, $element, searchBarService, $mdDialog, $translate, $window) {
        var vm = this;
        vm.enabled = $scope.enabled;
        vm.menuSize = vm.enabled ? 3 : 1;
        vm.excelExport = excelExport;
        vm.csvExport = csvExport;
        vm.saveImage = saveImage;
        vm.downloadPromise = null;
        vm.loading = false;
        vm.generateFileName = generateFileName;
        vm.appendStylesToSVG = appendStylesToSVG;
        vm.showHelpAlert = showHelpAlert;
        vm.metricType = $scope.metricType;

        function excelExport() {
            vm.loading = true;
            var queryObj = dataExportService.getCurrentQueryObject();
            queryObj.metricType = $scope.metricType;
            queryObj.fileType = "xlsx";
            dataExportService.getExcelFile(queryObj).then(function (e) {
                vm.loading = false;
            });
        }

        function csvExport() {
            vm.loading = true;
            var queryObj = dataExportService.getCurrentQueryObject();
            queryObj.metricType = $scope.metricType;
            queryObj.fileType = "csv";
            dataExportService.getRawCsvFile(queryObj).then(function (e) {
                vm.loading = false;
            });
        }

        function generateFileName() {
            var res = "";
            var options = searchBarService.getSearchOptions();
            var sigId = options.signal.signalID

            vm.timeOptions = options.timeOptions;
            switch ($scope.metricType) {
                case 1:
                    //phase termination
                    res = "Phase_Termination"
                    break;
                case 2:
                    //split monitor
                    res = "Split_Monitor";
                    break;
                case 3:
                    //ped delay
                    res = "Pedestrian_Delay";
                    break;
                case 4:
                    //preempt
                    res = "Preemption_Details";
                    break;
                case 5:
                    //tmcoptions
                    res = "Turning_Movement_Counts";
                    break;
                case 6:
                    //pcdoptions
                    res = "Purdue_Coordination";
                    break;
                case 7:
                    //apprvolume
                    res = "Approach_Volume";
                    break;
                case 8:
                    //approach delay
                    res = "Approach_Delay";
                    break;
                case 9:
                    //aoroptions
                    res = "Arrivals_Red";
                    break;

                case 10:
                    //appr speed options
                    break;
                case 11:
                    //yellow and red options
                    res = "Red_Light_Runners";
                    break;
                case 12:
                    //split fail
                    res = "Split_Failure";
                    break;
                case 20:
                    //split fail
                    res = "Queue_Length";
                    break;
                default:
                    res = "Chart";
                    break;
            }
            res += "_" + sigId + "_" + new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleDateString() + ".png";
            return res;
        }

        function saveImage() {
            vm.loading = true;
            //DOM2IMAGE == FLEXBOX NOT SUPPORTED
            let element = $element[0].parentElement.parentElement.parentElement;
            domtoimage.toBlob(element)
                .then(function (blob) {
                    FileSaver.saveAs(blob, vm.generateFileName());
                    vm.loading = false;
                    $scope.$apply()
                });
        }

        function appendStylesToSVG(svgElement) {
            svgElement.selectAll('.nvd3 .nv-axis').style('pointer-events', 'none');
            svgElement.selectAll('.nvd3 .nv-axis path').style('fill', 'none');
            svgElement.selectAll('.nvd3 .nv-axis rect').style('fill', 'none');
            svgElement.selectAll('.nvd3 .nv-axis .plan-label-rect-even').style('fill', '#eeeeee');
            svgElement.selectAll('.nvd3 .nv-axis .plan-label-rect-even').style('outline', 'none');
            svgElement.selectAll('.nvd3 .nv-axis .plan-label-rect-odd').style('fill', '#ffffff');
            svgElement.selectAll('.nvd3 .nv-axis .plan-label-rect-odd').style('outline', 'none');
            svgElement.selectAll('.nvd3 .nv-axis line').style('stroke', 'rgb(229, 229, 229)');
            svgElement.selectAll('.nvd3 .nv-axis path').style('stroke', 'rgb(204, 204, 204)');
            svgElement.selectAll('.nvd3 .nv-axis .nv-axis-plan .nv-axis path').style('display', 'none');
            svgElement.selectAll('.nvd3 .nv-axis .nv-axis-plan .nv-axis line').style('display', 'none');
            svgElement.selectAll('.nvd3 .nv-axis .hide-label').style('visibility', 'hidden');


            svgElement.selectAll('.nvd3 .nv-axis path').style('stroke-opacity', '0.75');
            svgElement.selectAll('.nvd3 .nv-axis path').style('shape-rendering', 'crispedges');
            svgElement.selectAll('.nvd3 .nv-axis path.domain').style('stroke-opacity', '0.75');
            svgElement.selectAll('.nvd3 .nv-axis.nv-x path.domain').style('stroke-opacity', '0');
            svgElement.selectAll('.nvd3 .nv-axis line').style('fill', 'none');
            svgElement.selectAll('.nvd3 .nv-axis line').style('shape-rendering', 'crispedges');
            svgElement.selectAll('.nvd3 .nv-axis .zero line, .nvd3 .nv-axis line.zero').style('stroke-opacity', '0.75');
            svgElement.selectAll('.nvd3 .nv-multibar .nv-groups rect').style('stroke-opacity', '0');
            svgElement.selectAll('.nvd3 .nv-multibar .nv-groups rect').style('transition', 'fill-opacity 250ms linear 0s');
            svgElement.selectAll('.nvd3 .nv-multibarHorizontal .nv-groups rect').style('stroke-opacity', '0');
            svgElement.selectAll('.nvd3 .nv-multibarHorizontal .nv-groups rect').style('transition', 'fill-opacity 250ms linear 0s');
            svgElement.selectAll('.nvd3 .nv-discretebar .nv-groups rect').style('stroke-opacity', '0');
            svgElement.selectAll('.nvd3 .nv-discretebar .nv-groups rect').style('transition', 'fill-opacity 250ms linear 0s');
            svgElement.selectAll('.nv-x .tick line').style('display', 'none');
            svgElement.selectAll('.nvd3 .dashed-line').style('stroke-dasharray', '5, 5');
            svgElement.selectAll('text').style('font-weight', '700');

        };

        function HelpController($scope, $mdDialog) {
            $scope.moreInfo = false;
            $scope.buttonText = "MORE INFO";
            $scope.buttonToolTip = "Show me more info"
            var metricType = vm.metricType;
            $translate('METRIC.' + metricType.toString() + '.TITLE')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.title = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_DEF')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.cardInfo = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_HOW_TO_USE_TEXT')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.howToUse = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_KEEP_IN_MIND')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.keepInMind = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_0')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo0 = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_1')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo1 = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_2')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo2 = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_3')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo3 = translatedValue;
                    }
                });

            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_4')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo4 = translatedValue;
                    }
                });
            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_5')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo5 = translatedValue;
                    }
                });
            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_6')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo6 = translatedValue;
                    }
                });
            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_7')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo7 = translatedValue;
                    }
                });
            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_8')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo8 = translatedValue;
                    }
                });
            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_9')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo9 = translatedValue;
                    }
                });
            $translate('METRIC.' + metricType.toString() + '.HELP_EXTRA_TEXT_10')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        $scope.extraInfo10 = translatedValue;
                    }
                });

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
            $scope.moreInfoFunc = function () {
                if ($scope.moreInfo) {
                    $scope.moreInfo = false;
                    $scope.buttonText = "MORE INFO";
                    $scope.buttonToolTip = "Show me more info"
                    $window.open('https://traffop.freshdesk.com', '_blank');
                }
                else {
                    $scope.moreInfo = true;
                    $scope.buttonText = "NEED MORE HELP?";
                    $scope.buttonToolTip = "Visit our Helpdesk and submit a question!"
                }
            };
        }

        function showHelpAlert(ev) {
            $mdDialog.show({
                controller: HelpController,
                parent: $element,
                multiple: true,
                clickOutsideToClose: true,
                templateUrl: 'app/main/spm/core/directives/menus/help-dialog.html',
                targetEvent: ev,
            });
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        });
    }

}());
