﻿import * as ADDAPPROACH from 'raw-loader!./../../approach/view/approach-add.html';

(function () {
    "use strict";

    angular
        .module("app.spm.signals")
        .controller("signalController", signalController);

    function signalController($state, $rootScope, $element, $stateParams, signalService, $mdDialog, searchBarService, breadcrumbNavigationService) {
        var vm = this;
        vm.signal = null;
        vm.signalID = 0;
        vm.lookups = undefined;

        if ($stateParams.inputParameters) {
            vm.lookups = $stateParams.inputParameters.lookups;
            if ($stateParams.inputParameters.signal) {
                vm.signalID = $stateParams.inputParameters.signal.signalID;
            }
            vm.agencySettings = $stateParams.inputParameters.agencySettings;
        }
        else {
            //go to signal list
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals');
            return;
        }
        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
            },
            //search bar options
            showSearchBar: false,
        });

        vm.approachPromise = null;
        vm.totalApproaches = 0;
        vm.getApproaches = getApproaches;
        vm.setupDataTable = setupDataTable;
        vm.getSignal = getSignal;
        vm.goToAddApproach = goToAddApproach;
        vm.changeValues = changeValues;
        vm.goToApproachEdit = goToApproachEdit;
        vm.saveSignal = saveSignal;
        vm.deleteSignal = deleteSignal;
        vm.goToSignals = goToSignals;
        vm.deleteApproach = deleteApproach;
        vm.isFormValid = isFormValid;
        vm.isFormDirty = isFormDirty;
        vm.enableSaveButton = enableSaveButton;
        vm.setPristine = setPristine;
        vm.isAgencyUsingLocalSignalConfig = isAgencyUsingLocalSignalConfig;
        vm.getSignal();
        vm.setupDataTable();
        vm.getApproaches();
        vm.signalStatusChange = signalStatusChange

        function isAgencyUsingLocalSignalConfig() {
            if (vm.agencySettings) {
                return vm.agencySettings.isLocalConfigFileEnabled;
            }
        }

        function signalStatusChange(signal) {
            signalService.signalStatusChanged(signal.signalID, signal.customID);
        }

        function changeValues() {
            vm.getSignal();
            vm.setupDataTable();
            vm.getApproaches();
        }

        function getSignalDescriptionWithoutId(primaryName, secondaryName) {

            var res = primaryName;
            if (secondaryName != null && secondaryName != '') {
                res += " - " + secondaryName;
            }

            return res;
        }

        function getSignal() {
            signalService.getSignalById(vm.signalID)
                .then(function (signal) {
                    vm.signal = angular.copy(signal)
                    vm.headerMessage = "Edit Signal: " + getSignalDescriptionWithoutId(vm.signal.primaryName, vm.signal.secondaryName);
                });
        }

        function getApproaches() {
            vm.queryData.ID = vm.signalID;
            vm.approachPromise = signalService.getApproaches(vm.queryData)
                .then(function (data) {
                    vm.approaches = data.approaches;
                    vm.totalApproaches = data.totalCount;
                    //find the direction type desc from lookups
                    for (var i = 0; i < vm.approaches.length; i++) {
                        for (var l = 0; l < vm.lookups.directionTypes.length; l++) {
                            if (vm.lookups.directionTypes[l].id == vm.approaches[i].directionTypeID) {
                                vm.approaches[i].directionTypeDesc = vm.lookups.directionTypes[l].description;
                                break;
                            }
                        }
                    }
                });
        }


        function deleteSignal() {
            vm.signalsPromise = signalService.removeSignal(vm.signal, vm.goToSignals);
        }

        function saveSignal() {
            signalService.updateSignal(vm.signal, vm.setPristine);
        }

        function goToSignals() {
            vm.signal = null;
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals');
        }

        function goToAddApproach(ev) {
            var confirm = $mdDialog.show({
                locals: { lookups: vm.lookups, signal: vm.signal },
                controller: 'approachAddController as vm',
                template: ADDAPPROACH,
                parent: angular.element(document.body),
                targetEvent: ev,
            }).then(function () {
                vm.getApproaches();
            });
        }

        function goToApproachEdit(obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.approaches.edit', { approach: obj, signal: vm.signal, lookups: vm.lookups });
        }

        function deleteApproach(obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            // Appending dialog to document.body to cover sidenav in docs app
            signalService.deleteApproach(obj, getApproaches);
            vm.selected = [];
        }

        function isFormValid() {
            if (vm.signalEditForm) {
                return vm.signalEditForm.$valid;
            }
        }

        function setPristine() {
            vm.signalEditForm.$setPristine();
        }

        function enableSaveButton() {
            if (isFormValid() && isFormDirty()) {
                return true;
            }
            return false;
        }

        function isFormDirty() {
            if (vm.signalEditForm) {
                return vm.signalEditForm.$dirty;
            }
        }

        function setupDataTable() {
            vm.selected = [];
            vm.totalCount = {};
            vm.options = {
                rowSelection: true,
                multiSelect: false,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: true,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };


            vm.queryData = {
                orderBy: 'approachID',
                pageSize: 10,
                pageIndex: 1,
                filter: ''
            };
        }
        var bookmark;
        vm.searchChange = function (newValue, oldValue) {
            if (!oldValue) {
                bookmark = vm.queryData.pageIndex;
            }

            if (newValue !== oldValue) {
                vm.queryData.pageIndex = 1;
            }

            if (!newValue) {
                vm.queryData.pageIndex = bookmark;
            }
            vm.getApproaches();
        };
    }
}());