import angularMoment from 'angular-moment';
import ngFileSaver from 'angular-file-saver';
import 'md-steppers';

import * as chartJS from 'angular-chart.js';

(function () {
    'use strict';

    angular
        .module('app.spm', [
            angularMoment,
            ngFileSaver,
            'md-steppers',
            'md.data.table',
            'app.spm.core',
            'app.spm.help',
            'app.spm.dashboards',
            'app.spm.signal-events',
            'ngMaterialDateRangePicker',
            'app.spm.event-player',
            'app.spm.charts',
            'app.spm.link-pivot',
            // 'app.spm.routes',
            // 'app.spm.regions',
            'app.spm.signals',
            'app.spm.corridors',
            'app.spm.action-logs',
            // 'app.spm.reports',
            // 'app.spm.security',
            // 'nvd3'
            // datatables,
            chartJS
        ])
        .config(config);

    /** @ngInject */
    function config($stateProvider, $httpProvider) {
        $httpProvider.interceptors.push('commonHttpInterceptor');

        /*setup routes*/
        $stateProvider
            .state('app.spm', {
                abstract: true,
                url: '/spm'
            })
            .state('app.spm.dashboards', {
                abstract: true,
                url: '/dashboards'
            })
            .state('app.spm.config', {
                abstract: true,
                url: '/config'
            })
            .state('app.spm.measures', {
                abstract: true,
                url: '/measures'
            })
            .state('app.spm.charts', {
                abstract: true,
                url: '/charts'
            })
            .state('app.spm.reports', {
                abstract: true,
                url: '/reports'
            });
    }
    /** @ngInject */
})();
