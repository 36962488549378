﻿import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("queueLengthChartController", queueLengthChartController);

    function queueLengthChartController($state, $scope, $rootScope, $attrs, chartsService, $timeout, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 1;
        vm.chartLoading = false;
        vm.chartServerError = false;
        vm.isDataAvailable = false;
        vm.moment = moment;
        vm.nullishDate = "0001-01-01T00:00:00"

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input != "{}") {
                    var jsonData = JSON.stringify(input, null, 4);
                    $scope.loading = false;
                    vm.queueLengthWidget.processQueueLengthData(JSON.parse(jsonData, JSON.dateParser));
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        vm.queueLengthWidget = {
            selectedIndex: 0,
            chartArray: [],
            selectedTabChange: function (index) {
                vm.queueLengthWidget.selectedIndex = index;
            },
            addPlanLabels: function (primaryPlans) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 0:
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        var row1 = "Avg: " + parseFloat(plan.avgQueueLength).toFixed(2) + " ft / Max: " + parseFloat(plan.maxQueueLength).toFixed(2) + " ft";
                        var row2 = "Cycles: " + plan.totalCycles + " Spillback: " + plan.spillbacksInPlan;
                        plan.labels.push(planText, row1, row2);
                        plansCopy[i] = plan;
                    }
                }
                return plansCopy;
            },
            // getChart: function (planData) {
            //     var clonedChartSetup = angular.copy(vm.queueLengthWidget.chartDef);
            //     // clonedChartSetup.options.chart.planData = planData;
            //     return clonedChartSetup;
            // },
            //logic for taking server events and manipulating into format the chart is expecting
            processQueueLengthData: function (rawData) {
                var tabData = [];
                var searchObj = searchBarService.getSearchOptions();

                rawData.summaryData.length > 0 ? vm.isDataAvailable = true : vm.isDataAvailable = false;

                rawData.summaryData.forEach(function (phaseItem) {
                    var planList = phaseItem.plans;
                    var plansPerLane = {};

                    planList.forEach(function (plan) {
                        for (var lane in plan.perLaneSummary) {
                            //go through each lane plan summary nested in plan and pull out into lane keyed dictionary obj
                            var newPlan = {
                                startTime: plan.startTime,
                                endTime: plan.endTime,
                                planNumber: plan.planNumber,
                                laneSummary: plan.perLaneSummary[lane]
                            };
                            //add new array
                            if (!plansPerLane[lane])
                                plansPerLane[lane] = [];

                            plansPerLane[lane].push(newPlan);
                        }
                    });

                    var groupedDataPerLane = {};

                    //see if we have added other charts for this direction and get the obj
                    var directionTabObj = tabData.find(function (x) { return x.tabTitle == phaseItem.phaseDirection });
                    if (!directionTabObj) {
                        directionTabObj = {
                            tabTitle: phaseItem.phaseDirection,
                            charts: [],
                            isChart: true,
                            isDataAvailable: false
                        }
                        tabData.push(directionTabObj);
                    }

                    //add detector specific info
                    for (var lane in phaseItem.cyclesPerLane) {

                        var groupDataQueueDet = {
                            key: "Queue over Detector",
                            label: "Queue over Detector",
                            data: [],
                            type: 'scatter',
                            backgroundColor: '#3182bd',
                            pointStyle: 'rectRot',
                            yAxisID: '1'
                        };

                        if (chartsService.getChartComplexity() == 'Advanced') {
                            var groupDataClearDuration = {
                                key: "Clearance Duration (right axis)",
                                label: "Clearance Duration (right axis)",
                                data: [],
                                type: 'scatter',
                                yAxisID: '2',
                                borderColor: '#636363',
                                backgroundColor: 'rgba(99,99,99,.4)'
                            };
                            var groupDataFreeFlowDuration = {
                                key: "Free Flow Duration (right axis)",
                                label: "Free Flow Duration (right axis)",
                                data: [],
                                type: 'scatter',
                                yAxisID: '2',
                                backgroundColor: '#74c476'
                            };
                        }
                        var groupDataMaxQueueLength = {
                            key: "Queue Length (left axis)",
                            label: "Queue Length (left axis)",
                            data: [],
                            type: 'LineWithLine',
                            lineTension: 0,
                            bezierCurve: false,
                            steppedLine: true,
                            strokeWidth: 2,
                            pointRadius: 1,
                            fill: false,
                            xAxisID: 'x-axis-0',
                            borderColor: '#9e9ac8',
                            strokeColor: '#9e9ac8',
                            yAxisID: '1',
                        };
                        var groupDataSaturated = {
                            key: "Spillback",
                            label: "Spillback",
                            data: [],
                            type: 'bar',
                            barThickness: 0.7,
                            hideTooltip: true,
                            yAxisID: '1',
                            backgroundColor: 'rgb(255, 187, 120)',
                            borderColor: 'rgb(255, 187, 120)',
                            fillOpacity: 1
                        };

                        var cyclesForLane = phaseItem.cyclesPerLane[lane];
                        vm.tooltipData = [];
                        if (cyclesForLane.length > 0) {
                            var laneMaxQueueLength = Math.max.apply(Math, cyclesForLane.map(function (i) {
                                return i.queueLength;
                            }))

                            for (var cycle in cyclesForLane) {
                                var thisCycle = cyclesForLane[cycle];
                                var start = new Date(thisCycle.redStart);

                                vm.tooltipData.push({
                                    start: new Date(thisCycle.redStart),
                                    cycle: thisCycle
                                })
                                //add max queue length
                                groupDataMaxQueueLength.data.push({
                                    x: start,
                                    y: thisCycle.queueLength,
                                });

                                //add queue over detector
                                if (thisCycle.isQueueOnDetector) {
                                    groupDataQueueDet.data.push({
                                        x: start,
                                        y: laneMaxQueueLength,
                                    });
                                }
                                //add clearance duration
                                if (thisCycle.queueClearanceDuration > 0 && chartsService.getChartComplexity() == 'Advanced') {
                                    groupDataClearDuration.data.push({
                                        x: start,
                                        y: thisCycle.queueClearanceDuration
                                    });
                                }
                                //add free flow
                                if (thisCycle.freeFlowDuration > 0 && chartsService.getChartComplexity() == 'Advanced') {
                                    groupDataFreeFlowDuration.data.push({
                                        x: start,
                                        y: thisCycle.freeFlowDuration
                                    });
                                }
                                //add saturdated
                                if (thisCycle.isSpillback) {
                                    groupDataSaturated.data.push({
                                        x: start,
                                        y: laneMaxQueueLength
                                    });
                                }
                            }

                            if (!groupedDataPerLane[lane])
                                groupedDataPerLane[lane] = [];

                            groupedDataPerLane[lane].push(groupDataSaturated);
                            groupedDataPerLane[lane].push(groupDataMaxQueueLength);
                            groupedDataPerLane[lane].push(groupDataQueueDet);

                            if (chartsService.getChartComplexity() == 'Advanced') {
                                groupedDataPerLane[lane].push(groupDataClearDuration);
                                groupedDataPerLane[lane].push(groupDataFreeFlowDuration);
                            }
                        }
                    }

                    //Create the chart objects for each lane
                    for (var laneGroups in groupedDataPerLane) {
                        var avgQueueLengthTotal = 0, totalCycles = 0, totalSpillbacks = 0, totalMaxQueueLength = 0;

                        plansPerLane[laneGroups].forEach(function (plan) {
                            avgQueueLengthTotal += plan.laneSummary.avgQueueLength;
                            totalCycles += plan.laneSummary.totalCycles;
                            totalSpillbacks += plan.laneSummary.spillbacks;
                            if (plan.laneSummary.maxQueueLength > totalMaxQueueLength)
                                totalMaxQueueLength = plan.laneSummary.maxQueueLength;
                        });
                        avgQueueLengthTotal = avgQueueLengthTotal / plansPerLane[laneGroups].length;

                        directionTabObj.charts.push({
                            title: "Queue Length",
                            titleLine2: $scope.signal.description + " - " + phaseItem.phaseDirection + " " + phaseItem.movement + " - Lane " + laneGroups + " - Phase " + phaseItem.phaseNumber,
                            subLine1: "Queue Length Avg: " + parseFloat(avgQueueLengthTotal).toFixed(2) + " (ft) / Max: " + parseFloat(totalMaxQueueLength).toFixed(2) + " (ft)",
                            subLine2: "Total Cycles: " + totalCycles + " / Spillbacks: " + totalSpillbacks,
                            plans: vm.queueLengthWidget.addPlanLabels(planList),
                            tabTitle: phaseItem.phaseDirection,
                            dataset: groupedDataPerLane[laneGroups],
                            planColor: "#000",
                            flex: 100,
                            onDestroy: function () {
                                this.api = {};
                            },
                            onApiInit: function (apiObj) {
                                this.api = apiObj;
                                this.render(apiObj);
                            },
                            render: function (apiObj) {
                                apiObj.render(undefined, this.dataset, this.chartOptions);
                                vm.chart =
                                    vm.chartRendering = false;
                                this.isRendered = true;
                            },
                            hidePlanLabels: function (e, chart) {
                                chart.plansHidden = !chart.plansHidden;
                                chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                                Chart.defaults.global.togglePlans(this, chart.plansHidden);
                                this.plans.visible = false;
                                if (this.plansHidden) {
                                    this.plans.forEach(function (plan) {
                                        plan.labels2 = plan.labels;
                                        plan.labels = [];
                                    });

                                    this.planColor = "#fff";
                                } else {
                                    this.plans.forEach(function (plan) {
                                        plan.labels = plan.labels2;
                                    });
                                    this.planColor = "#000";
                                }
                                this.api.update();
                            },
                            isRendered: false,
                            api: {},
                            chartOptions: {
                                hidePlans: false,
                                useCrosshair: true,
                                animation: false,
                                tooltips: {
                                    mode: 'x',
                                    intersect: false,
                                    enabled: false,
                                    callbacks: {
                                        afterBody: function (t, d) {
                                            // return vm.tooltipData.find((x, index) => (new Date(x.start).getTime() >= new Date(t[0].label).getTime())
                                            //     && (new Date(vm.tooltipData[index + 1].start).getTime() < new Date(t[0].label).getTime())
                                            // )

                                            var currentCycle = undefined;

                                            vm.tooltipData.forEach(function (tt, index) {
                                                if ((new Date(tt.start).getTime() <= new Date(t[0].label).getTime())
                                                    && vm.tooltipData[index + 1]
                                                    && (new Date(vm.tooltipData[index + 1].start).getTime() > new Date(t[0].label).getTime())
                                                ) {
                                                    currentCycle = tt;
                                                    return;
                                                }
                                            });

                                            return currentCycle;
                                        }
                                    },
                                    custom: function (tooltipModel) {

                                        // Create element on first render
                                        var tooltipEl = document.getElementById('chartjs-tooltip');
                                        if (!tooltipEl) {
                                            tooltipEl = document.createElement('div');
                                            tooltipEl.id = 'chartjs-tooltip';
                                            tooltipEl.innerHTML = '<table></table>';
                                            document.body.appendChild(tooltipEl);
                                        }

                                        // Hide if no tooltip
                                        if (tooltipModel.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            return;
                                        }

                                        // Set caret Position
                                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                                        if (tooltipModel.yAlign) {
                                            tooltipEl.classList.add(tooltipModel.yAlign);
                                        } else {
                                            tooltipEl.classList.add('no-transform');
                                        }

                                        function getBody(bodyItem) {
                                            return bodyItem.lines;
                                        }
                                        if (tooltipModel.body) {

                                            var titleLines = tooltipModel.title[0];
                                            var header =
                                                "<thead>" +
                                                "<tr>" +
                                                "<td class='key'><strong>" + titleLines + "</strong></td>" +
                                                "</tr>" +
                                                "</thead>";
                                            var rows = "";

                                            if (tooltipModel.afterBody && tooltipModel.afterBody.length > 0) {
                                                var tt = tooltipModel.afterBody[0].cycle;
                                                rows += "<tr><td class='key'><strong> Model: </strong></td><td class='x-value'>" + tt.model + "</td></tr>";
                                            }
                                            tooltipModel.dataPoints.forEach(function (point, i) {
                                                if (point.label == titleLines) {
                                                    var titleArr = tooltipModel.body[i].lines[0].split(':');

                                                    if (titleArr[0].includes('Queue Length')) {
                                                        titleArr[0] = "Queue Length (ft)";
                                                    } else if (titleArr[0].includes('Clearance Duration')) {
                                                        titleArr[0] = "Clearance Duration";
                                                    } else if (titleArr[0].includes('Free Flow')) {
                                                        titleArr[0] = "Free Flow Duration";
                                                    }

                                                    rows += "<tr>" +
                                                        "<td class='key'><strong>" + titleArr[0] + "</strong></td><td class='x-value'>" + titleArr[1] + "</td>" +
                                                        "</tr>";
                                                }
                                            });


                                            // rows += ((tooltipModel.afterBody && tooltipModel.afterBody[0].value) ? tooltipModel.afterBody[0].value : undefined)
                                            if (tooltipModel.afterBody && tooltipModel.afterBody.length > 0) {
                                                var clearance = tooltipModel.body.find(x => x.lines[0].includes('Clearance'));
                                                var freeFlow = tooltipModel.body.find(x => x.lines[0].includes('Free Flow'));
                                                let clearanceRow, freeFlowRow = null;
                                                if (clearance && clearance.length > 0) {
                                                    clearanceRow = "<td class='key'>" + 'Queue Clearance Duration (sec): ' + "</td>" +
                                                        "<td class='x-value'><strong>" + clearance.split(":")[1] + "</strong></td>" +
                                                        "</tr>"
                                                }

                                                if (freeFlow && freeFlow.length > 0) {
                                                    freeFlowRow = "<td class='key'>" + 'Free Flow Duration (sec): ' + "</td>" +
                                                        "<td class='x-value'><strong>" + freeFlow.split(":")[1] + "</strong></td>" +
                                                        "</tr>"
                                                }

                                                var tt = tooltipModel.afterBody[0].cycle;
                                                if (tt.model == 'input-output') {
                                                    rows += "<tr><td class='key'><strong> Red Start Time: </strong></td><td class='x-value'>" + vm.moment(tt.redStart).format('MM/DD/yyyy hh:mm A') + "</td></tr>";
                                                    rows += "<tr><td class='key'><strong> Green Start Time: </strong></td><td class='x-value'>" + vm.moment(tt.greenStart).format('MM/DD/yyyy hh:mm A') + "</td></tr>";
                                                    (tt.lastVehicle !== vm.nullishDate ?   rows += "<tr><td class='key'><strong> Last Vehicle on Red: </strong></td><td class='x-value'>" + vm.moment(tt.lastVehicle).format('MM/DD/yyyy hh:mm A') + "</td></tr>" : "");
                                                    rows += "<tr><td class='key'><strong> Number of Vehicles on Red: </strong></td><td class='x-value'>" + tt.numOfVehiclesOnRed + "</td></tr>";
                                                } else if (tt.model == "breakpoint") {
                                                    rows +=
                                                        "<tr><td class='key'><strong>" + 'Spillback: ' + "</strong></td>" +
                                                        "<td class='x-value'>" + tt.isSpillback + "</td>" +
                                                        "</tr>" +
                                                        "<td class='key'><strong>" + 'Red Start Time: ' + "</strong></td>" +
                                                        "<td class='x-value'>" + moment(tt.value).format('MM/DD/yyyy hh:mm A') + "</td>" +
                                                        "</tr>";
                                                    if (tt.greenStart > tt.queueOnDetector) {
                                                        rows +=
                                                            "<tr><td class='key'><strong>" + 'Queue on Detector:' + "</strong></td>" +
                                                            "<td class='x-value'>" + vm.moment(tt.queueOnDetector).format('MM/DD/yyyy hh:mm A') + "</td>" +
                                                            "</tr>" +
                                                            "<td class='key'><strong>" + 'Green Start Time: ' + "</strong></td>" +
                                                            "<td class='x-value'>" + vm.moment(tt.greenStart).format('MM/DD/yyyy hh:mm A')  + "</td>" +
                                                            "</tr>";
                                                    }
                                                    else {
                                                        rows +=
                                                            "<tr><td class='key'><strong>" + 'Green Start Time: ' + "</strong></td>" +
                                                            "<td class='x-value'>" + vm.moment(tt.greenStart).format('MM/DD/yyyy hh:mm A') + "</td>" +
                                                            "</tr>" +
                                                            "<td class='key'><strong>" + 'Queue on Detector:' + "</strong></td>" +
                                                            "<td class='x-value'>" + vm.moment(tt.queueOnDetector).format('MM/DD/yyyy hh:mm A') + "</td>" +
                                                            "</tr>";
                                                    }
                                                    rows +=
                                                        "<tr><td class='key'><strong>" + 'Queue Discharge Wave:' + "</strong></td>" +
                                                        "<td class='x-value'>" + vm.moment(tt.queueDischageWave).format('MM/DD/yyyy hh:mm A') + "</td>" +
                                                        "</tr>" +
                                                        "<td class='key'><strong>" + 'Max Queue Time:' + "</strong></td>" +
                                                        "<td class='x-value'>" + vm.moment(tt.maxQueue).format('MM/DD/yyyy hh:mm A') + "</td>" +
                                                        "</tr>" +
                                                        "<td class='key'><strong>" + 'End Of Queue Discharge Wave: ' + "</strong></td>" +
                                                        "<td class='x-value'>" + vm.moment(tt.queueDischargeWaveEnd).format('MM/DD/yyyy hh:mm A') + "</td>" +
                                                        "</tr>" +
                                                        (clearanceRow ? clearanceRow : "")
                                                        +
                                                        (freeFlowRow ? freeFlowRow : "")
                                                        +
                                                        "<tr><td class='key'><strong>" + 'Discharge Shockwave Speed (mph): ' + "</strong></td>" +
                                                        "<td class='x-value'>" + tt.dischargeWaveSpeed + "</td>" +
                                                        "</tr>" +
                                                        "<td class='key'><strong>" + 'Departure Shockwave Speed (mph): ' + "</strong></td>" +
                                                        "<td class='x-value'>" + tt.departureWaveSpeed + "</td>" +
                                                        "</tr>";
                                                }

                                            }

                                            var innerHTML = "<table>" +
                                                header +
                                                "<tbody>" +
                                                rows +
                                                "</tbody>" +
                                                "</table>";

                                            var tableRoot = tooltipEl.querySelector('table');
                                            var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                            tableStyle += 'color: rgba(255,255,255, 1);';
                                            tableStyle += 'padding: 5px 10px;';

                                            tableRoot.style = tableStyle;
                                            tableRoot.innerHTML = innerHTML;
                                        }
                                        // `this` will be the overall tooltip
                                        var position = this._chart.canvas.getBoundingClientRect();
                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.position = 'absolute';
                                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                        if (position.left + window.pageXOffset + tooltipModel.caretX + 270 > position.width) {
                                            // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 70 + 'px';
                                            tooltipEl.style.left = window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 70 + 'px';
                                        }
                                        tooltipEl.style.top = ((position.y + 600) / 2) + 'px';
                                        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                        tooltipEl.style.pointerEvents = 'none';
                                    }
                                },
                                scales: {
                                    yAxes: [{
                                        id: '1',
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Queue Length (Distance)',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        type: 'linear',
                                        position: 'left',
                                        ticks: {
                                            // callback: function (d) {
                                            //     if (Math.floor(d) != d) return d3.format('.01f')(d);
                                            //     else return d;
                                            // }
                                        }
                                    },
                                    {
                                        type: 'linear',
                                        display: chartsService.getChartComplexity() == 'Advanced',
                                        position: 'right',
                                        id: '2',
                                        scaleLabel: {
                                            display: chartsService.getChartComplexity() == 'Advanced',
                                            labelString: 'Seconds',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        ticks: {
                                            // callback: function (d) {
                                            //     if (Math.floor(d) != d) return d3.format('.01f')(d);
                                            //     else return d;
                                            // }
                                        }
                                    }],
                                    xAxes: [{
                                        stacked: true,
                                        type: 'time',
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Date',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },

                                        gridLines: {
                                            display: false
                                        },
                                        ticks: {
                                            autoSkip: true,
                                            autoSkipPadding: 50,
                                            minRotation: 0,
                                            maxRotation: 0,
                                            // callback: function (d) {
                                            //     return moment(d).format('HH:MM A');
                                            // },
                                        }
                                    }],
                                },
                                legend: {
                                    display: true,
                                    align: 'end',
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    zoom: {
                                        pan: {
                                            enabled: false,
                                            mode: 'x',

                                            rangeMin: {
                                                x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        },
                                        zoom: {
                                            enabled: true,
                                            drag: true,
                                            speed: 0.1,
                                            mode: 'x',

                                            rangeMin: {
                                                x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        }
                                    }
                                },
                            },
                        });
                    }
                });

                if (tabData) {
                    tabData.forEach(function (tab) {
                        if (tab.charts && tab.charts.length > 0) {
                            tab.isDataAvailable = true;
                        }
                        else {
                            tab.isDataAvailable = false;
                        }
                    });
                }
                vm.queueLengthWidget.chartArray = tabData;
                vm.chartLoading = false;
                vm.chartRendering = true;
            }
        }
        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.queueLengthWidget.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions)
                .then(function (data) {
                    vm.chartServerError = false;
                    $scope.loading = false;
                    vm.chartLoading = true;
                    //JSON stringify the server data and then process it for the chart
                    // vm.rawData = JSON.stringify(data, null, 4);
                    vm.queueLengthWidget.processQueueLengthData(data);
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;;
                });
        }
    }
}());
