(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("chartsSignalController", chartsSignalController);

    function chartsSignalController($state, $rootScope, $scope, $stateParams, $element, $timeout, signalService, environmentConfig, $mdSidenav, searchBarService, chartsService) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        });
        $rootScope.chartServerError = false;
        vm.metricTypeId = $stateParams.metricTypeId;
        vm.chartCompatible = true;
        vm.stepper = {};
        vm.searchBarService = searchBarService;
        vm.chartServerError = false;
        vm.chartErrorReason = "";
        vm.stackPcd = $stateParams.stackPcd;
        vm.stackSplitMonitor = $stateParams.stackSplitMonitor;
        vm.getMetric = getMetric;
        vm.chartUserOptions = {};
        vm.chartsService = chartsService;
        vm.tabHelper = {
            tabs: [],
            selectedIndex: 0,
            addTab: function (title, path, disabled, idx) {
                return vm.tabHelper.tabs.push({ index: idx, title: title, includePathStr: path, disabled: disabled });
            },
            removeTab: function (tab) {
                var index = vm.tabHelper.tabs.indexOf(tab);
                vm.tabHelper.tabs.splice(index, 1);
            }
        };
        vm.signals = {
            signalID: {},
            signalName: {},
            selected: {},
            getSearchBarData: function () {
                //on initial load, we need to populate our data with the focus data from service
                var options = searchBarService.getSearchOptions();
                vm.timeOptions = options.timeOptions;
                if (options) {
                    if (options.signal && options.signal.signalID) {
                        vm.signals.signalID = options.signal.signalID;
                        vm.signals.signalName = options.signal.description;
                        vm.signals.selected = options.signal;
                        vm.signals.selected.description = options.signal.description;
                    }
                    else {
                        vm.signals.signalID = "";
                        vm.signals.signalName = "";
                        vm.signals.selected = undefined;
                    }
                }
            }
        };
        vm.charts = {
            imageLocations: [],
            loading: true,
            api: {},
            interactiveLoading: false,
            chartDirectiveData: {},
            rawData: {},
            clearChartData: function () {
                vm.charts.chartDirectiveData = {};
                vm.charts.rawData = {};
            },
            isGalleryOpen: false,
            showGallery: function (i) {
                if (angular.isDefined(i)) {
                    this.photoOpts.index = i;
                }
                this.isGalleryOpen = true;
            },
            closeGallery: function () {
                this.isGalleryOpen = false;
            },
            onApiInit: function (apiObj) {
                vm.charts.api = apiObj;
                if (vm.configuration.pendingDataUpdate) {
                    vm.configuration.updateNewChartData();
                    vm.configuration.pendingDataUpdate = false;
                }
            },
            onApiDestroy: function () {
                vm.charts.api = {};
            },
            photoOpts: {
                index: 0
            },
            resultData: {},
        };
        vm.configuration = {
            dates: {
                startDate: new Date(2017, 4, 14),
                endDate: new Date(2017, 4, 16),
                startTime: new Date(Date.UTC(0, 0, 0, 16, 0, 0)),
                endTime: new Date(Date.UTC(0, 0, 0, 22, 0, 0))
            },
            loadChart: function (update) {
                if (!vm || !vm.isFiltering)
                    return;

                vm.charts.loading = true;
                vm.notSupportedReason = null;
                vm.isSupportedForSignal = null;
                // vm.metricTypeId = 6;

                vm.chartsService.getSupportedChartOptionsById(vm.signals.signalID, vm.metricTypeId).then(function (data) {
                    vm.isSupportedForSignal = data.chart.isSupportedForSignal;
                    vm.notSupportedReason = data.chart.notSupportedReason;

                    if (vm.isSupportedForSignal) {
                        vm.chartsService.getChartOptionsForSignal(vm.signals.signalID, vm.metricTypeId)
                            .then(function (data) {
                                data.stackPcd = vm.stackPcd;
                                data.showAsStacked = vm.stackSplitMonitor;
                                vm.configuration.data = data
                                //launch the chart
                                vm.configuration.submit();
                            })
                            .catch(function (err) {
                                if (vm)
                                    vm.chartCompatible = false;
                                return true; // or return { error: true, reason: "some reason" };
                            });
                    }

                }).catch(function (error) {
                    if (vm)
                        vm.chartServerError = false;
                });
            },
            data: [],
            openInNewTab: false,
            spmChartOptions: {},
            pendingDataUpdate: false,
            submit: function () {
                vm.charts.clearChartData();
                //update time options
                var options = vm.searchBarService.getSearchOptions();
                vm.timeOptions = options.timeOptions;
                if (vm.configuration.openInNewTab) {
                    vm.configuration.openInNewTab = false;
                    //route to charts but pass in config object
                    //put this into service also
                    var stringifyVm = angular.copy(vm);
                    sessionStorage.setItem("__SPM_MEASURES_NEW_MODEL", JSON.stringify(stringifyVm));
                    //put this into separate service
                    var url = $state.href('app.spm.measures.signal-popout');
                    window.open(url, '_blank', "height=800,width=1250");
                    return;
                }
                vm.charts.imageLocations = [];
                vm.charts.loading = true;
                $rootScope.loadingProgress = true;
                vm.charts.interactiveLoading = true;

                vm.configuration.spmChartOptions = vm.configuration.createOptionsObject();

                //set bin 
                switch (vm.timeOptions.bin) {
                    case "FifteenMinute":
                        vm.configuration.spmChartOptions.selectedBinSize = 15;
                        break;
                    case "ThirtyMinute":
                        vm.configuration.spmChartOptions.selectedBinSize = 30;
                        break;
                    case "Hour":
                        vm.configuration.spmChartOptions.selectedBinSize = 60;
                        break;
                }

                //get the chart data and set it to scope variable, which will get passed into
                //each chart's directive instead of the charts fetching their own data
                var respArray = false;
                if (vm.configuration.spmChartOptions.metricTypeID === 6 ||
                    vm.configuration.spmChartOptions.metricTypeID === 8 ||
                    vm.configuration.spmChartOptions.metricTypeID === 9 ||
                    vm.configuration.spmChartOptions.metricTypeID === 11) {
                    respArray = true;
                }

                vm.chartsService.getChartData(vm.configuration.spmChartOptions, respArray)
                    .then(function (data) {
                        vm.charts.chartDirectiveData = data;
                        vm.chartServerError = false;

                        if (vm.charts.api && vm.charts.api.updateData) {
                            vm.configuration.updateNewChartData();
                            vm.configuration.pendingDataUpdate = false;
                        }
                        else {
                            vm.configuration.pendingDataUpdate = true;
                        }

                        //todo: update notification bar
                        //notificationApi.updateChartOverviewCard(data); 
                    })
                    .catch(function (error) {
                        $rootScope.loading = false;
                        if (vm) {
                            vm.chartServerError = true;
                            if (typeof error.data === 'string' || error.data instanceof String)
                                vm.chartErrorReason = error.data;
                        }
                    });
            },
            updateNewChartData: function () {
                if (vm.charts.api && vm.charts.api.updateData) {
                    vm.charts.api.updateData(vm.charts.chartDirectiveData);
                }
                $rootScope.loading = false;
                $rootScope.loadingProgress = false;
                vm.charts.loading = false;
                vm.charts.interactiveLoading = false;
                vm.chartServerError = false;
                // here add catch statement
                //in all other html tags add ng-if(vm.charts.chartDirectiveData) -> if yes show chart data if no show nothing 
            },
            createOptionsObject: function () {
                var sendData = vm.configuration.data;
                sendData.endDate = new Date(vm.timeOptions.currentFilter.endDateAndTime).toLocaleString();
                sendData.startDate = new Date(vm.timeOptions.currentFilter.startDateAndTime).toLocaleString();
                sendData.signalID = vm.signals.signalID;
                sendData.metricTypeID = vm.metricTypeId;

                if (vm.chartUserOptions) {
                    switch (vm.metricTypeId) {
                        //pcd
                        case 6:
                            sendData.carsInPlatoon = vm.chartUserOptions.carsInPlatoon;
                            sendData.headwayInSec = vm.chartUserOptions.headwayInSec;
                            sendData.showCarsInQueue = vm.chartUserOptions.showCarsInQueue;
                            break;
                        case 11:
                            sendData.severeLevelSeconds = vm.chartUserOptions.severeLevelSeconds;
                            sendData.forceDetectionTypeId = vm.chartUserOptions.forceDetectionTypeId;
                            sendData.speedFilter = vm.chartUserOptions.speedFilter;
                            break;
                    }
                }

                delete sendData['$promise'];
                delete sendData['$resolved'];
                return sendData;
            },
        }

        vm.setupSearchBar = setupSearchBar;
        function setupSearchBar() {
            //set options for the search bar
            vm.searchBarConfig = {};
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                    case "signal":
                    case "configuration":
                        vm.isFiltering = searchBarService.isFiltering();
                        if (vm.signals)
                            vm.signals.getSearchBarData();
                        if (vm.configuration && vm.isFiltering)
                            vm.configuration.loadChart(true);
                        break;
                    case "state":
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                }
            });

            vm.chartsService.subscribe($scope, function onChange(ev, options) {
                if (!vm)
                    return;

                if (options) {
                    vm.chartUserOptions = options;
                    //update chart with new options
                    vm.configuration.submit();
                }
            });

        }

        vm.setSearchbarBarHeader = function () {
            vm.tabHelper.tabs = [];
            var headerText = "";
            var maxDays = 2;
            var showBinConfig = false, showOptions = false, showWeekday = false, notifyOnBinChange = true, skipStepsPerBin = false, showAdvancedChart = false;
            var badgeText = "", customOptionsHtmlPath = "";


            switch (vm.metricTypeId) {
                case 1:
                    //phase termination
                    headerText = "Phase Terminations";
                    showBinConfig = true;
                    skipStepsPerBin = false;
                    showOptions = true;
                    break;
                case 2:
                    //split monitor
                    headerText = "Split Monitor";
                    skipStepsPerBin = true;
                    break;
                case 3:
                    //ped delay
                    headerText = "Pedestrian Delay";
                    skipStepsPerBin = true;
                    break;
                case 4:
                    //preempt
                    headerText = "Preemption Details";
                    skipStepsPerBin = true;
                    maxDays = 7;
                    break;
                case 5:
                    //tmcoptions
                    headerText = "Turning Movement Counts";
                    showBinConfig = true;
                    showOptions = true;
                    skipStepsPerBin = false;
                    maxDays = 7;
                    break;
                case 6:
                    //pcdoptions
                    vm.chartUserOptions = vm.chartsService.getPcdOptions();
                    headerText = "Purdue Coordination";
                    showBinConfig = true;
                    showOptions = true;
                    skipStepsPerBin = false;
                    customOptionsHtmlPath = "app/main/spm/charts/signals/directives/pcd-chart/pcd-options.html";
                    notifyOnBinChange = true;
                    break;
                case 7:
                    //apprvolume
                    headerText = "Approach Volume";
                    showAdvancedChart = true;
                    showBinConfig = true;
                    showOptions = true;
                    skipStepsPerBin = false;
                    maxDays = 7;
                    break;
                case 8:
                    //approach delay
                    headerText = "Approach Delay";
                    showBinConfig = true;
                    showOptions = true;
                    skipStepsPerBin = false;
                    maxDays = 7;
                    break;
                case 9:
                    //aoroptions
                    headerText = "Arrivals on Red";
                    showAdvancedChart = true;
                    showBinConfig = true;
                    showOptions = true;
                    skipStepsPerBin = false;
                    maxDays = 7;
                    break;
                case 10:
                    //appr speed options
                    break;
                case 11:
                    //pcdoptions
                    vm.chartUserOptions = vm.chartsService.getYellowRedOptions();
                    headerText = "Red Light Runners";
                    showBinConfig = true;
                    showOptions = true;
                    maxDays = 7;
                    skipStepsPerBin = false;
                    customOptionsHtmlPath = "assets/templates/rlr/yellow-red-options.html";
                    notifyOnBinChange = true;
                    break;
                case 12:
                    //split fail
                    headerText = "Purdue Split Failure";
                    showBinConfig = true;
                    showOptions = true;
                    skipStepsPerBin = false;
                    showAdvancedChart = true;
                    notifyOnBinChange = true;
                    break;
                case 20:
                    //queue Length
                    headerText = "Queue Length";
                    showAdvancedChart = true;
                    showOptions = true;
                    skipStepsPerBin = true;
                    break;
                default:
                    //split fail
                    break;
            }
            //set default config into searchbar
            vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
                //header information
                header: {
                    show: true,
                    text: headerText,
                    badgeText: badgeText
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Signals',
                helpJsonPropertyPath: "METRIC." + vm.getMetric(vm.metricTypeId.toString(), vm.stackSplitMonitor),
                metricTypeId: vm.metricTypeId,
                showCurrentFilterDates: true,
                showHelp: true,
                showExcel: true,
                showSignalNotificationTabs: true,
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TD",
                    defaultTodTemplate: "FD",
                    showWeekdayFilter: showWeekday,
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    enableAdvancedTod: false,
                    maxDayRange: maxDays
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: showOptions,
                    showBinConfig: showBinConfig,
                    showAdvancedChart: showAdvancedChart,
                    skipStepsPerBin: skipStepsPerBin,
                    notifyOnBinChange: notifyOnBinChange,
                    customHtmlPath: customOptionsHtmlPath
                }
            });
            vm.isFiltering = searchBarService.isFiltering();

        }
        vm.setSearchbarBarHeader();

        function getMetric(metricID, stackSplitMonitor) {
            var res = metricID;
            if (stackSplitMonitor === false) {
                res = res.concat("a");
            }
            return res;
        }

        var inVm = "";
        //put this into service
        if (sessionStorage.getItem("__SPM_MEASURES_NEW_MODEL")) {
            inVm = JSON.parse(sessionStorage.getItem("__SPM_MEASURES_NEW_MODEL"), JSON.dateParser);
            sessionStorage.removeItem("__SPM_MEASURES_NEW_MODEL");
        }

        if (inVm) {
            $.extend(true, vm, inVm);
            vm.configuration.submit();
        }

        if (vm.isFiltering) {
            //initially load the options
            vm.signals.getSearchBarData();
            vm.configuration.loadChart(false);
        }
        vm.setupSearchBar();
    }


}());