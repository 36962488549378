(function () {
    "use strict";

    angular.module("app.spm.dashboards.insights")
        .factory("insightsResource", ["$resource", "environmentConfig", "$cacheFactory", function ($resource, environmentConfig, $cacheFactory) {
            var insightsUrl = environmentConfig.apiUrl + "/api/insights/";
            var insightsCache = $cacheFactory('insightsResource');
            return $resource(insightsUrl, {}, {
                getDefinitions: { method: "GET", url: insightsUrl + "getDefinitions" },
                getAllSignalStates: { method: "GET", url: insightsUrl + "getAllSignalStates", isArray: true, cache: false },
                getSystemInsights: { method: "GET", url: insightsUrl + "getSystemInsights?"},
                getSystemInsightsByCategory: { method: "GET", url: insightsUrl + "getSystemInsightsByCategory?"},
                getSystemInsightsBySignal: { method: "GET", url: insightsUrl + "getSystemInsightsBySignal?"},
                setAcknowledge: {method: "POST" , url: insightsUrl + "acknowledge"},
                setIgnore: {method: "POST" , url: insightsUrl + "ignore"},
                setUnignore: {method: "POST" , url: insightsUrl + "unignore"},
                updateAcknowledge: {method: "POST" , url: insightsUrl + "updateAcknowledge"},
                removeAcknowledge: {method: "POST" , url: insightsUrl + "unacknowledge"},
                runInsightQuery: {method: "GET" , url: insightsUrl + "runInsightQuery?"},
                getInsightQueryResults: {method: "GET" , url: insightsUrl + "getQueryResults", isArray: true},
                deleteInsightQuery: {method:'DELETE',  url:insightsUrl + "deleteInsightQuery"}
            });
        }]);
}());