(function () {
    "use strict";

    angular
        .module("app.spm.dashboards.user-settings")
        .service("userSettingsService", userSettingsService);

    function userSettingsService(userSettingsResource, $state, $rootScope, $mdToast) {

        var categories = new Map();
        
        var dashboardTypeIds = Object.freeze({
            CorridorPerformance: 1,
            CorridorHistoricalTravelTime: 2,
            CorridorTravelTime: 3,
            CorridorPurdueLinkPivot: 4,
            SignalOverview: 5,
            SignalPerformance: 6,
            SignalExecutive: 7,
            SignalEvents: 8,
            SignalTrends: 9,
            Insights: 9,
            DetectorConfigSuggestions: 11,
        });

        function getUserSettings() {
            return userSettingsResource.getUserSettings(
                function (data) {
                    return data;
                }).$promise;
        }

        function setUserSettings(userSettings) {
            return userSettingsResource.setUserSettings(userSettings,
                function (data) {
                    showSavedToast();
                    notify('UserSettingsSaved')
                    return data;
                }).$promise;
        }

        function setReportSettings(reportSettings) {
            return userSettingsResource.setReportSettings(reportSettings,
                function (data) {
                    showSavedToast();
                    return data;
                }).$promise;
        }

        function showSavedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelector('#layout-vertical-navigation'))
                    .textContent('User settings saved successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }
        
        function notify(typeOfChange) {
            if (!sessionStorage.getItem('__change_notifications_paused')) {
                $rootScope.$emit('user-settings-change-event', typeOfChange);
            }
        }


        return {
            getUserSettings: getUserSettings,
            setUserSettings: setUserSettings,
            dashboardTypeIds: dashboardTypeIds,
            categories: categories,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('user-settings-change-event', callback);
                scope.$on('$destroy', handler);
            },
            setReportSettings: setReportSettings
        }
    }
})();
