﻿(function () {
    "use strict";

    angular.module("app.spm.core")
        .factory("dataExportResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            var dataExportUrl = environmentConfig.apiUrl + "/api/DataExport/";
            return $resource(dataExportUrl, {}, {
                getRawCsvFile: {
                    method: "GET", url: dataExportUrl + "getRawCsvFile", responseType: 'arraybuffer',
                    transformResponse: function (data, headers) {
                        headers = headers();
                        return {
                            response: new Blob([data], { type: "text/csv" }),
                            fileName: headers['x-filename']
                        };
                    }
                },
                getDashboardExcelFile: {
                    method: "GET", url: dataExportUrl + "getDashboardExcelFile", responseType: 'arraybuffer',
                    transformResponse: function (data, headers) {
                        headers = headers();
                        return {
                            response: new Blob([data], { type: "text/xlsx" }),
                            fileName: headers['x-filename']
                        };
                    }
                },
                getSignalConfigExcelFile: {
                    method: "GET", url: dataExportUrl + "getSignalConfigExcelFile", responseType: 'arraybuffer',
                    transformResponse: function (data, headers) {
                        headers = headers();
                        return {
                            response: new Blob([data], { type: "text/xlsx" }),
                            fileName: headers['x-filename']
                        };
                    }
                },
                getCorridorConfigExcelFile: {
                    method: "GET", url: dataExportUrl + "getCorridorConfigExcelFile", responseType: 'arraybuffer',
                    transformResponse: function (data, headers) {
                        headers = headers();
                        return {
                            response: new Blob([data], { type: "text/xlsx" }),
                            fileName: headers['x-filename']
                        };
                    }
                },
                getExcelFile: {
                    method: "GET", url: dataExportUrl + "getExcelFile", responseType: 'arraybuffer',
                    transformResponse: function (data, headers) {
                        headers = headers();
                        return {
                            response: new Blob([data], { type: "text/xlsx" }),
                            fileName: headers['x-filename']
                        };
                    }
                },
                getInsightsExcelFile:{
                    method: "GET", url: dataExportUrl + "getInsightsExcelFile", responseType: 'arraybuffer',
                    transformResponse: function (data, headers) {
                        headers = headers();
                        return {
                            response: new Blob([data], { type: "text/xlsx" }),
                            fileName: headers['x-filename']
                        };
                    }
                },
            });
        }]);
}());
