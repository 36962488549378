﻿import * as CALLBACK from 'raw-loader!./callback.html'; 

(function () {
    'use strict';

    angular
        .module('traffop.callback', [])
        .config(config)

    /** @ngInject */
    function config($stateProvider) {
        // State
        $stateProvider.state('callback', {
                url: '/callback',
                controller: 'CallbackController',
                template: CALLBACK,
                controllerAs: 'vm'
            });
        // Translation
    }
})();

