﻿(function () {
    'use strict';

    angular
        .module('app.spm.signals')
        .controller('signalAddController', signalAddController);

    /** @ngInject */
    function signalAddController($rootScope, $scope, $element, $state, $mdDialog, signalService, lookups, agencySettings, searchBarService, breadcrumbNavigationService) {

        var vm = this;
        vm.signal = {};
        vm.close = close;
        vm.saveSignal = saveSignal;
        vm.notUsaDialog = notUsaDialog;
        vm.hasLatLng = false;
        vm.mapApi = {};
        vm.lookups = lookups;
        vm.agencySettings = agencySettings;
        vm.isAgencyUsingLocalSignalConfig = isAgencyUsingLocalSignalConfig;
        vm.mapOpen = false;
        vm.buttonText = 'auto locate';
        vm.errorMessage = "";
        vm.signalInfoText = 'Click the button to auto locate the signal based on street names. Tip: if your entry is correct, you won\'t need to fill out coordinates.'
        vm.coordinatesHoverText = 'To manually input the latitude and the longitude, first input the Primary and Secondary Name and Auto Locate.'
        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.noResults = false;
        const geocoder = new google.maps.Geocoder();
        vm.drawSignal = drawSignal;
        vm.signalDragendCallback = signalDragendCallback;
        vm.checkSignal = checkSignal;
        // vm.setPristine = setPristine;
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
            },
            //search bar options
            showSearchBar: false,
        });

        $rootScope.$on('no results geocode', function () {
            vm.noResults = true
        });

        function checkSignal(customID) {
            vm.signalExists = signalService.checkSignalExists(customID);
        }

        function signalDragendCallback(signal) {
            $scope.$apply();
        }

        function drawSignal() {
            vm.coordinatesHoverText = '';
            vm.mapOpen = true;
            vm.buttonText = 'update';
            vm.signalInfoText = 'If you manually override the position or change either the coordinates or street names, click the button to update the map (and coordinates).'
            if (vm.signal && vm.mapApi.drawNewSignal) {
                vm.mapApi.drawNewSignal(vm.signal)
            }
        }

        function isAgencyUsingLocalSignalConfig() {
            return vm.agencySettings.isLocalConfigFileEnabled;
        }

        function close() {
            $mdDialog.cancel(false);
        }

        $scope.ok = function () {
            $mdDialog.hide({ message: 'Success' });
        };

        function notUsaDialog() {
            $mdDialog.show(

                $mdDialog.confirm()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title('The signal you are trying to add is located outside of the United States borders')
                    .multiple(true)
                    .textContent('Are you sure you want to do that?')
                    .ariaLabel('Error during saving')
                    .ok('Yes, I\'m sure')
                    .cancel('Cancel')
            ).then(function () {
                addSignalAndReturnToEdit(vm.signal);
            }, function () { });
            return;
        }

        function saveSignal(lat, lng) {
            // TODO: Permenant solution to automatically generate new signal IDs on the backend. 
            vm.signal.signalID = vm.signal.customID;
            geocoder.geocode({ location: { lat: parseFloat(lat), lng: parseFloat(lng) } }, (results, status) => {
                if (status === "OK") {
                    if (results[0]) {
                        let isInUs = results[0].address_components.filter(location => location.short_name == 'US')
                        if (isInUs && isInUs.length > 0) {
                            addSignalAndReturnToEdit(vm.signal);
                        } else {
                            vm.notUsaDialog();
                        }
                    } else {
                        window.alert("No results found");
                    }
                } else {
                    window.alert("Geocoder failed due to: " + status);
                }
            });
        }

        function addSignalAndReturnToEdit(signal) {
            signalService.createSignal(signal)
                .then(function (respSignal) {
                    vm.errorMessage = "";
                    $scope.ok();
                    breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals.edit', { signal: respSignal, lookups: vm.lookups, agencySettings: vm.agencySettings });
                }).catch(function (e) {
                    console.error(e);
                    vm.errorMessage = 'Error: ' + e.data + '!';
                });
        }
    }
})();
