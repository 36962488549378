import * as MAINTENANCE from 'raw-loader!./dashboard-insights.html';
import * as DASHBOARDINSIGHTS from 'raw-loader!./dashboard-insights.html'
import * as INSIGHTSQUERY from 'raw-loader!./../query-tester/insights-query.html';

(function () {
    'use strict';

    angular
        .module('app.spm.dashboards.insights',
            [
                // 3rd Party Dependencies
               
            ]
        )
        .config(config)
    //    .run(runBlock)

    /** @ngInject */

    // var stateProv = null;

    // function runBlock(userSettingsService, $interval) {
    //     var setRoutesInterval = $interval(function() {
    //         if (sessionStorage.userID && userSettingsService) {
    //             // use this:
    //             // insightsService.getInsightsDefintions().then(function (data) {

    //             userSettingsService.getUserSettings().then(function (userSettings) {
    //                 var availableCategories = userSettings.insightSettings.enabledDashboardInsightCategories;
    //                 for (let i = 0; i < availableCategories.length; i++) {
    //                     stateProv
    //                         .state(`app.spm.dashboards.insights.${availableCategories[i].name.replace(/\s+/g, '-').toLowerCase()}`, {
    //                             url: `/${availableCategories[i].name.replace(/\s+/g, '-').toLowerCase()}`,
    //                             views: {
    //                                 'content@app': {
    //                                     templateUrl: `app/main/spm/dashboards/insights/view/dashboard-insights.html`,
    //                                     controller: 'DashboardInsightsController as vm'
    //                                 }
    //                             },
    //                             params: {
    //                                 insightCategory: availableCategories[i].insightCategoryID
    //                             }
    //                         });
    //                 }
    //             }).catch(err => console.error(err))
    //             $interval.cancel(setRoutesInterval);
    //         }
    //     }, 500)
    // }

    function config($stateProvider, msApiProvider, $translatePartialLoaderProvider) {
        // stateProv = $stateProvider;
        // var test = insightDashboardProvider;
        // userSettingsService.getUserSettings().then(function (userSettings) {
        //     var availableCategories = userSettings.insightSettings.enabledDashboardInsightCategories;
        //     for (let i = 0; i < availableCategories.length; i++) {
        //         $stateProvider
        //             .state(`app.spm.dashboards.insights.${availableCategories[i].name.replace(/\s+/g, '-').toLowerCase()}`, {
        //                 url: `/${availableCategories[i].name.replace(/\s+/g, '-').toLowerCase()}`,
        //                 views: {
        //                     'content@app': {
        //                         templateUrl: `app/main/spm/dashboards/insights/view/dashboard-insights.html`,
        //                         controller: 'DashboardInsightsController as vm'
        //                     }
        //                 },
        //                 // params: {
        //                 //     insightCategory: '3'
        //                 // }
        //             });
        //     }
        // });
        $stateProvider
            .state('app.spm.dashboards.insights', {
                url: '/insights',
            })
            .state('app.spm.dashboards.insights.data-insights', {
                url: '/data-insights',
                views: {
                    'content@app': {
                        template: DASHBOARDINSIGHTS,
                        controller: 'DashboardInsightsController as vm'
                    }
                },
                params: {
                    insightCategory: '3'
                }
            })

            .state('app.spm.dashboards.insights.configuration-insights', {
                url: '/configuration-insights',
                views: {
                    'content@app': {
                        template: DASHBOARDINSIGHTS,
                        controller: 'DashboardInsightsController as vm'
                    }
                },
                params: {
                    insightCategory: '4'
                }
            })

            .state('app.spm.dashboards.insights.maintenance-insights', {
                url: '/maintenance-insights',
                views: {
                    'content@app': {
                        template: MAINTENANCE,
                        controller: 'DashboardInsightsController as vm'
                    }
                },
                params: {
                    insightCategory: '1'
                }
            })

            .state('app.spm.dashboards.insights.operational-insights', {
                url: '/operational-insights',
                views: {
                    'content@app': {
                        template: DASHBOARDINSIGHTS,
                        controller: 'DashboardInsightsController as vm'
                    }
                },
                params: {
                    insightCategory: '5'
                }
            })


            .state('app.spm.dashboards.insights.equipment-insights', {
                url: '/equipment-insights',
                views: {
                    'content@app': {
                        template: DASHBOARDINSIGHTS,
                        controller: 'DashboardInsightsController as vm'
                    }
                },
                params: {
                    insightCategory: '6'
                }
            })
            .state('app.spm.dashboards.insights.performance-insights', {
                url: '/performance-insights',
                views: {
                    'content@app': {
                        template: DASHBOARDINSIGHTS,
                        controller: 'DashboardInsightsController as vm'
                    }
                },
                params: {
                    insightCategory: '2'
                }
            })

        $stateProvider
            .state('app.spm.dashboards.insights-query', {
                url: '/insights-query',
                views: {
                    'content@app': {
                        template: INSIGHTSQUERY,
                        controller: 'InsightsQueryController as vm'
                    }
                }
            });

        $translatePartialLoaderProvider.addPart('insights');


    }

})();