(function () {
    "use strict";

    angular.module("app.spm.charts")
        .factory("chartsResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            var chartApi = environmentConfig.apiUrl + "/api/Charts/";

            return $resource(chartApi, {}, {
                getMetrics: {
                    method: "GET", 
                    isArray: true,
                    cache: true,
                    url: chartApi + "metricList/:id"
                },
                getMetricOptions: {
                    method: "GET", 
                    cache: true,
                    url: chartApi + "options/:id"
                },
                getSupportedChartOptions: {
                    method: "GET",
                    cache: true,
                    url: chartApi + "supportedCharts/:id"
                },
                getSupportedChartOptionsById: {
                    method: "GET",
                    cache: true,
                    url: chartApi + "supportedCharts/:signalId/:metricId"
                },
                getChartOptionsRaw: {
                    method: "GET",
                    url: chartApi + "getChart?",
                    cache: false
                },
                getChartOptionsRawArray: {
                    method: "GET",
                    isArray: true,
                    url: chartApi + "getChart?",
                    cache: false
                },
                getRedGreenAllocationData: {
                    method: "GET",
                    cache: true,
                    url: chartApi + "data/params?"
                },
                getChartOptionsForSignal: {
                    method: "GET", 
                    cache: true,
                    url: chartApi + "options/:signalId/:metricId"
                }
            });
        }]);
}());
