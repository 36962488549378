import moment from 'moment';
import * as DETAILSDIALOG from 'raw-loader!./details-dialog.html'
import * as ACKNOWLEDGEPANEL from 'raw-loader!./acknowledge-event.panel.html'
(function () {
    'use strict';
    var app = angular
        .module('app.spm.dashboards.insights')
        .controller('DashboardInsightsController', DashboardInsightsController);

    //setup startFrom pagination filter
    app.filter('startFrom', function () {
        return function (input, pageData) {
            if (!input || input.length <= 0)
                return input;
            var pageSize = pageData.size;
            var page = pageData.pageIndex;
            return input.slice((page - 1) * pageSize, page * pageSize);
        }
    });
    /** @ngInject */
    function DashboardInsightsController($scope, $mdDialog, dataExportService, $stateParams, helpService, searchBarService, signalService, userService, insightsService, newWindowService,
        InsightConstants, $translate, $element, $window, userSettingsService, breadcrumbNavigationService) {
        var vm = this;

        $scope.$on("$destroy", function () {
            vm = null;
            sessionStorage.removeItem('segmentData');
        });
        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.loggedInCity = sessionStorage.getItem("userName");
        vm.selectedinsightsIndex = 0;
        vm.insightsOverviewCards = [];
        vm.insightsCards = [];
        vm.signal = {};
        vm.timeOptions = {};
        vm.getNewTimeFrame = getNewTimeFrame;
        vm.typeOfClass = "glowing-border-success";
        vm.findings = "No findings ";
        vm.findingsOfDetectors = "No findings";
        vm.typeOfStatus = ["ok", "good", "bad"];
        vm.percentage = 0;
        $scope.loading = true;
        vm.cardServerError = false;
        vm.tabName = [];
        vm.setupDataTable = setupDataTable;
        vm.setupSearchBar = setupSearchBar;
        vm.setSearchOptions = setSearchOptions;
        vm.getInsightsDefinitions = getInsightsDefinitions;
        vm.removeInsightsCards = removeInsightsCards;
        vm.saveAckItem = saveAckItem;
        vm.updateAckItem = updateAckItem;
        vm.removeAckItem = removeAckItem;
        vm.setIgnore = setIgnore;
        vm.setUnignore = setUnignore;
        vm.widthClass = "";
        vm.approachID = null;
        vm.matchSearchSignal = matchSearchSignal;
        vm.filterCards = filterCards;
        vm.setupOptionsChangeNotify = setupOptionsChangeNotify;
        vm.insightsService = insightsService;
        vm.searchCards = searchCards;
        vm.createOverviewInsightCard = createOverviewInsightCard;
        vm.createDetailInsightsCard = createDetailInsightsCard;
        vm.options = vm.insightsService.getOptions();
        vm.currentFilter = {
            state: vm.options.filtering.state,
            priority: vm.options.filtering.priority,
        };
        vm.stateStrings = ["new", "acknowledged", "ignored", "all"];
        vm.priorityStrings = ["all", "low", "high"];
        //setup searchbar now always needs to be called first
        vm.setupSearchBar();
        vm.setupOptionsChangeNotify();
        vm.setupDataTable();
        vm.setSearchOptions();

        vm.toDetectors = false;
        vm.insightTypeID = 0;
        vm.getUserSettings = getUserSettings;
        vm.userSettingsInsightTypes = [];
        vm.userSettingsInsightCategories = [];
        vm.breadcrumbNavigationService = breadcrumbNavigationService;
        vm.navigateToState = navigateToState;
        vm.newWindowService = newWindowService;
        vm.checkInsight = checkInsight;
        vm.selectedTabChange = selectedTabChange;

        vm.getUserSettings();
        vm.getInsightsDefinitions();
        function selectedTabChange(title) {
            vm.currentTab = title;
            var prevSessionData = JSON.parse(sessionStorage.getItem('segmentData'));
            sessionStorage.setItem('segmentData', JSON.stringify(Object.assign(prevSessionData, {
                tab: title
            })));
            // telemetryService.insightsDashboardTelemetry("click", "tab");
        }
        //getting user settings and showing cards according to User Settings        
        function getUserSettings() {
            userSettingsService.getUserSettings()
                .then(function (userSettings) {
                    if (vm && userSettings.insightSettings && userSettings.insightSettings.enabledDashboardInsightCategories.length>0 ) {
                        userSettings.insightSettings.enabledDashboardInsightCategories.forEach(function (category) {
                            if (category.insightTypes) {
                                if (category.insightTypes.length > 0) vm.userSettingsInsightCategories.push(category);
                                category.insightTypes.forEach(function (type) {
                                    vm.userSettingsInsightTypes.push(type);
                                })
                            }
                        })
                    }
                })
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            if (options.signal) {
                vm.signal = {
                    signalID: options.signal.signalID,
                    description: options.signal.description,
                };
            }
            vm.timeOptions = options.timeOptions;
            vm.queryData.start = new Date(vm.timeOptions.currentFilter.startDateAndTime).toLocaleString();
            vm.queryData.end = new Date(vm.timeOptions.currentFilter.endDateAndTime).toLocaleString();
        };


        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.removeInsightsCards();

            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                        $scope.loading = true;
                        vm.isFiltering = searchBarService.isFiltering();
                        if (vm.isFiltering) {
                            vm.setSearchOptions();
                            vm.getInsightsDefinitions();
                        }
                        break;
                    case "signal":
                        break;
                    case "configuration": break;
                    case "state":
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                    case "wildcard":
                        vm.searchCards();
                        break;
                }
            });

            vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
                //header information
                header: {
                    show: true,
                    text: "System Insights",
                    badgeText: "beta"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Wildcard',
                showCurrentFilterDates: true,
                helpJsonPropertyPath: "INSIGHTS_DASHBOARD." + "C" + $stateParams.insightCategory,
                showHelp: true,
                metricTypeId: userSettingsService.dashboardTypeIds.Insights,
                dashboardType: "Insights",
                showExcel: true,
                timeFrameConfig: {
                    enableWithoutFiltering: true,
                    defaultDateTemplate: "YD",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    showWeekdayFilter: false,
                    maxDayRange: 31
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: false,
                    skipStepsPerBin: true,
                    customHtmlPath: "assets/templates/help/insights-options.html"
                }
            });

            vm.isFiltering = searchBarService.isFiltering();
        }

        function setupOptionsChangeNotify() {
            vm.insightsService.subscribe($scope, function onChange(ev, options) {
                if (!vm)
                    return;

                switch (options.name) {
                    case "Filter":
                        vm.currentFilter = options.value;
                        vm.filterCards(options.value);
                        break;
                }
            });
        }

        function filterCards(newFilter) {
            for (let i = 0; i < vm.insightsCards.length; i++) {
                var thisCard = vm.insightsCards[i];
                thisCard.filterItems(newFilter);
            }
        }

        function searchCards() {
            var opts = searchBarService.getSearchOptions();
            var searchText = opts.wildcardSearchText;
            for (let i = 0; i < vm.insightsCards.length; i++) {
                var thisCard = vm.insightsCards[i];
                thisCard.searchChange(searchText);
            }
        }

        function checkInsight(index) {
            vm.selectedInsight = vm.insightsCards[index];
        }


        function getNewTimeFrame(start, end) {
            var options = vm.searchBarService.getSearchOptions();
            options.timeOptions.timeFrame = "custom";
            var startDate = new Date(start).toLocaleString();
            var endtDate = new Date(end).toLocaleString();
            options.timeOptions.currentFilter.startDateAndTime = new Date(startDate);
            options.timeOptions.currentFilter.endDateAndTime = new Date(endtDate);
            options.timeOptions.currentFilter.selectedTodTemplate = 'CT';
            return options.timeOptions;
        }

        function createOverviewInsightCard(group, hasSelectedInsTypes) {
            vm.insightsOverviewCards.push({
                hasSelectedInsTypes: hasSelectedInsTypes,
                class: vm.typeOfClass,
                title: group.name,
                id: group.insightGroupID,
                pageIndex: 1,
                subtitle: vm.findings,
                button1: "Investigate",
                button1Action: function () {
                    //if (category.insightCategoryID == 1) 
                    vm.selectedinsightsIndex = 1;
                    // if (category.insightCategoryID == 2) vm.selectedinsightsIndex = 2;
                    // if (category.insightCategoryID == 3) vm.selectedinsightsIndex = 3;
                },
                button2: "Acknowledge",
                text: vm.findings + "Insights have been found today.",
                status: vm.typeOfStatus[1],
                chart: {
                    config: {
                        refreshDataOnly: true,
                        deepWatchDataDepth: 0
                    },
                    options: {
                        chart: {
                            type: 'pieChart',
                            //color: ['#0C5EAF ', '#BDBDBD', '#0C5EAF', '#D0021B'],
                            height: 350,
                            margin: {
                                top: 75,
                                right: 0,
                                bottom: 0,
                                left: 0
                            },
                            donut: true,
                            x: function (d) {
                                return d.label;
                            },
                            y: function (d) {
                                return d.value;
                            },
                            tooltip: {
                                gravity: 's',
                                classes: 'gravity-s'
                            },
                            pie: {},
                            growOnHover: true,
                            showLabels: false
                        }
                    },
                    data: [
                        {
                            label: "Stuck on",
                            value: 5,
                        },
                        {
                            label: "Stuck off",
                            value: 1
                        },
                        {
                            label: "Chatter",
                            value: 3
                        },
                        {
                            label: "Phase Skipped",
                            value: 0,
                        }
                    ]
                }
            });
        }

        function removeInsightsCards() {
            vm.insightsCards.splice(0, vm.insightsCards.length);
            vm.insightsOverviewCards.splice(0, vm.insightsOverviewCards.length);
        }


        function matchSearchSignal(signalID, searchText) {
            if (signalID && searchText !== undefined) {
                var signalDesc = signalService.getSignalByIDFromDict(signalID).signalName.toLowerCase();
                if (signalDesc && signalDesc.includes(searchText.toLowerCase()))
                    return true;
            }
            return false;
        }

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function createDetailInsightsCard(name, listOfItems, parentID, acknowledgedActions, insightTypeID, action, level) {
            var newCard;
            if (!vm.insightsCards) vm.insightsCards = [];

            var opts = searchBarService.getSearchOptions();

            var newCard = {
                class: vm.typeOfClass,
                title: name,
                level: level,
                restrictedControlls: ($stateParams.insightCategory == "4" && !userService.getUserRoles().some(x => x == 'ADMIN')),
                parentId: parentID,
                subtitle: vm.findingsOfDetectors,
                insightTypeId: insightTypeID,
                allItems: listOfItems,
                filteringBy: vm.currentFilter,
                isExpanded: false,
                totalCount: listOfItems.length,
                searchText: "",
                filters: InsightConstants.Filters,
                excelLoading: false,
                navigateTo: action,
                pageData: {
                    pageIndex: 1,
                    size: 5
                },
                excelExport() {
                    var card = this;
                    card.excelLoading = true;
                    var insightType = [];
                    insightType.push({
                        insightTypeID: this.insightTypeId,
                        start: vm.queryData.start,
                        end: vm.queryData.end
                    });
                    var queryObj = {
                        start: vm.queryData.start,
                        end: vm.queryData.end,
                        fileType: "xlsx",
                        insightTypeIDs: [insightType[0].insightTypeID],
                        insightCategoryIDs: [vm.queryData.insightCategoryID]
                    };
                    // telemetryService.insightsDashboardTelemetry("click", "export_data", {
                    //     total_insights: card.list.items.length,
                    //     card_name: card.title,
                    //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                    //     card_page: [{
                    //         current_page_number: card.pageData.pageIndex,
                    //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                    //     }]
                    // });
                    dataExportService.getInsightsExcelFile(queryObj).then(function (e) {
                        card.excelLoading = false;
                    });

                },
                showHelpAlert: function (ev, card) {
                    helpService.showHelpDialog("INSIGHTS_DASHBOARD." + card.toString(), ev, "How We Calculate It");

                    var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));

                    currentSession.card_name = this.title;
                    currentSession.sort_order = this.orderBy[0] === '-' ? "descending" : "ascending";
                    currentSession.card_page = [{
                        current_page_number: this.pageData.pageIndex,
                        total_pages: Math.ceil(this.list.items.length / this.pageData.size)
                    }]

                    sessionStorage.setItem('segmentData', JSON.stringify(currentSession));

                    // telemetryService.insightsDashboardTelemetry("click", "help");
                },
                isInsightIgnored: function (i) {
                    return this.list.items[i].isIgnored;
                },
                isInsightAcknowledged: function (i) {
                    return this.list.items[i].isAcknowledged;
                },
                getSignalDescription: function (signalID) {
                    var description = signalService.getSignalByIDFromDict(signalID).signalName;
                    return description;
                },
                getApproachDescription: function (signalID, approachID) {
                    var approaches = signalService.getSignalByIDFromDict(signalID).approaches;
                    var description = 'Not Configured';
                    approaches.forEach(function (approach) {
                        if (approachID == approach.approachID) description = approach.description;
                    });
                    return description;
                },
                getDetectorType(signalID, approachID, detectorChannel) {
                    // get approach by id
                    var approaches = signalService.getSignalByIDFromDict(signalID).approaches;
                    var detectorDescription = 'Not Configured Det ' + detectorChannel;

                    var selectedApproach = approaches.filter(appr => appr.approachID == approachID)[0];

                    if (selectedApproach) {
                        selectedApproach.detectors.forEach(function (detector) {
                            if (detector.detChannel == detectorChannel) detectorDescription = detector.description + ' Det ' + detectorChannel
                        })
                    }

                    return detectorDescription
                    // get detector type by detector channel from approaches
                },
                expand: function () {
                    if (this.widthClass == "width-600") {
                        this.widthClass = "width-360";
                        this.isExpanded = false;

                        // telemetryService.insightsDashboardTelemetry("click", "collapse_card", {
                        //     card_name: this.title,
                        //     sort_order: this.orderBy[0] === '-' ? "descending" : "ascending",
                        //     card_page: [{
                        //         current_page_number: this.pageData.pageIndex,
                        //         total_pages: Math.ceil(this.list.items.length / this.pageData.size)
                        //     }],
                        //     total_insights: this.list.items.length,
                        // });
                    }
                    else if (this.widthClass == "width-360") {
                        this.widthClass = "width-600";
                        this.isExpanded = true;
                        // telemetryService.insightsDashboardTelemetry("click", "expand_card", {
                        //     card_name: this.title,
                        //     sort_order: this.orderBy[0] === '-' ? "descending" : "ascending",
                        //     card_page: [{
                        //         current_page_number: this.pageData.pageIndex,
                        //         total_pages: Math.ceil(this.list.items.length / this.pageData.size)
                        //     }],
                        //     total_insights: this.list.items.length,
                        // });
                    }
                },
                widthClass: "width-360",
                searchChange: function (value) {
                    if (value !== undefined) {
                        this.searchText = value;

                        if (value == "") {
                            this.list.items = this.allItems;
                            //restore filters
                            this.filterItems(this.filteringBy);
                        }
                        else {
                            var isAcknowledged = false;
                            var isIgnored = false;
                            switch (this.filteringBy.state) {
                                case InsightConstants.Filters.State.new:
                                    isAcknowledged = false;
                                    isIgnored = false;
                                    break;
                                case InsightConstants.Filters.State.acknowledged:
                                    isAcknowledged = true;
                                    isIgnored = false;
                                    break;
                                case InsightConstants.Filters.State.ignored:
                                    isAcknowledged = false;
                                    isIgnored = true;
                                    break;
                                case InsightConstants.Filters.State.all:
                                    isAcknowledged = true;
                                    isIgnored = true;
                                    break;
                            }
                            if (isIgnored && !isAcknowledged) this.list.items = this.allItems.filter(item => (item.isIgnored == isIgnored) && vm.matchSearchSignal(item.signalID, value));
                            else if (isIgnored && isAcknowledged) this.list.items = this.allItems.filter(item => vm.matchSearchSignal(item.signalID, value));
                            else this.list.items = this.allItems.filter(item => (item.isIgnored == isIgnored && item.isAcknowledged == isAcknowledged) && vm.matchSearchSignal(item.signalID, value));

                            switch (this.filteringBy.priority) {
                                case InsightConstants.Filters.Priority.all:
                                    break;
                                case InsightConstants.Filters.Priority.low:
                                case InsightConstants.Filters.Priority.high:
                                    this.list.items = this.list.items.filter(item => (item.priority.level == this.filteringBy.priority));
                                    break;
                            }
                        }
                    }
                },
                filterItems: function (newFilter) {
                    switch (newFilter.state) {
                        case InsightConstants.Filters.State.new:
                            this.list.items = this.allItems.filter(item => (item.isAcknowledged == false && item.isIgnored == false));
                            break;
                        case InsightConstants.Filters.State.acknowledged:
                            this.list.items = this.allItems.filter(item => (item.isAcknowledged == true && item.isIgnored == false));
                            break;
                        case InsightConstants.Filters.State.ignored:
                            this.list.items = this.allItems.filter(item => (item.isIgnored == true));
                            break;
                        case InsightConstants.Filters.State.all:
                            this.list.items = this.allItems;
                            break;
                    }
                    switch (newFilter.priority) {
                        case InsightConstants.Filters.Priority.all:
                            break;
                        case InsightConstants.Filters.Priority.low:
                        case InsightConstants.Filters.Priority.high:
                            this.list.items = this.list.items.filter(item => (item.priority.level == newFilter.priority));
                            break;
                    }
                    this.filteringBy = newFilter;
                    //if we are searching then search after filtering
                    if (this.searchText != "")
                        this.searchChange(this.searchText);

                    this.setFooterTextDetails();
                },
                investigate: function (i, openInNewWindow) {
                    var index = this.getCurrentPagedIndex(i);
                    var selectedInsight = this.list.items[index];
                    var title = this.title;
                    var card = this;
                    vm.approachID = selectedInsight.approachID;
                    signalService.getSignalById(selectedInsight.signalID)
                        .then(function (signal) {
                            var currentDate = new Date();
                            vm.signal = signal;
                            var toLowerTitle = title.toLowerCase();
                            vm.signal.description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                            if (card.navigateTo == "app.spm.charts.phase-termination") {
                                var s = new Date(selectedInsight.occurrences[0].start);
                                var e = new Date(selectedInsight.occurrences[0].end);
                                var dayInMonth = 0;
                                var startDate = new Date(s.getFullYear(), s.getMonth(), s.getDate(), 0, 0, 0);
                                var endDate = new Date(e.getFullYear(), e.getMonth(), e.getDate() + 1, 0, 0, 0);
                                var newTimeOptions = {
                                    currentFilter: {
                                        startDateAndTime: moment(startDate).startOf('day'),
                                        endDateAndTime: moment(endDate).endOf('day')
                                    }
                                };
                            }
                            if (card.navigateTo == "app.spm.dashboards.signal-overview" || card.navigateTo == "app.spm.charts.purdue-coord-diagram" || card.navigateTo == "app.spm.charts.split-failure") {
                                var startDate = new Date(selectedInsight.occurrences[0].start);
                                var endDate = new Date(new Date(selectedInsight.occurrences[0].end).getTime() - 1);
                                var newTimeOptions = {
                                    currentFilter: {
                                        startDateAndTime: moment(startDate).startOf('day'),
                                        endDateAndTime: moment(endDate).endOf('day')
                                    }
                                };
                            }
                            if (card.navigateTo == "app.spm.charts.detector-counts" || card.navigateTo == "app.spm.charts.detector-durations") {
                                var s = new Date(selectedInsight.occurrences[0].start);
                                var e = new Date(selectedInsight.occurrences[0].end);
                                var dayInMonth = 0;
                                vm.toDetectors = true;
                                var testEndDate = new Date(e.getFullYear(), e.getMonth(), e.getDate() + 3, 0, 0, 0);
                                if (currentDate <= testEndDate) dayInMonth = currentDate.getDate();
                                else dayInMonth = e.getDate() + 3;
                                var startDate = new Date(s.getFullYear(), s.getMonth(), s.getDate() - 3, 0, 0, 0);
                                var endDate = new Date(e.getFullYear(), e.getMonth(), dayInMonth, 0, 0, 0);
                                var newTimeOptions = {
                                    currentFilter: {
                                        startDateAndTime: moment(startDate).startOf('day'),
                                        endDateAndTime: moment(endDate).endOf('day')
                                    }
                                };
                            }

                            var investigatingFromInsights = {
                                navigatingFromInsights: true,
                                categoryToInvestigate: vm.queryData.insightCategoryID
                            }
                            sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));
                            if (newTimeOptions) newTimeOptions.daysOfWeekList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            vm.navigateToState(card.navigateTo, newTimeOptions, vm.signal, openInNewWindow, { 'focusApproachId': vm.approachID });

                            // telemetryService.insightsDashboardTelemetry("click", "investigate", {
                            //     acknowledged: selectedInsight.isAcknowledged,
                            //     ignored: selectedInsight.isIgnored,
                            //     occurrences: selectedInsight.occurrences.length,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }],
                            //     intersection_id: vm.signal.signalID,
                            //     intersection_name: vm.signal.signalName,
                            //     approach_id: selectedInsight.approachID,
                            //     approach: card.getApproachDescription(vm.signal.signalID, selectedInsight.approachID),
                            //     detector_channel: selectedInsight.detectorChannel,
                            //     detector: card.getDetectorType(vm.signal.signalID, selectedInsight.approachID, selectedInsight.detectorChannel)
                            // });
                        });
                },
                investigateOccurrence: function (insight, occurrence, openInNewWindow) {
                    var title = this.title;
                    var card = this;
                    vm.approachID = insight.approachID;
                    signalService.getSignalById(insight.signalID)
                        .then(function (signal) {
                            vm.signal = signal;
                            vm.signal.description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                            var newTimeOptions = vm.getNewTimeFrame(occurrence.start, occurrence.end);
                            if (title.includes("Data")) {
                                var startDate = new Date(occurrence.start);
                                var endDate = new Date(new Date(occurrence.end).getTime() - 1);
                                var newTimeOptions = vm.getNewTimeFrame(startDate, endDate);
                            }
                            // telemetryService.insightsDashboardTelemetry("click", "investigate", {
                            //     pop_up_window: "details",
                            //     intersection_id: vm.signal.signalID,
                            //     acknowledged: insight.isAcknowledged,
                            //     ignored: insight.isIgnored,
                            //     intersection_name: vm.signal.description,
                            //     card_name: card.title,
                            //     approach: card.getApproachDescription(insight.signalID, insight.approachID),
                            //     approach_id: insight.approachID,
                            //     detector: card.getDetectorType(insight.signalID, insight.approachID, insight.detectorChannel),
                            //     detector_channel: insight.detectorChannel,
                            //     occurrences: insight.occurrences.length,
                            //     occurrence_period: [{
                            //         start_datetime: new Date(occurrence.start),
                            //         end_datetime: new Date(occurrence.end)
                            //     }]
                            // });
                            vm.navigateToState(card.navigateTo, newTimeOptions, vm.signal, openInNewWindow, { 'focusApproachId': vm.approachID });

                        });
                },
                getCurrentPagedIndex: function (i) {
                    return ((this.pageData.pageIndex * this.pageData.size) - this.pageData.size) + i;
                },
                orderByOccurrence: function (order, e) {
                    this.orderBy = order;
                    if (this.orderBy == "occurrenceCount") {
                        this.list.items.sort((a, b) => this.sort(b, a));
                    }
                    if (this.orderBy == "-occurrenceCount") {
                        this.list.items.sort((a, b) => this.sort(a, b));
                    }

                    if (!$scope.loading) {
                        // telemetryService.insightsDashboardTelemetry("click", "sort", {
                        //     card_name: this.title,
                        //     sort_order: this.orderBy[0] === '-' ? "descending" : "ascending",
                        //     card_page: [{
                        //         current_page_number: this.pageData.pageIndex,
                        //         total_pages: Math.ceil(this.list.items.length / this.pageData.size)
                        //     }]
                        // });
                    }
                },
                sort: function (a, b) {
                    //if occurence count is same, then order by signal ID
                    if (a.occurrences.length == b.occurrences.length) {
                        if (a.signalID == b.signalID) {
                            return a.detChannel < b.detChannel ? 1 : -1
                        }
                        return a.signalID < b.signalID ? 1 : -1;
                    }
                    return a.occurrences.length < b.occurrences.length ? 1 : -1;
                },
                paginationTrigger: function () {
                    var crd = this;
                    // telemetryService.insightsDashboardTelemetry("click", "page", {
                    //     card_name: crd.title,
                    //     sort_order: crd.orderBy[0] === '-' ? "descending" : "ascending",
                    //     card_page: [{
                    //         current_page_number: crd.pageData.pageIndex,
                    //         total_pages: Math.ceil(crd.list.items.length / crd.pageData.size)
                    //     }]
                    // });
                },
                acknowledgeItem: function (i) {
                    var index = this.getCurrentPagedIndex(i);
                    var ackItem = this.list.items[index];
                    var card = this;

                    signalService.getSignalById(ackItem.signalID)
                        .then(function (signal) {
                            ackItem.signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                            if (ackItem.approachID) {
                                ackItem.approachDescription = card.getApproachDescription(signal.signalID, ackItem.approachID);
                            }

                            if (ackItem.isAcknowledged) {
                                // telemetryService.insightsDashboardTelemetry("click", "unacknowledge", {
                                //     intersection_id: ackItem.signalID,
                                //     intersection_name: ackItem.signalDescription,
                                //     approach: card.getApproachDescription(ackItem.signalID, ackItem.approachID),
                                //     approach_id: ackItem.approachID,
                                //     detector_channel: ackItem.detectorChannel,
                                //     detector: card.getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel),
                                //     occurrences: ackItem.occurrences.length,
                                //     card_name: card.title,
                                //     occurrences: ackItem.occurrences.length,
                                //     acknowledged: ackItem.isAcknowledged,
                                //     ignored: ackItem.isIgnored,
                                //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                                //     card_page: [{
                                //         current_page_number: card.pageData.pageIndex,
                                //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                                //     }]
                                // });
                            } else {
                                // telemetryService.insightsDashboardTelemetry("click", "acknowledge", {
                                //     intersection_id: ackItem.signalID,
                                //     occurrences: ackItem.occurrences.length,
                                //     intersection_name: ackItem.signalDescription,
                                //     approach: card.getApproachDescription(ackItem.signalID, ackItem.approachID),
                                //     approach_id: ackItem.approachID,
                                //     detector_channel: ackItem.detectorChannel,
                                //     detector: card.getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel),
                                //     acknowledged: ackItem.isAcknowledged,
                                //     ignored: ackItem.isIgnored,
                                //     card_name: card.title,
                                //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                                //     card_page: [{
                                //         current_page_number: card.pageData.pageIndex,
                                //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                                //     }]
                                // });
                            }
                        });
                    var title = this.title;

                    $mdDialog.show({
                        clickOutsideToClose: true,
                        multiple: true,
                        template: ACKNOWLEDGEPANEL,
                        controller: 'AcknowledgeController as vm',
                        parent: angular.element(document.body),
                        locals: {
                            cardDetails: card,
                            typeOfAcknowledges: acknowledgedActions,
                            selectedItem: ackItem,
                            insightCategory: $stateParams.insightCategory,
                            insightName: title,
                            on_acknowledge: function (data_from_dialog_controller) {
                                ackItem.isAcknowledged = data_from_dialog_controller.isAcknowledged;
                                ackItem.newOccurrenceAfterExpiration = data_from_dialog_controller.newOccurrenceAfterExpiration;
                                ackItem.currentAcknowledgment.acknowledgedComment = data_from_dialog_controller.comment;
                                ackItem.currentAcknowledgment.selectedAckReasons = data_from_dialog_controller.selectedAckReasons;
                                ackItem.currentAcknowledgment.acknowledgedTimestamp = data_from_dialog_controller.acknowledgedTimestamp;
                                ackItem.currentAcknowledgment.acknowledgedByEmail = getEmail();
                                ackItem.startTime = vm.queryData.start;
                                ackItem.endTime = vm.queryData.end;
                                vm.saveAckItem(ackItem, card);

                                var ackResponse = undefined;
                                if (ackItem && ackItem.currentAcknowledgment &&
                                    ackItem.currentAcknowledgment.selectedAckReasons &&
                                    ackItem.currentAcknowledgment.selectedAckReasons.length > 0) {
                                    ackResponse = {
                                        confirmed_in_atms: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 1),
                                        investigated_in_atms: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 2),
                                        technician_dispatched: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 3),
                                        technician_repaired: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 4),
                                    }
                                }

                                // telemetryService.insightsDashboardTelemetry("acknowledge", "acknowledge", {
                                //     intersection_id: ackItem.signalID,
                                //     intersection_name: ackItem.signalDescription,
                                //     approach: card.getApproachDescription(ackItem.signalID, ackItem.approachID),
                                //     approach_id: ackItem.approachID,
                                //     detector_channel: ackItem.detectorChannel,
                                //     detector: card.getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel),
                                //     occurrences: ackItem.occurrences.length,
                                //     card_name: card.title,
                                //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                                //     card_page: [{
                                //         current_page_number: card.pageData.pageIndex,
                                //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                                //     }],
                                //     acknowledge_parameters: [{
                                //         acknowledge_expiration_dt: new Date(ackItem.currentAcknowledgment.acknowledgedExpirationTimestamp),
                                //         comment: ackItem.currentAcknowledgment.acknowledgedComment,
                                //         other: ackResponse
                                //     }]
                                // });
                            },
                            on_update: function (data_from_dialog_controller) {
                                //update acknowledged info
                                ackItem.isAcknowledged = data_from_dialog_controller.isAcknowledged;
                                ackItem.currentAcknowledgment.acknowledgedComment = data_from_dialog_controller.comment;
                                ackItem.currentAcknowledgment.selectedAckReasons = data_from_dialog_controller.selectedAckReasons;
                                ackItem.startTime = vm.queryData.start;
                                ackItem.endTime = vm.queryData.end;
                                vm.updateAckItem(ackItem, card);
                            },
                            on_unacknowledge: function () {

                                var ackResponse = undefined;
                                if (ackItem && ackItem.currentAcknowledgment &&
                                    ackItem.currentAcknowledgment.selectedAckReasons &&
                                    ackItem.currentAcknowledgment.selectedAckReasons.length > 0) {
                                    ackResponse = {
                                        confirmed_in_atms: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 1),
                                        investigated_in_atms: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 2),
                                        technician_dispatched: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 3),
                                        technician_repaired: ackItem.currentAcknowledgment.selectedAckReasons.some(x => x == 4),
                                    }
                                }

                                // telemetryService.insightsDashboardTelemetry("unacknowledge", "unacknowledge", {
                                //     // filter_parameters: [{
                                //     //     state: vm.stateStrings[card.filteringBy.state - 1],
                                //     //     priority: vm.priorityStrings[card.filteringBy.priority + 1]
                                //     // }],
                                //     intersection_id: ackItem.signalID,
                                //     intersection_name: ackItem.signalDescription,
                                //     approach: card.getApproachDescription(ackItem.signalID, ackItem.approachID),
                                //     approach_id: ackItem.approachID,
                                //     detector_channel: ackItem.detectorChannel,
                                //     detector: card.getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel),
                                //     occurrences: ackItem.occurrences.length,
                                //     card_name: card.title,
                                //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                                //     card_page: [{
                                //         current_page_number: card.pageData.pageIndex,
                                //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                                //     }],
                                //     acknowledge_parameters: [{
                                //         acknowledge_expiration_dt: new Date(ackItem.currentAcknowledgment.acknowledgedExpirationTimestamp),
                                //         comment: ackItem.currentAcknowledgment.acknowledgedComment,
                                //         other: ackResponse
                                //     }]
                                // });
                                vm.removeAckItem(ackItem, card);
                            }
                        }
                    }).then(function () { }, function onCancel() {
                        if (ackItem.isAcknowledged) {
                            // telemetryService.insightsDashboardTelemetry("cancel", "unacknowledge", {
                            //     intersection_id: ackItem.signalID,
                            //     intersection_name: ackItem.signalDescription,
                            //     approach: card.getApproachDescription(ackItem.signalID, ackItem.approachID),
                            //     approach_id: ackItem.approachID,
                            //     detector_channel: ackItem.detectorChannel,
                            //     detector: card.getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel),
                            //     occurrences: ackItem.occurrences.length,
                            //     acknowledged: ackItem.isAcknowledged,
                            //     ignored: ackItem.isIgnored,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }],
                            // });
                        } else {
                            // telemetryService.insightsDashboardTelemetry("cancel", "acknowledge", {
                            //     intersection_id: ackItem.signalID,
                            //     intersection_name: ackItem.signalDescription,
                            //     approach: card.getApproachDescription(ackItem.signalID, ackItem.approachID),
                            //     approach_id: ackItem.approachID,
                            //     detector_channel: ackItem.detectorChannel,
                            //     detector: card.getDetectorType(ackItem.signalID, ackItem.approachID, ackItem.detectorChannel),
                            //     occurrences: ackItem.occurrences.length,
                            //     acknowledged: ackItem.isAcknowledged,
                            //     ignored: ackItem.isIgnored,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }],
                            // });
                        }
                        // $mdDialog.hide();
                    })
                },
                ignoreItem: function (i) {
                    var index = this.getCurrentPagedIndex(i);
                    var title = this.title;
                    var card = this;
                    var d = new Date();
                    var ignoreItem = this.list.items[index];
                    ignoreItem.isIgnored ? unignoreInsight(ignoreItem, title, card, index, d) : ignoreInsight(ignoreItem, title, card, index, d);

                },
                openDetails: function (i) {
                    var index = this.getCurrentPagedIndex(i);
                    var item = this.list.items[index];
                    var card = this;
                    signalService.getSignalById(item.signalID)
                        .then(function (signal) {
                            item.signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                            if (item.approachID) {
                                item.approachDescription = card.getApproachDescription(signal.signalID, item.approachID);
                            }

                            // telemetryService.insightsDashboardTelemetry("click", "details", {
                            //     approach: card.getApproachDescription(signal.signalID, item.approachID),
                            //     approach_id: item.approachID,
                            //     intersection_id: signal.signalID,
                            //     intersection_name: signal.signalName,
                            //     detector: card.getDetectorType(signal.signalID, item.approachID, item.detectorChannel),
                            //     detector_channel: item.detectorChannel,
                            //     occurrences: item.occurrences.length,
                            //     acknowledged: item.isAcknowledged,
                            //     ignored: item.isIgnored,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }]
                            // });
                        });
                    var title = this.title;

                    $mdDialog.show({
                        clickOutsideToClose: true,
                        multiple: true,
                        template: DETAILSDIALOG,
                        controller: 'InsightDetailsController as vm',
                        parent: angular.element(document.body),
                        locals: {
                            insight: item,
                            insightName: title,
                            cardParent: card
                        }
                    }).then(function () {
                    }, function onCancel() {
                        // telemetryService.insightsDashboardTelemetry("close", "details", {
                        //     approach: card.getApproachDescription(item.signalID, item.approachID),
                        //     approach_id: item.approachID,
                        //     pop_up_window: "details",
                        //     intersection_id: item.signalID,
                        //     intersection_name: item.signalDescription,
                        //     detector: card.getDetectorType(item.signalID, item.approachID, item.detectorChannel),
                        //     detector_channel: item.detectorChannel,
                        //     occurrences: item.occurrences.length,
                        //     acknowledged: item.isAcknowledged,
                        //     ignored: item.isIgnored,
                        //     card_name: card.title,
                        //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                        //     card_page: [{
                        //         current_page_number: card.pageData.pageIndex,
                        //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                        //     }]
                        // });
                    });
                },
                text: vm.findingsOfDetectors + " Insights have been found today.",
                status: vm.typeOfStatus[1],
                orderBy: "-occurrenceCount",
                list: {
                    selected: {},
                    column1Header: "Signal",
                    column2Header: "Signal Name",
                    column3Header: "Approach",
                    column4Header: "Detector",
                    column5Header: "Occurrences",
                    items: listOfItems,
                    pageIndex: 1,
                    paginate: function () { }
                },
                percentage: function () {
                    return this.list.items.length;
                },
                setFooterTextDetails: function () {
                    var firstPart = "";
                    var middlePart = "";
                    var endingPart = "";
                    switch (this.filteringBy.state) {
                        case InsightConstants.Filters.State.new:
                            firstPart = "New";
                            break;
                        case InsightConstants.Filters.State.acknowledged:
                            firstPart = "Acknowledged";
                            break;
                        case InsightConstants.Filters.State.ignored:
                            firstPart = "Ignored";
                            break;
                    }
                    switch (this.filteringBy.priority) {
                        case InsightConstants.Filters.Priority.all:
                            middlePart = "";
                            break;
                        case InsightConstants.Filters.Priority.low:
                            middlePart = "Low Priority ";
                            break;
                        case InsightConstants.Filters.Priority.high:
                            middlePart = "High Priority ";
                            break;
                    }
                    if (this.list.items.length > 1 || this.list.items.length == 0) {
                        endingPart = "s";
                    }
                    this.footerText = firstPart + " " + middlePart + "Insight" + endingPart;
                },
                footerText: "",
            };
            switch (level) {
                case "Phase":
                case "PedPhase":
                    newCard.list.column4Header = "Phase";
                    break;
                case "Detector":
                case "PedDetector":
                    newCard.list.column4Header = "Detector";
                    break;

            }
            //bind this to our order function 
            newCard.orderByOccurrence = newCard.orderByOccurrence.bind(newCard);
            //order
            newCard.orderByOccurrence(newCard.orderBy);
            //update the card if we are searching
            newCard.searchChange(searchBarService.getSearchOptions().wildcardSearchText);
            vm.insightsCards.push(newCard);
        }

        function setUnignore(ignoreItem, card) {
            insightsService.setUnignoreItem(ignoreItem)
                .then(function (respIgnored) {
                    ignoreItem.ignoredTimestamp = "";
                    ignoreItem.isIgnored = false;
                    ignoreItem.ignoredByEmail = "";
                    card.filterItems(vm.currentFilter);
                });
        }

        function unignoreInsight(ignoreItem, title, card, index, d) {
            var opts = searchBarService.getSearchOptions();
            signalService.getSignalById(ignoreItem.signalID)
                .then(function (signal) {
                    var signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                    var approachDescription = "";
                    if (ignoreItem.approachID) {
                        approachDescription = card.getApproachDescription(signal.signalID, ignoreItem.approachID);
                    }
                    var confirmText = "Are you sure you want to unignore " + title + " for " +
                        approachDescription + " " +
                        signalDescription + "?";

                    var confirm = $mdDialog.confirm()
                        .title("Confirm")
                        .textContent(confirmText)
                        .ok('Yes')
                        .cancel('No');

                    // telemetryService.insightsDashboardTelemetry("click", "unignore", {
                    //     // filter_parameters: [{
                    //     //     state: vm.stateStrings[card.filteringBy.state - 1],
                    //     //     priority: vm.priorityStrings[card.filteringBy.priority + 1]
                    //     // }],
                    //     intersection_id: signal.signalID,
                    //     intersection_name: signalDescription,
                    //     approach: card.getApproachDescription(ignoreItem.signalID, ignoreItem.approachID),
                    //     approach_id: ignoreItem.approachID,
                    //     acknowledged: ignoreItem.isAcknowledged,
                    //     ignored: ignoreItem.isIgnored,
                    //     detector_channel: ignoreItem.detectorChannel,
                    //     detector: card.getDetectorType(ignoreItem.signalID, ignoreItem.approachID, ignoreItem.detectorChannel),
                    //     occurrences: ignoreItem.occurrences.length,
                    //     card_name: card.title,
                    //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                    //     card_page: [{
                    //         current_page_number: card.pageData.pageIndex,
                    //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                    //     }],
                    // });

                    $mdDialog.show(confirm)
                        .then(function () {
                            vm.setUnignore(ignoreItem, card);
                            // telemetryService.insightsDashboardTelemetry("apply", "unignore", {
                            //     // filter_parameters: [{
                            //     //     state: vm.stateStrings[card.filteringBy.state - 1],
                            //     //     priority: vm.priorityStrings[card.filteringBy.priority + 1]
                            //     // }],
                            //     intersection_id: signal.signalID,
                            //     intersection_name: signalDescription,
                            //     approach: card.getApproachDescription(ignoreItem.signalID, ignoreItem.approachID),
                            //     approach_id: ignoreItem.approachID,
                            //     detector_channel: ignoreItem.detectorChannel,
                            //     detector: card.getDetectorType(ignoreItem.signalID, ignoreItem.approachID, ignoreItem.detectorChannel),
                            //     occurrences: ignoreItem.occurrences.length,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }],
                            // });
                        }, function onCancel() {
                            // telemetryService.insightsDashboardTelemetry("cancel", "unignore", {
                            //     // filter_parameters: [{
                            //     //     state: vm.stateStrings[card.filteringBy.state - 1],
                            //     //     priority: vm.priorityStrings[card.filteringBy.priority + 1]
                            //     // }],
                            //     intersection_id: signal.signalID,
                            //     intersection_name: signalDescription,
                            //     approach: card.getApproachDescription(ignoreItem.signalID, ignoreItem.approachID),
                            //     approach_id: ignoreItem.approachID,
                            //     detector_channel: ignoreItem.detectorChannel,
                            //     detector: card.getDetectorType(ignoreItem.signalID, ignoreItem.approachID, ignoreItem.detectorChannel),
                            //     occurrences: ignoreItem.occurrences.length,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }],
                            // });
                        }
                        );
                });
        }

        function ignoreInsight(ignoreItem, title, card, index, d) {
            var opts = searchBarService.getSearchOptions();
            signalService.getSignalById(ignoreItem.signalID)
                .then(function (signal) {
                    var signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                    var approachDescription = "";
                    if (ignoreItem.approachID) {
                        approachDescription = card.getApproachDescription(signal.signalID, ignoreItem.approachID);
                    }
                    var confirmText = "Are you sure you want to ignore " + title + " for " +
                        approachDescription + " " +
                        signalDescription + "?";

                    var confirm = $mdDialog.confirm()
                        .title("Confirm")
                        .textContent(confirmText)
                        .ok('Yes')
                        .cancel('No');

                    // telemetryService.insightsDashboardTelemetry("click", "ignore", {
                    //     acknowledged: ignoreItem.isAcknowledged,
                    //     ignored: ignoreItem.isIgnored,
                    //     intersection_id: signal.signalID,
                    //     intersection_name: signalDescription,
                    //     approach: card.getApproachDescription(ignoreItem.signalID, ignoreItem.approachID),
                    //     approach_id: ignoreItem.approachID,
                    //     detector_channel: ignoreItem.detectorChannel,
                    //     detector: card.getDetectorType(ignoreItem.signalID, ignoreItem.approachID, ignoreItem.detectorChannel),
                    //     occurrences: ignoreItem.occurrences.length,
                    //     card_name: card.title,
                    //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                    //     card_page: [{
                    //         current_page_number: card.pageData.pageIndex,
                    //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                    //     }],
                    // });

                    $mdDialog.show(confirm)
                        .then(function () {
                            ignoreItem.ignoredTimestamp = d.toLocaleTimeString() + ' ' + d.toDateString();
                            ignoreItem.isIgnored = true;
                            ignoreItem.ignoredByEmail = getEmail();
                            vm.setIgnore(ignoreItem, card);
                            // telemetryService.insightsDashboardTelemetry("apply", "ignore", {
                            //     // filter_parameters: [{
                            //     //     state: vm.stateStrings[card.filteringBy.state - 1],
                            //     //     priority: vm.priorityStrings[card.filteringBy.priority + 1]
                            //     // }],
                            //     intersection_id: signal.signalID,
                            //     intersection_name: signalDescription,
                            //     approach: card.getApproachDescription(ignoreItem.signalID, ignoreItem.approachID),
                            //     approach_id: ignoreItem.approachID,
                            //     detector_channel: ignoreItem.detectorChannel,
                            //     detector: card.getDetectorType(ignoreItem.signalID, ignoreItem.approachID, ignoreItem.detectorChannel),
                            //     occurrences: ignoreItem.occurrences.length,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }],
                            // });
                        }, function onCancel() {
                            // telemetryService.insightsDashboardTelemetry("cancel", "ignore", {
                            //     // filter_parameters: [{
                            //     //     state: vm.stateStrings[card.filteringBy.state - 1],
                            //     //     priority: vm.priorityStrings[card.filteringBy.priority + 1]
                            //     // }],
                            //     intersection_id: signal.signalID,
                            //     intersection_name: signalDescription,
                            //     approach: card.getApproachDescription(ignoreItem.signalID, ignoreItem.approachID),
                            //     approach_id: ignoreItem.approachID,
                            //     detector_channel: ignoreItem.detectorChannel,
                            //     detector: card.getDetectorType(ignoreItem.signalID, ignoreItem.approachID, ignoreItem.detectorChannel),
                            //     occurrences: ignoreItem.occurrences.length,
                            //     card_name: card.title,
                            //     sort_order: card.orderBy[0] === '-' ? "descending" : "ascending",
                            //     card_page: [{
                            //         current_page_number: card.pageData.pageIndex,
                            //         total_pages: Math.ceil(card.list.items.length / card.pageData.size)
                            //     }],
                            // });
                        }
                        );
                });
        }

        function navigateToState(state, timeOptions, signal, openInNewWindow, params) {
            if (!openInNewWindow) {
                vm.breadcrumbNavigationService.navigateToState(state, timeOptions, signal, params);
            }
            else {
                //set into local storage and open new tab
                vm.newWindowService.openNewTabTimeAndSignal(state, timeOptions, signal, params);
            }
        }

        function getEmail() {
            return sessionStorage.getItem('userEmail');
        }

        function getInsightsDefinitions() {
            signalService.getAllSignalsWithApproaches().then(function () {
                //to get by category call getSystemInsightsByCategory
                insightsService.getSystemInsightsByCategory(vm.queryData)
                    .then(function (definitions) {
                        if (!vm)
                            return;
                        vm.selectedInsightTypes = [];
                        userSettingsService.getUserSettings().then(function (userSettings) {
                            if (vm && userSettings.insightSettings) {
                                userSettings.insightSettings.enabledDashboardInsightCategories.forEach(function (category) {
                                    if (category.insightTypes) {
                                        if (category.insightTypes.length > 0) vm.userSettingsInsightCategories.push(category);
                                        category.insightTypes.forEach(function (type) {
                                            vm.selectedInsightTypes.push(type);
                                        })
                                    }
                                })
                            }
                            vm.removeInsightsCards();
                            //sort insight categories by display order
                            var selectedInsightCategory = definitions.insightCategories[Number($stateParams.insightCategory) - 1];
                            // needs to change the tabs to be dynamical and also cards from insight categories
                            vm.noGroupSelected = false;
                            if (selectedInsightCategory.display) {

                                selectedInsightCategory.insightGroups.forEach(function (group, k) {
                                    var insightTypes = selectedInsightCategory.insightGroups[k].insightTypes.filter(x => x.display);
                                    var userInsightIDs = vm.selectedInsightTypes.map(x => x.insightTypeID);
                                    var groupInsightIDs = insightTypes.map(x => x.insightTypeID);
                                    var hasInsightTypes = groupInsightIDs.some(r => userInsightIDs.includes(r))
                                    insightTypes.sort(function (a, b) { return a.displayOrder - b.displayOrder });
                                    //order cards by display id
                                    insightTypes.forEach(function (item, index) {

                                        if (item.display) {
                                            //let's find typeID from userSettings
                                            var elem = vm.userSettingsInsightTypes.find(type => type.insightTypeID == item.insightTypeID);
                                            if (elem != null && item.insightTypeID == elem.insightTypeID) {
                                                vm.createDetailInsightsCard(item.name, item.insights, group.insightGroupID, item.acknowledgedActions, item.insightTypeID, item.investigateAction, item.level);
                                            }
                                        }
                                    });
                                    vm.createOverviewInsightCard(group, hasInsightTypes);
                                    vm.tabName[k] = selectedInsightCategory.insightGroups[k].name;
                                });

                                $scope.loading = false;

                                vm.options = vm.insightsService.getOptions();
                                var prevSession = JSON.parse(sessionStorage.getItem('segmentData'));
                                sessionStorage.setItem('segmentData', JSON.stringify(Object.assign(prevSession ? prevSession : {}, {
                                    insight_category: $stateParams.insightCategory,
                                    tab: 'Signal',
                                    filter_parameters: [{
                                        state: vm.stateStrings[vm.options.filtering.state - 1],
                                        priority: vm.priorityStrings[vm.options.filtering.priority + 1]
                                    }],
                                    component: 'insights_component'
                                })));
                                if (sessionStorage.getItem('main_back_navigation')) {
                                    // telemetryService.insightsDashboardTelemetry("main_back");
                                    sessionStorage.removeItem('main_back_navigation');
                                } else if (sessionStorage.getItem('breadcrumb_navigation')) {
                                    // telemetryService.insightsDashboardTelemetry("breadcrumb");
                                    sessionStorage.removeItem('breadcrumb_navigation');
                                } else {
                                    // telemetryService.insightsDashboardTelemetry("open");
                                }
                                //filter cards with new data received from server
                                vm.currentTab = vm.tabName[0];
                                vm.filterCards(vm.currentFilter);
                            }
                        })
                            .catch(function (errorType) {
                                $scope.loading = false;
                                if (vm)
                                    vm.cardServerError = true;
                            });

                    });
            });
        }

        function setupDataTable() {
            vm.selected = [];
            vm.totalCount = {};
            vm.options = {
                rowSelection: true,
                multiSelect: false,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: true,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };

            vm.queryData = {
                start: '',
                end: '',
                //set this when querying insights for just 1 signal
                signalID: '',
                //set this when querying for just 1 category id. 
                insightCategoryID: $stateParams.insightCategory
            };
        }

        function saveAckItem(acknowledged, card) {
            insightsService.setAcknowledgeItem(acknowledged)
                .then(function () {
                    acknowledged.isAcknowledged = true;
                    acknowledged.newOccurrenceAfterExpiration = false;
                    acknowledged.previousAcknowledgement.acknowledgedComment = "";
                    acknowledged.previousAcknowledgement.selectedAckReasons = [];
                    acknowledged.previousAcknowledgement.acknowledgedByEmail = "";
                    card.filterItems(vm.currentFilter);
                });
        }

        function updateAckItem(acknowledged, card) {
            insightsService.updateAcknowledgeItem(acknowledged)
                .then(function () {
                    acknowledged.isAcknowledged = true;
                    acknowledged.newOccurrenceAfterExpiration = false;
                    acknowledged.previousAcknowledgement.acknowledgedComment = "";
                    acknowledged.previousAcknowledgement.selectedAckReasons = [];
                    acknowledged.previousAcknowledgement.acknowledgedByEmail = "";
                    card.filterItems(vm.currentFilter);
                });
        }

        function removeAckItem(acknowledged, card) {
            insightsService.removeAcknowledgeItem(acknowledged)
                .then(function () {
                    acknowledged.isAcknowledged = false;
                    acknowledged.newOccurrenceAfterExpiration = false;
                    acknowledged.currentAcknowledgment.acknowledgedComment = "";
                    acknowledged.currentAcknowledgment.selectedAckReasons = [];
                    acknowledged.currentAcknowledgment.acknowledgedByEmail = "";
                    acknowledged.currentAcknowledgment.acknowledgedTimestamp = "";
                    acknowledged.previousAcknowledgement.acknowledgedComment = "";
                    acknowledged.previousAcknowledgement.selectedAckReasons = [];
                    acknowledged.previousAcknowledgement.acknowledgedByEmail = "";
                    card.filterItems(vm.currentFilter);
                });
        }

        function setIgnore(ignoredItem, card) {
            insightsService.setIgnoreItem(ignoredItem)
                .then(function (respIgnored) {
                    card.filterItems(vm.currentFilter);
                });
        }
    }

})();