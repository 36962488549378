﻿(function () {
    'use strict';

    angular.module('app.spm.charts.signal')
    .directive('phaseTerminationCountChart', phaseTerminationCountDirective);

    /** @ngInject */
    function phaseTerminationCountDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'phaseTerminationCountController as vm',
            templateUrl: function (elem, attrs) {
                return 'app/main/spm/charts/signals/directives/chart-sunburst-widget.html';
            },
            scope: {
                loading: '=',
                fetchData:'<',
                spmChartOptions: '<',
                signal: '<',
                searchDates: '<',
                api: '=',
                onApiInit: '&',
            },
            link: function (scope) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        updateData: function (data) {
                            return scope.updateData(data);
                        },
                        updateFetchData: function (signal, dates){
                            return scope.updateFetchData(signal, dates);
                        }
                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
                // if(scope.errorType){
                //     angular.extend(scope.errorType,{
                //         errorTypes: function(data){
                //             return scope.errorTypes(data);
                //         }
                //     });
                //     if (scope.onErrorType) {
                //         scope.onErrorType({ errorTypeObj: scope.errorType });
                //     }
                // }
            }
        };
    }
})();