import * as SIGNALOVERVIEW from 'raw-loader!./dashboard-signal.html'

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.signal-overview',
             []
        )
        .config(config);

    /** @ngInject */
    function config(msApiProvider, $stateProvider,$translatePartialLoaderProvider)
    {
        //var resolve = {
        //    DashboardData: function (msApi) {
        //        return msApi.resolve('dashboard.analytics@get');
        //    },
        //    //PerformanceData: function (msApi) {
        //    //    return msApi.resolve('dashboard.performance@get');
        //    //}
        //};
        // State
        //$runtimeStatesProvider.addState('app.spm.dashboards.signal-overview', '/signal-overview',
        //                        'app/main/spm/dashboards/signal-overview/dashboard-signal.html', 'DashboardSignalController as vm','dashboard-analytics');

        // Api
        //msApiProvider.register('dashboard.analytics', ['app/data/dashboard/analytics/data.json']);
        //msApiProvider.register('dashboard.performance', ['app/data/dashboard/performance/data.json']);
        $stateProvider
            .state('app.spm.dashboards.signal-overview', {
                url: '/signal-overview',
                views: {
                    'content@app': {
                        template: SIGNALOVERVIEW,
                        controller: 'DashboardSignalController as vm'
                    }
                }
            });
        $translatePartialLoaderProvider.addPart('signal-overview');

    }

})();