﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts")
        .controller("splitMonitorController", splitMonitorController);

    function splitMonitorController($state, $scope, $interval, $mdSidenav, $rootScope, $attrs, $element, environmentConfig, chartsService, insightsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 2;
        vm.isDataAvailable = false;
        vm.chartServerError = false;
        insightsService.setInsightsSidemenu();

        if (!$scope.fetchData) {
            $scope.updateData = function (data) {
                if (data && data != "{}") {
                    var jsonData = JSON.stringify(data, null, 4);
                    vm.chartServerError = false;
                    vm.splitMonitorWidget.processSplitPhaseChart(JSON.parse(jsonData, JSON.dateParser));
                    $scope.loading = false;
                }

            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            $scope.api = {};
            $scope.onApiInit = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }


        vm.splitMonitorWidget = {
            getChart: function () {
                var clonedChartSetup = angular.copy(vm.splitMonitorWidget.chartSetup);
                return clonedChartSetup;
            },
            addPlanLabels: function (primaryPlans) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 0:
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        plan.labels.push(planText);
                        plan.startTime = new Date(plan.startTime);
                        plan.endTime = new Date(plan.endTime);
                        plansCopy[i] = plan;
                        // getPlanAnnotations(primaryPlans);
                    }
                }
                return plansCopy;
            },
            //logic for taking server events and manipulating into format the chart is expecting
            processSplitPhaseChart: function (rawData) {
                var data = [],
                    shapes = ['circle', 'rectRot', 'triangle', 'cross', 'rectRot', 'rect'];
                var thisShape = "";
                var group = "";
                var searchObj = searchBarService.getSearchOptions();

                if (rawData && rawData.phases.length > 0) {
                    vm.isDataAvailable = true;
                }
                else {
                    vm.isDataAvailable = false;
                }

                rawData.phases.forEach(function (phaseItem) {
                    var thisPhaseData = {};
                    var itemCount = 0;
                    var minTime, maxTime, maxSeconds;
                    var phaseNum = phaseItem.phaseNumber;
                    var thisColor;
                    phaseItem.cycles.forEach(function (cycleItem) {
                        switch (cycleItem.terminationEvent) {
                            case 4:
                                group = "Gap Out";
                                thisShape = shapes[0];
                                thisColor = chartsService.colors.greenExtend;
                                break;
                            case 5:
                                thisShape = shapes[5];
                                group = "Max Out";
                                thisColor = chartsService.colors.redPreemption;
                                break;
                            case 6:
                                group = "Force Off";
                                thisShape = shapes[4];
                                thisColor = chartsService.colors.forceOff;
                                break;
                            case 0:
                            default:
                                thisShape = shapes[0];
                                group = "Unknown";
                                thisColor = chartsService.colors.unknown;
                                break;
                        }
                        if (!thisPhaseData[group]) {
                            thisPhaseData[group] = [];
                            thisPhaseData[group].push({
                                key: group,
                                label: group,
                                data: [],
                                type: 'scatter',
                                pointStyle: thisShape,
                                yAxisID: '1',
                                backgroundColor: thisColor
                            });
                        }
                        var startDate = new Date(cycleItem.startTime);
                        var endDate = new Date(cycleItem.endTime);
                        var duration = cycleItem.duration;

                        //if (!minTime || startDate < minTime) {
                        //    minTime = angular.copy(startDate);
                        //}
                        //if (!maxTime || startDate > maxTime) {
                        //    maxTime = angular.copy(startDate);
                        //}
                        //if (!maxSeconds || maxSeconds < duration) {
                        //    maxSeconds = Math.ceil(angular.copy(duration));
                        //}

                        thisPhaseData[group][0].data.push({
                            x: startDate,
                            y: duration,
                            // shape: thisShape,
                        });
                        itemCount++;
                    });
                    var groupArr = [];
                    for (group in thisPhaseData) {
                        groupArr.push(thisPhaseData[group][0]);
                    }

                    //sort by group so each chart has same color legend
                    groupArr.sort(function (a, b) {
                        if (a.key > b.key) {
                            return 1;
                        }
                        else if (a.key < b.key) {
                            return -1;
                        }
                        return 0;
                    });
                    //var begin = angular.copy(new Date($scope.spmChartOptions.startDate));
                    //var end = angular.copy(new Date($scope.spmChartOptions.endDate));
                    //begin.setMinutes(new Date($scope.spmChartOptions.startDate).getMinutes());
                    //end.setMinutes(new Date($scope.spmChartOptions.endDate).getMinutes());
                    //minTime.setMinutes(minTime.getMinutes() - 10);
                    //maxTime.setMinutes(maxTime.getMinutes() + 10)
                    //clonedChartSetup.options.chart.scatters1.xDomain = [minTime, maxTime];
                    //clonedChartSetup.options.chart.yDomain = [0, maxSeconds];
                    if (!$scope.signal.description) {
                        $scope.signal.description = getSignalDescription($scope.signal.customID, $scope.signal.primaryName, $scope.signal.secondaryName);
                    }

                    data.push({
                        title: "Phase " + phaseItem.phaseNumber + " for " + $scope.signal.description,
                        api: {},
                        dataset: groupArr,
                        plans: vm.splitMonitorWidget.addPlanLabels(rawData.plans),
                        flex: 50,
                        isDataAvailable: groupArr.length > 0,
                        planColor: "#000",
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                            this.plans.visible = false;
                            if (chart.plansHidden) {
                                chart.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                chart.planColor = "#fff";
                            } else {
                                chart.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                chart.planColor = "#000";
                            }
                            chart.api.update();
                        },
                        isRendered: false,
                        chartOptions: {
                            responsive: true,
                            hidePlans: false,
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    // time: {
                                    //     unit: 'hour'
                                    // },
                                    ticks: {
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                        minRotation: 0,
                                        maxRotation: 0,
                                        // callback: function (value, index, values) {
                                        //     return moment(values[index].value).format('hh:mm A');;
                                        // }
                                    }
                                },
                                ],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Phase Duration (seconds)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    // ticks: {
                                    //     callback: function (d) {
                                    //         if (d == 0) return null;
                                    //         if (d % 1 != 0) return null;
                                    //         else if (d > vm.maxPhase) return null;
                                    //         else return d3.format('.0f')(d);
                                    //     },
                                    //     max: vm.maxPhase + 1
                                    // },
                                    id: '1',
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    // stacked: true,
                                    // type: 'linear',
                                    position: 'left',

                                }
                                ]
                            },
                            legend: {
                                display: true,
                                reverse: true,
                                align: 'end',
                            },
                            hover: {
                                mode: 'nearest'
                            },
                            tooltips: {
                                mode: 'nearest'
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            }
                        },
                    });
                });

                vm.chartRendering = true;
                vm.chartArray = data;
                vm.isDataAvailable = vm.chartArray.length > 0;
            },
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    // vm.rawData = JSON.stringify(data, null, 4);
                    vm.splitMonitorWidget.processSplitPhaseChart(data);
                    $scope.loading = false;

                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;

                });

        }
    }
}());
