
import './signals.module';
import './signals.Resource';
import './signal/view/signal.controller';
import './signal/view/signal-add.controller';
import './signal-list/view/signal-list.controller';
import './performance/directives/signal-performance-card.directive';
import './performance/directives/signal-performance-card.controller';
import './trends/signal-trends-card.directive';
import './trends/signal-trends-card.controller';
import './approach/view/approach.controller';
import './approach/view/approach-add.controller';
import './detector/view/detector.controller';
import './detector/view/detector-add.controller';