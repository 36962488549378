import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("splitMonitorStackedController", splitMonitorStackedController);

    function splitMonitorStackedController($state, $attrs, $scope, $rootScope, $element, environmentConfig, chartsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 2;
        vm.getOptionsAndData = getOptionsAndData;
        vm.isRing1DataAvailable = true;
        vm.isRing2DataAvailable = true;
        vm.chartServerError = false;
        vm.formatAMPM = formatAMPM;
        vm.ring1BlobColor = "#000";
        vm.ring2BlobColor = "#000";

        if (!$scope.widgetFlex || $scope.widgetFlex == 0) {
            vm.widgetFlex = 50;
        } else {
            vm.widgetFlex = $scope.widgetFlex;
        }
        $scope.updateData = function (data) {
            vm.splitMonitorBarWidget.processSplitData(data);
        }

        var planAnnotations = [];
        function getPlanAnnotations(planList) {
            for (let i = 0; i < planList.length; i++) {
                //create annotation for each plan
                var fillStyle;
                var thisPlan = planList[i];

                var odd = i % 2;
                if (odd) {
                    fillStyle = 'rgba(238, 238, 238, 0)';
                }
                else {
                    fillStyle = 'rgba(0,0,0, 0.1)';
                }

                // var planStartTime = new Date(thisPlan.startTime).getTime();
                // var planEndTime = new Date(thisPlan.endTime).getTime();

                var planAnnotation = {
                    id: 'plan-' + i + "-" + thisPlan.planNumber, // optional
                    type: 'box',
                    drawTime: 'afterDatasetsDraw',
                    // ID of the X scale to bind onto
                    xScaleID: 'x-axis-0',
                    // ID of the Y scale to bind onto
                    yScaleID: '1',
                    xMin: thisPlan.startTime,
                    borderColor: 'transparent',
                    xMax: thisPlan.endTime,
                    yMin: 0,
                    backgroundColor: fillStyle,
                }
                planAnnotations.push(planAnnotation);
            }
        }

        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        function generateChartColors(d, counter) {
            var barChartColors = ['#33a02c', '#1f78b4', '#6a3d9a', '#a6cee3'];
            var resColor = "";
            if (d == "Red Clearance") {
                resColor = '#e31a1c';
            }
            else if (d == "Yellow Clearance") {
                resColor = '#e1e199';
            }
            else {
                resColor = barChartColors[counter % barChartColors.length];
            }

            return resColor;
        }

        $scope.updateFetchData = function (signal, dates) {
            if (signal) {
                $scope.signal = signal;
            }
            if (dates) {
                if (!$scope.searchDates)
                    $scope.searchDates = {};

                $scope.searchDates.endDateAndTime = new Date(dates.endDateAndTime);
                $scope.searchDates.startDateAndTime = new Date(dates.startDateAndTime);
            }
            if (vm)
                vm.getOptionsAndData(true);
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            $scope.api = {};
            $scope.onApiInit = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        })

        vm.splitMonitorBarWidget = {
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.splitMonitorBarWidget.chartDef);
                // clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            isRendered: false,
            chartArray: {},
            signalName: $scope.signal.description,
            //holds ring 1 chart data
            ring1: {
                data: [],
            },
            //ring 2 chart data
            ring2: {
                data: [],
            },
            addPlanLabels: function (primaryPlans) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 0:
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        plan.labels.push(planText);
                        plan.startTime = new Date(plan.startTime);
                        plan.endTime = new Date(plan.endTime);
                        plansCopy[i] = plan;
                        getPlanAnnotations(plansCopy);
                    }
                }
                return plansCopy;
            },
            //logic for taking server events and manipulating into format the chart is expecting
            processSplitData: function (rawData) {
                var ring1data = [];
                var ring2data = [];
                var allCycleStartsR1 = [];
                var allCycleStartsR2 = [];
                var planList = rawData.plans;
                var phaseOrderR1 = [];
                var phaseOrderR2 = [];
                var searchObj = searchBarService.getSearchOptions();

                if (rawData && Object.keys(rawData.ring1Data).length > 0) {
                    vm.isRing1DataAvailable = true;
                }
                else {
                    vm.isRing1DataAvailable = false;
                }
                if (rawData && Object.keys(rawData.ring2Data).length > 0) {
                    vm.isRing2DataAvailable = true;
                }
                else {
                    vm.isRing2DataAvailable = false;
                }

                for (var cycleStart in rawData.ring1Data) {
                    rawData.ring1Data[cycleStart].forEach(function (phase) {
                        if (!ring1data[phase.phaseNumber]) {
                            ring1data[phase.phaseNumber] = [];
                        }
                        if (phaseOrderR1.indexOf(phase.phaseNumber) == -1) {
                            phaseOrderR1[phase.phaseNumber] = phase.phaseNumber;
                        }
                        var xAxis = new Date(cycleStart);
                        if (xAxis.getTime() > 0) {
                            if (allCycleStartsR1.map(Number).indexOf(+xAxis) < 0) {
                                allCycleStartsR1.push(xAxis);
                            }
                        }
                        ring1data[phase.phaseNumber].push({
                            x: xAxis,
                            y: phase.duration,
                            yellowClear: phase.yellowClearanceDuration,
                            redClear: phase.redClearanceDuration,
                            splitStart: new Date(phase.startTime).toLocaleString(),
                            splitEnd: new Date(phase.endTime).toLocaleString(),
                            termination: chartsService.getTerminationFromEvent(phase.terminationEvent)
                        });
                    });

                }

                for (var cycleStart in rawData.ring2Data) {
                    rawData.ring2Data[cycleStart].forEach(function (phase) {
                        if (!ring2data[phase.phaseNumber]) {
                            ring2data[phase.phaseNumber] = [];
                        }
                        if (phaseOrderR2.indexOf(phase.phaseNumber) == -1) {
                            phaseOrderR2[phase.phaseNumber] = phase.phaseNumber;
                        }
                        var xAxis = new Date(cycleStart);
                        if (xAxis.getTime() > 0) {
                            if (allCycleStartsR2.map(Number).indexOf(+xAxis) < 0) {
                                allCycleStartsR2.push(xAxis);
                            }
                        }
                        ring2data[phase.phaseNumber].push({
                            x: xAxis,
                            y: phase.duration,
                            yellowClear: phase.yellowClearanceDuration,
                            redClear: phase.redClearanceDuration,
                            splitStart: new Date(phase.startTime).toLocaleString(),
                            splitEnd: new Date(phase.endTime).toLocaleString(),
                            termination: chartsService.getTerminationFromEvent(phase.terminationEvent)
                        });
                    });
                }
                var ring1Chart = [];
                var ring2Chart = [];

                var counter = 0;
                for (var index in phaseOrderR1) {
                    var phaseNum = phaseOrderR1[index];
                    if (phaseNum) {
                        ring1Chart.push({
                            key: "Phase " + phaseNum,
                            label: "Phase " + phaseNum,
                            data: ring1data[phaseNum],
                            backgroundColor: generateChartColors('', counter),
                            borderColor: generateChartColors('', counter),
                            type: "bar",
                            yAxisID: '1'
                        });
                        counter++;
                    }
                }
                counter = 0;
                for (var index in phaseOrderR2) {
                    var phaseNum = phaseOrderR2[index];
                    if (phaseNum) {
                        ring2Chart.push({
                            key: "Phase " + phaseNum,
                            label: "Phase " + phaseNum,
                            data: ring2data[phaseNum],
                            backgroundColor: generateChartColors('', counter),
                            borderColor: generateChartColors('', counter),
                            type: "bar",
                            yAxisID: '1'
                        });
                        counter++;
                    }
                }
                allCycleStartsR1.sort(function (a, b) { return b - a });
                allCycleStartsR2.sort(function (a, b) { return b - a });

                ring1data.forEach(function (phaseStackGrp) {
                    OrderPhaseDates(phaseStackGrp, allCycleStartsR1);
                    //spaceDateTimes(phaseStackGrp);
                });

                if ($scope.splitRings) {
                    ring2data.forEach(function (phaseStackGrp) {
                        OrderPhaseDates(phaseStackGrp, allCycleStartsR2);
                        //spaceDateTimes(phaseStackGrp);
                    });
                }


                vm.splitMonitorBarWidget.ring1.data = ring1Chart;
                vm.splitMonitorBarWidget.ring2.data = ring2Chart;

                vm.splitMonitorBarWidget.ring1 = {
                    plans: vm.splitMonitorBarWidget.addPlanLabels(rawData.plans),
                    plansHidden: false,
                    onDestroy: function () {
                        this.api = {};
                    },
                    onApiInit: function (apiObj) {
                        this.api = apiObj;
                        this.render(apiObj);
                    },
                    render: function (apiObj) {
                        apiObj.render(undefined, this.dataset, this.chartOptions);
                        vm.chart =
                            vm.chartRendering = false;
                        this.isRendered = true;
                    },
                    hidePlanLabels: function (e, chart) {
                        chart.plansHidden = !chart.plansHidden;
                        chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                        Chart.defaults.global.togglePlans(vm.splitMonitorBarWidget.ring1, chart.plansHidden);
                        this.plans.visible = false;
                        if (chart.plansHidden) {
                            vm.splitMonitorBarWidget.ring1.plans.forEach(function (plan) {
                                plan.labels2 = plan.labels;
                                plan.labels = [];
                            });
                            vm.ring1BlobColor = "#fff";
                        } else {
                            vm.splitMonitorBarWidget.ring1.plans.forEach(function (plan) {
                                plan.labels = plan.labels2;
                            });
                            vm.ring1BlobColor = "#000";
                        }
                        vm.splitMonitorBarWidget.ring1.api.update();
                    },
                    api: {},
                    dataset: ring1Chart,
                    data: ring1Chart,
                    isRendered: false,
                    // data: ring1Chart,
                    chartOptions: {
                        responsive: true,
                        maintainAspectRatio: false,
                        hidePlans: false,
                        hover: {
                            animationDuration: 0
                        },
                        animation: false,
                        tooltips: {
                            intersect: false,
                        },
                        legend: {
                            display: true,
                            // labels: {
                            //     filter: function (legendItem) {
                            //         if (legendItem.datasetIndex == 0 || legendItem.datasetIndex == 1 || legendItem.datasetIndex == 2) {
                            //             return false;
                            //         }
                            //         return true;
                            //     }
                            // },
                            align: 'end',
                        },
                        tooltips: {
                            enabled: false,
                            intersect: false,
                            mode: 'point',
                            // custom: function (tooltip) {
                            callbacks: {
                                afterBody: function (t, d) {
                                    var currentData = d.datasets[t[0].datasetIndex].data[t[0].index];

                                    var respArr = [
                                        {
                                            title: 'Yellow Clearance Duration',
                                            value: currentData.yellowClear
                                        },
                                        {
                                            title: 'Red Clearance Duration',
                                            value: currentData.redClear
                                        },
                                        {
                                            title: 'Split Start',
                                            value: currentData.splitStart
                                        },
                                        {
                                            title: 'Split End',
                                            value: currentData.splitEnd
                                        },
                                        {
                                            title: 'Termination',
                                            value: currentData.termination
                                        }
                                    ];

                                    return respArr;
                                }
                            },
                            custom: function (tooltipModel, dtes) {
                                var tooltipEl = document.getElementById('chartjs-tooltip');

                                // Create element on first render
                                if (!tooltipEl) {
                                    tooltipEl = document.createElement('div');
                                    tooltipEl.id = 'chartjs-tooltip';
                                    tooltipEl.innerHTML = '<table></table>';
                                    document.body.appendChild(tooltipEl);
                                }

                                // Hide if no tooltip
                                if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }

                                // Set caret Position
                                tooltipEl.classList.remove('above', 'below', 'no-transform');
                                if (tooltipModel.yAlign) {
                                    tooltipEl.classList.add(tooltipModel.yAlign);
                                } else {
                                    tooltipEl.classList.add('no-transform');
                                }

                                function getBody(bodyItem) {
                                    return bodyItem.lines;
                                }
                                var bodyLines = tooltipModel.body.map(getBody);
                                if (tooltipModel.body) {

                                    var plan = vm.splitMonitorBarWidget.ring1.plans.filter(x => moment(x.startTime).isBefore(moment(tooltipModel.title[0])) && moment(x.endTime).isAfter(moment(tooltipModel.title[0])));

                                    var planText = "";
                                    switch (plan[0].planNumber) {
                                        case 254:
                                        case 0:
                                            planText = "Free";
                                            break;
                                        case 255:
                                            planText = "Flash";
                                            break;
                                        default:
                                            planText = plan[0].planNumber;
                                            break;
                                    }

                                    var rows = "<tr>" +
                                        "<td class='key'>" + 'Plan: ' + "</td>" +
                                        "<td class='x-value'><strong>" + planText + "</strong></td>" +
                                        "</tr>";
                                    rows += "<tr>" +
                                        "<td class='key'>" + 'Split Duration: ' + "</td>" +
                                        "<td class='x-value'><strong>" + tooltipModel.dataPoints[0].yLabel + "</strong></td>" +
                                        "</tr>";
                                    tooltipModel.afterBody
                                        .forEach(function (toolTipData) {
                                            rows += "<tr>" +
                                                "<td class='key'>" + toolTipData.title + ":</td>" +
                                                "<td class='x-value'><strong>" + toolTipData.value + "</strong></td>" +
                                                "</tr>";
                                        });
                                    var header =
                                        "<thead>" +
                                        "<tr>" +
                                        "<td class='key'><strong>" + tooltipModel.body[0].lines[0].split(':')[0] + "</strong></td>" +
                                        "</tr>" +
                                        "</thead>";

                                    var innerHTML = "<table>" +
                                        header +
                                        "<tbody>" +
                                        rows +
                                        "</tbody>" +
                                        "</table>";

                                    var tableRoot = tooltipEl.querySelector('table');
                                    var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                    tableStyle += 'color: rgba(255,255,255, 1);';
                                    tableStyle += 'padding: 5px 10px;';

                                    tableRoot.style = tableStyle;
                                    tableRoot.innerHTML = innerHTML;
                                    vm.ttRows = "";
                                }

                                // `this` will be the overall tooltip
                                var position = this._chart.canvas.getBoundingClientRect();
                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.position = 'absolute';
                                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                if (position.left + window.pageXOffset + tooltipModel.caretX + 300 > window.innerWidth) {
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 100 + 'px';
                                }
                                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                tooltipEl.style.pointerEvents = 'none';
                            }
                        },
                        scales: {
                            xAxes: [{
                                barThickness: 'flex',
                                stacked: true,
                                type: 'time',
                                id: 'x-axis-0',
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Time',
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                },
                                // time: {
                                //     unit: 'hour'
                                // },
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    minRotation: 0,
                                    maxRotation: 0,
                                    autoSkip: true,
                                    autoSkipPadding: 50,
                                    // callback: function (value, index, values) {
                                    //     var timeValue = moment(values[index].value).hours() == 0 ? moment(values[index].value).format('MM-DD hh:mm A') : moment(values[index].value).format('h:mm A');
                                    //     return timeValue;
                                    // }
                                }
                            }],
                            yAxes: [{
                                stacked: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Duration (seconds)',
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                },
                                id: '1',
                                position: 'left',
                                type: 'linear'
                            }
                            ]
                        },
                        plugins: {
                            zoom: {
                                pan: {
                                    enabled: false,
                                    mode: 'x',

                                    rangeMin: {
                                        x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                    },
                                    rangeMax: {
                                        x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                    }
                                },
                                zoom: {
                                    enabled: true,

                                    drag: true,
                                    speed: 0.1,
                                    mode: 'x',

                                    rangeMin: {
                                        x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                    },
                                    rangeMax: {
                                        x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                    }
                                }
                            }
                        },
                    }
                }

                vm.splitMonitorBarWidget.ring2 = {
                    plans: vm.splitMonitorBarWidget.addPlanLabels(rawData.plans),
                    plansHidden: false,
                    onDestroy: function () {
                        this.api = {};
                    },
                    onApiInit: function (apiObj) {
                        this.api = apiObj;
                        this.render(apiObj);
                    },
                    render: function (apiObj) {
                        apiObj.render(undefined, this.dataset, this.chartOptions);
                        vm.chart =
                            vm.chartRendering = false;
                        this.isRendered = true;
                    },
                    hidePlanLabels: function (e, chart) {
                        chart.plansHidden = !chart.plansHidden;
                        chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                        Chart.defaults.global.togglePlans(vm.splitMonitorBarWidget.ring2, chart.plansHidden);
                        this.plans.visible = false;
                        if (chart.plansHidden) {
                            vm.splitMonitorBarWidget.ring2.plans.forEach(function (plan) {
                                plan.labels2 = plan.labels;
                                plan.labels = [];
                            });
                            vm.ring2BlobColor = "#fff";
                        } else {
                            vm.splitMonitorBarWidget.ring2.plans.forEach(function (plan) {
                                plan.labels = plan.labels2;
                            });
                            vm.ring2BlobColor = "#000";
                        }
                        vm.splitMonitorBarWidget.ring2.api.update();
                    },
                    dataset: ring2Chart,
                    data: ring2Chart,
                    chartOptions: {
                        animation: false,
                        tooltips: {
                            intersect: false,
                        },
                        hidePlans: false,
                        legend: {
                            display: true,
                            labels: {
                                filter: function (legendItem) {
                                    return true;
                                }
                            },
                            align: 'end',
                        },
                        tooltips: {
                            enabled: false,
                            intersect: false,
                            mode: 'point',
                            // custom: function (tooltip) {
                            callbacks: {
                                afterBody: function (t, d) {
                                    var currentData = d.datasets[t[0].datasetIndex].data[t[0].index];

                                    var respArr = [
                                        {
                                            title: 'Yellow Clearance Duration',
                                            value: currentData.yellowClear
                                        },
                                        {
                                            title: 'Red Clearance Duration',
                                            value: currentData.redClear
                                        },
                                        {
                                            title: 'Split Start',
                                            value: currentData.splitStart
                                        },
                                        {
                                            title: 'Split End',
                                            value: currentData.splitEnd
                                        },
                                        {
                                            title: 'Termination',
                                            value: currentData.termination
                                        }
                                    ];

                                    return respArr;
                                }
                            },
                            custom: function (tooltipModel, dtes) {
                                var tooltipEl = document.getElementById('chartjs-tooltip');

                                // Create element on first render
                                if (!tooltipEl) {
                                    tooltipEl = document.createElement('div');
                                    tooltipEl.id = 'chartjs-tooltip';
                                    tooltipEl.innerHTML = '<table></table>';
                                    document.body.appendChild(tooltipEl);
                                }

                                // Hide if no tooltip
                                if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }

                                // Set caret Position
                                tooltipEl.classList.remove('above', 'below', 'no-transform');
                                if (tooltipModel.yAlign) {
                                    tooltipEl.classList.add(tooltipModel.yAlign);
                                } else {
                                    tooltipEl.classList.add('no-transform');
                                }

                                function getBody(bodyItem) {
                                    return bodyItem.lines;
                                }
                                var bodyLines = tooltipModel.body.map(getBody);
                                if (tooltipModel.body) {

                                    var plan = vm.splitMonitorBarWidget.ring2.plans.filter(x => moment(x.startTime).isBefore(moment(tooltipModel.title[0])) && moment(x.endTime).isAfter(moment(tooltipModel.title[0])));

                                    var planText = "";
                                    switch (plan[0].planNumber) {
                                        case 254:
                                        case 0:
                                            planText = "Free";
                                            break;
                                        case 255:
                                            planText = "Flash";
                                            break;
                                        default:
                                            planText = plan[0].planNumber;
                                            break;
                                    }

                                    var rows = "<tr>" +
                                        "<td class='key'>" + 'Plan: ' + "</td>" +
                                        "<td class='x-value'><strong>" + planText + "</strong></td>" +
                                        "</tr>";
                                    rows += "<tr>" +
                                        "<td class='key'>" + 'Split Duration: ' + "</td>" +
                                        "<td class='x-value'><strong>" + tooltipModel.dataPoints[0].yLabel + "</strong></td>" +
                                        "</tr>";
                                    tooltipModel.afterBody
                                        .forEach(function (toolTipData) {
                                            rows += "<tr>" +
                                                "<td class='key'>" + toolTipData.title + ":</td>" +
                                                "<td class='x-value'><strong>" + toolTipData.value + "</strong></td>" +
                                                "</tr>";
                                        });
                                    var header =
                                        "<thead>" +
                                        "<tr>" +
                                        "<td class='key'><strong>" + tooltipModel.body[0].lines[0].split(':')[0] + "</strong></td>" +
                                        "</tr>" +
                                        "</thead>";

                                    var innerHTML = "<table>" +
                                        header +
                                        "<tbody>" +
                                        rows +
                                        "</tbody>" +
                                        "</table>";

                                    var tableRoot = tooltipEl.querySelector('table');
                                    var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                    tableStyle += 'color: rgba(255,255,255, 1);';
                                    tableStyle += 'padding: 5px 10px;';

                                    tableRoot.style = tableStyle;
                                    tableRoot.innerHTML = innerHTML;
                                    vm.ttRows = "";
                                }

                                // `this` will be the overall tooltip
                                var position = this._chart.canvas.getBoundingClientRect();
                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.position = 'absolute';
                                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                if (position.left + window.pageXOffset + tooltipModel.caretX + 300 > window.innerWidth) {
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 100 + 'px';
                                }
                                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                if (position.height <= tooltipModel.caretY + tooltipEl.clientHeight) {
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 80 + 'px';
                                }
                                tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                tooltipEl.style.pointerEvents = 'none';
                            }
                        },
                        scales: {
                            xAxes: [{
                                barThickness: 'flex',
                                stacked: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Time',
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                },
                                type: 'time',
                                id: 'x-axis-0',
                                // time: {
                                //     unit: 'hour'
                                // },
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    autoSkipPadding: 50,
                                    minRotation: 0,
                                    maxRotation: 0,
                                    // maxTicksLimit: 18,
                                    // callback: function (value, index, values) {
                                    //     var timeValue = moment(values[index].value).hours() == 0 ? moment(values[index].value).format('MM-DD hh:mm A') : moment(values[index].value).format('h:mm A');
                                    //     return timeValue;
                                    // }
                                }
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Duration (seconds)',
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                },
                                id: '1',
                                position: 'left',
                                stacked: true,
                                // type: 'linear',
                                ticks: {
                                    // beginAtZero: true,
                                    // callback: function (d) {
                                    //     if (Math.floor(d) != d) return d3.format('.01f')(d);
                                    //     else return d;
                                    // },
                                },
                            }
                            ]
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        hover: {
                            animationDuration: 0
                        },
                        plugins: {

                            zoom: {
                                pan: {
                                    enabled: false,
                                    mode: 'x',

                                    rangeMin: {
                                        x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                    },
                                    rangeMax: {
                                        x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                    }
                                },
                                zoom: {
                                    enabled: true,

                                    drag: true,
                                    speed: 0.1,
                                    mode: 'x',

                                    rangeMin: {
                                        x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                    },
                                    rangeMax: {
                                        x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                    }
                                }
                            }
                        }
                    }
                }
                // vm.splitMonitorBarWidget.ring1.chart = vm.splitMonitorBarWidget.getChart(planList);
                // vm.splitMonitorBarWidget.ring2.chart = vm.splitMonitorBarWidget.getChart(planList);
                vm.splitMonitorBarWidget.signalName = $scope.signal.description;

                function OrderPhaseDates(phaseStackGrp, cycleStarts) {
                    var diffs = cycleStarts.filter(function (n) {
                        return phaseStackGrp.map(function (e) { return Number(e.x) }).indexOf(+n) < 0;
                    });

                    diffs.forEach(function (addDateTime) {
                        phaseStackGrp.push({
                            x: addDateTime,
                            y: 0
                        });
                    });
                    phaseStackGrp.sort(function (a, b) {
                        return a.x - b.x;
                    });
                }

                //function spaceDateTimes(phaseStackGrp) {
                //    var lastTime;
                //    for (var i = 0; i < phaseStackGrp.length; i++) {
                //        if (!lastTime) {
                //            lastTime = new Date(phaseStackGrp[i].x);
                //            continue;
                //        }
                //        var newTime = new Date(lastTime);
                //        newTime.setMinutes(lastTime.getMinutes() + 1);
                //        newTime.setSeconds(lastTime.getSeconds() + 30);
                //        phaseStackGrp[i].x = newTime;
                //        lastTime = newTime;
                //    }
                //}
            }
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            vm.getOptionsAndData();
        }

        function getOptionsAndData(refresh) {
            $scope.loading = true;
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions || refresh) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            $scope.spmChartOptions.showAsStacked = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions, false)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    if (vm) {
                        vm.chartServerError = false;
                        vm.splitMonitorBarWidget.processSplitData(data);
                    }
                    //vm.splitMonitorBarWidget = localBarWidget;
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;

                });
        }
    }
}());
