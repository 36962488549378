﻿(function () {
    angular
            .module('app.spm.action-logs', [])
            .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider) {
        $stateProvider
            .state('app.spm.log-action', {
                url: '/action-logs',
                views: {
                    'content@app': {
                        templateUrl: 'app/main/spm/action-logs/view/action-logs.html',
                        controller: 'actionLogsController'
                    }
                }
            })
        // Translation
        //$translatePartialLoaderProvider.addPart('app/main/apps/spm/action-logs');
    }
}());