﻿import domtoimage from 'dom-to-image';
import * as FileSaver from 'file-saver';

(function () {
    "use strict";

    angular
        .module("app.spm.signals")
        .controller("signalTrendsCardController", signalTrendsCardController);

    function signalTrendsCardController($state, $attrs, $element, $scope, signalTrendsService, searchBarService, TrendsConstants, $timeout, helpService, insightsService, $interval) {
        var vm = this;
        vm.weekday = new Array(7);
        vm.weekday[0] = "Sunday";
        vm.weekday[1] = "Monday";
        vm.weekday[2] = "Tuesday";
        vm.weekday[3] = "Wednesday";
        vm.weekday[4] = "Thursday";
        vm.weekday[5] = "Friday";
        vm.weekday[6] = "Saturday";

        $scope.update = update;
        vm.metricType = $scope.metricType;
        vm.hideButtons = $scope.hideButtons;
        vm.loading = true;
        vm.selectedChartType = $scope.chartType;
        vm.signal = {};
        vm.searchBarService = searchBarService;
        vm.roundNumbers = true;
        vm.trendsConstants = TrendsConstants;
        vm.chartType = "";
        vm.chartText = "";
        vm.chartTitle = $scope.chartTitle;
        vm.isSquareRoot = $scope.isSquareRoot;
        vm.reverseColorScale = $scope.reverseColorScale;
        vm.isAverage = $scope.isAverage;
        vm.showTotal = $scope.showTotal;
        vm.legendTitle = "";
        vm.reverseColor = false;
        vm.showSummaryChart = true;
        vm.changeChart = changeChart;
        vm.signalTrendsService = signalTrendsService;
        vm.dataResponse = undefined;
        vm.updateCurrentChart = updateCurrentChart;
        vm.searchBarConfig = {};
        vm.getApproachIds = getApproachIds;
        vm.getSelectedApproaches = getSelectedApproaches;
        vm.approachesDescriptions = "";
        vm.expand = expand;
        vm.widthClass = $scope.isExpanded ? "width-1500" : "width-750";
        vm.data = [];
        vm.paddingLeft = "pl-200";
        vm.chartWidths = $scope.isExpanded ? 1500:750;
        vm.expandHourlyDaily = expandHourlyDaily;
        vm.isDaily = undefined;
        vm.showHelpAlert = showHelpAlert;
        vm.setLegendAndChartType = setLegendAndChartType;
        vm.setHourlyOrDaily = setHourlyOrDaily;
        vm.isDataAvailable = true;
        vm.getDaysOfWeekNumber = getDaysOfWeekNumber;
        vm.showHourlyOrDaily = false;
        vm.showDowChart = true;
        vm.loading = false;
        vm.chartSubheader = {};
        vm.baselineOptions = {};
        vm.openMenu = openMenu;
        vm.changeLegend = changeLegend;
        vm.selectedLegend = vm.signalTrendsService.legendTypes.scaleQuantile;
        vm.saveImage = saveImage;
        vm.generateFileName = generateFileName;

        function generateFileName(element) {
            var res = "";
            var options = searchBarService.getSearchOptions();
            var sigId = options.signal.signalID

            vm.timeOptions = options.timeOptions;
            res = "Signal Trends_" + element.children[0].children[0].children[0].children[0].innerText
                + "_" + sigId + "_" + new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleDateString() + ".png";
            return res;
        }

        function saveImage() {
            vm.loading = true;
            var element = $element[0];
            var children = element.children[0].children[0].children[0].children[1].children;
            angular.element($element.find("#loadingSpinner")).css('display', 'block');
            for (let i = 0; i < children.length; i++) {
                children[i].style.display = 'none';
            }
            domtoimage.toBlob(element)
                .then(function (blob) {
                    vm.loading = false;
                    angular.element($element.find("#loadingSpinner")).css('display', 'none');
                    $scope.$apply();
                    for (let i = 0; i < children.length; i++) {
                        children[i].style.display = 'flex';
                    }
                    window.saveAs(blob, vm.generateFileName(element));
                });
        }

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm)
                return;

            switch (changeType) {
                case "time":
                    if (vm.searchBarService.isFiltering()) {
                        vm.showHourlyOrDaily = vm.setHourlyOrDaily();
                        vm.dataResponse = undefined;
                    }
                    break;
            }
        });

        //setup options change also
        vm.signalTrendsService.subscribe($scope, function onChange(ev, options) {
            if (!vm)
                return;

            switch (options.name) {
                case "Filter":
                    vm.baselineDates = vm.signalTrendsService.getOptions();
                    vm.baselineOptions = {
                        baselineDateStart: vm.baselineDates.baselineStartDate ? vm.baselineDates.baselineStartDate : new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
                        baselineDateEnd: vm.baselineDates.baselineEndDate ? vm.baselineDates.baselineEndDate : new Date()
                    }
                    vm.setLegendAndChartType();
                    vm.updateCurrentChart();
                    break;
            }
        });

        vm.calendarHeatmap = {
            api: {},
            rawData: {
                changeByDowDaily: [],
                countByDowDaily: [],
                changeByDayDaily: [],
                baseLineDaily: [],
                baseLineDateDaily: [],
                baseLineDateHourly: [],
                changeByDowHourly: [],
                countByDowHourly: [],
                changeByDayHourly: [],
                baseLineHourly: [],
                clearData: function () {
                    this.changeByDowDaily = [];
                    this.countByDowDaily = [];
                    this.changeByDayDaily = [];
                    this.baseLineDaily = [];
                    this.baseLineDateDaily = [];
                    this.changeByDowHourly = [];
                    this.countByDowHourly = [];
                    this.changeByDayHourly = [];
                    this.baseLineHourly = [];
                    this.baseLineDateHourly = [];
                },
                getDataForSelectedChart: function () {
                    var res = [];
                    var filterOptions = vm.signalTrendsService.getOptions();
                    switch (filterOptions.filterState) {
                        case vm.trendsConstants.Filters.counts:
                            res = vm.isDaily ? this.countByDowDaily : this.countByDowHourly;
                            break;
                        case vm.trendsConstants.Filters.dailyChange:
                            res = vm.isDaily ? this.changeByDayDaily : this.changeByDayHourly;
                            break;
                        case vm.trendsConstants.Filters.dowChange:
                            res = vm.isDaily ? this.changeByDowDaily : this.changeByDowHourly;
                            break;
                        case vm.trendsConstants.Filters.baselineAverage:
                            res = vm.isDaily ? this.baseLineDaily : this.baseLineHourly;
                            break;
                        case vm.trendsConstants.Filters.baselineDate:
                            res = vm.isDaily ? this.baseLineDateDaily : this.baseLineDateHourly;
                            break;
                    }
                    return res;
                },
                containsData: function () {
                    var res = false;
                    var data = this.getDataForSelectedChart();
                    if (data.length > 0)
                        res = true;

                    return res;
                }
            },
            update: function () {
                if (!this.api || !this.api.update)
                    return;

                if (!this.rawData.containsData()) {
                    var dataBins = vm.isDaily ? vm.dataResponse.dailyBins : vm.dataResponse.hourlyBins;
                    //take data from server and setup into objects for chart bindings
                    for (let i = 0; i < vm.dataResponse.dailyBins.length; i++) {
                        var day = vm.dataResponse.dailyBins[i];
                        var dayWithInsights = vm.dataResponse && vm.dataResponse.dailyBinsWithInsights ?  vm.dataResponse.dailyBinsWithInsights[i] : null;
                        var missingData = dayWithInsights && dayWithInsights.dataInsights && dayWithInsights.dataInsights.missingData ? dayWithInsights.dataInsights.missingData : false;
                        var noData = dayWithInsights && dayWithInsights.dataInsights && dayWithInsights.dataInsights.noData ? dayWithInsights.dataInsights.noData : false;

                        this.rawData.changeByDayDaily.push({
                            "date": day.start,
                            "endDate": day.end,
                            "value": day.changePercentage,
                            "totalValue": day.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.baseLineDaily.push({
                            "date": day.start,
                            "endDate": day.end,
                            "value": day.baselineChangePercentage,
                            "totalValue": day.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.baseLineDateDaily.push({
                            "date": day.start,
                            "endDate": day.end,
                            "value": day.baselineDateChangePercentage,
                            "totalValue": day.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.changeByDowDaily.push({
                            "date": day.start,
                            "endDate": day.end,
                            "value": day.dayOfWeekChangePercentage,
                            "totalValue": day.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.countByDowDaily.push({
                            "date": day.start,
                            "endDate": day.end,
                            "value": day.value,
                            "totalValue": day.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                    }

                    for (let i = 0; i < vm.dataResponse.hourlyBins.length; i++) {
                        var hour = vm.dataResponse.hourlyBins[i];
                        var hourWithInsights =  vm.dataResponse && vm.dataResponse.hourlyBinsWithInsights ?  vm.dataResponse.hourlyBinsWithInsights[i] : null;
                        var missingData = hourWithInsights && hourWithInsights.dataInsights && hourWithInsights.dataInsights.missingData ? hourWithInsights.dataInsights.missingData : false;
                        var noData = hourWithInsights && hourWithInsights.dataInsights && hourWithInsights.dataInsights.noData ? hourWithInsights.dataInsights.noData : false;

                        this.rawData.changeByDayHourly.push({
                            "date": hour.start,
                            "endDate": hour.end,
                            "value": hour.changePercentage,
                            "totalValue": hour.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.baseLineHourly.push({
                            "date": hour.start,
                            "endDate": hour.end,
                            "value": hour.baselineChangePercentage,
                            "totalValue": hour.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.baseLineDateHourly.push({
                            "date": hour.start,
                            "endDate": hour.end,
                            "value": hour.baselineDateChangePercentage,
                            "totalValue": hour.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.changeByDowHourly.push({
                            "date": hour.start,
                            "endDate": hour.end,
                            "value": hour.dayOfWeekChangePercentage,
                            "totalValue": hour.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                        this.rawData.countByDowHourly.push({
                            "date": hour.start,
                            "endDate": hour.end,
                            "value": hour.value,
                            "totalValue": hour.value,
                            "dataInsights": {
                                "missingData": missingData,
                                "noData": noData,
                            }
                        });
                    }
                }
                var data = this.rawData.getDataForSelectedChart();
                vm.data = data;
                vm.isDataAvailable = data.length > 0 ? true : false;
                if (vm.isDataAvailable) {
                    let result = data.filter(d => d.value !== 0);
                    result.length > 0 ? vm.isDataAvailable = true : vm.isDataAvailable = false;
                }
                // vm.baselineOptions = {

                // }

                vm.baselineOptions.baselineDateValue = vm.isDaily ? vm.dataResponse.baselineDateAvg.dailyBaselineValue : vm.dataResponse.baselineDateAvg.hourlyBaselineValue,
                    vm.baselineOptions.baselineAverageValue = vm.isDaily ? vm.dataResponse.baselineAvg.dailyBaselineValue : vm.dataResponse.baselineAvg.hourlyBaselineValue
                //call our api update function which update chart data
                if(vm.isSquareRoot) vm.selectedLegend = vm.signalTrendsService.legendTypes.scaleSequentialSqrt;

                if (vm.isDataAvailable && vm.isDaily != undefined) vm.calendarHeatmap.api.update(vm.data, vm.getDaysOfWeekNumber(), vm.chartType, vm.chartText, vm.legendTitle,
                    vm.roundNumbers, vm.chartWidths, vm.chartTitle, vm.isSquareRoot, vm.reverseColorScale, vm.chartSubheader, vm.baselineOptions, vm.selectedLegend
                );
            },
            onApiInit: function (apiObj) {
                this.api = apiObj;
                if (vm.dataResponse)
                    this.update();
            },
        };
        vm.summaryCharts = {
            total: {
                api: {},
                update: function () {
                    if (!this.api || !this.api.render)
                        return;

                    var filterOptions = vm.signalTrendsService.getOptions();
                    var options = vm.searchBarService.getSearchOptions();
                    var differenceInTime = options.timeOptions.currentFilter.endDateAndTime.getTime() - options.timeOptions.currentFilter.startDateAndTime.getTime();
                    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
                    if (differenceInDays <= 1) {
                        vm.selectedChartType = 1
                        vm.calendarHeatmap.update();
                        return;
                    }
                    var dataBins = vm.dataResponse.dailyBins;//vm.isDaily ? vm.dataResponse.dailyBins : vm.dataResponse.hourlyBins;
                    //lets update our datasets if we need to
                    if (this.datasets[0].data.length <= 0) {
                        //update our daily dataset
                        for (let i = 0; i < dataBins.length; i++) {
                            this.datasets[0].data.push({
                                x: new Date(dataBins[i].start),
                                y: dataBins[i].value
                            });
                        }
                    }
                    //update our weekly dataset
                    if (this.datasets[1].data.length <= 0) {
                        for (let k = 0; k < vm.dataResponse.weeklyData.length; k++) {
                            vm.summaryCharts.total.datasets[1].data.push({
                                x: new Date(vm.dataResponse.weeklyData[k].start),
                                y: vm.dataResponse.weeklyData[k].value
                            });
                        }
                    }

                    //extend the weekly average line (summaryCharts.total.dataset[1]) to match the length of each bin in dataset[0]
                    var lastBin = this.datasets[0].data[this.datasets[0].data.length - 1];
                    var lastWeeklyAvgDatapoint = vm.summaryCharts.total.datasets[1].data[vm.summaryCharts.total.datasets[1].data.length - 1];
                    if (lastWeeklyAvgDatapoint && lastBin && lastWeeklyAvgDatapoint.x < lastBin.x) {
                        vm.summaryCharts.total.datasets[1].data.push({
                            x: new Date(lastBin.x),
                            y: lastWeeklyAvgDatapoint.y
                        });
                    }
                    vm.summaryCharts.options.annotation.annotations[1].value = null;
                    if ((vm.dataResponse.baselineDateAvg.dailyBaselineValue || vm.dataResponse.baselineDateAvg.hourlyBaselineValue) && vm.chartText == 2) {
                        vm.xAdjust = -100;
                        vm.summaryCharts.options.annotation.annotations[1].value = vm.isDaily ? vm.dataResponse.baselineDateAvg.dailyBaselineValue : vm.dataResponse.baselineDateAvg.hourlyBaselineValue;
                    }
                    vm.summaryCharts.options.annotation.annotations[0].value = vm.dataResponse.baselineAvg.dailyBaselineValue;


                    //call our api render function which update chart data
                    if (this.datasets.length > 0) {
                        vm.isDataAvailable = true;
                    }
                    this.api.render(this.labels, this.datasets, vm.summaryCharts.options);
                },
                onApiInit: function (apiObj) {
                    this.api = apiObj;
                    vm.summaryCharts.options.title.text = "Trends";
                    if (vm.dataResponse)
                        this.update();
                },
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: undefined,
                //array holder the data values
                datasets: [
                    //daily
                    {
                        data: [],
                        label: 'Daily Total',
                        // backgroundColor: 'rgba(55,126,184,.75)',
                        pointRadius: 0,
                        borderWidth: 2,
                        fill: true,
                    },
                    //weekly
                    {
                        label: 'Weekly Average',
                        data: [],
                        pointRadius: 0,
                        borderWidth: 4,
                        backgroundColor: 'rgba(55,126,184,.75)',
                        borderColor: 'rgba(55,126,184,.75)',
                        fill: false,
                    },
                ],
            },
            options: {
                hover: {
                    intersect: false
                },
                title: {
                    display: true,
                    text: 'Total'
                },
                legend: {
                    display: true,
                },
                annotation: {
                    annotations: [{
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        y: 150,
                        borderColor: 'rgb(255, 112, 67)',
                        borderWidth: 2,
                        borderDash: [10, 5],
                        label: {
                            enabled: true,
                            content: 'Baseline'
                        }
                    },
                    {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        borderColor: 'rgb(153, 51, 255)',
                        borderWidth: 2,
                        borderDash: [10, 5],
                        label: {
                            enabled: true,
                            position: "right",
                            xAdjust: 200,
                            content: 'Baseline Date'
                        }
                    }]
                },

                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'series',
                        display: true, //this will remove all the x-axis grid lines,
                        ticks: {
                            maxTicksLimit: 6
                        }
                    }],
                }
            },
        };
        vm.dowBreakdownChart = {
            api: {},
            rawData: {
                changeByDow: [],
                labels: [],
                countByDow: [],
                changeByDay: [],
                baseLine: [],
                baseLineDate: [],
                clearData: function () {
                    this.changeByDow = [];
                    this.labels = [];
                    this.countByDow = [];
                    this.changeByDay = [];
                    this.baseLine = [];
                    this.baseLineDate = [];
                },
                getDataForSelectedChart: function () {
                    var res = [];
                    var filterOptions = vm.signalTrendsService.getOptions();
                    switch (filterOptions.filterState) {
                        case vm.trendsConstants.Filters.counts:
                            res = this.countByDow;
                            break;
                        case vm.trendsConstants.Filters.dailyChange:
                            res = this.changeByDay;
                            break;
                        case vm.trendsConstants.Filters.dowChange:
                            res = this.changeByDow;
                            break;
                        case vm.trendsConstants.Filters.baselineAverage:
                            res = this.baseLine;
                            break;
                        case vm.trendsConstants.Filters.baselineDate:
                            res = this.baseLineDate;
                            break;
                    }
                    return res;
                },
                containsData: function () {
                    var res = false;
                    var data = this.getDataForSelectedChart();
                    vm.data = data;
                    if (data.length > 0)
                        res = true;

                    return res;
                }
            },
            update: function () {
                if (!this.api || !this.api.render)
                    return;


                //get data if we do not already have it
                if (!this.rawData.containsData()) {
                    for (let j = 0; j < vm.dataResponse.dayOfWeekData.length; j++) {
                        var day = vm.dataResponse.dayOfWeekData[j];
                        this.rawData.labels.push(vm.weekday[day.dayOfWeek]);
                        this.rawData.changeByDow.push(day.averageDayOfWeekChangePercentage);
                        this.rawData.baseLine.push(day.baselinePercentage);
                        this.rawData.baseLineDate.push(day.baselineDatePercentage);
                        this.rawData.countByDow.push(day.value.toFixed(2));
                        this.rawData.changeByDay.push(day.averageChangePercentage.toFixed(2));
                    }
                    vm.isDataAvailable = true;
                }
                this.datasets[0].data = this.rawData.getDataForSelectedChart();
                //call our api render function which update chart data
                if (vm.chartType == vm.trendsConstants.ChartTypes.changePercentage) {
                    var percentageDataset = [];
                    for (let i = 0; i < this.datasets[0].data.length; i++) {
                        percentageDataset.push(this.datasets[0].data[i] * 100)
                    }
                    this.datasets[0].data = percentageDataset;
                }
                this.api.render(this.rawData.labels, this.datasets, this.options);
            },
            // These labels appear in the legend and in the tooltips when hovering different arcs
            //array holder the data values
            datasets: [
                {
                    data: [],
                    backgroundColor: Chart.defaults.global.colors
                }
            ],
            options: {
                title: {
                    display: true,
                    text: vm.isAverage ? 'Breakdown by Day of Week (average per day)' : 'Breakdown by Day of Week'
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
                            var filterOptions = vm.signalTrendsService.getOptions();
                            if (label) {
                                if (vm.chartType == vm.trendsConstants.ChartTypes.changePercentage) {
                                    label = Math.round(label) + "%";
                                }
                                else {
                                    label = Math.round(label);
                                }
                            }
                            return label.toLocaleString();
                        }
                    }
                }
            },
            onApiInit: function (apiObj) {
                this.api = apiObj;
                if (vm.dataResponse)
                    this.update();
            },
            onDestroy: function () {

            }
        }

        function openMenu($mdMenu, ev) {
            $mdMenu.open(ev);
        }

        function expandHourlyDaily() {
            if (vm.isDaily) {
                vm.isDaily = false;
            }
            else {
                vm.isDaily = true;
            }
        }

        function expand() {
            // check if the selected chart is heatmap
            var closing = false;
            if (vm.widthClass == "width-1500") {
                vm.widthClass = "width-750";
                vm.paddingLeft = "pl-200";
                vm.chartWidths = 750;
                closing = true;
            }
            else if (vm.widthClass == "width-750") {
                vm.widthClass = "width-1500";
                vm.paddingLeft = "pl-470";
                vm.chartWidths = 1500;
            }
            if (closing) {
                //add 500ms timeout to account for animation effect
                $timeout(function () {
                    if (vm.selectedChartType == 1 && vm.calendarHeatmap.api && vm.calendarHeatmap.api.update)
                        vm.calendarHeatmap.api.update(vm.data, vm.getDaysOfWeekNumber(), vm.chartType, vm.chartText, vm.legendTitle,
                            vm.roundNumbers, vm.chartWidths, vm.chartTitle, vm.isSquareRoot, vm.reverseColorScale,
                            vm.chartSubheader,
                            vm.baselineOptions, vm.selectedLegend);

                }, 500);
                vm.isExpanded = false;
            }
            else {
                //resize the charts. Can't be done with css has to be changed in the options object referenced by chart
                if (vm.selectedChartType == 1 && vm.calendarHeatmap.api && vm.calendarHeatmap.api.update)
                    vm.calendarHeatmap.api.update(vm.data, vm.getDaysOfWeekNumber(), vm.chartType, vm.chartText, vm.legendTitle,
                        vm.roundNumbers, vm.chartWidths, vm.chartTitle, vm.isSquareRoot, vm.reverseColorScale, vm.chartSubheader,
                        vm.baselineOptions, vm.selectedLegend);
                vm.isExpanded = true;
            }
        }

        function changeLegend(legendType) {

            vm.selectedLegend = legendType;
            //add 500ms timeout to account for animation effect
            $timeout(function () {
                if (vm.selectedChartType == 1 && vm.calendarHeatmap.api && vm.calendarHeatmap.api.update)
                    vm.calendarHeatmap.api.update(vm.data, vm.getDaysOfWeekNumber(), vm.chartType, vm.chartText, vm.legendTitle,
                        vm.roundNumbers, vm.chartWidths, vm.chartTitle, vm.isSquareRoot, vm.reverseColorScale,
                        vm.chartSubheader,
                        vm.baselineOptions, vm.selectedLegend);

            }, 500);

        }

        function setLegendAndChartType() {
            var filterOptions = vm.signalTrendsService.getOptions();
            switch (filterOptions.filterState) {
                case vm.trendsConstants.Filters.counts:
                    vm.legendTitle = $scope.legendTitle;
                    // title += " Counts";
                    vm.chartType = vm.trendsConstants.ChartTypes.count;
                    vm.chartText = vm.trendsConstants.Filters.counts;
                    break;
                case vm.trendsConstants.Filters.dailyChange:
                    vm.legendTitle = "Daily percent Change";
                    // title += " Daily Change";
                    vm.chartType = vm.trendsConstants.ChartTypes.changePercentage;
                    vm.chartText = vm.trendsConstants.Filters.dailyChange;
                    break;
                case vm.trendsConstants.Filters.dowChange:
                    vm.legendTitle = "DOW Change";
                    // title += " DOW Change";
                    vm.chartType = vm.trendsConstants.ChartTypes.changePercentage;
                    vm.chartText = vm.trendsConstants.Filters.dowChange;
                    break;
                case vm.trendsConstants.Filters.baselineDate:
                    vm.legendTitle = "Baseline Date Change";
                    // title += " Baseline Change";
                    vm.chartType = vm.trendsConstants.ChartTypes.changePercentage;
                    vm.chartText = vm.trendsConstants.Filters.baselineDate;
                    break;
                case vm.trendsConstants.Filters.baselineAverage:
                    vm.legendTitle = "Daily Baseline Average Change";
                    // title += " Baseline Change";
                    vm.chartType = vm.trendsConstants.ChartTypes.changePercentage;
                    vm.chartText = vm.trendsConstants.Filters.baselineAverage;
                    break;

            }
        }

        function update(data) {
            if (vm) {
                vm.showHourlyOrDaily = vm.setHourlyOrDaily();
                vm.setLegendAndChartType();
                //here we will save our data response from server. 
                //Each 'chart' object contains it's own update logic. 
                //when user switches chart it will generate dataset if needed from vm.dataResponse
                vm.dataResponse = data;
                vm.chartSubheader = {
                    total: vm.dataResponse.total,
                    average: vm.dataResponse.average,
                    showTotal: vm.showTotal
                }
                //clear out our existing dataset arrays so update logic will replenish
                vm.summaryCharts.total.datasets[0].data = [];
                vm.summaryCharts.total.datasets[1].data = [];
                vm.dowBreakdownChart.rawData.clearData();
                vm.calendarHeatmap.rawData.clearData();

                //depending on currently viewing chart, trigger update function
                vm.updateCurrentChart();
                //implement this later
                // vm.getSelectedApproaches(vm.signal.selectedApproaches);
            }
        }

        function changeChart(type) {
            vm.selectedChartType = type;
            if (vm.selectedChartType == 1)
                vm.calendarHeatmap.update();
        }

        function setHourlyOrDaily() {
            var res = false;
            var options = vm.searchBarService.getSearchOptions();
            vm.diffInDays = null;
            // var roundedDates = vm.searchBarService.roundTimeToBin(!vm.searchBarService.getSearchBarConfig().moreOptionsMenu.skipStepsPerBin, options.timeOptions.bin, options.timeOptions.currentFilter.startDateAndTime, options.timeOptions.currentFilter.endDateAndTime);
            // options.timeOptions.currentFilter.startDateAndTime = new Date(roundedDates.start);
            // options.timeOptions.currentFilter.endDateAndTime = new Date(roundedDates.end);

            var differenceInTime = options.timeOptions.currentFilter.endDateAndTime.getTime() - options.timeOptions.currentFilter.startDateAndTime.getTime();
            var differenceInDays = differenceInTime / (1000 * 3600 * 24);
            vm.diffInDays = differenceInDays;
            if (differenceInDays <= 1) {
                vm.showSummaryChart = false;
            } else {
                vm.showSummaryChart = true;
            }

            if (differenceInDays <= 7) {
                vm.summaryCharts.total.datasets[1].label = 'Average'
            } else {
                vm.summaryCharts.total.datasets[1].label = 'Weekly Average'
            }

            if (differenceInDays < 62) {
                vm.showDowPercentage = false;
                signalTrendsService.setDowPercentage(vm.showDowPercentage);
                options.timeOptions.bin = "Hourly"; 
            } else {
                vm.showDowPercentage = true;
                signalTrendsService.setDowPercentage(vm.showDowPercentage);
                options.timeOptions.bin = "Daily"; 
            }

            // Updating the bin size to match Hourly/Daily for the timepicker selection
            vm.searchBarService.setTimeOptionsNoNotify(options.timeOptions);

            if (differenceInDays > 32 && differenceInDays < 62) {
                res = true;
            }
            else {
                res = false
                if (differenceInDays < 32) {
                    vm.isDaily = false;
                }
                if (differenceInDays > 62) {
                    vm.isDaily = true;
                }
            }

            if ((vm.signalTrendsService.getOptions().filterState == 5 || vm.signalTrendsService.getOptions().filterState == 2) && differenceInDays < 62) {
                //if daily data is currently selected and filter is base line date than do not change filter state otherwise change filter state to counts                 
                if (res && vm.isDaily && vm.signalTrendsService.getOptions().filterState == 2) {
                    vm.signalTrendsService.getOptions().filterState = 2;
                }
                else {
                    vm.signalTrendsService.getOptions().filterState = 3;
                }

            }

            return res;
        }

        function updateCurrentChart() {
            if (vm.diffInDays <= 1 && vm.signalTrendsService.getOptions().filterState == 4) {
                vm.selectedChartType = 1;
                vm.showDowChart = false;
            } else {
                vm.showDowChart = true;
            }

            // if time period is between 32 and 62 days and filter is based date than show only daily data and hide hourly button
            if (vm.diffInDays > 32 && vm.diffInDays < 62 && vm.signalTrendsService.getOptions().filterState == 2) {
                vm.isDaily = true;
            }

            switch (vm.selectedChartType) {
                //heatmap calendar
                case 1:
                    vm.calendarHeatmap.update();
                    break;
                //dow breakdown
                case 2:
                    vm.dowBreakdownChart.update();
                    break;
                //trend lines
                case 3:
                    vm.summaryCharts.total.update();
                    break;
            }
        }

        function getSelectedApproaches(approaches) {
            let result = "";
            vm.approachesDescriptions = "";
            if (approaches.length > 0) {
                if (approaches.length == vm.signal.approaches.length) {
                    result = "All approaches are selected";
                }
                else {
                    approaches.forEach(function (app, i) {
                        if (i == 0) result += app.description;
                        else result += ", " + app.description;
                    })
                }
            }
            vm.approachesDescriptions = result;
            return vm.approachesDescriptions;
        }


        function getDaysOfWeekNumber() {
            let res = [];
            var options = vm.searchBarService.getSearchOptions();
            
            for (let i = 0; i < options.timeOptions.daysOfWeekList.length; i++) {
                switch (options.timeOptions.daysOfWeekList[i]) {
                    case "Monday": res.push(0);
                        break;
                    case "Tuesday": res.push(1);
                        break;
                    case "Wednesday": res.push(2);
                        break;
                    case "Thursday": res.push(3);
                        break;
                    case "Friday": res.push(4);
                        break;
                    case "Saturday": res.push(5);
                        break;
                    case "Sunday": res.push(6);
                        break;
                }
            }
            res.sort(function (a, b) {
                return a - b;
            })
            return res;
        }

        function getApproachIds(approaches) {
            vm.selectedIdsApproaches = [];
            if (approaches == undefined) {
                vm.signal.approaches.forEach(appr => {
                    vm.selectedIdsApproaches.push(appr.approachID);
                });
            }
            else {
                approaches.forEach(appr => {
                    vm.selectedIdsApproaches.push(appr.approachID);
                });
            }
            return vm.selectedIdsApproaches;
        }

        function showHelpAlert(metricType, ev) {
            helpService.showHelpDialog("SIGNAL_TRENDS_CARD." + metricType.toString(), ev, "How We Calculate It");
        }

        $scope.$on("$destroy", function () {
            if (vm.cancelIntervalInsights)
                $interval.cancel(vm.cancelIntervalInsights);
            for (let member in vm) {
                vm[member] = null;
            }
            vm.cardWidget = null;
            vm = null;
            if ($scope.onApiDestroy) {
                $scope.onApiDestroy();
            }
            //if (cleanupQuery)
            //    cleanupQuery();
        });

    }
}());
