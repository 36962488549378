﻿import * as CUSTOMEVENTWIDGET from 'raw-loader!./custom-event-widget.html'

(function () {
    'use strict';

    angular.module('app.spm.charts.signal')
    .directive('customEventChart', customEventChartDirective);

    /** @ngInject */
    function customEventChartDirective($window) {
        function link(scope, element, attrs) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'customEventChartController as vm',
            template: function (elem, attrs) {
                 return CUSTOMEVENTWIDGET;
            },
            scope: {
                loading: '=',
                eventsWithParameters: '<',
                signals: '<',
                fetchData: '<',
                signalName: '<',
                approach: '<',
                searchDates: '<',
                chartType: '@',
                legendItems: '=',
                api: '=',
                onApiInit: '&',
                newChartData: '<'
            },
            link: function (scope, element, attrs) {
                if (!scope.api) {
                    scope.api = {};
                }

                angular.extend(scope.api, {
                    update: function (signalIds, approach, dates) {
                        scope.update(signalIds, approach, dates);
                    }
                });
                if (scope.onApiInit) {
                    scope.onApiInit({ apiObj: scope.api });
                }
            }
        };
    }
})();