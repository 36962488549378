(function ()
{
    'use strict';

    angular
        .module('app.pages', [,
        ])
        .config(config);

    /** @ngInject */
    function config(msNavigationServiceProvider)
    {
    }
})();