import * as LOGINV2 from 'raw-loader!./login-v2.html'

(function () {
    'use strict';

    angular
        .module('traffop.login', ['traffop.forgot_password', 'traffop.callback'])
        .config(config)

    /** @ngInject */
    function config($stateProvider, $translateProvider, $translatePartialLoaderProvider, $urlRouterProvider, $locationProvider) {
        // State
        $stateProvider.state('login', {
            url: '/login',
            views: {
                'main@': {
                    template: LOGINV2,
                    controller: 'LoginV2Controller as vm'
                }
            },
            bodyClass: 'login-v2'
        });
        // Translation
        $translatePartialLoaderProvider.addPart('login-v2');
    }
})();

