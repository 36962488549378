﻿(function () {
    'use strict';

    angular.module('app.spm.core')
        .directive('chartWidgetContextMenu', chartWidgetContextMenu);

    /** @ngInject */
    function chartWidgetContextMenu($mdMenu) {
        function link(scope) {
        }

        return {
            restrict: 'AE',
            scope: {
                metricType: '<',
                enabled: '<'
            },
            link: link,
            controller: 'chartWidgetContextMenuController as vm',
            templateUrl: function (elem, attrs) {
                return 'assets/templates/chart-widget-context-menu.html';
            },
        };
    }
})();