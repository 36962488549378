(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.insights-map')
        .controller('InsightsMapDashboardController', InsightsMapDashboardController);

    /** @ngInject */
    function InsightsMapDashboardController($scope, $interval, $element, $mdSidenav, searchBarService) {
        var vm = this;
        // vm.updateMap = updateMap;

    }
})();