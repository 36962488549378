﻿(function () {
    "use strict";

    angular
        .module("app.spm.dashboards.waze-travel-time")
        .controller("wazeTravelTimeController", wazeTravelTimeController);

    function wazeTravelTimeController($state, $scope, searchBarService, corridorService) {
        var vm = this;
        vm.mapApi = {};
        vm.wazeRouteGeometries = undefined;
        vm.routes = [];
        vm.routeShapes = [];
        vm.loadingPromise = {};
        vm.options = {};
        vm.getTravelTimeInfo = getTravelTimeInfo;
        vm.formatSecondsString = formatSecondsString;
        vm.routeTotal = 0;
        vm.rgba2hex = rgba2hex;

        vm.routeColors = ['rgba(228,26,28,.75)', 'rgba(55,126,184,.75)', 'rgba(77,175,74,.75)', 'rgba(152,78,163,.75)',
            'rgba(255,127,0,.75)', 'rgba(255,255,51,.75)', 'rgba(166,86,40,.75)', 'rgba(247,129,191,.75)',
            'rgba(153,153,153,.75)'];

        $scope.$on("$destroy", function () {
            vm = null;
        })

        vm.onApiInit = function (apiObj) {
            vm.mapApi = apiObj;
            if (vm.mapApi && vm.mapApi.updateRouteLines && vm.routeShapes && vm.routeShapes.length > 0) {
                vm.mapApi.updateRouteLines(vm.routeShapes, []);
            }

        }
        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: true,
                text: "Waze Routes"
            },
            //search bar options
            showSearchBar: true,
            searchType: 'NoSearch',
            showCurrentFilterDates: true,
            timeFrameConfig: {
                enableWithoutFiltering: false,
                defaultDateTemplate: "TD",
                defaultTodTemplate: "FD",
                dateTemplateMinimumDays: 0,
                timeOptionForCustomTemplate: "StartToEnd",
                showWeekdayFilter: false,
                maxDayRange: 7,
            },
            //right-side more options menu
            moreOptionsMenu: {
                show: false,
                showBinConfig: false,
                skipStepsPerBin: true,
            }
        });
        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm)
                return;

            switch (changeType) {
                case "time":
                    vm.options = vm.searchBarService.getSearchOptions();
                    vm.getTravelTimeInfo();
                    break;
                case "configuration":
                case "state":
                    break;
            }
        });
        vm.options = vm.searchBarService.getSearchOptions();

        vm.queryData = {
            orderBy: 'Id',
            pageSize: 10,
            pageIndex: 1,
            filter: '',
            decesendingOrder: true,
        };

        vm.bookMark = 0;
        vm.searchChange = function (newValue, oldValue) {
            if (!oldValue) {
                vm.bookmark = vm.queryData.pageIndex;
            }

            if (newValue !== oldValue) {
                vm.queryData.pageIndex = 1;
            }

            if (!newValue) {
                vm.queryData.pageIndex = vm.bookmark;
            }

            vm.getTravelTimeInfo();
        };

        function rgba2hex(rgba) {
            rgba = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
            return (rgba && rgba.length === 4) ? "#" +
                ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
                ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
                ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2) : '';
        }

        function formatSecondsString(time) {
            var res = "";
            time = time.toFixed(0);
            var minutes = Math.floor(time / 60);
            var seconds = time - minutes * 60;
            if (minutes > 0)
                res = minutes + "m " + seconds + "s";
            else
                res = seconds + "s";

            return res;
        }

        function getTravelTimeInfo() {
            vm.queryData.start = new Date(vm.options.timeOptions.currentFilter.startDateAndTime).toLocaleString();
            vm.queryData.end = new Date(vm.options.timeOptions.currentFilter.endDateAndTime).toLocaleString();
            vm.loadingPromise = corridorService.getWazeRouteTravelTimeInfo(vm.queryData)
                .then(function (data) {
                    vm.routeTotal = data.routeCount;
                    vm.routes = data.routes;
                    vm.routeShapes = [];
                    var colorIdx = 0;
                    vm.routes.forEach(rt => {
                        if (colorIdx >= vm.routeColors.length - 1)
                            colorIdx = 0;

                        vm.routeShapes.push({
                            name: rt.name,
                            id: rt.wazeId,
                            lineGeometry: rt.lineGeometry,
                            travelTimeString: vm.formatSecondsString(rt.averageCurrentTravelTimeSeconds),
                            color: vm.routeColors[colorIdx]
                        });
                        rt.color = vm.routeColors[colorIdx];
                        colorIdx++;
                    });
                    if (vm.mapApi && vm.mapApi.updateRouteLines) {
                        vm.mapApi.updateRouteLines(vm.routeShapes, []);
                    }
                });
        }
        vm.getTravelTimeInfo();
    }
}());
