﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts")
        .controller("comparisonChartController", comparisonChartController);

    function comparisonChartController($state, $attrs, $scope, $rootScope, $document, $timeout, $element, $interval, searchBarService, breadcrumbNavigationService, signalService, newWindowService) {
        //dataset param passed in needs to look like the following
        //[{
        //   name: 'Name of item (signal, corridor, etc)',
        //   total: number (total value of the dataset),
        //   values: [{
        //       x: (X values),
        //       y: (y values)
        //   }]
        //}]

        //overview card binding object needs to look like the following
        //[{
        //   title: title displayed in top left,
        //   total: the total value to display (in blue font),
        //   showTotal: shows the card
        //   showAverage: shows the card
        //   average: the average value to display
        //   valueLabel: display below value, (ie: seconds, vehicles)
        //}]

        var vm = this;
        $scope.setupDatasets = setupDatasets;
        vm.update = update;
        vm.callApiLoaded = callApiLoaded;
        vm.filterChange = filterChange;
        vm.filtersLoaded = filtersLoaded;
        vm.setAxisLabels = setAxisLabels;
        vm.api = {};
        vm.filters = $scope.filters;
        vm.onFilterChange = $scope.onFilterChange;
        vm.leftChartLabels = $scope.leftChartLabels;
        vm.rightChartLabels = $scope.rightChartLabels;
        vm.showHeatMap = $scope.showHeatMap;
        vm.showLineChart = true;
        vm.showBarChart = true;
        vm.overviewCard = $scope.overviewCard;
        vm.mapApi = {};
        vm.cancelIntervalMap = undefined;
        vm.header = $scope.header;
        vm.subHeader = $scope.subHeader;
        vm.forceDestroy = forceDestroy;
        vm.loadStateFromMenu = loadStateFromMenu;
        vm.showContextMenu = showContextMenu;
        vm.setContextmenuPosition = setContextmenuPosition;
        vm.closeContextMenu = closeContextMenu;
        vm.disableMenuItem = disableMenuItem;
        vm.horizontalData = undefined;
        vm.investigate = investigate;
        vm.newWindowService = newWindowService;
        vm.contextSignal = {};
        vm.contextClosed = true;
        vm.contextCounter = 0;
        vm.showSideMenu = showSideMenu;
        vm.colors = [
            "#55efc4",
            "#2d3436",
            "#81ecec",
            "#e84393",
            "#74b9ff",
            "#d63031",
            "#a29bfe",
            "#e17055",
            "#00b894",
            "#fdcb6e",
            "#0984e3",
            "#636e72",
            "#6c5ce7",
            "#fd79a8",

        ];
        vm.greyColor = '#b2bec3';

        $scope.$on("$destroy", function () {
            vm.showLineChart = false;
            vm.showBarChart = false;
            if (vm.cancelIntervalMap)
                $interval.cancel(vm.cancelIntervalMap);

            for (let member in vm) {
                vm[member] = null;
            }

            vm = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();

        });

        //setup the two chart objects
        vm.horizontalBarChart = {
            dataset: [],
            //chart specific options
            options: {
                mode: 'y',
                intersect: false,
                axis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                        },
                        ticks: {
                            callback: function (label, index, labels) {
                                var res = "";
                                if (label >= 1000000)
                                    res = label / 1000000 + 'm';
                                else if (label >= 1000)
                                    res = label / 1000 + 'k';
                                else
                                    res = label.toLocaleString();

                                return res;
                            }
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                        },
                        ticks: {
                            callback: function (label, index, labels) {
                                var res = "";
                                var index = null;

                                labels.forEach(function (tLabel, i) {
                                    if (tLabel == label) index = i;
                                });

                                res = label.toLocaleString();

                                return res;
                            },
                        }
                    }],
                },
                legend: {
                    display: false,
                },

                isFiltering: false,
                onHover: function (event, data) {
                    //get the line datasets
                    var lineDatasets = vm.lineChart.chart.config.data.datasets;
                    if (data.length) {
                        var current = data[0]._view.label;
                        this.isFiltering = true;
                        $element.find("#chartContainer")[0].style.cursor = 'pointer';
                        //if our line data doesn't match current hover then change it's color
                        lineDatasets.forEach(function (dataset) {
                            if (dataset.label != current) {
                                dataset.backgroundColor = vm.greyColor;
                                dataset.borderColor = vm.greyColor;
                                dataset.borderwidth = 1;
                            }
                            else {
                                //if we match, then increase width
                                dataset.borderWidth = 5;
                            }
                        });
                        vm.lineChart.chart.update();
                    }
                    else {
                        $element.find("#chartContainer")[0].style.cursor = 'default';

                        //nothing hovered so let's reset our line graph back to normal if we filtered
                        if (this.isFiltering) {
                            for (var i = 0; i < lineDatasets.length; i++) {
                                var dataset = lineDatasets[i];
                                dataset.backgroundColor = vm.colors[i];
                                dataset.borderColor = vm.colors[i];
                                dataset.borderWidth = 2;
                            };
                            vm.lineChart.chart.update();
                            this.isFiltering = false;
                        }
                    }

                },
                onClick: function (e, data) {
                    var lineDatasets = vm.lineChart.chart.config.data.datasets;
                    if (data.length || vm.horizontalData) {
                        var current = '';
                        if (data.length) {
                            current = data[0]._view.label;
                        } else {
                            current = vm.horizontalData;
                        }
                        lineDatasets.forEach(function (dataset) {
                            if (dataset.label == current) {
                                vm.customID = dataset.label;
                                const id = dataset.id;
                                vm.showContextMenu(e, id)
                            }
                        });
                    }
                },
                tooltips: {
                    mode: 'y',
                    intersect: false,
                    axis: 'y',
                    custom: function (tooltip) {
                        return;
                    },
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.xLabel.toLocaleString() !== '0' ? tooltipItem.xLabel.toLocaleString() : 'Has No Detection';
                        },
                        title: function (tooltipItem, data) {
                            var res = "";

                            var parts = tooltipItem[0].yLabel.split("-");
                            var id = parts[parts.length - 1].trim();
                            var foundLabel = vm.horizontalBarChart.fullLabels.find(function (e) {
                                if (e.id == id) {
                                    vm.horizontalData = tooltipItem[0].yLabel
                                }
                                return e.id == id
                            });
                            if (foundLabel)
                                res = foundLabel.name;
                            return res;
                        }
                    },
                    titleFontSize: 13,
                    bodyFontSize: 16
                }
            },
            chart: {},
            api: undefined,
            fullLabels: [],
            onApiInit: function (apiObj) {
                this.api = apiObj;
                vm.callApiLoaded();
            },
            onDestroy: function () {
                this.api = undefined;
                this.chart = {};
                this.dataset = {};
            }
        };
        vm.lineChart = {
            dataset: [],
            //chart specific options
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'day'
                        },
                        scaleLabel: {
                            display: true,
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,

                        },
                        ticks: {
                            callback: function (label, index, labels) {
                                var res = "";
                                if (label >= 1000000)
                                    res = label / 1000000 + 'm';
                                else if (label >= 1000)
                                    res = label / 1000 + 'k';
                                else
                                    res = Math.round(label).toLocaleString();

                                return res;
                            }
                        }
                    }]
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            //var label = data.datasets[tooltipItem.datasetIndex].label;
                            return tooltipItem.yLabel.toLocaleString();
                        },
                    },
                    titleFontSize: 13,
                    bodyFontSize: 16,
                    position: 'nearest',
                    xAlign: 'center',
                    _bodyAlign: 'center',
                    _footerAlign: 'center',
                },
                elements: {
                    point: {
                        radius: 0,
                        hoverRadius: 5
                    }
                },
                legend: {
                    display: true,
                    onHover: function (e) {
                        e.target.style.cursor = 'pointer';
                    }
                },
                hover: {
                    onHover: function (e) {
                        e.target.style.cursor = 'default';
                    }
                },
                lineTension: 0,
                animation: {
                    duration: 0
                },
            },
            chart: {},
            api: undefined,
            onApiInit: function (apiObj) {
                this.api = apiObj;
                vm.callApiLoaded();
            },
            onDestroy: function () {
                this.api = undefined;
                this.chart = {};
                this.dataset = {};
            }
        };

        function callApiLoaded() {
            if (vm.lineChart.api && vm.lineChart.api.render && vm.horizontalBarChart.api && vm.horizontalBarChart.api.render) {
                if (!vm.api.update) {
                    vm.api.update = vm.update;
                    vm.api.forceDestroy = vm.forceDestroy;
                    if ($scope.onApiInit) {
                        vm.filterChange({ description: "All Directions", id: -1 });
                        $scope.onApiInit({ apiObj: vm.api });
                    }
                }
            }
        }

        function loadStateFromMenu(e, state) {
            vm.isContextMenuPressed = true;
            var getSignalInterval = $interval(function () {
                if (vm.signal && vm.signal.customID && vm.signal.primaryName) {
                    $interval.cancel(getSignalInterval);

                    if ($scope.hideContextMenu) {
                        return;
                    }
                    e.stopPropagation();
                    if (vm.contextSignal) {
                        breadcrumbNavigationService.navigateToState(
                            state,
                            undefined,
                            vm.signal
                        );
                    } else {
                        breadcrumbNavigationService.navigateToState(state);
                    }
                }
            }, 500)
        }

        function forceDestroy() {
            $scope.$destroy();
        }

        //use api object to interact with chart from parent controller
        //don't use watchers, has significant performance degradation
        function update(dataset, heatMapData, inverseHeatMapColors) {
            if (vm.mapApi && vm.mapApi.updateHeatMap) {
                vm.mapApi.updateHeatMap(heatMapData, inverseHeatMapColors);
            }
            else {
                var cancelIntervalMap = $interval(function () {
                    if (vm) {
                        vm.cancelIntervalMap = cancelIntervalMap;
                        if (vm.mapApi && vm.mapApi.updateHeatMap) {

                            vm.mapApi.updateHeatMap(heatMapData, inverseHeatMapColors);
                            $interval.cancel(vm.cancelIntervalMap);
                        }
                    }
                    else {
                        $interval.cancel(cancelIntervalMap);
                    }

                }, 10);
            }

            $scope.setupDatasets(dataset);
        }

        function setAxisLabels() {
            vm.horizontalBarChart.options.scales.xAxes[0].scaleLabel.labelString = vm.leftChartLabels.xAxis;
            vm.horizontalBarChart.options.scales.xAxes[0].scaleLabel.display = true;
            vm.horizontalBarChart.options.scales.yAxes[0].scaleLabel.labelString = vm.leftChartLabels.yAxis;
            vm.horizontalBarChart.options.scales.yAxes[0].scaleLabel.display = true;

            //if time filtering is greater than 2 days, show daily X axis tick labels
            //if less than 2 days then show hourly tick labels
            if (searchBarService.calculateFilterTimeSpanInDays() > 3) {
                vm.lineChart.options.scales.xAxes[0].time.unit = "day";
                vm.lineChart.options.scales.xAxes[0].scaleLabel.labelString = "Date";
            }
            else {
                vm.lineChart.options.scales.xAxes[0].time.unit = "hour";
                vm.lineChart.options.scales.xAxes[0].scaleLabel.labelString = "Time";
            }

            vm.lineChart.options.scales.xAxes[0].scaleLabel.display = true;
            vm.lineChart.options.scales.yAxes[0].scaleLabel.labelString = vm.rightChartLabels.yAxis;
            vm.lineChart.options.scales.yAxes[0].scaleLabel.display = true;

        }

        function setupDatasets(datasets) {
            if (!datasets) {
                //show some empty state
                return;
            }

            vm.setAxisLabels();

            //clear old data
            var horizontalLabels = [];
            var horizontalDataset = [{
                showLines: false,
                data: [],
                backgroundColor: vm.greyColor,
                borderColor: vm.greyColor,
                hoverBackgroundColor: vm.colors,
                hoverborderColor: vm.colors,
            }];
            var linesDataset = [];
            //go through dataset passed in and manipulate into seperate datasets
            for (var i = 0; i < datasets.length; i++) {
                var item = datasets[i];
                horizontalLabels.push(item.name);
                horizontalDataset[0].data.push(item.total);
                //item.name could be in two different string formats. 
                //Either just the ID, ie: "1499" or with the prefix "EBT - 1499"
                //We should parse just to get id portion and only add label to our array if it doesn't exist
                var parts = item.name.split("-");
                var id = parts[parts.length - 1].trim();
                if (item.fullName && !vm.horizontalBarChart.fullLabels.find(function (e) { e.id == id })) {
                    vm.horizontalBarChart.fullLabels.push({ id: id, name: item.fullName });
                }
                //vm.lineChart.labels.push(item.name);
                linesDataset.push({
                    id: item.id,
                    label: item.name,
                    data: item.values,
                    fill: false,
                    borderColor: vm.colors[i],
                    backgroundColor: vm.colors[i],
                    borderWidth: 2
                });
            };

            vm.horizontalBarChart.chart = vm.horizontalBarChart.api.render(horizontalLabels, horizontalDataset, vm.horizontalBarChart.options);
            vm.lineChart.chart = vm.lineChart.api.render(undefined, linesDataset, vm.lineChart.options);
        }

        function filterChange(filter) {
            vm.selectedFilter = filter;
            if (vm.onFilterChange)
                vm.onFilterChange({ filter: filter });
        }

        function filtersLoaded() {
            if (!vm.selectedFilter)
                vm.selectedFilter = vm.filters[0];
        }

        function showSideMenu($mdMenu, ev) {
            var sideMenu = angular.element($document[0].getElementById('corridor-side-menu'));
            // var mapEl = $element.find(".gm-style");
            // var pushFromBottom = vm.bottomLeftContextMenu - 100;
            // if (vm.bottomLeftContextMenu + 303 > mapEl.height()) {
            //   if (vm.topLeftContextMenu - 300 < 50) {
            //     pushFromBottom = vm.bottomLeftContextMenu - 250;
            //   } else {
            //     pushFromBottom = vm.bottomLeftContextMenu - 330;
            //   }
            // }
            // if (vm.sideMenuLeftPosition + vm.contextMenuWidth + 65 + 327 > mapEl.width() + mapEl.scrollLeft()) {
            //   sideMenu.css({ left: vm.sideMenuLeftPosition - 265 + 'px', top: pushFromBottom + 'px' })
            //            } else {
            if (vm.contextMenuPositionY + 600 > $document.height()) {
                vm.contextMenuPositionY = $document.height() - 650;
            }
            sideMenu.css({ left: vm.contextMenuPositionX + vm.contextMenuDimension + 69 + 'px', top: vm.contextMenuPositionY + 'px' })
            //          }
            $timeout(function () {
                $mdMenu.open(ev);
            }, 500);
        }

        // $document.on('click', function (e, vm) {
        //     if (!vm.contextClosed) {
        //         vm.closeContextMenu();
        //     }
        //     return $document.off('click', e);
        // });

        angular.element($element.find("#chart-context-menu")).on('click', function (e) {
            if (!vm.contextClosed) {
                e.preventDefault();
            }
        });

        function getSignalDescription(){

            var res = vm.signal.customID + " - " + vm.signal.primaryName;
            if (vm.signal.secondaryName != null && vm.signal.secondaryName != '')
            {
                res += " " + vm.signal.secondaryName;
            }

            return res;
        }

        function showContextMenu(e, signalID) {
            if (!vm.contextClosed) {
                vm.closeContextMenu();
            }
            vm.contextClosed = true;
            var parts = signalID.split("-");
            var id = parts[parts.length - 1].trim();
            signalService.getSignalById(id).then(function (signal) {
                vm.signal = signal;
                vm.contextSignal.signal = signal
                vm.signal.description = getSignalDescription();
            })

            $scope.$apply();

            vm.contextClosed = false;

            $scope.$apply();
            var menu = angular.element($element.find("#chart-context-menu"));
            var position = vm.setContextmenuPosition(e, menu);

            // set the position of the ContextMenu and make it visible
            menu.css({ left: position.x, top: position.y });
            menu.addClass("corridor-dashboard-md-active");
            e.stopPropagation();
            // set visibilty to visible
        }

        function setContextmenuPosition(event, contextMenu) {
            var mousePosition = {};
            mousePosition.y = event.y - 300;
            vm.contextMenuPositionY = mousePosition.y;
            mousePosition.x = event.x - 100;
            var menuPosition = {};
            var menuDimension = {};
            menuDimension.x = contextMenu.outerWidth();
            vm.contextMenuDimension = menuDimension.x;
            //boolean to signal if we are opening menu higher than mouse position or lower

            menuPosition.x = mousePosition.x;
            vm.contextMenuPositionX = menuPosition.x;
            menuPosition.y = mousePosition.y;
            if (menuPosition.y + 500 > $document.height()) {
                menuPosition.y = menuPosition.y - 150;
            }
            return menuPosition;
        }

        function closeContextMenu() {
            if (!vm.contextClosed) {
                var menu = angular.element($element.find("#chart-context-menu"));
                menu.removeClass("corridor-dashboard-md-active");
                vm.contextClosed = true;
            }
        }


        function investigate(metricType, newWindow) {
            if (!metricType || !vm.signal) return;
            if (metricType == 'Signal Overview') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-overview', undefined, vm.signal);
            if (metricType == 'Trends Dashboard') vm.newWindowService.openNewTabTimeAndSignal('app.spm.dashboards.signal-trends', undefined, vm.signal);
            if (metricType == 'Signal Events') vm.newWindowService.openNewTabTimeAndSignal('app.spm.signal-events', undefined, vm.signal);
            if (metricType == 'Split Monitor') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.split-monitor', undefined, vm.signal);
            if (metricType == 'Purdue Split Monitor') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.purdue-split-monitor', undefined, vm.signal);
            if (metricType == 'Phase Termination') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.phase-termination', undefined, vm.signal);
            if (metricType == 'Pedestrian Delay') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.ped-delay', undefined, vm.signal);
            if (metricType == 'Preemption Details') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.preempt-details', undefined, vm.signal);
            if (metricType == 'Turning Movement Counts') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.turning-movements', undefined, vm.signal);
            if (metricType == 'Purdue Coordination') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.purdue-coord-diagram', undefined, vm.signal);
            if (metricType == 'Approach Volume') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.approach-volume', undefined, vm.signal);
            if (metricType == 'Approach Delay') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.approach-delay', undefined, vm.signal);
            if (metricType == 'Arrivals on Red') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.aor', undefined, vm.signal);
            if (metricType == 'Red Light Runners') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.red-light-runners', undefined, vm.signal);
            if (metricType == 'Purdue Split Failure') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.split-failure', undefined, vm.signal);
            if (metricType == 'Queue Length') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.queue-length', undefined, vm.signal);
            if (metricType == 'Detector Counts') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.detector-counts', undefined, vm.signal);
            if (metricType == 'Detector Durations') vm.newWindowService.openNewTabTimeAndSignal('app.spm.charts.detector-durations', undefined, vm.signal);
        };

        function disableMenuItem(menuItem) {
            if (vm.contextClosed == false && vm.signal) {
                let availableCharts = vm.signal.availableCharts.map(function (a) {
                    return a.chartName;
                });
                return availableCharts.indexOf(menuItem) <= -1;
            }
            return;
        }
    }
}());
