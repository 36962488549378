import * as SIDEPANEL from 'raw-loader!./insights-sidepanel.html'


(function () {
    'use strict';

    angular.module('app.spm.core')
        .directive('insightsSidepanel', insightsSidepanelDirective);


    function insightsSidepanelDirective() {
        return {
            restrict: 'AE',
            bindToController: {
                api: '=',
            },
            scope: {
                param: '=',
                hidePins: '<',
            },
            template: SIDEPANEL,
            controller: 'insightsRightMenuController as vm'
        }
    }
})();
