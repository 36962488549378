(function () {
    "use strict";

    var app = angular
        .module("app.spm.dashboards.insights")
        .service("insightsService", insightsService);

    app.constant('InsightConstants', {
        Filters: {
            State: {
                new: 1,
                acknowledged: 2,
                ignored: 3,
                all: 4,
            },
            Priority: {
                all: -1,
                low: 0,
                high: 1,
            }
        }
    });

    function insightsService(insightsResource, $state, $rootScope, $interval, $mdSidenav, InsightConstants, $mdToast) {
        var vm = this;
        vm.options = {
            filtering: {
                state: InsightConstants.Filters.State.new,
                priority: InsightConstants.Filters.Priority.all
            }
        };

        var insights = new Map();

        function setInsights(ins, success) {
            insights = ins;
            if (success === true) notify("InsightsLoaded")
            if (success === false) notify("InsightsFailed")
        }

        function getInsights(key) {
            return insights.get(key);
        }

        function setInsightsSidemenu() {
            var investigateInsightsParams;

            // check if user is investigating from insights
            if (sessionStorage && sessionStorage.__investigating_from_insights) {
                investigateInsightsParams = JSON.parse(sessionStorage.__investigating_from_insights);
            }

            if (investigateInsightsParams && investigateInsightsParams.navigatingFromInsights) {

                // wait for the insights side-menu to load
                var cancelIntervalInsightsButton = $interval(function () {
                    var insightsMenu = document.getElementById('insights-sidepanel');

                    if (insightsMenu) {
                        // open it
                        $mdSidenav('insights-sidepanel').toggle();

                        // set item in session storage
                        var investigatingFromInsights = {
                            navigatingFromInsights: true,
                            categoryToInvestigate: investigateInsightsParams.categoryToInvestigate
                        }
                        sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));
                        $interval.cancel(cancelIntervalInsightsButton);
                    }
                }, 200);
            }
        }

        function getInsightsDefintions() {
            return insightsResource.getDefinitions(
                function (data) {
                    return data;
                }).$promise;
        }

        function getInsightQueryResults() {
            return insightsResource.getInsightQueryResults(
                function (data) {
                    return data;
                }).$promise;
        }

        function getAllSignalStates(queryData) {
            return insightsResource.getAllSignalStates(queryData, function (data) {
                return data;
            }).$promise;
        }

        function runInsightQuery(queryData) {
            return insightsResource.runInsightQuery(queryData, function (data) {
                return data;
            }).$promise;
        }


        function getSystemInsights(queryData) {
            return insightsResource.getSystemInsights(queryData, function (data) {
                return data;
            }).$promise;
        }


        function getSystemInsightsByCategory(queryData) {
            return insightsResource.getSystemInsightsByCategory(queryData, function (data) {
                return data;
            }).$promise;
        }

        function getSystemInsightsBySignal(queryData) {
            return insightsResource.getSystemInsightsBySignal(queryData, function (data) {
                return data;
            }).$promise;
        }

        function deleteInsightQuery(dataToDelete) {
            return insightsResource.deleteInsightQuery({ query: dataToDelete },
                function (data) {
                    if (data) {
                        showToast('Insight query deleted successfully!');
                    }
                    else {
                        showToast('Error deleting insight query!');
                    }
                    return data;
                }).$promise;
        }

        function setAcknowledgeItem(acknowledgeItem) {
            return insightsResource.setAcknowledge(acknowledgeItem,
                function (data) {
                    showToast('Acknowledge saved successfully');
                    $rootScope.$broadcast('insight-acknowledged');
                    return data;
                }).$promise;
        }

        function updateAcknowledgeItem(acknowledgeItem) {
            return insightsResource.updateAcknowledge(acknowledgeItem,
                function (data) {
                    showToast('Acknowledge updated successfully');
                    return data;
                }).$promise;
        }

        function removeAcknowledgeItem(acknowledgeItem) {
            return insightsResource.removeAcknowledge(acknowledgeItem,
                function (data) {
                    showToast('Acknowledge removed successfully');
                    $rootScope.$broadcast('insight-unackowledged');
                    return data;
                }).$promise;
        }

        function setIgnoreItem(acknowledgeItem) {
            return insightsResource.setIgnore(acknowledgeItem,
                function (data) {
                    showToast('Insight ignored successfully');
                    $rootScope.$broadcast('insight-ignored');
                    return data;
                }).$promise;
        }

        function setUnignoreItem(acknowledgeItem) {
            return insightsResource.setUnignore(acknowledgeItem,
                function (data) {
                    showToast('Insight unignored successfully');
                    return data;
                }).$promise;
        }


        function showToast(text) {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent(text)
                    .position("top right")
                    .hideDelay(3000)
            );
        }

        function notifyChange(optionName, optionValue) {
            $rootScope.$emit('insights-options-change-event', { name: optionName, value: optionValue });
        }

        function notify(typeOfChange) {
            if (!sessionStorage.getItem('__change_notifications_paused')) {
                $rootScope.$emit('insights-options-change-event', typeOfChange);
            }
        }


        function getOptions() {
            return vm.options;
        }
        function setOptions(options) {
            return vm.options = options;
        }

        return {
            getInsightsDefintions: getInsightsDefintions,
            getAllSignalStates: getAllSignalStates,
            getSystemInsights: getSystemInsights,
            getSystemInsightsByCategory: getSystemInsightsByCategory,
            setAcknowledgeItem: setAcknowledgeItem,
            updateAcknowledgeItem: updateAcknowledgeItem,
            removeAcknowledgeItem: removeAcknowledgeItem,
            setIgnoreItem: setIgnoreItem,
            setUnignoreItem: setUnignoreItem,
            notifyChange: notifyChange,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('insights-options-change-event', callback);
                scope.$on('$destroy', handler);
            },
            getOptions: getOptions,
            setOptions: setOptions,
            runInsightQuery: runInsightQuery,
            getInsightQueryResults: getInsightQueryResults,
            deleteInsightQuery: deleteInsightQuery,
            getSystemInsightsBySignal: getSystemInsightsBySignal,
            setInsights: setInsights,
            getInsights: getInsights,
            setInsightsSidemenu: setInsightsSidemenu,
        }
    }
})();
