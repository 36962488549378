﻿(function ()
{
    'use strict';
    angular
        .module('app.spm.dashboards.map-overview')
        .directive('mapOverview', function () {
            return {
                restrict: 'AE',
                bindToController: {
                    api: '=',
                },
                scope: {
                    param: '=',
                },
                template: `<div id="dashboard-system-map" class="page-layout simple right-sidenav" layout="row"
                style="overflow:hidden !important;">
            
                <!-- CENTER -->
                <div class="center" flex style="overflow:hidden !important;">
                    <!-- CONTENT -->
                    <div flex layout="row" layout-fill>
                        <div layout="column" flex layout-fill id="map-content-div">
                            <google-map style="width :100%; height: 100%;" show-only-signal-id="false" is-session-storage="true"
                                zoom-restriction="false" show-fullscreen-street="true" disable-zoom-control="false" use-clustering="true"
                                rotate-controls="true" show-all-signals="true" show-traffic-button="true" full-screen="true"
                                draggable-map="true"></google-map>
                        </div>
                    </div>
                    </md-tabs>
                </div>
            </div>`,
                controller: 'InsightsMapDashboardController as vm'
            }
        })
        .controller('DashboardMapController', DashboardMapController);

    /** @ngInject */
    function DashboardMapController($scope, $interval, $mdSidenav, searchBarService)
    {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        vm.loading = true;
        vm.camelCaseToRegularForm = camelCaseToRegularForm;
        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = vm.searchBarService.getSearchBarConfig();
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: true,
                text: "Map Overview",
            },
            //search bar options
            showSearchBar: true,
            searchType: 'Signals',
            showCurrentFilterDates: false,
            timeFrameConfig: vm.searchBarConfig.timeFrameConfig,
            //right-side more options menu
            moreOptionsMenu: {
                show: false,
                showWeekdayFilter: false,
                showBinConfig: false,
                skipStepsPerBin: true,
            }
        });

        // Data
        //vm.dashboardData = DashboardData;
        //vm.performanceData = PerformanceData;
        // vm.operationRanges = [];
        // vm.operationData = {};

        // vm.totalSignals = 15;

        // vm.signalStatusWidget1 = {
        //     currentRange: {},
        // }; 
        // vm.signalStatusWidget2 = {
        //     currentRange: {},
        // }; 
        // vm.signalStatusWidget3 = {
        //     currentRange: {},
        // }; 
        // vm.signalStatusWidget4 = {
        //     currentRange: {},
        // }; 

        // function getSystemOverviewData() {
        //     var start = new Date();
        //     start.setHours(0, 0, 0, 0);

        //     var queryData = {
        //         startDate: new Date(start).toLocaleString(),
        //         endDate: new Date().toLocaleString(),
        //         signals: [],
        //         eventDetails: false
        //     };

        //     controllerEventService.getSystemOverviewData(queryData)
        //         .then(function (resp) {
        //             vm.totalSignals = resp.totalSignals;
        //             //Object.keys(resp.operationStatus).forEach(function (key) {
        //             //    vm.operationRanges.push(camelCaseToRegularForm(key));
        //             //});

        //             Object.keys(resp.operationStatus).forEach(function (key) {
        //                 var newKey = camelCaseToRegularForm(key);
        //                 Object.defineProperty(resp.operationStatus, newKey,
        //                     Object.getOwnPropertyDescriptor(resp.operationStatus, key));
        //                 delete resp.operationStatus[key];
        //                 vm.operationRanges.push(newKey);
        //             });
                        

        //             vm.operationData = resp.operationStatus;

        //             vm.signalStatusWidget1.currentRange = vm.operationRanges[0];
        //             vm.signalStatusWidget2.currentRange = vm.operationRanges[1];
        //             vm.signalStatusWidget3.currentRange = vm.operationRanges[2];
        //             vm.signalStatusWidget4.currentRange = vm.operationRanges[3];
        //             vm.loading = false;

        //             vm.overviewCharts.generateCharts();
        //         });
        // }

        function camelCaseToRegularForm(input) {
            return input.replace(/([A-Z])/g, ' $1')
            .replace(/^./, function (str) { return str.toUpperCase(); })
        }


        // vm.overviewCharts = {
        //     charts: [],
        //     //charts array will hold chartObjects
        //     chartDef: {
        //         config: {
        //             refreshDataOnly: true,
        //             deepWatchDataDepth: 0
        //         },
        //         options: {
        //             chart: {
        //                 type: 'pieChart',
        //                 color: ['#ffbb78', '#1f77b4'],
        //                 height: 400,
        //                 margin: {
        //                     top: 0,
        //                     right: 0,
        //                     bottom: 0,
        //                     left: 0
        //                 },
        //                 donut: true,
        //                 x: function (d) {
        //                     return d.label;
        //                 },
        //                 y: function (d) {
        //                     return d.value;
        //                 },
        //                 tooltip: {
        //                     gravity: 's',
        //                     classes: 'gravity-s'
        //                 },
        //                 pie: {},
        //                 growOnHover: true,
        //                 showLabels: false
        //             }
        //         },
        //     },
        //     createChartObj: function createChartObj(){
        //         return {
        //             setup: angular.copy(vm.overviewCharts.chartDef),
        //             data: {},
        //             title: {}
        //         }
        //     },
        //     generateCharts: function generateCharts() {
        //         for (let data in vm.operationData) {
        //             var chartObj = vm.overviewCharts.createChartObj();
        //             chartObj.data = [{
        //                 "label": data,
        //                 "value": vm.operationData[data].length
        //                 },
        //                 {
        //                     "label": "Other",
        //                     "value": vm.totalSignals - vm.operationData[data].length
        //                 }

        //             ];
        //             chartObj.title = data;
        //             vm.overviewCharts.charts.push(chartObj);
        //         }
        //     }
        // }


                /*!!!!!! everything below here is demo stuff, not working with live data !!!!!!!!! */
                //vm.bookMark = 0;

        //vm.badPacketsWidget = vm.dashboardData.badPacketsWidget;
        //vm.throughputWidget = vm.dashboardData.throughputWidget;
        //vm.networkUtilizationWidget = vm.dashboardData.networkUtilizationWidget;
        //vm.performanceIssuesWidget = vm.dashboardData.performanceIssuesWidget;
        //vm.performanceWarningsWidget = vm.dashboardData.performanceWarningsWidget;
        //vm.performanceGoodWidget = vm.dashboardData.performanceGoodWidget;
        //vm.performanceSystemWidget = vm.dashboardData.performanceSystemWidget;
        //vm.latencyWidget = {
        //    title: vm.dashboardData.latencyWidget.title,
        //    value: vm.dashboardData.latencyWidget.value,
        //    footnote: vm.dashboardData.latencyWidget.footnote,
        //    detail: vm.dashboardData.latencyWidget.detail,
        //    chart: {
        //        config: {
        //            refreshDataOnly: true,
        //            deepWatchData: true,
        //            deepWatchDataDepth: 0
        //        },
        //        options: {
        //            chart: {
        //                type: 'lineChart',
        //                color: ['rgba(0, 0, 0, 0.27)'],
        //                height: 50,
        //                margin: {
        //                    top: 8,
        //                    right: 0,
        //                    bottom: 0,
        //                    left: 0
        //                },
        //                duration: 1,
        //                clipEdge: true,
        //                interpolate: 'cardinal',
        //                interactive: false,
        //                isArea: true,
        //                showLegend: false,
        //                showControls: false,
        //                showXAxis: false,
        //                showYAxis: false,
        //                x: function (d) {
        //                    return d.x;
        //                },
        //                y: function (d) {
        //                    return d.y;
        //                },
        //                yDomain: [0, 4]
        //            }
        //        },
        //        data: vm.dashboardData.latencyWidget.chart
        //    },
        //    init: function () {
        //        // Run this function once to initialize the widget

        //        // Grab the x value
        //        var lastIndex = vm.dashboardData.latencyWidget.chart[0].values.length - 1,
        //            x = vm.dashboardData.latencyWidget.chart[0].values[lastIndex].x;

        //        /**
        //         * Emulate constant data flow
        //         *
        //         * @param min
        //         * @param max
        //         */
        //        function latencyTicker(min, max) {
        //            // Increase the x value
        //            x++;

        //            var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        //            var newValue = {
        //                x: x,
        //                y: randomNumber
        //            };

        //            vm.latencyWidget.chart.data[0].values.shift();
        //            vm.latencyWidget.chart.data[0].values.push(newValue);

        //            // Randomize the value
        //            vm.latencyWidget.value = 220 + randomNumber + 'ms';
        //            vm.latencyWidget.api.update();
        //        }

        //        // Set interval
        //        var latencyTickerInterval = $interval(function () {
        //            latencyTicker(1, 4);
        //        }, 5000);

        //        // Cleanup
        //        $scope.$on('$destroy', function () {
        //            $interval.cancel(latencyTickerInterval);
        //        });
        //    }
        //};
        //// Widget 8
        //vm.uploadDownloadWidget = vm.dashboardData.uploadDownloadWidget;

        //vm.commStatsWidget = {
        //    title: vm.dashboardData.commStatsWidget.title,
        //    chart: {
        //        config: {
        //            refreshDataOnly: true,
        //            deepWatchData: true,
        //            deepWatchDataDepth: 0
        //        },
        //        options: {
        //            chart: {
        //                type: 'stackedAreaChart',
        //                color: ['#4caf50', '#3f51b5', '#ff5722'],
        //                height: 320,
        //                margin: {
        //                    top: 32,
        //                    right: 32,
        //                    bottom: 32,
        //                    left: 48
        //                },
        //                useInteractiveGuideline: true,
        //                clipVoronoi: false,
        //                interpolate: 'linear',
        //                clipEdge: true,
        //                style: "stack",
        //                order: "default",
        //                x: function (d) {
        //                    return d.x;
        //                },
        //                y: function (d) {
        //                    return d.y;
        //                },
        //                xAxis: {
        //                    tickFormat: function (d) {
        //                        return d + ' min.';
        //                    },
        //                    showMaxMin: false
        //                },
        //                yAxis: {
        //                    tickFormat: function (d) {
        //                        return d;
        //                    }
        //                },
        //                interactiveLayer: {
        //                    tooltip: {
        //                        gravity: 's',
        //                        classes: 'gravity-s'
        //                    }
        //                },
        //                legend: {
        //                    margin: {
        //                        top: 8,
        //                        right: 0,
        //                        bottom: 32,
        //                        left: 0
        //                    },
        //                    rightAlign: false
        //                }
        //            }
        //        },
        //        data: vm.dashboardData.commStatsWidget.chart
        //    }
        //};

        //vm.utilizationGraphWidget = {
        //    title: vm.dashboardData.utilizationGraphWidget.title,
        //    chart: {
        //        config: {
        //            refreshDataOnly: true,
        //            deepWatchData: true,
        //            deepWatchDataDepth: 0
        //        },
        //        options: {
        //            chart: {
        //                type: 'lineChart',
        //                color: ['#03A9F4'],
        //                height: 140,
        //                margin: {
        //                    top: 8,
        //                    right: 32,
        //                    bottom: 16,
        //                    left: 48
        //                },
        //                duration: 1,
        //                clipEdge: true,
        //                clipVoronoi: false,
        //                interpolate: 'cardinal',
        //                isArea: true,
        //                useInteractiveGuideline: true,
        //                showLegend: false,
        //                showControls: false,
        //                x: function (d) {
        //                    return d.x;
        //                },
        //                y: function (d) {
        //                    return d.y;
        //                },
        //                yDomain: [0, 100],
        //                xAxis: {
        //                    tickFormat: function (d) {
        //                        return d + ' sec.';
        //                    },
        //                    showMaxMin: false
        //                },
        //                yAxis: {
        //                    tickFormat: function (d) {
        //                        return d + '%';
        //                    }
        //                },
        //                interactiveLayer: {
        //                    tooltip: {
        //                        gravity: 's',
        //                        classes: 'gravity-s'
        //                    }
        //                }
        //            }
        //        },
        //        data: vm.dashboardData.utilizationGraphWidget.chart
        //    },
        //    init: function () {
        //        // Run this function once to initialize the widget

        //        // Grab the x value
        //        var lastIndex = vm.dashboardData.utilizationGraphWidget.chart[0].values.length - 1,
        //            x = vm.dashboardData.utilizationGraphWidget.chart[0].values[lastIndex].x;

        //        /**
        //         * Emulate constant data flow
        //         *
        //         * @param min
        //         * @param max
        //         */
        //        function cpuTicker(min, max) {
        //            // Increase the x value
        //            x = x + 5;

        //            var newValue = {
        //                x: x,
        //                y: Math.floor(Math.random() * (max - min + 1)) + min
        //            };

        //            vm.utilizationGraphWidget.chart.data[0].values.shift();
        //            vm.utilizationGraphWidget.chart.data[0].values.push(newValue);
        //            vm.utilizationGraphWidget.api.update();
        //        }

        //        // Set interval
        //        var cpuTickerInterval = $interval(function () {
        //            cpuTicker(0, 100);
        //        }, 3000);

        //        // Cleanup
        //        $scope.$on('$destroy', function () {
        //            $interval.cancel(cpuTickerInterval);
        //        });
        //    }
        //};

        //vm.utilizationGraphWidget.init();
        //vm.latencyWidget.init();

        //vm.performanceStatsWidget = {
        //    title: vm.performanceData.performanceStatsWidget.title,
        //    chart: {
        //        config: {
        //            refreshDataOnly: true,
        //            deepWatchData: true,
        //            deepWatchDataDepth: 0
        //        },
        //        options: {
        //            chart: {
        //                type: 'multiBarChart',
        //                //color: ['#4caf50', '#3f51b5', '#ff5722'],
        //                height: 400,
        //                stacked: true,
        //                clipEdge: true,
        //                xAxis: {
        //                    showMaxMin: true,
        //                    axisLabel: "Date"

        //                },
        //                yAxis: {
        //                    tickFormat: function (d) {
        //                        return d;
        //                    },
        //                    axisLabel: "Count"
        //                },
        //                interactiveLayer: {
        //                    tooltip: {
        //                        gravity: 's',
        //                        classes: 'gravity-s'
        //                    }
        //                }
        //                //},
        //                //legend: {
        //                //    margin: {
        //                //        top: 8,
        //                //        right: 0,
        //                //        bottom: 32,
        //                //        left: 0
        //                //    },
        //                //    rightAlign: yt
        //                //}
        //            }
        //        },
        //        data: vm.performanceData.performanceStatsWidget.chart
        //    }
        //};

  
    }

})();