﻿// nv.models.multiChartWithFocus = function () {
//     "use strict";

//     //============================================================
//     // Public Variables with Default Settings
//     //------------------------------------------------------------

//     // var x = d3.scale.linear(),
//     //     x2 = d3.scale.linear()
//     //     //setup Y scales for multi charts
//     //     ,
//     //     yScale1 = d3.scale.linear(),
//     //     yScale2 = d3.scale.linear(),
//     //     yScale3 = d3.scale.linear(),
//     //     yScale4 = d3.scale.linear(),
//     //     xAxis = nv.models.axis().scale(x).orient('bottom').tickPadding(5),
//     //     yAxis = nv.models.axis().scale(yScale1).orient('left'),
//     //     x2Axis = nv.models.axis().scale(x2).orient('bottom').tickPadding(5),
//     //     y2Axis = nv.models.axis().scale(yScale2).orient('right'),
//     //     legend = nv.models.legend(),
//     //     controls = nv.models.legend(),
//     //     planControls = nv.models.legend(),
//     //     brush = d3.svg.brush(),
//     //     interpolate = 'basis',
//     //     interpolate2 = 'basis',
//     //     y1min,
//     //     y1max,
//     //     y2min,
//     //     y2max,
//     //     forceY1min = -1,
//     //     forceY2min = -1,
//     //     timelineHideScatters1 = false,
//     //     timelineGreyBackground = false,
//     //     thinHistoricalBars = false,
//     //     //plan info
//     //     planAxisHeight = 50,
//     //     planData = [],
//     //     xPlanAxis = nv.models.axis().scale(x).tickSize(0).tickPadding(0).orient('bottom').showMaxMin(false),
//     //     planLabelCallback = null,
//     //     //setup multichart
//     //     multibar = nv.models.multiBar().yScale(yScale1),
//     //     multibarContext = nv.models.multiBar()

//     //     //setup for line chart
//     //     ,
//     //     lines1 = nv.models.line().yScale(yScale1).duration(duration),
//     //     lines2 = nv.models.line().yScale(yScale2).duration(duration),
//     //     lines1Context = nv.models.line(),
//     //     lines2Context = nv.models.line()

//     //     //setup bar chart
//     //     , historicalBars = nv.models.historicalBar().yScale(yScale1)
//     //     , historicalBarsContext = nv.models.historicalBar()

//     //     //setup for scatter plot
//     //     , scatters1 = nv.models.scatter().yScale(yScale1).duration(duration)
//     //     , scatters2 = nv.models.scatter().yScale(yScale2).duration(duration)
//     //     , scatters1Context = nv.models.scatter()
//     //     , scatters2Context = nv.models.scatter()

//     //     //setup for stacked area
//     //     //, stack1 = nv.models.stackedArea().yScale(yScale1).duration(duration)
//     //     //, stack1Context = nv.models.stackedArea()
//     // ;

//     // var margin = { top: 30, right: 20, bottom: 250, left: 60 }
//     //     , margin2 = { top: 0, right: 30, bottom: 20, left: 60 }
//     //     , width = null
//     //     , height = null
//     //     , height2 = 100
//     //     , color = nv.utils.defaultColor()
//     //     , showControls = true
//     //     , controlLabels = {}
//     //     , showLegend = true
//     //     , focusEnable = true
//     //     , focusShowAxisY = false
//     //     , focusShowAxisX = true
//     //     , focusHeight = 50
//     //     , showXAxis = true
//     //     , showPlanAxis = false
//     //     , enablePlanAxis = false
//     //     , planAxisVisible = false
//     //     , showYAxis = true
//     //     , rightAlignYAxis = false
//     //     , stackBars = false
//     //     , disableLegendClick = false
//     //     , disableStackBarResizing = false
//     //     , reduceXTicks = true // if false a tick will show for every data point
//     //     , staggerLabels = false
//     //     , rotateLabels = 0
//     //     , tooltips = true
//     //     , tooltip = nv.models.tooltip()
//     //     , interactiveLayer = nv.interactiveGuideline()
//     //     , useInteractiveGuideline = false
//     //     , useVoronoi = false
//     //     , groupSpacing = 0.0
//     //     , getX = function (d) {
//     //         if (d)
//     //             return d.x;
//     //         return 0;
//     //     }
//     //     , getY = function (d) {
//     //         if (d)
//     //             return d.y;
//     //         return 0;
//     //     }
//     //     , x //can be accessed via chart.xScale()
//     //     , y //can be accessed via chart.yScale()
//     //     , x2
//     //     , y2
//     //     , state = nv.utils.state()
//     //     , brushExtent = null
//     //     , defaultState = null
//     //     , noData = "No Data Available."
//     //     , dispatch = d3.dispatch('tooltipShow', 'tooltipHide', 'brush', 'stateChange', 'changeState', 'renderEnd')
//     //     , controlWidth = function () { return showControls ? 180 : 0 }
//     //     , planControlWidth = function () { return enablePlanAxis ? 90 : 0 }
//     //     , duration = 250
//     // ;

//     // state.stacked = false // DEPRECATED Maintained for backward compatibility

//     // controls.updateState(false);

//     // //============================================================
//     // // Private Variables
//     // //------------------------------------------------------------

//     // var stacked = false;
//     // var groupedState;

//     // tooltip
//     //     .duration(0)
//     //     .valueFormatter(function (d, i) {
//     //         return yAxis.tickFormat()(d, i);
//     //     })
//     //     .headerFormatter(function (d, i) {
//     //         return xAxis.tickFormat()(d, i);
//     //     });

//     // var stateGetter = function (data) {
//     //     return function () {
//     //         return {
//     //             active: data.map(function (d) { return !d.disabled }),
//     //             stacked: stacked
//     //         };
//     //     }
//     // };

//     // var stateSetter = function (data) {
//     //     return function (state) {
//     //         if (state.stacked !== undefined)
//     //             stacked = state.stacked;
//     //         if (state.active !== undefined)
//     //             data.forEach(function (series, i) {
//     //                 series.disabled = !state.active[i];
//     //             });
//     //     }
//     // };

    
//     // //var y = nv.models.axis();  //holder for initial yAxis domain, which will update on brush
//     // //var y2 = nv.models.axis(); //holder for initial y2Axis domain, which will update on brush
//     // // yAxis3.domain(yAxis.domain());  //TODO implement y axes for context bar
//     // // yAxis4.domain(y2Axis.domain());  //TODO implement y axes for context bar

//     // lines1Context.interactive(false);
//     // lines1Context.pointActive(function(d) { return false; });

//     // lines2Context.interactive(false);
//     // lines2Context.pointActive(function(d) { return false; });

//     // var charts = [lines1, lines2, historicalBars, historicalBarsContext, lines1Context, lines2Context, scatters1, scatters2, scatters1Context, scatters2Context, multibar, multibarContext];

//     // function syncPlanTimesWithData(data) {
//     //     if (!enablePlanAxis || !showPlanAxis) return;

//     //     //get all the X values in our data
//     //     var allArrays = data.filter(function (d) { return !d.disabled})
//     //         .map(function (d) {
//     //             return d.values.map(function (d, i) {
//     //                 return getX(d);
//     //             })
//     //         });

//     //     //combine all data series into one array
//     //     var allSeriesData = [];
//     //     data.filter(function (d) { return !d.disabled && (d.yAxis == 1 || d.yAxis == 2)  }).forEach(function (e) {
//     //         allSeriesData = allSeriesData.concat(e.values);
//     //     });

//     //     //concat 
//     //     var xData = [];
//     //     allArrays.forEach(function (e) {
//     //         xData = xData.concat(e);
//     //     });
//     //     //find the min and max X values in our data
//     //     var xMax = Math.max.apply(null, xData);
//     //     var xMin = Math.min.apply(null, xData);

//     //     //set plan bounds based on our data values
//     //     for (var i = 0; i < planData.length; i++) {
//     //         var start = new Date(planData[i].startTime).getTime();
//     //         var end = new Date(planData[i].endTime).getTime();

//     //         if (i % 2 == 0) {
//     //             planData[i].class ='plan-label plan-label-rect-even';
//     //         }
//     //         else {
//     //             planData[i].class ='plan-label plan-label-rect-odd';
//     //         }
//     //         if (start < xMin) {
//     //             planData[i].startTime = new Date(xMin);
//     //         }
//     //         if (end > xMax) {
//     //             planData[i].endTime = new Date(xMax);
//     //         }
//     //     }

//     //     //go through our data and add plan number to each data point
//     //     for (var j = 0; j < allSeriesData.length; j++){
//     //         var plan = findPlan(planData, new Date(allSeriesData[j].x).getTime());
//     //         if (plan)
//     //             allSeriesData[j].planNumber = getPlanTextFromNumber(plan.planNumber);
//     //     }
//     // }
//     // function getPlanTextFromNumber(planNumber) {
//     //     var res = planNumber;
//     //     switch (planNumber) {
//     //         case 0:
//     //             res = "Auto";
//     //             break;
//     //         case 254:
//     //             res = "Free";
//     //             break;
//     //         case 255:
//     //             res = "Flash";
//     //             break;
//     //     }
//     //     return res;
//     // }
//     // function findPlan(plans, d) {
//     //     var plan = plans.find(function (x) { return new Date(x.startTime).getTime() == d });
//     //     if (!plan) {
//     //         plan = plans.find(function (x) { return d > new Date(x.startTime).getTime() && d <= new Date(x.endTime).getTime() });
//     //     }
//     //     return plan;
//     // }
//     // function chart(selection) {
//     //     multibar.groupSpacing(groupSpacing);
//     //     multibarContext.groupSpacing(groupSpacing);

//     //     //set local vars
//     //     var localPlanData = planData;
//     //     var localPlanCallback = planLabelCallback;

//     //     if (showControls) {
//     //         if (groupedState == "Grouped") {
//     //             multibar.stacked(false);
//     //             multibarContext.stacked(false);
//     //         }
//     //         else if (groupedState == "Stacked") {
//     //             multibar.stacked(true);
//     //             multibarContext.stacked(true);
//     //         }
//     //         else {
//     //             multibar.stacked(stackBars);
//     //             multibarContext.stacked(stackBars);
//     //         }
//     //     }
//     //     else {
//     //         multibar.stacked(stackBars);
//     //         multibarContext.stacked(stackBars);
//     //     }

//     //     selection.each(function (data) {
//     //         var container = d3.select(this),
//     //             that = this;
//     //         nv.utils.initSVG(container);
//     //         var availableWidth = (width || parseInt(container.style('width')) || 960)
//     //                 - margin.left - margin.right,
//     //             availableHeight = (height || parseInt(container.style('height')) || 400)
//     //                 - margin.top - margin.bottom - (focusEnable ? focusHeight : 0),
//     //             availableHeight2 = focusEnable ? (focusHeight - (margin2.bottom - margin2.top)) : 0;

//     //         syncPlanTimesWithData(data);
//     //         var chartAreaHeight = 0;
//     //         setChartHeight();

//     //         //filter data for each chart type
//     //         var dataLinesCF = [], dataLines2CF = [], dataScatters1CF = [], dataScatters2CF = [], dataBarsCF = [], dataHistoricalBarsCF = [];

//     //         var dataLines1 = data.filter(function (d) { return d.type == 'line' && d.yAxis == 1 });
//     //         dataLines1.forEach(function (c) {
//     //             dataLinesCF[c.key] = crossfilter(c.values).dimension(function (d) { return d.x; });
//     //         });
//     //         var dataLines2 = data.filter(function (d) { return d.type == 'line' && d.yAxis == 2 });
//     //         dataLines2.forEach(function (c) {
//     //             dataLines2CF[c.key] = crossfilter(c.values).dimension(function (d) { return d.x; });
//     //         });

//     //         var dataHistoricalBars = data.filter(function (d) { return d.type == 'hist-bar' && d.yAxis == 1 });
//     //         dataHistoricalBars.forEach(function (c) {
//     //             dataHistoricalBarsCF[c.key] = crossfilter(c.values).dimension(function (d) { return d.x; });
//     //         });

//     //         var dataScatters1 = data.filter(function (d) { return d.type == 'scatter' && d.yAxis == 1 });
//     //         dataScatters1.forEach(function (c) {
//     //             dataScatters1CF[c.key] = crossfilter(c.values).dimension(function (d) { return d.x; });
//     //         });

//     //         var dataScatters2 = data.filter(function (d) { return d.type == 'scatter' && d.yAxis == 2 });
//     //         dataScatters2.forEach(function (c) {
//     //             dataScatters2CF[c.key] = crossfilter(c.values).dimension(function (d) { return d.x; });
//     //         });

//     //         //used to hold filtered databars data for quicker searching
//     //         var dataBarsFiltered;
//     //         var dataBars = data.filter(function (d) { return d.type == 'bar' && d.yAxis == 1 });
//     //         dataBars.forEach(function (c) {
//     //             //databars must be sorted
//     //             c.values.sort(function (a, b) {
//     //                 if (a.x instanceof Date)
//     //                     return a.x.getTime() - b.x.getTime();
//     //                 else
//     //                     return a.x - b.x;
//     //             });
//     //             dataBarsCF[c.key] = crossfilter(c.values).dimension(function (d) { return d.x; });
//     //         });

//     //         chart.update = function () { container.transition().call(chart); };
//     //         chart.container = this;

//     //         if (showControls) {
//     //             state
//     //            .setter(stateSetter(data), chart.update)
//     //            .getter(stateGetter(data))
//     //            .update();

//     //             // DEPRECATED set state.disableddisabled
//     //             state.disabled = data.map(function (d) { return !!d.disabled });

//     //             if (!defaultState) {
//     //                 var key;
//     //                 defaultState = {};
//     //                 for (key in state) {
//     //                     if (state[key] instanceof Array)
//     //                         defaultState[key] = state[key].slice(0);
//     //                     else
//     //                         defaultState[key] = state[key];
//     //                 }
//     //             }
//     //         }
            

//     //         // Display noData message if there's nothing to show.
//     //         if (!data || !data.length || !data.filter(function (d) { return d.values.length }).length) {
//     //             var noDataText = container.selectAll('.nv-noData').data([noData]);

//     //             noDataText.enter().append('text')
//     //                 .attr('class', 'nvd3 nv-noData')
//     //                 .attr('dy', '-.7em')
//     //                 .style('text-anchor', 'middle');

//     //             noDataText
//     //                 .attr('x', margin.left + availableWidth / 2)
//     //                 .attr('y', margin.top + chartAreaHeight / 2)
//     //                 .text(function (d) { return d });

//     //             return chart;
//     //         } else {
//     //             container.selectAll('.nv-noData').remove();
//     //         }

//     //         //x.range([0, availableWidth]);
//     //         var series1 = data.filter(function (d) { return !d.disabled && d.yAxis == 1 })
//     //         .map(function (d) {
//     //             return d.values.map(function (d, i) {
//     //                 return { x: getX(d), y: getY(d) }
//     //             })
//     //         });
//     //         var series2 = data.filter(function (d) { return !d.disabled && d.yAxis == 2 })
//     //         .map(function (d) {
//     //             return d.values.map(function (d, i) {
//     //                 return { x: getX(d), y: getY(d) }
//     //             })
//     //         });

//     //         x.domain(d3.extent(d3.merge(series1.concat(series2)), function (d) { return getX(d) }))
//     //             .range([0, availableWidth]);

//     //         x2.domain(d3.extent(d3.merge(series1.concat(series2)), function (d) { return getX(d) }))
//     //             .range([0, availableWidth]);


//     //         // Setup containers and skeleton of main chart
//     //         var wrap = container.selectAll('g.nv-wrap.nv-multiChart').data([data]);
//     //         var gEnter = wrap.enter().append('g').attr('class', 'nvd3 nv-wrap nv-multiChart').append('g');
//     //         var g = wrap.select('g');

//     //         var focusEnter = gEnter.append('g').attr('class', 'nv-focus');
//     //         //axis
//     //         focusEnter.append('g').attr('class', 'nv-x nv-axis');
//     //         focusEnter.append('g').attr('class', 'nv-x2 nv-axis nv-axis-plan');
//     //         focusEnter.append('g').attr('class', 'nv-y1 nv-axis');
//     //         focusEnter.append('g').attr('class', 'nv-y2 nv-axis');

//     //         var historicalBarAddClass = "";
//     //         if (thinHistoricalBars) {
//     //             historicalBarAddClass = 'thin-bars';
//     //         }

//     //         //stack
//     //         //focusEnter.append('g').attr('class', 'nv-stack1Wrap');
//     //         //bars
//     //         focusEnter.append('g').attr('class', 'nv-barsWrap');

//     //         //lines
//     //         focusEnter.append('g').attr('class', 'nv-historicalBarsWrap ');
//     //         focusEnter.append('g').attr('class', 'nv-lines1Wrap');
//     //         focusEnter.append('g').attr('class', 'nv-lines2Wrap');
            
//     //         //scatters
//     //         focusEnter.append('g').attr('class', 'nv-scatters1Wrap');
//     //         focusEnter.append('g').attr('class', 'nv-scatters2Wrap');

//     //         focusEnter.append('g').attr('class', 'nv-legendWrap');
//     //         focusEnter.append('g').attr('class', 'nv-controlsWrap');
//     //         focusEnter.append('g').attr('class', 'nv-plans-controlsWrap');
//     //         if (useInteractiveGuideline)
//     //             focusEnter.append('g').attr('class', 'nv-interactive');

//     //         var contextEnter = gEnter.append('g').attr('class', 'nv-context');
//     //         contextEnter.append('g').attr('class', 'nv-x nv-axis');
//     //         contextEnter.append('g').attr('class', 'nv-y nv-axis');

//     //         contextEnter.append('g').attr('class', 'nv-historicalBarsWrap ');
//     //         contextEnter.append('g').attr('class', 'nv-barsWrap');
//     //         contextEnter.append('g').attr('class', 'nv-lines1Wrap');
//     //         contextEnter.append('g').attr('class', 'nv-lines2Wrap');

//     //         contextEnter.append('g').attr('class', 'nv-scatters1Wrap');
//     //         contextEnter.append('g').attr('class', 'nv-scatters2Wrap');
//     //         //contextEnter.append('g').attr('class', 'nv-stack1Wrap');

//     //         contextEnter.append('g').attr('class', 'nv-brushBackground');
//     //         contextEnter.append('g').attr('class', 'nv-x nv-brush');
        

//     //         // Legend
//     //         if (showLegend) {
//     //             if (disableLegendClick){
//     //                 legend.updateState(false);
//     //             }
//     //             //support long labels
//     //             legend.maxKeyLength(100);
//     //             legend.width(availableWidth - controlWidth());

//     //             if (multibar.barColor()) {
//     //                 data.forEach(function (series, i) {
//     //                     series.color = d3.rgb('#ccc').darker(i * 1.5).toString();
//     //                 });
//     //             }

//     //             g.select('.nv-legendWrap')
//     //                 .datum(data)
//     //                 .call(legend);

//     //             if (margin.top != legend.height()) {
//     //                 margin.top = legend.height();
//     //                 availableHeight = (height || parseInt(container.style('height')) || 400)
//     //                     - margin.top - margin.bottom;
//     //             }

//     //             g.select('.nv-legendWrap')
//     //                 .attr('transform', 'translate(' + controlWidth() + ',' + (-margin.top) + ')');
//     //         }

//     //         // Controls
//     //         if (showControls) {
//     //             var controlsData = [
//     //                 { key: controlLabels.grouped || 'Grouped', disabled: multibar.stacked() },
//     //                 { key: controlLabels.stacked || 'Stacked', disabled: !multibar.stacked() }
//     //             ];

//     //             controls.width(controlWidth()).color(['#444', '#444', '#444']);
//     //             g.select('.nv-controlsWrap')
//     //                 .datum(controlsData)
//     //                 .attr('transform', 'translate(0,' + (-margin.top) + ')')
//     //                 .call(controls);
//     //         }

//     //         if (enablePlanAxis) {
//     //             var plansControlsData = [{ key: 'Show Plans', disabled: !showPlanAxis }];

//     //             var shift = 20;
//     //             if (showControls)
//     //                 shift = 180;

//     //             planControls.width(planControlWidth()).color(['#444', '#444', '#444']);
//     //             g.select('.nv-plans-controlsWrap')
//     //                 .datum(plansControlsData)
//     //                 .attr('transform', 'translate(' + shift + ',' + (-margin.top) + ')')
//     //                 .call(planControls);
//     //         }

//     //         //setup the plan axis tick logic
//     //         if (showPlanAxis) {
//     //             xPlanAxis.tickSize(0).tickPadding(0).showMaxMin(false).tickFormat(function (d, i) {

//     //                 var plan = findPlan(localPlanData, d);
//     //                 if (plan) {
//     //                     var text = "Plan " + plan.planNumber;
//     //                     switch (plan.planNumber) {
//     //                         case 0:
//     //                             text = "Auto";
//     //                         case 254:
//     //                             text = "Free";
//     //                             break;
//     //                         case 255:
//     //                             text = "Flash";
//     //                             break;
//     //                     }
//     //                     return text;
//     //                 }
//     //                 return "Unknown";
//     //             });
//     //             updatePlanTickValues(x.domain()[0], x.domain()[1]);
//     //         }

//     //         wrap.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
//     //         if (rightAlignYAxis) {
//     //             var yTransForm = 0;
//     //             if (showPlanAxis)
//     //                 yTransform = planAxisHeight;

//     //             g.select(".nv-y1.nv-axis")
//     //                 .attr("transform", "translate(" + availableWidth + "," + yTransform + ")");
//     //         }

//     //         // Main Chart Component(s)
//     //         setupMainChartObjects();


//     //         if (focusEnable) {
//     //             multibarContext
//     //                 .width(availableWidth)
//     //                 .height(availableHeight2)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'bar' && data[i].yAxis == 1 }));

//     //             lines1Context
//     //                 .width(availableWidth)
//     //                 .height(availableHeight2)
//     //                 .interpolate(interpolate)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'line' && data[i].yAxis == 1 }));
//     //             lines2Context
//     //                 .width(availableWidth)
//     //                 .height(availableHeight2)
//     //                 .interpolate(interpolate2)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'line' && data[i].yAxis == 2 }));
//     //             historicalBarsContext
//     //                 .width(availableWidth)
//     //                 .height(availableHeight2)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'hist-bar' && data[i].yAxis == 1 }));
//     //             if (!timelineHideScatters1) {
//     //                 scatters1Context
//     //                     .width(availableWidth)
//     //                     .height(availableHeight2)
//     //                     .color(data.map(function (d, i) {
//     //                         return d.color || color(d, i);
//     //                     }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'scatter' && data[i].yAxis == 1 }));
//     //             }

//     //             scatters2Context
//     //                 .width(availableWidth)
//     //                 .height(availableHeight2)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'scatter' && data[i].yAxis == 2 }));
//     //         }
        

//     //         //stack1Context
//     //         //    .width(availableWidth)
//     //         //    .height(availableHeight2)
//     //         //    .interpolate(interpolate)
//     //         //    .color(data.map(function (d, i) {
//     //         //        return d.color || color(d, i);
//     //         //    }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'area' && data[i].yAxis == 1 }));

//     //         //set data
//     //         var bars1Wrap = g.select('.nv-focus .nv-barsWrap')
//     //             .datum(dataBars.filter(function (d) { return !d.disabled }));
//     //         var lines1Wrap = g.select('.nv-focus .nv-lines1Wrap')
//     //             .datum(dataLines1.filter(function (d) { return !d.disabled }));
//     //         var scatters1Wrap = g.select('.nv-focus .nv-scatters1Wrap')
//     //             .datum(dataScatters1.filter(function (d) { return !d.disabled }));
//     //         //var stack1Wrap = g.select('.nv-focus .nv-stack1Wrap')
//     //         //    .datum(dataStack1.filter(function (d) { return !d.disabled }));
//     //         var lines2Wrap = g.select('.nv-focus .nv-lines2Wrap')
//     //             .datum(dataLines2.filter(function (d) { return !d.disabled }));

//     //         var historicalBarsWrap = g.select('.nv-focus .nv-historicalBarsWrap')
//     //             .datum(dataHistoricalBars.map(function (d) {
//     //                 if (d.disabled) {
//     //                     return {
//     //                         disabled: d.disabled,
//     //                         key: d.key,
//     //                         type: d.type,
//     //                         values: [],
//     //                         yAxis: d.yAxis
//     //                     }
//     //                 }
//     //                 return d;
//     //             }));

//     //         var scatters2Wrap = g.select('.nv-focus .nv-scatters2Wrap')
//     //             .datum(dataScatters2.filter(function (d) { return !d.disabled }));

//     //         updateDomains();
            
//     //         //set data for each chart type and it's corresponding context timeline data
//     //         if (dataBars.length) {
//     //             d3.transition(bars1Wrap).call(multibar);

//     //             if (focusEnable) {
//     //                 var contextBarsWrap = g.select('.nv-context .nv-barsWrap').datum(dataBars.filter(function (d) { return !d.disabled }))

//     //                 d3.transition(contextBarsWrap).call(multibarContext);
//     //             }
//     //         }
//     //         if (dataHistoricalBars.length) {
//     //             d3.transition(historicalBarsWrap).call(historicalBars);

//     //             if (focusEnable) {
//     //                 var contextBarsWrap = g.select('.nv-context .nv-historicalBarsWrap').datum(dataHistoricalBars.map(
//     //                     function (d) {
//     //                         if (d.disabled) {
//     //                             return {
//     //                                 disabled: d.disabled,
//     //                                 key: d.key,
//     //                                 type: d.type,
//     //                                 values: [],
//     //                                 yAxis: d.yAxis
//     //                             }
//     //                         }
//     //                         return d;
//     //                     }));

//     //                 contextBarsWrap.transition().call(historicalBarsContext);              
//     //             }
//     //         }
//     //         if (dataLines1.length) {
//     //             d3.transition(lines1Wrap).call(lines1);

//     //             if (focusEnable) {
//     //                 var contextBarsWrap = g.select('.nv-context .nv-lines1Wrap').datum(dataLines1.filter(function (d) { return !d.disabled }))

//     //                 contextBarsWrap.transition().call(lines1Context);
//     //             }
//     //         }
//     //         if (dataLines2.length) {
//     //             d3.transition(lines2Wrap).call(lines2);

//     //             if (focusEnable) {
//     //                 var contextBarsWrap = g.select('.nv-context .nv-lines2Wrap').datum(dataLines2.filter(function (d) { return !d.disabled }))

//     //                 contextBarsWrap.transition().call(lines2Context);
//     //             }
//     //         }

//     //         if (dataScatters1.length) {
//     //             d3.transition(scatters1Wrap).call(scatters1);

//     //             if (focusEnable) {
//     //                 if (!timelineHideScatters1) {
//     //                     var contextBarsWrap = g.select('.nv-context .nv-scatters1Wrap').datum(dataScatters1.filter(function (d) { return !d.disabled }))

//     //                     contextBarsWrap.transition().call(scatters1Context);
//     //                 }
//     //             }
//     //         }
//     //         if (dataScatters2.length) {
//     //             d3.transition(scatters2Wrap).call(scatters2);
//     //             if (focusEnable) {
//     //                 var contextBarsWrap = g.select('.nv-context .nv-scatters2Wrap').datum(dataScatters2.filter(function (d) { return !d.disabled }))

//     //                 contextBarsWrap.transition().call(scatters2Context);
//     //             }
//     //         }

//     //         //if (dataStack1.length) {
//     //         //    d3.transition(stack1Wrap).call(stack1);

//     //         //    var contextBarsWrap = g.select('.nv-context .nv-stack1Wrap').datum(dataStackContext.filter(function (d) { return !d.disabled }))

//     //         //    contextBarsWrap.transition().call(stack1Context);
//     //         //}

//     //         // Context component

//     //         if (focusEnable) {
//     //             g.select('.nv-context')
//     //                 .attr('transform', 'translate(0,' + (availableHeight + margin.bottom + margin2.top) + ')')

//     //             //setup axes for timeline
//     //             x2Axis
//     //                 ._ticks(nv.utils.calcTicksX(availableWidth / 100, data))
//     //                 .tickSize(-availableHeight2, 0);

//     //             g.select('.nv-context .nv-x.nv-axis')
//     //                 .attr('transform', 'translate(0,' + availableHeight2 + ')');
//     //             d3.transition(g.select('.nv-context .nv-x.nv-axis'))
//     //                 .call(x2Axis);
//     //         }
           

//     //         function setChartHeight() {
//     //             chartAreaHeight = availableHeight;
//     //             if (showPlanAxis) {
//     //                 chartAreaHeight = availableHeight - planAxisHeight;
//     //             }

//     //         }

//     //         function updatePlanTickValues(min, max) {
//     //             if (showPlanAxis) {
//     //                 //set xPlanAxis tick values here depending on scale
//     //                 var planStarts = planData
//     //                     .filter(function (f) {
//     //                         var start = new Date(f.startTime).getTime();
//     //                         var end = new Date(f.endTime).getTime();

//     //                         //our start time is past the end time domain
//     //                         if (start > max)
//     //                             return false;

//     //                         return true;
//     //                     })
//     //                     .map(function (z) {
//     //                         var start = new Date(z.startTime).getTime();
//     //                         var end = new Date(z.endTime).getTime();

//     //                         //if start time is before min domain and NOT greater than max domain
//     //                         if (start < min && start < max) {
//     //                             return min;
//     //                         }
//     //                         return start;
//     //                     });
//     //                 xPlanAxis.tickValues(planStarts);
//     //             }
//     //         }

//     //         function setupAxis() {
//     //             setChartHeight();
//     //             // Setup Axes
//     //             if (showXAxis) {
//     //                 xAxis
//     //                     ._ticks(nv.utils.calcTicksX(availableWidth / 100, data))
//     //                     .tickSize(-availableHeight, 0);
//     //             }
//     //             g.select('.nv-x.nv-axis')
//     //                 .attr('height', chartAreaHeight)
//     //                 .attr('transform', 'translate(0,' + availableHeight + ')');
//     //             d3.transition(g.select('.nv-x.nv-axis'))
//     //                 .call(xAxis);
//     //             var yTransform = 0;
//     //             if (showPlanAxis) {
//     //                 //setup the top plan axis and translate Y axis accordingly
//     //                 //xPlanAxis
//     //                 //    ._ticks(nv.utils.calcTicksX(availableWidth / 100, localPlanData.map(function (x) { return new Date(x.startTime) })));

//     //                 g.select('.nv-x2.nv-axis-plan')
//     //                     .attr('height', chartAreaHeight);

//     //                 d3.transition(g.select('.nv-x2.nv-axis-plan'))
//     //                     .call(xPlanAxis);


//     //                 //check if our label rect exists already
//     //                 g.select('.nv-x2.nv-axis-plan').selectAll("g.tick").each(function (d, z) {
//     //                     var thisTick = d3.select(this);
//     //                     var planDescClass = "plan-desc-text", hideClass = "hide-label", showClass="show-label";
//     //                     var widthPadding = 10;

//     //                     var planLabel = thisTick.selectAll(".plan-label");
//     //                     var mainText = thisTick.selectAll("text:not(." + planDescClass + ")");
//     //                     var descText = thisTick.selectAll(".plan-desc-text");
//     //                     var plan = findPlan(localPlanData, d);

//     //                     //if rect doesn't exist we need to add it.
//     //                     if (planLabel.empty()) {
//     //                         thisTick
//     //                             .insert('rect', ':first-child')
//     //                             .attr('width', function (d, i) {
//     //                                 return getPlanOverlayWidth(plan);
//     //                             })
//     //                             //+ margin.bottom + margin2.top
//     //                             .attr('height', availableHeight)
//     //                             .attr('class', function (d, i) {
//     //                                 var res = "";
//     //                                 if (plan) {
//     //                                     res = plan.class;
//     //                                 }
//     //                                 return res;
//     //                             });
//     //                     }
//     //                     //if rect exists we need to update size
//     //                     else {
//     //                         planLabel.attr('width', function (d, i) {
//     //                             return getPlanOverlayWidth(plan);
//     //                         });
//     //                     }

//     //                     //if we don't have a secondary text label, let's see if the callback function returns one and if so, add it
//     //                     if (descText.empty() && localPlanCallback) {
//     //                         var callbackText = localPlanCallback(plan);
//     //                         if (callbackText && callbackText != "") {
//     //                             //split into lines
//     //                             var strLines = callbackText.split("\n");
//     //                             var startingYHeight = 25;

//     //                             strLines.forEach(function (line) {
//     //                                 descText = thisTick.append('text');
//     //                                 descText.attr('class', planDescClass)
//     //                                     .attr('y', startingYHeight)
//     //                                     .style('text-anchor', 'middle')
//     //                                     .html(line);
//     //                                 startingYHeight += 15;
//     //                             });
//     //                         }
//     //                     }

//     //                     //this keeps the text always centered even when scrolling via timeline
//     //                     thisTick.selectAll(".plan-desc-text").attr("transform", function (d, i) {
//     //                         return "translate(" + thisTick.selectAll('rect').attr('width') / 2 + ",0)";
//     //                     });

//     //                     //this keeps the text always centered even when scrolling via timeline
//     //                     mainText.attr("transform", function (d, i) {
//     //                         return "translate(" + thisTick.selectAll('rect').attr('width') / 2 + ",0)";
//     //                     });

                        
//     //                     //Plan text sometimes won't fit
//     //                     //get the width and if it's greater than plan overlay width hide it
//     //                     if (mainText[0][0].getBBox().width + widthPadding > (thisTick.selectAll(".plan-label").attr('width'))) {
//     //                         mainText.attr('class', hideClass);
//     //                     }
//     //                     else {
//     //                         mainText.attr('class', showClass);
//     //                     }

//     //                     //Description texts won't always fit
//     //                     //Let's get the text width, if it's greater than the total plan width hide it
//     //                     var descWidths = [];
//     //                     thisTick.selectAll(".plan-desc-text")[0].forEach(function (tBox) {
//     //                         descWidths.push(tBox.getBBox().width);
//     //                     });

//     //                     var newClass = "";
//     //                     var maxWidth = Math.max.apply(null, descWidths);
//     //                     if (maxWidth > 0) {
//     //                         if (Math.max.apply(null, descWidths) + widthPadding > (thisTick.selectAll(".plan-label").attr('width'))) {
//     //                             newClass = planDescClass + " " + hideClass;
//     //                         }
//     //                         else {
//     //                             newClass = planDescClass + " " + showClass;
//     //                         }
//     //                         thisTick.selectAll(".plan-desc-text").attr("class", function (d, i) {
//     //                             return newClass;
//     //                         });
//     //                     }
//     //                 });

//     //                 yTransform = planAxisHeight;
//     //             }

//     //             if (showYAxis) {
//     //                 yAxis
//     //                     ._ticks(nv.utils.calcTicksY(availableHeight / 36, data))
//     //                     .tickSize(-availableWidth, 0);
//     //                 y2Axis
//     //                     ._ticks(nv.utils.calcTicksY(availableHeight / 36, data))
//     //                     .tickSize(-availableWidth, 0);
//     //             }
//     //             d3.transition(g.select('.nv-y1.nv-axis'))
//     //                 .call(yAxis);
              
//     //             d3.transition(g.select('.nv-y2.nv-axis'))
//     //                 .call(y2Axis);


//     //             g.select('.nv-y1.nv-axis')
//     //                 .classed('nv-disabled', series1.length ? false : true)
//     //                 .attr('transform', 'translate(' + x.range()[0] + ',' + yTransform + ')');

//     //             g.select('.nv-y2.nv-axis')
//     //                 .classed('nv-disabled', series2.length ? false : true)
//     //                 .attr('transform', 'translate(' + x.range()[1] + ',' + yTransform + ')');
//     //         }

//     //         if (useInteractiveGuideline) {
//     //             interactiveLayer
//     //                 .width(availableWidth)
//     //                 .height(chartAreaHeight)
//     //                 .margin({ left: margin.left, top: margin.top })
//     //                 .svgContainer(container)
//     //                 .xScale(x);
//     //             wrap.select(".nv-interactive").call(interactiveLayer);
//     //         }

//     //         function getPlanOverlayWidth(plan) {
//     //             var width = 0;
//     //             if (plan) {
//     //                 //Get the MIN value of either the X domain maximum or the plan end time
//     //                 //Get the MAX value of either the X domain min or plan start time
//     //                 //pass the difference through x() to time scale the time difference into screen pixels
//     //                 width = Math.min(x(x.domain()[1]), x(new Date(plan.endTime).getTime())) - Math.max(x(x.domain()[0]), x(new Date(plan.startTime).getTime()));
//     //             }

//     //             if (width > availableWidth)
//     //                 width = availableWidth;
//     //             if (width < 0)
//     //                 width = 0;

//     //             return width;
//     //         }

//     //         //we need to update our domains when timeline brush event occurs
//     //         //otherwise our data will not be scaled correctly
//     //         function updateDomains() {
//     //             if (stackBars && dataBars) {
//     //                 xAxis.scale().rangeBands = xAxis.scale().range;
//     //                 xAxis.scale().rangeBand = function () {
//     //                     return 0;
//     //                 };
//     //                 multibar.yDomain(yAxis.domain());
//     //                 multibar.yScale(yAxis.scale());
//     //                 multibar.xDomain(xAxis.domain());
//     //                 multibar.xScale(xAxis.scale());

//     //                 multibarContext.xDomain(xAxis.domain());
//     //                 multibarContext.xScale(xAxis.scale());

//     //             }

//     //             lines1.yDomain(yAxis.domain());
//     //             historicalBars.yDomain(yAxis.domain());
//     //             scatters1.yDomain(yAxis.domain());
//     //             lines2.yDomain(y2Axis.domain());
//     //             scatters2.yDomain(y2Axis.domain());

//     //             lines1.yScale(yAxis.scale());
//     //             historicalBars.yScale(yAxis.scale());

//     //             scatters1.yScale(yAxis.scale());
//     //             lines2.yScale(y2Axis.scale());
//     //             scatters2.yScale(y2Axis.scale());

//     //             lines1.xDomain(xAxis.domain());
//     //             historicalBars.xDomain(xAxis.domain());

//     //             lines2.xDomain(xAxis.domain());
//     //             scatters1.xDomain(xAxis.domain());
//     //             scatters2.xDomain(xAxis.domain());
              
//     //             lines1.xScale(xAxis.scale());
//     //             historicalBars.xScale(xAxis.scale());

//     //             lines2.xScale(xAxis.scale());
//     //             scatters1.xScale(xAxis.scale());
//     //             scatters2.xScale(xAxis.scale());
//     //         }

      

//     //         //if (focusEnable) {
//     //             // Setup Brush
//     //         brush
//     //             .x(x2)
//     //             .on('brush', function (e) {
//     //                 //When brushing, turn off transitions because chart needs to change immediately.
//     //                 var oldTransition = chart.duration();
//     //                 chart.duration(0);
//     //                 onBrush();
//     //                 chart.duration(oldTransition);
//     //             });

//     //         if (brushExtent) brush.extent(brushExtent);

//     //         var brushBG = g.select('.nv-context .nv-brushBackground').selectAll('g')
//     //             .data([brushExtent || brush.extent()])

//     //         var brushBGenter = brushBG.enter()
//     //             .append('g');

//     //         brushBGenter.append('rect')
//     //             .attr('class', 'left')
//     //             .attr('x', 0)
//     //             .attr('y', 0)
//     //             .attr('height', availableHeight2);

//     //         brushBGenter.append('rect')
//     //             .attr('class', 'right')
//     //             .attr('x', 0)
//     //             .attr('y', 0)
//     //             .attr('height', availableHeight2);

//     //         var gBrush = g.select('.nv-context .nv-x.nv-brush')
//     //             .call(brush);
//     //         gBrush.selectAll('rect').each(function () {
//     //             d3.select(this).attr('height', availableHeight2);
//     //             if (timelineGreyBackground) {
//     //                 var className = d3.select(this).attr('class');
//     //                 if (className == "background") {
//     //                     d3.select(this).attr('class', "background grey-timeline");
//     //                 }
//     //                 else if (className == "extent") {
//     //                     d3.select(this).attr('class', "extent grey-timeline-extent");
//     //                     d3.select(this).attr('style', "fill-opacity: .65 !important");

//     //                 }
//     //             }
//     //         });
//     //         gBrush.selectAll('.resize').append('path').attr('d', resizePath);
           
//     //         onBrush();

//     //         if (thinHistoricalBars) {
//     //             g.selectAll('.nv-context .nv-historicalBarsWrap .nv-bars rect').each(function (a) {
//     //                 d3.select(this).attr('width', '2');
//     //                 d3.select(this).transition().attr('transform', 'translate(' + x(a.x) + ', 0)');
//     //             });
//     //         }

//     //         multibar.dispatch.on('renderEnd', function (e) {
//     //             resizeMultibar();
//     //         });
//     //         //============================================================
//     //         // Event Handling/Dispatching (in chart's scope)
//     //         //------------------------------------------------------------
           
           
//     //         legend.dispatch.on('stateChange', function (newState) {
//     //             for (var key in newState)
//     //                 state[key] = newState[key];
//     //             dispatch.stateChange(state);
//     //             chart.update();
//     //         });

//     //         controls.dispatch.on('legendClick', function (d, i) {
//     //             if (!showControls) return;
//     //             if (!d.disabled) return;
//     //             controlsData = controlsData.map(function (s) {
//     //                 s.disabled = true;
//     //                 return s;
//     //             });
//     //             d.disabled = false;
//     //             groupedState = d.key;
//     //             switch (d.key) {
//     //                 case 'Grouped': case controlLabels.grouped:

//     //                     multibar.stacked(false);
//     //                     if (focusEnable) {
//     //                         multibarContext.stacked(false);
//     //                     }
//     //                     break;
//     //                 case 'Stacked': case controlLabels.stacked:

//     //                     multibar.stacked(true);
//     //                     if (focusEnable) {
//     //                         multibarContext.stacked(true);
//     //                     }
//     //                     break;
//     //             }

//     //             state.stacked = multibar.stacked();
//     //             dispatch.stateChange(state);

//     //             chart.update();
//     //         });

//     //         planControls.dispatch.on('legendClick', function (d, i) {
//     //             if (!enablePlanAxis) return;
//     //             if (d.key == "Show Plans") {
//     //                 showPlanAxis = !showPlanAxis;
//     //                 //if unchecked then remove the plan axis svg
//     //                 if (!showPlanAxis) {
//     //                     g.selectAll(".nv-x2.nv-axis-plan").remove();
//     //                 }
//     //                 //if checking, then add the plan axis SVG back into dom
//     //                 else {
//     //                     g.select('g .nv-focus').insert('g', ':first-child').attr('class', 'nv-x2 nv-axis nv-axis-plan');
//     //                 }
//     //             }
//     //             chart.update();
//     //         });
            

//     //         dispatch.on('tooltipShow', function (e) {
//     //             if (tooltips) showTooltip(e, that.parentNode)
//     //         });

//     //         dispatch.on('tooltipHide', function () {
//     //             if (tooltips) nv.tooltip.cleanup();
//     //         });

//     //         // Update chart from a state object passed to event handler
//     //         dispatch.on('changeState', function (e) {
//     //             if (dataBars.length <= 0) return;

//     //             if (typeof e.disabled !== 'undefined') {
//     //                 data.forEach(function (series, i) {
//     //                     series.disabled = e.disabled[i];
//     //                 });

//     //                 state.disabled = e.disabled;
//     //             }

//     //             if (typeof e.stacked !== 'undefined') {
//     //                 multibar.stacked(e.stacked);
//     //                 if (focusEnable) {
//     //                     multibarContext.stacked(e.stacked);
//     //                 }
//     //                 state.stacked = e.stacked;
//     //                 stacked = e.stacked;
//     //             }

//     //             chart.update();
//     //         });

//     //         //============================================================
//     //         // Functions
//     //         //------------------------------------------------------------

//     //         // Taken from crossfilter (http://square.github.com/crossfilter/)
//     //         function resizePath(d) {
//     //             var e = +(d == 'e'),
//     //                 x = e ? 1 : -1,
//     //                 y = availableHeight2 / 3;
//     //             return 'M' + (.5 * x) + ',' + y
//     //                 + 'A6,6 0 0 ' + e + ' ' + (6.5 * x) + ',' + (y + 6)
//     //                 + 'V' + (2 * y - 6)
//     //                 + 'A6,6 0 0 ' + e + ' ' + (.5 * x) + ',' + (2 * y)
//     //                 + 'Z'
//     //                 + 'M' + (2.5 * x) + ',' + (y + 8)
//     //                 + 'V' + (2 * y - 8)
//     //                 + 'M' + (4.5 * x) + ',' + (y + 8)
//     //                 + 'V' + (2 * y - 8);
//     //         }


//     //         function updateBrushBG() {
//     //             if (!brush.empty()) brush.extent(brushExtent);
//     //             brushBG
//     //                 .data([brush.empty() ? x2.domain() : brushExtent])
//     //                 .each(function (d, i) {
//     //                     var leftWidth = x2(d[0]) - x2.range()[0],
//     //                         rightWidth = x2.range()[1] - x2(d[1]);

//     //                     d3.select(this).select('.left')
//     //                         .attr('width', leftWidth < 0 ? 0 : leftWidth);

//     //                     d3.select(this).select('.right')
//     //                         .attr('x', x2(d[1]))
//     //                         .attr('width', rightWidth < 0 ? 0 : rightWidth);
//     //                 });
//     //         }

//     //         function setupMainChartObjects() {
//     //             multibar
//     //                 .width(availableWidth)
//     //                 .height(chartAreaHeight)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'bar' && data[i].yAxis == 1 }));
//     //             lines1
//     //                .width(availableWidth)
//     //                .height(chartAreaHeight)
//     //                .interpolate(interpolate)
//     //                .color(data.map(function (d, i) {
//     //                    return d.color || color(d, i);
//     //                }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'line' && data[i].yAxis == 1 }));
//     //             historicalBars
//     //                 .width(availableWidth)
//     //                 .height(chartAreaHeight)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'hist-bar' && data[i].yAxis == 1 }));
//     //             lines2
//     //                 .width(availableWidth)
//     //                 .height(chartAreaHeight)
//     //                 .interpolate(interpolate2)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'line' && data[i].yAxis == 2 }));
//     //             scatters1
//     //                 .width(availableWidth)
//     //                 .height(chartAreaHeight)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'scatter' && data[i].yAxis == 1 }));
//     //             scatters2
//     //                 .width(availableWidth)
//     //                 .height(chartAreaHeight)
//     //                 .color(data.map(function (d, i) {
//     //                     return d.color || color(d, i);
//     //                 }).filter(function (d, i) { return !data[i].disabled && data[i].type == 'scatter' && data[i].yAxis == 2 }));
//     //             //transform if plan x axis is shown
//     //             if (showPlanAxis) {
//     //                 setWrapTransforms(planAxisHeight);
//     //             }
//     //             else {
//     //                 setWrapTransforms(0);
//     //             }
//     //         }

//     //         function setWrapTransforms(value) {
//     //             g.select('.nv-focus .nv-lines1Wrap').attr('transform', 'translate(0,' + value + ')');
//     //             g.select('.nv-focus .nv-lines2Wrap').attr('transform', 'translate(0,' + value + ')');
//     //             g.select('.nv-focus .nv-scatters1Wrap').attr('transform', 'translate(0,' + value + ')');
//     //             g.select('.nv-focus .nv-scatters2Wrap').attr('transform', 'translate(0,' + value + ')');
//     //             g.select('.nv-focus .nv-historicalBarsWrap').attr('transform', 'translate(0,' + value + ')');
//     //             g.select('.nv-focus .nv-barsWrap').attr('transform', 'translate(0,' + value + ')');
//     //         }

//     //         function onBrush() {
//     //             brushExtent = brush.empty() ? null : brush.extent();
//     //             var extent = brush.empty() ? x2.domain() : brush.extent();

//     //             dispatch.brush({ extent: extent, brush: brush });

//     //             updateBrushBG();

//     //             y1min = undefined;
//     //             y1max = undefined;
//     //             y2min = undefined;
//     //             y2max = undefined;
//     //             //set domains and axis and then call setupAxis
//     //             if (stackBars && dataBars) {
//     //             }
//     //             else {
//     //                 //// y values based off brushed x values
//     //                 data.forEach(function (d) {
//     //                     if (d.yAxis === 1) {
//     //                         d.values.forEach(function (value) {
//     //                             if (value.x >= extent[0] && value.x <= extent[1]) {
//     //                                 if (typeof y1max == 'undefined' || value.y > y1max) {
//     //                                     y1max = value.y;
//     //                                 }
//     //                                 if (typeof y1min == 'undefined' || value.y < y1min) {
//     //                                     y1min = value.y;
//     //                                 }
//     //                             }
//     //                         })
//     //                     } else if (d.yAxis === 2) {
//     //                         d.values.forEach(function (value) {
//     //                             if (value.x >= extent[0] && value.x <= extent[1]) {
//     //                                 if (typeof y2max == 'undefined' || value.y > y2max) {
//     //                                     y2max = value.y;
//     //                                 }
//     //                                 if (typeof y2min == 'undefined' || value.y < y2min) {
//     //                                     y2min = value.y;
//     //                                 }
//     //                             }
//     //                         })
//     //                     }
//     //                 });
//     //             }
                
//     //             ////add 5% to y min to account for buffer from bottom of graph
//     //             //var bufferPercent = .05;
//     //             ////find the range
//     //             //var rangey1 = y1max - y1min;
//     //             //var rangey2 = y2max - y2min;
//     //             ////get 5% of range and subtract from existing y min
//     //             //var y1min = y1min - (rangey1 * bufferPercent);
//     //             //var y2min = y2min - (rangey2 * bufferPercent);

                    
//     //             // If the brush selects no data, show the entire graph
//     //             if (brush.extent() == [0, 0]) {
//     //                 extent[0] = x2Axis.domain()[0];
//     //                 extent[1] = x2Axis.domain()[1];
//     //                 y1min = 0;
//     //                 y1max = chartAreaHeight;
//     //                 y2min = 0;
//     //                 y2max = chartAreaHeight;
//     //             }
//     //             updatePlanTickValues(extent[0], extent[1]);
                
//     //             //setupMainChartObjects();
//     //             // Filter the main focus chart based on extents in the context timeline
//     //             //filter bar chart
//     //             dataBarsFiltered = dataBars
//     //                 .filter(function (d) { return !d.disabled })
//     //                 .map(function (d, i) {
//     //                     return {
//     //                         key: d.key,
//     //                         area: d.area,
//     //                         classed: d.classed,
//     //                         values: dataBarsCF[d.key].filterAll().filter([extent[0], extent[1]]).bottom(Infinity)
//     //                     }
//     //                 });

//     //             var focusBarsWrap = g.select('.nv-focus .nv-barsWrap')
//     //                 .datum(!dataBars.length ? [{ values: [] }] : dataBarsFiltered);

//     //             //filter lines 1 data
//     //             var focusLines1Wrap = g.select('.nv-focus .nv-lines1Wrap')
//     //                 .datum(!dataLines1.length ? [{values:[]}] :
//     //                     dataLines1
//     //                         .filter(function (d) { return !d.disabled })
//     //                         .map(function (d, i) {
//     //                             return {
//     //                                 key: d.key,
//     //                                 fillOpacity: d.fillOpacity,
//     //                                 area: d.area,
//     //                                 classed: d.classed,
//     //                                 values: dataLinesCF[d.key].filterAll().filter([extent[0], extent[1]]).bottom(Infinity)
//     //                             }
//     //                         })
//     //             );

//     //             //filter hist bars data
//     //             var focushistoricalBarsWrap = g.select('.nv-focus .nv-historicalBarsWrap')
//     //                 .datum(!dataHistoricalBars.length ? [{ values: [] }] :
//     //                     dataHistoricalBars
//     //                     .map(function (d, i) {
//     //                         if (d.disabled) {
//     //                             return {
//     //                                 disabled: d.disabled,
//     //                                 key: d.key,
//     //                                 type: d.type,
//     //                                 values: [],
//     //                                 yAxis: d.yAxis
//     //                             }
//     //                         } else {
//     //                             return {
//     //                                 key: d.key,
//     //                                 fillOpacity: d.fillOpacity,
//     //                                 area: d.area,
//     //                                 classed: d.classed,
//     //                                 values: dataHistoricalBarsCF[d.key].filterAll().filter([extent[0], extent[1]]).bottom(Infinity)
//     //                             }
//     //                         }
//     //                     })
//     //                 );

//     //             //filter lines 2
//     //             var focusLines2Wrap = g.select('.nv-focus .nv-lines2Wrap')
//     //                 .datum(!dataLines2.length ? [{ values: [] }] :
//     //                     dataLines2
//     //                         .filter(function (d) { return !d.disabled })
//     //                         .map(function (d, i) {
//     //                             return {
//     //                                 key: d.key,
//     //                                 area: d.area,
//     //                                 classed: d.classed,
//     //                                 fillOpacity: d.fillOpacity,
//     //                                 values: dataLines2CF[d.key].filterAll().filter([extent[0], extent[1]]).bottom(Infinity)
//     //                             }
//     //                         })
//     //             );
                

//     //             //filter scatter 1
//     //             var focusScatters1Wrap = g.select('.nv-focus .nv-scatters1Wrap')
//     //                 .datum(!dataScatters1.length ? [{ values: [] }] :
//     //                     dataScatters1
//     //                         .filter(function (d) { return !d.disabled })
//     //                         .map(function (d, i) {
//     //                             return {
//     //                                 key: d.key,
//     //                                 area: d.area,
//     //                                 classed: d.classed,
//     //                                 values: dataScatters1CF[d.key].filterAll().filter([extent[0], extent[1]]).bottom(Infinity)
//     //                             }
//     //                         })
//     //             );
                

//     //             //filter scatter 2
//     //             var focusScatters2Wrap = g.select('.nv-focus .nv-scatters2Wrap')
//     //                 .datum(!dataScatters2.length ? [{ values: [] }] :
//     //                     dataScatters2
//     //                         .filter(function (d) { return !d.disabled })
//     //                         .map(function (d, i) {
//     //                             return {
//     //                                 key: d.key,
//     //                                 area: d.area,
//     //                                 classed: d.classed,
//     //                                 values: dataScatters2CF[d.key].filterAll().filter([extent[0], extent[1]]).bottom(Infinity)
//     //                             }
//     //                         })
//     //             );
                
//     //             //OK so, alot of our data has big gaps (maybe 4-5 minute gaps in X axis)
//     //             //with timeline, user can zoom in to a very low range (which is OK)
//     //             //but if they zoom in too far, we may not have but one or two data points so there is only half a graph (only applies to Lines)
//     //             //and it looks weird. So I'm finding our filtered data and adding a datapoint at the extent point if it doesnt exist, 
//     //             //so it will finish the line graph across
//     //             focusLines1Wrap.data()[0].forEach(function (key) {
//     //                 if (key && key.values.length > 0)
//     //                     extendDataPoints(key.values, extent);
//     //             });
//     //             focusLines2Wrap.data()[0].forEach(function (key) {
//     //                 if (key && key.values.length > 0)
//     //                     extendDataPoints(key.values, extent);
//     //             });   


//     //             //special stuff for stacked multibars
//     //             //Basically in order to get the correct domain for Y axis we need to "stack" our data here
//     //             //This code is associating all data points relevent to their X axis (stacking) and then finding the sum of all those stacked values.
//     //             //the max of sums is the highest range and will be y1max
//     //             if (stackBars && dataBars) {
//     //                 y1min = 0;

//     //                 var seriesData = dataBars
//     //                     .filter(function (d) { return !d.disabled })
//     //                     .map(function (d) {
//     //                     return d.values.map(function (d, i) {
//     //                         return { x: getX(d, i), y: getY(d, i), y0: d.y0, y1: d.y1 }
//     //                     })
//     //                 });
//     //                 var dataPerX = {};
//     //                 dataBarsFiltered.forEach(function (d) {
//     //                     for (var z = 0; z < d.values.length; z++) {
//     //                         var xDate = new Date(d.values[z].x).getTime();
//     //                         if (!dataPerX[xDate]) {
//     //                             dataPerX[xDate] = [];
//     //                         }
//     //                         dataPerX[xDate].push(d.values[z].y);                          
//     //                     }
                            
//     //                 });

//     //                 var stackedData = Object.keys(dataPerX);

//     //                 for (var key in stackedData) {
//     //                     var xDatekey = stackedData[key];
//     //                     var xStackedData = dataPerX[xDatekey];

//     //                     var y = 0;
//     //                     xStackedData.forEach(function (value) {
//     //                         y += value;
//     //                     });
//     //                     if (xDatekey >= extent[0] && xDatekey <= extent[1]) {
//     //                         if (typeof y1max == 'undefined' || y > y1max) {
//     //                             y1max = y;
//     //                         }
//     //                     }
//     //                 }
//     //             }

//     //             if (forceY1min != -1) {
//     //                 y1min = forceY1min;
//     //             }
//     //             if (forceY2min != -1) {
//     //                 y2min = forceY2min;
//     //             }

//     //             if (y1max == 0) {
//     //                 y1max = 1;
//     //             }
//     //             if (y2max == 0) {
//     //                 y2max = 1;
//     //             }

//     //             yAxis.domain([Math.floor(y1min), Math.ceil(y1max)]);
//     //             y2Axis.domain([Math.floor(y2min), Math.ceil(y2max)]);
//     //             xAxis.domain([extent[0], extent[1]]);
//     //             x.domain([extent[0], extent[1]]);
//     //             xPlanAxis.domain([extent[0], extent[1]]);
//     //             xPlanAxis.range([extent[0], extent[1]]);
//     //             x.range([extent[0], extent[1]]);

//     //             updateDomains();
//     //             if (useInteractiveGuideline) {
//     //                 g.select(".nv-interactive").call(interactiveLayer);
//     //             }

//     //             //update the chart
//     //             focusBarsWrap.transition().call(multibar);
//     //             focusLines1Wrap.transition().call(lines1);

//     //             focushistoricalBarsWrap.transition().call(historicalBars);              
               
//     //             focusLines2Wrap.transition().call(lines2);
//     //             focusScatters1Wrap.transition().call(scatters1);
//     //             focusScatters2Wrap.transition().call(scatters2);
//     //             //d3.transition(focusStack1Wrap).call(stack1);
//     //             setupAxis();

//     //             if (thinHistoricalBars) {
//     //                 //set thin bars and scale accordingly
//     //                 g.selectAll('.nv-focus .nv-historicalBarsWrap .nv-bars rect').each(function (a) {
//     //                     d3.select(this).attr('width', '2');
//     //                     d3.select(this).transition().attr('transform', 'translate(' + x(a.x) + ', 0)');
//     //                 });
//     //             }
//     //         }

//     //         function resizeMultibar() {
//     //             if (!stackBars || !dataBarsFiltered || disableStackBarResizing) return;

//     //             var multibarWidths = {};

//     //             dataBarsFiltered.forEach(function (d) {
//     //                 //sort
//     //                 for (var z = 0; z < d.values.length; z++) {

//     //                     if (d.values[z].x instanceof Date) {
//     //                         var xDate = new Date(d.values[z].x).getTime();
//     //                         //get time distance between this value and the next
//     //                         if (z + 1 < d.values.length) {
//     //                             var nextDate = new Date(d.values[z + 1].x).getTime();
//     //                             var width = x(nextDate) - x(xDate);
//     //                             if (!multibarWidths[xDate]) {
//     //                                 multibarWidths[xDate] = [];
//     //                                 multibarWidths[xDate].push({
//     //                                     width: width
//     //                                 });
//     //                             }
//     //                         }
//     //                     }
//     //                     else {
//     //                         multibarWidths[d.values[z].x] = [];
//     //                         multibarWidths[d.values[z].x].push({
//     //                             width: availableWidth / d.values.length 
//     //                         });
//     //                     }
//     //                 }
//     //             });

//     //             var focusBars = g.selectAll('.nv-focus .nv-barsWrap .nv-bar');
//     //             var contextBars = g.selectAll('.nv-context .nv-barsWrap .nv-bar');
//     //             var bars = focusBars[0].concat(contextBars[0]);

//     //             if (bars) {
//     //                 bars.forEach(function (d, z) {
//     //                     var svg = d3.select(d);
//     //                     var width = 0;
//     //                     if (d.__data__.x instanceof Date) {
//     //                         if (multibarWidths[new Date(d.__data__.x).getTime()] && multibarWidths[new Date(d.__data__.x).getTime()][0]) {
//     //                             width = multibarWidths[new Date(d.__data__.x).getTime()][0].width;
//     //                         }
//     //                     }
//     //                     else {
//     //                         if (multibarWidths[d.__data__.x] && multibarWidths[d.__data__.x][0]) {
//     //                             width = multibarWidths[d.__data__.x][0].width;
//     //                         }
//     //                     }

//     //                     svg.attr('width', width);
//     //                     //svg.attr('x', x(d.__data__.x));
//     //                 });
//     //             }
             
//     //         }
            
//     //         //goes through values array and will add a data point at the extent min/max if it doesn't exist
//     //         function extendDataPoints(values, extent) {
//     //             if (!values || values.length <= 0 ||
//     //                 !extent || extent.length <= 0)
//     //                 return;

//     //             var first = values[0];
//     //             var last = values[values.length - 1];

//     //             if (first.x > extent[0]) {
//     //                 values.unshift({
//     //                     x: new Date(extent[0]),
//     //                     y: first.y,
//     //                 });
//     //             }
//     //             if (last.x < extent[1]) {
//     //                 values.push({
//     //                     x: new Date(extent[1]),
//     //                     y: last.y
//     //                 });
//     //             }
//     //         }
            
//     //         //============================================================
//     //         // Event Handling/Dispatching (out of chart's scope)
//     //         //------------------------------------------------------------

//     //         var delay_hover = function (elem, callback) {
//     //             var timeout = null;
//     //             elem.dispatch.on('elementMouseover.tooltip', function (evt) {
//     //                 // Set timeout to be a timer which will invoke callback after 100ms
//     //                 var d3Event = d3.event;
//     //                 timeout = setTimeout(function () {
//     //                     d3.event = d3Event;
//     //                     callback(evt);
//     //                 }, 100);
//     //             });

//     //             elem.dispatch.on('elementMouseout.tooltip', function (evt) {
//     //                 // Clear any timers set to timeout
//     //                 clearTimeout(timeout);
//     //                 tooltip.hidden(true);
//     //             });
//     //         };

//     //         //setup mouseover events.
//     //         //TODO: Support the interactive guideline functionality
//     //         if (useInteractiveGuideline) {
//     //             interactiveLayer.dispatch.on('elementMousemove', function (e) {
//     //                 clearHighlights();
//     //                 var singlePoint, pointIndex, pointXLocation, allData = [], planNumber = 0;
//     //                 data
//     //                     .filter(function (series, i) {
//     //                         series.seriesIndex = i;
//     //                         return !series.disabled && !series.hideTooltip;
//     //                     })
//     //                     .forEach(function (series, i) {
//     //                         var extent = x.domain();
//     //                         var currentValues = series.values.filter(function (d, i) {
//     //                             return chart.x()(d, i) >= extent[0] && chart.x()(d, i) <= extent[1];
//     //                         });

//     //                         pointIndex = nv.interactiveBisect(currentValues, e.pointXValue, chart.x());
//     //                         var point = currentValues[pointIndex];
//     //                         if (point === undefined) return;

//     //                         if (point.planNumber)
//     //                             planNumber = point.planNumber;

//     //                         var pointYValue = chart.y()(point, pointIndex);
//     //                         if (pointYValue !== null) {
//     //                             highlightPoint(i, pointIndex, true);
//     //                         }
//     //                         if (singlePoint === undefined) singlePoint = point;
//     //                         if (pointXLocation === undefined) pointXLocation = x(chart.x()(point, pointIndex));
//     //                         allData.push({
//     //                             key: series.key,
//     //                             value: pointYValue,
//     //                             color: color(series, series.seriesIndex),
//     //                             data: point,
//     //                             yAxis: series.yAxis == 2 ? y2Axis : yAxis
//     //                         });
//     //                     });
//     //                 if (showPlanAxis) {
//     //                     allData.push({
//     //                         key: "Plan",
//     //                         value: planNumber,
//     //                         yAxis: yAxis
//     //                     });
//     //                 }

//     //                 var defaultValueFormatter = function (d, i) {
//     //                     var yAxis = allData[i].yAxis;
//     //                     return d == null ? "N/A" : yAxis.tickFormat()(d);
//     //                 };

//     //                 interactiveLayer.tooltip
//     //                     .headerFormatter(function (d, i) {
//     //                         return xAxis.tickFormat()(d, i);
//     //                     })
//     //                     .valueFormatter(interactiveLayer.tooltip.valueFormatter() || defaultValueFormatter)
//     //                     .data({
//     //                         value: chart.x()(singlePoint, pointIndex),
//     //                         index: pointIndex,
//     //                         series: allData
//     //                     })();

//     //                 interactiveLayer.renderGuideLine(pointXLocation);
//     //             });

//     //             interactiveLayer.dispatch.on("elementMouseout", function (e) {
//     //                 tooltip.hidden(true);
//     //                 interactiveLayer.tooltip.hidden(true);
//     //                 clearHighlights();
//     //             });
//     //         }
//     //         else {
//     //             delay_hover(scatters1, mouseover_scatter);
//     //             delay_hover(scatters2, mouseover_scatter);
//     //             delay_hover(lines1, mouseover_line);
//     //             delay_hover(lines2, mouseover_line);
//     //             delay_hover(historicalBars, mouseover_bars);
//     //             delay_hover(multibar, mouseover_multibar);
//     //         }

//     //         function mouseover_multibar(evt) {
//     //             evt.value = chart.x()(evt.data);
//     //             evt['series'] = {
//     //                 key: evt.data.key,
//     //                 value: chart.y()(evt.data),
//     //                 color: evt.color
//     //             };
//     //             tooltip.data(evt).hidden(false);
//     //         }

//     //         function mouseover_bars(evt) {
//     //             evt.value = evt.data.x;
//     //             evt.series = {
//     //                 value: evt.data.x,
//     //                 color: evt.color,
//     //             };
//     //             tooltip
//     //                 .duration(0)
//     //                 .headerFormatter(function (d, i) {
//     //                     return xAxis.tickFormat()(d, i);
//     //                 })
//     //                 .data(evt)
//     //                 .hidden(false);
//     //         }


//     //         //handle mouse over for line chart
//     //         function mouseover_line(evt) {
//     //             var yaxis = evt.series.yAxis === 2 ? y2Axis : yAxis;
//     //             evt.value = evt.point.x;
//     //             evt.series = {
//     //                 value: evt.point.y,
//     //                 color: evt.point.color,
//     //                 key: evt.series.key
//     //             };
//     //             tooltip
//     //                 .duration(0)
//     //                 .headerFormatter(function (d, i) {
//     //                     return xAxis.tickFormat()(d, i);
//     //                 })
//     //                 .valueFormatter(function (d, i) {
//     //                     return yaxis.tickFormat()(d, i);
//     //                 })
//     //                 .data(evt)
//     //                 .hidden(false);
//     //         }

//     //         //handle mouse over for scatters
//     //         function mouseover_scatter(evt) {
//     //             var yaxis = evt.series.yAxis === 2 ? y2Axis : yAxis;
//     //             evt.value = evt.point.x;
//     //             evt.series = {
//     //                 value: evt.point.y,
//     //                 color: evt.point.color,
//     //                 key: evt.series.key
//     //             };
//     //             tooltip
//     //                 .duration(100)
//     //                 .headerFormatter(function (d, i) {
//     //                     return xAxis.tickFormat()(d, i);
//     //                 })
//     //                 .valueFormatter(function (d, i) {
//     //                     return yaxis.tickFormat()(d, i);
//     //                 })
//     //                 .data(evt)
//     //                 .hidden(false);
//     //         }

//     //         //handle mouse over for stacks
//     //         function mouseover_stack(evt) {
//     //             var yaxis = evt.series.yAxis === 2 ? y2Axis : yAxis;
//     //             evt.point['x'] = stack1.x()(evt.point);
//     //             evt.point['y'] = stack1.y()(evt.point);
//     //             tooltip
//     //                 .duration(0)
//     //                 .headerFormatter(function (d, i) {
//     //                     return xAxis.tickFormat()(d, i);
//     //                 })
//     //                 .valueFormatter(function (d, i) {
//     //                     return yaxis.tickFormat()(d, i);
//     //                 })
//     //                 .data(evt)
//     //                 .hidden(false);
//     //         }

//     //         function clearHighlights() {
//     //             for (var i = 0, il = charts.length; i < il; i++) {
//     //                 var chart = charts[i];
//     //                 try {
//     //                     chart.clearHighlights();
//     //                 } catch (e) { }
//     //             }
//     //         }

//     //         function highlightPoint(serieIndex, pointIndex, b) {
//     //             for (var i = 0, il = charts.length; i < il; i++) {
//     //                 var chart = charts[i];
//     //                 try {
//     //                     chart.highlightPoint(serieIndex, pointIndex, b);
//     //                 } catch (e) { }
//     //             }
//     //         }
//     //     });
//     //     return chart;
//     // }

//     // //============================================================
//     // // Expose Public Variables
//     // //------------------------------------------------------------

//     // // expose chart's sub-components
//     // chart.dispatch = dispatch;
//     // chart.multibar = multibar;
//     // chart.multibarContext = multibarContext;
//     // chart.legend = legend;
//     // chart.xPlanAxis = xPlanAxis;
//     // chart.planData = planData;
//     // chart.planLabelCallback = planLabelCallback;
//     // chart.showPlanAxis = showPlanAxis;
//     // chart.xAxis = xAxis;
//     // chart.yAxis = yAxis;
//     // chart.state = state;
//     // chart.tooltip = tooltip;
//     // chart.x2Axis = x2Axis;
//     // chart.y2Axis = y2Axis;
//     // chart.lines1 = lines1;
//     // chart.lines2 = lines2;
//     // chart.scatters1 = scatters1;
//     // chart.scatters1Context = scatters1Context;
//     // chart.scatters2 = scatters2;
//     // chart.historicalBars = historicalBars;
//     // chart.historicalBarsContext = historicalBarsContext;
//     // //chart.stack1 = stack1;
//     // chart.interactiveLayer = interactiveLayer;

//     // chart.options = nv.utils.optionsFunc.bind(chart);

//     // chart._options = Object.create({}, {
//     //     // simple options, just get/set the necessary values
//     //     width: { get: function () { return width; }, set: function (_) { width = _; } },
//     //     height: { get: function () { return height; }, set: function (_) { height = _; } },
//     //     focusHeight: { get: function () { return height2; }, set: function (_) { height2 = _; } },
//     //     showLegend: { get: function () { return showLegend; }, set: function (_) { showLegend = _; } },
//     //     brushExtent: { get: function () { return brushExtent; }, set: function (_) { brushExtent = _; } },
//     //     showControls: { get: function () { return showControls; }, set: function (_) { showControls = _; } },
//     //     controlLabels: { get: function () { return controlLabels; }, set: function (_) { controlLabels = _; } },
//     //     showXAxis: { get: function () { return showXAxis; }, set: function (_) { showXAxis = _; } },
//     //     showYAxis: { get: function () { return showYAxis; }, set: function (_) { showYAxis = _; } },
//     //     enablePlanAxis: { get: function () { return enablePlanAxis; }, set: function (_) { enablePlanAxis = _; showPlanAxis = _; } },
//     //     showPlanAxis: { get: function () { return showPlanAxis; }, set: function (_) { showPlanAxis = _; } },
//     //     xPlanAxis: { get: function () { return xPlanAxis; }, set: function (_) { xPlanAxis = _; } },
//     //     planLabelCallback: { get: function () { return planLabelCallback; }, set: function (_) { planLabelCallback = _; } },
//     //     planData: { get: function () { return planData; }, set: function (_) { planData = _; } },
//     //     tooltips: { get: function () { return tooltips; }, set: function (_) { tooltips = _; } },
//     //     tooltipContent: { get: function () { return tooltip; }, set: function (_) { tooltip = _; } },
//     //     defaultState: { get: function () { return defaultState; }, set: function (_) { defaultState = _; } },
//     //     noData: { get: function () { return noData; }, set: function (_) { noData = _; } },
//     //     reduceXTicks: { get: function () { return reduceXTicks; }, set: function (_) { reduceXTicks = _; } },
//     //     rotateLabels: { get: function () { return rotateLabels; }, set: function (_) { rotateLabels = _; } },
//     //     staggerLabels: { get: function () { return staggerLabels; }, set: function (_) { staggerLabels = _; } },
//     //     focusEnable: { get: function () { return focusEnable; }, set: function (_) { focusEnable = _; } },
//     //     focusShowAxisX: { get: function () { return focusShowAxisX; }, set: function (_) { focusShowAxisX = _; } },
//     //     focusShowAxisY: { get: function () { return focusShowAxisY; }, set: function (_) { focusShowAxisY = _; } },
//     //     stackBars: { get: function () { return stackBars; }, set: function (_) { stackBars = _; } },
//     //     disableLegendClick: { get: function () { return disableLegendClick; }, set: function (_) { disableLegendClick = _; } },
//     //     disableStackBarResizing: { get: function () { return disableStackBarResizing; }, set: function (_) { disableStackBarResizing = _; } },
//     //     interpolate: { get: function () { return interpolate; }, set: function (_) { interpolate = _; } },
//     //     interpolate2: { get: function () { return interpolate2; }, set: function (_) { interpolate2 = _; } },
//     //     groupSpacing: { get: function () { return groupSpacing; }, set: function (_) { groupSpacing = _; } },
//     //     forceY1min: { get: function () { return forceY1min; }, set: function (_) { forceY1min = _; } },
//     //     forceY2min: { get: function () { return forceY2min; }, set: function (_) { forceY2min = _; } },
//     //     timelineHideScatters1: { get: function () { return timelineHideScatters1; }, set: function (_) { timelineHideScatters1 = _; } },
//     //     timelineGreyBackground: { get: function () { return timelineGreyBackground; }, set: function (_) { timelineGreyBackground = _; } },
//     //     thinHistoricalBars: { get: function () { return thinHistoricalBars; }, set: function (_) { thinHistoricalBars = _; } },
//     //     // options that require extra logic in the setter
//     //     margin: {
//     //         get: function () { return margin; }, set: function (_) {
//     //             margin.top = _.top !== undefined ? _.top : margin.top;
//     //             margin.right = _.right !== undefined ? _.right : margin.right;
//     //             margin.bottom = _.bottom !== undefined ? _.bottom : margin.bottom;
//     //             margin.left = _.left !== undefined ? _.left : margin.left;
//     //         }
//     //     },
//     //     duration: {get: function(){return duration;}, set: function(_) {
//     //         duration = _;
//     //         [lines1, lines2, multibar, scatters1, scatters1Context, scatters2, xAxis, yAxis, y2Axis].forEach(function(model){
//     //             model.duration(duration);
//     //         });
//     //     }},
//     //     color: {
//     //         get: function () { return color; }, set: function (_) {
//     //             color = nv.utils.getColor(_);
//     //             legend.color(color);
//     //         }
//     //     },
//     //     rightAlignYAxis: {
//     //         get: function () { return rightAlignYAxis; }, set: function (_) {
//     //             rightAlignYAxis = _;
//     //             yAxis.orient(rightAlignYAxis ? 'right' : 'left');
//     //         }
//     //     },
//     //     xTickFormat: {
//     //         get: function () { return xAxis.xTickFormat(); }, set: function (_) {
//     //             xAxis.xTickFormat(_);
//     //             x2Axis.xTickFormat(_);
//     //         }
//     //     },
//     //     yTickFormat: {
//     //         get: function () { return yAxis.yTickFormat(); }, set: function (_) {
//     //             yAxis.yTickFormat(_);
//     //             y2Axis.yTickFormat(_);
//     //         }
//     //     },
//     //     x: {
//     //         get: function () { return getX; }, set: function (_) {
//     //             getX = _;
//     //             lines1.x(_);
//     //             lines2.x(_);
//     //             scatters1.x(_);
//     //             scatters1Context.x(_);
//     //             scatters2.x(_);
//     //             bars1.x(_);
//     //             bars2.x(_);
//     //             historicalBars.x(_);
//     //             //stack1.x(_);
//     //             //stack2.x(_);
//     //         }
//     //     },
//     //     y: {
//     //         get: function () { return getY; }, set: function (_) {
//     //             getY = _;
//     //             lines1.y(_);
//     //             lines2.y(_);
//     //             scatters1.y(_);
//     //             scatters1Context.y(_);
//     //             scatters2.y(_);
//     //             historicalBars.y(_);
//     //             //stack1.y(_);
//     //             //stack2.y(_);
//     //             multibar.y(_);
//     //             //bars2.y(_);
//     //         }
//     //     },
//     //     useVoronoi: {
//     //         get: function () { return useVoronoi; }, set: function (_) {
//     //             useVoronoi = _;
//     //             lines1.useVoronoi(_);
//     //             lines2.useVoronoi(_);
//     //             scatters1.useVoronoi(_);
//     //             scatters2.useVoronoi(_);
//     //             //stack1.useVoronoi(_);
//     //             //stack2.useVoronoi(_);
//     //         }
//     //     },

//     //     useInteractiveGuideline: {
//     //         get: function () { return useInteractiveGuideline; }, set: function (_) {
//     //             useInteractiveGuideline = _;
//     //             lines1.interactive(false);
//     //             lines1.useVoronoi(false);
//     //             lines2.interactive(false);
//     //             lines2.useVoronoi(false);
//     //             //stack1.interactive(false);
//     //             //stack1.useVoronoi(false);
//     //             //stack2.interactive(false);
//     //             //stack2.useVoronoi(false);
//     //             scatters1.interactive(false);
//     //             scatters1.useVoronoi(false);
//     //             scatters2.interactive(false);
//     //             scatters2.useVoronoi(false);
//     //             historicalBars.interactive(false);
//     //         }
//     //     },
//     //     barColor: {
//     //         get: function () { return multibar.barColor; }, set: function (_) {
//     //             multibar.barColor(_);
//     //             multibarContext.barColor(_);
//     //             legend.color(function (d, i) { return d3.rgb('#ccc').darker(i * 1.5).toString(); })
//     //         }
//     //     },
//     // });

//     // nv.utils.inheritOptions(chart, nv.models.multiChart());
//     // nv.utils.initOptions(chart);

//     // return chart;
// };