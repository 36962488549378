﻿(function () {

    'use strict';

    angular
        .module('app.spm.core')
        .service('dataExportService', dataExportService);

    function dataExportService(dataExportResource, $state, $rootScope, FileSaver, searchBarService) {
   
        var queryData = {
            orderBy: '-Timestamp',
            pageSize: 10,
            pageIndex: 1,
            filter: '',
            id: '',
            fileType: '',
            binSize: '',
            daysOfWeek: ''
        };

        function getRawCsvFile(queryObj) {
            return dataExportResource.getRawCsvFile(queryObj,
                function (data, headers) {
                    //URL.createObjectURL(data.response)
                    //var blobData = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(data.response, data.fileName);
                    //return data;
                }).$promise;
        }

        function getExcelFile(queryObj) {
            return dataExportResource.getExcelFile(queryObj,
                function (data, headers) {
                    //URL.createObjectURL(data.response)
                    //var blobData = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(data.response, data.fileName);
                    //return data;
                }).$promise;
        }

        function getDashboardExcelFile(queryObj) {
            return dataExportResource.getDashboardExcelFile(queryObj,
                function (data, headers) {
                    //URL.createObjectURL(data.response)
                    //var blobData = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(data.response, data.fileName);
                    //return data;
                }).$promise;
        }

        function getSignalConfigExcelFile(queryObj) {
            return dataExportResource.getSignalConfigExcelFile(queryObj,
                function (data, headers) {
                    //URL.createObjectURL(data.response)
                    //var blobData = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(data.response, data.fileName);
                    //return data;
                }).$promise;
        }

        function getCorridorConfigExcelFile(queryObj) {
            return dataExportResource.getCorridorConfigExcelFile(queryObj,
                function (data, headers) {
                    //URL.createObjectURL(data.response)
                    //var blobData = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(data.response, data.fileName);
                    //return data;
                }).$promise;
        }

        function getInsightsExcelFile(queryObj) {
            return dataExportResource.getInsightsExcelFile(queryObj,
                function (data, headers) {
                    //URL.createObjectURL(data.response)
                    //var blobData = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(data.response, data.fileName);
                    //return data;
                }).$promise;
        }

        function setCurrentQueryObject(queryObj){
            queryData = getCurrentQueryObject();
            queryData.filter = queryObj.filter;
        }


        function getCurrentQueryObject(filter) {

            if(filter != undefined) queryData.filter = filter;

            var options = searchBarService.getSearchOptions();

            if (options.timeOptions.daysOfWeekList) {
                queryData.daysOfWeek = options.timeOptions.daysOfWeekList;
            }

            queryData.id = options.signal.signalID;
            queryData.start = new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleString();
            queryData.end = new Date(options.timeOptions.currentFilter.endDateAndTime).toLocaleString();
            //set bin 
            switch (options.timeOptions.bin) {
                case "OneMinute":
                    queryData.binSize = 1;
                break;
                case "FiveMinute":
                    queryData.binSize = 5;
                break;
                case "FifteenMinute":
                    queryData.binSize = 15;
                    break;
                case "ThirtyMinute":
                    queryData.binSize = 30;
                    break;
                case "Hour":
                    queryData.binSize = 60;
                    break;
                case "Day":
                    queryData.binSize = 1440;
                    break;
            }
            return queryData;
        }

        function getCurrentMetricQueryObject(chartOptions, metricTypeID) {
            var queryData = {
                id: '',
                start: '',
                end: '',
                fileType: '',
                metricTypeID:metricTypeID,
                metricOptions: ''
            };
            var options = searchBarService.getSearchOptions();

            if(!chartOptions && (metricTypeID == 91 || metricTypeID == 92)){
                let detectorOptions = {
                    signalID : options.signal.signalID,
                    startDate : new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleString(),
                    endDate: new Date(options.timeOptions.currentFilter.endDateAndTime).toLocaleString(),
                    selectedBinSize: 15
                }
                queryData.metricOptions = detectorOptions;
            }

            if(chartOptions){
                queryData.metricOptions = chartOptions;
                queryData.metricOptions.signalID = options.signal.signalID;
                queryData.metricOptions.startDate = new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleString();
                queryData.metricOptions.endDate = new Date(options.timeOptions.currentFilter.endDateAndTime).toLocaleString();
            }

            queryData.id = options.signal.signalID;
            queryData.start = new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleString();
            queryData.end = new Date(options.timeOptions.currentFilter.endDateAndTime).toLocaleString();
            //set bin 
            if(chartOptions.selectedBinSize || (metricTypeID == 91 || metricTypeID == 92)){
                switch (options.timeOptions.bin) {
                    case "OneMinute":
                        queryData.metricOptions.selectedBinSize = 1;
                    break;
                    case "FiveMinute":
                        queryData.metricOptions.selectedBinSize = 5;
                    break;
                    case "FifteenMinute":
                        queryData.metricOptions.selectedBinSize = 15;
                        break;
                    case "ThirtyMinute":
                        queryData.metricOptions.selectedBinSize = 30;
                        break;
                    case "Hour":
                        queryData.metricOptions.selectedBinSize = 60;
                        break;
                    case "Day":
                        queryData.metricOptions.selectedBinSize = 1440;
                        break;
                }
            }
            return queryData;
        }

        return {
            getRawCsvFile: getRawCsvFile,
            getExcelFile: getExcelFile,
            getInsightsExcelFile: getInsightsExcelFile,
            getCurrentQueryObject: getCurrentQueryObject,
            getDashboardExcelFile: getDashboardExcelFile,
            getSignalConfigExcelFile: getSignalConfigExcelFile,
            getCorridorConfigExcelFile: getCorridorConfigExcelFile,
            getCurrentMetricQueryObject: getCurrentMetricQueryObject,
            setCurrentQueryObject : setCurrentQueryObject
        }
    }
})();
