﻿(function () {
    'use strict';

    angular
        .module('app.spm.signals')
        .controller('approachAddController', approachAddController);

    /** @ngInject */
    function approachAddController($rootScope, $scope, $state, $mdDialog, signalService, lookups, signal) {
        var vm = this;
        vm.approach = {};
        vm.close = close;
        vm.signal = signal;
        vm.saveApproach = saveApproach;
        vm.lookups = lookups;
        vm.checkPhases = checkPhases;
        vm.samePhases = false;
        vm.message = "";


        function checkPhases(protectedPhase, premisivePhase) {
            if(protectedPhase==premisivePhase){
                vm.samePhases = true;
                vm.message ="Protected and Premesive Phase Number are same, please change!"
            } 
            else {
                vm.samePhases= false;
                vm.message =""
            } 
        }

        function close() {
            $mdDialog.cancel(false);
        };

        $scope.ok = function () {
            $mdDialog.hide({ message: 'Success' });
        };

        function saveApproach() {
            vm.approach.signalID = vm.signal.signalID;
            signalService.createApproach(vm.approach)
                .then(function (respApproach) {
                    $scope.ok();
                });
        };
    }
})();
