(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.historical-travel-time')
        .controller('DashboardHistoricalTravelTimeController', DashboardHistoricalTravelTimeController);

    /** @ngInject */
    function DashboardHistoricalTravelTimeController($scope, $state, searchBarService, $mdDialog, $translate, $element, $timeout, signalPerformanceService, corridorService, helpService, userSettingsService) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        vm.searchBarService = searchBarService;
        vm.timeOptions = {};
        vm.corridor = undefined;
        vm.signals = [];
        vm.setSearchOptions = setSearchOptions;
        vm.setupSearchBar = setupSearchBar;
        vm.setDataPerTab = setDataPerTab;
        vm.directionDetails = {};
        vm.mapApi = {};
        vm.getMetricDataForCorridor = getMetricDataForCorridor;
        vm.getWazeData = getWazeData;
        vm.createOrGetPerformanceTabs = createOrGetPerformanceTabs;
        vm.formatIntToString = formatIntToString;
        vm.goToSignalPerformance = goToSignalPerformance;
        vm.selectedRoute = {};
        vm.loading = false;
        vm.showHelpAlert = showHelpAlert;
        vm.createTab = createTab;
        vm.i18TopLocation = "HISTORICAL_TRAVEL_TIME.";
        vm.tabChange = tabChange;
        vm.loadingWazeData = true;
        vm.formatSecondsString = formatSecondsString;
        vm.getHoursMinutesSeconds = getHoursMinutesSeconds;
        vm.wazeDataPromise = {};
        vm.getMilesFromMeters = getMilesFromMeters;
        vm.getRouteMetricTab = getRouteMetricTab;
        vm.signalPerformanceService = signalPerformanceService;
        vm.getScaleUnit = getScaleUnit;
        vm.serverError = false;
        vm.setPerformanceData = setPerformanceData;
        vm.noData = false;
        vm.routeColors = ['rgba(228,26,28,.75)', 'rgba(55,126,184,.75)', 'rgba(77,175,74,.75)', 'rgba(152,78,163,.75)',
            'rgba(255,127,0,.75)', 'rgba(255,255,51,.75)', 'rgba(166,86,40,.75)', 'rgba(247,129,191,.75)',
            'rgba(153,153,153,.75)'];
        vm.rgba2hex = rgba2hex;
        vm.travelTimeChart = {
            lineSeries: ["tti"],
            colors: ['rgba(55,126,184,.75)', 'rgba(152,78,163,.75)'],
            options: {
                maintainAspectRatio: false,
                showLines: true,
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: false,
                        },
                        type: 'time',
                        position: 'bottom',
                        ticks: {
                            beginAtZero: true,
                        },
                        time: {
                            unit: 'hour'
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            fontSize: 11
                        }
                    }]
                },
                elements: {
                    line: {
                        fill: false,
                    }
                }
            }
        };
        vm.performanceLineChart = {
            options: {
                maintainAspectRatio: false,
                showLines: true,
                scales: {
                    xAxes: [{

                        scaleLabel: {
                            display: false,
                        },
                        type: 'time',
                        position: 'bottom',
                        ticks: {
                            display: false
                        },
                        time: {

                            tooltipFormat: 'MM/DD/YYYY, HH:mm:ss', // <- HERE
                            displayFormats: {
                                'millisecond': 'HH:mm:ss',
                                'second': 'HH:mm:ss',
                                'minute': 'HH:mm:ss',
                                'hour': 'HH',
                                'day': 'HH:mm:ss',
                                'week': 'HH:mm:ss',
                                'month': 'HH:mm:ss',
                                'quarter': 'HH:mm:ss',
                                'year': 'HH:mm:ss',
                            },
                            unit: 'hour'
                        },
                    }],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                userCallback: function (value, index, values) {
                                    return value.toLocaleString();   // this is all we need
                                },
                                fontSize: 11
                            }
                        }
                    ]
                },
                elements: {
                    line: {
                        fill: true,
                    }
                }
            }
        };
        vm.performanceBarChart = {
            colors: {
                backgroundColor: 'rgba(55,126,184,.75)',
            },
            options: {
                maintainAspectRatio: false,
                showLines: true,
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: false,
                        },
                        gridLines: {
                            offsetGridLines: true
                        },
                        position: 'bottom',
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (value, index, values) {
                                return value.toLocaleString();   // this is all we need
                            }
                        },
                    }],
                },
            }
        };

        function getScaleUnit() {
            if (searchBarService.calculateFilterTimeSpanInDays() > 1) {
                vm.travelTimeChart.options.scales.xAxes[0].time.unit = "day";
            }
            else {
                vm.travelTimeChart.options.scales.xAxes[0].time.unit = "hour";
            }
        }

        function showHelpAlert(ev, card) {
            helpService.showHelpDialog("HISTORICAL_TRAVEL_TIME." + card.toString(), ev, "How We Calculate It");
        };

        function goToSignalPerformance(signal) {
            searchBarService.setSignal(signal);
            $state.go('app.spm.dashboards.signal-performance');
        }

        function createOrGetPerformanceTabs(aggKey, route) {
            var tabReturn = [];
            switch (aggKey) {
                case signalPerformanceService.aggregationNames.Volumes:
                    var tab = vm.getRouteMetricTab(signalPerformanceService.aggregationNames.Volumes, route);
                    if (!tab) {
                        tab = vm.createTab(signalPerformanceService.metricTypesEnum.Volume, "Volume", "", signalPerformanceService.aggregationNames.Volumes, true, false, 2);
                        route.performanceTabs.push(tab);
                    }
                    break;
                case signalPerformanceService.aggregationNames.SplitFails:
                    var tab = vm.getRouteMetricTab(signalPerformanceService.metricTypesEnum.SplitFails, route);
                    if (!tab) {
                        tab = vm.createTab(signalPerformanceService.metricTypesEnum.SplitFails, "Split Failures", "", signalPerformanceService.aggregationNames.SplitFails, true, false, 4);
                        route.performanceTabs.push(tab);
                    }
                    break;
                case signalPerformanceService.aggregationNames.VehicleDelay:
                    var tab = vm.getRouteMetricTab(signalPerformanceService.metricTypesEnum.DelayPerVehicle, route);
                    if (!tab) {
                        tab = vm.createTab(signalPerformanceService.metricTypesEnum.DelayPerVehicle, "Delay Per Vehicle", "(sec)", signalPerformanceService.aggregationNames.VehicleDelay, false, false, 1);
                        route.performanceTabs.push(tab);
                    }
                    break;
                case signalPerformanceService.aggregationNames.Pcd:
                    var tab = vm.getRouteMetricTab(signalPerformanceService.metricTypesEnum.PlatoonRatio, route);
                    if (!tab) {
                        tab = vm.createTab(signalPerformanceService.metricTypesEnum.PlatoonRatio, "Platoon Ratio", "", signalPerformanceService.aggregationNames.Pcd, false, true, 5);
                        route.performanceTabs.push(tab);
                    }
                    var tab2 = vm.getRouteMetricTab(signalPerformanceService.metricTypesEnum.PercentArrivalsOnGreen, route);
                    if (!tab2) {
                        tab2 = vm.createTab(signalPerformanceService.metricTypesEnum.PercentArrivalsOnGreen, "Percent Arrivals on Green", "", signalPerformanceService.aggregationNames.Pcd, false, true, 3);
                        route.performanceTabs.push(tab2);
                    }
                    tabReturn.push(tab2);
                    break;
            }
            tabReturn.push(tab);
            return tabReturn;
        }

        function createTab(metricType, title, valueLabel, aggregationType, useTotal, inverseHeatMap, order) {
            return {
                metricType: metricType,
                tabOrder: order,
                title: title,
                metricText: title,
                valueLabel: valueLabel,
                useTotal: useTotal,
                highSignal: {
                    direction: "",
                    data: [{
                        "key": "",
                        "values": []
                    }]
                },
                lowSignal: {
                    direction: "",
                    data: [{
                        "key": "",
                        "values": []
                    }]
                },
                allSignalData: [],
                allSignalLabels: [],
                onApiInit: function (apiObj) {
                    this.mapApi = apiObj;
                    if (this.mapApi && this.mapApi.updateHeatMap && this.heatMapData.length > 0) {
                        this.mapApi.updateHeatMap(this.heatMapData);
                    }
                },
                aggregationType: aggregationType,
                corridorTotal: 0,
                corridorAverage: 0,
                heatMapData: [],
                inverseHeatMapColors: inverseHeatMap,
                mapApi: {},
            }
        }

        vm.setupSearchBar();

        function formatIntToString(input) {
            var noDecimal = parseInt(input.toFixed(0));
            return noDecimal.toLocaleString('en');
        }

        function getMilesFromMeters(i) {
            return (i * 0.000621371192).toFixed(2);
        }

        function rgba2hex(rgba) {
            rgba = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
            return (rgba && rgba.length === 4) ? "#" +
                ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
                ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
                ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2) : '';
        }

        function getWazeData() {
            vm.loadingWazeData = true;
            var opts = {
                orderBy: '',
                pageSize: 10,
                pageIndex: 1,
                filter: '',
                id: vm.corridor.corridorID,
            };
            opts.start = new Date(vm.timeOptions.currentFilter.startDateAndTime).toLocaleString();
            opts.end = new Date(vm.timeOptions.currentFilter.endDateAndTime).toLocaleString();
            vm.wazeDataPromise = corridorService.getWazeData(opts)
                .then(function (res) {
                    if (res && res.downstreamWazeRoutes && res.downstreamWazeRoutes.wazeRoutes) {
                        var route = vm.routes.filter(x => x.routeDirection == "Downstream")[0];
                        route.wazeRoutes = res.downstreamWazeRoutes.wazeRoutes;
                        route.wazeRoutes.sort((a, b) => (a.wazeId > b.wazeId) ? 1 : ((b.wazeId > a.wazeId) ? -1 : 0));
                        route.averageCurrentTravelTimeSeconds = res.downstreamWazeRoutes.averageCurrentTravelTimeSeconds;
                        route.hasData = res.downstreamWazeRoutes.hasData;
                        let colorIndex = 0;
                        route.wazeRoutes.forEach(x => {
                            x.travelTimeString = vm.formatSecondsString(x.averageCurrentTravelTimeSeconds);
                            x.color = vm.routeColors[colorIndex];
                            colorIndex++;
                            if (colorIndex > vm.routeColors.length - 1) {
                                colorIndex = 0;
                            }
                        });
                        route.planningTimeIndex = res.downstreamWazeRoutes.planningTimeIndex;
                        route.travelTimeIndex = res.downstreamWazeRoutes.travelTimeIndex;
                        route.congestedTime = res.downstreamWazeRoutes.totalCongestedTime;
                        if (route.mapApiWaze && route.mapApiWaze.updateRouteLines) {
                            route.mapApiWaze.updateRouteLines(route.wazeRoutes, route.signals);
                        }
                        var ttiData = [], ptiData = [];
                        for (let i = 0; i < res.downstreamWazeRoutes.combinedTravelTimes.length; i++) {
                            var tt = res.downstreamWazeRoutes.combinedTravelTimes[i];
                            var time = new Date(tt.time);
                            ttiData.push({
                                x: time,
                                y: tt.travelTimeIndex
                            });
                            ptiData.push({
                                x: time,
                                y: tt.planningTimeIndex
                            });
                        }
                        route.ttiChartData = [ttiData, ptiData];
                    }

                    if (res && res.upstreamWazeRoutes && res.upstreamWazeRoutes.wazeRoutes) {
                        var route = vm.routes.filter(x => x.routeDirection == "Upstream")[0];
                        route.wazeRoutes = res.upstreamWazeRoutes.wazeRoutes;
                        route.hasData = res.upstreamWazeRoutes.hasData;
                        route.wazeRoutes.sort((a, b) => (a.wazeId > b.wazeId) ? 1 : ((b.wazeId > a.wazeId) ? -1 : 0));
                        let colorIndex = 0;
                        route.averageCurrentTravelTimeSeconds = res.upstreamWazeRoutes.averageCurrentTravelTimeSeconds;
                        route.wazeRoutes.forEach(x => {
                            x.travelTimeString = vm.formatSecondsString(x.averageCurrentTravelTimeSeconds);
                            x.color = vm.routeColors[colorIndex];
                            colorIndex++;
                            if (colorIndex > vm.routeColors.length - 1) {
                                colorIndex = 0;
                            }
                        });
                        route.planningTimeIndex = res.upstreamWazeRoutes.planningTimeIndex;
                        route.travelTimeIndex = res.upstreamWazeRoutes.travelTimeIndex;
                        route.congestedTime = res.upstreamWazeRoutes.totalCongestedTime;
                        if (route.mapApiWaze && route.mapApiWaze.updateRouteLines) {
                            route.mapApiWaze.updateRouteLines(route.wazeRoutes, route.signals);
                        }
                        var ttiData = [], ptiData = [];
                        for (let i = 0; i < res.upstreamWazeRoutes.combinedTravelTimes.length; i++) {
                            var tt = res.upstreamWazeRoutes.combinedTravelTimes[i];
                            var time = new Date(tt.time);

                            ttiData.push({
                                x: time,
                                y: tt.travelTimeIndex
                            });
                            ptiData.push({
                                x: time,
                                y: tt.planningTimeIndex
                            });
                        }
                        route.ttiChartData = [ttiData, ptiData];
                    }

                    vm.loadingWazeData = false;
                })
                .catch(function (error) {
                    if (vm) {
                        vm.loadingWazeData = false;
                        if (error.status) vm.serverError = true;
                    }
                });
        }

        function getRouteMetricTab(metricType, route) {
            var tab;

            if (route && route.performanceTabs)
                tab = route.performanceTabs.filter(x => x.metricType == metricType)[0];

            return tab;
        }

        function getMetricDataForCorridor() {
            vm.setSearchOptions();
            vm.loading = true;
            if (!vm.timeOptions) {
                return;
            }

            //setup default parameters
            var daysOfWeek = [];
            var startHour = 0, startMinute = 0, endMinute = 0, endHour = 0;
            var start = new Date(vm.timeOptions.currentFilter.startDateAndTime);
            var end = new Date(vm.timeOptions.currentFilter.endDateAndTime);
            //focus time frame and today time frame do not send "TimeOption". Send "StartToEnd" for timeOption
            if (vm.timeOptions.timeOption == "TimePeriod") {
                //commenting this out for now
                startHour = 0;
                startMinute = 0;
                endHour = 23;
                endMinute = 59;
            }
            //set the day of week list
            if (vm.timeOptions.daysOfWeekList) {
                daysOfWeek = vm.timeOptions.daysOfWeekList;
            }
            //if time frame is greater than 1 day default to Daily bin
            if (!searchBarService.isFilterSpanGreaterThanDays(3, start, end)) {
                vm.timeOptions.bin = "Day"
            }
            var aggregationTypesToQuery = [signalPerformanceService.aggregationNames.Volumes, signalPerformanceService.aggregationNames.SplitFails,
            signalPerformanceService.aggregationNames.VehicleDelay, signalPerformanceService.aggregationNames.Pcd];

            var opts = corridorService.createPerformanceOptionsObject(vm.corridor.corridorID, aggregationTypesToQuery, start.toLocaleString(), end.toLocaleString(),
                startHour, startMinute, endHour, endMinute, daysOfWeek, vm.timeOptions.timeOption, vm.timeOptions.bin, true);
            corridorService.getCorridorPerformance(opts)
                .then(function (res) {
                    if (!res || !res.signals || !res.directionalData) {
                        vm.serverError = true;
                        vm.loading = false;
                        return;
                    }
                    //we need to see if all of our signals in corridor are missing data....really should only happen during full comm outages
                    else if (Object.keys(res.directionalData).length === 0 && res.directionalData.constructor === Object) {
                        vm.noData = true;
                        vm.loading = false;
                        vm.serverError = false;
                        return;
                    }
                    vm.noData = false;
                    let directionalData = res.directionalData;

                    //setup the directional data
                    for (var z = 0; z < Object.entries(directionalData).length; z++) {
                        //get the parent direction
                        var metricDefinitionKeyvalue = Object.entries(directionalData)[z];
                        var aggKey = metricDefinitionKeyvalue[0];
                        var directions = metricDefinitionKeyvalue[1]
                        for (var i = 0; i < directions.length; i++) {
                            var direction = directions[i];
                            var route;
                            if (direction.direction.description == "Upstream") {
                                route = vm.routes.filter(x => x.routeDirection == "Upstream")[0];
                            }
                            else if (direction.direction.description == "Downstream") {
                                route = vm.routes.filter(x => x.routeDirection == "Downstream")[0];
                            }
                            else {
                                continue;
                            }
                            var directionTabs = vm.createOrGetPerformanceTabs(aggKey, route);
                            for (let i = 0; i < directionTabs.length; i++) {
                                let directionTab = directionTabs[i];
                                let queryPropertyData = signalPerformanceService.getValuePropertiesFromType(directionTab.metricType);


                                let approachesWithGoodDetection = direction.approaches.filter(appr => appr.data.hasNeededDetection);
                                if (approachesWithGoodDetection.length <= 0) {
                                    directionTab.noDetection = true;
                                    continue;
                                }
                                vm.setPerformanceData(queryPropertyData, directionTab, approachesWithGoodDetection, route);
                                if (aggKey == signalPerformanceService.aggregationNames.VehicleDelay) {
                                    route.vehicleDelayTotal = directionTab.corridorTotal.toFixed(2);
                                }
                                else if (aggKey == signalPerformanceService.aggregationNames.Volumes) {
                                    route.volumeTotal = directionTab.corridorTotal;
                                }
                                if (directionTab.mapApi && directionTab.mapApi.updateHeatMap) {
                                    directionTab.mapApi.updateHeatMap(directionTab.heatMapData);
                                }

                            }
                        }
                    }
                    vm.loading = false;
                });
        }

        //this function will look at our directional data and create data binding objects for UI
        function setPerformanceData(queryPropertyData, directionTab, approachesWithGoodDetection, route) {
            var highest = approachesWithGoodDetection.reduce(function (prev, current) {
                if (!directionTab.useTotal) {
                    return (prev.data.binsContainer[queryPropertyData.summaryAverageProperty] > current.data.binsContainer[queryPropertyData.summaryAverageProperty]) ? prev : current;
                }
                return (prev.data.binsContainer[queryPropertyData.summaryTotalProperty] > current.data.binsContainer[queryPropertyData.summaryTotalProperty]) ? prev : current;
            });
            //determine our lowest and highest signal directions for this metric
            var lowest = approachesWithGoodDetection.reduce(function (prev, current) {
                let prevValue = 0, currValue = 0;
                if (!directionTab.useTotal) {
                    prevValue = prev.data.binsContainer[queryPropertyData.summaryAverageProperty];
                    currValue = current.data.binsContainer[queryPropertyData.summaryAverageProperty];
                }
                else {
                    prevValue = prev.data.binsContainer[queryPropertyData.summaryTotalProperty];
                    currValue = current.data.binsContainer[queryPropertyData.summaryTotalProperty];
                }
                if (currValue <= 0) return prev;
                if (currValue > 0 && prevValue <= 0) return current;
                return (prevValue < currValue) ? prev : current;
            });

            var highSignal = route.signals.find(function (signal) { return signal.signalID == highest.signalId });
            var lowSignal = route.signals.find(function (signal) { return signal.signalID == lowest.signalId });

            directionTab.highSignal.description = highSignal.description;
            directionTab.lowSignal.description = lowSignal.description;
            directionTab.highSignal.direction = highest.direction;
            directionTab.lowSignal.direction = lowest.direction;
            if (directionTab.useTotal) {
                directionTab.highSignal.metricValue = highest.data.binsContainer[queryPropertyData.summaryTotalProperty].toLocaleString('en');
                directionTab.lowSignal.metricValue = lowest.data.binsContainer[queryPropertyData.summaryTotalProperty].toLocaleString('en');
            }
            else {
                directionTab.highSignal.metricValue = highest.data.binsContainer[queryPropertyData.summaryAverageProperty].toFixed(2);
                directionTab.lowSignal.metricValue = lowest.data.binsContainer[queryPropertyData.summaryAverageProperty].toFixed(2);
            }

            var allSignalData = [], allSignalLabels = [], heatMapData = [];
            let runningTotal = 0;
            //for the bar chart and line graphs, let's create an x, y dataset for each bin with the corresponding value for that bin time
            for (var i = 0; i < approachesWithGoodDetection.length; i++) {
                var appr = approachesWithGoodDetection[i];
                var signal = route.signals.find(function (signal) { return signal.signalID == appr.signalId });

                var metricValueToUse = appr.data.binsContainer[queryPropertyData.summaryTotalProperty];
                if (!directionTab.useTotal)
                    metricValueToUse = parseFloat(appr.data.binsContainer[queryPropertyData.summaryAverageProperty].toFixed(2));

                runningTotal += metricValueToUse;
                //sets up the data arrays for the right-side bar chart
                allSignalLabels.push(signal.customID);
                allSignalData.push(metricValueToUse);

                if (metricValueToUse > 0) {
                    var dataPoints = [];
                    appr.data.binsContainer.bins.forEach(function (bin) {
                        var yValue = bin[queryPropertyData.valueProperty];
                        dataPoints.push({
                            x: new Date(bin.start),
                            y: yValue
                        });
                    });
                    if (signal.signalID == highSignal.signalID) {
                        directionTab.highSignal.data = [dataPoints];
                    }
                    if (signal.signalID == lowSignal.signalID) {
                        directionTab.lowSignal.data = [dataPoints];
                    }
                    //add heat map data
                    heatMapData.push({
                        id: signal.signalID,
                        customId: signal.customID,
                        fullName: signal.description,
                        latitude: signal.latitude,
                        longitude: signal.longitude,
                        count: metricValueToUse
                    });
                }
            }
            directionTab.corridorTotal = runningTotal;
            if (approachesWithGoodDetection.length > 0)
                directionTab.corridorAverage = parseFloat((runningTotal / approachesWithGoodDetection.length).toFixed(2));

            directionTab.allSignalData = allSignalData;
            directionTab.allSignalLabels = allSignalLabels;
            directionTab.heatMapData = heatMapData;

        }

        //this will kick off our data fetching routine from the API
        function setDataPerTab() {
            vm.loading = true;
            vm.getWazeData();
            vm.getMetricDataForCorridor();
        }

        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                        if (searchBarService.isFiltering() && vm.corridor) {
                            var options = vm.searchBarService.getSearchOptions();
                            vm.timeOptions = options.timeOptions;
                            if (vm.selectedRoute && vm.selectedRoute.performanceTabs) {
                                vm.getScaleUnit();
                                //wait half a second before fetching new data
                                //this will allow UI to show loading spinners to give the perception of the data changing
                                vm.loadingWazeData = true;
                                vm.serverError = false;
                                vm.loading = true;
                                $timeout(function () {
                                    vm.setDataPerTab();
                                }, 500)
                            }
                        }
                        break;
                    case "corridor":
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.setSearchOptions();
                            vm.setDataPerTab();
                            vm.serverError = false;
                        }
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                    case "state":
                        if (searchBarService.isFiltering() == false)
                            vm.isFiltering = false;
                        vm.serverError = false;
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Historical Travel Time Dashboard",
                    badgeText: "beta"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Corridors',
                showCurrentFilterDates: true,
                helpJsonPropertyPath: "HISTORICAL_TRAVEL_TIME." + "GENERAL_HELP",
                showHelp: true,
                showExcel: true,
                metricTypeId: userSettingsService.dashboardTypeIds.CorridorHistoricalTravelTime,
                dashboardType: "Corridor",
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "YD",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    showWeekdayFilter: true,
                    maxDayRange: 7
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: true,
                    skipStepsPerBin: false,
                    notifyOnBinChange: true,
                }
            });
            vm.getScaleUnit();
            vm.isFiltering = searchBarService.isFiltering();
        }

        function getSignalDescription(customId, primaryName, secondaryName) {

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '') {
                res += " - " + secondaryName;
            }

            return res;
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.corridor = options.corridor;
            vm.timeOptions = options.timeOptions;
            vm.signals = [];
            vm.routes = [];

            if (vm.corridor.upstreamRoute) {
                var upstreamRoute = {
                    signals: [],
                    routeDirection: 'Upstream',
                    direction: vm.corridor.upstreamRoute.direction,
                    directionDetails: {},
                    mapApi: {},
                    mapApiWaze: {},
                    wazeRoutes: [],
                    hasData: false,
                    chartData: [],
                    averageCurrentTravelTimeSeconds: 0,
                    congestedTime: 0,
                    travelTimeString: "",
                    selectedPerformanceTabIndex: 0,
                    refreshTravelTimes: function () {
                        if (this.mapApi && this.mapApi.calculateDirections) {
                            this.mapApi.calculateDirections();
                        }
                    },
                    onApiInit: function (apiObj) {
                        this.mapApiWaze = apiObj;
                        if (this.mapApiWaze && this.mapApiWaze.updateRouteLines && this.wazeRoutes.length > 0) {
                            this.mapApiWaze.updateRouteLines(this.wazeRoutes, this.signals);
                        }
                    },
                    performanceTabs: []
                };
                if (vm.corridor.upstreamRoute.approachRouteDetails) {
                    vm.corridor.upstreamRoute.approachRouteDetails.forEach(function (rt) {
                        rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                        upstreamRoute.signals.push(rt.signal);
                    });
                }
                vm.routes.push(upstreamRoute);
            }
            if (vm.corridor.downstreamRoute) {
                var downstreamRoute = {
                    signals: [],
                    routeDirection: 'Downstream',
                    direction: vm.corridor.downstreamRoute.direction,
                    directionDetails: {},
                    mapApi: {},
                    mapApiWaze: {},
                    wazeRoutes: [],
                    chartData: [],
                    hasData: false,
                    congestedTime: 0,
                    travelTimeString: "",
                    averageCurrentTravelTimeSeconds: 0,
                    selectedPerformanceTabIndex: 0,
                    refreshTravelTimes: function () {
                        if (this.mapApi && this.mapApi.calculateDirections) {
                            this.mapApi.calculateDirections();
                        }
                    },
                    onApiInit: function (apiObj) {
                        this.mapApiWaze = apiObj;
                        if (this.mapApiWaze && this.mapApiWaze.updateRouteLines && this.wazeRoutes.length > 0) {
                            this.mapApiWaze.updateRouteLines(this.wazeRoutes, this.signals);
                        }
                    },
                    performanceTabs: []
                }
                if (vm.corridor.downstreamRoute.approachRouteDetails) {
                    vm.corridor.downstreamRoute.approachRouteDetails.forEach(function (rt) {
                        rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                        downstreamRoute.signals.push(rt.signal);
                    });
                }
                vm.routes.push(downstreamRoute);
            }
        };

        function formatSecondsString(time) {
            var res = "";
            time = time.toFixed(0);
            var minutes = Math.floor(time / 60);
            var seconds = time - minutes * 60;
            if (minutes > 0)
                res = minutes + "m " + seconds + "s";
            else
                res = seconds + "s";

            return res;
        }

        function getHoursMinutesSeconds(time) {
            time = time.toFixed(0);
            var sec_num = parseInt(time, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;
            return [hours, minutes, seconds];
        }

        function tabChange(index) {
            vm.selectedRoute = vm.routes[index];
        }

    }
})();