﻿(function () {
    "use strict";

    angular
        .module("app.spm.event-player")
        .controller("eventPlayerController", eventPlayerController);

    function eventPlayerController($state, $scope, searchBarService) {
        var vm = this;
        vm.getSearchOptions = getSearchOptions;
        vm.searchBarService = searchBarService;
        vm.signal = undefined;
        vm.timeOptions = undefined;
        vm.updatePlayer = updatePlayer;

        //setup player widget objects
        vm.playerWidget = {
            api: {},
            onApiInit: function (apiObj) {
                this.api = apiObj;
                //when our player widget is ready call update
                this.update();
            },
            update: function () {
                if (this.api && this.api.update)
                    this.api.update(vm.signal, vm.timeOptions)
            }
        }

        //set options for the search bar
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: true,
                text: "Event Playback",
            },
            //search bar options
            showSearchBar: true,
            searchType: 'Signals',
            showCurrentFilterDates: true,
            helpJsonPropertyPath: "EVENT_PLAYER." + "GENERAL_HELP",
            showHelp: true,   
            timeFrameConfig: {
                enableWithoutFiltering: false,
                defaultDateTemplate: "TD",
                defaultTodTemplate: "FD",
                dateTemplateMinimumDays: 0,
                timeOptionForCustomTemplate: "StartToEnd",
                showWeekdayFilter: false,
                maxDayRange: 30,
            },
            //right-side more options menu
            moreOptionsMenu: {
                show: false,
                showBinConfig: false,
                skipStepsPerBin: true,
            }
        });
        vm.isFiltering = searchBarService.isFiltering();

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm)
                return; 

            switch (changeType) {
                case "state":
                    vm.isFiltering = searchBarService.isFiltering();
                    break;
                case "time":
                case "signal":
                case "configuration":
                    //any time time or signal config changes, call player update
                    vm.updatePlayer();
                    break;

            }
        });

        function updatePlayer() {
            vm.getSearchOptions();
            vm.playerWidget.update();
        }

        function getSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.signal = options.signal;
            vm.timeOptions = options.timeOptions;
        };
        vm.updatePlayer();
  
        $scope.$on("$destroy", function () {
            vm = null;
        })
    }
}());
