
(function () {
    'use strict';
    angular.module('mdPickers').directive("mdpTimePicker", ["$mdpTimePicker", "$timeout", "$rootScope", "searchBarService", "timeframeMenuService", function ($mdpTimePicker, $timeout, $rootScope, searchBarService, timeframeMenuService) {
    return {
        restrict: 'EA',
        require: 'ngModel',
        transclude: true,
        template: function (element, attrs) {
            var currentSearch = searchBarService.getSearchOptions();
            var currentBinSize;
            var dateFormat = 'hh:mm A';

            if (!searchBarService.getSearchBarConfig().moreOptionsMenu.skipStepsPerBin) {
                switch (currentSearch.timeOptions.bin) {
                    case "FiveMinute":
                        currentBinSize = 5;
                        break;
                    case "FifteenMinute":
                        currentBinSize = 15;
                        break;
                    case "ThirtyMinute":
                        currentBinSize = 30;
                        break;
                    case "Hour":
                        currentBinSize = 60;
                        break;
                    case "OneMinute":
                        currentBinSize = 5;
                        break;
                    case "Day":
                        currentBinSize = 60;
                        break;
                    default:
                        break;
                }
            } else {
                currentBinSize = 5;
            }

            var useAdditions = attrs.ngModel == 'dateEnd' ? `additions="{bottom: 'bottom-template-url'}"` : ``;
            var useMinDate = attrs.ngModel == 'dateEnd' ? ' min-date="disableTimes"' : '';
            return '<div layout="row" layout-align="start start">' +
                `<md-input-container is-open="isOpen" ng-click="handleClick()" ` + useAdditions + ` change="handleChange(true)" id="parent-` + attrs.ngModel + `" moment-picker="timepicker" ` + useMinDate + ` start-view="day" max-view="{{minView}}" show-header="false" minutes-step=` + currentBinSize + `>` +
                // '<input type="{{ ::type }}"  aria-label="Time Range"' + (angular.isDefined(attrs.mdpDisabled) ? ' ng-disabled="disabled"' : '') + ' ' + (openOnClick ? ' ng-click="showPicker($event)" ' : '') + ' />' +
                '<input readonly type="{{ ::type }}" ng-model="timepicker" id="' + attrs.ngModel + '" aria-label="Time Range">' +
                '<md-icon md-font-icon="icon-clock" class="s16"></md-icon>' +
                '</md-input-container>' +
                // '<script type="text/ng-template"><button id="bottom-template-url">Close picker</button></script>' +
                '</div>';
        },
        scope: {
            "timeFormat": "@mdpFormat",
            "placeholder": "@mdpPlaceholder",
            "dateStart": "=?",
            "dateEnd": "=?",
            "autoSwitch": "=?mdpAutoSwitch",
            "disabled": "=?mdpDisabled",
            "minuteSteps": "="
        },
        link: function (scope, element, attrs, ngModel, $transclude) {

            scope.startDate = moment();
            scope.timepicker = moment();
            scope.counter = 0;
            var currentSearch = searchBarService.getSearchOptions();
            var startDiff, endDiff;
            if (attrs.ngModel == 'dateStart') {
                scope.timepicker = moment(currentSearch.timeOptions.currentFilter.startDateAndTime, "hh:mm A");
            } else if (attrs.ngModel == 'dateEnd') {
                scope.timepicker = moment(currentSearch.timeOptions.currentFilter.endDateAndTime, "hh:mm A");
            }

            scope.isMultipleDaysSelected = false;
            var startDiff = moment(currentSearch.timeOptions.currentFilter.startDateAndTime).startOf('day');
            var endDiff = moment(currentSearch.timeOptions.currentFilter.endDateAndTime).startOf('day');
            if (endDiff.diff(startDiff, 'days') >= 1) {
                scope.isMultipleDaysSelected = true;
            }
            
            scope.type = "text";
            scope.timeFormat = 'hh:mm A';
            if (currentSearch.timeOptions.currentFilter.selectedTodTemplate == 'CT') {
                if (!searchBarService.getSearchBarConfig().moreOptionsMenu.skipStepsPerBin) {
                    switch (currentSearch.timeOptions.bin) {
                        case "OneMinute":
                        case "FiveMinute":
                        case "FifteenMinute":
                        case "ThirtyMinute":
                            scope.minView = "hour";
                            break;
                        case "Day":
                        case "Hour":
                            scope.minView = "day";
                            break;
                        default:
                            break;
                    }
                } else {
                    scope.minView = "hour";
                }
            }

            scope.handleChange = function () {
                updateTime(scope.timepicker);
            }

            scope.handleIconClick = function () {
                scope.timepicker = moment(scope.timepicker, 'hh:mm A').endOf('day');
                scope.isOpen = false;
                updateTime(scope.timepicker);
            }

            scope.handleClick = function () {
                var searchOpt = searchBarService.getSearchOptions();
                var bins;

                if (!searchBarService.getSearchBarConfig().moreOptionsMenu.skipStepsPerBin) {
                    switch (searchOpt.timeOptions.bin) {
                        case "OneMinute":
                        case "FiveMinute":
                        case "FifteenMinute":
                        case "ThirtyMinute":
                            bins = 30;
                            break
                        case "Day":
                        case "Hour":
                            bins = 60;
                            break;
                        default:
                            break;
                    }
                } else {
                    bins = 5;
                }
                if (moment(scope.timepicker, 'hh:mm A').minute() == 59) {
                    scope.timepicker = moment(scope.timepicker, 'hh:mm A').set('minute', 0);
                }
                var currentTime = moment(document.getElementById('dateStart').value, 'hh:mm A');
                if (!scope.isMultipleDaysSelected || searchBarService.getSearchBarConfig().timeFrameConfig.timeOptionForCustomTemplate == 'TimePeriod') {
                    scope.disableTimes = currentTime.add(bins, 'minutes').format('hh:mm A');
                }
            }

            timeframeMenuService.subscribeToDateSelectionChanged(scope, function onChange(ev, dateSpan) {
                if (dateSpan > 1) {
                    scope.isMultipleDaysSelected = true;
                    scope.disableTimes = "";
                } else {
                    scope.isMultipleDaysSelected = false;
                }

            });

            timeframeMenuService.subscribeToTodTemplateChange(scope, function onChange(ev, todKey) {
                if (todKey == 'CT') {
                    if (!searchBarService.getSearchBarConfig().moreOptionsMenu.skipStepsPerBin) {
                        switch (currentSearch.timeOptions.bin) {
                            case "OneMinute":
                            case "FiveMinute":
                            case "FifteenMinute":
                            case "ThirtyMinute":
                                scope.minView = "hour";
                                break;
                            case "Day":
                            case "Hour":
                                scope.minView = "day";
                                break;
                            default:
                                break;
                        }
                    } else {
                        scope.minView = "hour";
                    }
                    // scope.handleChange(undefined, todKey);
                    if (moment(scope.timepicker, 'hh:mm A').minutes() == 59) {
                        scope.timepicker = moment(scope.timepicker, 'hh:mm A').set('minute', 0);
                    }
                }
                if(ngModel.$$attr.ngModel == "dateEnd"){
                    ngModel.$modelValue = timeframeMenuService.dateEnd;
                }
                if(ngModel.$$attr.ngModel == "dateStart"){
                    ngModel.$modelValue = timeframeMenuService.dateStart;
                }
                //ngModel.$modelValue = timeframeMenuService.dateStart;
                // $rootScope.$emit('mdpTimePickerUpdated');
                updateTime(scope.timepicker);
            });

            var inputElement = angular.element(element[0].querySelector('input')),
                inputContainer = angular.element(element[0].querySelector('md-input-container')),
                inputContainerCtrl = inputContainer.controller("mdInputContainer");

            $transclude(function (clone) {
                inputContainer.append(clone);
            });

            var messages = angular.element(inputContainer[0].querySelector("[ng-messages]"));

            scope.autoSwitch = scope.autoSwitch || false;

            scope.$watch(function () { return ngModel.$error }, function (newValue, oldValue) {
                inputContainerCtrl.setInvalid(!ngModel.$pristine && !!Object.keys(ngModel.$error).length);
            }, true);

            // update input element if model has changed
            ngModel.$formatters.unshift(function (value) {
                var time = angular.isDate(value) && moment(value);
                if (time && time.isValid())

                    updateInputElement(time.format(scope.timeFormat));
                else
                    updateInputElement(null);
            });

            ngModel.$validators.format = function (modelValue, viewValue) {
                return !viewValue || angular.isDate(viewValue) || moment(viewValue, scope.timeFormat, true).isValid();
            };

            ngModel.$validators.required = function (modelValue, viewValue) {
                return angular.isUndefined(attrs.required) || !ngModel.$isEmpty(modelValue) || !ngModel.$isEmpty(viewValue);
            };

            ngModel.$parsers.unshift(function (value) {
                var parsed = moment(value, scope.timeFormat, true);
                if (parsed.isValid()) {
                    if (angular.isDate(ngModel.$modelValue)) {
                        var originalModel = moment(ngModel.$modelValue);
                        originalModel.minutes(parsed.minutes());
                        originalModel.hours(parsed.hours());
                        originalModel.seconds(parsed.seconds());

                        parsed = originalModel;
                    }
                    return parsed.toDate();
                } else
                    return null;
            });

            // update input element value
            function updateInputElement(value) {
                inputElement[0].value = value;
                inputContainerCtrl.setHasValue(!ngModel.$isEmpty(value));
            }

            function updateTime(time) {
                var value = moment(time, angular.isDate(time) ? null : scope.timeFormat, true),
                    strValue = value.format(scope.timeFormat);

                if (value.isValid()) {
                    updateInputElement(strValue);
                    ngModel.$setViewValue(strValue);
                } else {
                    value = moment(ngModel.$modelValue),
                        strValue = value.format(scope.timeFormat);
                    updateInputElement(strValue);
                    ngModel.$setViewValue(strValue);
                }

                if (!ngModel.$pristine &&
                    messages.hasClass("md-auto-hide") &&
                    inputContainer.hasClass("md-input-invalid")) messages.removeClass("md-auto-hide");

                ngModel.$render();
                $rootScope.$emit('mdpTimePickerUpdated');
            }

            scope.showPicker = function (ev) {
                $mdpTimePicker(ngModel.$modelValue, {
                    targetEvent: ev,
                    autoSwitch: scope.autoSwitch
                }).then(function (time) {
                    updateTime(time, true);
                });
            };

            function onInputElementEvents(event) {
                if (event.target.value !== ngModel.$viewVaue)
                    updateTime(event.target.value);
            }

            inputElement.on("reset input blur", onInputElementEvents);

            scope.$on("$destroy", function () {
                inputElement.off("reset input blur", onInputElementEvents);
            })
        }
    };
}]);
})();