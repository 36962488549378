import * as INSMAP from 'raw-loader!./../insights-map-dashboard/insights-map-dashboard.html'

(function () {
    'use strict';

    angular
        .module('app.spm.dashboards.map-overview',
            []
        )
        .config(config);

    /** @ngInject */
    function config(msApiProvider, $stateProvider) {
        $stateProvider
            .state('app.spm.dashboards.map-overview', {
                url: '/map-overview',
                views: {
                    'content@app': {
                        template: INSMAP,
                        controller: 'InsightsMapDashboardController as vm'
                    }
                }
            });

        // Api
        //msApiProvider.register('dashboard.analytics', ['app/data/dashboard/analytics/data.json']);
        //msApiProvider.register('dashboard.performance', ['app/data/dashboard/performance/data.json']);

    }

})();