﻿(function () {

    'use strict';

    angular
      .module('app.core')
      .service('sentryService', sentryService);

    function sentryService($state, $rootScope, environmentConfig) {

        function init() {
            Sentry.init({
                dsn: 'https://3e9406c274144f6a917ea6bce436cd5e@sentry.io/2906571' ,
                environment: environmentConfig.environmentName
           });
        }

        function shouldCapture(){
            var res = false;
            if (environmentConfig.environmentName == "development" || 
            environmentConfig.environmentName == "staging" ||
            environmentConfig.environmentName == "production"){
                res = true;
            }
            return res;
        }

        function setUser(){
            Sentry.setUser({"email": sessionStorage.getItem('userEmail') });
        }

        function captureException(exception){
            if (shouldCapture()){
                Sentry.captureException(err);
            };
        }

        function captureMessage(message){
            if (shouldCapture()){
                Sentry.captureMessage(message);
            };
        }

        return {
            // ...
            init: init,
            setUser: setUser,
            captureException: captureException,
            captureMessage: captureMessage
        }
    }
})();