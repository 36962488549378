(function () {
    angular
        .module('app.spm.core')
        .service('timeframeMenuService', timeframeMenuService);

    function timeframeMenuService($mdDialog, $rootScope) {
        var service = this;

        service.getSelectedDate = getSelectedDate;
        service.subscribeToTodTemplateChange = subscribeToTodTemplateChange;
        service.notifyTodTemplateChange = notifyTodTemplateChange;
        service.subscribeToDateSelectionChanged = subscribeToDateSelectionChanged;
        service.notifyDateSelectionChanged = notifyDateSelectionChanged;
        service.dateStart = null;
        service.dateEnd = null;

        /**
         * @description returns all seleced date based on mmodel, filters and max range
         * @param {*} model 
         * @param {*} isDisabledDateCallback 
         * @param {*} maxRange 
         */
        function getSelectedDate(dateStart, dateEnd, isDisabledDateCallback, maxRange) {
            var dates = [];
            var limit = dateEnd.getTime();
            var date = dateStart;
            var ctr = 0;
            var y = dateStart.getFullYear();
            var m = dateStart.getMonth();
            var d = dateStart.getDate();
            while (date.getTime() <= limit) {
                if (isDisabledDateCallback && !isDisabledDateCallback({ $date: date })) {
                    dates.push(date);
                }
                ctr++;
                date = new Date(y, m, d + ctr);
                if (ctr > (maxRange || 10000)) break; // break on 10,000
            }
            return dates;
        }
        function subscribeToDateSelectionChanged(scope, callback) {
            var handler = $rootScope.$on('date-selection-changed', callback);
            scope.$on('$destroy', handler);
        }

        function notifyDateSelectionChanged(todKey) {
            $rootScope.$emit('date-selection-changed', todKey);
        }

        function subscribeToTodTemplateChange(scope, callback) {
            var handler = $rootScope.$on('tod-template-change-event', callback);
            scope.$on('$destroy', handler);
        }

        function notifyTodTemplateChange(todKey,dateStart,dateEnd) {
            service.dateStart = dateStart;
            service.dateEnd = dateEnd;            
            $rootScope.$emit('tod-template-change-event', todKey);
        }
    }

}());
