﻿import moment from 'moment';

(function () {
    "use strict";


    var app = angular
        .module("app.spm.charts.signal")
        .controller("tmcChartController", tmcChartController);

    function tmcChartController($state, $attrs, $scope, $rootScope, $element, environmentConfig, chartsService, searchBarService, $timeout) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 8;
        vm.formatDateTime = formatDateTime;
        vm.chartServerError = false;
        vm.isDataAvailable = false;
        vm.customOrder = customOrder;
        // vm.orderingTableData = orderingTableData;
        // vm.orderingTableDataTotals = orderingTableDataTotals;

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input != "{}") {
                    var jsonData = JSON.stringify(input, null, 4);
                    $scope.loading = false;
                    vm.tmcChartWidget.processTmcData(JSON.parse(jsonData, JSON.dateParser));
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        //query object for data table paging
        vm.query = {
            pageSize: 10,
            pageIndex: 1,
            order: 'time'
        };


        //filter will be called whenever user is paging
        vm.filter = function (limit, page) {
            var end = page * limit;
            var begin = end - page;
            vm.tmcChartWidget.dataTableData = vm.tmcChartWidget.dataTableDataFull.records.slice(begin, end);
            vm.tmcChartWidget.currentPageTotals = [];
            //just going through each value in the current page and adding it for the running total
            for (var i = 0; i < vm.tmcChartWidget.dataTableData.length; i++) {
                for (var j = 0; j < vm.tmcChartWidget.dataTableData[i].values.length; j++) {
                    if (!vm.tmcChartWidget.currentPageTotals[j])
                        vm.tmcChartWidget.currentPageTotals[j] = 0;

                    vm.tmcChartWidget.currentPageTotals[j] += vm.tmcChartWidget.dataTableData[i].values[j].item1;
                }
            }
        }

        //orders the table based on user ordering time column then calls filter
        vm.orderTable = function (order) {
            if (order == "time") {
                vm.tmcChartWidget.dataTableDataFull.records.sort(function (a, b) {
                    return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
                });
            }
            if (order == "-time") {
                vm.tmcChartWidget.dataTableDataFull.records.sort(function (a, b) {
                    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
                });
            }
            vm.filter(vm.query.pageIndex, vm.query.pageSize);
        }

        //gets our date time in the format we want
        function formatDateTime(input) {
            var options = {
                weekday: "long", year: "numeric", month: "short",
                day: "numeric", hour: "2-digit", minute: "2-digit", second: "numeric",
            };
            var inputDate = new Date(input);
            return inputDate.toLocaleTimeString("en-us", options);
            //var amPM = time.substr(time.length - 2, time.length);
            //time = time.substr(0, time.length - 3);
            //return time = time + "." + inputMS + " " + amPM;
        }

        vm.tmcChartWidget = {
            selectedIndex: 0,
            //selected Direction tab
            selectedTabChange: function (index) {
            },
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.tmcChartWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            addPlanLabels: function (primaryPlans) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = plansCopy[i];
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 0:
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        plan.labels.push(planText);
                        plansCopy[i] = plan;
                    }
                }
                return plansCopy;
            },
            //holds each chart data
            chartArray: {},
            dataTableData: [],
            dataTableDataFull: [],
            allTotals: [],
            currentPageTotals: [],
            //logic for taking server events and manipulating into format the chart is expecting
            processTmcData: function (rawData) {
                var plansInfo = {};
                var phases = [];
                var searchObj = searchBarService.getSearchOptions();
                var tabData = [];
                var planList = rawData.plans;
                var colorScheme = ['#0C5EAF', '#63A5E8', '#FFC360', '#A46F17', '#275C29', '#9BD19D']
                //rawData is an array holding all data
                for (var key in rawData) {
                    if (key == "plans") continue;

                    if (key == "tmcDataTable") {
                        tabData.push({
                            tabTitle: "Data Table",
                            tableData: rawData[key],
                            isChart: false,
                        });
                        vm.tmcChartWidget.allTotals = rawData[key].footers[0].values;
                        vm.tmcChartWidget.dataTableDataFull = rawData[key];
                        vm.filter(vm.query.pageIndex, vm.query.pageSize);
                        continue;
                    }

                    var laneTypeDesc = "";
                    switch (key) {
                        case "vehicleDirections":
                            laneTypeDesc = "Vehicle";
                            break;
                        case "bikeDirections":
                            laneTypeDesc = "Bike";
                            break;
                        case "exitDirections":
                            laneTypeDesc = "Exit";
                            break;
                    }
                    var laneType = rawData[key];

                    //this goes through each direction type, ie: Vehicle, Bike, Exit, etc.
                    laneType.forEach(function (direction) {
                        //add or get the direction
                        if (direction.movements.length > 0) {
                            var directionTabObj = tabData.find(function (x) { return x.tabTitle == direction.directionType });
                            if (!directionTabObj) {
                                directionTabObj = {
                                    tabTitle: direction.directionType,
                                    charts: [],
                                    isChart: true
                                }
                                tabData.push(directionTabObj);
                            }
                        }

                        //this will loop through each direction, ie: Westbound, southbound, etc.
                        direction.movements.forEach(function (movement) {

                            //this will loop through each movement, ie: Thru, Thru-left, Thru-right, etc.
                            //we want to show a chart for each of these movements.
                            var allPhaseChartData = [];
                            //total movement volume
                            allPhaseChartData.push({
                                key: "Total Volume",
                                label: "Total Volume",
                                data: movement.totalVolumeData,
                                type: "LineWithLine",
                                yAxisID: '1',
                                borderDash: [10, 5],
                                strokeColor: colorScheme[0],
                                borderColor: colorScheme[0],
                                pointBackgroundColor: colorScheme[0],
                                fill: false,
                                pointRadius: 1,
                                pointHoverRadius: 4
                            });
                            if (movement.lanes.length > 1) {
                                var counter = 1;
                                movement.lanes.forEach(function (lane) {
                                    //this will loop through each lane in the movement, and add to chart
                                    allPhaseChartData.push({
                                        key: "Lane " + lane.laneName,
                                        label: "Lane " + lane.laneName,
                                        data: lane.volumeData,
                                        type: "LineWithLine",
                                        yAxisID: '1',
                                        strokeColor: colorScheme[counter],
                                        borderColor: colorScheme[counter],
                                        pointBackgroundColor: colorScheme[counter],
                                        fill: false,
                                        pointRadius: 1,
                                        pointHoverRadius: 4
                                    });
                                    counter++;
                                });
                            }
                            //push this phase chart back into the main chartArray. Each movement needs it's own chart
                            directionTabObj.charts.push({
                                title: "Turning Movement Counts for " + $scope.signal.description,
                                subLine1: direction.directionType + " " + movement.movementType + " " + laneTypeDesc + " Lanes",
                                subLine2: "Total Volume = " + movement.totalVolume + "; Peak Hour = " + movement.peakHour + "; Peak Hour Volume = " + movement.peakHourVolume + "; PHF = " + movement.phf + "; fLU = " + movement.flu,
                                dataset: allPhaseChartData,
                                planColor: "#000",
                                // chart: vm.tmcChartWidget.getChart(planList),
                                isDataAvailable: allPhaseChartData.length > 0 ? allPhaseChartData[0].data.length > 0 : false,
                                plans: vm.tmcChartWidget.addPlanLabels(planList),
                                api: {},
                                onDestroy: function () {
                                    this.api = {};
                                },
                                onApiInit: function (apiObj) {
                                    this.api = apiObj;
                                    this.render(apiObj);
                                },
                                render: function (apiObj) {
                                    apiObj.render(undefined, this.dataset, this.chartOptions);
                                    vm.chart =
                                        vm.chartRendering = false;
                                    this.isRendered = true;
                                },
                                hidePlanLabels: function (e, chart) {
                                    chart.plansHidden = !chart.plansHidden;
                                    chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                                    Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                                    this.plans.visible = false;
                                    if (chart.plansHidden) {
                                        chart.plans.forEach(function (plan) {
                                            plan.labels2 = plan.labels;
                                            plan.labels = [];
                                        });
                                        chart.planColor = "#fff";
                                    } else {
                                        chart.plans.forEach(function (plan) {
                                            plan.labels = plan.labels2;
                                        });
                                        chart.planColor = "#000";
                                    }
                                    chart.api.update();
                                },
                                isRendered: false,
                                chartOptions: {
                                    hidePlans: false,
                                    useCrosshair: true,
                                    responsive: true,
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            type: 'time',
                                            id: 'x-axis-0',
                                            gridLines: {
                                                display: false
                                            },
                                            ticks: {
                                                autoSkip: true,
                                                autoSkipPadding: 50,
                                                minRotation: 0,
                                                maxRotation: 0,
                                            }
                                        },
                                        ],
                                        yAxes: [{
                                            scaleLabel: {
                                                display: true,
                                                labelString: 'Volume (VPH)',
                                                fontFamily: 'Roboto',
                                                fontSize: 14,
                                            },
                                            id: '1',
                                            ticks: {
                                                beginAtZero: true
                                            },
                                            // stacked: true,
                                            // type: 'linear',
                                            position: 'left',

                                        }
                                        ]
                                    },
                                    legend: {
                                        display: true,
                                        align: 'end',
                                    },
                                    hover: {
                                        mode: 'nearest'
                                    },
                                    tooltips: {
                                        intersect: false,
                                        callbacks: {
                                            title: function (tooltipItems, data) {
                                                var tooltipItem = tooltipItems[0];
                                                return 'Bin Start Time: ' + tooltipItem.xLabel;
                                            },
                                            afterBody: function (t, d) {
                                                var plan = planList.filter(x => moment(x.startTime).isBefore(moment(t[0].label)) && moment(x.endTime).isAfter(moment(t[0].label)));

                                                var planText = "";
                                                if (!plan || plan.length == 0) return "";
                                                switch (plan[0].planNumber) {
                                                    case 0:
                                                    case 254:
                                                        planText = "Free";
                                                        break;
                                                    case 255:
                                                        planText = "Flash";
                                                        break;
                                                    default:
                                                        planText = plan[0].planNumber;
                                                        break;
                                                }

                                                return `\n Plan ${planText}`; //return a string that you wish to append
                                            }
                                        }
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        zoom: {
                                            pan: {
                                                enabled: false,
                                                mode: 'x',

                                                rangeMin: {
                                                    x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                                },
                                                rangeMax: {
                                                    x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                                }
                                            },
                                            zoom: {
                                                enabled: true,
                                                drag: true,
                                                speed: 0.1,
                                                mode: 'x',

                                                rangeMin: {
                                                    x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                                },
                                                rangeMax: {
                                                    x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                                }
                                            }
                                        }
                                    },
                                }
                            });
                        });
                    });
                };
                vm.tmcChartWidget.chartArray = tabData;
                // Sorting table's data  (Left-Thrue-ThrueRight)
                // Sorting table's headers (Left-Thrue-ThrueRight)
                // var table = vm.tmcChartWidget.chartArray[vm.tmcChartWidget.chartArray.length-1];
                // if(vm.tmcChartWidget.chartArray.length>0){
                //     if(table == 0) return 0;
                //     if(table.tabTitle.includes("Table")){
                //         let lengthOfDirectionHeaders = table.tableData.directionHeaders.length;
                //         var start = 0;
                //         var ordering = [];
                //         for(let i=0; i < lengthOfDirectionHeaders; i++){
                //             if(table.tableData.directionHeaders[i].colCount > 0){
                //                 var end = table.tableData.directionHeaders[i].colCount + start;
                //                 for(let k=start; k<end;k++){
                //                     var movementHeader = table.tableData.movementHeaders[k];
                //                     var temp = null;
                //                     for(let j=k; j<end; j++){
                //                         var currentMovementHeader = table.tableData.movementHeaders[j];
                //                         // According to flag which customOrder is going to return 
                //                         // we swap elements in array
                //                         // than we track these indexes to an array and pass it to new function orderingTableData or orderingTableData
                //                         // where we do swap according to the orders in "ordering" array
                //                         if(vm.customOrder(movementHeader.value) > vm.customOrder(currentMovementHeader.value)){
                //                             temp = movementHeader;
                //                             table.tableData.movementHeaders[k] = currentMovementHeader;
                //                             table.tableData.movementHeaders[j] = temp;
                //                             ordering.push(k);
                //                             ordering.push(j);
                //                         }
                //                     }
                //                 }
                //                 start = end;
                //             }
                //         }
                //         vm.orderingTableData(ordering);
                //         vm.orderingTableDataTotals(ordering);
                //     }
                // }
            }
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }
        // function orderingTableData(ordering){
        //     var temp = null;
        //     for(let i=0; i<vm.tmcChartWidget.dataTableDataFull.records.length;i++){
        //         for(let j=0;j<ordering.length;j+=2){
        //             temp = vm.tmcChartWidget.dataTableDataFull.records[i].values[ordering[j]].item1;
        //             vm.tmcChartWidget.dataTableDataFull.records[i].values[ordering[j]].item1 = vm.tmcChartWidget.dataTableDataFull.records[i].values[ordering[j+1]].item1;
        //             vm.tmcChartWidget.dataTableDataFull.records[i].values[ordering[j+1]].item1 = temp;
        //         }
        //     }
        //     vm.filter(vm.query.pageIndex, vm.query.pageSize);
        // }

        // function orderingTableDataTotals(ordering){
        //     var temp = null;
        //     for(let j=0;j<ordering.length;j+=2){
        //         temp = vm.tmcChartWidget.allTotals[ordering[j]];
        //         vm.tmcChartWidget.allTotals[ordering[j]] = vm.tmcChartWidget.allTotals[ordering[j+1]];
        //         vm.tmcChartWidget.allTotals[ordering[j+1]] = temp;
        //     }
        // }

        function customOrder(item) {
            switch (item) {
                case 'Thru':
                    return 3;
                case 'Thru-Left':
                    return 2;
                case 'Left':
                    return 1;
                case 'Thru-Right':
                    return 4;
                case 'Right':
                    return 5;
            }
        }
        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions, true)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    var rawData = JSON.stringify(data, null, 4);
                    vm.tmcChartWidget.processTmcData(JSON.parse(rawData, JSON.dateParser));
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
}());
