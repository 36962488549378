﻿(function () {
    'use strict';

    angular
        .module('app.spm.help')
        .controller('HelpController', HelpController);

    //purpose of this controller is to manage data bindings from HTML and the supplied i18n json files
    
    /** @ngInject */
    function HelpController($rootScope, $scope, $translate, jsonPathIndex, $window, $mdDialog , useCalculate) {          
        $scope.moreInfo = false;
        $scope.buttonText = "MORE INFO";
        $scope.buttonToolTip = "Show me more info"

       $scope.useCalculate = useCalculate;

        $translate(jsonPathIndex.toString() + '.TITLE')
            .then(function (translatedValue) {
                if (translatedValue) {
                    $scope.title = translatedValue;
                }
            });

        $translate(jsonPathIndex.toString() + '.HELP_DEF')
            .then(function (translatedValue) {
                if (translatedValue) {
                    $scope.cardInfo = translatedValue;
                }
            });

        $translate(jsonPathIndex.toString() + '.HELP_HOW_TO_USE_TEXT')
            .then(function (translatedValue) {
                if (translatedValue) {
                    $scope.howToUse = translatedValue;
                }
            });

        $translate(jsonPathIndex.toString() + '.HELP_KEEP_IN_MIND')
        .then(function (translatedValue) {
            if (translatedValue) {
                $scope.keepInMind = translatedValue;
            }
        });

        $translate(jsonPathIndex.toString() + '.HELP_MORE_INFO')
            .then(function (translatedValue) {
                if (translatedValue) {
                    $scope.moreInfoText = translatedValue;
                }
                else {
                    $scope.moreInfo = true;
                    $scope.buttonText = "NEED MORE HELP?";
                    $scope.buttonToolTip = "Visit our Helpdesk and submit a question!"
                }
            });


        $scope.cancel = function () {
            $mdDialog.cancel();
        };
        $scope.moreInfoFunc = function () {
            if ($scope.moreInfo && $scope.moreInfoText) {
                $scope.moreInfo = false;
                $scope.buttonText = "MORE INFO";
                $scope.buttonToolTip = "Show me more info"
                $window.open('https://traffop.freshdesk.com', '_blank');
                // telemetryService.insightsDashboardTelemetry("click", "help_desk");
            }
            else if ($scope.moreInfo && !$scope.moreInfoText) {
                $scope.moreInfo = true;
                $scope.buttonText = "NEED MORE HELP?";
                $scope.buttonToolTip = "Visit our Helpdesk and submit a question!"
                $window.open('https://traffop.freshdesk.com', '_blank');
                // telemetryService.insightsDashboardTelemetry("click", "help_desk");
            }
            else {
                $scope.moreInfo = true;
                $scope.buttonText = "NEED MORE HELP?";
                $scope.buttonToolTip = "Visit our Helpdesk and submit a question!"
                // telemetryService.insightsDashboardTelemetry("click", "help_more_info");
            }
        };
    }
})();