(function ()
{
    'use strict';

    angular
        .module('traffop.index')
        .directive('traffopIndex', function () {
            return {
                restrict: 'AE',
                bindToController: {
                    api: '=',
                },
                scope: {
                    param: '=',
                },
                template: `<div id="main" class="animate-slide-up" ui-view="main" layout="column">
                </div> `,
                controller: 'IndexController as vm'
            }
        })
        .controller('IndexController', IndexController);

    /** @ngInject */
    function IndexController($scope, fuseTheming, environmentConfig)
    {
        var vm = this;

        // Data
        vm.themes = fuseTheming.themes;
        //vm.themes = fuseTheming.themes;
        $scope.googleAnalyticsTrackingCode = environmentConfig.googleAnalyticsTrackingCode;

        vm.loadAnalytics = function () {
            ga('create', $scope.googleAnalyticsTrackingCode, 'auto');
        };
        //////////
    }
})();