﻿import * as GMAP from 'raw-loader!./google-map.html'

(function () {
    'use strict';

    angular.module('app.spm.core')
        .directive('googleMap', googleMapDirective);


    function googleMapDirective() {
        return {
            restrict: 'AE',
            bindToController: {
                api: '=',
                inverseHeatMapColors: "<",
            },
            scope: {
                param: '=',
                lessThanTwoSignals: '<',
                disableDefaultButtons: '<',
                draggableMarker: '<',
                editSignal: '<',
                addSignal: '<',
                signalDragendCallback: '&',
                showAllSignals: '<',
                useClustering: '<',
                showTrafficButton: '<',
                corridorConfig: '<',
                showHideMarkersButton: '<',
                draggableMap: '<',
                fullScreen: '<',
                showTraffic: '<',
                singleSignal: '<',
                controlSize: '<',
                hideContextMenu: '<',
                showOnlySignalId: '<',
                showDirectionSignals: '=',
                purdueLinkPivotOptions: '=',
                disableZoomControl: '<',
                showSelectedSignals: '=',
                rotateControls: '<',
                directionsUpdated: '&',
                directionsError: '&',
                signalAddedToCorridor: '&',
                signalRemovedFromCorridor: '&',
                mapType: '@',
                zoomLevel: '<',
                preventFocus: '<',
                zoomRestriction: '<',
                hideSignalMarker: '<',
                onApiInit: '&',
                addSignalToCorridor: '=',
                isSignalAdded: '&',
                isSessionStorage: '<',
                hideSignalMarkersOption: '<'
            },
            template: GMAP,
            controller: 'googleMapController as vm'
        }
    }
})();
