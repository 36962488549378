﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("aorChartController", aorChartController);

    function aorChartController($state, $attrs, $scope, $rootScope, $element, environmentConfig, chartsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 9;
        vm.chartServerError = false;

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input.length > 0) {
                    var jsonData = JSON.stringify(input, null, 4);
                    vm.chartServerError = false;
                    vm.aorChartWidget.processAorData(JSON.parse(jsonData, JSON.dateParser));
                    $scope.loading = false;
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        vm.aorChartWidget = {
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.aorChartWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            addPlanLabels: function (primaryPlans) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        plan.labels.push(planText, 'Vol: ' + plan.totalVolume + ' / AoR: ' + plan.totalArrivalOnRed, 'AoR: ' + ((plan.totalArrivalOnRed / plan.totalVolume) * 100).toFixed(2) + "%");
                        plansCopy[i] = plan;
                    }
                }
                return plansCopy;
            },
            //holds each chart data
            chartArray: {},
            //logic for taking server events and manipulating into format the chart is expecting
            processAorData: function (rawData) {
                var charts = {};
                var searchObj = searchBarService.getSearchOptions();

                //rawData is an array holding each phase data
                rawData.forEach(function (phaseItem) {
                    var planList = phaseItem.plans;
                    var totalsPerPlan = totalCarsAoR(planList);
                    var allPhaseChartData = [];
                    var aorSummary = phaseItem.aoRChartData;

                    if (!charts[phaseItem.direction]) {
                        charts[phaseItem.direction] = [];
                    }
                    //setup phase info object for later use on tooltips
                    //and to store the green/yellow/red/detector data 
                    var phaseInfo = {
                        direction: phaseItem.direction,
                        phaseNumber: phaseItem.phaseNumber,
                        totalPercentAoR: aorSummary.totalPercentAoR,
                        totalAoR: aorSummary.totalAoR,
                        totalCars: aorSummary.totalCars
                    };



                    if (chartsService.getChartComplexity() == 'Advanced') {
                        allPhaseChartData.push({
                            key: "Percentage AoR",
                            label: "Percentage AoR",
                            data: aorSummary.percentAoR,
                            type: "line",
                            yAxisID: '2',
                            borderDash: [10, 5],
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    allPhaseChartData.push({
                        key: "Total Vehicles",
                        label: "Total Vehicles",
                        data: aorSummary.totalVehicles,
                        type: "LineWithLine",
                        yAxisID: '1',
                        strokeColor: chartsService.colors.dark,
                        borderColor: chartsService.colors.dark,
                        pointBackgroundColor: chartsService.colors.dark,
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    });
                    allPhaseChartData.push({
                        key: "Total AoR",
                        label: "Total Aor",
                        data: aorSummary.arrivalsOnRed,
                        type: "LineWithLine",
                        yAxisID: '1',
                        strokeColor: chartsService.colors.signalRed,
                        borderColor: chartsService.colors.signalRed,
                        pointBackgroundColor: chartsService.colors.signalRed,
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    });

                    var phaseTypeText = phaseItem.isPermissive ? "Permissive" : "Protected";
                    //push this phase chart back into the main chartArray. Each phase needs it's own chart
                    charts[phaseItem.direction].push({
                        title: "Arrivals on Red",
                        //advNeSw.primaryPlans, advNeSw.secondaryPlans, "Northeast", "Southwest"
                        plans: vm.aorChartWidget.addPlanLabels(planList),
                        subLine1: $scope.signal.description + " - " + phaseInfo.direction + " " + phaseItem.movement + " - " + phaseTypeText + " Phase " + phaseItem.phaseNumber,
                        subLine2: "Volume: " + aorSummary.totalCars + " / AoR: " + aorSummary.totalAoR + " / AoR Percent: " + Math.round(aorSummary.totalPercentAoR * 100) / 100 + "%",
                        dataset: allPhaseChartData,
                        planColor: "#000",
                        isDataAvailable: aorSummary.totalAoR > 0,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(this, chart.plansHidden);
                            this.plans.visible = false;
                            if (chart.plansHidden) {
                                this.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                chart.planColor = "#cc0000";
                            } else {
                                this.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                chart.planColor = "#cc0000";
                            }
                            this.api.update();
                        },
                        isRendered: false,
                        chartOptions: {
                            useCrosshair: true,
                            animation: false,
                            tooltips: {
                                intersect: false,
                            },
                            legend: {
                                display: true,
                                align: 'end',
                            },
                            hidePlans: false,
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    time: {
                                        unit: vm.xaxeFormat
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        minRotation: 0,
                                        maxRotation: 0,
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Volume (Vehicles Per Hour)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '1',
                                    position: 'left',
                                    gridLines: {
                                        display: false
                                    },
                                },
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Percent AoR',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '2',
                                    position: 'right',
                                    gridLines: {
                                        display: false
                                    },
                                }
                                ]
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                        }
                    });
                });

                //set our chart array back to our binding object
                vm.aorChartWidget.chartArray = charts;
            }
        }


        function totalCarsAoR(plans) {
            let results = [0, 0, 0.00];
            for (let i = 0; i < plans.length; i++) {
                results[0] += plans[i].totalVolume;
                results[1] += plans[i].totalArrivalOnRed;
            }
            results[2] = (results[1] * 100) / results[0];
            return results;
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {

            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions, true)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    var rawData = JSON.stringify(data, null, 4);
                    vm.aorChartWidget.processAorData(JSON.parse(rawData, JSON.dateParser));
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
}());
