﻿
import moment from 'moment';
import * as CRDSEARCH from 'raw-loader!./panels/corridor-search.html';
import * as SIGNALSEARCH from 'raw-loader!./panels/signal-search.html';
import * as TIMEPICKER from 'raw-loader!./panels/time-picker.html';
import * as OPTSPANEL from 'raw-loader!./panels/options.html'

(function () {
    'use strict';

    angular
        .module('app.searchbar')
        .controller('SearchbarController', SearchbarController);

    /** @ngInject */
    function SearchbarController($rootScope, $scope, $element, $q, $state, $compile, $window, $timeout, $mdSidenav, searchBarService, userSettingsService, signalService, corridorService, routeService, $mdPanel, $mdDialog, $mdToast, breadcrumbNavigationService, newWindowService, insightsService, dataExportService, helpService, $interval, chartsService) {
        var vm = this;
        vm.searchBarService = searchBarService;
        vm.isFiltering = vm.searchBarService.isFiltering();
        vm.options = {};
        vm.configuration = {};
        vm.signalService = signalService;
        vm.getSearchOptions = getSearchOptions;
        vm.showOptionsPanel = showOptionsPanel;
        vm.showOverviewPanel = showOverviewPanel;
        vm.errorPanelToast = errorPanelToast;
        vm.openCorridorSearch = openCorridorSearch;
        vm.setTimeText = setTimeText;
        vm.formatAMPM = formatAMPM;
        vm.getSignals = getSignals;
        vm.corridorService = corridorService;
        vm.disableTimeFrameButton = disableTimeFrameButton;
        vm.wildcardSearchText = "";
        vm.wildcardSearchChange = wildcardSearchChange;
        vm.onSignalSet = onSignalSet;
        vm.loading = false;
        vm.csvExport = csvExport;
        vm.excelExport = excelExport;
        vm.pdfExport = pdfExport;
        vm.windowResize = windowResize;
        vm.notifyHelp = notifyHelp;
        vm.popoutContent = popoutContent;
        vm.breadcrumbNavigationService = breadcrumbNavigationService;
        vm.openTimePicker = openTimePicker;
        vm.openSignalSearch = openSignalSearch;
        vm.getBreadCrumbs = getBreadCrumbs;
        vm.navigate = navigate;
        vm.navigateBackOne = navigateBackOne;
        vm.openPanelRef = undefined;
        vm.closeDialog = closeDialog;
        vm.corridorService = corridorService;
        vm.getCorridors = getCorridors;
        vm.clearSelection = clearSelection;
        vm.isValidSelection = isValidSelection;
        vm.preserveSearchWidthOnClear = preserveSearchWidthOnClear;
        vm.addPanelAnimation = addPanelAnimation;
        // vm.monthlyIntersectionReportService = monthlyIntersectionReportService;

        vm.timeText = {
            dates: "",
            times: "",
        }
        vm.signalList = [];
        vm.corridorList = [];
        vm.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        vm.formatDate = formatDate;
        vm.signalDescription = "";
        vm.getNewTimeFrame = getNewTimeFrame;
        vm.investigateInsight = investigateInsight;
        vm.toggleOptionsSidenav = toggleOptionsSidenav;
        vm.toggleBookmarkedSidenav = toggleBookmarkedSidenav;
        vm.windowResize = windowResize;
        vm.getSearchBarData = getSearchBarData;
        vm.goToInsightsDashboard = goToInsightsDashboard;
        vm.firstLastOccurences = firstLastOccurences;
        vm.chartUserOptions = {};
        vm.setFullMonth = setFullMonth;
        var date = new Date(), y = date.getFullYear(), m = date.getMonth() - 1;
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.reportLoading = true;

        vm.currentDate = lastDay;
        vm.maxDate = lastDay;

        function pdfExport() {
            vm.pdfLoading = true;
            monthlyIntersectionReportService.triggerReport("pdfReport");
        }

        //setup options change also
        // vm.monthlyIntersectionReportService.subscribe($scope, function onChange(ev, options) {
        //     if (!vm)
        //         return;

        //     switch (options) {
        //         case "pdfReportGenerated":
        //         case "pdfReportError":
        //             vm.pdfLoading = false;
        //             break;
        //         case "reportLoaded":
        //             vm.reportLoading = false;
        //     }
        // });

        vm.signalService.subscribe($scope, function onChange(ev, options) {
            if (!vm)
                return;
            switch (options.type) {
                case "signalConfiguration":
                case "approachConfiguration":
                case "detectorConfiguration":
                    vm.getSearchBarData();
                    vm.getSignals();
                    break;
                case "signalRemoved":
                case "signalStatusChanged":
                    if (vm.signal && vm.signal.signalID == options.props) {
                        vm.signal = null;
                        searchBarService.setSearchOptionsNoNotify(null);
                    }
                    vm.getSignals();
                    break;
            }
        });

        function setFullMonth() {
            //searchBarService.searsetTimeOptions();
            var options = searchBarService.getSearchOptions();
            var timeOptions = options.timeOptions;

            var date = vm.currentDate, y = date.getFullYear(), m = date.getMonth();
            var lastDay = new Date(y, m + 1, 0, 23, 59, 59);

            timeOptions.currentFilter.startDateAndTime = vm.currentDate;
            timeOptions.currentFilter.endDateAndTime = lastDay;

            timeOptions.timeOption = "TimePeriod";

            searchBarService.setTimeOptions(timeOptions);
        }

        vm.queryData = {
            orderBy: '',
            pageSize: 10,
            pageIndex: 1,
            filter: '',
            start: '',
            end: '',
            signalID: '',
        };

        function setInsightCount() {
            if (vm.queryData.signalID != '' && vm.queryData.start != '' && vm.queryData.end) {
                insightsService.getSystemInsightsBySignal(vm.queryData).then(function (data) {
                    vm.summedInsightsCount = data.highestConcernSummary ? data.highestConcernSummary.count : 0;
                    if (data.highestConcernSummary) {
                        switch (data.highestConcernSummary.concern.level) {
                            case 1:
                                vm.color = "#D0021B";
                                break;
                            case 2:
                                vm.color = "#F5A623";
                                break;
                            case 3:
                                vm.color = "#6A7B8B";
                                break;
                            case 4:
                                vm.color = "#4CAF50";
                                break;
                        }
                    }
                });
            }
        }

        $rootScope.$on("insight-ignored", function () {
            setInsightCount();
        });

        signalService.getAllSignalsWithApproaches().then(function () {
            vm.getSearchBarData();
            setInsightCount();
        });

        function firstLastOccurences(first, last) {
            var notSame = true;
            if (first == last) notSame = false;
            else notSame = true;

            return notSame;
        }

        function wildcardSearchChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                vm.searchBarService.setWildcardText(newValue);
            }
        }

        function disableTimeFrameButton() {
            if (vm.configuration.timeFrameConfig.enableWithoutFiltering)
                return false;
            return !vm.isFiltering;
        }



        vm.corridors = {
            selected: null,
            queryData: {
                orderBy: 'corridorID',
                pageSize: 10,
                pageIndex: 1,
                filter: ''
            },
            search: function () {
                //query the server for our signals data source for autocomplete
                var res = corridorService.queryCorridors(this.queryData).then(function (data) {
                    return data.corridors;
                });

                return res;
            },
            corridorChange: function (item) {
                vm.searchBarService.setCorridor(vm.corridors.selected);
                if (!vm.corridors.selected) {
                    vm.corridors.queryData.filter = "";
                }
            },
        };

        vm.routes = {
            selected: null,
            queryData: {
                orderBy: 'approachRouteId',
                pageSize: 10,
                pageIndex: 1,
                filter: ''
            },
            search: function () {
                //query the server for our signals data source for autocomplete
                var res = routeService.queryRoutes(this.queryData).then(function (data) {
                    return data.routes;
                });

                return res;
            },
            routeChange: function (item) {
                vm.searchBarService.setRoute(vm.routes.selected);
                if (!vm.routes.selected) {
                    vm.routes.queryData.filter = "";
                }
            },
        };

        window.angular.element($window).on('resize', vm.windowResize);
        var delayResizeTimer;

        function windowResize() {
            if (vm.openPanelRef && vm.openPanelRef.isAttached) {
                clearTimeout(delayResizeTimer);
                vm.openPanelRef.close();
                vm.openPanelRef.destroy();
            }
        }


        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm)
                return;

            let currentEndDate = vm.queryData.end;
            vm.reportLoading = true;

            if (changeType == "configuration") {
                // vm.getSignals();
                if ($mdSidenav('insights-sidepanel') && vm.configuration.showSignalNotificationTabs && vm.queryData.signalID !== "" && !angular.element('.insights-sidemenu').hasClass('md-closed')) {
                    $mdSidenav('insights-sidepanel').toggle();
                }

                vm.getSearchOptions();
                if (currentEndDate) {
                    let endDateFromOptions = vm.options.timeOptions.currentFilter.endDateAndTime.toLocaleString();
                    if (currentEndDate != endDateFromOptions) {
                        vm.getSearchBarData();
                    }
                }

                if (vm.queryData.signalID != "") {
                    var cancelIntervalInsightsButton = $interval(function () {
                        vm.insightsSideMenuButton = document.getElementById('insights-lightbulb-div');

                        if (vm.insightsSideMenuButton) {
                            vm.insightsSideMenuButton.addEventListener('mousedown', setupInsightsEventListeners, false);
                            $interval.cancel(cancelIntervalInsightsButton);
                        }
                    }, 200);
                }

                //set content class
                var contentContainer = angular.element(document.querySelector('.main-content-container'));
                contentContainer.removeClass('full-header-with-searchbar');
                contentContainer.removeClass('header-no-searchbar');
                contentContainer.removeClass('hide-header');

                var contentClass = 'full-header-with-searchbar';
                if (!vm.configuration.header.show) {
                    contentClass = 'hide-header';
                }
                else if (!vm.configuration.showSearchBar) {
                    contentClass = 'header-no-searchbar';
                }
                contentContainer.addClass(contentClass);
                vm.isFiltering = vm.searchBarService.isFiltering();

                if (vm.openPanelRef) {
                    vm.openPanelRef.close();
                    vm.openPanelRef.destroy();
                }
            }
            if (changeType == "signal" || changeType == "time" || changeType == "corridor") {
                vm.getSearchOptions();
                if (changeType == "signal" || changeType == "time") {
                    vm.getSearchBarData();
                }
                if (vm.queryData.signalID != "") {
                    var cancelIntervalInsightsButton = $interval(function () {
                        vm.insightsSideMenuButton = document.getElementById('insights-lightbulb-div');

                        if (vm.insightsSideMenuButton) {
                            vm.insightsSideMenuButton.addEventListener('mousedown', setupInsightsEventListeners, false);
                            $interval.cancel(cancelIntervalInsightsButton);
                        }
                    }, 200);
                }

                setInsightCount();
            }

            if (changeType == "state") {
                vm.isFiltering = searchBarService.isFiltering();
            }

            vm.getBreadCrumbs();
        });


        function getBreadCrumbs() {
            vm.breadCrumbs = vm.breadcrumbNavigationService.getBreadcrumbTrail();
        }

        function navigate(bcIndex) {
            vm.setTimeText();
            vm.breadcrumbNavigationService.navigateToBreadCrumbIndex(bcIndex);
        }

        function navigateBackOne() {
            vm.setTimeText();
            vm.breadcrumbNavigationService.navigateBackOneBreadcrumb();
        }

        function getSearchOptions() {
            vm.preserveSearchWidthOnClear();
            vm.options = vm.searchBarService.getSearchOptions();
            vm.configuration = vm.searchBarService.getSearchBarConfig();
            vm.wildcardSearchText = vm.options.wildcardSearchText;
            vm.setTimeText();
        };

        function preserveSearchWidthOnClear() {
            //maintain width when we are clearing search option for a bit easier usability
            let width = "", controlElem;
            let newOptions = vm.searchBarService.getSearchOptions();
            switch (vm.configuration.searchType) {
                case 'Signals':
                    controlElem = angular.element(document.querySelectorAll(".signal-search-parent")[0]);
                    if ((!newOptions.signal || !newOptions.signal.signalID) && vm.options.signal && vm.options.signal.signalID) {
                        //account for padding 34px
                        width = controlElem[0].clientWidth + 34;
                    }
                    break;
                case 'Corridors':
                    controlElem = angular.element(document.querySelectorAll(".corridor-search-parent")[0]);
                    if ((!newOptions.corridor || !newOptions.corridor.corridorID) && vm.options.corridor && vm.options.corridor.corridorID) {
                        //account for padding 34px
                        width = controlElem[0].clientWidth + 34;
                    }
                    break;
            }
            if (controlElem) {
                if (width < 150)
                    width = "";

                controlElem.css('width', width);
            }
        }

        function popoutContent() {
            newWindowService.openCurrentInNewTab();
            telemetryService.insightsDashboardTelemetry("click", "open_in_new_tab");
        }

        function addPanelAnimation(openToCssClass) {
            var animation = $mdPanel.newPanelAnimation();
            animation.duration(300);
            animation.openFrom(openToCssClass);
            animation.closeTo(openToCssClass);
            animation.withAnimation($mdPanel.animation.FADE);
            return animation;
        }

        function showOverviewPanel() {
            var position = $mdPanel.newPanelPosition()
                .relativeTo('.overview-dropdown')
                .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

            var config = {
                animation: vm.addPanelAnimation('.overview-dropdown'),
                attachTo: angular.element(document.body),
                templateUrl: 'app/searchbar/panels/overview.html',
                hasBackdrop: false,
                multiple: true,
                position: position,
                scope: $scope.$new(),
                trapFocus: false,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                panelClass: 'traffop-animated',
                focusOnOpen: true,
            };

            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        }

        function errorPanelToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelector('#layout-vertical-navigation'))
                    .textContent('Oops.. We ran into an error. Please report issue on helpdesk.')
                    .position("top right")
                    .hideDelay(5000)
            );
        }

        function showOptionsPanel() {
            var position = $mdPanel.newPanelPosition()
                .relativeTo('.mdi-tune')
                .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

            var config = {
                animation: vm.addPanelAnimation('.mdi-tune'),
                attachTo: angular.element(document.body),
                template: OPTSPANEL,
                controller: 'optionsController as vm',
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: false,
                preserveScope: false,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                onRemoving: function (event, removePromise) {
                    // telemetryService.insightsDashboardTelemetry("close", "filters");
                },
                panelClass: 'traffop-animated',
                focusOnOpen: true,
                locals: {
                    on_close: function (isApply) {
                        vm.openPanelRef.close();
                        vm.openPanelRef.destroy();

                        // if (!isApply) {
                        //     telemetryService.insightsDashboardTelemetry("close", "filters");
                        // }
                    }
                }
            };

            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
                // telemetryService.insightsDashboardTelemetry("open", "filters");
            });
        }

        function closeDialog() {
            vm.openPanelRef.close();
            vm.openPanelRef.destroy();
        }

        function openTimePicker(ev) {
            var position = $mdPanel.newPanelPosition()
                .relativeTo('.time-frame-parent')
                .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

            var config = {
                animation: vm.addPanelAnimation('.time-frame-parent'),
                attachTo: angular.element(document.body),
                template: TIMEPICKER,
                controller: 'timePickerController as vm',
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: false,
                escapeToClose: false,
                focusOnOpen: true,
                panelClass: 'traffop-animated',
                locals: {
                    on_change: function () {
                        //do stuff
                        vm.openPanelRef.close();
                        vm.openPanelRef.destroy();
                    },
                    on_cancel: function () {
                        vm.openPanelRef.close();
                        vm.openPanelRef.destroy();
                    }
                }
            };

            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        }

        function openSignalSearch() {
            var position = $mdPanel.newPanelPosition()
                .relativeTo('.signal-search-parent')
                .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

            var config = {
                animation: vm.addPanelAnimation('.signal-search-parent'),
                attachTo: angular.element(document.body),
                template: SIGNALSEARCH,
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                panelClass: 'traffop-animated',
                controller: 'signalSearchController as vm',
                locals: {
                    signal_list: vm.signalList,
                    on_signal_set: function () {
                        vm.openPanelRef.close();
                        vm.openPanelRef.destroy();
                        vm.onSignalSet();
                    },
                    on_close: function () {
                        vm.openPanelRef.close();
                        vm.openPanelRef.destroy();
                    }
                }
            };

            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        }

        function openCorridorSearch() {
            var position = $mdPanel.newPanelPosition()
                .relativeTo('.corridor-search-parent')
                .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);

            var config = {
                animation: vm.addPanelAnimation('.corridor-search-parent'),
                attachTo: angular.element(document.body),
                template: CRDSEARCH,
                hasBackdrop: true,
                multiple: true,
                position: position,
                trapFocus: true,
                zIndex: 150,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true,
                panelClass: 'traffop-animated',
                controller: 'corridorSearchController as vm',
                locals: {
                    corridor_list: vm.corridorList,
                    on_corridor_set: function () {
                        vm.openPanelRef.close();
                        vm.openPanelRef.destroy();
                        // vm.onSignalSet();
                    },
                    on_close: function () {
                        vm.openPanelRef.close();
                        vm.openPanelRef.destroy();
                    }
                }
            };

            $mdPanel.open(config).then(function (ref) {
                vm.openPanelRef = ref;
            });
        }

        function onSignalSet() {

        }

        function setTimeText() {
            let timeOptions = vm.options.timeOptions;

            if (timeOptions.bin == 'Day' && $state.current.url == '/corridor-performance') {
                var momentStart = moment(timeOptions.currentFilter.startDateAndTime);
                var momentEnd = moment(timeOptions.currentFilter.endDateAndTime);

                vm.options.timeOptions.currentFilter.startDateAndTime = new Date(momentStart.startOf('day'));
                vm.options.timeOptions.currentFilter.endDateAndTime = new Date(momentEnd.endOf('day'));
            }

            if (timeOptions) {
                let start = timeOptions.currentFilter.startDateAndTime;
                let end = timeOptions.currentFilter.endDateAndTime;
                //depending on month and date we'll show different text, eg:
                //Same month, different day: Oct 15 - 21
                //Multiple months: Aug 13 - Oct 15
                //Same day/month: Nov 3
                //handle when months are the same
                if (start.getMonth() === end.getMonth()) {
                    if (start.getDate() === end.getDate()) {
                        //start and end date are exactly same
                        vm.timeText.dates = vm.monthNamesShort[start.getMonth()] + " " + start.getDate() + ", " + start.getFullYear();
                    }
                    else {
                        //same month different date
                        vm.timeText.dates = vm.monthNamesShort[start.getMonth()] + " " + start.getDate() + ", " + start.getFullYear() + " - " + vm.monthNamesShort[start.getMonth()] + " " + end.getDate() + ", " + start.getFullYear();
                    }
                }
                else {
                    //start and end dates span different months
                    vm.timeText.dates = vm.monthNamesShort[start.getMonth()] + " " + start.getDate() + ", " + start.getFullYear() + " - " + vm.monthNamesShort[end.getMonth()] + " " + end.getDate() + ", " + end.getFullYear();
                }
                // var roundedDates = vm.searchBarService.roundTimeToBin(vm.searchBarService.getSearchBarConfig().moreOptionsMenu.skipStepsPerBin, timeOptions.bin, start, end);
                // start = new Date(roundedDates.start);
                // end = new Date(roundedDates.end);
                vm.timeText.times = vm.formatAMPM(start) + " - " + vm.formatAMPM(end);


            }
        }

        function isValidSelection() {
            if (!vm.configuration)
                return false;

            let res = false;

            switch (vm.configuration.searchType) {
                case 'Wildcard':
                    if (vm.wildcardSearchText)
                        res = true;
                    break;
                case 'Signals':
                    if (vm.options.signal && vm.options.signal.signalID)
                        res = true;
                    break;
                case 'Corridors':
                    if (vm.options.corridor && vm.options.corridor.corridorID)
                        res = true;
                    break;
            }
            return res;
        }

        function clearSelection() {
            if (!vm.configuration)
                return;

            switch (vm.configuration.searchType) {
                case 'Wildcard':
                    //clear wildcard search text
                    vm.wildcardSearchChange("", vm.wildcardSearchText);
                    vm.wildcardSearchText = "";
                    break;
                case 'Signals':
                    vm.searchBarService.setSignal({
                        signalID: '',
                        description: '',
                    });
                    break;
                case 'Corridors':
                    vm.searchBarService.setCorridor({
                        corridorID: '',
                        description: '',
                    });
                    break;
            }
            return;
        }

        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        function getSignalDescription(customId, primaryName, secondaryName) {

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '') {
                res += " - " + secondaryName;
            }

            return res;
        }

        function getSignals() {
            vm.signalList = [];
            vm.signalService.getAllSignalsWithApproaches()
                .then(function (data) {
                    data.signals.forEach(function (signal, i) {
                        signal.description = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                        if (signal.enabled) {
                            vm.signalList.push(signal);
                        }
                    });
                    vm.signalList.sort((a, b) => a.customID - b.customID)
                });
        }

        function getCorridors() {
            vm.corridorService.getAllCorridors()
                .then(function (data) {
                    vm.corridorList = data.corridors;
                });
        }

        function excelExport(metricTypeID) {
            vm.excelLoading = true;
            // telemetryService.insightsDashboardTelemetry("click", "export_data");
            if (vm.configuration.dashboardType == 'Corridor') {
                var queryObj = dataExportService.getCurrentQueryObject();
                queryObj.id = vm.options.corridor.corridorID;
                queryObj.typeId = metricTypeID;
                queryObj.fileType = "xlsx";
                queryObj.filter = '';
                dataExportService.getDashboardExcelFile(queryObj).then(function (e) {
                    vm.excelLoading = false;
                })
                    .catch(function (error) {
                        if (vm) {
                            vm.excelLoading = false;
                            vm.errorPanelToast()
                        }
                    });
            }
            else if (vm.configuration.dashboardType == 'Signal') {
                var queryObj = dataExportService.getCurrentQueryObject();
                queryObj.id = vm.options.signal.signalID;
                queryObj.typeId = metricTypeID;
                queryObj.fileType = "xlsx";
                queryObj.filter = '';
                dataExportService.getDashboardExcelFile(queryObj).then(function (e) {
                    vm.excelLoading = false;
                    queryObj.makingError = true;
                })
                    .catch(function (error) {
                        if (vm) {
                            vm.excelLoading = false;
                            vm.errorPanelToast()
                        }
                    });
            }
            else if (vm.configuration.dashboardType == 'Insights') {
                var queryObj = dataExportService.getCurrentQueryObject();
                var queryObj = {
                    start: queryObj.start,
                    end: queryObj.end,
                    fileType: "xlsx",
                    insightTypeIDs: undefined,
                    insightCategoryIDs: [$state.current.params.insightCategory]
                };
                dataExportService.getInsightsExcelFile(queryObj).then(function (e) {
                    vm.excelLoading = false;
                })
                    .catch(function (error) {
                        if (vm) {
                            vm.excelLoading = false;
                            vm.errorPanelToast()
                        }
                    });
            }
            else {
                var chartOptions = {};
                var queryObj = {};
                if (metricTypeID != 92 && metricTypeID != 91) {
                    chartsService.getChartOptionsForSignal(vm.options.signal.signalID, metricTypeID).then(function (data) {
                        chartOptions = data;
                        queryObj = dataExportService.getCurrentMetricQueryObject(chartOptions, metricTypeID);
                        queryObj.metricTypeID = metricTypeID;
                        queryObj.fileType = "xlsx";
                        if (metricTypeID == 11) {
                            vm.chartUserOptions = chartsService.getYellowRedOptions();
                            queryObj.metricOptions.severeLevelSeconds = vm.chartUserOptions.severeLevelSeconds;
                            queryObj.metricOptions.forceDetectionTypeId = vm.chartUserOptions.forceDetectionTypeId;
                            queryObj.metricOptions.speedFilter = vm.chartUserOptions.speedFilter;
                        }
                        if (metricTypeID == 2) {
                            queryObj.metricOptions.showAsStacked = true;
                        }
                        if (metricTypeID == 6) {
                            vm.chartUserOptions = chartsService.getPcdOptions();
                            queryObj.metricOptions.carsInPlatoon = vm.chartUserOptions.carsInPlatoon;
                            queryObj.metricOptions.headwayInSec = vm.chartUserOptions.headwayInSec;
                            queryObj.metricOptions.showCarsInQueue = vm.chartUserOptions.showCarsInQueue;
                        }
                        dataExportService.getExcelFile(queryObj).then(function (e) {
                            vm.excelLoading = false;
                        })
                            .catch(function (error) {
                                if (vm) {
                                    vm.excelLoading = false;
                                    vm.errorPanelToast()
                                }
                            });
                    })
                        .catch(function (err) {
                            if (vm) {
                                vm.excelLoading = false;
                                vm.errorPanelToast()
                            }
                        });
                }
                else {
                    queryObj = dataExportService.getCurrentMetricQueryObject(chartOptions, metricTypeID);
                    queryObj.metricTypeID = metricTypeID;
                    queryObj.fileType = "xlsx";

                    dataExportService.getExcelFile(queryObj).then(function (e) {
                        vm.excelLoading = false;
                    })
                        .catch(function (error) {
                            if (vm) {
                                vm.excelLoading = false;
                                vm.errorPanelToast()
                            }
                        });
                }
            }

            // telemetryService.searchbarExcelExport(
            //     sessionStorage.userName,
            //     "click",
            //     "export_data",
            //     "???",

            // );
        }

        function csvExport() {
            vm.excelLoading = true;
            var queryObj = dataExportService.getCurrentQueryObject();
            queryObj.fileType = "csv";
            dataExportService.getRawCsvFile(queryObj).then(function (e) {
                vm.excelLoading = false;
            });
        }

        function notifyHelp(ev) {
            helpService.showHelpDialog(vm.configuration.helpJsonPropertyPath, ev, "How We Calculate It");
            // telemetryService.insightsDashboardTelemetry("click", "help");
        }

        function formatDate(date) {
            // const date = Date.parse(d);

            var dateStr =
                vm.monthNamesShort[date.getMonth()] + "-" +
                ("00" + date.getDate()).slice(-2) + "-" +
                date.getFullYear() + " " +
                formatAMPM(date);
            return dateStr;
        }

        function goToInsightsDashboard() {
            var newTimeOptions = vm.getNewTimeFrame(vm.queryData.start, vm.queryData.end);
            breadcrumbNavigationService.navigateToState('app.spm.dashboards.insights', newTimeOptions, vm.signal)
            vm.openPanelRef.close();
            vm.openPanelRef.destroy();
        }

        function getNewTimeFrame(start, end) {
            var options = vm.searchBarService.getSearchOptions();
            options.timeOptions.timeFrame = "custom";
            var startDate = new Date(start).toLocaleString();
            var endtDate = new Date(end).toLocaleString();
            options.timeOptions.currentFilter.startDateAndTime = new Date(startDate);
            options.timeOptions.currentFilter.endDateAndTime = new Date(endtDate);
            return options.timeOptions;
        }

        function investigateInsight(start, end, investigateAction, approachID) {
            var startDate = moment(start).startOf('day');
            var endDate = moment(end).endOf('day');
            var newTimeOptions = vm.getNewTimeFrame(startDate, endDate);

            vm.signal.description = getSignalDescription(vm.signal.customID, vm.signal.primaryName, vm.signal.secondaryName);

            newWindowService.openNewTabTimeAndSignal(
                investigateAction,
                newTimeOptions,
                vm.signal,
                { 'focusApproachId': approachID }
            );
        }

        // ================================ SIDE MENU ========================================= //

        function toggleOptionsSidenav() {
            // Toggle the side menu on/off
            if (!vm.isUserDragging &&
                !document.getElementById('main').classList.contains('pinned-sidemenu')) $mdSidenav('insights-sidepanel').toggle();
        }

        function toggleBookmarkedSidenav() {
            if (!vm.isUserDragging &&
                !document.getElementById('main').classList.contains('pinned-bookmarks')) {
                $mdSidenav('bookmarked-insights').toggle();
                $rootScope.$broadcast('bookmarks-open');
            }
        }

        // wait for the lightbulb div to be inserted in DOM before setting the event listener
        var cancelIntervalInsightsButton = $interval(function () {
            vm.insightsSideMenuButton = document.getElementById('insights-lightbulb-div');

            if (vm.insightsSideMenuButton) {
                vm.insightsSideMenuButton.addEventListener('mousedown', setupInsightsEventListeners, false);
                $interval.cancel(cancelIntervalInsightsButton);
            }
        }, 200);

        function setupInsightsEventListeners(event) {
            event.preventDefault();
            angular.element('#insights-lightbulb-div').css('cursor', 'grabbing');
            vm.initX = this.offsetLeft;
            vm.initY = this.offsetTop;
            vm.mousePressX = event.clientX;
            vm.mousePressY = event.clientY;
            vm.mouseMoving = false;
            vm.isUserDragging = false;

            this.addEventListener('mousemove', repositionElement, false);

            window.addEventListener('mouseup', cleanupInsightEventListeners, false);

        }

        function cleanupInsightEventListeners() {
            angular.element('#insights-lightbulb-div').css('cursor', 'grab');
            if (vm.insightsSideMenuButton) vm.insightsSideMenuButton.removeEventListener('mousemove', repositionElement, false);
            if (vm.mouseMoving) vm.isUserDragging = true;
            vm.mouseMoving = false;
        }

        function repositionElement(event) {
            event.preventDefault();
            vm.mouseMoving = true;
            this.style.top = vm.initY + event.clientY - vm.mousePressY + 'px';
            if (event.clientY < 220) {
                this.style.top = '192.5px';
            }
            if (event.clientY > window.innerHeight - 30) {
                this.style.top = window.innerHeight - 70 + "px";
            }
        }

        // ================================ SIDE MENU ========================================= //


        function getSearchBarData() {
            var opts = searchBarService.getSearchOptions();
            if (opts.signal && opts.signal.signalID != "") {
                vm.queryData.signalID = opts.signal.signalID;
                signalService.getSignalById(vm.queryData.signalID)
                    .then(function (signal) {
                        vm.signal = signal;
                        vm.signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                    })
            }
            vm.queryData.start = opts.timeOptions.currentFilter.startDateAndTime.toLocaleString();
            vm.queryData.end = opts.timeOptions.currentFilter.endDateAndTime.toLocaleString();
        }

        vm.getSignals();
        vm.getCorridors();

        $scope.$on("$destroy", function () {
            if (vm.insightsSideMenuButton) {
                vm.insightsSideMenuButton.removeEventListener("mousedown", setupInsightsEventListeners, false)
                vm.insightsSideMenuButton.removeEventListener("mousemove", repositionElement, false)
            }
            for (let member in vm) {
                vm[member] = null;
            }

            window.removeEventListener('mouseup', cleanupInsightEventListeners, false);
            window.angular.element($window).off('resize', vm.windowResize);
        });
    }
}
)();