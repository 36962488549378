﻿(function () {
    'use strict';

    angular
        .module('app.spm.core', ['ngMaterial', 'mdPickers'])
        .config(config);

    /** @ngInject */
    function config($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('google-maps');
    $translatePartialLoaderProvider.addPart('insights-sidepanel');
    }
})();