import * as TMPLT from 'raw-loader!./timeframe-picker.html'
(function () {

    var app = angular
        .module('app.spm.core')
        .directive('timeframePicker', timeframePickerDirective)
        .controller('timeframePickerCtrl', timeframePickerCtrl);


    app.constant('TimeframeConstants', {
        TODTemplates: {
            "FD": "Full Day",
            "AM": "AM Peak",
            "PM": "PM Peak",
            "MD": "Midday",
            "CT": "Custom",
        },
        DateTemplates: {
            'TD': 'Today',
            'YD': 'Yesterday',
            'TW': 'This Week',
            'TM': 'This Month',
            'TY': 'This Year',
            'LW': 'Last Week',
            'LM': 'Last Month',
            'L3M': 'Last 3 Months',
            'L6M': 'Last 6 Months',
            'LY': 'Last Year'
        },
        DateTemplateOrder: {
            'TD': 1,
            'YD': 2,
            'TW': 3,
            'TM': 4,
            'TY': 5,
            'LW': 6,
            'LM': 7,
            'L3M': 8,
            'L6M': 9,
            'LY': 10
        }
    });

    function timeframePickerDirective(timeframeMenuService, $compile) {
        var directive = {
            scope: {
                selectedTemplate: '=?',
                selectedTodTemplate: '=?',
                dateStart: '=?',
                dateEnd: '=?',
                selectedWeekdays: '=?',
                firstDayOfWeek: '=?',
                showTemplate: '=?',
                mdOnSelect: '&',
                localizationMap: '=?',
                customTemplates: '=?',
                disableTemplates: '@',
                onePanel: '=?',
                isDisabledDate: '&?',
                format: '=?',
                onTimeChange: '&',
                api: '=?',
                applyCallback: '&',
                enableMaxRange: '=',
                timeError: '=',
                minuteSteps: '=',
            },
            template: TMPLT,
            controller: 'timeframePickerCtrl',
            link: function (scope, element, attributes, ctrl) {
                scope.api = {
                    init: function () {
                        scope.init();
                    },
                };

                scope.actionByKey = function (eventKey, eventParam, e) {
                    if (eventParam == "time") {
                        scope.updateSelectedTimes(eventKey);
                        scope.runIfNotInDigest();
                    }
                    else {
                        switch (eventKey) {
                            case 'prev':
                                scope.handleClickPrevMonth(e);
                                scope.runIfNotInDigest();
                                break;
                            case 'next':
                                scope.handleClickNextMonth(e);
                                scope.runIfNotInDigest();
                                break;
                            case 'date1':
                                if (scope.handleClickDate(e, scope.dates[eventParam])) {
                                    scope.runIfNotInDigest(scope.triggerChange);
                                } else {
                                    scope.runIfNotInDigest();
                                }
                                break;
                            case 'date2':
                                if (scope.handleClickDate(e, scope.dates2[eventParam])) {
                                    scope.runIfNotInDigest(scope.triggerChange);
                                } else {
                                    scope.runIfNotInDigest();
                                }
                                break;
                            case 'TD':
                                scope.handleClickSelectToday();
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'YD':
                                scope.handleClickSelectYesterday();
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'TW':
                                scope.handleClickSelectThisWeek();
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'LW':
                                scope.handleClickSelectLastWeek();
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'TM':
                                scope.handleClickSelectThisMonth();
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'LM':
                                scope.handleClickSelectLastMonth(1, 'LM');
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'TY':
                                scope.handleClickSelectThisYear();
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'LY':
                                scope.handleClickSelectLastYear();
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'L3M':
                                scope.handleClickSelectLastMonth(3, 'L3M');
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'L6M':
                                scope.handleClickSelectLastMonth(6, 'L6M');
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            case 'Custom Template':
                                scope.runIfNotInDigest(scope.triggerChange);
                                break;
                            default:
                                break;
                        }
                        if(scope.disableCT){
                            scope.selectedTodTemplate = "FD";
                            scope.updateSelectedTimes('FD');
                        } 
                    }
                }



                scope.runIfNotInDigest = function (operation) {
                    if (scope.$root != null && !scope.$root.$$phase) { // check if digest already in progress
                        scope.$apply(); // launch digest;
                        if (operation && typeof operation === 'function') {
                            operation();
                        }
                    }
                };
                element.on('click', function (e) {
                    var eventKey = e.target.getAttribute('event-key'),
                        eventParam = e.target.getAttribute('event-param');
                    if (eventKey || eventParam)
                        scope.actionByKey(eventKey, eventParam, e);
                });


                // element.on('dblclick', function (e) {
                //     var eventKey = e.target.getAttribute('event-key'),
                //         eventParam = e.target.getAttribute('event-param');
                //     if (eventParam == "Date Template") {
                //         scope.actionByKey(eventKey, eventParam, e);
                //         //call apply also
                //         if (scope.applyCallback) {
                //             scope.applyCallback();
                //         }
                //     }
                // });
                scope.triggerChange = function triggerChange(e) {
                    var $dates = timeframeMenuService.getSelectedDate(scope.dateStart, scope.dateEnd, scope.isDisabledDate, scope.maxRange); // All selected enabled dates
                    if (scope.mdOnSelect) {
                        scope.mdOnSelect({ $dates: $dates });
                    }
                };
            }
        };
        return directive
    }

    function timeframePickerCtrl($rootScope, $scope, $filter, $timeout, $element, TimeframeConstants, searchBarService, timeframeMenuService) {
        var ctrl = $scope, NUMBER_OF_MONTH_TO_DISPLAY = 2, START_OF_WEEK = 1,
            SELECTION_TEMPLATES_CUSTOM = {};

        $scope.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        $scope.dateTemplates = {};
        $scope.selectionDateTemplates = {};
        $scope.selectedTemplate = undefined;
        $scope.selectedTodTemplate = undefined;
        $scope.updateSelectedTimes = updateSelectedTimes;
        $scope.showWeekdayFilter = false;
        $scope.weekdayError = false;


        $scope.flipWeekday = function (weekDay) {
            weekDay.isSelected = !weekDay.isSelected;
            $scope.weekdayError = $scope.selectedWeekdays.filter(x => x.isSelected).length === 0;
            if ($scope.weekdayError) {
                weekDay.isSelected = !weekDay.isSelected;
                return;
            };
        }

        $scope.isMenuContainer = false;
        $scope.days = [];
        $scope.label = 'Date range picker';
        $scope.dates = [];
        $scope.dates2 = [];
        $scope.numberOfMonthToDisplay = 2;
        $scope.today = new Date();
        $scope.firstDayOfMonth = $scope.dateStart ? new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), 1) : Date($scope.today.getFullYear(), $scope.today.getMonth(), 1);
        $scope.lastDayOfMonth = $scope.dateStart ? new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth() + 1, 0) : Date($scope.today.getFullYear(), $scope.today.getMonth() + 1, 0);
        $scope.activeDate = $scope.dateStart || $scope.today;
        $scope.activeDate2 = new Date($scope.activeDate.getFullYear(), $scope.activeDate.getMonth() + 1, 1);
        $scope.activeMonth = $scope.activeDate.getMonth();
        $scope.activeYear = $scope.activeDate.getFullYear();
        $scope.activeMonth2 = $scope.activeDate2.getMonth();
        $scope.activeYear2 = $scope.activeDate2.getFullYear();
        $scope.months = [];
        $scope.years = [];

        $scope.inCurrentMonth = inCurrentMonth;
        $scope.isToday = isToday;
        $scope.handleClickDate = handleClickDate;
        $scope.inSelectedDateRange = inSelectedDateRange;
        $scope.isSelectedStartDate = isSelectedStartDate;
        $scope.isSelectedEndDate = isSelectedEndDate;
        $scope.updateActiveDate = updateActiveDate;
        $scope.focusToDate = focusToDate;

        $scope.handleClickNextMonth = handleClickNextMonth;
        $scope.handleClickPrevMonth = handleClickPrevMonth;

        $scope.handleClickSelectToday = handleClickSelectToday;
        $scope.handleClickSelectYesterday = handleClickSelectYesterday;
        $scope.handleClickSelectThisWeek = handleClickSelectThisWeek;
        $scope.handleClickSelectLastWeek = handleClickSelectLastWeek;
        $scope.handleClickSelectThisMonth = handleClickSelectThisMonth;
        $scope.handleClickSelectLastMonth = handleClickSelectLastMonth;
        $scope.handleClickSelectThisYear = handleClickSelectThisYear;
        $scope.handleClickSelectLastYear = handleClickSelectLastYear;
        $scope.calculateCurrentDayRange = calculateCurrentDayRange;
        $scope.getLocalizationVal = getLocalizationVal;
        $scope.selectCustomRange = selectCustomRange;
        $scope.isRangeLongerThanWeek = false;
        $scope.currentDayRange = 0;
        $scope.init = init;
        $scope.checkForValidTimes = checkForValidTimes;
        $scope.setTimePickerStep = setTimePickerStep;
        $scope.selectedWeekdays = const_days;
        $scope.compareTemplateOrder = compareTemplateOrder;
        $scope.isWithinHoverRange = isWithinHoverRange;
        $scope.dateHovered = dateHovered;
        $scope.isWithinHoverRangeFirst = isWithinHoverRangeFirst;
        $scope.isWithinHoverRangeLast = isWithinHoverRangeLast;
        $scope.isWithinHoverRangeMid = isWithinHoverRangeMid;
        $scope.adjustTimeToBinSize = adjustTimeToBinSize;
        $scope.mouseLeavingCalendar = mouseLeavingCalendar;
        $scope.endDateSet = true;
        $scope.enableAdvancedTod = false;


        $scope.todClicked = function () {
            if ($scope.selectedTodTemplate != 'CT') {
                $scope.selectedTodTemplate = "CT";
                updateSelectedTimes('CT');
            }
        }

        $scope.setSelectedTod = function (key) {
            $scope.selectedTodTemplate = key;
            $scope.updateSelectedTimes(key)
        }
        // $scope.enableRange = false;

        $rootScope.$on("mdpTimePickerUpdated", $scope.checkForValidTimes);

        $scope.$on('destroy', function () {
            $rootScope.$off("mdpTimePickerUpdated", $scope.checkForValidTimes);
        });

        $scope.isDisabledDate = function ($date) {
            var startDate = $scope.dateStart;
            var maxRangeDiff = $scope.maxRange - 1;
            var minRangeDiff = 0;
            if ($scope.minRange)
                minRangeDiff = $scope.minRange - 1;

            var minRangeDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - maxRangeDiff, 0, 0, 0);
            var maxRangeDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + maxRangeDiff, 0, 0, 0);

            var todayMidnight = new Date();
            todayMidnight.setHours(0, 0, 0, 0);

            var midnight = new Date($date.$date);
            midnight.setHours(0, 0, 0, 0);
            //this date is in the future, disable it
            if (midnight.getTime() > todayMidnight.getTime())
                return true;

            // if ($scope.enableRange)
            //     return false;

            if ($scope.enableMaxRange) {
                //check to see if current date falls within our time range
                var startDay = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 0, 0, 0);
                var endDay = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), 0, 0, 0);
                if (startDay.getTime() == endDay.getTime()) {
                    if (midnight.getTime() > maxRangeDate.getTime() || midnight.getTime() < minRangeDate.getTime()) {
                        return true;
                    }
                    //falls within max range, now check min range
                    if (minRangeDiff && minRangeDiff > 0) {
                        var secondMinRangeDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - minRangeDiff, 0, 0, 0);
                        var secondMaxRangeDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + minRangeDiff, 0, 0, 0);
                        if (midnight.getTime() > secondMinRangeDate.getTime() && midnight.getTime() < secondMaxRangeDate.getTime()) {
                            return true;
                        }
                    }
                }
            }


            return false;
        }
        $scope.init();


        function dateHovered(date) {
            if ($scope.isDisabledDate({ $date: date })) {
                $scope.hoveringDate = undefined;
            }
            else {
                $scope.hoveringDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
            }
        }

        function mouseLeavingCalendar() {
            //remove all hover styles
            $element.find('.md-date-range-picker__calendar__hover').removeClass('md-date-range-picker__calendar__hover');
            $element.find('.md-date-range-picker__calendar__hover_first').removeClass('md-date-range-picker__calendar__hover_first');
            $element.find('.md-date-range-picker__calendar__hover_last').removeClass('md-date-range-picker__calendar__hover_last');
            $scope.hoveringDate = undefined;
        }

        function isWithinHoverRange(currentDate) {
            let res = false;
            if ($scope.hoveringDate && !$scope.endDateSet) {
                let startDate = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 0, 0, 0);
                let endDate = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), 0, 0, 0);
                let totalHoverDays = searchBarService.calculateFilterTimeSpanInDays(startDate, $scope.hoveringDate);

                if (startDate.getTime() === endDate.getTime() && $scope.hoveringDate > startDate && totalHoverDays < $scope.maxRange) {
                    if (currentDate >= startDate && currentDate <= $scope.hoveringDate) {
                        res = true;
                    }
                }
            }
            return res;
        }

        function isWithinHoverRangeFirst(currentDate) {
            let res = false;
            let startDate = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 0, 0, 0);
            if ($scope.isWithinHoverRange(currentDate) && currentDate.getDate() === startDate.getDate() && currentDate.getMonth() === startDate.getMonth())
                res = true;
            return res;
        }

        function isWithinHoverRangeLast(currentDate) {
            let res = false;
            if ($scope.isWithinHoverRange(currentDate) && currentDate.getDate() === $scope.hoveringDate.getDate() && currentDate.getMonth() === $scope.hoveringDate.getMonth())
                res = true;

            return res;
        }

        function isWithinHoverRangeMid(currentDate) {
            let res = false;
            if ($scope.isWithinHoverRange(currentDate) && !isWithinHoverRangeFirst(currentDate) && !isWithinHoverRangeLast(currentDate))
                res = true;

            return res;
        }

        function adjustTimeToBinSize(startDate, endDate, binSize) {
            if (endDate.getHours() == 23) {
                // if (roundEndMinutes == 60) roundEndMinutes = 59;
                $scope.dateEnd = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), $scope.dateEnd.getHours(), 59, 0);
            }
        }

        function checkForValidTimes() {
            if (!$scope.dateStart || !$scope.dateEnd)
                return;

            //add 30 minutes to the start event to see if it's greater than end
            //if so, then throw error
            var sbConfig = searchBarService.getSearchBarConfig();            
            var currentSearch = searchBarService.getSearchOptions();

            if( sbConfig.dashboardType == "Corridor" && currentSearch.timeOptions.bin=="Day") {
                if ($scope.selectedTodTemplate != 'FD') {
                    $scope.selectedTodTemplate = "FD";
                }
            }
            
            switch ($scope.selectedTodTemplate) {
                case 'FD':
                    $scope.dateStart = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 0, 0, 0); //new moment('12:00:00 am', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), 23, 59, 59); //new moment('11:59:59 pm', ['h:m:s a', 'H:m:s']);
                    break;
                case 'AM':
                    $scope.dateStart = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 6, 0, 0);//new moment('6:00:00 am', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), 9, 0, 0); //new moment('9:00:00 am', ['h:m:s a', 'H:m:s']);
                    break;
                case 'PM':
                    $scope.dateStart = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 15, 0, 0); //new moment('3:00:00 pm', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), 18, 0, 0); //new moment('6:00:00 pm', ['h:m:s a', 'H:m:s']);
                    break;
                case 'MD':
                    $scope.dateStart = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 9, 0, 0);//new moment('9:00:00 am', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), 15, 0, 0);//new moment('3:00:00 pm', ['h:m:s a', 'H:m:s']);
                    break;
                case 'CT':
                    break;
            }
            var newStart = new Date($scope.dateStart.getTime() + 30 * 60000);
            var startTime = ($scope.dateStart.getHours() * 3600000) + ($scope.dateStart.getMinutes() * 60000);
            var endTime = ($scope.dateEnd.getHours() * 3600000) + ($scope.dateEnd.getMinutes() * 60000);
            // if ((startTime + 30*60*60) > endTime) {

            if (searchBarService.getSearchBarConfig().timeFrameConfig.timeOptionForCustomTemplate != 'TimePeriod') {
                if (newStart > $scope.dateEnd) {
                    //set dirty don't allow save
                    $scope.timeError = true;
                    $scope.timeErrorText = "*End time must be at least 30 minutes after start time";
                }
                else {
                    $scope.timeError = false;
                    $scope.timeErrorText = "";
                }
            } else {
                if (startTime + 30 * 60000 > endTime) {
                    //set dirty don't allow save
                    $scope.timeError = true;
                    $scope.timeErrorText = "*End time must be at least 30 minutes after start time";
                }
                else {
                    $scope.timeError = false;
                    $scope.timeErrorText = "";
                }
            }
            

        }

        function setTimePickerStep() {
            var currentSearch = searchBarService.getSearchOptions()
            if ($scope.selectedTodTemplate == 'CT') {
                var currentBinSize;
                switch (currentSearch.timeOptions.bin) {
                    case "FiveMinute":
                        currentBinSize = '5';
                        break;
                    case "FifteenMinute":
                        currentBinSize = '15';
                        break;
                    case "ThirtyMinute":
                        currentBinSize = '30';
                        break;
                    case "Hour":
                        currentBinSize = '60';
                        break;
                    case "OneMinute":
                        currentBinSize = '5';
                        break;
                    case "Day":
                        currentBinSize = '60';
                        break;
                    default:
                        break;
                }
                return currentBinSize
            }
        }

        function init() {
            var mctr = 0;
            var currTmpl;
            setOptionsAndConfigurations(TimeframeConstants, searchBarService);
            $scope.updateSelectedTimes($scope.selectedTodTemplate);
            /** 
             * add custom template to local custom template array 
            */
            if ($scope.customTemplates != null) {
                for (var i = 0; i < $scope.customTemplates.length; i++) {
                    currTmpl = $scope.customTemplates[i];
                    SELECTION_TEMPLATES_CUSTOM[currTmpl.name] = currTmpl;
                }
            }


            if ($scope.dateStart)
                $scope.focusToDate($scope.dateStart);
            else
                $scope.updateActiveDate();

            $scope.calculateCurrentDayRange();

            /**
             * Generate Days of Week Names
             * Fact: January 1st of 2017 is Sunday
             */
            var w = new Date(2017, 0, 1);
            $scope.days = [];
            for (mctr = 0; mctr < 7; mctr++) {
                //add $scope.firstDayOfWeek to set the first Day of week e.g. -1 = Sunday, 0 = Monday 
                w.setDate(mctr + 1 + getFirstDayOfWeek());
                $scope.days.push({ id: mctr, name: getLocalizationVal($filter('date')(w, 'EEE')) });
            }
            /**
             * Generate Month Names, Might depend on localization
            */
            var m = null;
            $scope.months = [];
            for (mctr = 0; mctr < 12; mctr++) {
                m = new Date(2017, mctr, 1);
                $scope.months.push({ id: mctr, name: getLocalizationVal($filter('date')(m, 'MMMM')) });
            }
            /**
             * Generate Year Selection
            */
            var currentYear = new Date().getFullYear();
            var y = $scope.activeYear, yctr = 0;
            $scope.years = [];
            for (yctr = currentYear - 4; yctr < currentYear + 1; yctr++) {
                $scope.years.push({ id: yctr, name: getLocalizationVal(yctr) })
            }

            /**
             * filter out disabled templates
            */
            for (var tmplKey in $scope.dateTemplates) {
                if ($scope.dateTemplates.hasOwnProperty(tmplKey)) {
                    //check if we have disable templates property 
                    if ($scope.disableTemplates != null && $scope.disableTemplates != '') {
                        //if key is not exist in disableTemplates property add it
                        if ($scope.disableTemplates.indexOf(tmplKey) < 0) {
                            $scope.selectionDateTemplates[tmplKey] = $scope.dateTemplates[tmplKey];
                        }
                    } else {
                        $scope.selectionDateTemplates[tmplKey] = $scope.dateTemplates[tmplKey];
                    }

                }
            }
        }

        function setOptionsAndConfigurations(TimeframeConstants, searchBarService) {
            $scope.selectedDateTemplate = "";
            $scope.selectedTodTemplate = "";
            $scope.currentBinSize = "";
            $scope.selectionDateTemplates = {};
            $scope.timeOfDayTemplates = {};
            $scope.dateTemplates = [];
            $scope.selectedTemplate = "";
            $scope.timeOfDayTemplatesDisabled = [];
            //
            var options = searchBarService.getSearchOptions();
            var configs = searchBarService.getSearchBarConfig();
            var dateTemplates = [], todTemplates = [];
            var binSize = options.timeOptions.bin;
            let disableCT = false;

            binSize == 'Day' ?  disableCT = true : disableCT = false;            
            $scope.disableCT = disableCT;




            //depending on what our max date range is set to, we can figure out which date templates to show
            if (configs && configs.timeFrameConfig) {
                $scope.showWeekdayFilter = configs.timeFrameConfig.showWeekdayFilter;
                $scope.maxRange = configs.timeFrameConfig.maxDayRange;
                $scope.minRange = configs.timeFrameConfig.minDayRange;
                let dateTemplateMin = configs.timeFrameConfig.dateTemplateMinimumDays;
                //always add today and yesterday, plus page's current default
                if (dateTemplateMin <= 1)
                    dateTemplates.push("TD", "YD");


                if ($scope.maxRange >= 7 && dateTemplateMin <= 7) {
                    //last week and this week
                    dateTemplates.push("LW", "TW");
                }
                if ($scope.maxRange >= 31 && dateTemplateMin <= 31) {
                    //last month and this month
                    dateTemplates.push("LM", "TM");
                }
                if ($scope.maxRange > 62 && dateTemplateMin <= 62) {
                    //last year and this year
                    dateTemplates.push("LY", "TY", "L3M", "L6M");
                }

                //add in default if not added
                if (!dateTemplates.includes(configs.timeFrameConfig.defaultDateTemplate))
                    dateTemplates.push(configs.timeFrameConfig.defaultDateTemplate);

                dateTemplates.sort($scope.compareTemplateOrder);
                //add in the time of day templates
                //always add Full day and cusom
                todTemplates.push("FD");
                if (configs.timeFrameConfig.enableAdvancedTod) {
                    todTemplates.push("AM", "PM", "MD");
                    $scope.enableAdvancedTod = true;
                }
                else {
                    $scope.enableAdvancedTod = false;
                }
                todTemplates.push("CT");

                //add in default if not added
                if (!todTemplates.includes(configs.timeFrameConfig.defaultTodTemplate))
                    todTemplates.push(configs.timeFrameConfig.defaultTodTemplate);
            }

            $scope.todTemplates = todTemplates;

            if(configs.dashboardType == "Corridor"){
                for (var todTemplate in todTemplates) {
                    var key = todTemplates[todTemplate];
                    if (TimeframeConstants.TODTemplates.hasOwnProperty(key)) {
                        if(key=='FD'){
                            $scope.timeOfDayTemplatesDisabled[key] = false;
                        }
                        if(key=="CT"){ 
                            $scope.timeOfDayTemplatesDisabled[key] = binSize == 'Day' ?  true : false; 
                            $scope.ctTimeErrorText = "*Selected bin size is 'Daily' so you can not change hours on this dashboard.";
                            $scope.ctTimeError = true;
                        }
                    }
                }
            }
            else{
                for (var todTemplate in todTemplates) {
                    var key = todTemplates[todTemplate];
                    if (TimeframeConstants.TODTemplates.hasOwnProperty(key)) {
                        if(key=='FD') $scope.timeOfDayTemplatesDisabled[key] = false;
                        if(key=="CT") $scope.timeOfDayTemplatesDisabled[key] = false; 
                    }
                }
            }




            //take our templates and find the objects from our constants object and add to scope variables
            if (dateTemplates && dateTemplates.length > 0) {
                for (var dateTemplate in dateTemplates) {
                    var key = dateTemplates[dateTemplate];
                    if (TimeframeConstants.DateTemplates.hasOwnProperty(key)) {
                        $scope.dateTemplates[key] = TimeframeConstants.DateTemplates[key];
                    }
                }
            }
            if (todTemplates && todTemplates.length > 0 && !$scope.enableAdvancedTod) {
                for (var todTemplate in todTemplates) {
                    var key = todTemplates[todTemplate];
                    if (TimeframeConstants.TODTemplates.hasOwnProperty(key)) {
                        $scope.timeOfDayTemplates[key] = { todName: TimeframeConstants.TODTemplates[key], todDisabled: $scope.timeOfDayTemplatesDisabled[key] };
                    }
                }
            }
            if (todTemplates && todTemplates.length > 0 && $scope.enableAdvancedTod) {
                for (var todTemplate in todTemplates) {
                    var key = todTemplates[todTemplate];
                    if (TimeframeConstants.TODTemplates.hasOwnProperty(key)) {
                        $scope.timeOfDayTemplates[key] = TimeframeConstants.TODTemplates[key];
                    }
                }
            }

            //read in settings from searchbar service
            if (options && options.timeOptions && options.timeOptions.currentFilter) {
                //set our selected template and dates to what is saved in options
                var currentDateTemplate = options.timeOptions.currentFilter.selectedDateTemplate;
                var currentTodTemplate = options.timeOptions.currentFilter.selectedTodTemplate;

                if (currentDateTemplate && $scope.dateTemplates.hasOwnProperty(currentDateTemplate)) {
                    //check if date template selected is a valid option for our current page
                    $scope.selectedTemplate = currentDateTemplate;
                }

                if (currentTodTemplate && $scope.timeOfDayTemplates.hasOwnProperty(currentTodTemplate)) {
                    //check if TOD template selected is a valid option for our current page
                    $scope.selectedTodTemplate = currentTodTemplate;
                }
                // else if ($scope.timeOfDayTemplates.hasOwnProperty(configs.timeFrameConfig.defaultTodTemplate)) {
                //     //use default, selected date template is not valid for this page
                //     $scope.selectedTodTemplate = configs.timeFrameConfig.defaultTodTemplate;
                // }
                // else if ($scope.dateTemplates.hasOwnProperty(configs.timeFrameConfig.defaultDateTemplate)) {
                //     //use default, selected date template is not valid for this page
                //     $scope.selectedTemplate = configs.timeFrameConfig.defaultDateTemplate;
                // }
                if (!$scope.selectedTodTemplate || $scope.selectedTodTemplate == "")
                    $scope.selectedTodTemplate = "CT";

                $scope.dateStart = new Date(options.timeOptions.currentFilter.startDateAndTime);
                $scope.dateEnd = new Date(options.timeOptions.currentFilter.endDateAndTime);
                //Look at what weekdays we user currently has selected and select them in our binding object array
                if (options.timeOptions.daysOfWeekList) {
                    for (let i = 0; i < options.timeOptions.daysOfWeekList.length; i++) {
                        var selectedWeekday = options.timeOptions.daysOfWeekList[i];
                        var weekday = $scope.selectedWeekdays.find(x => x.value == selectedWeekday);
                        if (weekday)
                            weekday.isSelected = true;

                    }
                }
            }
        }

        //function looks at the DateTemplatesOrder object and fill dynamically order the array of date tempalte keys
        // use like dateTemplateArray.sort(compareTemplateOrder)
        function compareTemplateOrder(a, b) {
            let aOrder = 0, bOrder = 0;
            if (TimeframeConstants.DateTemplateOrder.hasOwnProperty(a)) {
                aOrder = TimeframeConstants.DateTemplateOrder[a];
            }
            if (TimeframeConstants.DateTemplateOrder.hasOwnProperty(b)) {
                bOrder = TimeframeConstants.DateTemplateOrder[b];
            }
            if (aOrder < bOrder) {
                return -1;
            }
            if (aOrder > bOrder) {
                return 1;
            }
            return 0;
        }

        function updateSelectedTimes(eventKey) {
            $scope.selectedTodTemplate = eventKey;
            if (!$scope.newSelectedDateStart)
                $scope.newSelectedDateStart = new Date($scope.dateStart);
            if (!$scope.newSelectedDateEnd)
                $scope.newSelectedDateEnd = new Date($scope.dateEnd);
            switch (eventKey) {
                case 'FD':
                    $scope.dateStart = new Date($scope.newSelectedDateStart.getFullYear(), $scope.newSelectedDateStart.getMonth(), $scope.newSelectedDateStart.getDate(), 0, 0, 0); //new moment('12:00:00 am', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.newSelectedDateEnd.getFullYear(), $scope.newSelectedDateEnd.getMonth(), $scope.newSelectedDateEnd.getDate(), 23, 59, 59); //new moment('11:59:59 pm', ['h:m:s a', 'H:m:s']);
                    break;
                case 'AM':
                    $scope.dateStart = new Date($scope.newSelectedDateStart.getFullYear(), $scope.newSelectedDateStart.getMonth(), $scope.newSelectedDateStart.getDate(), 6, 0, 0);//new moment('6:00:00 am', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.newSelectedDateEnd.getFullYear(), $scope.newSelectedDateEnd.getMonth(), $scope.newSelectedDateEnd.getDate(), 9, 0, 0); //new moment('9:00:00 am', ['h:m:s a', 'H:m:s']);
                    break;
                case 'PM':
                    $scope.dateStart = new Date($scope.newSelectedDateStart.getFullYear(), $scope.newSelectedDateStart.getMonth(), $scope.newSelectedDateStart.getDate(), 15, 0, 0); //new moment('3:00:00 pm', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.newSelectedDateEnd.getFullYear(), $scope.newSelectedDateEnd.getMonth(), $scope.newSelectedDateEnd.getDate(), 18, 0, 0); //new moment('6:00:00 pm', ['h:m:s a', 'H:m:s']);
                    break;
                case 'MD':
                    $scope.dateStart = new Date($scope.newSelectedDateStart.getFullYear(), $scope.newSelectedDateStart.getMonth(), $scope.newSelectedDateStart.getDate(), 9, 0, 0);//new moment('9:00:00 am', ['h:m:s a', 'H:m:s']);
                    $scope.dateEnd = new Date($scope.newSelectedDateEnd.getFullYear(), $scope.newSelectedDateEnd.getMonth(), $scope.newSelectedDateEnd.getDate(), 15, 0, 0);//new moment('3:00:00 pm', ['h:m:s a', 'H:m:s']);
                    break;
                case 'CT':
                    var currentSearch = searchBarService.getSearchOptions()
                    if (searchBarService.getSearchBarConfig().moreOptionsMenu.showBinConfig) {
                        switch (currentSearch.timeOptions.bin) {
                            case "FiveMinute":
                                $scope.binInfoText = "Time options are automatically adjusted to match the selected 5 minutes bin size";
                                break;
                            case "FifteenMinute":
                                $scope.binInfoText = "Time options are automatically adjusted to match the selected 15 minutes bin size";
                                break;
                            case "ThirtyMinute":
                                $scope.binInfoText = "Time options are automatically adjusted to match the selected 30 minutes bin size";
                                break;
                            case "Hour":
                                $scope.binInfoText = "Time options are automatically adjusted to match the selected hourly bin size";
                                break;
                            case "OneMinute":
                            case "Day":
                            default:
                                $scope.binInfoText = "";
                                break;
                        }
                    }
                    if ($scope.dateEnd.getMinutes() == 59) {
                        $scope.dateEnd = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate(), $scope.dateEnd.getHours(), 0, 0);
                    }
                    break;
            }
            $scope.checkForValidTimes();
            if (timeframeMenuService && timeframeMenuService.notifyTodTemplateChange) {
                timeframeMenuService.notifyTodTemplateChange(eventKey,$scope.dateStart,$scope.dateEnd);
            }
        }

        function setStartDate(newDate) {
            //maintain existing time, just change dates
            $scope.newSelectedDateStart = newDate;
            $scope.dateStart = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), $scope.dateStart.getHours(), $scope.dateStart.getMinutes(), $scope.dateStart.getSeconds());
        }

        function setEndDate(newEndDate) {
            //maintain existing time, just change dates
            //check if end date is past current date
            $scope.newSelectedDateEnd = newEndDate;
            var now = new Date();
            var currentDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
            var newEndDateDay = new Date(newEndDate.getFullYear(), newEndDate.getMonth(), newEndDate.getDate(), 0, 0, 0);
            var useDate = newEndDate;
            var useTime = $scope.dateEnd;

            if (newEndDateDay > currentDay) {
                //new date we are changing to is in the future so just use current day as end
                useDate = currentDay;
            }
            //commenting this code out for now, for cross time-zone purposes it doesn't allow us to see latest data 
            //problem should probably be solved in charts but leaving code in case we want to use it at some point
            // //if our ending date is the current day
            // if (useDate == currentDay && useTime.getHours() > now.getHours()){
            //     //check the time of day, if endEnd hours are later than use current time minus 1 hour
            //     //for example if current time is 8am and end time for current day is 5pm, some charts will show 7 hours of empty data
            //     //this will set the end time to 7am
            //     useTime = new Date(useDate.getFullYear(), useDate.getMonth(), useDate.getDate(), now.getHours()-1, now.getMinutes(), now.getSeconds());
            // }
            $scope.dateEnd = new Date(useDate.getFullYear(), useDate.getMonth(), useDate.getDate(), useTime.getHours(), useTime.getMinutes(), useTime.getSeconds());
        }

        function selectCustomRange(tmpltKey, tmpltObj) {
            setStartDate(tmpltObj.dateStart);
            setEndDate(tmpltObj.dateEnd);
            $scope.selectedTemplate = tmpltKey;
            $scope.calculateCurrentDayRange();
        }

        function calculateCurrentDayRange() {
            //$scope.checkForValidTimes();
            //use midnight times for each day so that custom times don't influence date range days
            let startMidnight = new Date($scope.dateStart.getFullYear(), $scope.dateStart.getMonth(), $scope.dateStart.getDate(), 0, 0, 0);
            let endMidnight = new Date($scope.dateEnd.getFullYear(), $scope.dateEnd.getMonth(), $scope.dateEnd.getDate() + 1, 0, 0, 0);
            $scope.currentDayRange = searchBarService.calculateFilterTimeSpanInDays(startMidnight, endMidnight);
            $scope.isRangeLongerThanWeek = $scope.currentDayRange >= 7 ? true : false;
            $scope.newSelectedDateEnd =  $scope.dateEnd;
            $scope.newSelectedDateStart =  $scope.dateStart;

            if (timeframeMenuService && timeframeMenuService.notifyDateSelectionChanged) {
                timeframeMenuService.notifyDateSelectionChanged($scope.currentDayRange);
            }
            
            setCTflag($scope.currentDayRange);
            $scope.checkForValidTimes();
        }

        function setCTflag(currentDayRange){

            var searchOpt = searchBarService.getSearchOptions();
            var sbConfig = searchBarService.getSearchBarConfig();
            var binSize = searchOpt.timeOptions.bin;
            var disableCT = false;

            
            if(sbConfig.dashboardType == "Corridor"){
                let tooltipText = 'Selected bin size is "Daily" so you can not change hours on this dashboard.';
                if(currentDayRange >= 10) {
                    disableCT = true;
                    $scope.ctTimeErrorText = tooltipText;
                    $scope.ctTimeError = true;
                }
                if(currentDayRange <= 3) {
                    disableCT = false;
                    $scope.ctTimeErrorText = '';
                    $scope.ctTimeError = false;

                }
                if(currentDayRange > 3 && currentDayRange < 10){
                    if(binSize == 'Day'){
                        disableCT = true;
                        $scope.ctTimeErrorText = tooltipText;
                        $scope.ctTimeError = true;
                    } 
                    else {
                        disableCT = false;
                        $scope.ctTimeErrorText = '';
                        $scope.ctTimeError = false;
                    }
                }

                for (var todTemplate in $scope.todTemplates) {
                    var key = $scope.todTemplates[todTemplate];
                    if (TimeframeConstants.TODTemplates.hasOwnProperty(key)) {
                        if(key=='FD') $scope.timeOfDayTemplatesDisabled[key] = false;
                        if(key=="CT") $scope.timeOfDayTemplatesDisabled[key] = disableCT; 
                    }
                }

                if ($scope.todTemplates && $scope.todTemplates.length > 0 && !$scope.enableAdvancedTod) {
                    for (var todTemplate in $scope.todTemplates) {
                        var key = $scope.todTemplates[todTemplate];
                        if (TimeframeConstants.TODTemplates.hasOwnProperty(key)) {
                            $scope.timeOfDayTemplates[key] = { todName: TimeframeConstants.TODTemplates[key], todDisabled: $scope.timeOfDayTemplatesDisabled[key] };
                        }
                    }
                }
                //if(disableCT) updateSelectedTimes('FD');
                $scope.disableCT = disableCT;
            }
        }

        function getLocalizationVal(val) {
            var ret = null;
            if ($scope.localizationMap != null && $scope.localizationMap[val] != null) {
                ret = $scope.localizationMap[val];
            } else {
                ret = val;
            }
            return ret;
        }

        function getFirstDayOfWeek() {
            if ([undefined, null, '', NaN].indexOf($scope.firstDayOfWeek) !== -1) {
                return START_OF_WEEK;
            }
            return $scope.firstDayOfWeek;
        }
        /**
         * Fill the Calendar Dates
         */
        function fillDateGrid(currentDate) {

            var dates = [],
                monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
                monthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
                firstDay = getFirstDayOfWeek(),
                ctr, day;

            for (ctr = 1; ctr <= monthEndDate.getDate(); ctr++) {
                dates.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), ctr));
            }

            day = dates[0].getDay();
            ctr = 0;
            while (day !== firstDay) {
                dates.unshift(new Date(currentDate.getFullYear(), currentDate.getMonth(), ctr));
                day = day <= 0 ? 6 : day - 1;
                ctr--;
            }

            day = (dates[dates.length - 1].getDay() + 1) % 7;
            ctr = 1;
            while (day !== firstDay) {
                dates.push(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, ctr));
                day = (day + 1) % 7;
                ctr++
            }
            return dates;
        }

        /**
         * Diff 2 Dates by Day Differences
         * date1 < date2 return positive integer
         * date1 = date2 return 0
         * date1 > date2 return negative integer
         */
        function getDateDiff(date1, date2) {
            if (!date1 || !date2) return;
            var _d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()),
                _d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
            return _d2 - _d1;
        }

        /**
         * return Day Name in a week
         */
        function getDayName(day) {
            var weekday = new Array(7), div = getFirstDayOfWeek();
            weekday[0] = "Sun";
            weekday[1] = "Mon";
            weekday[2] = "Tue";
            weekday[3] = "Wed";
            weekday[4] = "Thu";
            weekday[5] = "Fri";
            weekday[6] = "Sat";
            return weekday[day + div % 7];
        }

        /**
         * Events
         */

        function inCurrentMonth(date, isSecondMonth) {
            return !isSecondMonth ?
                date.getMonth() === $scope.activeMonth && date.getFullYear() === $scope.activeYear :
                date.getMonth() === $scope.activeMonth2 && date.getFullYear() === $scope.activeYear2;
        }

        function handleClickDate($event, date) {
            var changed = false;
            var shouldConfirm = false;
            if (getDateDiff($scope.dateStart, $scope.dateEnd) === 0) {
                if (getDateDiff($scope.dateStart, date) === 0) {
                    //start date was clicked again, so set as end date
                    setEndDate(date);
                    $scope.endDateSet = true;
                    changed = true;
                }
                else if (!$scope.isDisabledDate || !$scope.isDisabledDate({ $date: date })) {
                    var diff = getDateDiff($scope.dateStart, date);
                    var diffDays = Math.abs(Math.ceil(diff / (1000 * 3600 * 24))) + 1;

                    if (diff > 0) {
                        // Check if maxRange, if so then set start and end range
                        if (!$scope.endDateSet && $scope.maxRange && diffDays <= $scope.maxRange || !$scope.maxRange) {
                            if ($scope.minRange && diffDays >= $scope.minRange || !$scope.minRange) {
                                //sets the end of the range selection
                                setEndDate(date);
                                $scope.endDateSet = true;
                                changed = true;
                            }
                        }
                        else {
                            //date outside of existing range, new start date so set enddateset to false, creates new start date
                            if (!$scope.isDisabledDate || !$scope.isDisabledDate({ $date: date })) {
                                setStartDate(date);
                                setEndDate(date);
                                $scope.endDateSet = false;
                                changed = true;
                            }
                        }
                    } else {
                        //date outside of existing range, new start date so set enddateset to false, creates new start date
                        if (!$scope.isDisabledDate || !$scope.isDisabledDate({ $date: date })) {
                            setStartDate(date);
                            setEndDate(date);
                            $scope.endDateSet = false;
                            changed = true;
                        }
                    }
                }
            } else {
                //date outside of existing range, new start date so set enddateset to false, creates new start date
                if (!$scope.isDisabledDate || !$scope.isDisabledDate({ $date: date })) {
                    setStartDate(date);
                    setEndDate(date);
                    $scope.endDateSet = false;
                    changed = true;
                }
            }
            if (changed) {
                shouldConfirm = true;
                $scope.selectedTemplate = undefined;
            }
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
            return shouldConfirm;
        }

        function inSelectedDateRange(date) {
            return $scope.dateStart && $scope.dateEnd
                ? getDateDiff($scope.dateStart, date) >= 0 && 0 <= getDateDiff(date, $scope.dateEnd)
                : false;
        }

        function updateActiveDate(isSecondMonth) {
            var d = new Date($scope.activeYear, $scope.activeMonth, 1),
                d2 = new Date($scope.activeYear2, $scope.activeMonth2, 1);
            if (isSecondMonth) {
                d = new Date($scope.activeYear2, $scope.activeMonth2 - 1, 1);
                $scope.activeYear = d.getFullYear();
                $scope.activeMonth = d.getMonth();
            } else {
                d2 = new Date($scope.activeYear, $scope.activeMonth + 1, 1);
                $scope.activeYear2 = d2.getFullYear();
                $scope.activeMonth2 = d2.getMonth();
            }
            $scope.focusToDate(d);
            $scope.calculateCurrentDayRange();
        }

        function handleClickNextMonth($event) {
            var d = new Date($scope.activeDate.getFullYear(), $scope.activeDate.getMonth() + 1, 1);
            $scope.focusToDate(d);
        }

        function handleClickPrevMonth($event) {
            var d = new Date($scope.activeDate.getFullYear(), $scope.activeDate.getMonth() - 1, 1);
            $scope.focusToDate(d);
        }

        function handleClickSelectToday() {
            var d = new Date(), d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate());

            setStartDate(d1);
            setEndDate(d1);
            $scope.selectedTemplate = 'TD';
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }

        function handleClickSelectYesterday() {
            var d = new Date(), d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);

            setStartDate(d1);
            setEndDate(d1);
            $scope.selectedTemplate = 'YD';
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }


        function handleClickSelectThisWeek() {
            var p = new Date(),
                d = new Date(p.getFullYear(), p.getMonth(), p.getDate()),
                d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - (d.getDay() - getFirstDayOfWeek())),
                d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (6 - d.getDay() + getFirstDayOfWeek()));

            setStartDate(d1);
            setEndDate(d2);
            $scope.selectedTemplate = 'TW';
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }

        function handleClickSelectLastWeek() {
            var p = new Date(),
                d = new Date(p.getFullYear(), p.getMonth(), p.getDate() - 7),
                d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate() - (d.getDay() - getFirstDayOfWeek())),
                d2 = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (6 - d.getDay() + getFirstDayOfWeek()));
            setStartDate(d1);
            setEndDate(d2);
            $scope.selectedTemplate = 'LW';
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }


        function handleClickSelectThisMonth() {
            var d = new Date(),
                d1 = new Date(d.getFullYear(), d.getMonth(), 1),
                d2 = new Date(d.getFullYear(), d.getMonth() + 1, 0);

            setStartDate(d1);
            setEndDate(d2);
            $scope.selectedTemplate = 'TM';
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }

        function handleClickSelectLastMonth(monthsBack, template) {
            var p = new Date(),
                d = new Date(p.getFullYear(), p.getMonth(), 0),
                d1 = new Date(d.getFullYear(), d.getMonth() - (monthsBack - 1), 1),
                d2 = new Date(d.getFullYear(), d.getMonth() + 1, 0);

            setStartDate(d1);
            setEndDate(d2);
            $scope.selectedTemplate = template;
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }

        function handleClickSelectThisYear() {
            var d = new Date(),
                d1 = new Date(d.getFullYear(), 0, 1),
                d2 = new Date(d.getFullYear(), 11, 31);

            setStartDate(d1);
            setEndDate(d2);
            $scope.selectedTemplate = 'TY';
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }

        function handleClickSelectLastYear() {
            var d = new Date(),
                d1 = new Date(d.getFullYear() - 1, 0, 1),
                d2 = new Date(d.getFullYear() - 1, 11, 31);

            setStartDate(d1);
            setEndDate(d2);
            $scope.selectedTemplate = 'LY';
            $scope.calculateCurrentDayRange();
            $scope.focusToDate($scope.dateStart);
        }

        function isSelectedStartDate(date) {
            return getDateDiff($scope.dateStart, date) === 0;
        }

        function isSelectedEndDate(date) {
            return getDateDiff($scope.dateEnd, date) === 0;
        }

        function isToday(date) {
            return getDateDiff(date, new Date()) === 0;
        }

        function focusToDate(d) {
            var d2 = new Date(d.getFullYear(), d.getMonth() + 1, 1);
            $scope.activeDate = d;
            $scope.activeMonth = d.getMonth();
            $scope.activeYear = d.getFullYear();

            $scope.activeDate2 = d2;
            $scope.activeMonth2 = d2.getMonth();
            $scope.activeYear2 = d2.getFullYear();

            $scope.dates = fillDateGrid(d);
            $scope.dates2 = fillDateGrid(d2);
        }
    }

    var const_days = [{
        id: 0,
        name: "MON",
        value: "Monday",
        isSelected: false
    }, {
        id: 1,
        name: "TUE",
        value: "Tuesday",
        isSelected: false
    }, {
        id: 2,
        name: "WED",
        value: "Wednesday",
        isSelected: false
    }, {
        id: 3,
        name: "THU",
        value: "Thursday",
        isSelected: false
    }, {
        id: 4,
        name: "FRI",
        value: "Friday",
        isSelected: false
    }, {
        id: 5,
        value: "Saturday",
        name: "SAT",
        isSelected: false
    },
    {
        id: 6,
        name: "SUN",
        value: "Sunday",
        isSelected: false
    }];
}());
