﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("pcdCanvasChartController", pcdCanvasChartController);

    function pcdCanvasChartController($state, $attrs, $timeout, $scope, $rootScope, $element, environmentConfig, chartsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 6;
        vm.chartLoading = false;
        vm.searchBarService = searchBarService;
        vm.chartRendering = false;
        vm.chartServerError = false;
        vm.chartsService = chartsService;

        vm.getChartbySelectedTab = getChartbySelectedTab;

        if (!$scope.fetchData) {
            $scope.updateData = function (data) {
                if (data && data != "{}") {
                    var jsonData = JSON.stringify(data, null, 4);

                    $scope.loading = false;
                    vm.chartLoading = true;
                    vm.pcdChartWidget.processPCDData(JSON.parse(jsonData, JSON.dateParser));
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        vm.pcdChartWidget = {
            //selected Direction tab
            selectedTabChange: function (index, direction) {
                vm.pcdChartWidget.selectedDirection = direction;
            },
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.pcdChartWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            addPlanLabels: function (plans) {
                var plansCopy = Object.create(plans)
                for (let i = 0; i < plansCopy.length; i++) {
                    var plan = plansCopy[i];
                    plan.labels = [];

                    //add plan header
                    var planText = "";
                    switch (plan.planNumber) {
                        case 254:
                            planText = "Free";
                            break;
                        case 255:
                            planText = "Flash";
                            break;
                        default:
                            planText = "Plan " + plan.planNumber;
                            break;
                    }
                    plan.labels.push(planText, "Progression: " + plan.progressionQuality,
                        "Vehicles Platooning: " + plan.percentVehiclesInPlatoon + "%",
                        "AoG: " + plan.percentArrivalOnGreen + "% / Platoon: " + plan.platoonRatio);
                    plansCopy[i] = plan;
                }
                return plansCopy;
            },
            isRendered: false,
            selectedDirection: "",
            //holds each chart data
            chartArray: {},
            //logic for taking server events and manipulating into format the chart is expecting
            processPCDData: function (rawData) {
                var charts = {};
                var tabIndex = 0;

                //rawData is an array holding each phase data
                rawData.forEach(function (phaseItem) {
                    var allPhaseChartDataset = [];
                    var chartData = [];
                    if (!charts[phaseItem.direction]) {
                        charts[phaseItem.direction] = [];
                    }
                    //setup phase info object for later use on tooltips
                    //and to store the green/yellow/red/detector data 
                    var phaseInfo = {
                        direction: phaseItem.direction,
                        platoonRatio: phaseItem.platoonRatio,
                        totalArrivalOnGreen: phaseItem.totalArrivalOnGreen,
                        totalArrivalOnRed: phaseItem.totalArrivalOnRed,
                        totalGreenTime: phaseItem.totalGreenTime,
                        totalRedTime: phaseItem.totalRedTime,
                        totalVolume: phaseItem.totalVolume,
                        totalYellowTime: phaseItem.totalYellowTime,
                        totalDelay: phaseItem.totalDelay,
                        percentArrivalOnGreen: phaseItem.percentArrivalOnGreen,
                        percentGreen: phaseItem.percentGreen,
                        avgDelay: phaseItem.avgDelay,
                        progressionQuality: phaseItem.progressionQuality,
                        percentVehiclesInPlatoon: phaseItem.percentVehiclesInPlatoon
                    };

                    if (!phaseItem.pcdSummary || !phaseItem.pcdSummary.cycleData)
                        return;

                    let redData = [], greenData = [], yellowData = [], platoonActivations = [], nonPlatoonActivations = [];
                    for (let cycleDay in phaseItem.pcdSummary.cycleData.cycleCollection) {
                        let oneDayCycles = phaseItem.pcdSummary.cycleData.cycleCollection[cycleDay];
                        let dayStartTime = new Date(cycleDay);
                        for (let i = 0; i < oneDayCycles.length; i++) {
                            let thisCycle = oneDayCycles[i];
                            var cycleStartTime = new Date(dayStartTime.getTime() + thisCycle.cycleStart);
                            //red data is from cycle start to when green starts
                            redData.push({
                                x: cycleStartTime,
                                y: (thisCycle.redTime / 1000)
                            });
                            //green data is from cycle starts to yellow start
                            greenData.push({
                                x: cycleStartTime,
                                y: (thisCycle.greenTime / 1000)
                            });
                            //yellow data is from cycle starts to end start
                            yellowData.push({
                                x: cycleStartTime,
                                y: (thisCycle.yellowTime / 1000)
                            });
                            for (let k = 0; k < thisCycle.platoonActivations.length; k++) {
                                // let activationStart = new Date(cycleStartTime.getTime() + thisCycle.platoonActivations[k]); 
                                platoonActivations.push({
                                    x: cycleStartTime,
                                    y: (thisCycle.platoonActivations[k] / 1000)
                                });
                            }
                            for (let r = 0; r < thisCycle.nonPlatoonActivations.length; r++) {
                                // let activationStartNonPlatoon = new Date(cycleStartTime.getTime() + thisCycle.nonPlatoonActivations[r]); 
                                nonPlatoonActivations.push({
                                    x: cycleStartTime,
                                    y: (thisCycle.nonPlatoonActivations[r] / 1000)
                                });
                            }
                        }
                    }
                    let volumeData = [];
                    for (let volumeKey in phaseItem.pcdSummary.volumeData.dataPointsCollection) {
                        let oneDayVolumes = phaseItem.pcdSummary.volumeData.dataPointsCollection[volumeKey];
                        let dayStartTime = new Date(volumeKey);
                        for (let i = 0; i < oneDayVolumes.length; i++) {
                            let thisVolume = oneDayVolumes[i];
                            //red data is from cycle start to when green starts
                            // if daystarttime + thisvolume.x > lastcycle don't add extra volume
                            if ((dayStartTime.getTime() + thisVolume.x) <= redData[redData.length - 1].x.getTime()) {
                                volumeData.push({
                                    x: new Date(dayStartTime.getTime() + thisVolume.x),
                                    y: thisVolume.y
                                });
                            }
                        }
                    }
                    //insert an extra volume bin at the end so our chart doesn't "stop short" since our data points occur at the beginning of the bin
                    var lastVolume = volumeData[volumeData.length - 1];
                    if (lastVolume) {
                        var lastCycle = redData[redData.length - 1];
                        if (lastCycle) {
                            volumeData.push({
                                x: lastCycle.x,
                                y: lastVolume.y
                            });
                        }
                    }

                    if (redData && redData.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Phase Red",
                            type: 'bar',
                            yAxisID: '1',
                            data: redData,
                            disableUnchecking: true,
                            backgroundColor: vm.chartsService.colors.signalRed, //'#cc3232',
                            fillColor: vm.chartsService.colors.signalRed,
                            borderColor: vm.chartsService.colors.signalRed,
                            strokeColor: vm.chartsService.colors.signalRed,
                            order: 999,
                            barPercentage: 1,
                            categoryPercentage: 1.0,
                            barThickness: 'flex',
                        });
                    }

                    if (greenData && greenData.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Phase Green",
                            type: 'bar',
                            yAxisID: '1',
                            data: greenData,
                            disableUnchecking: true,
                            backgroundColor: vm.chartsService.colors.signalGreen, //'#2dc937',
                            fillColor: vm.chartsService.colors.signalGreen,
                            borderColor: vm.chartsService.colors.signalGreen,
                            strokeColor: vm.chartsService.colors.signalGreen,
                            order: 999,
                            barPercentage: 1,
                            categoryPercentage: 1.0,
                            barThickness: 'flex',
                        });
                    }

                    if (yellowData && yellowData.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Phase Yellow",
                            type: 'bar',
                            yAxisID: '1',
                            data: yellowData,
                            disableUnchecking: true,
                            fillColor: vm.chartsService.colors.signalYellow,
                            strokeColor: vm.chartsService.colors.signalYellow,
                            borderColor: vm.chartsService.colors.signalYellow,
                            backgroundColor: vm.chartsService.colors.signalYellow, //'#e7b416',
                            order: 999,
                            barPercentage: 1,
                            categoryPercentage: 1.0,
                            barThickness: 'flex',
                        });
                    }
                    if (volumeData && volumeData.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Volume",
                            type: 'line',
                            yAxisID: '2',
                            data: volumeData,
                            fill: false,
                            pointRadius: 0,
                            pointHoverRadius: 2,
                            borderColor: '#000000',
                            backgroundColor: '#000000',
                            order: 1,
                        });
                    }

                    let options = vm.chartsService.getPcdOptions();
                    let arrivalHighlightColor = "#1e25e5";
                    let arrivalSecondaryColor = "#1e88e5";
                    let platoonColor = options.highlight == 'platoon' ? arrivalHighlightColor : arrivalSecondaryColor;
                    let randomArrivalColor = options.highlight == 'random' ? arrivalHighlightColor : arrivalSecondaryColor;

                    if (nonPlatoonActivations && nonPlatoonActivations.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Random Arrivals",
                            type: 'scatter',
                            yAxisID: '1',
                            data: nonPlatoonActivations,
                            backgroundColor: '',
                            fillColor: randomArrivalColor,
                            pointBackgroundColor: randomArrivalColor, //'#cc3232',
                            pointFillColor: randomArrivalColor,
                            pointBorderColor: randomArrivalColor,
                            pointStrokeColor: randomArrivalColor,
                            pointRadius: 2,
                            pointHoverRadius: 2,
                            order: options.highlight == 'random' ? 2 : 3,
                        });
                    }
                    if (platoonActivations && platoonActivations.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Platoon Arrivals",
                            type: 'scatter',
                            yAxisID: '1',
                            data: platoonActivations,
                            backgroundColor: '', //1f77b4
                            fillColor: platoonColor,
                            pointBackgroundColor: platoonColor, //'#cc3232',
                            pointFillColor: platoonColor,
                            pointBorderColor: platoonColor,
                            pointStrokeColor: platoonColor,
                            pointRadius: 2,
                            pointHoverRadius: 2,
                            order: options.highlight == 'platoon' ? 2 : 3,
                        });
                    }
                    //push this phase chart back into the main chartArray. Each phase needs it's own chart
                    var phaseTypeText = phaseItem.isPermissive ? "Permissive" : "Protected";
                    if (phaseTypeText == "Protected") {
                        charts[phaseItem.direction].push({
                            title: "Purdue Coordination Diagram",
                            subLine1: $scope.signal.description + " - " + phaseInfo.direction + " " + phaseItem.movement + " - " + phaseTypeText + " Phase " + phaseItem.phaseNumber,
                            subLine2: "Progression Quality: " + phaseInfo.progressionQuality + " / Vehicles Platooning: " + phaseInfo.percentVehiclesInPlatoon + "%" + " / Percent Green: " + phaseInfo.percentGreen + "%",
                            subLine3: "AoG: " + phaseInfo.percentArrivalOnGreen + "%" + " / Total AoG: " + phaseInfo.totalArrivalOnGreen + " / Total AoR: " + phaseInfo.totalArrivalOnRed + " / Platoon Ratio: " + phaseInfo.platoonRatio,
                            plans: vm.pcdChartWidget.addPlanLabels(phaseItem.plans),
                            planColor: "#000",
                            api: {},
                            tabIndex: tabIndex,
                            isDataAvailable: (Object.keys(phaseItem.pcdSummary.cycleData.cycleCollection).length > 0),
                            onDestroy: function () {
                                this.api = {};
                            },
                            onApiInit: function (apiObj) {
                                this.api = apiObj;
                                this.render(apiObj);
                            },
                            render: function (apiObj) {
                                apiObj.render(undefined, this.dataset, this.chartOptions);
                                vm.chartRendering = false;
                                this.isRendered = true;
                            },
                            hidePlanLabels: function (e, chart) {
                                chart.plansHidden = !chart.plansHidden;
                                chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                                Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                                this.plans.visible = false;
                                if (chart.plansHidden) {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels2 = plan.labels;
                                        plan.labels = [];
                                    });
                                    chart.planColor = "#fff";
                                } else {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels = plan.labels2;
                                    });
                                    chart.planColor = "#000";
                                }
                                chart.api.update();
                            },
                            isRendered: false,
                            dataset: allPhaseChartDataset,
                            data: chartData,
                            chartOptions: {
                                hidePlans: false,
                                skipStackZoom: true,
                                animation: false,
                                tooltips: {
                                    mode: 'nearest',
                                    intersect: 'true',
                                },
                                legend: {
                                    display: true,
                                    align: 'end',
                                    //disables the legend filtering
                                    onClick: (e, legendItem) => {
                                        e.stopPropagation()
                                    }

                                },
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        type: 'time',
                                        time: {
                                            unit: 'hour'
                                        },
                                        ticks: {
                                            minRotation: 0,
                                            maxRotation: 0,
                                            autoSkip: true,
                                            autoSkipPadding: 50,
                                        }
                                    }],
                                    yAxes: [{
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Time (seconds)',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        id: '1',
                                        stacked: true,
                                        type: 'linear',
                                        position: 'left',

                                    }, {
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Volume',
                                            fontFamily: 'Roboto',
                                            fontSize: 14

                                        },
                                        id: '2',
                                        type: 'linear',
                                        position: 'right',
                                    }]
                                },
                                plugins: {
                                    zoom: {
                                        pan: {
                                            enabled: false,
                                            mode: 'x',
                                            rangeMin: {
                                                x: redData && redData.length > 0 ? redData[0].x.getTime() : undefined
                                            },
                                            rangeMax: {
                                                x: redData && redData.length > 0 ? redData[redData.length - 1].x.getTime() : undefined
                                            }
                                        },
                                        zoom: {
                                            enabled: true,
                                            drag: true,
                                            speed: 0.1,
                                            mode: 'x',
                                            rangeMin: {
                                                x: redData && redData.length > 0 ? redData[0].x.getTime() : undefined
                                            },
                                            rangeMax: {
                                                x: redData && redData.length > 0 ? redData[redData.length - 1].x.getTime() : undefined
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                    vm.chartRendering = true;
                    vm.chartLoading = false;
                    tabIndex++;
                });
                //set our chart array back to our binding object
                vm.pcdChartWidget.chartArray = charts;
            }
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getChartbySelectedTab() {
            if (vm.pcdChartWidget.chartArray) {
                var chart = vm.pcdChartWidget.chartArray[vm.pcdChartWidget.selectedDirection];
                if (chart && chart[0] && chart[0].api) {
                    return chart[0];
                }
            }
        }


        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions, true)
                .then(function (data) {
                    $scope.loading = false;
                    vm.chartLoading = true;
                    //JSON stringify the server data and then process it for the chart
                    var rawData = JSON.stringify(data, null, 4);
                    vm.pcdChartWidget.processPCDData(JSON.parse(rawData, JSON.dateParser));
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
}());
