﻿(function () {

    "use strict";

    angular
        .module("app.spm.core")
        .service("signalPerformanceService", signalPerformanceService);

    function signalPerformanceService(signalPerformanceResource, $state, $rootScope, $mdDialog, $mdToast) {

        var aggregationNames = Object.freeze(
        {
            Volumes: "volumes",
            VehicleDelay: "vehicleDelay",
            Cycles: "cycles",
            PhaseTerminations: "phaseTerminations",
            Pcd: "pcd",
            YellowRed: "yellowRed",
            Queue: "queue",
            SplitFails: "splitFails",
            Priority: "priority",
            Preemption: "preemption",
            Peds: "peds",
            DetectorCounts: "detectorCounts"
        });

        var metricTypesEnum = Object.freeze({
            Volume: "Volume",
            EventCount: "EventCount",
            TotalCycles: "TotalCycles",
            GreenTime: "GreenTime",
            RedTime: "RedTime",
            YellowTime: "YellowTime",
            PedActuations: "PedActuations",
            SplitFails: "SplitFails",
            GapOuts: "GapOuts",
            ForceOffs: "ForceOffs",
            MaxOuts: "MaxOuts",
            Unknown: "Unknown",
            PriorityRequests: "PriorityRequests",
            PriorityServiceEarlyGreen: "PriorityServiceEarlyGreen",
            PriorityServiceExtendedGreen: "PriorityServiceExtendedGreen",
            PriorityNumber: "PriorityNumber",
            ArrivalsOnGreen: "ArrivalsOnGreen",
            ArrivalsOnRed: "ArrivalsOnRed",
            ArrivalsOnYellow: "ArrivalsOnYellow",
            PercentArrivalsOnGreen: "PercentArrivalsOnGreen",
            PlatoonRatio: "PlatoonRatio",
            SevereRedLightViolations: "SevereRedLightViolations",
            TotalRedLightViolations: "TotalRedLightViolations",
            PreemptNumber: "PreemptNumber",
            PreemptRequests: "PreemptRequests",
            PreemptServices: "PreemptServices",
            SummedSpeed: "SummedSpeed",
            AverageSpeed: "AverageSpeed",
            SpeedVolume: "SpeedVolume",
            Speed85Th: "Speed85Th",
            Speed15Th: "Speed15Th",
            PedCount: "PedCount",
            PedDelay: "PedDelay",
            TotalDelay: "TotalDelay",
            DelayPerVehicle: "DelayPerVehicle",
            QueueLength: "QueueLength",
            QueueSpillback: "QueueSpillback"
        })

        function createOptionsObject(signalId, aggTypes, startDate, endDate, startHour, startMinute, endHour, endMinute,
            daysOfWeekArray, timeOption, binSize, approachId, phaseNumber) {
            var opts = {
                signalId: signalId,
                approachId: approachId,
                phaseNumber: phaseNumber,
                aggregationTypes: aggTypes,
                startDateTime: startDate,
                endDateTime: endDate,
                timeOfDayStartHour: startHour,
                timeOfDayStartMinute: startMinute,
                timeOfDayEndHour: endHour,
                timeOfDayEndMinute: endMinute,
                daysOfWeek: daysOfWeekArray,
                timeOption: timeOption,
                selectedBinSize: binSize,
            }
            return opts;
        }

        function getMetricForSignal(opts) {
            return signalPerformanceResource.getForSignal(opts,
                function (data) {
                    return data;
                }).$promise;
        }

        function getMetricForApproach(options) {
            return signalPerformanceResource.getForSignal({ options: options },
                function (data) {
                    return data;
                }).$promise;
        }

        function getMetricForPhase(options) {
            return signalPerformanceResource.getForSignal({ options: options },
                function (data) {
                    return data;
                }).$promise;
        }

        function getExecutiveInformation(options) {
            return signalPerformanceResource.getExecutiveInformation(options,
                function (data) {
                    return data;
                }).$promise;
        }

        //depending on which metric is being shown, Volume, split fails, delay per vechiel, etc.
        //this function will map the property value paths in the response from the server so we can 
        //generically access them from JSON
        function getValuePropertiesFromType(metricType){
            let res = {
                useTotal: false,
                valueProperty: "",
                summaryTotalProperty: "",
                summaryAverageProperty: "",
            };
            switch(metricType){
                case metricTypesEnum.Volume:
                    res.useTotal = true;
                    res.valueProperty = "totalVolume";
                    res.summaryTotalProperty = "totalVolume";
                    res.summaryAverageProperty = "averageVolume";
                    break;
                case metricTypesEnum.SplitFails:
                    res.useTotal = true;
                    res.valueProperty = "totalSplitFails";
                    res.summaryAverageProperty = "averageSplitFails";
                    res.summaryTotalProperty = "totalSplitFails"
                    break;
                case metricTypesEnum.DelayPerVehicle: 
                    res.useTotal = false;
                    res.valueProperty = "delayPerVehicle";
                    res.summaryAverageProperty = "delayPerVehicle";
                    res.summaryTotalProperty = ""
                    break;
                case metricTypesEnum.PlatoonRatio:
                    res.useTotal = false;
                    res.valueProperty = "platoonRatio";
                    res.summaryAverageProperty = "platoonRatio";
                    res.summaryWeightedAverageProperty = "weightedPlatoonRatio";
                    res.summaryTotalProperty = ""
                    break;
                case metricTypesEnum.PercentArrivalsOnGreen:
                    res.useTotal = false;
                    res.valueProperty = "percentArrivalsOnGreen";
                    res.summaryAverageProperty = "percentArrivalsOnGreen";
                    res.summaryTotalProperty = ""
                    break;
            }
            return res;
        }

        return {
            getMetricForSignal: getMetricForSignal,
            getMetricForApproach: getMetricForApproach,
            getMetricForPhase: getMetricForPhase,
            createOptionsObject: createOptionsObject,
            metricTypesEnum: metricTypesEnum,
            getExecutiveInformation: getExecutiveInformation,
            aggregationNames: aggregationNames,
            getValuePropertiesFromType: getValuePropertiesFromType
        }
    }
})();
