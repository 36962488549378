(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.user-settings')
        .controller('DashboardReportSettingsController', DashboardReportSettingsController);

    /** @ngInject */
    function DashboardReportSettingsController($rootScope, $scope, $window, $state, $mdDialog, signalService, insightsService, userSettingsService, $mdToast,$interval) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        $scope.loading = true;
        vm.isExpanded = false;
        vm.getUserSettings = getUserSettings;
        vm.setUserSettings = setUserSettings;
        vm.saveReportSettings = saveReportSettings;
        vm.selectedSignals = [];
        vm.allSignals = [];
        vm.selectedItems = [];
        vm.isEmailEnabled = false;
        vm.close = function close() {
            $mdDialog.cancel();
        };
        vm.setupDataTable = setupDataTable;
        vm.getSignals = getSignals;
        
        vm.initialize = initialize;
        vm.initialize();
      
        // angular.element(document.querySelector('[aria-label="Select All"]'))[0].style = "visibility: hidden";        
        // Before everything check if any signal is selected 
        // if yes than fulfill that information in vm.signals -> by adding new property is selected!


      

        var bookmark;

        vm.searchChange = function (newValue, oldValue) {
            if (!oldValue) {
                bookmark = vm.queryData.pageIndex;
            }

            if (newValue !== oldValue) {
                vm.queryData.pageIndex = 1;
            }

            if (!newValue) {
                vm.queryData.pageIndex = bookmark;
            }

            vm.getSignals();
        };

        vm.checkMax = function(){
            if(vm.selectedItems.length>10){
                angular.element(document.getElementById('tableOfSignals'))[0].style = "pointer-events: none";
            }
            else{
                angular.element(document.getElementById('tableOfSignals'))[0].style = "";

            }
        }

        function initialize(){
            vm.setupDataTable();
            signalService.getAllSignals().then(function (data){
                vm.allSignals = data.signals;
            });
            vm.getSignals();
            vm.rowCollection = vm.signals;

            vm.disabled = true;
            vm.userSettingsViewModel = {
                userID: sessionStorage.getItem('userID'),
                userEmail: sessionStorage.getItem('userEmail'),
                insightSettings: {
                    emailsEnabled: false,
                    enabledInsightCategories: []
                }
            };

            vm.reportSettings = {
                emailsEnabled: false,
                userID: sessionStorage.getItem('userID'),
                userEmail: sessionStorage.getItem('userEmail'),
                monthlySignalReportSettings: {
                    subscribedSignalIds: []
                }
            }
            vm.getUserSettings();
        }

        function getSignals() {
            vm.signalsPromise = signalService.querySignals(vm.queryData);
            vm.signalsPromise.then(function(data) {
                getSignalsSuccess(data);
            });
        }

        function setupDataTable() {
            vm.selected = [];
            vm.totalCount = {};
            vm.options = {
                rowSelection: true,
                multiSelect: true,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: true,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };

            vm.queryData = {
                orderBy: 'customID',
                pageSize: 5,
                pageIndex: 1,
                filter: '',
                id: '',
                fileType: ''
            };
        }
        
        function getSignalsSuccess(data) {
            vm.totalCount = data.totalCount;
            vm.totalQueryCount = data.totalCountInQuery;
            vm.signals = data.signals;
            vm.lookups = data.lookups;
        }
        
        function getUserSettings() {
            userSettingsService.getUserSettings()
                .then(function (userSettings) {
                    if(userSettings.reportSettings && userSettings.reportSettings.monthlySignalReportSettings && userSettings.reportSettings.monthlySignalReportSettings.subscribedSignalIds.length >0){
                        let selectedItems = [];
                        vm.isEmailEnabled = userSettings.reportSettings.emailsEnabled;
                        userSettings.reportSettings.monthlySignalReportSettings.subscribedSignalIds.forEach(function(signalID){
                            let s = vm.allSignals.find(x => x.signalID === signalID);
                            s && selectedItems.push(s);
                        })
                        vm.selectedItems = selectedItems;
                    }
                    $scope.loading = false;
                    vm.selectAllIcon = $interval(function () {
                        if (angular.element(document.querySelector('[aria-label="Select All"]')) && angular.element(document.querySelector('[aria-label="Select All"]'))[0]) {
                            $interval.cancel(vm.selectAllIcon);
                            angular.element(document.querySelector('[aria-label="Select All"]'))[0].style = "visibility: hidden";
                        }
                    }, 300)
                })
        }


        function setUserSettings(userSettings) {
            userSettingsService.setUserSettings(userSettings);
        }

        function saveReportSettings(selectedItems) {
            // Just testing , fullfiling ReportSettings

            let signalIDs =[];
            selectedItems.forEach(function(signal,i){
               signal && signalIDs.push(signal.signalID);
            });

            console.log(selectedItems)
            vm.reportSettings.emailsEnabled = true;
            vm.reportSettings.monthlySignalReportSettings.subscribedSignalIds = signalIDs;            
            userSettingsService.setReportSettings(vm.reportSettings)

        }
    }

})();