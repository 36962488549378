﻿import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("phaseTerminationController", phaseTerminationController);

    function phaseTerminationController($scope, chartsService, $timeout, insightsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 1;
        vm.chartRendering = false;
        vm.chartServerError = false;
        vm.formatAMPM = formatAMPM;
        insightsService.setInsightsSidemenu();

        if (!$scope.fetchData) {
            $scope.updateData = function (data) {
                if (data && data != "{}") {
                    vm.chartServerError = false;
                    var jsonData = JSON.stringify(data, null, 4);
                    vm.phaseTerminationWidget.processPhaseTerminationData(JSON.parse(jsonData, JSON.dateParser));
                    $scope.loading = false;
                }
            }

        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.api = {};
            $scope.onApiInit = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });


        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        vm.phaseTerminationWidget = {
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.phaseTerminationWidget.chartSetup);
                clonedChartSetup.options.chart.planData = angular.copy(planData);
                return clonedChartSetup;
            },
            isRendered: false,
            chartArray: {},
            planLabelCallback: function (plans) {
                for (let i = 0; i < plans.length; i++) {
                    var plan = plans[i];
                    plan.labels = [];
                    var res = plan.cycleLabel + ": " + plan.cycleCount.toLocaleString();
                    if (plan) {

                        if (plan.preemptionCount > 0) {
                            res += "\n" + plan.preemptionLabel + ": " + plan.preemptionCount.toLocaleString();
                        }

                        if (plan.tspCount > 0) {
                            if (plan.preemptionCount > 0) res += "\t" + plan.tspLabel + ": " + plan.tspCount.toLocaleString();
                            else res += "\n" + plan.tspLabel + ": " + plan.tspCount.toLocaleString();
                        }

                        var planText = "";
                        switch (plan.planNumber) {
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }

                        plan.labels.push(planText, res);
                    }
                }
                return plans;
            },
            //logic for taking server events and manipulating into format the chart is expecting
            processPhaseTerminationData: function (rawData) {
                var chartData = [];
                var data = [],
                    shapes = ['circle', 'rectRot', 'triangle', 'cross', 'rectRot', 'rect'];
                var planList = [];
                var thisShape = "";
                var group = "";
                var groupedData = {};
                var phaseCount = 0;
                var isPedestrian = false;
                var thisShapePed = "";
                var groupPed = "";
                var groupPrempt = "";
                var thisShapePrempt = shapes[1];
                var thisColorPrempt = chartsService.colors.redPreemption;
                var groupTSPCheckIn = '';
                var groupTSPCheckOut = '';
                var thisColorTcpCheckIn = chartsService.colors.yellowCheckIn;
                var groupTSPEarlyGreen = '';
                var groupTSPExtendGreen = '';
                var thisColorExtend = chartsService.colors.greenExtend;
                var thisColorEarly = chartsService.colors.greenEarly;
                var planLength = rawData.plans.length;
                var thisShapeTSP = shapes[3];
                var incr = 0;
                vm.maxPhase = rawData.maxPhaseInUse;


                function addOrGetExistingPlan(thisPlan, cycleLabel, cycleCount, preemptionLabel, preemptionCount, tspLabel, tspCount) {
                    var plan = planList.find(function (e) { return new Date(e.startTime).getTime() == new Date(thisPlan.startTime).getTime() });
                    if (!plan) {
                        plan = {
                            planNumber: thisPlan.planNumber,
                            startTime: thisPlan.startTime,
                            endTime: thisPlan.endTime,
                            cycleLabel: cycleLabel,
                            cycleCount: cycleCount,
                            preemptionLabel: preemptionLabel,
                            preemptionCount: preemptionCount,
                            tspLabel: tspLabel,
                            tspCount: tspCount
                        };
                        planList.push(plan);
                    }
                    return plan;
                }

                rawData.phases.forEach(function (phaseItem) {
                    var phaseNum = phaseItem.phaseNumber;
                    phaseCount++;
                    var thisColor;
                    var thisColorPed;
                    var thisOrder;
                    phaseItem.cycles.forEach(function (cycleItem) {
                        switch (cycleItem.terminationEvent) {
                            case 4:
                                group = "Gap Out";
                                thisShape = shapes[0];
                                thisOrder = 4;
                                thisColor = chartsService.colors.gapOut;
                                if (cycleItem.hasPedActivity) {
                                    groupPed = "Ped Activity";
                                    thisShapePed = shapes[2];
                                    thisColorPed = chartsService.colors.purplePedestrian;
                                    isPedestrian = true;
                                }
                                break;
                            case 5:
                                thisShape = shapes[5];
                                group = "Max Out";
                                thisOrder = 2;
                                thisColor = chartsService.colors.maxOut;
                                if (cycleItem.hasPedActivity) {
                                    groupPed = "Ped Activity";
                                    thisShapePed = shapes[2];
                                    thisColorPed = chartsService.colors.purplePedestrian;
                                    isPedestrian = true;
                                }
                                break;
                            case 6:
                                group = "Force Off";
                                thisShape = shapes[4];
                                thisOrder = 3;
                                thisColor = chartsService.colors.forceOff;
                                if (cycleItem.hasPedActivity) {
                                    groupPed = "Ped Activity";
                                    thisShapePed = shapes[2];
                                    thisColorPed = chartsService.colors.purplePedestrian;
                                    isPedestrian = true;
                                }
                                break;
                            case 0:
                            default:
                                group = "Unknown"
                                thisShape = shapes[0];
                                thisOrder = 1;
                                thisColor = chartsService.colors.unknown;
                                if (cycleItem.hasPedActivity) {
                                    groupPed = "Ped Activity";
                                    thisShapePed = shapes[2];
                                    thisColorPed = chartsService.colors.purplePedestrian;
                                    isPedestrian = true;
                                }
                                break;
                        }
                        if (!groupedData[group]) {
                            groupedData[group] = {
                                label: group,
                                key: group,
                                data: [],
                                type: 'scatter',
                                yAxisID: '1',
                                order: thisOrder,
                                pointStyle: thisShape,
                                xAxisID: 'x-axis-0',
                                backgroundColor: thisColor,
                            };
                        }

                        groupedData[group].data.push({
                            x: new Date(cycleItem.startTime),
                            y: phaseNum,
                            // shape: thisShape
                        });

                        if (isPedestrian) {
                            if (!groupedData[groupPed]) {
                                groupedData[groupPed] = {
                                    label: groupPed,
                                    key: groupPed,
                                    data: [],
                                    type: 'scatter',
                                    yAxisID: '1',
                                    xAxisID: 'x-axis-0',
                                    pointStyle: thisShapePed,
                                    backgroundColor: thisColorPed,
                                };
                            }

                            groupedData[groupPed].data.push({
                                x: new Date(cycleItem.startTime),
                                y: phaseNum + 0.2,
                                // size: 1,
                                // shape: thisShapePed,
                            });
                        }

                        isPedestrian = false;

                    });


                });

                if (rawData.preempts && rawData.preempts.length > 0) {
                    incr = 0.5;
                    groupPrempt = "Preemption";
                    rawData.preempts.forEach(function (premptItem) {
                        premptItem.cycles.forEach(function (cycle) {
                            if (!groupedData[groupPrempt]) {
                                groupedData[groupPrempt] = {
                                    label: groupPrempt,
                                    key: groupPrempt,
                                    data: [],
                                    type: 'scatter',
                                    yAxisID: '1',
                                    pointStyle: thisShapePrempt,
                                    xAxisID: 'x-axis-0',
                                    backgroundColor: thisColorPrempt,
                                };
                            }
                            groupedData[groupPrempt].data.push({
                                x: new Date(cycle.timeToService.x),
                                y: premptItem.preemptNumber + incr,
                                size: 1,
                                // shape: thisShapePrempt
                            });
                        });
                    });
                }

                if (rawData.tspCycles && rawData.tspCycles.length > 0) {
                    groupTSPCheckIn = "TSP Check In";
                    groupTSPCheckOut = "TSP Check Out";
                    groupTSPEarlyGreen = "TSP Early Green";
                    groupTSPExtendGreen = "TSP Extend Green";

                    rawData.tspCycles.forEach(function (tspCycle) {

                        if (!groupedData[groupTSPCheckIn]) {
                            groupedData[groupTSPCheckIn] = {
                                label: groupTSPCheckIn,
                                key: groupTSPCheckIn,
                                data: [],
                                type: 'scatter',
                                yAxisID: '1',
                                xAxisID: 'x-axis-0',
                                pointStyle: thisShapeTSP,
                                backgroundColor: thisColorTcpCheckIn,
                            };
                        }
                        groupedData[groupTSPCheckIn].data.push({
                            x: new Date(tspCycle.checkInTime),
                            y: tspCycle.tspNumber + 0.7,
                            // size: 1,
                            // shape: thisShapeTSP
                        });


                        if (tspCycle.adjustmentToEarlyGreen) {
                            if (!groupedData[groupTSPEarlyGreen]) {
                                groupedData[groupTSPEarlyGreen] = {
                                    label: groupTSPEarlyGreen,
                                    key: groupTSPEarlyGreen,
                                    data: [],
                                    type: 'scatter',
                                    yAxisID: '1',
                                    pointStyle: thisShapeTSP,
                                    xAxisID: 'x-axis-0',
                                    backgroundColor: thisColorExtend,
                                };
                            }
                            groupedData[groupTSPEarlyGreen].data.push({
                                x: new Date(tspCycle.adjustmentToEarlyGreen.earlyGreenTime),
                                y: tspCycle.adjustmentToEarlyGreen.tspNumber + 0.7,
                                // size: 1,
                                // shape: thisShapeTSP
                            });
                        }

                        if (tspCycle.adjustmentToExtendGreen) {
                            if (!groupedData[groupTSPExtendGreen]) {
                                groupedData[groupTSPExtendGreen] = {
                                    label: groupTSPExtendGreen,
                                    key: groupTSPExtendGreen,
                                    data: [],
                                    type: 'scatter',
                                    yAxisID: '1',
                                    pointStyle: thisShapeTSP,
                                    xAxisID: 'x-axis-0',
                                    backgroundColor: thisColorExtend,
                                };
                            }
                            groupedData[groupTSPExtendGreen].data.push({
                                x: new Date(tspCycle.adjustmentToExtendGreen.extendedGreenTime),
                                y: tspCycle.adjustmentToExtendGreen.tspNumber + 0.7,
                                // size: 1,
                                // shape: thisShapeTSP
                            });
                        }

                        if (tspCycle.checkOutTime) {
                            if (!groupedData[groupTSPCheckOut]) {
                                groupedData[groupTSPCheckOut] = {
                                    label: groupTSPCheckOut,
                                    key: groupTSPCheckOut,
                                    data: [],
                                    type: 'scatter',
                                    pointStyle: thisShapeTSP,
                                    yAxisID: '1',
                                    xAxisID: 'x-axis-0',
                                    backgroundColor: '#ff4c00',
                                };
                            }
                            groupedData[groupTSPCheckOut].data.push({
                                x: new Date(tspCycle.checkOutTime),
                                y: tspCycle.tspNumber + 0.7,
                                // size: 1,
                                // shape: thisShapeTSP
                            });
                        }

                    });
                }

                var totalNumberOfCyclePlan = 0;
                var totalNumberOfCycle = 0;
                var totalGapOut = 0;
                var totalForceOff = 0;
                var totalMaxOut = 0;
                var totalUnknown = 0;
                var totalPreemptions = 0;
                var totalTSP = 0;

                rawData.plans.forEach(function (plan) {

                    for (var split in plan.splits) {
                        totalGapOut = totalGapOut + plan.splits[split].gapOutCount;
                        totalForceOff = totalForceOff + plan.splits[split].forceOffCount;
                        totalMaxOut = totalMaxOut + plan.splits[split].maxOutCount;
                        totalUnknown = totalUnknown + plan.splits[split].unknownCount;
                    }

                    totalNumberOfCyclePlan = plan.cycleCount;

                    if (groupedData[groupPrempt] && groupedData[groupPrempt].values && groupedData[groupPrempt].values.length > 0) {
                        totalPreemptions = plan.totalPreemptions;
                    }

                    if (groupedData[groupTSPCheckIn] && groupedData[groupTSPCheckIn].values && groupedData[groupTSPCheckIn].values.length > 0) {
                        totalTSP = plan.totalTSP;
                    }

                    if (groupedData[groupTSPExtendGreen] && groupedData[groupTSPExtendGreen].values && groupedData[groupTSPExtendGreen].values.length > 0) {
                        totalTSP = plan.totalTSP;
                    }

                    if (groupedData[groupTSPEarlyGreen] && groupedData[groupTSPEarlyGreen].values && groupedData[groupTSPEarlyGreen].values.length > 0) {
                        totalTSP = plan.totalTSP;
                    }

                    if (groupedData[groupTSPCheckOut] && groupedData[groupTSPCheckOut].values && groupedData[groupTSPCheckOut].values.length > 0) {
                        totalTSP = plan.totalTSP;
                    }

                    addOrGetExistingPlan(plan, "Cycle Count", totalNumberOfCyclePlan, "Preemption Count", totalPreemptions, 'Total TSP', totalTSP);
                    totalNumberOfCycle = totalNumberOfCycle + totalNumberOfCyclePlan;
                    totalGapOut = 0;
                    totalForceOff = 0;
                    totalMaxOut = 0;
                    totalUnknown = 0;
                    totalNumberOfCyclePlan = 0;
                    totalPreemptions = 0;
                    totalTSP = 0;
                });

                var groupArr = [];
                for (group in groupedData) {
                    groupArr.push(groupedData[group]);
                }

                var searchObj = searchBarService.getSearchOptions();

                data.push({
                    title: "Phase Termination for " + ($scope.signal.description ? $scope.signal.description : $scope.signal.signalName),
                    primaryLine1: "Total Number of Cycles: " + totalNumberOfCycle,
                    plans: vm.phaseTerminationWidget.planLabelCallback(planList),
                    planColor: "#000",
                    // dates: vm.rlrChartWidget.addDateLabels(annotationDatesSpan),
                    api: {},
                    isDataAvailable: groupArr.length > 0,
                    onDestroy: function () {
                        this.api = {};
                    },
                    onApiInit: function (apiObj) {
                        this.api = apiObj;
                        this.render(apiObj);
                    },
                    render: function (apiObj) {
                        apiObj.render(undefined, this.dataset, this.chartOptions);
                        vm.chart =
                            vm.chartRendering = false;
                        this.isRendered = true;
                    },
                    hidePlanLabels: function (e, chart) {
                        chart.plansHidden = !chart.plansHidden;
                        chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                        Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                        this.plans.visible = false;
                        if (chart.plansHidden) {
                            chart.plans.forEach(function (plan) {
                                plan.labels2 = plan.labels;
                                plan.labels = [];
                            });
                            chart.planColor = "#fff";
                        } else {
                            chart.plans.forEach(function (plan) {
                                plan.labels = plan.labels2;
                            });
                            chart.planColor = "#000";
                        }
                        chart.api.update();
                    },
                    isRendered: false,
                    dataset: groupArr,
                    data: chartData,
                    // hover: {
                    //     mode: 'nearest'
                    // },
                    chartOptions: {
                        // layout: {
                        //     padding: {
                        //         top: 50
                        //     }
                        // },
                        hidePlans: false,
                        plugins: {
                            zoom: {
                                pan: {
                                    enabled: false,
                                    mode: 'x',
                                    rangeMin: {
                                        x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                    },
                                    rangeMax: {
                                        x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                    }
                                },
                                zoom: {
                                    enabled: true,
                                    drag: true,
                                    speed: 0.1,
                                    mode: 'x',
                                    rangeMin: {
                                        x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                    },
                                    rangeMax: {
                                        x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                    }
                                }
                            }
                        },
                        responsive: true,
                        scales: {
                            xAxes: [{
                                stacked: true,
                                type: 'time',
                                id: 'x-axis-0',
                                // time: {
                                //     unit: 'hour'
                                // },
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    minRotation: 0,
                                    maxRotation: 0,
                                    autoSkip: true,
                                    autoSkipPadding: 50,
                                }
                            },
                            ],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Phase / TSP / Preemption Number',
                                    fontFamily: 'Roboto',
                                    fontSize: 14,
                                },
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    callback: function (d) {
                                        if (d == 0) return null;
                                        if (d % 1 != 0) return null;
                                        else if (d > vm.maxPhase) return null;
                                        else return d3.format('.0f')(d);
                                    },
                                    max: vm.maxPhase + 1
                                },
                                id: '1',
                                // ticks: {
                                //     max: 8
                                // },
                                // stacked: true,
                                type: 'linear',
                                position: 'left',

                            }
                            ]
                        },
                        legend: {
                            display: true,
                            reverse: true,
                            align: 'end',
                        },
                        hover: {
                            mode: 'nearest'
                        },
                        tooltips: {
                            enabled: false,
                            mode: 'nearest',
                            position: 'average',
                            intersect: 'nearest',

                            custom: function (tooltipModel) {
                                // Tooltip Element
                                var tooltipEl = document.getElementById('chartjs-tooltip');

                                // Create element on first render
                                if (!tooltipEl) {
                                    tooltipEl = document.createElement('div');
                                    tooltipEl.id = 'chartjs-tooltip';
                                    tooltipEl.innerHTML = '<table></table>';
                                    document.body.appendChild(tooltipEl);
                                }

                                // Hide if no tooltip
                                if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = 0;
                                    return;
                                }

                                // Set caret Position
                                tooltipEl.classList.remove('above', 'below', 'no-transform');
                                if (tooltipModel.yAlign) {
                                    tooltipEl.classList.add(tooltipModel.yAlign);
                                } else {
                                    tooltipEl.classList.add('no-transform');
                                }

                                function getBody(bodyItem) {
                                    return bodyItem.lines;
                                }

                                // Set Text
                                if (tooltipModel.body) {
                                    var percentHtml = "";
                                    var valueText = "";
                                    var header = "";
                                    var valuetoShow;

                                    var plan = planList.filter(x => moment(x.startTime).isBefore(moment(tooltipModel.title[0])) && moment(x.endTime).isAfter(moment(tooltipModel.title[0])));

                                    var planText = "";
                                    switch (plan[0].planNumber) {
                                        case 254:
                                            planText = "Free";
                                            break;
                                        case 255:
                                            planText = "Flash";
                                            break;
                                        default:
                                            planText = plan[0].planNumber;
                                            break;
                                    }

                                    valueText = "<tr>" +
                                        "<td class='key'>" + 'Test' + ': ' + "</td>" +
                                        "<td class='x-value'><strong>" + 'test' + "</strong></td>" +
                                        // add to value description of that value / check cardWidget for properties
                                        "</tr>";

                                    header = "<thead>" +
                                        "<tr>" +
                                        "<td class='key'><strong>" + tooltipModel.body[0].lines[0].split(':')[0] + "</strong></td>" +
                                        "</tr>" +
                                        "</thead>";
                                    var rows =
                                        "<tr>" +
                                        "<td class='key'>" + 'Plan:' + "</td>" +
                                        "<td class='x-value'>" + planText + "</td>" +
                                        "</tr>" +
                                        "<tr>" +
                                        "<td class='key'>" + 'Event Time:' + "</td>" +
                                        "<td class='x-value'>" + moment(tooltipModel.title[0]).format('hh:mm A') + "</td>" +
                                        "</tr>" +
                                        "<tr>" +
                                        "<td class='key'>" + 'Phase: ' + "</td>" +
                                        "<td class='x-value'>" + tooltipModel.dataPoints[0].yLabel + "</td>"
                                    "</tr>"

                                    var innerHtml = "<table>" +
                                        header +
                                        "<tbody>" +
                                        rows +
                                        "</tbody>" +
                                        "</table>";

                                    var tableRoot = tooltipEl.querySelector('table');
                                    var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                    tableStyle += 'color: rgba(255,255,255, 1);';
                                    tableStyle += 'padding: 5px 10px;';

                                    tableRoot.style = tableStyle;
                                    tableRoot.innerHTML = innerHtml;
                                }

                                // `this` will be the overall tooltip
                                var position = this._chart.canvas.getBoundingClientRect();

                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.position = 'absolute';
                                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                if (position.width <= tooltipEl.offsetLeft + tooltipEl.clientWidth) {
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 150 + 'px';
                                }
                                if (position.height <= tooltipModel.caretY + tooltipEl.clientHeight) {
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 80 + 'px';
                                }
                                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                                tooltipEl.style.pointerEvents = 'none';
                            }
                        },
                    }
                });
                vm.chartRendering = true;
                vm.chartArray = data;
            }
        }
        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;

            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    // vm.rawData = JSON.stringify(data, null, 4);
                    vm.phaseTerminationWidget.processPhaseTerminationData(data);
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
}());
