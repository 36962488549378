﻿import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("customEventChartController", customEventChartController);

    function customEventChartController($state, $scope, $interval, $attrs, $rootScope, $element, controllerEventService, chartsService, searchBarService) {
        var vm = this;
        $scope.update = update;
        vm.getData = getData;
        vm.chartType = 1;
        vm.readyToRenderChart = false;
        vm.chartLoading = false;
        vm.chartServerError = false;
        vm.chartTitle = "";
        vm.groupType = 'stacked';
        vm.updateGroupType = updateGroupType;
        vm.metricType = 92;
        vm.getTimeFromTimeFrame = getTimeFromTimeFrame;
        vm.formatAMPM = formatAMPM;
        vm.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        vm.formatDate = formatDate;
        vm.ttRows = "";

        if (!$scope.signalName) {
            var options = searchBarService.getSearchOptions();
            if (options && options.signal)
                $scope.signalName = options.signal.description;
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.searchDates = null;
            $scope.binSize = null;
            $scope.signals = null;
            $scope.eventsWithParameters = null;
            $scope.signals = null;
            $scope.legendItems = null;
        });

        vm.widgetReady = function () {
            vm.chartLoading = true;
            $timeout(function () {
                vm.readyToRenderChart = true;
                vm.chartLoading = false;
            }, 5)
        };

        function updateGroupType(newGroupType) {
            vm.groupType = newGroupType;
            vm.getData();
        }

        function getTimeFromTimeFrame() {
            var opts = searchBarService.getSearchOptions();
            $scope.searchDates.endDate = opts.timeOptions.currentFilter.endDateAndTime.toLocaleString();
            $scope.searchDates.startDate = opts.timeOptions.currentFilter.startDateAndTime.toLocaleString();
            switch (opts.timeOptions.bin) {
                case "FifteenMinute":
                    $scope.binSize = 900000;
                    break;
                case "ThirtyMinute":
                    $scope.binSize = 1800000;
                    break;
                case "Hour":
                    $scope.binSize = 3600000;
                    break;
                case "Day":
                    $scope.binSize = 86400000;
                    break;
                case "OneMinute":
                    $scope.binSize = 60000;
                    break;
                case "FiveMinute":
                    $scope.binSize = 300000;
                    break;
            }
        }

        function update(signals, approach, dates) {
            if (signals) {
                $scope.signals = signals;
            }
            if (approach) {
                $scope.approach = approach;
            }
            if (dates) {
                if (!$scope.searchDates)
                    $scope.searchDates = {};

                $scope.searchDates.endDateAndTime = new Date(dates.endDateAndTime);
                $scope.searchDates.startDateAndTime = new Date(dates.startDateAndTime);
            }
            if (vm && vm.getData) {
                var cancelDataInterval = $interval(function () {
                    if ($scope.newChartData) {
                        $interval.cancel(cancelDataInterval);
                        vm.getData();
                    }
                }, 10);
            }
        }

        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        function hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ?
                "rgba(" + parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) + "," + 0.7 + ")"
                : null;
        }


        function formatDate(date) {
            return vm.monthNamesShort[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
        }

        vm.chartWidget = {
            chartObject: {},
            //config and options setup the chart
            multiBarConfig: {
                getChart: function (planData) {
                    var clonedChartSetup = angular.copy(vm.phaseTerminationWidget.chartSetup);
                    // clonedChartSetup.options.chart.planData = angular.copy(planData);
                    return clonedChartSetup;
                },
                isRendered: false,
            },
            donutChartConfig: {
                getChart: function (planData) {
                    var clonedChartSetup = angular.copy(vm.phaseTerminationWidget.chartSetup);
                    // clonedChartSetup.options.chart.planData = angular.copy(planData);
                    return clonedChartSetup;
                },
                isRendered: false,
            },

            setChartObject: function () {
                if ($scope.chartType == "bar") {
                    vm.chartWidget.chartObject = vm.chartWidget.multiBarConfig;
                }
                else if ($scope.chartType == "donut") {
                    vm.chartWidget.chartObject = vm.chartWidget.donutChartConfig;
                }
            },

            //logic for taking server events and manipulating into format the chart is expecting
            processEventData: function (signalItemDays) {
                var charts = [];
                var data = [];
                var chartData = [];
                var acctuations = 0;
                var toolTipData = [];
                if (signalItemDays && signalItemDays.length > 0) {
                    for (let p = 0; p < signalItemDays.length; p++) {
                        let signalItem = signalItemDays[p];
                        if (signalItem && signalItem.aggregatedEvents) {
                            for (var key in signalItem.aggregatedEvents) {
                                var order = 10;
                                var aggData = signalItem.aggregatedEvents[key];
                                for (var i = 0; i < aggData.length; i++) {
                                    acctuations += aggData[i];
                                    if ($scope.chartType == "bar") {
                                        if (!data[$scope.legendItems[i]]) {
                                            data[$scope.legendItems[i]] = [];
                                        }
                                        //create array to hold some tooltip info and stash it inside the chart point object

                                        //should remove this at some point, don't want to have event specific logic in this controller. 
                                        //just getting it to work for now

                                        if ($scope.approach.detectors && $scope.approach.detectors.length > 0) {
                                            toolTipData.push([{
                                                title: "Type",
                                                value: $scope.approach.detectors[i].description
                                            }, {
                                                title: "Detector ID",
                                                value: $scope.approach.detectors[i].detectorID
                                            }])
                                        } else {
                                            toolTipData.push([{
                                                title: "",
                                            }, {
                                                title: "",
                                            }])
                                        }

                                        data[$scope.legendItems[i]].push({
                                            x: new Date(key),
                                            y: aggData[i],
                                            toolTipData: toolTipData[i]
                                        });
                                    }
                                    if ($scope.chartType == "donut") {
                                        if (!data[$scope.legendItems[i]]) {
                                            data[$scope.legendItems[i]] = 0;
                                        }
                                        data[$scope.legendItems[i]] = parseInt(data[$scope.legendItems[i]]) + parseInt(aggData[i]);
                                    }
                                }
                            };
                        }
                    }
                }


                var borderScheme = ['#0C5EAF', '#63A5E8', '#A46F17', '#FFC360', '#275C29', '#9BD19D']
                var counter = 0;
                var chartLabels = [];
                var chartValues = [];

                for (var key in data) {
                    if ($scope.chartType == "bar") {
                        //remove key if it has no data
                        var hasValues = false;
                        data[key].forEach(function (value) {
                            //if we hit a value then flip has values to true
                            if (value.y > 0) {
                                hasValues = true;
                                return;
                            }
                        });
                        if (hasValues) {
                            chartData.push({
                                // distribution: 'linear',
                                // barThickness: 'flex',
                                // barPercentage: 1,
                                categoryPercentage: 1,
                                label: "Det " + key,
                                key: "Det " + key,
                                data: data[key],
                                toolTipData: toolTipData[key],
                                type: "bar",
                                yAxisID: '1',
                                backgroundColor: hexToRgb(chartsService.chartColors[counter]),
                                borderColor: chartsService.chartColors[counter],
                                borderWidth: {
                                    top: 2,
                                    right: 0,
                                    bottom: 2,
                                    left: 0
                                },
                                fillOpacity: 1
                            });
                            counter <= 6 ? ++counter : counter = 0;
                        }
                    }
                    else if ($scope.chartType == "donut" && data[key] > 0) {
                        chartLabels.push("Det " + key);
                        hasValues = true;
                        chartValues.push(data[key]);
                        chartData.push({
                            dataset: data[key],
                            key: "Det " + key,
                            backgroundColor: chartsService.chartColors[counter],
                            borderColor: hexToRgb(chartsService.chartColors[counter]),
                            color: chartsService.chartColors[counter],
                            // toolTipData: toolTipData[key],
                            type: 'doughnut',
                            label: "Det " + key,
                            fillOpacity: 1
                        });
                        /*
                        chartData.sort(function (a, b) {
                            return b.value - a.value
                        });
                        */
                    }
                }

                var noDetector = false;

                if ($scope.approach.approachID != "unassigned") {
                    noDetector = $scope.approach.detectors.length <= 0;
                }
                else if ($scope.approach.approachID == "unassigned") {
                    noDetector = false;
                }
                //push this phase chart back into the main chartArray. Each phase needs it's own chart
                if ($scope.chartType == "bar") {

                    var searchObj = searchBarService.getSearchOptions();

                    charts.push({
                        title: "Detector Counts Chart for " + $scope.signalName,
                        subLine1: "Actuations: " + acctuations,
                        dataset: chartData,
                        api: {},
                        isDataAvailable: chartData.length > 0,
                        noDetector: noDetector,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        isRendered: false,
                        chartOptions: {
                            doMagic: true,
                            responsive: true,
                            scales: {
                                xAxes: [{
                                    // distribution: 'linear',
                                    // barThickness: 'flex',
                                    barPercentage: 1,
                                    categoryPercentage: 1,
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    time: {
                                        unit: 'hour'
                                    },
                                    offset: true,
                                    ticks: {

                                        beginAtZero: true,
                                        // maxTicksLimit: 25,
                                        // callback: function (value, index, values) {
                                        //     return moment(values[index].value).format('MM-DD hh:mm')
                                        // }
                                    }
                                },
                                ],
                                yAxes: [{
                                    // distribution: 'linear',
                                    // barThickness: 'flex',
                                    barPercentage: 1,
                                    categoryPercentage: 1,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Count',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    ticks: {

                                        beginAtZero: true,
                                        callback: function (d) {
                                            if (Math.floor(d) != d) return d3.format('.01f')(d);
                                            else return d;
                                        },
                                    },
                                    id: '1',
                                    // ticks: {
                                    //     max: 8
                                    // },
                                    stacked: vm.groupType == 'stacked',
                                    type: 'linear',
                                    position: 'left',
                                }
                                ]
                            },
                            legend: {
                                display: true,
                                align: 'end',
                            },
                            tooltips: {
                                enabled: false,
                                intersect: false,
                                mode: 'point',
                                // custom: function (tooltip) {
                                callbacks: {
                                    afterBody: function (t, d) {
                                        return d.datasets[t[0].datasetIndex].data[0].toolTipData
                                    }
                                },
                                custom: function (tooltipModel, dtes) {
                                    var tooltipEl = document.getElementById('chartjs-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    function getBody(bodyItem) {
                                        return bodyItem.lines;
                                    }
                                    var bodyLines = tooltipModel.body.map(getBody);
                                    if (tooltipModel.body) {
                                        var rows = "<tr>" +
                                            "<td class='key'>" + 'Count: ' + "</td>" +
                                            "<td class='x-value'><strong>" + tooltipModel.dataPoints[0].yLabel + "</strong></td>" +
                                            "</tr>";
                                        rows += "<tr>" +
                                            "<td class='key'>" + 'Time: ' + "</td>" +
                                            "<td class='x-value'><strong>" + moment(tooltipModel.dataPoints[0].xLabel).format('MMM DD, YYYY, hh:mm A') + "</strong></td>" +
                                            "</tr>";
                                        tooltipModel.afterBody
                                            .forEach(function (toolTipData) {
                                                if (toolTipData.value) {
                                                    rows += "<tr>" +
                                                        "<td class='key'>" + toolTipData.title + ":</td>" +
                                                        "<td class='x-value'><strong>" + toolTipData.value + "</strong></td>" +
                                                        "</tr>";
                                                }
                                            });
                                        var header =
                                            "<thead>" +
                                            "<tr>" +
                                            "<td class='key'><strong>" + tooltipModel.body[0].lines[0].split(':')[0] + "</strong></td>" +
                                            "</tr>" +
                                            "</thead>";

                                        var innerHTML = "<table>" +
                                            header +
                                            "<tbody>" +
                                            rows +
                                            "</tbody>" +
                                            "</table>";

                                        var tableRoot = tooltipEl.querySelector('table');
                                        var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                        tableStyle += 'color: rgba(255,255,255, 1);';
                                        tableStyle += 'padding: 5px 10px;';

                                        tableRoot.style = tableStyle;
                                        tableRoot.innerHTML = innerHTML;
                                        vm.ttRows = "";
                                    }

                                    // `this` will be the overall tooltip
                                    var position = this._chart.canvas.getBoundingClientRect();
                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                    if (position.left + window.pageXOffset + tooltipModel.caretX + 255 > window.innerWidth) {
                                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 100 + 'px';
                                    }
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            hover: {
                                animationDuration: 0
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                        }
                    });
                } else if ($scope.chartType == "donut") {
                    var pieData = [
                        {
                            fill: true,
                            backgroundColor: chartsService.chartColors,
                            data: chartValues,
                            borderWidth: 0
                        }
                    ]

                    var options = {
                        elements: {
                           arc: {
                              borderWidth: 0
                           }
                        },
                        chartType: 'doughnut',
                        paddingBelowLegend: true,
                        layout: {
                            padding: {
                                bottom: 30
                            }
                        },
                        title: {
                            display: true,
                            position: 'top'
                        },
                        legend: {
                            display: true
                        },
                        tooltips: {
                            enabled: true,
                            mode: 'single',
                            // callbacks: {
                            //     label: function (tooltipItems, data) {
                            //         var i = tooltipItems.index;
                            //         return data.labels[i] + " State: " + data.datasets[0].data[i] + " signals";
                            //     }
                            // } 
                        },
                        rotation: -0.7 * Math.PI,
                        plugins: {
                            beforeInit: function (chart, options) {
                                chart.legend.afterFit = function () {
                                    this.height = this.height + 300;
                                };
                            },
                            datalabels: {
                                align: 'end',
                                anchor: "end",
                                offset: 8,
                                display: function (context) {
                                    let sum = 0;
                                    let dataArr = context.chart.data.datasets[0].data;
                                    dataArr.map(data => {
                                        sum += data;
                                    });
                                    let percentage = (context.dataset.data[context.dataIndex] * 100 / sum).toFixed(2);
                                    return percentage >= 5; // or >= 1 or ...
                                },
                                formatter: (value, ctx) => {

                                    let sum = 0;
                                    let dataArr = ctx.chart.data.datasets[0].data;
                                    dataArr.map(data => {
                                        sum += data;
                                    });
                                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                                    return percentage;


                                },
                                color: '#000',
                            },
                        }
                    };

                    charts.push({
                        title: "Detector Counts Chart for " + $scope.signalName,
                        subLine1: "Actuations: " + acctuations,
                        // dataset: chartData,
                        data: pieData,
                        api: {},
                        isDataAvailable: chartData.length > 0,
                        noDetector: noDetector,
                        backgroundColor: borderScheme.splice(counter),
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(this.labels, this.data, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        isRendered: false,
                        chartOptions: options,
                        labels: chartLabels,

                    }
                    );
                }
                vm.chartWidget.chartArray = charts;
            },
        }

        function getData() {
            //gets data from the server
            vm.chartWidget.chartArray = [];
            $scope.loading = true;
            if ($scope.chartType == "donut") {
                vm.chartTitle = "Detector Counts Chart for " + $scope.signalName;
            }
            else if ($scope.chartType == "bar") {
                vm.chartTitle = "Detector Counts Chart for " + $scope.signalName;
            }

            vm.getTimeFromTimeFrame();

            // vm.chartWidget.processEventData($scope.newChartData.signalDataStatistics);
            // $scope.loading = false;
            // pass the object to the server
            var sendEventsObject = controllerEventService.createDataStatisticsQuery($scope.searchDates, $scope.signals, $scope.eventsWithParameters, $scope.binSize);
            controllerEventService.getDataStatistics(sendEventsObject)
                .then(function (data) {
                    if (vm && vm.chartWidget) {
                        vm.chartServerError = false;
                        //JSON stringify the server data and then process it for the chart
                        // var rawData = JSON.stringify(data, null, 4);
                        vm.chartWidget.processEventData(data.signalDataStatistics);
                    }
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
        vm.chartWidget.setChartObject();
        if ($scope.fetchData) {
            vm.getData();
        }
    }
}());
