import * as SIGNALPERF from 'raw-loader!./dashboard-signal-perf.html'

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.signal-performance',
           []
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.signal-performance', {
                url: '/signal-performance',
                views: {
                    'content@app': {
                        template: SIGNALPERF,
                        controller: 'DashboardSignalPerformanceController as vm'
                    }
                }
            });
        $translatePartialLoaderProvider.addPart('signal-performance');
    }
})();