﻿import * as WIDGETCONTEXTMENU from 'raw-loader!./basic-widget-context-menu.html';

(function () {
    'use strict';

    angular.module('app.spm.core')
    .directive('basicWidgetContextMenu', basicWidgetContextMenu);

    /** @ngInject */
    function basicWidgetContextMenu($mdMenu) {
        function link(scope) {
        }

        return {
            restrict: 'AE',
            scope: {
                signalID: '=',
                flipWidget: '&',
                refreshWidget: '&',
                showRefresh: '<',
                showSlack: '<'
            },
            link: link,
            controller: function ($scope) {
            },
            template: WIDGETCONTEXTMENU,
        };
    }
})();