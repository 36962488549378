﻿(function () {

    'use strict';

    angular
        .module('app.core')
        .service('authService', authService);

    function authService($state, $rootScope, $location, jwtHelper, $timeout, angularAuth0) {


        function logout() {
            sessionStorage.clear();
            localStorage.clear();
            $state.go('login');
            $rootScope.$emit('logoutEvent', {});
        }


        function handleAuthentication() {
            angularAuth0.parseHash({ hash: window.location.hash }, function (err, authResult) {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    setSession(authResult);
                    $state.go('app.spm.dashboards.map-overview');

                    // segment.identify(sessionStorage.userID, {
                    //     user_email: sessionStorage.userEmail,
                    //     user_name: sessionStorage.userName,
                    // })
                } else if (err) {
                    $timeout(function () {
                        $state.go('login');
                    });
                }
            });
        }

        function getToken() {
            return sessionStorage.getItem('id_token');
        }

        function setSession(authResult) {
            // Set the time that the Access Token will expire at
            var expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
            sessionStorage.setItem('access_token', authResult.accessToken);
            sessionStorage.setItem('id_token', authResult.idToken);
            sessionStorage.setItem('userNickName', authResult.idTokenPayload.nickname);
            sessionStorage.setItem('userName', authResult.idTokenPayload.name);
            sessionStorage.setItem('userEmail', authResult.idTokenPayload.email);
            sessionStorage.setItem('userID', authResult.idTokenPayload.sub);
            // sentryService.setUser();


            if (authResult.idTokenPayload.user_metadata)
                sessionStorage.setItem('user_metadata', JSON.stringify(authResult.idTokenPayload.user_metadata));
            if (authResult.idTokenPayload.app_metadata)
                sessionStorage.setItem('app_metadata', JSON.stringify(authResult.idTokenPayload.app_metadata));

        }

        return {
            // ...
            handleAuthentication: handleAuthentication,
            logout: logout,
            getToken: getToken,
        }
    }
})();