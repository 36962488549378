(function () {
    "use strict";

    angular
        .module("app.spm.dashboards.monthly-intersection-report")
        .service("monthlyIntersectionReportService", monthlyIntersectionReportService);

    function monthlyIntersectionReportService(monthlyReportResource, $state, $rootScope, $mdToast) {


        function getMonhtlyIntersectionData(opts) {
            return monthlyReportResource.getMonhtlyIntersectionData(opts,
                function (data) {
                    return data;
                }).$promise;
        }

        function createOptionsObject(signalId, aggType, startDate, endDate, startHour, startMinute, endHour, endMinute,
            daysOfWeekArray, timeOption, binSize,  baselineType, baselineStartDate, baselineEndDate, includeHourly) {
            var opts = {
                signalId: signalId,
                aggregationTypes: aggType,
                startDateTime: startDate,
                endDateTime: endDate,
                timeOfDayStartHour: startHour,
                timeOfDayStartMinute: startMinute,
                timeOfDayEndHour: endHour,
                timeOfDayEndMinute: endMinute,
                daysOfWeek: daysOfWeekArray,
                timeOption: timeOption,
                selectedBinSize: binSize,
                baselineType: baselineType,
                baselineStartDate: baselineStartDate,
                baselineEndDate: baselineEndDate,
                includeHourlyData: includeHourly
            }
            return opts;
        }

        function triggerReport(event){
            notify(event)
        }

        function reportOutput(success){
            if(success == true) notify("pdfReportGenerated");
            if(success == false) notify("pdfReportError");
        }

        function notifyChange(optionName, optionValue) {
            $rootScope.$emit('pdf-report-change-event', {name: optionName, value: optionValue});
        }

        function notify(typeOfChange) {
            if (!sessionStorage.getItem('__change_notifications_paused')) {
                $rootScope.$emit('pdf-report-change-event', typeOfChange);
            }
        }


        return {
            getMonhtlyIntersectionData: getMonhtlyIntersectionData,
            createOptionsObject: createOptionsObject,
            notifyChange: notifyChange,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('pdf-report-change-event', callback);
                scope.$on('$destroy', handler);
            },
            triggerReport: triggerReport,
            reportOutput: reportOutput    
        }
    }
})();
