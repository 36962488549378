import * as INSMAP from 'raw-loader!./insights-map.html'

(function () {
    'use strict';

    angular.module('app.spm.core')
        .directive('insightMap', insightMapDirective);


    function insightMapDirective() {
        return {
            restrict: 'AE',
            bindToController: {
                api: '=',
            },
            scope: {
                param: '=',
            },
            template: INSMAP,
            controller: 'insightsMapController as vm'
        }
    }
})();
