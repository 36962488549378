(function () {
    angular
        .module('app.searchbar')
        .controller('optionsController', optionsController);

    function optionsController($rootScope, $stateParams, $scope, searchBarService, on_close, chartsService) {
        var vm = this;
        vm.options = {};
        vm.apply = apply;
        vm.close = close;
        vm.binChange = binChange;
        vm.chartTypeChange = chartTypeChange;
        vm.metricTypeId = $stateParams.metricTypeId
        vm.init = init;
        vm.searchBarService = searchBarService;
        vm.chartsService = chartsService;
        vm.selectedBin = "";
        vm.selectedChartType = vm.chartsService.getChartComplexity();
        vm.determineOptionsPanelClass = determineOptionsPanelClass;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        function init() {
            vm.configuration = vm.searchBarService.getSearchBarConfig();
            vm.options = vm.searchBarService.getSearchOptions();
            vm.selectedBin = vm.options.timeOptions.bin;
        }

        function determineOptionsPanelClass() {
            var panelClass = "";
            if (vm.configuration.showExcel && vm.configuration.showHelp) {
                panelClass = "searchbar-options-panel-arrow-two-buttons";
            }
            else if (vm.configuration.showExcel || vm.configuration.showHelp) {
                panelClass = "searchbar-options-panel-arrow-one-button";
            }
            return panelClass;
        }

        function close() {
            if (on_close)
                on_close()
        }

        function binChange(bin) {
            vm.selectedBin = bin;
        }

        function chartTypeChange(option) {
            vm.selectedChartType = option;
        }

        function apply() {
            if (vm.configuration.moreOptionsMenu.showBinConfig) {
                //set the bin options
                vm.options = vm.searchBarService.getSearchOptions();
                vm.options.timeOptions.bin = vm.selectedBin;
                //call update
                if (vm.configuration.moreOptionsMenu.notifyOnBinChange){
                    if(vm.selectedBin=="Day"){
                        // if binsize is day then we should select all day from 00 to 23
                        let startHour = 0;
                        let startMinute = 0;
                        let endHour = 23;
                        let endMinute = 59;
                        let start =  new Date(vm.options.timeOptions.currentFilter.startDateAndTime.getFullYear(), vm.options.timeOptions.currentFilter.startDateAndTime.getMonth(), vm.options.timeOptions.currentFilter.startDateAndTime.getDate(),startHour,startMinute);
                        let end =  new Date(vm.options.timeOptions.currentFilter.endDateAndTime.getFullYear(), vm.options.timeOptions.currentFilter.endDateAndTime.getMonth(), vm.options.timeOptions.currentFilter.endDateAndTime.getDate(),endHour,endMinute);
                        vm.options.timeOptions.currentFilter.startDateAndTime = start;
                        vm.options.timeOptions.currentFilter.endDateAndTime = end;
                    }
                    vm.searchBarService.setTimeOptionsOnBinChange(vm.options.timeOptions);
                }
                else{
                    vm.searchBarService.setTimeOptionsNoNotify(vm.options.timeOptions);
                }
            }
            if (vm.configuration.moreOptionsMenu.showAdvancedChart) {
                vm.chartsService.setChartComplexity(vm.selectedChartType);
            }
            if (vm.configuration.moreOptionsMenu.customHtmlPath) {
                //notify all child options controllers that options have been saved
                $rootScope.$emit('applyOptions');
            }

            vm.close();
        }
        vm.init();
    }
}());
