(function () {
    'use strict';

    angular
        .module('app.spm.dashboards.insights')
        .controller('insightQueryDetailsController', insightQueryDetailsController);

    /** @ngInject */
    function insightQueryDetailsController($rootScope, $scope, signalQuery, insightDefinitions, filterInsightType, filterAlgorithm, runQuery, detectorIssueTypes, pedPhaseIssueTypes, deleteInsightQueryById, signalIssueTypes, $mdDialog) {
        var vm = this;
        vm.signalQuery = signalQuery;
        vm.signal = vm.signalQuery.signal;
        vm.queryResults = vm.signalQuery.queryResultsFiltered;
        vm.insightDefinitions = insightDefinitions;
        vm.getTextForInsightTypeID = getTextForInsightTypeID;
        vm.setData = setData;
        vm.successPercent = 0;
        vm.runQueryLocal = runQueryLocal;
        vm.deleteQuery = deleteQuery;
        vm.reorder = reorder;
        vm.filterInsightType = parseInt(filterInsightType);
        vm.deleteInsightQueryById= deleteInsightQueryById;
        vm.filterAlgorithm = filterAlgorithm;
        vm.runQuery = runQuery;
        vm.pageData = {
            pageIndex: 1,
            size: 10,
            orderBy: '-issueDate'
        };
        vm.successCount = 0, vm.failedCount = 0;
        vm.detectorIssueTypes = detectorIssueTypes;
        vm.pedPhaseIssueTypes = pedPhaseIssueTypes;
        vm.signalIssueTypes = signalIssueTypes;

        vm.setData();
        vm.reorder();
        
        vm.cancel = function () {
            $mdDialog.cancel();
        };

        function getTextForInsightTypeID(typeID, appendNo){
            var res = "";
            if (typeID == -1){
                return "Any Insights"; 
            }
            if (typeID == 0){
                return "No Insight Found"; 
            }
            vm.insightDefinitions.insightCategories.forEach(function (def) {
                var insightType = def.insightTypes.find(x => x.insightTypeID == typeID);
                if (insightType && insightType.name){
                    res = insightType.name;
                    if (appendNo) res = "No " + res;
                    return;
                }
               
            })
            return res;
        }

        function setData(){
            vm.successPercent = ((vm.queryResults.filter(x => x.passOrFail == "PASS").length / vm.queryResults.length)*100).toFixed(2);
            vm.successCount = vm.queryResults.filter(x=>x.passOrFail == 'PASS').length;
            vm.failedCount = vm.queryResults.filter(x=>x.passOrFail == 'FAIL').length
        }

        function reorder(){
            var orderStr = vm.pageData.orderBy;
            var desc = false;
            if (vm.pageData.orderBy.charAt(0) == '-'){
                desc = true;
                orderStr = orderStr.substring(1, orderStr.length);
            }

            if (desc)
                vm.queryResults.sort((a, b) => (a[orderStr] < b[orderStr]) ? 1 : -1)
            else
                vm.queryResults.sort((a, b) => (a[orderStr] > b[orderStr]) ? 1 : -1)
        }

        function runQueryLocal(queryResult){
            var newObj = {
                query:{
                    signalID: vm.signal.signalID,
                    issueTypeID: queryResult.issueTypeId,
                    expectedPositiveResult: queryResult.expectedPositiveResult == 1 ? true : false,
                    phaseNumber: queryResult.phaseNumber,
                    detectorChannel: queryResult.detectorChannel,
                    issueDate: queryResult.issueDate
                },
                signal: vm.signal,
            }
            if (vm.runQuery)
                vm.runQuery(newObj);
        }

        function deleteQuery(resultQuery){
            if (vm.deleteInsightQueryById)
                vm.deleteInsightQueryById(vm.signalQuery, resultQuery.resultUniqueId).then(function(){
                    var removeIdx = vm.queryResults.findIndex(x => x.resultUniqueId == resultQuery.resultUniqueId);
                    if (removeIdx > -1) {
                        vm.queryResults.splice(removeIdx, 1);
                    }
                    vm.setData();
                });
        }
    }
})();
