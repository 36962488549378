(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("yellowRedOptionsController", yellowRedOptionsController);

    function yellowRedOptionsController($rootScope, $scope, chartsService, searchBarService) {
        var vm = this;
        vm.chartsService = chartsService;
        vm.yellowRedOptions = vm.chartsService.getYellowRedOptions();
        
        vm.currentSpeedFilter = vm.yellowRedOptions.speedFilter;
        vm.currentSevereLevelSeconds = vm.yellowRedOptions.severeLevelSeconds;
        vm.currentForceDetectionTypeId = vm.yellowRedOptions.forceDetectionTypeId;

        vm.changeSevereLevel = changeSevereLevel;
        vm.changeDetectionType = changeDetectionType;
        vm.searchBarService = searchBarService;
        vm.changeSpeedFilter = changeSpeedFilter;

        vm.detectorsAvailable = false;
        vm.stopBarDet = 0;
        vm.laneByLaneDet = 0;
        
        vm.searchObj = vm.searchBarService.getSearchOptions();
        var allApproaches = vm.searchObj.signal.approaches;
        var allApproachesLength = vm.searchObj.signal.approaches.length;

        for (let i = 0; i < allApproachesLength; i++){
            vm.stopBarDet += allApproaches[i].detectors.filter(det => det.detectionTypeID == 6).length;
            vm.laneByLaneDet += allApproaches[i].detectors.filter(det => det.detectionTypeID == 4).length;
        }

        if(vm.stopBarDet > 0 && vm.laneByLaneDet > 0){
            vm.detectorsAvailable = true;
        }

        vm.severeLevels = [
            {
                value: 3,
                text: "3 sec"
            },
            {
                value: 4,
                text: "4 sec"
            },
            {
                value: 5,
                text: "5 sec"
            },
            {
                value: 6,
                text: "6 sec"
            }
        ];

        vm.forceDetectionTypeIds = [
            {
                value: -1,
                text: "Best Available"
            },
            {
                value: 6,
                text: "Stop Bar Only"
            },
            {
                value: 4,
                text: "Lane by Lane Count Only"
            }
        ];

        vm.speedFilters = [
            {
                value: 0,
                text: "None"
            },
            {
                value: 5,
                text: "5 MPH"
            },
            {
                value: 10,
                text: "10 MPH"
            },
            {
                value: 15,
                text: "15 MPH"
            }
        ];

        function changeSpeedFilter(val){
            vm.currentSpeedFilter = val;
        }

        function changeSevereLevel(val){
            vm.currentSevereLevelSeconds = val;
        }

        function changeDetectionType(val){
            vm.currentForceDetectionTypeId = val;
        }
        
        vm.searchBarService.subscribeToOptionsApply($scope, function(){
            vm.yellowRedOptions.speedFilter = vm.currentSpeedFilter;
            vm.yellowRedOptions.severeLevelSeconds = vm.currentSevereLevelSeconds;
            vm.yellowRedOptions.forceDetectionTypeId = vm.currentForceDetectionTypeId;
            //user hit apply on options
            vm.chartsService.setYellowRedOptions(vm.yellowRedOptions);
        });
    }
}());
