﻿(function () {
    'use strict';

    angular
        .module('app.spm.corridors')
        .controller('corridorAddController', corridorAddController);

    /** @ngInject */
    function corridorAddController($interval, signalService, $element, $rootScope, $scope, $state, corridorService, $mdDialog, directionTypes, searchBarService, $stateParams, corridorID, helpService) {
        var vm = this;
        vm.signal = {};
        vm.close = close;
        vm.saveCorridor = saveCorridor;
        vm.directionTypes = directionTypes;
        vm.corridorID = corridorID;
        vm.isUpDownStream = false;
        vm.checkUpDownStream = checkUpDownStream;
        vm.upDownMessage = "";
        vm.isCorridorReady = false;
        vm.isOneWay = false;
        vm.timeOptions = {};
        vm.corridors = [];
        vm.corridor = undefined;
        vm.searchBarService = searchBarService;
        vm.setCorridorOptions = setCorridorOptions;
        vm.signalAddedToCorridor = signalAddedToCorridor;
        vm.signalRemovedFromCorridor = signalRemovedFromCorridor;
        vm.signalsInCorridor = [];
        vm.saveDownStreamCorridorDetails = saveDownStreamCorridorDetails;
        vm.saveUpStreamCorridorDetails = saveUpStreamCorridorDetails;
        vm.getCorridor = getCorridor;
        vm.signals = [];
        vm.controlApproachDireciton = controlApproachDireciton;
        vm.mapApi2ndStep = {};
        const mapOfSignals = new Map();
        vm.opositeApproachPanel = opositeApproachPanel;
        vm.setupDataTable = setupDataTable;
        vm.setupDataTable();
        vm.editedSignalApproach = false;
        vm.isSignalAdded = isSignalAdded;
        vm.errorSavingPanel = errorSavingPanel;
        vm.showHelpReviewAlert = showHelpReviewAlert;
        vm.cancelClose = "CANCEL";
        vm.tabIndex = 0;
        vm.majorMinorDir = "";
        vm.noApproacehs = noApproacehs;
        vm.signalInterval = undefined;
        vm.disableButton = disableButton;

        vm.showHelpAlert = showHelpAlert;
        vm.i18TopLocation = "CORRIDOR_DASHBOARD.";
        vm.phaseName = "FIRST_PHASE";

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function showHelpAlert(ev, card, corridorID) {
            if (corridorID == null) card += "_ADD";
            else card += "_EDIT";
            helpService.showHelpDialog("CORRIDOR_DASHBOARD." + card.toString(), ev, "How To Use");
        };

        vm.isCorridorLoaded = false;

        if (vm.corridorID && vm.corridorID != null) {
            vm.getCorridor(vm.corridorID);
            vm.cancelClose = "CLOSE";
        }

        function opositeApproachPanel(approachDirection, index, stream) {
            var directiontext = (stream == 'd') ? vm.downstreamRoute.direction : vm.upstreamRoute.direction;
            var confirmText = '';
            var confirmButton = 'Yes';
            if (approachDirection.direction) {
                confirmText = "Are you sure you want to change approach to " +
                    approachDirection.direction +
                    ' since the route direction is ' +
                    directiontext + '?';
                confirmButton = "Yes add this approach";

            }
            else {
                confirmText = "Are you sure you want to remove this approach ?";
                confirmButton = "Remove";
            }

            var confirm = $mdDialog.confirm()
                .parent(angular.element(document.querySelector('#corridor-add-dialog')))
                .clickOutsideToClose(true)
                .title(confirmText)
                .multiple(true)
                .textContent('This action cannot be undone')
                .ok(confirmButton)
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                if (stream == 'd') vm.downstreamRoute.approaches[index] = approachDirection;
                if (stream == 'u') vm.upstreamRoute.approaches[index] = approachDirection;
                vm.editedSignalApproach = true;
            }, function () {
                if (stream == 'd') vm.downstreamRoute.approaches[index] = vm.downstreamRoute.signals[index].approach;
                if (stream == 'u') vm.upstreamRoute.approaches[index] = vm.upstreamRoute.signals[index].approach;
            });
        }

        function controlApproachDireciton(currentApproach, index, stream) {
            if (stream == 'd') {
                if (currentApproach.direction != vm.downstreamRoute.direction) {
                    vm.opositeApproachPanel(currentApproach, index, stream);
                }
            }
            if (stream == 'u') {
                if (currentApproach.direction != vm.upstreamRoute.direction) {
                    vm.opositeApproachPanel(currentApproach, index, stream);
                }
            }
            vm.editedSignalApproach = true;
        }

        vm.upstreamRoute = {
            stream: 'u',
            approachRouteId: 0,
            signals: [],
            direction: '',
            approaches: [],
            directionDetails: {},
            mapApi: {},
            refreshDirection: function () {
                if (this.mapApi && this.mapApi.updateSignalsOnMap) {
                    this.mapApi.updateSignalsOnMap(vm.upstreamRoute.signals);
                }
            }
        };

        vm.downstreamRoute = {
            stream: 'd',
            approachRouteId: 0,
            signals: [],
            direction: '',
            approaches: [],
            directionDetails: {},
            mapApi: {},
            refreshDirection: function () {
                if (this.mapApi && this.mapApi.updateSignalsOnMap) {
                    this.mapApi.updateSignalsOnMap(vm.downstreamRoute.signals);
                }
            }
        };

        vm.emptyApproach = {
            approachID: null,
            signalID: null,
            signalName: null,
            directionTypeID: null,
            direction: null,
            directionType: {},
            description: "NONE",
            mph: null,
            protectedPhaseNumber: null,
            permissivePhaseNumber: null,
            isProtectedPhaseOverlap: null,
            detectors: []
        }

        vm.corridorToAdd = {
            corridorID: 0,
            name: null,
            upstreamDirectionID: 0,
            downstreamDirectionID: 0,
            wazeID: null
        };



        var index = 0;
        //stepper objects
        vm.selectedStep = 0;
        vm.stepProgress = 1;
        vm.maxStep = 3;
        vm.stepData = [
            { step: 1, completed: false, optional: false },
            { step: 2, completed: false, optional: false },
            { step: 3, completed: false, optional: false },
        ];


        function signalAddedToCorridor(signal, approach) {
            signal.approach = approach;
            vm.downstreamRoute.signals.push(signal);
            vm.downstreamRoute.approaches.push(approach);
            // find oposite approach to store in upstreamRoute
            if (!vm.isOneWay) {
                var opositeApproach = getOpositeApproach(signal, approach);
                signal.approach = opositeApproach; // it seems that he pushs this approach also to the downstreamRoute ? how Comes? discuss with Niko? 
                vm.upstreamRoute.approaches.unshift(opositeApproach);
                vm.upstreamRoute.signals.unshift(signal);
            }
        }

        function isSignalAdded(isSignalAdded) {
            vm.editedSignalApproach = isSignalAdded;
        }

        function signalRemovedFromCorridor(signals) {
            vm.downstreamRoute.signals.forEach(function (elem, i) {
                signals.forEach(function (sig) {
                    if (elem.signalID == sig.signalID) vm.downstreamRoute.signals.splice(i, 1);
                });
            });

            vm.downstreamRoute.approaches.forEach(function (elem, i) {
                signals.forEach(function (sig) {
                    if (elem.signalID == sig.signalID) vm.downstreamRoute.approaches.splice(i, 1);
                });
            });

            if (!vm.isOneWay) {
                vm.upstreamRoute.signals.forEach(function (elem, i) {
                    signals.forEach(function (sig) {
                        if (elem.signalID == sig.signalID) vm.upstreamRoute.signals.splice(i, 1);
                    });
                });
                vm.upstreamRoute.approaches.forEach(function (elem, i) {
                    signals.forEach(function (sig) {
                        if (elem.signalID == sig.signalID) vm.upstreamRoute.approaches.splice(i, 1);
                    });
                });
            }
        }


        function setCorridorOptions() {
            if (vm.downstreamRoute.approaches.length < 2) {
                noApproacehs();
                vm.moveToPreviousStep();
            }
            vm.phaseName = "THIRD_PHASE";
            vm.downstreamRoute.refreshDirection();
            vm.upstreamRoute.refreshDirection();
            addingNoApproachDescription();
            vm.routes = [];
            if (!vm.isOneWay) {
                // adding no approach description
                vm.routes.push(vm.downstreamRoute, vm.upstreamRoute);
            }
            else {
                vm.routes.push(vm.downstreamRoute);
            }
            vm.tabIndex = 0;
            vm.majorMinorDir = vm.downstreamRoute.direction;
        }

        function addingNoApproachDescription() {
            vm.downstreamRoute.signals.forEach(function (signal, i) {
                if (signal.approaches[signal.approaches.length - 1].description != "NONE") {
                    signal.approaches[signal.approaches.length] = vm.emptyApproach;
                }
            });
            if (!vm.isOneWay) {
                vm.upstreamRoute.signals.forEach(function (signal, i) {
                    if (signal.approaches[signal.approaches.length - 1].description != "NONE") {
                        signal.approaches[signal.approaches.length] = vm.emptyApproach;
                    }
                });
            }
        }


        $scope.$on("$destroy", function () {
            if (vm.signalInterval)
                $interval.cancel(vm.signalInterval);
            if (vm.apiLoadInterval)
                $interval.cancel(vm.apiLoadInterval);
            for (let member in vm) {
                vm[member] = null;
            }
        });
        vm.enableNextStep = function nextStep() {
            //do not exceed into max step
            if (vm.selectedStep >= vm.maxStep) {
                return;
            }
            //do not increment vm.stepProgress when submitting from previously completed step
            if (vm.selectedStep === vm.stepProgress - 1) {
                vm.stepProgress = vm.stepProgress + 1;
            }
            vm.selectedStep = vm.selectedStep + 1;
            vm.tabIndex = 0;
        }

        vm.moveToPreviousStep = function () {
            if (vm.selectedStep > 0) {
                vm.selectedStep = vm.selectedStep - 1;
            }
            if (vm.selectedStep == 0)
                vm.phaseName = 'FIRST_PHASE';
            if (vm.selectedStep == 1)
                vm.phaseName = 'SECOND_PHASE';
            if (vm.selectedStep == 2)
                vm.phaseName = 'THIRD_PHASE';

            vm.tabIndex = 0;
            vm.majorMinorDir = vm.downstreamRoute.direction;
        }

        vm.submitCurrentStep = function (stepData) {
            stepData.completed = true;
            vm.enableNextStep();
        }

        vm.tabIndexDir = function () {
            vm.tabIndex = 1;
            vm.majorMinorDir = vm.upstreamRoute.direction;
        }

        vm.tabChange = function () {
            if (vm.tabIndex == 0) vm.majorMinorDir = vm.downstreamRoute.direction;
            if (vm.tabIndex == 1) vm.majorMinorDir = vm.upstreamRoute.direction;
        }

        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
            },
            //search bar options
            showSearchBar: false,
        });

        function close() {
            $mdDialog.cancel(false);
        };

        $scope.ok = function () {
            $mdDialog.hide({ message: 'Success' });
        };
        function setupDataTable() {
            vm.selected = [];
            vm.totalCount = {};
            vm.options = {
                rowSelection: true,
                multiSelect: false,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: true,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };

            vm.queryData = {
                orderBy: 'index',
                pageSize: 5,
                pageIndex: 1,
                filter: ''
            };
        }
        function getOpositeApproach(signal, approach) {
            var opositeDirection = undefined;
            var opositeApproach = undefined;
            switch (approach.direction) {
                case "Northbound":
                    opositeDirection = "Southbound";
                    break;
                case "Southbound":
                    opositeDirection = "Northbound";
                    break;
                case "Eastbound":
                    opositeDirection = "Westbound";
                    break;
                case "Westbound":
                    opositeDirection = "Eastbound";
                    break;
                case "Southwestbound":
                    opositeDirection = "Northeastbound";
                    break;
                case "Northeastbound":
                    opositeDirection = "Southwestbound";
                    break;
                case "Northwestbound":
                    opositeDirection = "Southeastbound";
                    break;
                case "Southeastbound":
                    opositeDirection = "Northwestbound";
                    break;
            }
            approach = undefined;
            opositeApproach = signal.approaches.find(elem => elem.direction == opositeDirection && elem.description.includes('T') && !elem.description.toLowerCase().includes('bike') && !elem.description.toLowerCase().includes('turn'));
            if(opositeApproach == undefined) opositeApproach = signal.approaches.find(elem => elem.direction == opositeDirection && !elem.description.toLowerCase().includes('bike') && !elem.description.toLowerCase().includes('turn'));
            if(opositeApproach == undefined) opositeApproach = signal.approaches.find(elem => elem.direction != opositeDirection && checkApproachDirection(elem,opositeDirection));
            if(opositeApproach == undefined) opositeApproach = signal.approaches.find(elem => elem.direction == opositeDirection && approach == undefined);
            if(opositeApproach == undefined) opositeApproach = signal.approaches.find(elem => approach == undefined);
            
            return opositeApproach;
        }

        function checkApproachDirection(approach,md){

            var majorDir = md.toLowerCase();
      
            if(majorDir.includes('bound')){
              majorDir = majorDir.replace('bound','');        
            }
      
            if(approach.direction && approach.direction.toLowerCase().includes(majorDir) &&  approach.description.includes('T') && !approach.description.toLowerCase().includes('bike') && !approach.description.toLowerCase().includes('turn') ){
              return true;
            }
            else{
              return false;
            }
      
        }

        function checkUpDownStream(downstreamID) {
            vm.phaseName = "SECOND_PHASE";
            switch (downstreamID) {
                case 1:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 2;
                        vm.upstreamRoute.direction = "Southbound";
                    }
                    vm.downstreamRoute.direction = "Northbound";
                    break;
                case 2:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 1;
                        vm.upstreamRoute.direction = "Northbound";
                    }
                    vm.downstreamRoute.direction = "Southbound";
                    break;
                case 3:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 4;
                        vm.upstreamRoute.direction = "Westbound";
                    }
                    vm.downstreamRoute.direction = "Eastbound";
                    break;
                case 4:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 3;
                        vm.upstreamRoute.direction = "Eastbound";
                    }
                    vm.downstreamRoute.direction = "Westbound";
                    break;
                case 5:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 8;
                        vm.upstreamRoute.direction = "Southwestbound";
                    }
                    vm.downstreamRoute.direction = "Northeastbound";
                    break;
                case 8:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 5;
                        vm.upstreamRoute.direction = "Northeastbound";
                    }
                    vm.downstreamRoute.direction = "Southwestbound";
                    break;
                case 6:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 7;
                        vm.upstreamRoute.direction = "Southeastbound";
                    }
                    vm.downstreamRoute.direction = "Northwestbound";
                    break;
                case 7:
                    if (!vm.isOneWay) {
                        vm.corridorToAdd.upstreamDirectionID = 6;
                        vm.upstreamRoute.direction = "Northwestbound";
                    }
                    vm.downstreamRoute.direction = "Southeastbound";
                    break;

            }
            if (!vm.corridorID) {
                vm.mapApi2ndStep.majorDirection(vm.downstreamRoute.direction);
            }
        }
        function getCorridor(id) {
            // First lets get all signals with approaches 
            signalService.getAllSignalsWithApproaches()
                .then(function (data) {
                    data.signals.forEach(function (signal, i) {
                        mapOfSignals.set(signal.signalID, signal.approaches);

                    });

                    corridorService.getCorridorById(id)
                        .then(function (corridor) {

                            if (!corridor.upstreamRoute) vm.isOneWay = true;
                            else vm.isOneWay = false;
                            vm.majorMinorDir = corridor.downstreamRoute.direction

                            vm.corridorToAdd.corridorID = corridor.corridorID;
                            vm.corridorToAdd.name = corridor.name;
                            vm.corridorToAdd.wazeID = corridor.wazeID;
                            vm.corridorToAdd.downstreamDirectionID = corridor.downstreamRoute.directionTypeID;
                            vm.downstreamRoute.direction = corridor.downstreamRoute.direction;
                            vm.downstreamRoute.approachRouteId = corridor.downstreamRoute.approachRouteId;

                            corridor.downstreamRoute.approachRouteDetails.forEach(function (elem, k) {
                                vm.downstreamRoute.signals[k] = elem.signal;
                                vm.downstreamRoute.signals[k].approaches = mapOfSignals.get(elem.signal.signalID);
                                vm.downstreamRoute.signals[k].approachOrder = elem.approachOrder;
                                vm.downstreamRoute.approaches[k] = elem.approach;
                                vm.downstreamRoute.signals[k].approach = elem.approach;
                                vm.downstreamRoute.signals[k].description = getSignalDescription(elem.signal.customID, elem.signal.primaryName, elem.signal.secondaryName);
                            });

                            if (!vm.isOneWay) {
                                vm.corridorToAdd.upstreamDirectionID = corridor.upstreamRoute.directionTypeID;
                                vm.upstreamRoute.approachRouteId = corridor.upstreamRoute.approachRouteId;

                                vm.upstreamRoute.direction = corridor.upstreamRoute.direction;

                                if(corridor.upstreamRoute.approachRouteDetails && corridor.upstreamRoute.approachRouteDetails.length>0){
                                    corridor.upstreamRoute.approachRouteDetails.forEach(function (elem, k) {
                                        vm.upstreamRoute.signals[k] = elem.signal;
                                        vm.upstreamRoute.signals[k].approaches = mapOfSignals.get(elem.signal.signalID);
                                        vm.upstreamRoute.signals[k].approachOrder = elem.approachOrder;
                                        vm.upstreamRoute.approaches[k] = elem.approach;
                                        vm.upstreamRoute.signals[k].approach = elem.approach;
                                        vm.upstreamRoute.signals[k].description = getSignalDescription(elem.signal.customID, elem.signal.primaryName, elem.signal.secondaryName);
                                    });
                                }
                                else {
                                    let k=0;
                                    for(var i=vm.downstreamRoute.signals.length-1;i>=0;i--){
                                        let signal = vm.downstreamRoute.signals[i];
                                        let approaches = vm.downstreamRoute.approaches[i];
                                        vm.upstreamRoute.signals[k] = signal;
                                        vm.upstreamRoute.signals[k].approaches = signal.approaches;
                                        vm.upstreamRoute.approaches[k] = getOpositeApproach(signal, signal.approach);
                                        vm.upstreamRoute.signals[k].approach = getOpositeApproach(signal, signal.approach);
                                        vm.upstreamRoute.signals[k].description = signal.description;
                                        k++;
                                    }
                                    vm.editedSignalApproach = true;

                                }
                            }

                            if (!vm.mapApi2ndStep || !vm.mapApi2ndStep.highlightSelectedSignals || !vm.mapApi2ndStep.finishedLoadingSignals()) {
                                vm.signalInterval = $interval(function () {
                                    if (vm.mapApi2ndStep && vm.mapApi2ndStep.highlightSelectedSignals && vm.mapApi2ndStep.finishedLoadingSignals()) {
                                        $interval.cancel(vm.signalInterval);
                                        vm.mapApi2ndStep.majorDirection(vm.downstreamRoute.direction);
                                        vm.mapApi2ndStep.highlightSelectedSignals(vm.downstreamRoute.signals);
                                        vm.editedSignalApproach = false;

                                    }
                                }, 500)
                            }
                            else {
                                if (vm.mapApi2ndStep && vm.mapApi2ndStep.highlightSelectedSignals) {
                                    vm.mapApi2ndStep.highlightSelectedSignals(vm.downstreamRoute.signals);
                                    vm.editedSignalApproach = false;

                                }
                            }

                            if (vm.mapApi2ndStep && vm.mapApi2ndStep.majorDirection) {
                                vm.mapApi2ndStep.majorDirection(vm.downstreamRoute.direction);
                            } else {
                                vm.apiLoadInterval = $interval(function () {
                                    var isLoadedApi = vm.mapApi2ndStep && vm.mapApi2ndStep !== undefined;

                                    if (isLoadedApi) {
                                        $interval.cancel(vm.apiLoadInterval);
                                        vm.mapApi2ndStep.majorDirection(vm.downstreamRoute.direction);
                                    }
                                }, 500);
                            }

                            vm.isCorridorLoaded = true;
                        });
                });
        }

        function disableButton() {

            vm.editedSignalApproach = true;
            if (vm.downstreamRoute.approaches.length < 2) {
                noApproacehs();
            }
        }

        function saveCorridor() {
            vm.editedSignalApproach = true;
            if (vm.downstreamRoute.approaches.length > 1) {
                // corridorService.createUpsertCorridor(vm.corridorToAdd)
                //     .then(function (respCorridor) {
                //         vm.downstreamRoute.approachRouteId = respCorridor.downstreamRoute.approachRouteId;
                //         if (!vm.isOneWay) {
                //             vm.upstreamRoute.approachRouteId = respCorridor.upstreamRoute.approachRouteId;
                //         }
                //         saveDownStreamCorridorDetails();
                //     }).catch(function (errorMessage) {
                //         $scope.ok();
                //         errorSavingPanel(errorMessage.status);
                //     });
            }
            else {
                noApproacehs();
            }
        };

        function showHelpReviewAlert() {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title('Review corridor signals')
                    .multiple(true)
                    .textContent('In this phase please review the corridor. You can change direction of approaches also. \n ' + '\n' + 'For removing signals from the corridor please go back to second phase.')
                    .ariaLabel('New Signal to Corridor')
                    .ok('Got it!')
            );
        };

        function errorSavingPanel(errorStatus) {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title('Hey, something went wrong')
                    .textContent('Error status code is :' + errorStatus + '. Please contact us! :) ')
                    .ariaLabel('Error during saving')
                    .ok('Got it!')
            );
        }

        function noApproacehs() {
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title('Hey, something went wrong')
                    .multiple(true)
                    .textContent('You should select at least 2 signals in order to create a corridor. If you want to delete corridor, you can do that from Corridor Configuration.')
                    .ariaLabel('Error during saving')
                    .ok('Got it!')
            );
            return;
        }


        function saveUpStreamCorridorDetails() {

            var upStreamApproachRouteDetail = {
                approachRouteId: 0,
                approachRouteDetails: []
            }
            upStreamApproachRouteDetail.approachRouteId = vm.upstreamRoute.approachRouteId;
            vm.upstreamRoute.approaches.forEach(function (elem, i) {
                var routeDetail = {
                    approachOrder: i + 1,
                    approachID: elem.approachID,
                }
                upStreamApproachRouteDetail.approachRouteDetails.push(routeDetail);
            });

            upStreamApproachRouteDetail.approachRouteDetails.forEach(function (approachDet, i) {
                if (approachDet.approachID == null) upStreamApproachRouteDetail.approachRouteDetails.splice(i, 1);
            });

            // corridorService.createCorridorDetails(upStreamApproachRouteDetail)
            //     .then(function (respCorridor) {
            //         $scope.ok();
            //         vm.editedSignalApproach = false;
            //     });
        }

        function saveDownStreamCorridorDetails() {

            var downStreamApproachRouteDetail = {
                approachRouteId: 0,
                approachRouteDetails: []
            }
            downStreamApproachRouteDetail.approachRouteId = vm.downstreamRoute.approachRouteId;
            vm.downstreamRoute.approaches.forEach(function (elem, i) {
                var routeDetail = {
                    approachOrder: i + 1,
                    approachID: elem.approachID,
                }
                downStreamApproachRouteDetail.approachRouteDetails.push(routeDetail);
            });

            downStreamApproachRouteDetail.approachRouteDetails.forEach(function (approachDet, i) {
                if (approachDet.approachID == null) downStreamApproachRouteDetail.approachRouteDetails.splice(i, 1);
            });

            // corridorService.createCorridorDetails(downStreamApproachRouteDetail)
            //     .then(function (respCorridor) {
            //         if (!vm.isOneWay) saveUpStreamCorridorDetails();
            //         if (vm.isOneWay) $scope.ok();
            //         vm.editedSignalApproach = false;
            //     });
        };
    }
})();
