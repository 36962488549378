(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("pcdOptionsController", pcdOptionsController);

    function pcdOptionsController($rootScope, $scope, chartsService, searchBarService) {
        var vm = this;
        vm.chartsService = chartsService;
        vm.pcdOptions = vm.chartsService.getPcdOptions();
        vm.changeCarsInPlatoon = changeCarsInPlatoon;
        vm.changeHeadway = changeHeadway;
        vm.changeShowQueue = changeShowQueue;
        vm.changeHighlight = changeHighlight;
        vm.searchBarService = searchBarService;

        vm.currentCarsInPlatoon = vm.pcdOptions.carsInPlatoon;

        vm.currentHeadwayInSec = vm.pcdOptions.headwayInSec;

        vm.currentShowCarsInQueue = vm.pcdOptions.showCarsInQueue;

        vm.currentHighlight = vm.pcdOptions.highlight;

        vm.platoonOptions = [
            {
                value: 1,
                text: "1 vehicle"
            },
            {
                value: 2,
                text: "2 vehicles"
            },
            {
                value: 3,
                text: "3 vehicles"
            },
            {
                value: 4,
                text: "4 vehicles"
            }
        ];
        vm.headwayOptions = [
            {
                value: 2.5,
                text: "2.5 sec"
            },
            {
                value: 3.5,
                text: "3.5 sec"
            },
            {
                value: 4.5,
                text: "4.5 sec"
            },
            {
                value: 5.5,
                text: "5.5 sec"
            }
        ];
        vm.queueOptions = [
            {
                value: false,
                text: "hide"
            },
            {
                value: true,
                text: "show"
            },
        ];
        vm.highlightOptions = [
            {
                value: "platoon",
                text: "Platoons"
            },
            {
                value: "random",
                text: "Random Arrivals",
            },
        ];

        function changeCarsInPlatoon(val) {
            vm.currentCarsInPlatoon = val;
        }

        function changeHeadway(val) {
            vm.currentHeadwayInSec = val;
        }
        function changeShowQueue(val) {
            vm.currentShowCarsInQueue = val;
        }
        function changeHighlight(val) {
            vm.currentHighlight = val;
        }
        vm.searchBarService.subscribeToOptionsApply($scope, function () {
            vm.pcdOptions.carsInPlatoon = vm.currentCarsInPlatoon;
            vm.pcdOptions.headwayInSec = vm.currentHeadwayInSec;
            vm.pcdOptions.showCarsInQueue = vm.currentShowCarsInQueue;
            vm.pcdOptions.highlight = vm.currentHighlight;
            //user hit apply on options
            vm.chartsService.setPcdOptions(vm.pcdOptions);
        });
    }
}());
