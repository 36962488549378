import * as DETECTORCONFIGSUGGESTIONS from 'raw-loader!./detector-config-suggestions.html'

(function () {
    'use strict';

    angular
        .module('app.spm.dashboards.detector-configuration-suggestions',
            []
        )
        .config(config);

    /** @ngInject */
    function config(msApiProvider, $stateProvider) {
        $stateProvider
            .state('app.spm.dashboards.detector-configuration-suggestions', {
                url: '/detector-configuration-suggestions',
                views: {
                    'content@app': {
                        template: DETECTORCONFIGSUGGESTIONS,
                        controller: 'detectorConfigSuggestions as vm'
                    }
                }
            });

        // Api
        //msApiProvider.register('dashboard.analytics', ['app/data/dashboard/analytics/data.json']);
        //msApiProvider.register('dashboard.performance', ['app/data/dashboard/performance/data.json']);

    }

})();