﻿(function () {

    'use strict';

    angular
        .module('traffop.callback')
        .controller('CallbackController', callbackController);

    function callbackController() { }

})();