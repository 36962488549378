﻿import * as d3v5 from '../../../../../custom-plugins/d3v5';
import 'd3';

(function () {
    "use strict";

    angular
        .module("app.spm.charts")
        .controller("calendarHeatmapController", calendarHeatmapController);

    function calendarHeatmapController($state, $attrs, $scope, $rootScope, $element, insightsService, signalTrendsService) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        });
        vm.signalTrendsService = signalTrendsService;
        vm.cellSize = 22;
        vm.width = 950;
        vm.widthMonth = 50;
        vm.cardWidth = 726;
        vm.formatDay = formatDay;
        vm.formatDate = formatDate;
        vm.formatValue = formatValue;
        vm.formatMonth = formatMonth;
        vm.color = color;
        vm.pathMonth = pathMonth;
        vm.height = height;
        vm.countDay = countDay;
        vm.timeOfWeek = d3v5.utcMonday;
        vm.setTimeOfWeek = setTimeOfWeek;
        vm.getTooltipText = getTooltipText;
        vm.getLegendTooltip = getLegendTooltip;
        vm.data = [];
        vm.getLegend = getLegend;
        vm.daysOfWeek = [];
        //color scheme from good to bad, green to red
        vm.colorScheme = ['#66BB6A', '#9CCC65', '#D4E157', '#FFCA28', '#FFA726', '#FF7043'];
        vm.logScheme = ['#9CCC65', '#FFA726'];
        vm.reverseLogScheme = vm.logScheme.slice().reverse()
        vm.reversedScheme = vm.colorScheme.slice().reverse()
        vm.transformPosition = transformPosition;
        vm.unselectedDays = [];
        vm.findUnselectedDays = findUnselectedDays;
        vm.findIndexesDays = findIndexesDays;
        vm.removeUnselectedDays = removeUnselectedDays;
        vm.displayOnce = displayOnce;
        vm.formatDaysInYAxis = formatDaysInYAxis;
        vm.formatYAxisDay = formatYAxisDay;
        vm.xPositionOfRect = xPositionOfRect;
        vm.yPositionOfRect = yPositionOfRect;
        vm.alignMonth = alignMonth;
        vm.chartName = "";
        vm.isSquareRoot = false;
        vm.baselineOptions = {};
        vm.reverseColorScale = false;
        vm.getPattern = getPattern;
        vm.noData = false;
        vm.getPatternLegend = getPatternLegend;
        vm.getInfoLegend = getInfoLegend;
        $scope.update = update;
        vm.formatDateLegend = formatDateLegend;
        vm.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


        function update(data, daysOfWeek, chartType, chartText, legendTitle, roundNumbers, widthOfCard, chartName, isSquareRoot, reverseColorScale, chartSubheader, baselineOptions, selectedLegend) {
            if (!vm)
                return;

            vm.selectedLegend = selectedLegend;
            vm.baselineOptions = baselineOptions;
            vm.isSquareRoot = isSquareRoot;
            vm.reverseColorScale = reverseColorScale;
            vm.chartSubheader = chartSubheader;
            vm.chartName = chartName;
            vm.daysOfWeek = daysOfWeek;
            vm.chartType = chartType;
            vm.chartText = chartText;
            vm.roundNumbers = roundNumbers;
            vm.legendTitle = legendTitle;
            vm.yearShow = true;
            vm.daysShow = true;
            vm.countDays = 0;
            vm.firstMonth = true;
            vm.unselectedDays = [];
            vm.indexesDays = [];
            vm.cardWidth = widthOfCard - 26;
            vm.dataInsights = {};
            vm.data = data;
            vm.noData = vm.data.filter(x => x.dataInsights.noData == true).length > 0;
            vm.setTimeOfWeek();
            vm.findUnselectedDays(vm.daysOfWeek);
            vm.findIndexesDays(vm.daysOfWeek);
            vm.removeUnselectedDays(vm.unselectedDays);
            vm.selectedWeeks = vm.timeOfWeek.count(new Date(vm.data[0].date), new Date(vm.data[vm.data.length - 1].date));

            var years = d3.groups(vm.data, d => new Date(d.date).getFullYear());

            var widthExtract = 106;
            if (vm.cardWidth < 750) {
                widthExtract = 66;
            }
            
            var cardWidthRect = (vm.cardWidth - widthExtract) / (vm.selectedWeeks + 1);
            vm.cellSize = 147 / vm.daysOfWeek.length;
            //create the svg
            const svg = d3v5.create("svg")
                .attr("viewBox", [0, 0, vm.cardWidth, 192])
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr('style', 'height:' + 192 + 'px')
                .attr("class", "flex layout-row svg-content");

            const year = svg.selectAll("g")
                .data(years)
                .join("g")
                .attr("transform", (d, i) => vm.transformPosition(d, i));


            // year.append("text")
            //     .attr("x", -5)
            //     .attr("y", -5)
            //     .attr("font-weight", "bold")
            //     .attr("font-size", 13)
            //     .attr("text-anchor", "end")
            //     .text(([key]) => vm.displayOnce(key))

            year.append("g")
                .attr("text-anchor", "end")
                .selectAll("text")
                .data(vm.indexesDays.map(i => vm.formatDaysInYAxis(i)))
                .join("text")
                .attr("x", -5)
                .attr("y", d => vm.formatYAxisDay(d))
                .attr("dy", "0.31em")
                .attr("font-size", 11)
                .text(t => vm.formatDay(t))


            year.append("g")
                .selectAll("rect")
                .data(([, values]) => values)
                .join("rect")
                .attr("width", cardWidthRect)
                .attr("height", vm.cellSize)
                .attr("x", d => vm.xPositionOfRect(d, cardWidthRect))
                .attr("y", d => vm.yPositionOfRect(d))
                .attr("fill", d => (d.dataInsights.noData || d.dataInsights.missingData) ? vm.getPattern(d) : vm.color()(d.value))
                .append("title")
                .text(d => vm.getTooltipText(d))


            const month = year.append("g")
                .selectAll("g")
                .data(([, values]) => d3v5.timeMonths(d3v5.timeMonth(new Date(values[0].date)), new Date(values[values.length - 1].date)))
                .join("g");


            month.filter((d, i) => i).append("path")
                .attr("fill", "none")
                .attr("stroke", "#fff")
                .attr("stroke-width", 2)
                .attr("d", t => vm.pathMonth(t, cardWidthRect));

            month.append("text")
                .attr("x", d => vm.alignMonth(d, cardWidthRect))
                .attr("y", -5)
                .attr("font-size", 11)
                .text(t => vm.formatMonth(t));

            var newNode = svg.node();
            var parentNode = $element.find('#calendar-heatmap-chart');
            parentNode.empty();
            parentNode.append(newNode);

            var legendParent = $element.find('#calendar-heatmap-legend');
            var legend = vm.getLegend();
            legendParent.empty();
            legendParent.append(legend);

            var patternParent = $element.find('#calendar-heatmap-pattern');

            if (vm.noData) {
                var pattern = vm.getPatternLegend();
                patternParent.empty();
                patternParent.append(pattern);
            }
            else {
                patternParent.empty();
            }

            var parentInfoNode = $element.find('#calendar-heatmap-info');
            var info = vm.getInfoLegend();
            parentInfoNode.empty();
            parentInfoNode.append(info);
        }

        function getPatternLegend() {
            let width = 100;
            let height = 50;

            let rectWitd = 54;
            let rectXPosition = -45;
            if (vm.cardWidth > 750) {
                rectWitd = 116;
                rectXPosition = 15;
            }

            const svg = d3v5.create("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [rectXPosition, 0, width, height])
                .style("overflow", "visible")
                .style("display", "block");

            //Create and append rectangle element
            svg.append("rect")
                .attr("x", 0)
                .attr("y", 18)
                .attr("width", rectWitd)
                .attr("height", 10)
                .attr("fill", 'url(#crosshatch)')
                .attr("stroke", "#000")
                .attr("stroke-width", 0.2)

            svg.append("text")
                .attr("x", 35)
                .attr("y", 42)
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr("text-anchor", "end")
                .text("No data")

            return svg.node();
        }

        function getInfoLegend() {
            var viewBoxX = -40;
            switch (vm.chartText) {
                case 1:
                    viewBoxX = -147;
                    break;
                case 2:
                    viewBoxX = -105;
                    break;
                case 3:
                    switch (vm.legendTitle) {
                        case 'Counts':
                            viewBoxX = -34
                            break;
                        case 'Seconds':
                            viewBoxX = -42;
                            break;
                        case 'Percentage':
                        default:
                            viewBoxX = -54
                            break;
                    }
                    break;
                case 4:
                    viewBoxX = -101;
                    break;
                case 5:
                    viewBoxX = -63;
                    break;
            }
            const svg = d3v5.create("svg")
                .attr("width", 20)
                .attr("height", 20)
                .attr("viewBox", [viewBoxX, 56, 20, 20])
                .style("overflow", "visible")
                .style("display", "block")

            //Create and append rectangle element
            svg.append("image")
                .attr("x", 11)
                .attr("y", 26)
                .attr("width", 17)
                .attr("height", 17)
                .attr("preserveAspectRatio", "none")
                .attr("xlink:href", '../assets/icons/info_icon_trends.svg')
                .append("title")
                .text(() => vm.getLegendTooltip());

            return svg.node();
        }

        function getPattern(d) {
            let res = '';
            if (d.dataInsights.noData) res = 'url(#crosshatch)'; // add also condition if d.value is == 0
            if (d.dataInsights.missingData) res = res = 'url(#lightstripe)';
            if (d.dataInsights.unrelabileData) res = "url(#diagonal-stripe-3)"
            return res;
        }

        function findUnselectedDays(selectedDays) {
            for (let i = 0; i < 7; i++) {
                let res = selectedDays.filter(n => n == i);
                if (!res.length > 0) vm.unselectedDays.push(i);
            }
        }

        function findIndexesDays(selectedDays) {
            selectedDays.forEach(function (element, i) {
                vm.indexesDays.push(i);
            });
        }

        function removeUnselectedDays(unselectedDays) {
            unselectedDays.forEach(function (u) {
                vm.data.forEach(function (d, i) {
                    let count = vm.countDay(new Date(d.date));
                    if (u == count) {
                        vm.data.splice(i, 1);
                    }
                })
            })
        }

        function determineCount(unselectedDays, currentCount) {
            let c = 0;
            if (unselectedDays.length > 0) {
                unselectedDays.forEach(function (uDay) {
                    if (currentCount > uDay) c++;
                })
            }
            let res = currentCount - c;
            return res;
        }

        function yPositionOfRect(d) {
            let count = vm.countDay(d.date);

            count = determineCount(vm.unselectedDays, count);

            let res = count * vm.cellSize + 0.5;
            return res;
        }

        function formatYAxisDay(d) {
            let count = vm.countDay(d);

            count = determineCount(vm.unselectedDays, count);

            let res = (count + .5) * vm.cellSize;
            return res;
        }

        function formatDaysInYAxis(i) {
            let res = new Date(vm.data[i].date);
            return res;
        }

        function alignMonth(d, cardWidthRect) {
            let res;
            let weeks = 0;
            let months = d3v5.timeMonths(d3v5.timeMonth(new Date(vm.data[0].date)), new Date(vm.data[vm.data.length - 1].date));
            let nextMonth = months[0];
            
            months.forEach(function (month,i) {
                if(month.getMonth() == d.getMonth()){ 
                    if(i === months.length-1) nextMonth = new Date(vm.data[vm.data.length-1].date);
                    else nextMonth = months[i+1]
                }
            });

            if (vm.firstMonth) {
                vm.firstMonth = false;
                weeks = 0;
            }
            else {
                weeks = vm.timeOfWeek.count(new Date(vm.data[0].date), vm.timeOfWeek.floor(new Date(d)));
                weeks == 0 ? (weeks = 1) : (weeks = weeks);
            }

            let lastSelectedWeek = vm.timeOfWeek.count(new Date(vm.data[0].date), vm.timeOfWeek.floor(new Date(vm.data[vm.data.length-1].date)));
            let nextMonthWeeks = vm.timeOfWeek.count(new Date(vm.data[0].date), vm.timeOfWeek.floor(nextMonth));

            res = ((nextMonthWeeks + weeks) / 2) * cardWidthRect ;


            // if(weeks + 2 < lastSelectedWeek){
            // res = weeks * cardWidthRect + (2 * cardWidthRect);
            // }
            // else if(weeks == lastSelectedWeek){
            //     res = weeks * cardWidthRect;
            // }
            // else{
            //     res = weeks * cardWidthRect + (1 * cardWidthRect);
            // }


            return res;
        }

        function displayOnce(y) {
            if (vm.yearShow) {
                vm.yearShow = false;
                return y;
            }
            else return null;
        }

        function xPositionOfRect(d, cardWidthRect) {
            let startDate = new Date(vm.data[0].date)
            let res = vm.timeOfWeek.count(startDate, new Date(d.date)) * cardWidthRect + 0.5;
            return res;
        }

        function transformPosition(d, i) {
            let step = i > 0 ? 2 : 0;
            return `translate(${vm.widthMonth + step}, ${18 * 1.5})`;
        }

        function getTooltipText(d) {
            if (vm.chartText == 2)
                return `${vm.formatDate(d.date)} Baseline Date: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 1)
                return `${vm.formatDate(d.date)} Baseline Average: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 5)
                return `${vm.formatDate(d.date)} Dow Change: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 4)
                return `${vm.formatDate(d.date)} Daily percent Change: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 3)
                return `${vm.formatDate(d.date)} ${vm.chartName}: ${d.value.toLocaleString()}`
        }

        function getLegendTooltip() {
            if (vm.chartText == 2)
                return `Percent change (compared to the baseline date) \nBaseline average for ${vm.formatDateLegend(vm.baselineOptions.baselineDateStart, vm.baselineOptions.baselineDateEnd)} : ${vm.baselineOptions.baselineDateValue.toFixed(2)}`
            if (vm.chartText == 1)
                return `Percent change (compared to the baseline) \nBaseline Average: ${vm.baselineOptions.baselineAverageValue.toFixed(2)}`
            if (vm.chartText == 5)
                return `Percent change (compared to the same day of  previous week)`
            if (vm.chartText == 4)
                return `Percent change (compared to the previous day)`
            if (vm.chartText == 3)
                return `Colors are asigned based on the range of values.`
        }

        function formatDateLegend(start, end) {
            //handle when months are the same
            if (start.getMonth() === end.getMonth()) {
                if (start.getDate() === end.getDate()) {
                    //start and end date are exactly same
                    return vm.monthNamesShort[start.getMonth()] + " " + start.getDate() + ", " + start.getFullYear();
                }
                else {
                    //same month different date
                    return vm.monthNamesShort[start.getMonth()] + " " + start.getDate() + ", " + start.getFullYear() + " - " + vm.monthNamesShort[start.getMonth()] + " " + end.getDate() + ", " + start.getFullYear();
                }
            }
            else {
                //start and end dates span different months
                return vm.monthNamesShort[start.getMonth()] + " " + start.getDate() + ", " + start.getFullYear() + " - " + vm.monthNamesShort[end.getMonth()] + " " + end.getDate() + ", " + end.getFullYear();
            }
        }

        function formatDay(t) {
            vm.countDays++;
            let res = "";
            if (vm.countDays <= (7 - vm.unselectedDays.length) && vm.daysShow) {
                res = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][t.getDay()];
                return res;
            }
        }

        function formatDate(d) {
            return d3v5.timeFormat("%x")(new Date(d));
        }

        function formatValue(d) {
            return d3v5.format("+.2%")(d);
        }

        function formatMonth(t) {
            return d3v5.timeFormat("%b")(t);
        }

        function height() {
            return vm.cellSize * (vm.daysOfWeek.length + 2);
        }

        function countDay(d) {
            let a = new Date(d).getDay() + 6;
            return a % 7;
        }

        function countPathDay(d) {
            let a = new Date(d).getDay() + 6;
            return a % 7;
        }

        function setTimeOfWeek() {
            switch (vm.daysOfWeek[0]) {
                case 0: vm.timeOfWeek = d3v5.timeMonday;
                    break;
                case 1: vm.timeOfWeek = d3v5.timeTuesday;
                    break;
                case 2: vm.timeOfWeek = d3v5.timeWednesday;
                    break;
                case 3: vm.timeOfWeek = d3v5.timeThursday;
                    break;
                case 4: vm.timeOfWeek = d3v5.timeFriday;
                    break;
                case 5: vm.timeOfWeek = d3v5.timeSaturday;
                    break;
                case 6: vm.timeOfWeek = d3v5.timeSunday;
                    break;
            }
        }

        function pathMonth(t, cardWidthRect) {
            const n = vm.daysOfWeek.length;
            const d = Math.max(0, Math.min(n, countPathDay(t)));
            const w = vm.timeOfWeek.count(new Date(vm.data[0].date), t);
            var res = `${d === 0 ? `M${w * cardWidthRect},0`
                : d === n ? `M${(w + 1) * cardWidthRect},0`
                    : `M${(w + 1) * cardWidthRect},0V${d * vm.cellSize}H${w * cardWidthRect}`}V${n * vm.cellSize}`;


            return res;
        }

        function color() {
            var noInsightsData = vm.data.filter(x => x.dataInsights.missingData == false && x.dataInsights.noData == false)
            var max = d3v5.quantile(noInsightsData.map(d => Math.abs(d.value)).sort(d3v5.ascending), 0.9975);
            switch (vm.chartType) {
                case 2:
                    vm.isScaleSequentialSqrt = false;
                    if (max > .20)
                        max = .20;
                    //return d3v5.scaleSequential().domain([-max, +max]).interpolator(d3v5.interpolateRgbBasis(vm.colorScheme));
                    return d3v5.scaleDivergingSqrt([-max, 0, +max], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme))
                //return d3v5.scaleLinear().domain([-max, 0, max]).range(vm.colorScheme);
                case 1:
                    var scheme = vm.colorScheme; //d3v5.schemeRdYlGn[6];
                    var domainData = noInsightsData.map(x => x.value).sort(d3v5.ascending);
                    var positiveDomain = domainData.filter(v => v > 0).sort(d3v5.ascending);
                    let minOfDomain = Math.min(...domainData)
                    let maxOfDomain = Math.max(...domainData)
                    // if (vm.reverseColorScale) {
                    //     vm.colorScheme = vm.colorScheme.slice().reverse();
                    //     scheme = scheme.slice().reverse();
                    // }

                    // if (max > .20) max = .20;

                    // switch(vm.selectedLegend){
                    //     case vm.signalTrendsService.legendTypes.scaleSequential:
                    //             vm.isScaleSequentialSqrt = true;
                    //             vm.isSquareRoot = true;
                    //             return d3v5.scaleSequential([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialSqrt:
                    //             vm.isScaleSequentialSqrt = true;
                    //             vm.isSquareRoot = true;
                    //             return d3v5.scaleSequentialSqrt([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleDiverging:
                    //             return d3v5.scaleDiverging([-1, 0, +1], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleDivergingSqrt:
                    //             return d3v5.scaleDivergingSqrt([-1, 0, +1], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialLog:
                    //             vm.isSquareRoot = true;
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleSequentialLog([minOfDomain <= 0 ?  0.1 : minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialQuantile:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleSequentialQuantile([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSqrt:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleSqrt([minOfDomain, ((maxOfDomain+minOfDomain) / 2).toFixed(), maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleQuantize:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleQuantize([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleQuantile:
                    //             vm.isSquareRoot = false;
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleQuantile(domainData, vm.reverseColorScale ? vm.reversedScheme : scheme);                                
                    //             //return d3v5.scaleLog().domain(d3v5.extent(domainData)).base(10).range(vm.reverseColorScale ? vm.reverseLogScheme: vm.logScheme)        
                    //     case vm.signalTrendsService.legendTypes.scaleThreshold:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleThreshold(domainData, d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleOrdinal:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleOrdinal(["<10", "10-49", "50-69", "70-79", "≥80"], d3v5.schemeSpectral[5]);        
                    // }
                    if (vm.isSquareRoot) {
                        vm.isScaleSequentialSqrt = true;
                        return d3v5.scaleSequentialSqrt([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));
                    }
                    else {
                        vm.isScaleSequentialSqrt = false;
                        return d3v5.scaleQuantile(domainData, vm.reverseColorScale ? vm.reversedScheme : scheme);
                        //return d3v5.scaleLog().domain(d3v5.extent(domainData)).base(10).range(vm.reverseColorScale ? vm.reverseLogScheme: vm.logScheme)
                    }
            }

        }

        function getLegend() {
            switch (vm.chartType) {
                case 2:
                    vm.caseTwo = true;
                    return d3v5legend({ color: vm.color(), title: vm.legendTitle, tickFormat: "+%" });
                case 1:
                    vm.caseTwo = false;
                        // switch(vm.selectedLegend){
                        //     case vm.signalTrendsService.legendTypes.scaleSequential:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential", tickFormat: "," });
                        //     case vm.signalTrendsService.legendTypes.scaleSequentialSqrt:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential Sqrt", tickFormat: "," });
                        //     case vm.signalTrendsService.legendTypes.scaleDiverging:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Diverging", tickFormat: "+%" });
                        //     case vm.signalTrendsService.legendTypes.scaleDivergingSqrt:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Diverging Sqrt", tickFormat: "+%" });
                        //     case vm.signalTrendsService.legendTypes.scaleSequentialLog:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential Log", ticks:5, tickFormat: ",d"});
                        //     case vm.signalTrendsService.legendTypes.scaleSequentialQuantile:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential Quantile" });
                        //     case vm.signalTrendsService.legendTypes.scaleSqrt:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sqrt"});
                        //     case vm.signalTrendsService.legendTypes.scaleQuantize:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Quantize", tickFormat: "," });
                        //     case vm.signalTrendsService.legendTypes.scaleQuantile:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Quantile", tickFormat: "," });
                        //     case vm.signalTrendsService.legendTypes.scaleThreshold:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Threshold", tickSize:0 });
                        //     case vm.signalTrendsService.legendTypes.scaleOrdinal:
                        //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Ordinal", tickSize:0 });
                        // }
                        return d3v5legend({ color: vm.color(), title: vm.legendTitle, tickFormat: "," });

            }
        }

        function getLegendText() {
            let res = "";            
            switch(vm.selectedLegend){
                case vm.signalTrendsService.legendTypes.scaleSequential: res="Scale Sequential"; break;
                case vm.signalTrendsService.legendTypes.scaleSequentialSqrt: res="Scale Sequential Sqrt"; break;
                case vm.signalTrendsService.legendTypes.scaleDiverging: res="Scale Diverging"; break;
                case vm.signalTrendsService.legendTypes.scaleDivergingSqrt: res="Scale Diverging Sqrt"; break;
                case vm.signalTrendsService.legendTypes.scaleSequentialLog: res="Scale Sequential Log"; break;
                case vm.signalTrendsService.legendTypes.scaleSequentialQuantile: res="Scale Sequential Quantile"; break;
                case vm.signalTrendsService.legendTypes.scaleSqrt: res="Scale Sqrt"; break;
                case vm.signalTrendsService.legendTypes.scaleQuantize: res="Scale Quantize"; break;
                case vm.signalTrendsService.legendTypes.scaleQuantile: res="Scale Quantile"; break;
                case vm.signalTrendsService.legendTypes.scaleThreshold: res="Scale Threshold"; break;
                case vm.signalTrendsService.legendTypes.scaleOrdinal: res="Scale Ordinal"; break;
            }
            return res;            
        }

        function d3v5legend({
            color,
            title,
            tickSize = 6,
            width = 270,
            height = 44 + tickSize,
            marginTop = 18,
            marginRight = 0,
            marginBottom = 16 + tickSize,
            marginLeft = 0,
            ticks = 7,
            tickFormat,
            tickValues
        } = {}) {

            if (vm.cardWidth < 750) {
                vm.isSquareRoot ? width = width + 54 : width;
            }
            else {
                if (vm.caseTwo) {
                    vm.isSquareRoot ? width = 580 + 116 : width = 580 + 62;
                } else {
                    vm.isSquareRoot ? width = 580 + 116 : width = 580;
                }
            }

            const svg = d3v5.create("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [0, 0, width, height])
                .style("overflow", "visible")
                .style("display", "block");

            let tickAdjust = g => g.selectAll(".tick line").attr("y1", marginTop + marginBottom - height);
            let x;
            // Continuous
            if (color.interpolate) {
                const n = Math.min(color.domain().length, color.range().length);

                x = color.copy().rangeRound(d3v5.quantize(d3v5.interpolate(marginLeft, width - marginRight), n));

                svg.append("image")
                    .attr("x", marginLeft)
                    .attr("y", marginTop)
                    .attr("width", width - marginLeft - marginRight)
                    .attr("height", height - marginTop - marginBottom)
                    .attr("preserveAspectRatio", "none")
                    .attr("xlink:href", ramp(color.copy().domain(d3v5.quantize(d3v5.interpolate(0, 1), n))).toDataURL())
                    .append("title")
                    .text(() => vm.getLegendTooltip())
            }

            // Sequential
            else if (color.interpolator) {
                if (vm.isSquareRoot) {
                    x = Object.assign(color.copy()
                        .interpolator(d3v5.interpolateRound(marginLeft, width - marginRight)),
                        { range() { return [marginLeft, width - marginRight]; } });
                    svg.append("image")
                        .attr("x", marginLeft)
                        .attr("y", marginTop)
                        .attr("width", width - marginLeft - marginRight)
                        .attr("height", height - marginTop - marginBottom)
                        .attr("preserveAspectRatio", "none")
                        .attr("xlink:href", ramp(color.interpolator()).toDataURL())
                        .append("title")
                        .text(() => vm.getLegendTooltip())
                } else {
                    x = Object.assign(color.copy()
                        .interpolator(d3v5.interpolateRound(marginLeft, width - marginRight + 54)),
                        { range() { return [marginLeft, width - marginRight]; } });
                    svg.append("image")
                        .attr("x", marginLeft)
                        .attr("y", marginTop)
                        .attr("width", width - marginLeft - marginRight + 54)
                        .attr("height", height - marginTop - marginBottom)
                        .attr("preserveAspectRatio", "none")
                        .attr("xlink:href", ramp(color.interpolator()).toDataURL())
                        .append("title")
                        .text(() => vm.getLegendTooltip())
                }

                // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
                // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
                if (!x.ticks) {
                    if (tickValues === undefined) {
                        const n = Math.round(ticks + 1);
                        tickValues = d3v5.range(n).map(i => d3v5.quantile(color.domain(), i / (n - 1)).toFixed());
                    }
                    if (typeof tickFormat !== "function") {
                        tickFormat = d3v5.format(tickFormat === undefined ? ",1f" : tickFormat);
                    }
                }
                if (x.ticks && vm.isScaleSequentialSqrt) {
                    if (tickValues === undefined) {
                        const n = Math.round(ticks + 1);
                        tickValues = d3v5.range(n).map(i => d3v5.quantile(color.domain(), i / (n - 1)).toFixed());
                    }
                    if (typeof tickFormat !== "function") {
                        tickFormat = d3v5.format(tickFormat === undefined ? ",1f" : tickFormat);
                    }
                }
            }

            // Threshold
            else if (color.invertExtent) {
                // var thresholds
                //     = color.thresholds ? color.thresholds() // scaleQuantize
                //         : color.quantiles ? color.quantiles().map(x => vm.roundNumbers ? d3.round(x) : x.toFixed(2)) // scaleQuantile
                //             : color.domain(); // scaleThreshold
                var thresholds
                    = color.thresholds ? color.thresholds() // scaleQuantize
                        : color.quantiles ? color.quantiles().map(x => x.toFixed(2)) // scaleQuantile
                            : color.domain(); // scaleThreshold
                thresholds.unshift(color.domain()[0]);
                thresholds.push(color.domain()[color.domain().length - 1]);

                const thresholdFormat
                    = tickFormat === undefined ? d => d
                        : typeof tickFormat === "string" ? d3v5.format(tickFormat)
                            : tickFormat;

                x = d3v5.scaleLinear()
                    .domain([0, color.range().length - 1])
                    .rangeRound([marginLeft, width - marginRight]);

                svg.append("g")
                    .selectAll("rect")
                    .data(color.range())
                    .join("rect")
                    .attr("x", (d, i) => x(i))
                    .attr("y", marginTop)
                    .attr("width", (d, i) => x(i) - x(i - 1))
                    .attr("height", height - marginTop - marginBottom)
                    .attr("fill", d => d)
                    .append("title")
                    .text(() => vm.getLegendTooltip())

                tickValues = d3v5.range(thresholds.length);
                tickFormat = i => thresholdFormat(thresholds[i], i);
            }

            // Ordinal
            else {
                x = d3v5.scaleBand()
                    .domain(color.domain())
                    .rangeRound([marginLeft, width - marginRight]);

                svg.append("g")
                    .selectAll("rect")
                    .data(color.domain())
                    .join("rect")
                    .attr("x", x)
                    .attr("y", marginTop)
                    .attr("width", Math.max(0, x.bandwidth() - 1))
                    .attr("height", height - marginTop - marginBottom)
                    .attr("fill", color)
                    .append("title")
                    .text(() => vm.getLegendTooltip())

                tickAdjust = () => { };
            }

            svg.append("g")
                .attr("transform", translateLegend(color, height, marginBottom))
                .call(d3v5.axisBottom(x)
                    .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
                    .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
                    .tickSize(tickSize)
                    .tickValues(tickValues))
                .call(tickAdjust)
                .call(g => g.select(".domain").remove())
                .call(g => g.append("text")
                    .attr("x", marginLeft)
                    .attr("y", marginTop + marginBottom - height - 6)
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "start")
                    .attr("font-weight", "bold")
                    .text(title));


            return svg.node();
        }

        function translateLegend(color, height, marginBottom) {
            return `translate(0,${height - marginBottom})`;
        }

        function ramp(color, n = 256) {
            const canvas = getCanvas(n, 1);
            const context = canvas.getContext("2d");
            for (let i = 0; i < n; ++i) {
                context.fillStyle = color(i / (n - 1));
                context.fillRect(i, 0, 1, 1);
            }
            return canvas;
        }

        function getCanvas(e, t) {
            var n = document.createElement("canvas");
            return n.width = e,
                n.height = t,
                n
        }

        function entity(character) {
            return `&#${character.charCodeAt(0).toString()};`;
        }
    }
}());
