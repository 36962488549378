﻿(function () {
    "use strict";

    angular.module("app.spm.core")
        .factory("routesResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            return {
                routes: $resource(environmentConfig.apiUrl + "/api/ApproachRoutes/", {}, {
                    query: {
                        method: "GET",
                        url: environmentConfig.apiUrl + "/api/ApproachRoutes/query?",
                    },
                    update: { 
                        url: environmentConfig.apiUrl + "/api/ApproachRoutes/?id=:id&name=:name",
                        method: "PUT", 
                        params: { id: "@id", name: "@name" } ,
                    },
                    create: { method: "POST"},
                    get: { method: "GET", url: environmentConfig.apiUrl + "/api/ApproachRoutes/:id", cache: false },
                    remove: { method: "DELETE" },
                }),
                approachDetails: $resource(environmentConfig.apiUrl + "/api/ApproachRouteDetails/", {}, {
                    create: { method: "POST" },
                    update: {
                        url: environmentConfig.apiUrl + "/api/ApproachRouteDetails/?routeId=:routeId&approachId=:approachId&newOrder=:newOrder",
                        method: "PUT",
                        params: { routeId: "@routeId", approachId: "@approachId", newOrder: "@newOrder" }
                    },
                    remove: { path: environmentConfig.apiUrl + "/api/ApproachRouteDetails/DeleteDetail", method: "DELETE" }
                }
            )};
        }]);
}());