(function () {
    "use strict";

    angular
        .module("app.spm.link-pivot")
        .controller("purdueLinkPivotOptionsController", purdueLinkPivotOptionsController);

    function purdueLinkPivotOptionsController($state, $rootScope, $scope, searchBarService, purdueLinkPivotService) {
        var vm = this;
        vm.startingPointChange = startingPointChange;
        vm.purdueLinkPivotService = purdueLinkPivotService;
        vm.options = {};
        vm.getOptions = getOptions;
        vm.biasChange = biasChange;
        vm.biasDirectionChange = biasDirectionChange;
        vm.cycleLengthChange = cycleLengthChange;
        vm.searchBarService= searchBarService;
        
        function startingPointChange(newStartingPoint) {
            vm.options.startingPoint = newStartingPoint;
        }

        function biasChange() {
            console.log(vm.options.bias)
            if (!vm.options.bias) vm.options.bias = 0;
        }

        function biasDirectionChange(newBias) {
            vm.options.biasDirection = newBias;

        }

        function cycleLengthChange() {
            if (!vm.options.cycleLength) vm.options.cycleLength = 1;
        }

        vm.searchBarService.subscribeToOptionsApply($scope, function () {
            //user hit apply on options
            vm.purdueLinkPivotService.notifyChange(vm.options);
        });

        function getOptions() {
            vm.fetchingOptions = true;
            vm.purdueLinkPivotService.getLinkPivotOptions()
                .then(function (data) {
                    vm.options = data;
                });
        };

        vm.getOptions();
    }
}());
