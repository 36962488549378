(function () {

    'use strict';

    angular
        .module("app.spm.core")
        .service('userService', userService);

    function userService(authService, jwtHelper) {

        const permission = {
            read_only: 'READ_ONLY',
            read_write: 'READ_WRITE',
        }
        const role = {
            admin: "ADMIN",
            standard: "STANDARD",
            marketing: "MARKETING"
        }

        //this function will go directly to the auth token and read the user roles array and return them back
        function getUserRoles() {
            let token = authService.getToken();
            let roles = [];
            if (!jwtHelper.isTokenExpired(token)) {
                let tokenPayload = jwtHelper.decodeToken(token);
                if (tokenPayload && tokenPayload.roles) {
                    for (let i = 0; i < tokenPayload.roles.length; i++) {
                        //make sure our roles match our enum values then return only enum values
                        switch (tokenPayload.roles[i].toUpperCase()) {
                            case role.admin:
                                roles.push(role.admin);
                                break;
                            case role.standard:
                                roles.push(role.standard);
                                break;
                            case role.marketing:
                                roles.push(role.marketing);
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            return roles;
        }

        //this function will go directly to the auth token and return back any user permissions present
        function getUserPermissions() {
            let token = sessionStorage.getItem('id_token');
            let permissions = [];
            if (!jwtHelper.isTokenExpired(token)) {
                let tokenPayload = jwtHelper.decodeToken(token);
                if (tokenPayload && tokenPayload.permissions) {
                    for (let i = 0; i < tokenPayload.permissions.length; i++) {
                        //make sure our pemrissions match our enum values then return only enum values
                        switch (tokenPayload.permissions[i].toUpperCase()) {
                            case permission.read_only:
                                permissions.push(permission.read_only);
                                break;
                            case permission.read_write:
                                permissions.push(permission.read_write);
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            return permissions;
        }

        //checks user premissions for READ_WRITE permission
        function canUserReadWrite() {
            return getUserPermissions().indexOf(permission.read_write) !== -1;
        }

        return {
            permission: permission,
            role: role,
            getUserRoles: getUserRoles,
            canUserReadWrite: canUserReadWrite
        }
    }
})();