(function () {
    return angular.module("config", [])
        .constant("environmentConfig", { 
            "apiUrl": "https://staging.api.traffop.miovision.com", 
            "rootUrl": "https://staging.traffop.miovision.com/callback", 
            "authClient": "WYNIiglJHFK2c6zAsx5JGkZcjvRMfI6t", 
            "authDomain": "login.traffop.com", 
            "googleAnalyticsTrackingCode": "UA-114926618-1", 
            "environmentName": "staging", 
            "segmentKey": "dEAn0FC4uMHl6jooJ3L3tmNaVeYDHKqt" 
        });
})();
