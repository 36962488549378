import 'flatpickr';

(function () {
    "use strict";

    angular
        .module('app.core')
        .controller('InsightsSidemenuController', InsightsSidemenuController);

    function InsightsSidemenuController($rootScope, $scope, $timeout, $compile, $interval, $mdDialog, $mdSidenav, newWindowService, signalService, insightsService, searchBarService, userSettingsService) {
        var vm = this;
       

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })

        // userSettingsService.getUserSettings().then(function (userSettings) {
        //     if (userSettings.insightSettings.bookmarkedInsights) {
        //         vm.bookmarkedInsights = userSettings.insightSettings.bookmarkedInsights;
        //     }
        // })


        // ============================== ACKNOWLEDGE AND IGNORE INSIGHT ============================== //

       
        // ================================================================================= //


        // ==================================================================== // 

    }
})();