﻿(function () {
    "use strict";

    angular
        .module("app.spm.corridors")
        .service("corridorService", ['routesResource', '$state', '$rootScope', '$mdDialog', '$mdToast', 'corridorResource', corridorService]);

    function corridorService(routesResource, $state, $rootScope, $mdDialog, $mdToast, corridorResource) {
        function queryCorridors(queryData) {
            return corridorResource.query(queryData, function (data) {
                if (data && data.corridors) {
                    data.corridors.forEach(function (rt) {
                        rt.description = rt.corridorID + ": " + rt.name;
                    });
                }
                return data;
            }).$promise;
        }

        function getAllCorridors() {
            return corridorResource.getAll(function (data) {
                if (data && data.corridors) {
                    data.corridors.forEach(function (rt) {
                        rt.description = rt.corridorID + ": " + rt.name;
                    });
                }
                return data;
            }).$promise;
        }

        function getCorridorById(id) {
            return corridorResource.getById({ id: id },
                function (data) {
                    if (!data.description && data.customID && data.primaryName) {
                        data.description = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                    }
                    return data;
                }).$promise;
        }

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }
        
        function getCorridorPerformance(options) {
            return corridorResource.getPerformance(options,
                function (data) {
                    return data;
                }).$promise;
        }

        function getWazeData(options) {
            return corridorResource.getWazeData(options,
                function (data) {
                    return data;
                }).$promise;
        }

        function getAllWazeRouteGeometries() {
            return corridorResource.getWazeRouteGeometries(
                function (data) {
                    return data;
                }).$promise;
        }

        function getWazeRoutes(query) {
            return corridorResource.getWazeRoutes(query,
                function (data) {
                    return data;
                }).$promise;
        }
        
        function getWazeRouteTravelTimeInfo(query) {
            return corridorResource.getWazeRouteTravelTimeInfo(query,
                function (data) {
                    return data;
                }).$promise;
        }
        
        function isWazeEnabled(){
            return corridorResource.isWazeEnabled(
                function (data) {
                    return data;
                }).$promise;
        }

        function createPerformanceOptionsObject(corridorID, aggType, startDate, endDate, startHour, startMinute, endHour, endMinute,
            daysOfWeekArray, timeOption, binSize, directionalOnly) {
            var opts = {
                aggregationTypes: aggType,
                corridorID: corridorID,
                startDateTime: startDate,
                endDateTime: endDate,
                timeOfDayStartHour: startHour,
                timeOfDayStartMinute: startMinute,
                timeOfDayEndHour: endHour,
                timeOfDayEndMinute: endMinute,
                daysOfWeek: daysOfWeekArray,
                timeOption: timeOption,
                selectedBinSize: binSize,
                directionalDataOnly: directionalOnly
            }
            return opts;
        }

        function createUpsertCorridor(corridor) {
            return corridorResource.createUpsertCorridor(corridor,
                function (data) {
                    showCorridorSavedToast();
                    return data;
                }).$promise;
        }

        function createCorridorDetails(corridor) {
            return corridorResource.setCorridorDetails(corridor,
                function (data) {
                    showCorridorSavedToast();
                    return data;
                }).$promise;
        }

        function removeCorridor(corridor, callback) {
            var confirmText = "Are you sure you want to delete " +
                corridor.corridorID +
                ": " +
                corridor.description;

            var confirm = $mdDialog.confirm()
                .title(confirmText)
                .textContent('This action cannot be undone')
                .ok('Yes delete this corridor')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
             deleteCorridor(corridor.corridorID).then(function () {
                    if (callback)
                        callback();
                });
            }, function () { });
        }
        function deleteCorridor(id) {
            return corridorResource.delete({ id: id },
                function (data) {
                    showCorridorDeleteToast();
                    return data;
                }).$promise;
        }

        function showCorridorSavedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Corridor saved successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }
        function showCorridorDeleteToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Corridor saved successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }

        return {
            getCorridorById: getCorridorById,
            queryCorridors: queryCorridors,
            getCorridorPerformance: getCorridorPerformance,
            createPerformanceOptionsObject: createPerformanceOptionsObject,
            createUpsertCorridor: createUpsertCorridor,
            deleteCorridor: deleteCorridor,
            removeCorridor: removeCorridor,
            createCorridorDetails: createCorridorDetails,
            getWazeData: getWazeData,
            isWazeEnabled: isWazeEnabled,
            getAllWazeRouteGeometries: getAllWazeRouteGeometries,
            getWazeRoutes: getWazeRoutes,
            getWazeRouteTravelTimeInfo: getWazeRouteTravelTimeInfo,
            getAllCorridors: getAllCorridors
        }
    }
})();
