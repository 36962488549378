(function () {

    'use strict';

    angular
        .module('app.searchbar')
        .service('searchBarService', searchBarService);

    //This service encapsulates all actions needing to do with the search bar. 
    //When using focus filter, inject this service to access and update data through this service only.

    function searchBarService($state, $rootScope, $mdDialog) {

        var defaultBins = {
            oneMinute: {
                value: "OneMinute",
                range: [undefined, undefined]
            },
            fiveMinute: {
                value: "FiveMinute",
                range: [undefined, undefined]
            },
            fifteenMinute: {
                value: "FifteenMinute",
                range: [0, 2]
            },
            thirtyMinute: {
                value: "ThirtyMinute",
                range: [0, 2]
            },
            hour: {
                value: "Hour",
                range: [0, 8]
            },
            day: {
                value: "Day",
                range: [4, 90]
            }
        };

        function init() {
            //define focus filter object. 
            //default our time frame to yesterday full day if nothing is defined yet

            var today = new Date();
            var yesterdayMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0);
            var todayMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59);


            var searchBar = {
                signal: {
                    signalID: '',
                    description: '',
                    selectedApproaches: [],
                },
                route: {
                    approachRouteId: '',
                    description: '',
                },
                corridor: {
                    corridorID: '',
                    description: '',
                },
                header: {
                    text: "",
                    badgeText: "",
                },
                timeOptions: {
                    daysOfWeek: undefined,
                    daysOfWeekList: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    bin: "Thirty",
                    currentFilter: {
                        startDateAndTime: yesterdayMidnight,
                        endDateAndTime: todayMidnight,
                        selectedTodTemplate: 'FD',
                        selectedDateTemplate: 'YD',
                    },
                    timeOption: "StartToEnd",
                }
            };
            //set default day to yesterday
            setSearchOptionsIntoStorage(searchBar);
            initConfig();
        }

        function initConfig() {
            if (!getConfigFromStorage()) {
                //these will be all the options for the search bar
                var configOptions = {
                    header: {
                        show: true,
                        text: "",
                        badgeText: ""
                    },
                    showSearchBar: true,
                    searchType: 'Signals',
                    showCurrentFilterDates: true,
                    wildcardSearchText: "",
                    helpJsonPropertyPath: "",
                    showHelp: false,
                    showExcel: false,
                    showPdf: false,
                    showExcelRaw: false,
                    showSignalNotificationTabs: false,
                    showApproachesSelection: false,
                    showMonthlyBasedTimeFrame: false,
                    timeFrameConfig: {
                        enableWithoutFiltering: false,
                        defaultDateTemplate: "YD",
                        defaultTodTemplate: "FD",
                        showWeekdayFilter: false,
                        enableAdvancedTod: false,
                        timeOptionForCustomTemplate: "StartToEnd",
                        dateTemplateMinimumDays: 0,
                        minDayRange: 0,
                        maxDayRange: 2,
                    },
                    moreOptionsMenu: {
                        show: true,
                        showBinConfig: true,
                        skipStepsPerBin: false,
                        notifyOnBinChange: true,
                        bins: defaultBins,
                    }
                }
                setConfigIntoStorage(configOptions);
            }
        }


        function setSearchOptionsIntoStorage(obj) {
            sessionStorage.setItem('__search_bar_options', JSON.stringify(obj));
        }

        function getSearchOptionsFromStorage() {
            return JSON.parse(sessionStorage.getItem('__search_bar_options'));
        }

        function getConfigFromStorage() {
            return JSON.parse(sessionStorage.getItem('__search_bar_config'));
        }

        function setConfigIntoStorage(obj) {
            sessionStorage.setItem('__search_bar_config', JSON.stringify(obj));
        }

        function isFiltering() {
            var config = getSearchBarConfig();
            var opts = getSearchOptions();
            var enabled = false;
            if (config && config.searchType && opts) {
                switch (config.searchType) {
                    case "Signals":
                        if (opts.signal && opts.signal.signalID != "")
                            enabled = true;
                        else
                            enabled = false;
                        break;
                    case "Corridors":
                        if (opts.corridor && opts.corridor.corridorID && opts.corridor.corridorID != "")
                            enabled = true;
                        else
                            enabled = false;
                        break;
                    case "Routes":
                        if (opts.route && opts.route.approachRouteId != "")
                            enabled = true;
                        else
                            enabled = false;
                        break;
                    case "Wildcard":
                        enabled = true;
                    case "NoSearch":
                        enabled = true;
                }
            }

            return enabled;
        }

        function clear() {
            sessionStorage.removeItem('__search_bar_options');
        }

        function getSearchOptions() {
            var searchObj = getSearchOptionsFromStorage();
            var token = sessionStorage.getItem('id_token');
            if (!searchObj && token) {
                init();
                searchObj = getSearchOptionsFromStorage();
            }
            if (searchObj && searchObj.timeOptions && searchObj.timeOptions.currentFilter) {
                //set date times
                searchObj.timeOptions.currentFilter.startDateAndTime = new Date(searchObj.timeOptions.currentFilter.startDateAndTime);
                searchObj.timeOptions.currentFilter.endDateAndTime = new Date(searchObj.timeOptions.currentFilter.endDateAndTime);
            }

            return searchObj;
        }

        function getSearchBarConfig() {
            return getConfigFromStorage();
        }

        function setSearchBarConfig(obj) {
            setConfigIntoStorage(obj);
            checkTimeRanges();
            notify("configuration");
        }

        function checkTimeRanges() {
            var options = getSearchOptions();
            var configuration = getSearchBarConfig();

            if (!configuration || !configuration.showSearchBar || !configuration.timeFrameConfig)
                return;

            var selectedDayRange = calculateFilterTimeSpanInDays();

            // Assuming that all dashboards start out with StartToEnd
            options.timeOptions.timeOption = "StartToEnd";
            //if our selected day range is greater than our max day range we need to fix that
            //for example, if user has 1 month selected on a dashboard that allows 1 month, then navigates
            //to a chart that only allows 2 days we will catch that here. 
            //Keep the start date the same, but modify the end date to fit within the max day range limit of the new page
            if (selectedDayRange > configuration.timeFrameConfig.maxDayRange) {
                var start = new Date(options.timeOptions.currentFilter.startDateAndTime);
                var end = new Date(options.timeOptions.currentFilter.endDateAndTime);
                var newEndDate = new Date(start.getFullYear(), start.getMonth(), (start.getDate() + configuration.timeFrameConfig.maxDayRange), end.getHours(), end.getMinutes(), end.getSeconds());

                if (calculateFilterTimeSpanInDays(start, newEndDate) > configuration.timeFrameConfig.maxDayRange) {
                    newEndDate = newEndDate.setDate(newEndDate.getDate() - 1);
                }

                options.timeOptions.currentFilter.endDateAndTime = newEndDate;
                options.timeOptions.currentFilter.selectedDateTemplate = "";

                if (!isFilterSpanGreaterThanOrEqualToDays(7, start, new Date(newEndDate))) {
                    //timeframe is less than 1 week, we do not allow day of week list filtering set to default
                    options.timeOptions.daysOfWeekList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                }

                pauseChangeNotifications();
                setTimeOptions(options.timeOptions);
                resumeChangeNotifications();
            }
            //if we have a minimum day range set for the controller, then check here and modify if needed
            if (configuration.timeFrameConfig.minDayRange && configuration.timeFrameConfig.minDayRange > 0) {
                if (selectedDayRange < configuration.timeFrameConfig.minDayRange) {
                    var start = new Date(options.timeOptions.currentFilter.startDateAndTime);
                    var end = new Date(options.timeOptions.currentFilter.endDateAndTime);
                    //go back number of days of difference between current time span and min days required
                    var daysToGoBack = configuration.timeFrameConfig.minDayRange - selectedDayRange;
                    var newStartDate = new Date(start.getFullYear(), start.getMonth(), (start.getDate()), end.getHours(), end.getMinutes(), end.getSeconds());

                    newStartDate = newStartDate.setDate(newStartDate.getDate() - daysToGoBack);

                    options.timeOptions.currentFilter.startDateAndTime = newStartDate;
                    options.timeOptions.currentFilter.selectedDateTemplate = "";

                    pauseChangeNotifications();
                    setTimeOptions(options.timeOptions);
                    resumeChangeNotifications();
                }
            }

            setCorrectBin();

        }

        function notifyStateChange() {
            notify("state");
        }

        function setSearchOptionsNoNotify(obj) {
            setSearchOptionsIntoStorage(obj);
        }

        function setTimeOptions(timeOptions) {
            var obj = getSearchOptions();
            obj.timeOptions = timeOptions;
            setSearchOptionsIntoStorage(obj);
            setCorrectBin();
            notify("time");
        }

        function setTimeOptionsOnBinChange(timeOptions) {
            var obj = getSearchOptions();
            obj.timeOptions = timeOptions;

            if (obj.timeOptions.currentFilter.selectedTodTemplate == 'CT') {
                var roundedDates = roundTimeToBin(!getSearchBarConfig().moreOptionsMenu.skipStepsPerBin, obj.timeOptions.bin, obj.timeOptions.currentFilter.startDateAndTime, obj.timeOptions.currentFilter.endDateAndTime);
                obj.timeOptions.currentFilter.startDateAndTime = new Date(roundedDates.start);
                obj.timeOptions.currentFilter.endDateAndTime = new Date(roundedDates.end);
                setSearchOptionsNoNotify(obj)
            }

            setSearchOptionsIntoStorage(obj);
            notify("time");
        }

        function setTimeOptionsNoNotify(timeOptions) {
            var obj = getSearchOptions();
            obj.timeOptions = timeOptions;
            setSearchOptionsIntoStorage(obj);
            setCorrectBin();
        }

        function setSignal(signal) {
            var obj = getSearchOptions();

            obj.signal = signal;
            setSearchOptionsIntoStorage(obj);

            notifyStateChange();
            if (signal)
                notify("signal");
        }

        function setWildcardText(value) {
            var obj = getSearchOptions();

            obj.wildcardSearchText = value;
            setSearchOptionsIntoStorage(obj);

            notifyStateChange();
            notify("wildcard");
        }

        function setCorridor(corridor) {
            var obj = getSearchOptions();

            obj.corridor = corridor;
            setSearchOptionsIntoStorage(obj);

            notifyStateChange();

            if (corridor)
                notify("corridor");
        }

        function setRoute(route) {
            var obj = getSearchOptions();

            obj.route = route;
            setSearchOptionsIntoStorage(obj);

            notifyStateChange();

            if (route)
                notify("route");
        }

        function notify(typeOfChange) {
            if (!sessionStorage.getItem('__change_notifications_paused')) {
                $rootScope.$emit('searchbar-change-event', typeOfChange);
            }
        }

        function pauseChangeNotifications() {
            sessionStorage.setItem('__change_notifications_paused', "true");
        }

        function resumeChangeNotifications() {
            sessionStorage.removeItem('__change_notifications_paused');
        }

        function areDaysGreaterOrEqualThanFilterSpan(days, start, end) {
            let calculateFilterTimeSpanInDaysNumber = calculateFilterTimeSpanInDays(start, end);
            return days >= calculateFilterTimeSpanInDaysNumber;
        }

        function isFilterSpanGreaterThanDays(days, start, end) {
            let calculateFilterTimeSpanInDaysNumber = calculateFilterTimeSpanInDays(start, end);
            return days < calculateFilterTimeSpanInDaysNumber;
        }

        function isFilterSpanGreaterThanOrEqualToDays(days, start, end) {
            let calculateFilterTimeSpanInDaysNumber = calculateFilterTimeSpanInDays(start, end);
            return days <= calculateFilterTimeSpanInDaysNumber;
        }

        function isFilterSpanGreaterThanMinutes(mins, start, end) {
            let calculateFilterTimeSpanInMinutesNumber = calculateFilterTimeSpanInMinutes(start, end);
            return mins < calculateFilterTimeSpanInMinutesNumber;
        }

        function calculateFilterTimeSpanInDays(start, end) {
            var filterTime = validateFilterTime(start, end);
            var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return calculateFilterTimeSpan(filterTime.start, filterTime.end, oneDay);
        }

        function calculateFilterTimeSpanInMinutes(start, end) {
            var filterTime = validateFilterTime(start, end);
            var oneMinute = 60 * 1000; //seconds*milliseconds
            return calculateFilterTimeSpan(filterTime.start, filterTime.end, oneMinute);
        }

        // `timeInMs` is in milliseconds (ex: one minute would be 60000)
        function calculateFilterTimeSpan(start, end, timeInMs) {
            var time = Math.abs((start.getTime() - end.getTime()) / (timeInMs));
            var res = Math.ceil(time);
            if (res <= 0) {res = time.toFixed(2);}
            return res;
        }

        function validateFilterTime(start, end) {
            if (!start || !end) {
                var obj = getSearchOptions();
                if (!start) {
                    start = new Date(obj.timeOptions.currentFilter.startDateAndTime);
                }
                if (!end) {
                    end = new Date(obj.timeOptions.currentFilter.endDateAndTime);
                }
            }
            return {start, end};
        }

        function roundTimeToBin(showBin, bin, start, end) {
            start = new Date(start);
            end = new Date(end);
            var roundTo;
            if (getSearchBarConfig().timeFrameConfig.timeOptionForCustomTemplate == 'TimePeriod') {
                roundTo = 60;
            } else {
                if (showBin) {
                    switch (bin) {
                        case "OneMinute":
                        case "FiveMinute":
                            roundTo = 5;
                            break;
                        case "FifteenMinute":
                            roundTo = 15;
                            break;
                        case "ThirtyMinute":
                            roundTo = 30;
                            break;
                        case "Day":
                        case "Hour":
                        default:
                            roundTo = 60;
                            break;
                    }
                } else {
                    roundTo = 5;
                }
            }

            if (start.getMinutes() % roundTo != 0) {
                var roundedMinutes = Math.floor(start.getMinutes() / roundTo) * roundTo;
                start = new Date(start.setMinutes(roundedMinutes));
            }
            if (end.getMinutes() % roundTo != 0 && end.getMinutes() != 59) {
                var roundedMinutes = Math.ceil(end.getMinutes() / roundTo) * roundTo;
                end = new Date(end.setMinutes(roundedMinutes));
            }

            if (getSearchBarConfig().timeFrameConfig.timeOptionForCustomTemplate == 'TimePeriod' && start.getHours() >= end.getHours()) {
                end = new Date(end.setHours(start.getHours() + 1));
            }

            return {
                start: start,
                end: end
            }
        }
        function setCorrectBin() {
            var options = getSearchOptions();
            var configuration = getSearchBarConfig();

            if (!options || !options.timeOptions || !configuration || !configuration.moreOptionsMenu)
                return;
            if (!configuration.moreOptionsMenu.bins)
                configuration.moreOptionsMenu.bins = defaultBins;

            var start = new Date(options.timeOptions.currentFilter.startDateAndTime);
            var end = new Date(options.timeOptions.currentFilter.endDateAndTime);
            var bins = configuration.moreOptionsMenu.bins;


            if (options.timeOptions.currentFilter.selectedTodTemplate == 'CT') {
                var roundedDates = roundTimeToBin(!getSearchBarConfig().moreOptionsMenu.skipStepsPerBin, options.timeOptions.bin, options.timeOptions.currentFilter.startDateAndTime, options.timeOptions.currentFilter.endDateAndTime);
                options.timeOptions.currentFilter.startDateAndTime = new Date(roundedDates.start);
                options.timeOptions.currentFilter.endDateAndTime = new Date(roundedDates.end);
                setSearchOptionsNoNotify(options);
            }

            if (!shouldBinBeVisible(options.timeOptions.bin)) {
                options.timeOptions.bin = getMaxBin(bins, start, end)

                pauseChangeNotifications();
                setTimeOptions(options.timeOptions);
                resumeChangeNotifications();
            }
        }

        function getMaxBin(bins, start, end) {
            var res = "";
            if (shouldDayBinBeVisible(bins,start,end)) {
                res = "Day";
            } else if (shouldHourBinBeVisible(bins,start,end)) {
                res = "Hour";
            } else if (shouldThirtyMinuteBinBeVisible(bins,start,end)) {
                res = "ThirtyMinute"
            } else if (shouldFifteenMinuteBinBeVisible(bins,start,end)) {
                res = "FifteenMinute";
            } else if (shouldFiveMinuteBinBeVisible(bins,start,end)) {
                res = "FiveMinute";
            } else if (shouldOneMinuteBinBeVisible(bins,start,end)) {
                res = "OneMinute";
            }
            return res;
        }

        function shouldDayBinBeVisible(bins, start, end) {
            return bins.day && isFilterSpanGreaterThanOrEqualToDays(bins.day.range[0], start, end)
        }

        function shouldHourBinBeVisible(bins, start, end) {
            return bins.hour && isFilterSpanGreaterThanMinutes(60, start, end) &&
            areDaysGreaterOrEqualThanFilterSpan(bins.hour.range[1], start, end);
        }

        function shouldThirtyMinuteBinBeVisible(bins, start, end) {
            return bins.thirtyMinute && isFilterSpanGreaterThanMinutes(30, start, end) &&
            areDaysGreaterOrEqualThanFilterSpan(bins.thirtyMinute.range[1], start, end);
        }

        function shouldFifteenMinuteBinBeVisible(bins, start, end) {
            return bins.fifteenMinute && isFilterSpanGreaterThanMinutes(15, start, end) &&
            areDaysGreaterOrEqualThanFilterSpan(bins.fifteenMinute.range[1], start, end);
        }

        function shouldFiveMinuteBinBeVisible(bins, start, end) {
            return bins.fiveMinute && isFilterSpanGreaterThanMinutes(15, start, end) &&
            areDaysGreaterOrEqualThanFilterSpan(bins.fiveMinute.range[1], start, end);
        }

        function shouldOneMinuteBinBeVisible(bins, start, end) {
            return bins.oneMinute && isFilterSpanGreaterThanMinutes(15, start, end) &&
            areDaysGreaterOrEqualThanFilterSpan(bins.oneMinute.range[1], start, end);
        }

        function shouldBinBeVisible(bin) {
            var res = false;
            var options = getSearchOptions();
            var configuration = getSearchBarConfig();

            if (!options || !options.timeOptions || !configuration || !configuration.moreOptionsMenu)
                return res;
            if (!configuration.moreOptionsMenu.bins)
                configuration.moreOptionsMenu.bins = defaultBins;

            var start = new Date(options.timeOptions.currentFilter.startDateAndTime);
            var end = new Date(options.timeOptions.currentFilter.endDateAndTime);
            var declaredBins = configuration.moreOptionsMenu.bins
            switch (bin) {
                case "Day":
                    if (shouldDayBinBeVisible(declaredBins, start, end)) {
                        res = true;
                    }
                    break;
                case "Hour":
                    if (shouldHourBinBeVisible(declaredBins, start, end)) {
                        res = true;
                    }
                    break;
                case "ThirtyMinute":
                    if (shouldThirtyMinuteBinBeVisible(declaredBins, start, end)) {
                        res = true;
                    }
                    break;
                case "FifteenMinute":
                    if (shouldFifteenMinuteBinBeVisible(declaredBins, start, end)) {
                        res = true;
                    }
                    break;
                case "FiveMinute":
                    if (shouldFiveMinuteBinBeVisible(declaredBins, start, end)) {
                        res = true;
                    }
                    break;
                case "OneMinute":
                    if (shouldOneMinuteBinBeVisible(declaredBins, start, end)) {
                        res = true;
                    }
                    break;
            }
            return res;
        }

        return {
            clear: clear,
            getSearchOptions: getSearchOptions,
            isFiltering: isFiltering,
            setTimeOptions: setTimeOptions,
            setSignal: setSignal,
            getSearchBarConfig: getSearchBarConfig,
            setSearchBarConfig: setSearchBarConfig,
            setCorridor: setCorridor,
            setRoute: setRoute,
            isFilterSpanGreaterThanDays: isFilterSpanGreaterThanDays,
            isFilterSpanGreaterThanOrEqualToDays: isFilterSpanGreaterThanOrEqualToDays,
            calculateFilterTimeSpanInDays: calculateFilterTimeSpanInDays,
            pauseChangeNotifications: pauseChangeNotifications,
            resumeChangeNotifications: resumeChangeNotifications,
            shouldBinBeVisible: shouldBinBeVisible,
            setCorrectBin: setCorrectBin,
            roundTimeToBin: roundTimeToBin,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('searchbar-change-event', callback);
                scope.$on('$destroy', handler);
            },
            subscribeToOptionsApply: function (scope, callback) {
                var handler = $rootScope.$on('applyOptions', callback);
                scope.$on('$destroy', handler);
            },
            setWildcardText: setWildcardText,
            setSearchOptionsNoNotify: setSearchOptionsNoNotify,
            setTimeOptionsNoNotify: setTimeOptionsNoNotify,
            setTimeOptionsOnBinChange: setTimeOptionsOnBinChange,
            areDaysGreaterOrEqualThanFilterSpan: areDaysGreaterOrEqualThanFilterSpan,            
        }
    }
})();
