﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("splitFailureChartController", splitFailureChartController);

    function splitFailureChartController($state, $scope, $stateParams, insightsService, signalService, environmentConfig, chartsService, $timeout, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 1;
        // vm.selectedIndex = 0;
        vm.chartLoading = false;
        vm.isDataAvailable = false;
        vm.chartServerError = false;
        vm.init = init;
        //set events and params
        vm.queryData = {
            orderBy: 'protectedPhaseNumber',
            pageSize: 20,
            pageIndex: 1,
            filter: '',
        };

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input != "{}") {
                    var jsonData = JSON.stringify(input, null, 4);
                    $scope.loading = false;
                    vm.chartLoading = true;
                    vm.splitFailureWidget.processSplitFailureData(JSON.parse(jsonData, JSON.dateParser));
                }
            }
        }


        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        function init() {

            vm.focusApproachId = $stateParams.inputParameters.focusApproachId;
            vm.queryData.ID = $scope.signal.signalID;
            if (vm.focusApproachId) {
                signalService.getApproaches(vm.queryData)
                    .then(function (data) {
                        data.approaches.forEach(function (approach) {
                            if (approach.approachID == vm.focusApproachId) {
                                vm.selectedApproach = approach;

                                if (vm.selectedApproach && vm.selectedApproach.direction) {
                                    vm.investigatedIndex = vm.selectedApproach.directionTypeID - 1;
                                    vm.selectedIndex = vm.investigatedIndex;
                                }
                            }
                        })

                    })
            } else {
                vm.selectedIndex = 0;
            }
        }

        vm.splitFailureWidget = {
            selectedTabChange: function (index, e) {
                // var options = searchBarService.getSearchOptions();
                // var startDate = new Date(options.timeOptions.currentFilter.startDateAndTime).getDate();
                // var startPlus1Day = new Date(options.timeOptions.currentFilter.startDateAndTime).setDate(startDate + 1);
                // var showLoad = new Date(options.timeOptions.currentFilter.endDateAndTime).getDate() > new Date(startPlus1Day).getDate();

                // if (vm.selectedIndex != index && showLoad) {
                //     vm.chartLoading = true;
                //     $timeout(function () {
                //         vm.chartLoading = false;
                // }, 1500);
                // }

                // if (vm.investigatedIndex) {
                //     this.selectedIndex = vm.investigatedIndex;
                // } else {
                // if (vm.investigatedIndex !== vm.selectedIndex) {
                //     vm.selectedIndex = index;
                // }
                // }
            },
            addPlanLabels: function (primaryPlans) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        plan.labels.push(planText, "Split Failures: " + plan.failsInPlan, "Total Cycles: " + plan.totalCycles);
                        // getPlanAnnotations(primaryPlans);
                        plansCopy[i] = plan;
                    }
                }
                return plansCopy;
            },
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.splitFailureWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            //logic for taking server events and manipulating into format the chart is expecting
            processSplitFailureData: function (rawData) {
                var data = [],
                    shapes = ['circle', 'rectRot', 'rect', 'rectRot', 'rectRot', 'rect'];
                var group = "";
                var tabData = [];
                var phaseCount = 0;
                var totalSplitFailures = 0;
                rawData.summaryData.length > 0 ? vm.isDataAvailable = true : vm.isDataAvailable = false;

                rawData.summaryData.forEach(function (phaseItem) {
                    var phaseNum = phaseItem.phaseNumber;
                    var planList = phaseItem.plans;
                    phaseCount++;
                    var groupedData = {};

                    //add detector specific info
                    for (var key in phaseItem.gorPerDetector) {
                        group = "GOR Det Chan" + key;
                        groupedData[group] = {
                            key: group,
                            label: group,
                            data: [],
                            type: 'line',
                            yAxisID: '1',
                            pointRadius: 3,
                            xAxisID: 'x-axis-0',
                            strokeWidth: 2,
                            disabled: true,
                        };
                        phaseItem.gorPerDetector[key].forEach(function (gorDet) {
                            groupedData[group].data.push({
                                x: new Date(gorDet.x),
                                y: gorDet.y
                            });
                        });
                    }
                    //add the GOR Gap out chart data
                    if (chartsService.getChartComplexity() == "Advanced") {
                        group = "GOR - Gap Out";
                        groupedData[group] = {
                            key: group,
                            label: group,
                            data: [],
                            type: 'scatter',
                            yAxisID: '1',
                            backgroundColor: chartsService.colors.signalGreen,
                            pointStyle: shapes[0],
                        };
                        phaseItem.gorGapOut.forEach(function (gorGapOut) {
                            if (gorGapOut.y > 0) {
                                groupedData[group].data.push({
                                    x: new Date(gorGapOut.x),
                                    y: gorGapOut.y,
                                });
                            }
                        });
                    }

                    if (chartsService.getChartComplexity() == "Advanced") {
                        //add the ROR Gap out data
                        group = "ROR - Gap Out";
                        groupedData[group] = {
                            key: group,
                            label: group,
                            data: [],
                            type: 'scatter',
                            yAxisID: '1',
                            backgroundColor: chartsService.colors.signalRed,
                            pointStyle: shapes[1],
                        };
                        phaseItem.rorGapOut.forEach(function (rorGapOut) {
                            if (rorGapOut.y > 0) {
                                groupedData[group].data.push({
                                    x: new Date(rorGapOut.x),
                                    y: rorGapOut.y,
                                });
                            }
                        });
                    }
                    //add the GOR Force Off data

                    if (chartsService.getChartComplexity() == "Advanced") {
                        group = "GOR - Force Off";
                        groupedData[group] = {
                            key: group,
                            label: group,
                            data: [],
                            type: 'scatter',
                            yAxisID: '1',
                            backgroundColor: chartsService.colors.signalGreen,
                            pointStyle: shapes[3],
                        };
                        phaseItem.gorForceOff.forEach(function (gorForceOff) {
                            if (gorForceOff.y > 0) {
                                groupedData[group].data.push({
                                    x: new Date(gorForceOff.x),
                                    y: gorForceOff.y,
                                });
                            }
                        });
                    }
                    //add the ROR Force Off data

                    if (chartsService.getChartComplexity() == "Advanced") {
                        group = "ROR - Force Off";
                        groupedData[group] = {
                            key: group,
                            label: group,
                            data: [],
                            type: 'scatter',
                            yAxisID: '1',
                            backgroundColor: chartsService.colors.signalRed,
                            pointStyle: shapes[2],
                        };
                        phaseItem.rorForceOff.forEach(function (rorForceOff) {
                            if (rorForceOff.y > 0) {
                                groupedData[group].data.push({
                                    x: new Date(rorForceOff.x),
                                    y: rorForceOff.y,
                                });
                            }
                        });
                    }

                    //add the avg GOR data
                    group = "Avg. GOR";
                    groupedData[group] = {
                        key: group,
                        label: group,
                        data: [],
                        type: 'LineWithLine',
                        fill: false,
                        yAxisID: '1',
                        pointRadius: 3,
                        xAxisID: 'x-axis-0',
                        strokeWidth: 3,
                        strokeColor: chartsService.colors.signalGreen,
                        borderColor: chartsService.colors.signalGreen,
                        pointBackgroundColor: chartsService.colors.signalGreen,
                    };
                    phaseItem.avgGor.forEach(function (avgGor) {
                        groupedData[group].data.push({
                            x: new Date(avgGor.x),
                            y: avgGor.y
                        });
                    });

                    //add the ROR Force Off data
                    group = "Avg. ROR";
                    groupedData[group] = {
                        key: group,
                        label: group,
                        data: [],
                        type: 'LineWithLine',
                        fill: false,
                        pointRadius: 3,
                        xAxisID: 'x-axis-0',
                        yAxisID: '1',
                        strokeWidth: 3,
                        strokeColor: chartsService.colors.signalRed,
                        borderColor: chartsService.colors.signalRed,
                        pointBackgroundColor: chartsService.colors.signalRed,
                    };
                    phaseItem.avgRor.forEach(function (avgRor) {
                        groupedData[group].data.push({
                            x: new Date(avgRor.x),
                            y: avgRor.y
                        });
                    });

                    //add the percent fails
                    group = "Percent Fails";
                    groupedData[group] = {
                        key: group,
                        label: group,
                        data: [],
                        type: 'LineWithLine',
                        borderDash: [10, 5],
                        yAxisID: '1',
                        fill: false,
                        pointRadius: 3,
                        xAxisID: 'x-axis-0',
                        strokeWidth: 3,
                        classed: 'dashed-line',
                        strokeColor: 'rgb(255, 187, 120)',
                        borderColor: 'rgb(255, 187, 120)',
                        pointBackgroundColor: 'rgb(255, 187, 120)',
                    };
                    phaseItem.percentFails.forEach(function (percentFails) {
                        groupedData[group].data.push({
                            x: new Date(percentFails.x),
                            y: percentFails.y
                        });
                    });

                    //add the split failures
                    group = "Split Failures";
                    groupedData[group] = {
                        key: group,
                        label: group,
                        data: [],
                        type: 'bar',
                        maxBarThickness: 1.5,
                        yAxisID: '1',
                        hideTooltip: true,
                        backgroundColor: 'rgb(255, 187, 120)',
                        borderColor: 'rgb(255, 187, 120)',
                        fillOpacity: 1
                    };
                    phaseItem.splitFailures.forEach(function (splitFailures) {
                        if (splitFailures.y > 0) {
                            groupedData[group].data.push({
                                x: new Date(splitFailures.x),
                                y: splitFailures.y
                            });
                        }
                    });

                    //Create the chart object
                    var groupArr = [];
                    for (group in groupedData) {
                        groupArr.push(groupedData[group]);
                    }

                    //see if we have added other charts for this direction and get the obj
                    var directionTabObj = tabData.find(function (x) { return x.tabTitle == phaseItem.phaseDirection });
                    if (!directionTabObj) {
                        directionTabObj = {
                            tabTitle: phaseItem.phaseDirection,
                            charts: [],
                            isChart: true
                        }
                        tabData.push(directionTabObj);
                    }

                    var phaseTypeText = phaseItem.isPermissive ? "Permissive" : "Protected";
                    var options = searchBarService.getSearchOptions();

                    directionTabObj.charts.push({
                        title: "Purdue Split Failure",
                        titleLine2: $scope.signal.description + " - " + phaseItem.phaseDirection + " " + phaseItem.movement + " - " + phaseTypeText + " Phase " + phaseItem.phaseNumber,
                        subLine1: "Total Split Failures: " + phaseItem.splitFailures.length,
                        tabTitle: phaseItem.phaseDirection,
                        dataset: groupArr,
                        planColor: "#000",
                        plans: vm.splitFailureWidget.addPlanLabels(planList),
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },

                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                            this.plans.visible = false;
                            if (chart.plansHidden) {
                                chart.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                chart.planColor = "#fff";
                            } else {
                                chart.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                chart.planColor = "#000";
                            }
                            chart.api.update();
                        },
                        isRendered: false,
                        api: {},
                        flex: 100,
                        isDataAvailable: groupArr.length > 0,
                        chartOptions: {
                            hidePlans: false,
                            useCrosshair: true,
                            responsive: true,
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    // time: {
                                    //     unit: 'hour'
                                    // },
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                        minRotation: 0,
                                        maxRotation: 0,
                                    }
                                },
                                ],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Phase Duration (seconds)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    id: '1',
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    // stacked: true,
                                    // type: 'linear',
                                    position: 'left',

                                }
                                ]
                            },
                            legend: {
                                display: true,
                                align: 'end',
                            },
                            // hover: {
                            //     mode: 'x-axis',
                            //     intersect: false
                            // },
                            tooltips: {
                                mode: 'x',
                                intersect: false,
                                enabled: false,
                                custom: function (tooltipModel) {
                                    var tooltipEl = document.getElementById('chartjs-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    function getBody(bodyItem) {
                                        return bodyItem.lines;
                                    }

                                    if (tooltipModel.body) {
                                        var titleLines = tooltipModel.title[0];
                                        var header =
                                            "<thead>" +
                                            "<tr>" +
                                            "<td class='key'><strong>" + titleLines + "</strong></td>" +
                                            "</tr>" +
                                            "</thead>";
                                        var rows = "";

                                        phaseItem.plans.forEach(function (plan, i) {
                                            if (plan.labels && plan.labels.length > 0 && new Date(plan.startTime).getTime() < new Date(tooltipModel.title[0]).getTime() &&
                                                new Date(tooltipModel.title[0]).getTime() < new Date(plan.endTime).getTime()) {
                                                rows += "<tr>" +
                                                    "<td class='x-value'><strong>" + (plan.labels[0] == "Free" ?
                                                        ("Plan: " + plan.labels[0]) : plan.labels[0])
                                                    + "</strong></td>" +
                                                    "</tr>";
                                            }
                                        });

                                        var hasPercentFails = tooltipModel.body.some(x => x.lines[0].includes('Percent'));

                                        tooltipModel.dataPoints.forEach(function (point, i) {
                                            if (point.label == titleLines) {
                                                var bgColor = "";
                                                if (tooltipModel.body[i].lines[0].includes('GOR')) {
                                                    bgColor = chartsService.colors.signalGreen;
                                                } else if (tooltipModel.body[i].lines[0].includes('ROR')) {
                                                    bgColor = chartsService.colors.signalRed;
                                                } else {
                                                    bgColor = 'rgb(255, 187, 120)';
                                                }

                                                var content = tooltipModel.body[i].lines[0].includes('Percent') ? (tooltipModel.body[i].lines[0] + "%") : tooltipModel.body[i].lines[0];

                                                rows += "<tr style='display: flex; align-items: center; justify-content: flex-start;'>" +
                                                    "<td><div style='width: 8px; height: 8px; background-color: " + bgColor + "; padding: 0px;'></div></td>" +
                                                    "<td style='padding: 0px;' class='x-value'><strong>" + content + "</strong></td>" +
                                                    "</tr>";
                                            }
                                        });

                                        if (!hasPercentFails) {
                                            rows
                                        }

                                        var innerHTML = "<table>" +
                                            header +
                                            "<tbody>" +
                                            rows +
                                            "</tbody>" +
                                            "</table>";

                                        var tableRoot = tooltipEl.querySelector('table');
                                        var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                        tableStyle += 'color: rgba(255,255,255, 1);';
                                        tableStyle += 'padding: 5px 10px;';

                                        tableRoot.style = tableStyle;
                                        tableRoot.innerHTML = innerHTML;
                                        vm.ttRows = "";
                                    }


                                    // `this` will be the overall tooltip
                                    var position = this._chart.canvas.getBoundingClientRect();
                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                    if (position.left + window.pageXOffset + tooltipModel.caretX + 255 > window.innerWidth) {
                                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 100 + 'px';
                                    }
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                                // filter(item, data) {
                                //     // const currentIndex = this.tooltip._active[0]._index;
                                //     // return item.index === currentIndex;
                                // }
                                // filter: function (tooltipItem, data) {
                                //     var test = tooltipItem;
                                //     // if (!tooltip.dataPoints || tooltip.dataPoints.length == 0) return;
                                //     // for(let i = 0; i < tooltip.dataPoints.length; i++) {
                                //     //     if (tooltip.title != tooltip.dataPoints[i].label) {
                                //     //         tooltip.dataPoints[i] = undefined;
                                //     //         tooltip.body[i] = undefined;
                                //     //     }
                                //     // }
                                // }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: options.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: options.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: options.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: options.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                        }
                    });
                });

                vm.splitFailureWidget.chartArray = tabData;
                vm.chartLoading = false;
                vm.chartRendering = true;
                if ($stateParams && $stateParams.inputParameters) {
                    vm.init();
                }
            }
        }
        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.splitFailureWidget.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions)
                .then(function (data) {
                    vm.chartServerError = false;
                    $scope.loading = false;
                    vm.chartLoading = true;
                    //JSON stringify the server data and then process it for the chart
                    // vm.rawData = JSON.stringify(data, null, 4);
                    vm.splitFailureWidget.processSplitFailureData(data);
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;

                });
        }
    }
}());
