(function () {
    "use strict";

    angular.module("app.spm.core")
        .factory("signalPerformanceResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            var signalPerfUrl = environmentConfig.apiUrl + "/api/signalPerformance/";
            return $resource(signalPerfUrl, {}, {
                getForSignal: { method: "GET", url: signalPerfUrl + "signal?", cache: true },
                getForApproach: { method: "GET", url: signalPerfUrl + "approach?" },
                getForPhase: { method: "GET", url: signalPerfUrl + "phase?" },
                getExecutiveInformation: { method: "GET", url: signalPerfUrl + "executive?"}
            });
        }]);
}());
