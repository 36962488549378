angular.module('app.spm.core')
    .factory('googleMapService', ['$rootScope', function ($rootScope) {
        var service = {
            model: {
                zoomLevel: null,
                mapTypeId: null,
                bounds: null,
                center: null,
                streetView: null,
                traffic: null,
                streetCenter: null,
            },

            SaveState: function () {
                sessionStorage.googleMapService = angular.toJson(service.model);
            },
            RestoreState: function () {
                service.model = angular.fromJson(sessionStorage.googleMapService);
            }
        }
        $rootScope.$on("savestate", service.SaveState);
        $rootScope.$on("restorestate", service.RestoreState);
    
        // var currentSignal;

        // function zoomToSignal(map, signal){
        //     map.setCenter(new google.maps.LatLng(signal.latitude, signal.longitude));
        //     map.setZoom(19);
        // }

        // function setSelectedSignal(signal){
        //     currentSignal = signal;
        // }

        return service;
        
    }]);