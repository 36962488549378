﻿import * as d3v5 from './../../../../../../custom-plugins/d3v5';

(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("yellowRedCanvasChartController", yellowRedCanvasChartController);

    function yellowRedCanvasChartController($state, $attrs, $timeout, $scope, $rootScope, $element, environmentConfig, chartsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 11;
        vm.isDataAvailable = false;

        vm.chartLoading = false;
        vm.chartRendering = false;
        vm.chartServerError = false;

        vm.searchBarService = searchBarService;
        vm.chartsService = chartsService;

        vm.planBackgrounds = [];

        // Time Formatting Functions
        vm.minutesLeadingZeros = minutesLeadingZeros;
        vm.formatDate = formatDate;
        vm.formatDatePrecise = formatDatePrecise;
        vm.formatDateOneHour = formatDateOneHour;
        vm.secondsLeadingZeros = secondsLeadingZeros;
        vm.tConvert = tConvert;
        vm.tConvertPrecise = tConvertPrecise;


        vm.getChartbySelectedTab = getChartbySelectedTab;
        vm.createPlanAnnotations = createPlanAnnotations;

        function createPlanAnnotations(phaseItem, hidePlans) {
            var annotations = [];
            for (let i = 0; i < phaseItem.plans.length; i++) {
                //create annotation for each plan
                var fillStyle;
                var thisPlan = phaseItem.plans[i];

                var odd = i % 2;
                if (hidePlans || odd) {
                    fillStyle = 'rgba(238, 238, 238, 0)';
                }
                else {
                    fillStyle = 'rgba(0,0,0, 0.1)';
                }

                var planStartTime = new Date(thisPlan.startTime).getTime();
                var planEndTime = new Date(thisPlan.endTime).getTime();

                var planAnnotation = {
                    id: 'plan-' + i + "-" + thisPlan.planNumber, // optional
                    type: 'box',
                    drawTime: 'afterDatasetsDraw',
                    // ID of the X scale to bind onto
                    xScaleID: 'x-axis-0',
                    // ID of the Y scale to bind onto
                    yScaleID: '1',
                    xMin: planStartTime,
                    borderColor: 'transparent',
                    xMax: planEndTime,
                    yMin: 0,
                    backgroundColor: fillStyle,
                }
                annotations.push(planAnnotation);
            }

            return annotations;
        }

        if (!$scope.fetchData) {
            $scope.updateData = function (data) {
                if (data && data != "{}") {
                    var jsonData = JSON.stringify(data, null, 4);

                    $scope.loading = false;
                    vm.chartLoading = true;
                    vm.rlrChartWidget.processRLRData(JSON.parse(jsonData, JSON.dateParser));
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        // ==================================== FORMATING TIME ======================================= 
        function tConvert(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }

        function tConvertPrecise(time) {
            // Check correct time format and split into components
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) { // If time format correct
                time = time.slice(1);  // Remove full string match value
                vm.amOrPm = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join(''); // return adjusted time or original string
        }

        function minutesLeadingZeros(dt) {
            return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
        }

        function secondsLeadingZeros(dt) {
            return (dt.getSeconds() < 10 ? '0' : '') + dt.getSeconds();
        }

        function formatDate(d) {
            var formatedHour = (new Date(d).getHours() < 10 ? '0' : '') + new Date(d).getHours();
            return d3v5.timeFormat("%x")(new Date(d)) + " " + vm.tConvert(formatedHour + ":" + vm.minutesLeadingZeros(new Date(d)));
        }
        function formatDatePrecise(d) {
            var formatedHour = (new Date(d).getHours() < 10 ? '0' : '') + new Date(d).getHours();
            return d3v5.timeFormat("%x")(new Date(d)) + " " + vm.tConvertPrecise(formatedHour + ":" + vm.minutesLeadingZeros(new Date(d))) + ":" + vm.secondsLeadingZeros(new Date(d)) + "." + new Date(d).getMilliseconds() + vm.amOrPm;
        }

        function formatDateOneHour(d) {
            var formatedHour = (new Date(d).getHours() < 10 ? '0' : '') + new Date(d).getHours();
            return vm.tConvert(formatedHour + ":" + vm.minutesLeadingZeros(new Date(d)));
        }
        // ===========================================================================================

        vm.rlrChartWidget = {
            //selected Direction tab
            selectedTabChange: function (index, direction) {
                vm.rlrChartWidget.selectedDirection = direction;
            },
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.rlrChartWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            addPlanLabels: function (plans) {
                var plansCopy = Object.create(plans)
                for (let i = 0; i < plansCopy.length; i++) {
                    var plan = plansCopy[i];
                    plan.labels = [];

                    //add plan header
                    var planText = "";
                    switch (plan.planNumber) {
                        case 254:
                            planText = "Free";
                            break;
                        case 255:
                            planText = "Flash";
                            break;
                        default:
                            planText = "Plan " + plan.planNumber;
                            break;
                    }
                    plan.labels.push(planText, "Severe Red Light Violations: " + plan.severeRedLightViolations, "Number of Red Violations: " + plan.violations,
                        "Volume: " + plan.totalVolume);
                    plansCopy[i] = plan;
                }
                return plansCopy;
            },
            isRendered: false,
            selectedDirection: "",
            //holds each chart data
            chartArray: {},
            //logic for taking server events and manipulating into format the chart is expecting
            processRLRData: function (rawData) {
                rawData.length > 0 ? vm.isDataAvailable = true : vm.isDataAvailable = false;

                var charts = {};
                var tabIndex = 0;
                var options = vm.chartsService.getYellowRedOptions();
                var searchOptions = vm.searchBarService.getSearchOptions();
                vm.currentBin = searchOptions.timeOptions.bin;
                vm.redLightRunnersTitle = 'Hourly Total';

                switch (vm.currentBin) {
                    case "FifteenMinute":
                        vm.redLightRunnersTitle = '15 Min Total';
                        vm.binSize = '15 Minutes';
                        vm.binInMilliseconds = 15 * 60 * 1000;
                        break;
                    case "ThirtyMinute":
                        vm.redLightRunnersTitle = '30 Min Total';
                        vm.binSize = '30 Minutes';
                        vm.binInMilliseconds = 30 * 60 * 1000;
                        break;
                    case "Hour":
                    default:
                        vm.redLightRunnersTitle = 'Hourly Total';
                        vm.binSize = 'One Hour'
                        vm.binInMilliseconds = 60 * 60 * 1000;
                        break;
                }
                vm.severityLevel = options.severeLevelSeconds;
                //rawData is an array holding each phase data
                rawData.forEach(function (phaseItem) {
                    var allPhaseChartDataset = [];
                    var chartData = [];
                    if (!charts[phaseItem.direction]) {
                        charts[phaseItem.direction] = [];
                    }
                    //setup phase info object for later use on tooltips
                    //and to store the green/yellow/red/detector data 
                    var phaseInfo = {
                        direction: phaseItem.direction,
                        totalVolume: phaseItem.totalVolume,
                        totalYellowTime: phaseItem.totalYellowTime,
                        percentViolations: phaseItem.percentViolations,
                        percentSevereViolations: phaseItem.percentSevereViolations,
                        totalViolationTime: phaseItem.totalViolationTime,
                        severeRedLightViolations: phaseItem.severeRedLightViolations,
                        averageTRLV: phaseItem.averageTRLV,
                        averageTYLO: phaseItem.averageTYLO,
                        cycleCount: phaseItem.cycleCount,
                        isPermissive: phaseItem.isPermissive,
                    };

                    if (!phaseItem || !phaseItem.cycleData)
                        return;

                    // Arrays for charts
                    let yellowEnd = [], redClearanceEnd = [], redEnd = [], detectorActivation = [], severeActivation = [], planAnnotations = [];

                    // Arrays for matching tooltip data with chart data
                    let detectorChannels = [], laneNumbers = [], detectorActivationTimes = [], severeActivationTimes = [],
                        cycleData = [], redClearanceViolations = [], yellowClearanceViolations = [], redViolations = [],
                        binDates = [], redLightRunners = [];

                    let rlrRadius = [];

                    let averageYellowTime = 0, averageRedClearanceTime = 0, averageRedTime = 0;
                    vm.maxRedLightRunnerY = 0;

                    for (let i = 0; i < phaseItem.bins.length; i++) {

                        let bin = phaseItem.bins[i]
                        if (bin.totalViolations > vm.maxRedLightRunnerY)
                            vm.maxRedLightRunnerY = bin.totalViolations;

                        rlrRadius.push(2)
                        binDates.push({ binStart: new Date(bin.startTime).getTime(), binEnd: new Date(bin.endTime).getTime() })
                        //red data is from cycle start to when green starts
                        // if (i == 0) {
                        //     redLightRunners.push({
                        //         x: new Date(bin.startTime.getTime()),
                        //         y: bin.totalViolations
                        //     });
                        // } else if (i == phaseItem.bins.length - 1) {
                        //     redLightRunners.push({
                        //         x: new Date(bin.endTime.getTime()),
                        //         y: bin.totalViolations
                        //     });
                        // } else {
                        redLightRunners.push({
                            x: new Date((new Date(bin.startTime).getTime() + new Date(bin.endTime).getTime()) / 2),
                            y: bin.totalViolations
                        });
                        // }
                    }

                    planAnnotations = vm.createPlanAnnotations(phaseItem);

                    let count = 0;
                    for (let cycleDay in phaseItem.cycleData.cycleCollection) {
                        let oneDayCycles = phaseItem.cycleData.cycleCollection[cycleDay];
                        for (let i = 0; i < oneDayCycles.length; i++) {
                            count++;
                            averageYellowTime += oneDayCycles[i].yellowTime;
                            averageRedClearanceTime += oneDayCycles[i].redClearanceTime;
                            averageRedTime += oneDayCycles[i].redTime;
                        }
                    }

                    averageYellowTime = averageYellowTime / count;
                    averageRedClearanceTime = averageRedClearanceTime / count;
                    averageRedTime = averageRedTime / count;

                    for (let cycleDay in phaseItem.cycleData.cycleCollection) {
                        let oneDayCycles = phaseItem.cycleData.cycleCollection[cycleDay];
                        let dayStartTime = new Date(cycleDay);

                        // var plan1 = phaseItem.plans[0];
                        // let firstCycle = oneDayCycles[0];
                        // var firstCycleStartTime = (new Date(dayStartTime.getTime() + firstCycle.cycleStart))

                        // if(firstCycleStartTime.getTime() > plan1.startTime.getTime()){                                
                        //     yellowEnd.push({
                        //         x: plan1.startTime.getTime(),
                        //         y: plan1.yellowTime
                        //     });
                        //     redClearanceEnd.push({
                        //         x: plan1.startTime.getTime(),
                        //         y: plan1.redClearanceTime,
                        //     });
                        //     redEnd.push({
                        //         x: plan1.startTime.getTime(),
                        //         y: plan1.yellowTime + plan1.redClearanceTime + 1
                        //     });
                        // }

                        for (let i = 0; i < oneDayCycles.length; i++) {
                            let thisCycle = oneDayCycles[i];
                            var cycleStartTime = new Date(dayStartTime.getTime() + thisCycle.cycleStart);

                            yellowEnd.push({
                                x: cycleStartTime,
                                y: (thisCycle.yellowTime / 1000),
                            });
                            redClearanceEnd.push({
                                x: cycleStartTime,
                                y: ((thisCycle.redClearanceTime) / 1000),
                            });
                            redEnd.push({
                                x: cycleStartTime,
                                y: 15
                            });

                            cycleData.push({
                                red: (thisCycle.yellowTime + thisCycle.redClearanceTime) / 1000,
                                redClearance: thisCycle.redClearanceTime / 1000,
                                yellowClearance: thisCycle.yellowTime / 1000,
                                cycleStart: dayStartTime.getTime() + thisCycle.cycleStart,
                                cycleEnd: cycleStartTime.getTime() + (thisCycle.yellowTime + thisCycle.redClearanceTime + thisCycle.redTime + thisCycle.greenTime) * 1000
                            })
                            redClearanceViolations.push(thisCycle.totalViolations - thisCycle.totalSevereViolations)
                            yellowClearanceViolations.push(thisCycle.totalYellowOccurrences)
                            redViolations.push(thisCycle.totalSevereViolations)

                            if (thisCycle.detectorActivations.length > 0) {
                                for (let k = 0; k < thisCycle.detectorActivations.length; k++) {
                                    laneNumbers.push(thisCycle.detectorActivations[k].laneNumber);
                                    detectorChannels.push(thisCycle.detectorActivations[k].detectorChannel);
                                    if (thisCycle.detectorActivations[k].secondsAfterYellow < ((averageYellowTime / 1000) + vm.severityLevel)) {
                                        detectorActivationTimes.push(dayStartTime.getTime() + thisCycle.cycleStart + thisCycle.detectorActivations[k].secondsAfterYellow);
                                        detectorActivation.push({
                                            x: new Date(dayStartTime.getTime() + thisCycle.cycleStart + thisCycle.detectorActivations[k].secondsAfterYellow),
                                            y: thisCycle.detectorActivations[k].secondsAfterYellow
                                        });
                                    } else if (thisCycle.detectorActivations[k].secondsAfterYellow <= 15 && thisCycle.detectorActivations[k].secondsAfterYellow >= ((averageYellowTime / 1000) + vm.severityLevel)) {
                                        severeActivationTimes.push(dayStartTime.getTime() + thisCycle.cycleStart + thisCycle.detectorActivations[k].secondsAfterYellow);
                                        severeActivation.push({
                                            x: new Date(dayStartTime.getTime() + thisCycle.cycleStart + thisCycle.detectorActivations[k].secondsAfterYellow),
                                            y: thisCycle.detectorActivations[k].secondsAfterYellow
                                        });
                                    }
                                }
                            }
                        }
                    }

                    let arrivalSecondaryColor = "#1e88e5";
                    let platoonColor = arrivalSecondaryColor;


                    allPhaseChartDataset.push({
                        label: "Yellow Clearance",
                        type: 'bar',
                        yAxisID: '1',
                        data: yellowEnd,
                        disableUnchecking: true,
                        backgroundColor: 'rgb(255,250,228)', //'#cc3232',
                        hoverBackgroundColor: 'rgb(255,250,228)', //'#cc3232',
                        hoverBorderColor: 'rgb(255,250,228)',
                        borderColor: 'rgb(255,250,228)',
                        fillColor: 'rgb(255,250,228)',
                        strokeColor: 'rgb(255,250,228)',
                        order: 5,
                        barPercentage: 1,
                        categoryPercentage: 1.0,
                        barThickness: 'flex',
                    });
                    allPhaseChartDataset.push({
                        label: "Red Clearance",
                        type: 'bar',
                        yAxisID: '1',
                        data: redClearanceEnd,
                        disableUnchecking: true,
                        backgroundColor: 'rgb(255, 189, 189)', //'#cc3232',
                        borderColor: 'rgb(255, 189, 189)',
                        hoverBackgroundColor: 'rgb(255, 189, 189)', //'#cc3232',
                        hoverBorderColor: 'rgb(255, 189, 189)',
                        fillColor: 'rgb(255, 189, 189)',
                        strokeColor: 'rgb(255, 189, 189)',
                        order: 6,
                        barPercentage: 1,
                        categoryPercentage: 1.0,
                        barThickness: 'flex',
                    });
                    allPhaseChartDataset.push({
                        label: "Red",
                        type: 'bar',
                        yAxisID: '1',
                        disableUnchecking: true,
                        data: redEnd,
                        backgroundColor: 'rgb(255,231,231)', //'#cc3232',
                        borderColor: 'rgb(255,231,231)',
                        hoverBackgroundColor: 'rgb(255,231,231)', //'#cc3232',
                        hoverBorderColor: 'rgb(255,231,231)',
                        fillColor: 'rgb(255,231,231)',
                        strokeColor: 'rgb(255,231,231)',
                        order: 7,
                        barPercentage: 1,
                        categoryPercentage: 1.0,
                        barThickness: 'flex',
                    });

                    if (redLightRunners && redLightRunners.length > 0) {
                        allPhaseChartDataset.push({
                            label: vm.redLightRunnersTitle,
                            type: 'line',
                            yAxisID: '2',
                            data: redLightRunners,
                            fill: false,
                            pointRadius: 2,
                            pointHoverRadius: 2,
                            borderColor: 'rgb(92, 92, 92)',
                            borderWidth: 2,
                            backgroundColor: 'rgb(92, 92, 92)',
                            order: 2,
                        });
                    }
                    var lastRedLightRunner = redLightRunners[redLightRunners.length - 1];
                    if (lastRedLightRunner) {
                        rlrRadius.push(2)
                        binDates.push({ binStart: (lastRedLightRunner.x.getTime() - (vm.binInMilliseconds / 2)), binEnd: (lastRedLightRunner.x.getTime() + (vm.binInMilliseconds / 2)) })
                        var lastCycle = redEnd[redEnd.length - 1];
                        if (lastCycle) {
                            redLightRunners.push({
                                x: new Date(lastCycle.x.getTime()),
                                y: lastRedLightRunner
                            });
                        }
                    }
                    var firstRedLightRunner = redLightRunners[0];
                    if (firstRedLightRunner) {
                        rlrRadius.unshift(2)
                        binDates.unshift({ binStart: (firstRedLightRunner.x.getTime() - vm.binInMilliseconds), binEnd: (firstRedLightRunner.x.getTime() + vm.binInMilliseconds) })
                        var firstCycle = redEnd[0];
                        if (firstCycle) {
                            redLightRunners.unshift({
                                x: new Date(firstCycle.x.getTime()),
                                y: firstRedLightRunner
                            });
                        }
                    }

                    if (detectorActivation && detectorActivation.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Violations",
                            type: 'scatter',
                            xAxisID: 'x-axis-0',
                            yAxisID: '1',
                            data: detectorActivation,
                            backgroundColor: '', //1f77b4
                            fillColor: platoonColor,
                            pointBackgroundColor: platoonColor, //'#cc3232',
                            pointFillColor: platoonColor,
                            pointBorderColor: platoonColor,
                            pointStrokeColor: platoonColor,
                            pointRadius: 2,
                            pointHoverRadius: 2,
                            order: 1
                        });
                    }
                    if (severeActivation && severeActivation.length > 0) {
                        allPhaseChartDataset.push({
                            label: "Severe Red Violations",
                            type: 'scatter',
                            xAxisID: 'x-axis-0',
                            yAxisID: '1',
                            data: severeActivation,
                            backgroundColor: 'rgb(255,0,0)',
                            fillColor: 'rgb(255,0,0)',
                            pointBackgroundColor: 'rgb(255,0,0)',
                            pointFillColor: 'rgb(255,0,0)',
                            pointBorderColor: 'rgb(255,0,0)',
                            pointStrokeColor: 'rgb(255,0,0)',
                            pointRadius: 2,
                            pointHoverRadius: 2,
                            order: 1
                        });
                    }


                    //push this phase chart back into the main chartArray. Each phase needs it's own chart
                    var phaseTypeText = phaseItem.isPermissive ? "Permissive" : "Protected";
                    if (phaseTypeText == "Protected") {
                        charts[phaseItem.direction].push({
                            title: "Red Light Runners",
                            subLine1: $scope.signal.description + " - " + phaseInfo.direction + " " + phaseItem.movement + " - " + phaseTypeText + " Phase " + phaseItem.phaseNumber,
                            subLine2: "Severe Red Light Violations: " + phaseInfo.severeRedLightViolations + " / Percent Severe Red Violation: " + phaseInfo.percentSevereViolations + "%",
                            subLine3: "Total Red Violations: " + phaseItem.violations + " / Percent Red Violations: " + phaseInfo.percentViolations + "%",
                            subLine4: "Using " + phaseItem.detectionType + " Detection",
                            plans: vm.rlrChartWidget.addPlanLabels(phaseItem.plans),
                            plansHidden: false,
                            api: {},
                            planColor: "#000",
                            tabIndex: tabIndex,
                            isDataAvailable: Object.keys(phaseItem.cycleData.cycleCollection).length > 0,
                            onDestroy: function () {
                                this.api = {};
                            },
                            onApiInit: function (apiObj) {
                                this.api = apiObj;
                                this.render(apiObj);
                            },
                            render: function (apiObj) {
                                apiObj.render(undefined, this.dataset, this.chartOptions);
                                vm.chart =
                                    vm.chartRendering = false;
                                this.isRendered = true;
                            },

                            hidePlanLabels: function (e, chart) {
                                chart.plansHidden = !chart.plansHidden;
                                chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                                chart.chartOptions.annotation.annotations = vm.createPlanAnnotations(phaseItem, chart.plansHidden);
                                Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                                // this.plans.visible = false;
                                if (chart.plansHidden) {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels2 = plan.labels;
                                        plan.labels = [];
                                    });
                                    chart.planColor = "#fff";
                                } else {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels = plan.labels2;
                                    });
                                    chart.planColor = "#000";
                                }
                                chart.api.update();
                            },
                            isRendered: false,
                            dataset: allPhaseChartDataset,
                            data: chartData,
                            hover: {
                                mode: 'nearest'
                            },
                            chartOptions: {
                                hidePlans: false,
                                animation: false,
                                tooltips: {
                                    enabled: false,
                                    mode: 'nearest',
                                    position: 'average',
                                    intersect: 'nearest',
                                    callbacks: {
                                        afterBody: function (t, d) {
                                            return {
                                                // Matching Chart Data with Tooltip Data
                                                redClearanceViolation: redClearanceViolations[t[0].index] > 0 ? ('Red Clearance Violations in Cycle: ' + redClearanceViolations[t[0].index]) : undefined,
                                                redViolation: redViolations[t[0].index] > 0 ? ('Red Light Violations in Cycle: ' + redViolations[t[0].index]) : undefined,
                                                yellowClearanceViolation: yellowClearanceViolations[t[0].index] > 0 ? ('Yellow Clearance Violations in Cycle: ' + yellowClearanceViolations[t[0].index]) : undefined,
                                                laneNumber: 'Lane Number: ' + laneNumbers[t[0].index],
                                                binDate: binDates[t[0].index],
                                                cycleData: cycleData[t[0].index],
                                                detectorActivationTime: detectorActivationTimes[t[0].index],
                                                severeActivationTime: severeActivationTimes[t[0].index],
                                                detectorChannel: 'Detector Channel: ' + detectorChannels[t[0].index]
                                            };
                                        }
                                    },
                                    custom: function (tooltipModel) {
                                        // Tooltip Element
                                        var tooltipEl = document.getElementById('chartjs-tooltip');

                                        // Create element on first render
                                        if (!tooltipEl) {
                                            tooltipEl = document.createElement('div');
                                            tooltipEl.id = 'chartjs-tooltip';
                                            tooltipEl.innerHTML = '<table></table>';
                                            document.body.appendChild(tooltipEl);
                                        }

                                        // Hide if no tooltip
                                        if (tooltipModel.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            return;
                                        }

                                        // Set caret Position
                                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                                        if (tooltipModel.yAlign) {
                                            tooltipEl.classList.add(tooltipModel.yAlign);
                                        } else {
                                            tooltipEl.classList.add('no-transform');
                                        }

                                        function getBody(bodyItem) {
                                            return bodyItem.lines;
                                        }

                                        // Set Text
                                        if (tooltipModel.body) {
                                            var index = tooltipModel.dataPoints[0].datasetIndex;
                                            var currentChart = tooltipModel.body[0].lines[0].split(':')[0];
                                            var titleLines = tooltipModel.title || [];
                                            var bodyLines = tooltipModel.body.map(getBody);

                                            if (currentChart == 'Violations' || currentChart == 'Severe Red Violations') {
                                                var currentBody = bodyLines[0];
                                                bodyLines = [];
                                                if (currentChart == 'Violations') {
                                                    bodyLines.push({ text: 'Detector off: ' + vm.formatDatePrecise(tooltipModel.afterBody[0].detectorActivationTime) });
                                                    if (Number(currentBody[0].toString().split(':')[1]) >= tooltipModel.afterBody[0].cycleData.yellowClearance) {
                                                        titleLines = 'Red Violation';
                                                    } else {
                                                        titleLines = 'Yellow Violation';
                                                    }
                                                } else {
                                                    bodyLines.push({ text: 'Detector off: ' + vm.formatDatePrecise(tooltipModel.afterBody[0].severeActivationTime) });
                                                    titleLines = 'Severe Red Violation';
                                                }

                                                bodyLines.push({ text: 'Time Since Yellow Start: ' + currentBody[0].toString().split(':')[1] + ' (sec)' });
                                                bodyLines.push({ text: phaseItem.detectionType });
                                                bodyLines.push({ text: tooltipModel.afterBody[0].laneNumber });
                                                bodyLines.push({ text: tooltipModel.afterBody[0].detectorChannel });
                                            }
                                            if (currentChart == 'Red' || currentChart == 'Yellow Clearance' || currentChart == 'Red Clearance') {
                                                var currentBody = bodyLines[0];
                                                titleLines = "Cycle Information"
                                                bodyLines = [];

                                                if (tooltipModel.afterBody[0].redViolation) {
                                                    bodyLines.push({ highlighted: true, text: tooltipModel.afterBody[0].redViolation });
                                                }
                                                if (tooltipModel.afterBody[0].redClearanceViolation) {
                                                    bodyLines.push({ highlighted: true, text: tooltipModel.afterBody[0].redClearanceViolation });
                                                }
                                                if (tooltipModel.afterBody[0].yellowClearanceViolation) {
                                                    bodyLines.push({ highlighted: true, text: tooltipModel.afterBody[0].yellowClearanceViolation });
                                                }
                                                bodyLines.push({ text: 'Cycle Start: ' + vm.formatDatePrecise(tooltipModel.afterBody[0].cycleData.cycleStart) });
                                                bodyLines.push({ text: 'Red Clearance: ' + tooltipModel.afterBody[0].cycleData.redClearance + ' sec' });
                                                bodyLines.push({ text: 'Yellow Clearance: ' + tooltipModel.afterBody[0].cycleData.yellowClearance + ' sec' });
                                            }
                                            if (currentChart.includes('Total') && tooltipModel.afterBody[0].binDate) {
                                                var violations = bodyLines[0];
                                                bodyLines = [];
                                                titleLines = "Totals Information";
                                                bodyLines.push({ text: "Total Violations in Bin:" + violations.toString().split(':')[1] });
                                                bodyLines.push({ text: 'Bin size: ' + vm.binSize });
                                                bodyLines.push({ text: 'Bin start: ' + vm.formatDate(tooltipModel.afterBody[0].binDate.binStart) });
                                                bodyLines.push({ text: 'Bin end: ' + vm.formatDate(tooltipModel.afterBody[0].binDate.binEnd) });
                                            }
                                            var innerHtml = '<thead>';
                                            innerHtml += '<tr><th>' + titleLines + '</th></tr>';
                                            innerHtml += '</thead><tbody>';

                                            bodyLines.forEach(function (body, i) {
                                                vm.changeColorToRed = false;
                                                var style = 'background-color:' + 'rgba(0,0,0,.9)';
                                                style += '; border-color:' + 'rgba(0,0,0,.9)';
                                                style += '; border-width: 1px';
                                                var span = '<span style="' + style + '"></span>';
                                                innerHtml += '<tr><td style="color:' + (body.highlighted ? 'rgb(255, 77, 77);' : 'rgb(255,255,255)') + '">' + span + body.text + '</td></tr>'
                                            });
                                            innerHtml += '</tbody>';

                                            var tableRoot = tooltipEl.querySelector('table');
                                            var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                            tableStyle += 'color: rgba(255,255,255, 1);';
                                            tableStyle += 'padding: 5px 10px;';
                                            tableStyle += 'border-radius: 10px;';
                                            if (currentChart.includes('Total') && !tooltipModel.afterBody[0].binDate) {
                                                tableStyle += 'display: none;';
                                            }

                                            tableRoot.style = tableStyle;
                                            tableRoot.innerHTML = innerHtml;
                                        }

                                        // `this` will be the overall tooltip
                                        var position = this._chart.canvas.getBoundingClientRect();

                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.position = 'absolute';
                                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                        if (position.width <= tooltipEl.offsetLeft + tooltipEl.clientWidth) {
                                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 250 + 'px';
                                        }
                                        if (position.height <= tooltipModel.caretY + tooltipEl.clientHeight) {
                                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 80 + 'px';
                                        }
                                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                                        tooltipEl.style.pointerEvents = 'none';
                                    }
                                },
                                legend: {
                                    display: true,
                                    labels: {
                                        filter: function (legendItem) {
                                            if (legendItem.datasetIndex == 0 || legendItem.datasetIndex == 1 || legendItem.datasetIndex == 2) {
                                                return false;
                                            }
                                            return true;
                                        }
                                    },
                                    align: 'end',
                                },
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        type: 'time',
                                        id: 'x-axis-0',
                                        time: {
                                            unit: 'hour'
                                        }, ticks: {
                                            minRotation: 0,
                                            maxRotation: 0,
                                            autoSkip: true,
                                            autoSkipPadding: 50,
                                        }
                                    }],
                                    yAxes: [{
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Time Since Yellow Start (seconds)',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        id: '1',
                                        ticks: {
                                            max: 15
                                        },
                                        stacked: false,
                                        type: 'linear',
                                        position: 'left',

                                    },
                                    {
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Red Light Runners',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        id: '2',
                                        type: 'linear',
                                        position: 'right',
                                        yMin: 0,
                                        ticks: { beginAtZero: true }
                                    }
                                    ]
                                },
                                annotation: {
                                    annotations: [
                                        ...planAnnotations,
                                        {
                                            type: 'line',
                                            id: 'call-count-4',
                                            mode: 'horizontal',
                                            scaleID: '1',
                                            value: ((averageYellowTime / 1000) + vm.severityLevel),
                                            borderColor: 'blue',
                                            borderWidth: 2,
                                            borderDash: [10, 5],
                                            label: {
                                                backgroundColor: 'rgba(230, 230, 230, 0.8)',
                                                fontFamily: 'sans-serif',
                                                fontSize: 12,
                                                fontStyle: 'bold',
                                                fontColor: 'blue',
                                                xPadding: 2,
                                                yPadding: 6,
                                                position: "left",
                                                yAdjust: -11,
                                                enabled: true,
                                                content: 'Severity'
                                            }
                                        },
                                        {
                                            type: 'line',
                                            id: 'call-count-1',
                                            scaleID: '1',
                                            mode: 'horizontal',
                                            value: (averageYellowTime / 1000),
                                            borderColor: 'transparent',
                                            label: {
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                fontFamily: 'sans-serif',
                                                fontSize: 10,
                                                fontStyle: 'bold',
                                                fontColor: 'rgb(255, 255, 102)',
                                                xPadding: 4,
                                                yPadding: 4,
                                                position: "right",
                                                yAdjust: -11,
                                                enabled: true,
                                                content: 'END YELLOW CLEARANCE'
                                            }
                                        },
                                        {
                                            type: 'line',
                                            id: 'call-count-2',
                                            mode: 'horizontal',
                                            scaleID: '1',
                                            value: averageRedClearanceTime > 1000 ? ((averageRedClearanceTime + averageYellowTime) / 1000) : ((averageYellowTime + 1000) / 1000),
                                            borderColor: 'transparent',
                                            label: {
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                fontFamily: 'sans-serif',
                                                fontSize: 10,
                                                fontStyle: 'bold',
                                                fontColor: 'rgb(255, 102, 102)',
                                                xPadding: 4,
                                                yPadding: 4,
                                                position: "right",
                                                yAdjust: -11,
                                                enabled: true,
                                                content: 'END RED CLEARANCE'
                                            }
                                        },
                                        {
                                            type: 'line',
                                            id: 'call-count-3',
                                            mode: 'horizontal',
                                            scaleID: '1',
                                            value: 0,
                                            borderColor: 'transparent',
                                            label: {
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                fontFamily: 'sans-serif',
                                                fontSize: 10,
                                                fontStyle: 'bold',
                                                fontColor: 'rgb(255, 255, 102)',
                                                xPadding: 4,
                                                yPadding: 4,
                                                position: "right",
                                                yAdjust: -12,
                                                enabled: true,
                                                content: 'START YELLOW CLEARANCE'
                                            }
                                        }

                                    ]
                                },
                                plugins: {
                                    zoom: {
                                        pan: {
                                            enabled: false,
                                            mode: 'x',
                                            rangeMin: {
                                                x: yellowEnd && yellowEnd.length > 0 ? yellowEnd[0].x.getTime() : undefined
                                            },
                                            rangeMax: {
                                                x: yellowEnd && yellowEnd.length > 0 ? yellowEnd[yellowEnd.length - 1].x.getTime() : undefined
                                            }
                                        },
                                        zoom: {
                                            enabled: true,
                                            drag: true,
                                            speed: 0.1,
                                            mode: 'x',
                                            rangeMin: {
                                                x: yellowEnd && yellowEnd.length > 0 ? yellowEnd[0].x.getTime() : undefined
                                            },
                                            rangeMax: {
                                                x: yellowEnd && yellowEnd.length > 0 ? yellowEnd[yellowEnd.length - 1].x.getTime() : undefined
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                    vm.chartRendering = true;
                    vm.chartLoading = false;
                    tabIndex++;
                });
                //set our chart array back to our binding object
                vm.rlrChartWidget.chartArray = charts;
            }
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getChartbySelectedTab() {
            if (vm.rlrChartWidget.chartArray) {
                var chart = vm.rlrChartWidget.chartArray[vm.rlrChartWidget.selectedDirection];
                if (chart && chart[0] && chart[0].api) {
                    return chart[0];
                }
            }
        }


        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions, true)
                .then(function (data) {
                    $scope.loading = false;
                    vm.chartLoading = true;
                    //JSON stringify the server data and then process it for the chart
                    var rawData = JSON.stringify(data, null, 4);
                    vm.rlrChartWidget.processRLRData(JSON.parse(rawData, JSON.dateParser));
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
}());
