(function () {
    angular
        .module('app.searchbar')
        .controller('timePickerController', timePickerController);

    function timePickerController($rootScope, $scope, searchBarService, on_change, on_cancel) {
        var vm = this;
        vm.selectedDateText = "";
        vm.searchBarService = searchBarService;
        vm.apply = apply;
        vm.clear = clear;
        vm.dirtyTimes = false;
        // vm.checkForValidTimes = checkForValidTimes;

        // $scope.$on('destroy', function () {
        //     $rootScope.$off("mdpTimePickerUpdated", vm.checkForValidTimes);
        // });
        // $rootScope.$on("mdpTimePickerUpdated", vm.checkForValidTimes);



        // function checkForValidTimes() {
        //     if (!$scope.ngModel || !$scope.ngModel.dateStart || !$scope.ngModel.dateEnd)
        //         return;
        //     //add 30 minutes to the start event to see if it's greater than end
        //     //if so, then disable apply button
        //     var newStart = new Date($scope.ngModel.dateStart.getTime() + 30 * 60000);
        //     if (newStart > $scope.ngModel.dateEnd) {
        //         vm.dirtyTimes = true;
        //     }
        //     else {
        //         vm.dirtyTimes = false;
        //     }
        // }


        function clear() {
            if (on_cancel)
                on_cancel();

            // telemetryService.insightsDashboardTelemetry("cancel", "date_selection");
        }

        function apply() {
            //update our saved values in local storage, trigger update events then hide menu
            var options = vm.searchBarService.getSearchOptions();
            var timeOptions = options.timeOptions;

            timeOptions.currentFilter.startDateAndTime = $scope.ngModel.dateStart;
            timeOptions.currentFilter.endDateAndTime = $scope.ngModel.dateEnd;
            timeOptions.currentFilter.selectedDateTemplate = $scope.ngModel.selectedTemplate;
            timeOptions.currentFilter.selectedTodTemplate = $scope.ngModel.selectedTodTemplate;
            //if our timeframe is less than a week we need to reset the day of week list
            if (searchBarService.isFilterSpanGreaterThanOrEqualToDays(7, timeOptions.currentFilter.startDateAndTime, timeOptions.currentFilter.endDateAndTime)) {
                //timeframe greater than 7 days we can set dayOfWeekList
                timeOptions.daysOfWeekList = $scope.ngModel.selectedWeekdays.filter(x => x.isSelected).map(y => y.value);
            }
            else {
                //timeframe is less than 1 week, we do not allow day of week list filtering set to default
                timeOptions.daysOfWeekList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            }


            if ($scope.ngModel.selectedTodTemplate == "AM" || $scope.ngModel.selectedTodTemplate == "PM" || $scope.ngModel.selectedTodTemplate == "MD")
                timeOptions.timeOption = "TimePeriod";
            else
                timeOptions.timeOption = "StartToEnd";

            searchBarService.setTimeOptions(timeOptions);
            if (on_change)
                on_change();

            // telemetryService.insightsDashboardTelemetry("apply", "date_selection");
        }
    }
}());
