﻿(function () {
  "use strict";

  angular.module('app.core')
    .directive("ngWeekdayPicker", function () {
      var const_days = [{
        id: 0,
        name: "MON",
        value: "Monday",
        isSelected: true
      }, {
        id: 1,
        name: "TUE",
        value: "Tuesday",
        isSelected: true
      }, {
        id: 2,
        name: "WED",
        value: "Wednesday",
        isSelected: true
      }, {
        id: 3,
        name: "THU",
        value: "Thursday",
        isSelected: true
      }, {
        id: 4,
        name: "FRI",
        value: "Friday",
        isSelected: true
      }, {
        id: 5,
        value: "Saturday",
        name: "SAT",
        isSelected: true
      },
      {
        id: 6,
        name: "SUN",
        value: "Sunday",
        isSelected: true
      }];

      var template = "<div class='days-container' ng-disabled=\"ngDisabled\">" +
        "<md-button style='pointer-events: auto' md-prevent-menu-close=\"md-prevent-menu-close\" class=\"md-button md-fab md-raised\" layout-align=\"start start\" ng-click=\'onDayClicked((0 + weekStartsIndex)%7)\' ng-class=\"{\'md-day-button-pressed\': ngModel[(0 + weekStartsIndex)%7].isSelected}\" ng-disabled=\"ngDisabled\"><div><div layout='row' class='text-wrapper' layout-align='center center'>{{ngModel[(0 + weekStartsIndex)%7].name}}</div><div layout='row'><md-icon layout-align='start center' md-font-icon='icon-check'></md-icon></div></div><md-tooltip>{{ getToolTip(0) }} </md-tooltip></md-button>" +
        "<md-button style='pointer-events: auto' md-prevent-menu-close=\"md-prevent-menu-close\" class=\"md-button md-fab md-raised\" layout-align=\"start start\" ng-click=\'onDayClicked((1 + weekStartsIndex)%7)\' ng-class=\"{\'md-day-button-pressed\': ngModel[(1 + weekStartsIndex)%7].isSelected}\" ng-disabled=\"ngDisabled\"><div><div layout='row' class='text-wrapper' layout-align='center center'>{{ngModel[(1 + weekStartsIndex)%7].name}}</div><div layout='row'><md-icon layout-align='start center' md-font-icon='icon-check'></md-icon></div></div><md-tooltip>{{ getToolTip(1) }} </md-tooltip></md-button>" +
        "<md-button style='pointer-events: auto' md-prevent-menu-close=\"md-prevent-menu-close\" class=\"md-button  md-fab md-raised\" layout-align=\"start start\" ng-click=\'onDayClicked((2 + weekStartsIndex)%7)\' ng-class=\"{\'md-day-button-pressed\': ngModel[(2 + weekStartsIndex)%7].isSelected}\" ng-disabled=\"ngDisabled\"><div><div layout='row' class='text-wrapper' layout-align='center center'>{{ngModel[(2 + weekStartsIndex)%7].name}}</div><div layout='row'><md-icon layout-align='start center' md-font-icon='icon-check'></md-icon></div></div><md-tooltip>{{ getToolTip(2) }} </md-tooltip></md-button>" +
        "<md-button style='pointer-events: auto' md-prevent-menu-close=\"md-prevent-menu-close\" class=\"md-button  md-fab md-raised\" layout-align=\"start start\" ng-click=\'onDayClicked((3 + weekStartsIndex)%7)\' ng-class=\"{\'md-day-button-pressed\': ngModel[(3 + weekStartsIndex)%7].isSelected}\" ng-disabled=\"ngDisabled\"><div><div layout='row' class='text-wrapper' layout-align='center center'>{{ngModel[(3 + weekStartsIndex)%7].name}}</div><div layout='row'><md-icon layout-align='start center' md-font-icon='icon-check'></md-icon></div></div><md-tooltip>{{ getToolTip(3) }} </md-tooltip></md-button>" +
        "<md-button style='pointer-events: auto' md-prevent-menu-close=\"md-prevent-menu-close\" class=\"md-button  md-fab md-raised\" layout-align=\"start start\" ng-click=\'onDayClicked((4 + weekStartsIndex)%7)\' ng-class=\"{\'md-day-button-pressed\': ngModel[(4 + weekStartsIndex)%7].isSelected}\" ng-disabled=\"ngDisabled\"><div><div layout='row' class='text-wrapper' layout-align='center center'>{{ngModel[(4 + weekStartsIndex)%7].name}}</div><div layout='row'><md-icon layout-align='start center' md-font-icon='icon-check'></md-icon></div></div><md-tooltip>{{ getToolTip(4) }} </md-tooltip></md-button>" +
        "<md-button style='pointer-events: auto' md-prevent-menu-close=\"md-prevent-menu-close\" class=\"md-button  md-fab md-raised\" layout-align=\"start start\" ng-click=\'onDayClicked((5 + weekStartsIndex)%7)\' ng-class=\"{\'md-day-button-pressed\': ngModel[(5 + weekStartsIndex)%7].isSelected}\" ng-disabled=\"ngDisabled\"><div><div layout='row' class='text-wrapper' layout-align='center center'>{{ngModel[(5 + weekStartsIndex)%7].name}}</div><div layout='row'><md-icon layout-align='start center' md-font-icon='icon-check'></md-icon></div></div><md-tooltip>{{ getToolTip(5) }} </md-tooltip></md-button>" +
        "<md-button style='pointer-events: auto' md-prevent-menu-close=\"md-prevent-menu-close\" class=\"md-button  md-fab  md-raised\" layout-align=\"start start\" ng-click=\'onDayClicked((6 + weekStartsIndex)%7)\' ng-class=\"{\'md-day-button-pressed\': ngModel[(6 + weekStartsIndex)%7].isSelected}\" ng-disabled=\"ngDisabled\"><div><div layout='row' class='text-wrapper' layout-align='center center'>{{ngModel[(6 + weekStartsIndex)%7].name}}</div><div layout='row'><md-icon layout-align='start center' md-font-icon='icon-check'></md-icon></div></div><md-tooltip>{{ getToolTip(6) }} </md-tooltip></md-button>" +
        "</div>";

      var link = function (scope) {
        var cleanup;

        var init = function () {
          if (!scope.weekStartsIndex) {
            scope.weekStartsIndex = 0;
          }
          if (scope.ngDisabled === undefined || scope.ngDisabled === null) {
            scope.ngDisabled = false;
          }
          cleanup = scope.$watch('ngDisabled', function (val) {
            scope.ngDisabled = val;
          });
          initDaysSelected();
          initControl();
        };


        var initDaysSelected = function () {
          if (!scope.ngModel) {
            scope.ngModel = [];
            angular.extend(scope.ngModel, const_days);
          }
        };

        var initControl = function () {
          if (scope.control) {
            scope.control.toggleDayByIndex = function (dayIndex) {
              if (scope.ngModel) {
                scope.ngModel[dayIndex].isSelected = !scope.ngModel[dayIndex].isSelected;
              } else {
                console.log("Error, no model to toggle for!");
              }
            };
          }

        };

        scope.onDayClicked = function (dayIndex) {
          if (!scope.ngDisabled) {
            scope.ngModel[dayIndex].isSelected = !scope.ngModel[dayIndex].isSelected;
            if (typeof scope.ngChange === "function") {
              scope.ngChange({ newValue: { index: dayIndex, item: scope.ngModel[dayIndex] } });
            }
          }
        };

        scope.getToolTip = function (input) {
          if (scope.ngDisabled) {
            return 'Weekday filtering is disabled when the current timeframe is shorter than a week';
          }

          return scope.ngModel[(input + scope.weekStartsIndex) % 7].value;
        };


        init();

        scope.$on("$destroy", function () {
          if (cleanup) {
            cleanup();
          }
        });
      };

      return {
        restrict: 'AE',
        scope: {
          ngModel: '=',
          ngChange: '&',
          weekStartsIndex: '@?',
          ngDisabled: '=?',
          control: '=?'
        },
        link: link,
        template: template
      };
    });
})();