﻿import * as CHARTSTACKED from 'raw-loader!./chart-split-monitor-stacked.html'

(function () {
    'use strict';

    angular.module('app.spm.charts.signal')
    .directive('splitMonitorStackedChart', splitMonitorStackedDirective);

    /** @ngInject */
    function splitMonitorStackedDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'splitMonitorStackedController as vm',
            template: function (elem, attrs) {
                 return CHARTSTACKED;
            },
            scope: {
                fetchData:'<',
                spmChartOptions: '<',
                signal: '=',
                searchDates: '<',
                splitRings: '<',
                widgetFlex: '<',
                onApiInit: '&',
                onApiDestroy: '&',
                api: '=',
                loading: '='
            },
            link: function (scope) {
                if (scope.api) {
                    angular.extend(scope.api, {                
                        updateFetchData: function (signal, dates){
                            return scope.updateFetchData(signal, dates);
                        },
                        updateData: function (data){
                            return scope.updateData(data);
                        }                  
                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }

        };
    }
})();