﻿(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards',
        [
                // 'app.spm.dashboards.productivity',
                'app.spm.dashboards.map-overview',
                'app.spm.dashboards.signal-overview',
                // 'app.spm.dashboards.resources',
                'app.spm.dashboards.signal-performance',
                'app.spm.dashboards.signal-trends',
                'app.spm.dashboards.monthly-intersection-report',
                'app.spm.dashboards.insights',
                'app.spm.dashboards.insights-map',
                'app.spm.dashboards.detector-configuration-suggestions',
                // 'app.spm.dashboards.insights.data-insights',
                // 'app.spm.dashboards.insights.config-insights',
                // 'app.spm.dashboards.insights.maintenance-insights',
                // 'app.spm.dashboards.insights.performance-insights',
                'app.spm.dashboards.user-settings',
                'app.spm.dashboards.executive',
                'app.spm.dashboards.corridor-performance',
                'app.spm.dashboards.travel-time',
                'app.spm.dashboards.historical-travel-time',
                'app.spm.dashboards.waze-travel-time',
            ]
        )
        .config(config);

    /** @ngInject */
    function config(msNavigationServiceProvider) {
        
    }

})();