(function () {
    'use strict';

    angular
        .module('app.spm')
        .run(runBlock);

    /** @ngInject */
    function runBlock($rootScope, $http, msNavigationService, environmentConfig, chartsService, corridorService, userService, userSettingsService, insightsService) {
        //setup chartJs globals
        //setup some chart defaults
        Chart.defaults.global.multiTooltipTemplate = '<%if (datasetLabel){%><%=datasetLabel%>: <%}%><%= value %>';
        Chart.defaults.global.tooltips.mode = 'label';
        Chart.defaults.global.responsive = true;
        Chart.defaults.global.elements.line.borderWidth = 2;
        Chart.defaults.global.elements.rectangle.borderWidth = 2;
        Chart.defaults.global.maintainAspectRatio = false;
        Chart.defaults.global.legend.labels.usePointStyle = true;
        //    Chart.defaults.line.spanGaps = true;
        Chart.defaults.global.colors = [
            '#97BBCD', // blue
            '#DCDCDC', // light grey
            '#F7464A', // red
            '#46BFBD', // green
            '#FDB45C', // yellow
            '#949FB1', // grey
            '#4D5360'  // dark grey
        ];


        function doStuff(category) {
            var keyString = category.insightCategoryID.toString();
            return !userSettingsService.categories.has(keyString)// must be a boolean value
        }

        //setup a watch to wait for our ID_TOKEN to get set here
        //once we have an auth token then call to get menus
        var unregister = $rootScope.$watch(function () { return sessionStorage.getItem('id_token'); }, function (newVal, oldVal) {
            if (!newVal)
                return;
            //check if  menus are loaded
            var dashboardRoot = msNavigationService.getNavigation("spm.dashboards");
            //call to load menus if our id token has changed or we are missing dashboard root
            if (!dashboardRoot) {
                Promise.all(
                    [
                        $http.get(environmentConfig.apiUrl + "/api/Menus/"),
                        $http.get(environmentConfig.apiUrl + "/api/Menus/isTrafficLinkUser")
                    ]
                ).then(function (result) {
                    var data = result[0];

                    // TrafficLink users manage config in TrafficLink - hide the config in Traffop Prod (allow in Dev)
                    function shouldDisplayConfig() {
                        var isTrafficLinkUser = result[1].data;
                        var isProd = environmentConfig.environmentName == "production";
                        var canUserReadWrite = userService.canUserReadWrite();
                        return canUserReadWrite && !(isTrafficLinkUser && isProd)
                    }

                    data.data.push({
                        menuObject: {
                            action: "spm.charts.red-light-runners",
                            appllication: "EnhancedUI",
                            controller: "",
                            displayOrder: 11,
                            menuId: 1011,
                            menuName: "Red Light Runners",
                            parentId: 0
                        },
                        subMenuItems: []
                    })
                    msNavigationService.saveItem('spm', {
                        title: 'Signal Performance Metrics',
                        group: true,
                        weight: 1
                    });

                    msNavigationService.saveItem('spm.system', {
                        title: 'System',
                        icon: 'icon-view-dashboard',
                        weight: 1,
                    });
                    msNavigationService.saveItem('spm.corridor', {
                        title: 'Corridor',
                        icon: 'icon-link',
                        weight: 3,
                    });
                    msNavigationService.saveItem('spm.signal', {
                        title: 'Signal',
                        icon: 'icon-traffic-light',
                        weight: 4,
                    });
                    msNavigationService.saveItem('spm.detection', {
                        title: 'Detection',
                        icon: 'icon-border-outside',
                        weight: 5,
                    });


                    msNavigationService.saveItem('spm.system.map', {
                        title: 'Map Overview',
                        icon: 'icon-google-maps',
                        state: 'app.spm.dashboards.map-overview',
                        weight: 0
                    });

                    userSettingsService.getUserSettings().then(function (userSettings) {
                        var availableCategories = userSettings.insightSettings.enabledDashboardInsightCategories;
                        userSettings.insightSettings.enabledDashboardInsightCategories.forEach(function (category) {
                            var keyString = category.insightCategoryID.toString();
                            userSettingsService.categories.set(keyString, { display: category.display })
                        });

                        if (!sessionStorage.getItem('userSettings')) {
                            sessionStorage.setItem('userSettings', JSON.stringify(userSettings));
                        }

                        insightsService.getInsightsDefintions().then(function (data) {
                            addInsightMenuItems();
                            data.insightCategories.sort((x, y) => (x.displayOrder > y.displayOrder) ? 1 : -1);
                            data.insightCategories.forEach(function (category) {
                                availableCategories.forEach(function (availableCategory) {
                                    if (category.insightCategoryID == availableCategory.insightCategoryID) {
                                        var categoryName = category.name.replace(/\s+/g, '-').toLowerCase();
                                        msNavigationService.saveItem('spm.insights.' + categoryName, {
                                            title: category.name,
                                            state: 'app.spm.dashboards.insights.' + categoryName,
                                            mdIcon: 'mdi-' + category.iconName,
                                            weight: category.displayOrder,
                                            hidden: doStuff(category)
                                        });
                                    }
                                });
                            });
                            msNavigationService.sort();
                        });
                    });
                    // msNavigationService.saveItem('spm.insights.data-insights', {
                    //     title: 'Data Insights',
                    //     state: 'app.spm.dashboards.insights.data-insights',
                    //     icon: 'icon-data',    
                    //     weight: 0,
                    // });
                    // msNavigationService.saveItem('spm.insights.config-insights', {
                    //     title: 'Configuration Insights',
                    //     state: 'app.spm.dashboards.insights.config-insights',
                    //     icon: 'icon-cog-box',    
                    //     weight: 1,
                    // });
                    // msNavigationService.saveItem('spm.insights.maintenance-insights', {
                    //     title: 'Maintenance Insights',
                    //     state: 'app.spm.dashboards.insights.maintenance-insights',
                    //     icon: 'icon-hammer-and-wrench',    
                    //     weight: 2,
                    // });
                    // msNavigationService.saveItem('spm.insights.operational-insights', {
                    //     title: 'Operational Insights',
                    //     state: 'app.spm.dashboards.insights.operational-insights',
                    //     icon: 'icon-rocket',    
                    //     weight: 3,
                    // });
                    // msNavigationService.saveItem('spm.insights.performance-insights', {
                    //     title: 'Performance Insights',
                    //     state: 'app.spm.dashboards.insights.performance-insights',
                    //     icon: 'icon-speedometer',    
                    //     weight: 4,
                    // });

                    msNavigationService.saveItem('spm.user-settings', {
                        title: 'User Settings',
                        state: 'app.spm.dashboards.user-settings',
                        icon: 'icon-person-box',
                        weight: 999
                    });
                    msNavigationService.saveItem('spm.signal.overview', {
                        title: 'Overview',
                        icon: 'icon-television-guide',
                        state: 'app.spm.dashboards.signal-overview',
                        weight: 0,
                    });
                    msNavigationService.saveItem('spm.signal.performance', {
                        title: 'Performance',
                        icon: 'icon-speedometer',
                        state: 'app.spm.dashboards.signal-performance',
                        weight: 1,
                    });
                    msNavigationService.saveItem('spm.signal.trends', {
                        title: 'Trends',
                        icon: 'icon-chart-line',
                        state: 'app.spm.dashboards.signal-trends',
                        weight: 2,
                        badge: {
                            content: 'Beta',
                            color: '#936DC7'
                        },
                    });
                    msNavigationService.saveItem('spm.signal.report', {
                        title: 'Monthly Report',
                        icon: 'icon-paper-cut-vertical',
                        state: 'app.spm.dashboards.monthly-intersection-report',
                        weight: 2,
                        badge: {
                            content: 'Beta',
                            color: '#936DC7'
                        },
                    });
                    msNavigationService.saveItem('spm.signal.executive', {
                        title: 'Executive',
                        icon: 'icon-coin',
                        state: 'app.spm.dashboards.executive',
                        weight: 3,
                    });

                    msNavigationService.saveItem('spm.corridor.performance', {
                        title: 'Performance',
                        icon: 'icon-speedometer',
                        state: 'app.spm.dashboards.corridor-performance',
                        weight: 1,
                    });
                    corridorService.isWazeEnabled().then(function (data) {
                        if (data.result) {
                            msNavigationService.saveItem('spm.corridor.historical-travel-time', {
                                title: 'Historical Travel Time',
                                icon: 'icon-history',
                                state: 'app.spm.dashboards.historical-travel-time',
                                weight: 2,
                            });
                            msNavigationService.saveItem("spm.system.wazeRoutes", {
                                title: 'Waze Travel Times',
                                state: "app.spm.dashboards.waze-travel-time",
                                icon: 'icon-timer',
                                weight: 3
                            });
                            msNavigationService.sort();
                        }
                    });

                    // msNavigationService.saveItem('spm.corridor.travel-time', {
                    //     title: 'Current Travel Time',
                    //     icon: 'icon-timer',
                    //     state: 'app.spm.dashboards.travel-time',
                    //     weight: 3,
                    // });
                    msNavigationService.saveItem("spm.corridor.purdue-link-pivot", {
                        title: 'Purdue Link Pivot',
                        state: "app.spm.purdue-link-pivot",
                        icon: 'icon-chart-pie',
                        weight: 4
                    });

                    //only show config menu if user has proper read_write permissions and if they manage configurations in Traffop
                    if (shouldDisplayConfig()) {
                        msNavigationService.saveItem("spm.config.corridors", {
                            title: 'Corridors',
                            state: "app.spm.config.corridors",
                            icon: 'icon-link',
                            weight: 2
                        });

                    }

                    //                        msNavigationService.disableMenuAvailability('spm.system', "insights", "This feature is coming soon!");

                    var dArr = data.data;
                    for (var i = 0, l = dArr.length; i < l; i++) {
                        var action = "";
                        var state = "", weight = dArr[i].menuObject.displayOrder;
                        var menuName = dArr[i].menuObject.menuName
                        if (dArr[i].menuObject.action.startsWith("{root}")) {
                            action = dArr[i].menuObject.action.replace("{root}", "");
                            state = "";
                        }
                        else {
                            action = dArr[i].menuObject.action;
                            state = "app." + dArr[i].menuObject.action;
                        }

                        //move signal events
                        switch (action) {
                            case "spm.signal-events":
                                action = "spm.signal.signal-events";
                                weight = 999;
                                break;
                            case "spm.map":
                                continue;
                            case "spm.charts":
                                action = "spm.signal.charts";
                                menuName = "Metrics"
                                weight = 1000;
                                break;
                            case "spm.charts.detector-counts":
                                action = "spm.detection.detector-counts";
                                weight = 1;
                                break;
                            case "spm.charts.detector-durations":
                                action = "spm.detection.detector-durations";
                                weight = 2;
                                break;
                            case "spm.charts.detector-config-suggestions":
                                action = "spm.detection.detector-config-suggestions";
                                weight = 3;
                                break;
                            case "spm.config":
                            case "spm.config.signals":
                                if (!shouldDisplayConfig())
                                    continue;
                                break;
                            default:
                                {
                                    //check for sub menu
                                    if (action === "spm.charts.split-monitor")
                                        weight = 0;
                                    var splitAction = action.split(".");
                                    if (splitAction.length > 2) {
                                        if (splitAction[0] + "." + splitAction[1] == "spm.charts") {
                                            let newAction = splitAction[0] + ".signal." + splitAction[1];
                                            for (let i = 2; i < splitAction.length; i++) {
                                                newAction += ".";
                                                newAction += splitAction[i];
                                            }
                                            action = newAction;
                                        }
                                    }
                                    break;
                                }

                        }

                        msNavigationService.saveItem(action, {
                            title: menuName,
                            icon: dArr[i].menuObject.controller,
                            state: state,
                            weight: weight
                        });
                    }
    
                    msNavigationService.saveItem("spm.signal.charts.purdue-split-monitor", {
                        weight: 1,
                        title: 'Purdue Split Monitor',
                        state: "app.spm.charts.purdue-split-monitor",
                        icon: '',
                    });
                    // TODO: take this out before deploying new version
                    msNavigationService.saveItem("spm.event-player", {
                        title: 'Event Playback',
                        state: "app.spm.event-player",
                        icon: 'icon-play-circle-outline',
                        badge: {
                            content: 'Beta',
                            color: '#936DC7'
                        },
                        weight: 50
                    });

                    msNavigationService.sort();
                    chartsService.updateChartMenus();
                });

                function addInsightMenuItems() {
                    msNavigationService.saveItem('spm.insights', {
                        title: 'Insights',
                        icon: 'icon-lightbulb',
                        weight: 0,
                    });
                    msNavigationService.saveItem('spm.insights.map', {
                        title: 'Insights Map',
                        state: 'app.spm.dashboards.insights-map',
                        icon: 'icon-map-marker-radius',
                        weight: 0,
                        badge: {
                            content: 'Beta',
                            color: '#936DC7'
                        },
                    });
                    msNavigationService.saveItem('spm.insights.insights-query', {
                        title: 'Insights Tester',
                        state: 'app.spm.dashboards.insights-query',
                        icon: 'icon-beaker',
                        weight: 55555,
                        badge: {
                            content: 'Beta',
                            color: '#936DC7'
                        },
                    });
                }
                //call unregister to stop watch 
                //unregister();
            }
        })
    }
})();
