﻿import * as SIGNALSEDIT from 'raw-loader!./signal/view/signal.html' 
import * as SIGNALPAGE from 'raw-loader!./signal/view/signal.html';
import * as APPROACHPAGE from 'raw-loader!./approach/view/approach.html';
import * as DETECTORSPAGE from 'raw-loader!./detector/view/detector.html';
(function () {
    angular
            .module('app.spm.signals', [])
            .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider) {
        $stateProvider
            .state('app.spm.config.signals', {
                url: '/signal-list',
                views: {
                    'content@app': {
                        templateUrl: 'assets/templates/signal-list.html',
                        controller: 'signalListController as vm'
                    }
                }
            })
            .state('app.spm.config.signals.add', {
                url: '/detail/add',
                views: {
                    'content@app': {
                        template: SIGNALPAGE,
                        controller: 'signalController as vm'
                    }
                },
                params: {
                    inputParameters: undefined
                }
            })
            .state('app.spm.config.signals.edit', {
                url: '/detail',
                views: {
                    'content@app': {
                        template: SIGNALPAGE,
                        controller: 'signalController as vm'
                    }
                },
                params: {
                    inputParameters: { signal: undefined, lookups: undefined, agencySettings: undefined }
                }
            });

        $stateProvider.state('app.spm.config.approaches', {
                url: '/approaches',
                views: {
                    'content@app': {
                        template: APPROACHPAGE,
                        controller: 'approachController as vm'
                    }
                },
                params: {
                    inputParameters: undefined
                }
            })
            .state('app.spm.config.approaches.add', {
                url: '/approaches/add',
                views: {
                    'content@app': {
                        template: APPROACHPAGE,
                        controller: 'approachController as vm'
                    }
                },
                params: {
                    inputParameters: undefined
                }
            })
            .state('app.spm.config.approaches.edit', {
                url: '/approaches/edit',
                views: {
                    'content@app': {
                        template: APPROACHPAGE,
                        controller: 'approachController as vm'
                    }
                },
                params: {
                    inputParameters: undefined
                }
            });
        $stateProvider.state('app.spm.config.detectors', {
                url: '/detectors',
                views: {
                    'content@app': {
                        template: DETECTORSPAGE,
                        controller: 'detectorsController as vm'
                    }
                },
                params: {
                    inputParameters: undefined
                }
            })
            .state('app.spm.config.detectors.add', {
                url: '/detectors/add',
                views: {
                    'content@app': {
                        template: DETECTORSPAGE,
                        controller: 'detectorsController as vm'
                    }
                },
                params: {
                    inputParameters: undefined
                }
            })
            .state('app.spm.config.detectors.edit', {
                url: '/detectors/edit',
                views: {
                    'content@app': {
                        template: DETECTORSPAGE,
                        controller: 'detectorController as vm'
                    }
                },
                params: {
                    inputParameters: undefined
                }
            });
        // Translation
        //$translatePartialLoaderProvider.addPart('app/main/apps/spm/signals');
    }
}());