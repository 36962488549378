(function () {
    "use strict";

    var app = angular
        .module('app.spm.core')
        .service("systemInsightsService", systemInsightsService);

    function systemInsightsService(insightsResource, $state, $rootScope, $interval, $mdSidenav, InsightConstants, $mdToast) {
        var vm = this;

        function pieChartHover(state, params, legendItems) {
            notify(state, params, legendItems);
        }

        function notify(signalState, params, legendItems) {
            var shouldHide, actionType = null;
            if (params) {
                shouldHide = params.shouldHide;
                actionType = params.actionType;
            }
            $rootScope.$emit('insights-map-chart-hover', {signalState, actionType, shouldHide, legendItems});
        }


        return {
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('insights-map-chart-hover', callback);
                scope.$on('$destroy', handler);
            },
            pieChartHover: pieChartHover,
        }
    }
})();
