
(function () {
    "use strict";

    angular
        .module("app.spm.link-pivot")
        .controller("purdueLinkPivotController", purdueLinkPivotController);

    function purdueLinkPivotController($state, $rootScope, $scope, purdueLinkPivotResource, purdueLinkPivotService, signalsResource, $mdSidenav, searchBarService) {
        var vm = this;
        //init variables
        vm.fetchingOptions = false;
        vm.isFiltering = false;
        vm.linkPivotData = {};
        vm.options = null;
        vm.timeOptions = null;
        vm.corridor = null;
        vm.loading = true;
        vm.upstreamRoute = {};
        vm.downstreamRoute = {};
        vm.selectedRoute = {};
        vm.mapApi = {};
        vm.signals=[];
        vm.comparisonPromise = {};
        vm.selectedNavItem = {};
        vm.selected = [];
        vm.searchBarService = searchBarService;
        vm.purdueLinkPivotService = purdueLinkPivotService;
        //set functions
        vm.refreshData = refreshData;
        vm.daysChanged = daysChanged;
        vm.setChartData = setChartData;
        vm.getOptions = getOptions;
        vm.setDataTableOptions = setDataTableOptions;
        vm.setupSearchBar = setupSearchBar;
        vm.setupOptionsChangeNotify = setupOptionsChangeNotify;
        vm.setSearchOptions = setSearchOptions;
        vm.updateMap = updateMap;
        vm.adjustOffset = adjustOffset;

        vm.setupSearchBar();
        vm.setupOptionsChangeNotify();

        //setup data table and call server to get options
        vm.setDataTableOptions();
        function setupSearchBar() {
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                    case "corridor":
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.setSearchOptions();
                            vm.getOptions();
                        }
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                    case "state":
                        if (searchBarService.isFiltering() == false)
                            vm.isFiltering = false;
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Purdue Link Pivot"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Corridors',
                showCurrentFilterDates: true,
                helpJsonPropertyPath: "PURDUE_LINK_PIVOT." + "GENERAL_HELP",
                showHelp: true,  
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TD",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    showWeekdayFilter: false,                  
                    maxDayRange: 1
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: false,
                    skipStepsPerBin: true,
                    customHtmlPath: "assets/templates/purdue-link-pivot/purdue-link-pivot-options.html"
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.corridor = options.corridor;
            vm.timeOptions = options.timeOptions;
            vm.signals = [];
            var upstreamRoute = {
                signals: [],
                direction: vm.corridor.upstreamRoute.direction,
                directionDetails: {},
            };
            var downstreamRoute = {
                signals: [],
                
                direction: vm.corridor.downstreamRoute.direction,
                directionDetails: {},
            }
            vm.corridor.upstreamRoute.approachRouteDetails.forEach(function (rt) {
                rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                upstreamRoute.signals.push(rt.signal);
            });
            vm.corridor.downstreamRoute.approachRouteDetails.forEach(function (rt) {
                rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                downstreamRoute.signals.push(rt.signal);
            });
            vm.upstreamRoute = upstreamRoute;
            vm.downstreamRoute = downstreamRoute;
        };

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function setupOptionsChangeNotify(){
            vm.purdueLinkPivotService.subscribe($scope, function onChange(ev, options) {
                if (!vm || !options)
                    return;

                vm.options.startingPoint = options.startingPoint;
                vm.options.biasDirection = options.biasDirection;
                vm.options.bias = options.bias;
                vm.options.cycleLength = options.cycleLength;
                vm.refreshData();
                vm.updateMap();
            });
        }

        function updateMap(){
            if (vm.mapApi && vm.mapApi.updateSignalsOnMap){
                if (vm.options.startingPoint == "Downstream"){
                    vm.selectedRoute = vm.downstreamRoute;
                }
                else{
                    vm.selectedRoute = vm.upstreamRoute;
                }
                vm.mapApi.updateSignalsOnMap(vm.selectedRoute.signals);
            }
        }

        function refreshData() {
            $rootScope.loadingProgress = true;
            vm.loading = true;
            var options = vm.searchBarService.getSearchOptions();
            //if we have more than 1 day selected we need to also filter per weekday selection
            if (vm.searchBarService.isFilterSpanGreaterThanDays(1)){
                vm.options.selectedDays = [];
                for(let i = 0; i < options.timeOptions.daysOfWeekList.length; i++){
                    var addDay = options.timeOptions.daysOfWeekList[i];
                    var day = options.timeOptions.daysOfWeekList.find(function(e){return e.value == addDay});
                    vm.options.selectedDays.push({dayId: i, name: day});
                }
            }
            else{
                //not filtering more than 1 day so just allow all days
                vm.options.selectedDays = vm.options.availableDays;
            }
           
            vm.options.startDate = new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleDateString();
            vm.options.endDate = new Date(options.timeOptions.currentFilter.endDateAndTime).toLocaleDateString();
            vm.options.startTime = new Date(options.timeOptions.currentFilter.startDateAndTime).toLocaleTimeString();
            vm.options.endtime = new Date(options.timeOptions.currentFilter.endDateAndTime).toLocaleTimeString();
            vm.options.corridorId = options.corridor.corridorID;

            vm.comparisonPromise = purdueLinkPivotResource.update(vm.options, function (data) {
                $rootScope.loadingProgress = false;
                vm.linkPivotData = data;
                vm.setChartData();
                vm.adjustOffset();
                vm.loading = false;
            }).$promise;
        }
        
        function daysChanged(action, id) {
            var idx = vm.options.postedDays.DayIDs.indexOf(id);
            if (idx != -1) {
                vm.options.postedDays.DayIDs.splice(idx, 1);
            }
            else {
                vm.options.postedDays.DayIDs.push(id)
            }
        }


        function setChartData() {
            vm.linkPivotData.chartOptions = {
                legend: {
                  display: false
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    bodyFontSize: 14,
                    titleFontSize: 14,
                    callbacks: {
                      label: function(tooltipItem, data) {
                        var label = data.labels[tooltipItem.index];
                        var datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return label + ': ' + datasetLabel + '%';
                      }
                    }
                  },
            };
            vm.linkPivotData.chartOptionsAogLine = {
                responsive: true,
                maintainAspectRatio: false,
                showLines: true,
                  scales: {
                    xAxes: [{
                        type: 'linear',
                        position: 'bottom',
                        ticks: {
                            beginAtZero: true
                        }
                    }],                  
                },
                elements: {
                    line: {
                        fill: false
                    }
                }
            };
            vm.linkPivotData.chartColors = ["#B7E8A7", "#339933", "#cc0000", "#c0c0c0"];
            vm.linkPivotData.chartLabels = ["Existing Aog", "Positive Predicted Aog", "Negative Predicted Aog", "Non-Aog"];
            vm.linkPivotData.aogLineSeries = ["Total AOG", "Downstream AOG", "Upstream AOG"];
            vm.linkPivotData.aogLineColors = ['#000000','#10630c','#0000d5'];
            //set the chart data for each approach Link
            for (let i = 0; i < vm.linkPivotData.approachLinks.length; i++) {
                var thisLink = vm.linkPivotData.approachLinks[i];
                //sets the pie chart of Aog
                thisLink.upstreamChartData = [thisLink.upstreamChartExisting, thisLink.upstreamChartPositiveChange,
                thisLink.upstreamChartNegativeChange, thisLink.upstreamChartRemaining];

                thisLink.downstreamChartData = [thisLink.downstreamChartExisting, thisLink.downstreamChartPositiveChange,
                thisLink.downstreamChartNegativeChange, thisLink.downstreamChartRemaining];

                thisLink.totalChartData = [thisLink.totalChartExisting, thisLink.totalChartPositiveChange,
                thisLink.totalChartNegativeChange, thisLink.totalChartRemaining];

                //sets the end Aog line graph
                thisLink.aogLineGraphData = [thisLink.resultsGraph, thisLink.downstreamResultsGraph, thisLink.upstreamResultsGraph];
            }
            //set the chart data for summary  
            vm.linkPivotData.summaryDownstreamChartData = [vm.linkPivotData.totalDownstreamChartExisting, vm.linkPivotData.totalDownstreamChartPositiveChange,
                vm.linkPivotData.totalDownstreamChartNegativeChange, vm.linkPivotData.totalDownstreamChartRemaining];

            vm.linkPivotData.summaryUpstreamChartData = [vm.linkPivotData.totalUpstreamChartExisting, vm.linkPivotData.totalUpstreamChartPositiveChange,
                vm.linkPivotData.totalUpstreamChartNegativeChange, vm.linkPivotData.totalUpstreamChartRemaining];

            vm.linkPivotData.summaryTotalChartData = [vm.linkPivotData.totalChartExisting, vm.linkPivotData.totalChartPositiveChange,
                    vm.linkPivotData.totalChartNegativeChange, vm.linkPivotData.totalChartRemaining];
        }

        function getOptions() {
            vm.fetchingOptions = true;
            vm.purdueLinkPivotService.getLinkPivotOptions() 
            .then(function (data) {
                vm.options = data;
                vm.fetchingOptions = false;
                vm.updateMap();
                vm.refreshData();
            });
        };

        function setDataTableOptions() {
            vm.tableOptions = {
                rowSelection: false,
                multiSelect: false,
                autoSelect: false,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: false,
                pageSelect: false,
                filter: {
                  debounce: 500
                }
            };
        }

        function adjustOffset() {
             var rowCount = vm.linkPivotData.adjustments.length;
             var cycleLength = vm.options.cycleLength
        
             var cumulativeChange = 0;
             for (var i = rowCount - 1; i >= 0; i--) {
                 var thisAdjustment = vm.linkPivotData.adjustments[i];
                //Get the offset
                var offset = cumulativeChange + parseInt(thisAdjustment.newDelta);
                //add it to existing offset
                if (offset >= cycleLength) {
                    var tempOffset = offset;
                    while (tempOffset >= cycleLength)
                    {
                        tempOffset = tempOffset - cycleLength;
                    }
                    thisAdjustment.adjustment = tempOffset;
                }
                else
                {
                    thisAdjustment.adjustment = offset;
                }
                 //Get the new offset
                 var newOffset = offset + parseInt(thisAdjustment.existingOffset);
                //Check to make sure that the new offset isn't greater than the cycle length and then set it to column 8
                if (newOffset > cycleLength) {
                    var tempNewOffset = newOffset;
                    while (tempNewOffset > cycleLength) {
                        tempNewOffset = tempNewOffset - cycleLength;
                    }
                    thisAdjustment.newOffset = tempNewOffset;
                }
                else {
                    thisAdjustment.newOffset = newOffset;
                }
        
                 //update the cumulative change
                 cumulativeChange = offset;
            }
        }
    }
}());
