﻿(function () {
    "use strict";

    angular.module("app.spm.link-pivot")
        .factory("purdueLinkPivotResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            return $resource(environmentConfig.apiUrl + "/api/LinkPivot/", {}, {
                getOptions: {
                    url: environmentConfig.apiUrl + "/api/LinkPivot/GetLinkPivotOptions",
                    method: "GET",
                },
                update: {
                    url: environmentConfig.apiUrl + "/api/LinkPivot/PostLinkPivotResult/",
                    method: "POST",
                },
            });
        }]);
}());
