﻿(function () {
    "use strict";

    angular.module("app.spm.action-logs")
        .factory("actionLogsResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            return $resource(environmentConfig.apiUrl + "/api/Menus/", {}, {
                query: { method: "GET", isArray: true },
                update: { method: 'PUT' },
                create: { method: "POST" },
                get: { method: "GET" },
                remove: { method: "DELETE" },
            });
        }]);
}());
