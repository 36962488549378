(function () {
    "use strict";

    angular.module("app.spm.core")
        .factory("agencyResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            var spmApiPath = environmentConfig.apiUrl + "/api/";

            return $resource(spmApiPath, {}, {
                getAgencySettings: {
                    method: "GET",
                    url: spmApiPath + "agency/",
                    cache: true
                }
            });
        }]);
}());
