
import * as verticalNav from 'raw-loader!./core/layouts/vertical-navigation.html'
import * as verticalToolbar from 'raw-loader!./toolbar/layouts/vertical-navigation/toolbar.html'
import * as verticalNavigation from 'raw-loader!./navigation/layouts/vertical-navigation/navigation.html'
import * as verticalSearchbar from 'raw-loader!./searchbar/layouts/vertical-navigation/searchbar.html'
import * as SIDEPANEL from 'raw-loader!./main/spm/insights-sidepanel/insights-sidepanel.html'
import angular from 'angular';
import 'angular-cookies';
import 'angular-material';
import 'angular-material-data-table';
import 'angular-sanitize';
import 'angular-storage';

import 'auth0-js';
import 'jquery';
import 'd3';
import 'angular-auth0';
import 'angular-translate';
import 'angular-jwt';
// import 'flatpickr';
import 'ngstorage';
import uiRouter from '@uirouter/angularjs';
import '@uirouter/angularjs/release/stateEvents';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import 'moment-timezone';
import 'moment-duration-format';
import { AppComponent } from '../app/app.component';

import './index';
import './core';
import './authentication';
import '../index.constants';
import '../index.constants.json';
import './main';
import './custom-plugins';
import './navigation';
import './searchbar';
import './toolbar';

export const mainModule = angular
    .module('traffop', [
        uiRouter,
        'ngCookies',
        'ngMaterial',
        'ngSanitize',
        'ngStorage',
        'md.data.table',
        'pascalprecht.translate',
        'angular-storage',
        'config',
        'ui.router.state.events',
        'angular-flatpickr',
        'auth0.auth0',
        'angular-jwt',
        'traffop.index',
        'traffop.main',
        'traffop.login',
        // Core
        'app.core',
        'app.spm',
        // Navigation
        'app.navigation',
        // Toolbar
        'app.toolbar',
        'app.searchbar',
        // Quick Panel
        // 'app.quick-panel',
        //// Apps
        //'app.calendar',
        ////'app.mail',
        //'app.file-manager',
        //'app.contacts',
        //'app.notes',

        // Pages
        'app.pages',
    ]).directive(
        'traffop',
        downgradeComponent({
            component: AppComponent
        })
    ).config(routeConfig).run(runBlock)
    .config(config);


function runBlock($rootScope, $timeout, $state, $animate, authManager, authService, jwtHelper, $location, searchBarService, breadcrumbNavigationService) {
    $rootScope.isOnline = window.navigator.onLine;
    //setup online/offline internet connectivity
    // Add event listener offline to detect network failure.
    window.addEventListener("offline", switchedOffline);
    // Add event listener online to detect network recovery.
    window.addEventListener("online", switchedOnline);

    function switchedOnline(e) {
        $rootScope.isOnline = true;
    }

    function switchedOffline(e) {
        $rootScope.isOnline = false;
    }
    // 3rd Party Dependencies
    // editableThemes.default.submitTpl = '<md-button class="md-icon-button" type="submit" aria-label="save"><md-icon md-font-icon="icon-checkbox-marked-circle" class="md-accent-fg md-hue-1"></md-icon></md-button>';
    // editableThemes.default.cancelTpl = '<md-button class="md-icon-button" ng-click="$form.$cancel()" aria-label="cancel"><md-icon md-font-icon="icon-close-circle" class="icon-cancel"></md-icon></md-button>';

    //check for auth callback
    authService.handleAuthentication();

    //setup sentry logging
    // sentryService.init();

    // Activate loading indicator
    var stateChangeStartEvent = $rootScope.$on('$stateChangeStart', function (event, toState) {
        $rootScope.loadingProgress = true;
        breadcrumbNavigationService.addBreadcrumbItem(toState);
    });

    // De-activate loading indicator
    var stateChangeSuccessEvent = $rootScope.$on('$stateChangeSuccess', function () {
        $timeout(function () {
            $rootScope.loadingProgress = false;
        });
    });

    // Store state in the root scope for easy access
    $rootScope.state = $state;

    // Cleanup
    $rootScope.$on('$destroy', function () {
        stateChangeStartEvent();
        stateChangeSuccessEvent();
        window.removeEventListener("offline", switchedOffline);
        window.removeEventListener("online", switchedOnline);
    });

    // Put the authService on $rootScope so its methods
    // can be accessed from the nav bar
    $rootScope.authService = authService;

    // Use the authManager from angular-jwt to check for
    // the user's authentication state when the page is
    // refreshed and maintain authentication
    authManager.checkAuthOnRefresh();
    authManager.redirectWhenUnauthenticated();

    $rootScope.userInfo = {};
    //$rootScope.$on('$stateChangeStart', function (event, to, toParams) {
    //    authService.isAuthenticated(to);
    //});

    $rootScope.$on('$viewContentLoaded', function (event) {
        $rootScope.$broadcast('msSplashScreen::remove');
    });
    // $animate.enabled(false);       

    //let's check if this window is a child window from another tab
    let newWindowParams = localStorage.getItem('__new_window_open_parameters');
    if (newWindowParams) {
        //update our search params 
        let paramsObj = JSON.parse(newWindowParams);
        if (paramsObj.signal) {
            searchBarService.setSignal(paramsObj.signal);
        }
        if (paramsObj.timeConfig) {
            searchBarService.setTimeOptions(paramsObj.timeConfig);
        }
        if (paramsObj.state) {
            $state.go(paramsObj.state, { inputParameters: paramsObj.inputParameters });
        }
        else {
            $state.go('login');
        }
        //remove from localstorage we no longer want this
        localStorage.removeItem('__new_window_open_parameters');
    }

}
function config($translateProvider,  $animateProvider, $provide)
{
    // Put your common app configurations here

    // uiGmapgoogle-maps configuration
    //uiGmapGoogleMapApiProvider.configure({
    //    //    key: 'your api key',
    //    v        : '3.exp',
    //    libraries: 'weather,geometry,visualization'
    //});

    $animateProvider.classNameFilter(/traffop-animated/);     

    // angular-translate configuration
    $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: 'assets/i18n/{part}/{lang}.json'
    });
    $translateProvider.preferredLanguage('en');
    $translateProvider.useSanitizeValueStrategy('sanitize');
}

function routeConfig($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, environmentConfig, angularAuth0Provider, jwtOptionsProvider) {
    angularAuth0Provider.init({
        clientID: environmentConfig.authClient,
        domain: environmentConfig.authDomain,
        responseType: 'token',
        redirectUri: environmentConfig.rootUrl,
        scope: 'openid email name username'
    });

    jwtOptionsProvider.config({
        tokenGetter: function () {
            return sessionStorage.getItem('id_token');
        },
        unauthenticatedRedirector: ['$state', function ($state) {
            sessionStorage.clear();
            localStorage.clear();
            $state.go('login');
        }],
        unauthenticatedRedirectPath: '/login',
        whiteListedDomains: [environmentConfig.apiUrl]
    });
    $httpProvider.interceptors.push('jwtInterceptor');
    $locationProvider.html5Mode(true);

    $urlRouterProvider.otherwise(function ($injector, $location) {
        var state = $injector.get('$state');
        if (state.current.name === "callback" || $location.path() === "/spm-open-new-window") {
            return $location.path();
        }
        //handle root nav with NO id token, navigate to login page
        else if (!sessionStorage.getItem('id_token')) {
            state.go('login');
        }
        //Not callback, new window or root, then try and navigate
        else {
            // $state.go('login');
            state.go('app.spm.dashboards.map-overview');
        }
        return $location.path();
    });

    //$urlRouterProvider.otherwise('/login');
    /**
     * Layout Style Switcher
     *
     * This code is here for demonstration purposes.
     * If you don't need to switch between the layout
     * styles like in the demo, you can set one manually by
     * typing the template urls into the `State definitions`
     * area and remove this code
     */
    // Inject $cookies
    var $cookies;

    angular.injector(['ngCookies']).invoke([
        '$cookies', function (_$cookies) {
            $cookies = _$cookies;
        }
    ]);

    // Get active layout
    var layoutStyle = $cookies.get('layoutStyle') || 'verticalNavigation';

    var layouts = {
        verticalNavigation: {
            main: verticalNav,
            toolbar: verticalToolbar,
            navigation: verticalNavigation,
            searchbar: verticalSearchbar,
            notificationBar: ''
        },
        // verticalNavigationFullwidthToolbar: {
        //     main: 'app/core/layouts/vertical-navigation-fullwidth-toolbar.html',
        //     toolbar: 'app/toolbar/layouts/vertical-navigation-fullwidth-toolbar/toolbar.html',
        //     navigation: 'app/navigation/layouts/vertical-navigation/navigation.html',
        //     searchbar: '',
        //     notificationBar: ''
        // },
        // verticalNavigationFullwidthToolbar2: {
        //     main: 'app/core/layouts/vertical-navigation-fullwidth-toolbar-2.html',
        //     toolbar: 'app/toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.html',
        //     navigation: 'app/navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.html',
        //     searchbar: '',
        //     notificationBar: ''
        // },
        // horizontalNavigation: {
        //     main: 'app/core/layouts/horizontal-navigation.html',
        //     toolbar: 'app/toolbar/layouts/horizontal-navigation/toolbar.html',
        //     navigation: 'app/navigation/layouts/horizontal-navigation/navigation.html',
        //     searchbar: '',
        //     notificationBar: ''
        // },
        // contentOnly: {
        //     main: 'app/core/layouts/content-only.html',
        //     toolbar: '',
        //     navigation: '',
        //     searchbar: '',
        //     notificationBar: ''

        // },
        // contentWithToolbar: {
        //     main: 'app/core/layouts/content-with-toolbar.html',
        //     toolbar: 'app/toolbar/layouts/content-with-toolbar/toolbar.html',
        //     navigation: '',
        //     searchbar: '',
        //     notificationBar: ''
        // }
    };
    // END - Layout Style Switcher

    // State definitions
    $stateProvider
        .state('app', {
            abstract: true,
            views: {
                'main@': {
                    template: layouts[layoutStyle].main,
                    controller: 'MainController as vm'
                },
                'toolbar@app': {
                    template: layouts[layoutStyle].toolbar,
                    controller: 'ToolbarController as vm'
                },
                'searchbar@app': {
                    template: layouts[layoutStyle].searchbar,
                    controller: 'SearchbarController as vm'
                },
                'navigation@app': {
                    template: layouts[layoutStyle].navigation,
                    controller: 'NavigationController as vm'
                },
                // 'quickPanel@app': {
                //     template: quickPanel,
                //     controller: 'QuickPanelController as vm'
                // },
                'insightsRightMenu@app': {
                    template: SIDEPANEL,
                    controller: 'insightsRightMenuController as vm'
                },
                'notificationBar@app': {
                    template: layouts[layoutStyle].notificationBar,
                    controller: 'notificationBarController as vm'
                },
            }
        });
}


