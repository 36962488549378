angular.module("app.spm").factory('commonHttpInterceptor', function ($q, environmentConfig) {
    return {
        // optional method
        request: function (config) {
            //add the bearer token into every request
            if (config.url.startsWith(environmentConfig.apiUrl + "/api"))
                config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('id_token');

            return config;
        },

        // optional method
        'requestError': function (rejection) {
            // do something on error
            if (canRecover(rejection)) {
                return responseOrNewPromise
            }
            return $q.reject(rejection);
        },

        // optional method
        'response': function (response) {
            // do something on success
            if (response.config.method == "PUT" || response.config.method == "POST") {
                //Flash.create('success', "Data saved successfully!", 5000, { container: 'flashMsg' });
            }
            return response;
        },

        // optional method
        'responseError': function (rejection) {
            // var error = "";
            // if (rejection.status == "400" && rejection.data && rejection.data.modelState) {
            //     error += " Bad request. " + rejection.data.modelState[Object.keys(rejection.data.modelState)[0]];
            // }
            // else if (rejection.status = "500") {
            //     error = "An Internal error happened. Please contact support";
            // }
            //Flash.create('danger', error, 5000, { container: 'flashMsg' });
            return $q.reject(rejection);
        }
    };
});

