import * as TRAVELTIME from 'raw-loader!./dashboard-travel-time.html';

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.travel-time',
           []
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.travel-time', {
                url: '/travel-time',
                views: {
                    'content@app': {
                        templateUrl: TRAVELTIME,
                        controller: 'DashboardTravelTimeController as vm'
                    }
                }
            });
        $translatePartialLoaderProvider.addPart('travel-time');
    }
})();