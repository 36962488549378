import * as FORGOTPASS from 'raw-loader!./forgot-password.html';

(function ()
{
    'use strict';

    angular
        .module('traffop.forgot_password', [])
        .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider)
    {
        // State
        $stateProvider.state('forgot-password', {
            url      : '/forgot-password',
            views    : {
                'main@'                                 : {
                    template: FORGOTPASS,
                    controller: 'ForgotPasswordController as vm'
                },
            },
            bodyClass: 'forgot-password'
        });

        // Translation
        $translatePartialLoaderProvider.addPart('forgot-password');
    }

})();