﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("phaseTerminationCountController", phaseTerminationCountController);
  
    function phaseTerminationCountController($state, $scope, $attrs, $rootScope, $element, environmentConfig, chartsService) {
        var vm = this;
        vm.getData = getData;
        vm.getDataAndOptions = getDataAndOptions;
        vm.chartType = 1;
        vm.isDataAvailable = false;
        vm.chartServerError =false;
        vm.metricType = 1;
        
        $scope.updateData = function(data){
            if (data && data != "{}") {
                var jsonData = JSON.stringify(data, null, 4);
                vm.chartServerError =false;
                vm.phaseTermSunburstWidget.processTerminationsDataSunburst(JSON.parse(jsonData, JSON.dateParser));
                $scope.loading = false;
            }
        }
        $scope.updateFetchData = function update(signal, dates) {
            if (signal) {
                $scope.signal = signal;
            }
            if (dates) {
                if (!$scope.searchDates)
                    $scope.searchDates = {};

                $scope.searchDates.endDateAndTime = new Date(dates.endDateAndTime);
                $scope.searchDates.startDateAndTime = new Date(dates.startDateAndTime);
            }
            if (vm)
                vm.getDataAndOptions(true);
        }   

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            $scope.api = {};
            $scope.onApiInit = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        vm.phaseTermSunburstWidget = {
            //logic for taking server events and manipulating into format the chart is expecting
            processTerminationsDataSunburst: function (rawData) {
                var terminations = {
                    name: "Terminations",
                    key: "terminations",
                    noLegend: true,
                    children: [],
                    percentage: 0,
                    size: 0,
                };
                var gapOutInfo = {
                    name: "Gap Out",
                    key: "gap-out",
                    children: [],
                    percentage: 0,
                    size: 0
                };
                var maxOutInfo = {
                    name: "Max Out",
                    key: "max-out",
                    children: [],
                    percentage: 0,
                    size: 0
                };
                var forceOffInfo = {
                    name: "Force Off",
                    key: "force-off",
                    children: [],
                    percentage: 0,
                    size: 0
                };
                var unknownInfo = {
                    name: "Unknown",
                    key: "unknown",
                    children: [],
                    percentage: 0,
                    size: 0
                };
                var terminationCountsPerPhase = {};
                var daysPerPhase = {};

                if(rawData && rawData.length>0){
                    vm.isDataAvailable = true;
                } 
                else {
                    vm.isDataAvailable = false;                  
                }                

                rawData.phases.forEach(function (phaseItem) {
                    var phaseNum = phaseItem.phaseNumber;
                    var terminationCounts = {};
                    var days = {};
                    phaseItem.cycles.forEach(function (cycleItem) {
                        var startDate = new Date(cycleItem.startTime);
                        var tCount = 0, dCount = 0;
                        if (terminationCounts[cycleItem.terminationEvent])
                            tCount = terminationCounts[cycleItem.terminationEvent];

                        terminationCounts[cycleItem.terminationEvent] = ++tCount;

                        if (!days[cycleItem.terminationEvent])
                            days[cycleItem.terminationEvent] = {};

                        if (days[cycleItem.terminationEvent][startDate.toString('dddd')])
                            dCount = days[cycleItem.terminationEvent][startDate.toString('dddd')];

                        days[cycleItem.terminationEvent][startDate.toString('dddd')] = ++dCount;
                    });
                    terminationCountsPerPhase[phaseNum] = terminationCounts;
                    daysPerPhase[phaseNum] = days;
                });

                for (var key in terminationCountsPerPhase) {
                    var count = 0;
                    if (terminationCountsPerPhase[key][4])
                        count = terminationCountsPerPhase[key][4];

                    var newPhase = {
                        name: "Phase " + key,
                        key: "phase " + key + " gap-out",
                        size: count,
                        children: []
                    };
                    gapOutInfo.children.push(newPhase);

                    for (var dayName in daysPerPhase[key][4]) {
                        newPhase.children.push({
                            name: dayName,
                            size: daysPerPhase[key][4][dayName]
                        });
                    }
                    gapOutInfo.size = gapOutInfo.size + count;
                    count = 0;
                    if (terminationCountsPerPhase[key][6])
                        count = terminationCountsPerPhase[key][6];

                    newPhase = {
                        name: "Phase " + key,
                        key: "phase " + key + " force-off",
                        size: count,
                        children: []
                    };
                    forceOffInfo.children.push(newPhase);
                    for (var dayName in daysPerPhase[key][6]) {
                        newPhase.children.push({
                            name: dayName,
                            size: daysPerPhase[key][6][dayName]
                        });
                    }
                    forceOffInfo.size = forceOffInfo.size + count;
                    count = 0;

                    if (terminationCountsPerPhase[key][5])
                        count = terminationCountsPerPhase[key][5];

                    newPhase = {
                        name: "Phase " + key,
                        key: "phase " + key + " max-out",
                        size: count,
                        children: []
                    };
                    maxOutInfo.children.push(newPhase);
                    for (var dayName in daysPerPhase[key][5]) {
                        newPhase.children.push({
                            name: dayName,
                            size: daysPerPhase[key][5][dayName]
                        });
                    }
                    maxOutInfo.size = maxOutInfo.size + count;
                    count = 0;
                    if (terminationCountsPerPhase[key][0])
                        count = terminationCountsPerPhase[key][0];

                    newPhase = {
                        name: "Phase " + key,
                        key: "phase " + key + " unknown",
                        size: count,
                        children: []
                    };
                    unknownInfo.children.push(newPhase);
                    for (var dayName in daysPerPhase[key][0]) {
                        newPhase.children.push({
                            name: dayName,
                            size: daysPerPhase[key][0][dayName]
                        });
                    }
                    unknownInfo.size = unknownInfo.size + count;
                }
                terminations.children.push(gapOutInfo);
                terminations.children.push(forceOffInfo);
                terminations.children.push(maxOutInfo);
                terminations.children.push(unknownInfo);
                vm.chartArray = [];
                vm.chartArray.push({
                    title: "Phase Terminations",
                    data: [terminations]
                })

            },
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            vm.getDataAndOptions(false);
        }

        function getDataAndOptions(refresh) {
            $scope.loading = true;
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions || refresh) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;

            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions)
            .then(function (data) {
                //JSON stringify the server data and then process it for the chart
                vm.chartServerError = false;
                // vm.rawData = JSON.stringify(data, null, 4);
                vm.phaseTermSunburstWidget.processTerminationsDataSunburst(data.items);
                $scope.loading = false;
            })
            .catch(function (error){
                $scope.loading = false;
                if (vm)
                    vm.chartServerError = true;
        });
        }
    }
}());
