(function () {

    'use strict';

    angular
      .module('app.spm.core')
      .service('controllerEventService', controllerEventService);

    function controllerEventService(eventsResource, $state, $rootScope, FileSaver) {
        function getEventsForQuery(queryObj) {
            return eventsResource.query(queryObj,
                function(data) {
                    return data;
                }).$promise;
        }

        function getSignalDataIntegrity(query) {
            return eventsResource.getDataIntegrity(query,
                function (data) {
                    return data;
                }).$promise;
        }

        function getSystemOverviewData(query) {
            return eventsResource.getSystemOverviewData(query,
                function (data) {
                    return data;
                }).$promise;
        }

        function getDataStatistics(query) {
            return eventsResource.getDataStatistics(query,
                function (data) {
                    return data;
                }).$promise;
        }

        function createDataStatisticsQuery(searchDates, signalIdArray, eventAndParamArray, binSize) {
            var dates = compileStartEndDates(searchDates);
            var res = {
                startDate: dates.startDateTime,
                endDate: dates.endDateTime,
                period: binSize,
                signals: signalIdArray,
                filterEvents: eventAndParamArray
            }
            return res;
        }

        function getEventHistogram(query) {
            return eventsResource.getHistogram(query,
                function (data) {
                    return data;
                }).$promise;
        }

        function createEventHistogramQuery(searchDates, signalIdArray, eventAndParamArray) {
            var dates = compileStartEndDates(searchDates);
            var parameters = [];
            for (var i = 0; i < eventAndParamArray.length; i++) {
                parameters.push(eventAndParamArray[i].eventParam);
            }
            var res = {
                startDate: dates.startDateTime,
                endDate: dates.endDateTime,
                signals: signalIdArray,
                startEvent: 82,
                endEvent: 81,
                eventParams: parameters
            }
            return res;
        }

        function compileStartEndDates(searchDates) {
            var st = new Date(searchDates.startTime);
            var et = new Date(searchDates.endTime);
            var res = {
                startDateTime: {},
                endDateTime: {},
            };
            res.startDateTime = angular.copy(new Date(searchDates.startDate));
            res.startDateTime.setHours(st.getHours(), st.getMinutes(), 0);
            res.startDateTime = res.startDateTime.toLocaleString();
            res.endDateTime = angular.copy(new Date(searchDates.endDate));
            res.endDateTime.setHours(et.getHours(), et.getMinutes(), et.getSeconds());
            res.endDateTime = res.endDateTime.toLocaleString();

            return res;
        }

        return {
            getEventsForQuery: getEventsForQuery,
            getSignalDataIntegrity: getSignalDataIntegrity,
            getSystemOverviewData: getSystemOverviewData,
            getDataStatistics: getDataStatistics,
            getEventHistogram: getEventHistogram,
            createDataStatisticsQuery: createDataStatisticsQuery,
            createEventHistogramQuery: createEventHistogramQuery
        }
    }
})();
