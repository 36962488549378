
import './chartjs-plugin-datalabels';
import './chartjs-plugin-zoom';
import './md-date-range-picker';
import './saveSvgAsPng';
import './datatables-responsive/js/dataTables.responsive';
import './angular-moment-picker';
import './angular-flatpickr';
import './auth0';
import './chartjs-plugin-annotation';
import './d3v5';