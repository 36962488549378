import * as EXECUTIVE from 'raw-loader!./executive-dashboard.html'

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.executive',
           []
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.executive', {
                url: '/executive-dashboard',
                views: {
                    'content@app': {
                        template: EXECUTIVE,
                        controller: 'DashboardExecutiveController as vm'
                    }
                }
            });
        $translatePartialLoaderProvider.addPart('executive');
    }
})();