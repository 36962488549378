(function () {
    'use strict';

    angular.module('app.spm.dashboards.user-settings')
    .directive('chartsSettings', chartsSettingsDirective);

    /** @ngInject */
    function chartsSettingsDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'DashboardChartsSettingsController as vm',
            templateUrl: function (elem, attrs) {
                 return 'app/main/spm/dashboards/user-settings/charts-settings/charts-settings.html';
            },
            scope: {
                loading: '=',
                fetchData:'<',
                spmChartOptions: '<',
                signal: '<',
                searchDates: '<',
                stacked: '<',
                onApiDestroy: '&',
                onSummaryChange: '&',
                api: '=',
            },
            link: function (scope) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        updateData: function (data) {
                            return scope.updateData(data);
                        }
                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();