(function () {
    "use strict";

    angular.module("app.spm.dashboards.monthly-intersection-report")
        .factory("monthlyReportResource", ["$resource", "environmentConfig", "$cacheFactory", function ($resource, environmentConfig, $cacheFactory) {
            var monthlyReportUrl = environmentConfig.apiUrl + "/api/monthlyReport/";
            return $resource(monthlyReportUrl, {}, {
                getMonhtlyIntersectionData: { method: "GET", url: monthlyReportUrl + "signal" }
            });
        }]);
}());