﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("customHistogramChartController", customHistogramChartController);

    function customHistogramChartController($state, $scope, $attrs, $rootScope, $element, chartsService, controllerEventService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 1;
        vm.readyToRenderChart = false;
        vm.chartLoading = false;
        vm.chartServerError = false;
        vm.chartTitle = "";
        vm.metricType = 91;
        vm.getTimeFromTimeFrame = getTimeFromTimeFrame;


        vm.widgetReady = function () {
            vm.chartLoading = true;
            $timeout(function () {
                vm.readyToRenderChart = true;
                vm.chartLoading = false;
            }, 5)
        };

        function getTimeFromTimeFrame() {
            var opts = searchBarService.getSearchOptions();
            $scope.searchDates.endDate = opts.timeOptions.currentFilter.endDateAndTime.toLocaleString();
            $scope.searchDates.startDate = opts.timeOptions.currentFilter.startDateAndTime.toLocaleString();
        }

        function hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ?
                "rgba(" + parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) + "," + 0.7 + ")"
                : null;
        }


        if (!$scope.signalName) {
            var options = searchBarService.getSearchOptions();
            if (options && options.signal)
                $scope.signalName = options.signal.description;
        }
        vm.chartWidget = {
            //logic for taking server events and manipulating into format the chart is expecting
            processHistogramData: function (signalItems) {
                var charts = [];
                var data = [];
                var chartData = [];

                var counter = 0;
                var backgroundColor = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928'];
                var chartLabels = [];
                var chartValues = [];

                if ($scope.chartType == "bar") {
                    // set min and max x-axis range
                    //var durationKeys = Object.keys(signalItems[0].histogramEvents);
                    var minDuration = 100; //parseInt(durationKeys[0]);
                    var maxDuration = 10000; //minDuration + 10000;
                    // populate all x range values
                    for (var index in $scope.legendItems) {
                        if (!data[$scope.legendItems[index]]) {
                            data[$scope.legendItems[index]] = [];
                        }
                        for (var i = minDuration; i <= maxDuration; i += 100) {
                            data[$scope.legendItems[index]].push({
                                x: i,
                                y: 0,
                                toolTipData: []
                            });
                        }
                    }

                    // populate chart data
                    for (var key in signalItems) {
                        for (var histogramKey in signalItems[key].histogramEvents) {
                            if ($scope.chartType == "bar") {
                                var index = $scope.legendItems.indexOf(signalItems[key].eventParam);
                                if (index => 0) {
                                    if (!data[$scope.legendItems[index]]) {
                                        data[$scope.legendItems[index]] = [];
                                    }

                                    //create array to hold some tooltip info and stash it inside the chart point object
                                    var toolTipData = [];
                                    if ($scope.approach.detectors && $scope.approach.detectors.length > 0) {
                                        toolTipData.push({
                                            title: "Type",
                                            value: $scope.approach.detectors[index].description
                                        });
                                        toolTipData.push({
                                            title: "Detector ID",
                                            value: $scope.approach.detectors[index].detectorID
                                        });
                                    }
                                    try {
                                        if (histogramKey <= maxDuration && histogramKey % 100 == 0) {
                                            var valueIndex = parseInt((histogramKey - minDuration) / 100);
                                            data[$scope.legendItems[index]][valueIndex].y = signalItems[key].histogramEvents[histogramKey];
                                            data[$scope.legendItems[index]][valueIndex].toolTipData = toolTipData;
                                        }
                                        else if (histogramKey > maxDuration && histogramKey % 100 == 0) {
                                            var valueIndex = parseInt((maxDuration - minDuration) / 100) - 1;
                                            data[$scope.legendItems[index]][valueIndex].y += signalItems[key].histogramEvents[histogramKey];
                                        }
                                    } catch (e) {
                                        console.error(e);
                                    }
                                }
                            }
                        }
                    }
                    // add all values that have data
                    for (var key in data) {
                        if ($scope.chartType == "bar") {

                            //remove key if it has no data
                            var hasValues = false;
                            data[key].forEach(function (value) {
                                //if we hit a value then flip has values to true
                                if (value.y > 0) {
                                    hasValues = true;
                                    return;
                                }
                            });
                            if (hasValues) {
                                chartData.push({
                                    categoryPercentage: 1,
                                    label: "Det " + key,
                                    key: key,
                                    data: data[key],
                                    toolTipData: data[key].find(point => point.y > 0).toolTipData,
                                    type: "bar",
                                    yAxisID: '1',
                                    backgroundColor: hexToRgb(chartsService.chartColors[counter]),
                                    borderColor: chartsService.chartColors[counter],
                                    borderWidth: {
                                        top: 2,
                                        right: 0,
                                        bottom: 2,
                                        left: 0
                                    },
                                    fillOpacity: 1
                                });
                                counter <= 6 ? ++counter : counter = 0;

                            }
                        } else if ($scope.chartType == "donut" && data[key] > 0) {
                            chartData.push({
                                dataset: data[key],
                                key: "Det " + key,
                                backgroundColor: hexToRgb(chartsService.chartColors[counter]),
                                borderColor: chartsService.chartColors[counter],
                                borderWidth: {
                                    top: 2,
                                    right: 0,
                                    bottom: 2,
                                    left: 0
                                },
                                // toolTipData: toolTipData[key],
                                type: 'doughnut',
                                label: "Det " + key,
                                fillOpacity: 1
                            });
                        }
                    }

                    // sort by medium value
                    chartData.sort(function (a, b) {
                        var indexA = signalItems.findIndex(e => e.eventParam == a.key);
                        var indexB = signalItems.findIndex(e => e.eventParam == b.key);
                        return signalItems[indexB].median - signalItems[indexA].median;
                    });

                    // update key value
                    for (var index in chartData) {
                        chartData[index].key = "Det " + chartData[index].key;
                    }
                    var noDetector = false;

                    if ($scope.approach.approachID != "unassigned") {
                        noDetector = $scope.approach.detectors.length <= 0;
                    }
                    else if ($scope.approach.approachID == "unassigned") {
                        noDetector = false;
                    }
                    //push this phase chart back into the main chartArray. Each phase needs it's own chart
                    if ($scope.chartType == "bar") {

                        var searchObj = searchBarService.getSearchOptions();
                        var xScaleData = [];
                        chartData[0].data.forEach(function (datapoint) {
                            xScaleData.push(datapoint.x)
                        })
                        charts.push({
                            title: "Detector Duration Histogram Chart for " + $scope.signalName,
                            //subLine1: "Median: " + signalItems[0].median,
                            dataset: chartData,
                            api: {},
                            isDataAvailable: chartData.length > 0,
                            noDetector: noDetector,
                            onDestroy: function () {
                                this.api = {};
                            },
                            onApiInit: function (apiObj) {
                                this.api = apiObj;
                                this.render(apiObj);
                            },
                            render: function (apiObj) {
                                apiObj.render(undefined, this.dataset, this.chartOptions);
                                vm.chart =
                                    vm.chartRendering = false;
                                this.isRendered = true;
                            },
                            isRendered: false,
                            chartOptions: {
                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        useCategoryZoom: true,
                                        barPercentage: 1,
                                        categoryPercentage: 1,
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Duration (ms)',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        stacked: true,
                                        labels: xScaleData,
                                        id: 'x-axis-0',
                                        type: 'time',
                                        // offset: true,
                                        time: {
                                            parser: 's',
                                            unit: 'second',
                                            displayFormats: {
                                                second: 's,000'
                                            }
                                        },
                                        display: true,
                                        ticks: {
                                            minRotation: 0,
                                            maxRotation: 0,
                                            autoSkip: true,
                                            // autoSkipPadding: 50,
                                            display: true,
                                            suggestedMin: 100,
                                            min: 100,
                                            suggestedMax: 10000,
                                            max: 10000,
                                            beginAtZero: true,
                                            // callback: function (d) {
                                            //     return moment(d).second()*1000
                                            // },
                                        },
                                    },
                                    ],
                                    yAxes: [{
                                        barPercentage: 1,
                                        categoryPercentage: 1,
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Count',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            callback: function (d) {
                                                if (Math.floor(d) != d) return d3.format('.01f')(d);
                                                else return d;
                                            },
                                        },
                                        id: '1',
                                        stacked: true,
                                        type: 'linear',
                                        position: 'left',
                                    }
                                    ]
                                },
                                tooltips: {
                                    enabled: false,
                                    intersect: false,
                                    mode: 'point',
                                    // custom: function (tooltip) {
                                    callbacks: {
                                        afterBody: function (t, d) {
                                            return d.datasets[t[0].datasetIndex].toolTipData
                                        }
                                    },
                                    custom: function (tooltipModel, dtes) {
                                        var tooltipEl = document.getElementById('chartjs-tooltip');

                                        // Create element on first render
                                        if (!tooltipEl) {
                                            tooltipEl = document.createElement('div');
                                            tooltipEl.id = 'chartjs-tooltip';
                                            tooltipEl.innerHTML = '<table></table>';
                                            document.body.appendChild(tooltipEl);
                                        }

                                        // Hide if no tooltip
                                        if (tooltipModel.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            return;
                                        }

                                        // Set caret Position
                                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                                        if (tooltipModel.yAlign) {
                                            tooltipEl.classList.add(tooltipModel.yAlign);
                                        } else {
                                            tooltipEl.classList.add('no-transform');
                                        }

                                        function getBody(bodyItem) {
                                            return bodyItem.lines;
                                        }
                                        var bodyLines = tooltipModel.body.map(getBody);
                                        if (tooltipModel.body) {
                                            var rows = "<tr>" +
                                                "<td class='key'>" + 'Count: ' + "</td>" +
                                                "<td class='x-value'><strong>" + tooltipModel.dataPoints[0].yLabel + "</strong></td>" +
                                                "</tr>";
                                            rows += "<tr>" +
                                                "<td class='key'>" + 'Time: ' + "</td>" +
                                                "<td class='x-value'><strong>" + (100 + Number(tooltipModel.dataPoints[0].index * 100)) + "</strong></td>" +
                                                "</tr>";
                                            tooltipModel.afterBody
                                                .forEach(function (toolTipData) {
                                                    rows += "<tr>" +
                                                        "<td class='key'>" + toolTipData.title + ":</td>" +
                                                        "<td class='x-value'><strong>" + toolTipData.value + "</strong></td>" +
                                                        "</tr>";
                                                });
                                            var header =
                                                "<thead>" +
                                                "<tr>" +
                                                "<td class='key'><strong>" + tooltipModel.body[0].lines[0].split(':')[0] + "</strong></td>" +
                                                "</tr>" +
                                                "</thead>";

                                            var innerHTML = "<table>" +
                                                header +
                                                "<tbody>" +
                                                rows +
                                                "</tbody>" +
                                                "</table>";

                                            var tableRoot = tooltipEl.querySelector('table');
                                            var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                            tableStyle += 'color: rgba(255,255,255, 1);';
                                            tableStyle += 'padding: 5px 10px;';

                                            tableRoot.style = tableStyle;
                                            tableRoot.innerHTML = innerHTML;
                                            vm.ttRows = "";
                                        }

                                        // `this` will be the overall tooltip
                                        var position = this._chart.canvas.getBoundingClientRect();
                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.position = 'absolute';
                                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                        if (position.left + window.pageXOffset + tooltipModel.caretX + 255 > window.innerWidth) {
                                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 100 + 'px';
                                        }
                                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                        tooltipEl.style.pointerEvents = 'none';
                                    }
                                },
                                legend: {
                                    display: true,
                                    align: 'end',
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                hover: {
                                    animationDuration: 0
                                },
                                ignoreDate: true,
                                plugins: {
                                    zoom: {
                                        pan: {
                                            enabled: false,
                                            mode: 'x',

                                            rangeMin: {
                                                x: 100
                                            },
                                            rangeMax: {
                                                x: 10000
                                            }
                                        },
                                        zoom: {
                                            enabled: true,
                                            // sensitivity: 1,
                                            drag: true,
                                            speed: 0.1,
                                            mode: 'x',

                                            rangeMin: {
                                                x: 100
                                            },
                                            rangeMax: {
                                                x: 10000
                                            }
                                        }
                                    }
                                },
                            }
                        });
                    }
                }
                if ($scope.chartType == "donut") {
                    // add median data
                    for (var key in signalItems) {
                        // make sure it is in current legend
                        var index = $scope.legendItems.indexOf(signalItems[key].eventParam);
                        if (index => 0) {
                            chartValues.push(signalItems[key].median);
                            chartLabels.push("Det " + signalItems[key].eventParam);
                            chartData.push({
                                dataset: data[key],
                                backgroundColor: backgroundColor[counter],
                                color: backgroundColor[counter],
                                type: 'doughnut',
                                label: "Det " + signalItems[key].eventParam,
                                fillOpacity: 1
                            });

                            chartData.sort(function (a, b) {
                                return b.value - a.value
                            });

                            hasValues = true;
                        }
                    }
                    var noDetector = false;

                    if ($scope.approach.approachID != "unassigned") {
                        noDetector = $scope.approach.detectors.length <= 0;
                    }
                    else if ($scope.approach.approachID == "unassigned") {
                        noDetector = false;
                    }

                    var pieData = [
                        {
                            fill: true,
                            backgroundColor: backgroundColor,
                            data: chartValues,
                            borderWidth: 0
                        }
                    ]

                    var options = {
                        elements: {
                           arc: {
                              borderWidth: 0
                           }
                        },
                        chartType: 'doughnut',
                        borderWidth: 0,
                        title: {
                            display: true,
                            position: 'top'
                        },
                        legend: {
                            display: true
                        },
                        tooltips: {
                            enabled: true,
                            mode: 'single',
                            // callbacks: {
                            //     label: function (tooltipItems, data) {
                            //         var i = tooltipItems.index;
                            //         return data.labels[i] + " State: " + data.datasets[0].data[i] + " signals";
                            //     }
                            // } 
                        },
                        paddingBelowLegend: true,
                        layout: {
                            padding: {
                                bottom: 30
                            }
                        },
                        rotation: -0.7 * Math.PI,
                        plugins: {

                            datalabels: {
                                align: 'end',
                                anchor: "end",
                                offset: 8,
                                display: function (context) {
                                    let sum = 0;
                                    let dataArr = context.chart.data.datasets[0].data;
                                    dataArr.map(data => {
                                        sum += data;
                                    });
                                    let percentage = (context.dataset.data[context.dataIndex] * 100 / sum).toFixed(2);
                                    return percentage >= 5; // or >= 1 or ...
                                },
                                formatter: (value, ctx) => {

                                    let sum = 0;
                                    let dataArr = ctx.chart.data.datasets[0].data;
                                    dataArr.map(data => {
                                        sum += data;
                                    });
                                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                                    return percentage;


                                },
                                color: '#000',
                            },
                        }
                    };
                    charts.push({
                        title: "Median Values for Detector Durations for " + $scope.signalName,
                        // dataset: chartData,
                        data: pieData,
                        api: {},
                        isDataAvailable: chartData.length > 0,
                        noDetector: noDetector,
                        backgroundColor: backgroundColor,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(this.labels, this.data, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        isRendered: false,
                        chartOptions: options,
                        labels: chartLabels,
                    }
                    );
                }
                vm.chartWidget.chartArray = charts;

            }
        }

        function update(signals, approach, dates) {
            if (signals) {
                $scope.signals = signals;
            }
            if (approach) {
                $scope.approach = approach;
            }
            if (dates) {
                if (!$scope.searchDates)
                    $scope.searchDates = {};

                $scope.searchDates.endDateAndTime = new Date(dates.endDateAndTime);
                $scope.searchDates.startDateAndTime = new Date(dates.startDateAndTime);
            }
            if (vm && vm.getData)
                vm.getData();
        }
        function getData() {
            //gets data from the server
            vm.chartWidget.chartArray = [];
            $scope.loading = true;
            vm.getTimeFromTimeFrame();

            if ($scope.chartType == "donut") {
                vm.chartTitle = "Median Values for Detector Durations for " + $scope.signalName;
            }
            else if ($scope.chartType == "bar") {
                vm.chartTitle = "Detector Duration Histogram Chart for " + $scope.signalName;
            }
            else if ($scope.chartType == "histogram") {
                vm.chartTitle = "Detector Duration Histogram Chart for " + $scope.signalName;
            }
            //pass the object to the server
            var sendHistogramObject = controllerEventService.createEventHistogramQuery($scope.searchDates, $scope.signals, $scope.eventsWithParameters);
            controllerEventService.getEventHistogram(sendHistogramObject)
                .then(function (data) {
                    if (vm && vm.chartWidget) {
                        //JSON stringify the server data and then process it for the chart
                        vm.chartServerError = false;
                        // vm.rawData = JSON.stringify(data, null, 4);
                        vm.chartWidget.processHistogramData(data.signalEventHistogram);
                    }
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }

        $scope.update = update;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.searchDates = null;
            $scope.signals = null;
            $scope.eventsWithParameters = null;
            $scope.signals = null;
            $scope.legendItems = null;
            $scope.rawData = null;

        });

        if ($scope.fetchData) {
            vm.getData();
        }
    }
}());
