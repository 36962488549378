﻿import * as TRENDSCARD from 'raw-loader!./signal-trends-card.html';

(function () {
    'use strict';

    angular.module('app.spm.signals')
        .directive('signalTrendsCard', signalTrendsCardDirective);

    /** @ngInject */
    function signalTrendsCardDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'signalTrendsCardController as vm',
            template: function (elem, attrs) {
                 return TRENDSCARD;
            },
            scope: {
                metricType: '<',
                chartType: '<',
                isExpanded: '<',
                hideButtons: '<',
                api: '=',
                onApiInit: '&',
                onApiDestroy: '&',
                aggregationType: '<',
                legendTitle: '<',
                loading: "=",
                chartTitle: '=',
                isSquareRoot: '<',
                reverseColorScale: '<',
                isAverage: '<',
                showTotal: '<',
                label:'='
            },
            link: function (scope, element, attrs) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        update: function (data) {
                            scope.update(data);
                        },
                        expand: function () {
                            scope.expand();
                        }
                    });

                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();