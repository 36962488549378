﻿import * as EDITCONTEXTMENU from 'raw-loader!./edit-context-menu.html'

(function () {
    'use strict';

    angular.module('app.spm.core')
    .directive('editContextMenu', editContextMenu);

    /** @ngInject */
    function editContextMenu($mdMenu) {
        function link(scope, element, attrs) {
           
        }

        return {
            restrict: 'E',
            link: link,
            scope: {
                editAction: '&',
                deleteAction: '&',
                excelExportFromMenu: '&',
                editObject: '=',
                noExcel: '='
            },
            controller: function ($scope) {
                $scope.editClicked = function (ev) {
                    if ($scope.editAction) {
                        $scope.editAction({ obj: $scope.editObject });
                    }
                }
                $scope.deleteClicked = function (ev) {
                    if ($scope.deleteAction) {
                        $scope.deleteAction({ obj: $scope.editObject });
                    }
                }
                $scope.excelExportClicked = function (ev) {
                    if ($scope.excelExportFromMenu) {
                        $scope.excelExportFromMenu({ obj: $scope.editObject });
                    }
                }
                $scope.noExcelFlag = $scope.noExcel;
            },
            template: EDITCONTEXTMENU
        };
    }
})();