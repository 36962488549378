﻿(function () {
    "use strict";

    angular
        .module("app.spm.event-player")
        .controller("eventPlayerWidgetController", eventPlayerWidgetController);

    function eventPlayerWidgetController($state, $attrs, $scope, $rootScope, $element, dataExportService, controllerEventService, $interval) {
        var vm = this;
        vm.loading = $scope.loading;
        vm.formatDateTimeEvents = formatDateTimeEvents;
        $scope.update = update;
        vm.setupTimeline = setupTimeline;
        vm.timelineObj = undefined;
        vm.loadPlayer = loadPlayer;
        vm.timelineInterval = undefined;
        vm.timelineSpeedMs = 100;
        vm.currentSpeedPercentage = 100;
        vm.baselineSpeed = 100;
        vm.currentTime = "";
        vm.resetTimelineInterval = resetTimelineInterval;
        vm.increaseSpeed = increaseSpeed;
        vm.decreaseSpeed = decreaseSpeed; 
        vm.timeChange = timeChange;
        vm.stop = stop;
        vm.goForward = goForward;
        vm.goBack = goBack;
        vm.paused = false;
        vm.fakeData = [];
        vm.addData = addData;
        vm.updateData = updateData;

        //create the signaleventswidget
        vm.signalEventsWidget = {
            title: "Events",
            statusData: [],
            selected: [],
        };

        function setupTimeline() {
            //if we have timelineObj already then destroy it
            if (vm.timelineObj) {
                vm.timelineObj.destroy();
                vm.timelineObj = undefined;
            }
            //grab our container div element
            var timelineDiv = $element.find('#timeline')[0];

            // Create a DataSet for time period based on events
            //can add items directly into timeline
            var items = new vis.DataSet();

            // Configuration for the timeline
            var start = new Date(vm.timeOptions.currentFilter.startDateAndTime);
            var newEnd = new Date(start.getTime() + 5 * 60000);

            var options =
            {
                start: start,
                end: newEnd,
                moveable: false,
                clickToUse: false,
                height: '100%',
                width: '100%',
                autoResize: true,
                horizontalScroll: true,
                showCurrentTime: false,
            };

            // create the timeline
            vm.timelineObj = new vis.Timeline(timelineDiv, null, null, options);
            //add the custom time marker and kick off the interval
            vm.timelineObj.addCustomTime(start, 1);
            vm.resetTimelineInterval();
        }

        //called from api obj. will refresh data with new parameters passed in
        function update(signal, timeOptions) {
            if (!vm)
                return;

            if (signal)
                vm.signal = signal;
            if (timeOptions)
                vm.timeOptions = timeOptions;

            vm.loadPlayer();
        }

        function loadPlayer() {
            vm.addData();
            //create player and fetch events
            vm.setupTimeline();
        }

        function stop() {
            if (vm.timelineInterval) {
                //stop the existing interval timer
                $interval.cancel(vm.timelineInterval);
                vm.timelineInterval = undefined;
                vm.paused = true;
            }
        }

        function resetTimelineInterval() {
            //stop timer
            vm.stop();

            //setup new timer
            vm.timelineInterval = $interval(vm.timeChange, 100);
            vm.paused = false;

        }

        function timeChange () {
            //get current time and figure out next time based on playback speed
            var currTime = vm.timelineObj.getCustomTime(1);
            var newTime = new Date(new Date(currTime).setMilliseconds(currTime.getMilliseconds() + vm.timelineSpeedMs));
            //set current time object back into timeline
            vm.timelineObj.setCustomTime(newTime, 1);
            //update binding card var
            vm.currentTime = vm.formatDateTimeEvents(newTime);

            //update data
            vm.updateData(newTime);
        }

        function updateData(time) {
            //using fake data for now. in future use time parameter to filter through actual events
            if (time.getSeconds() == 0) {
                vm.addData();
            }
            //switch data sets at 30 and increment phase uptime values
            if (time.getSeconds() > 30) {
                vm.signalEventsWidget.statusData[0] = vm.fakeData[1][0];
                var phaseUptime = vm.signalEventsWidget.statusData.filter(x => x.name == "Phase Up Time")[0];
                phaseUptime.values[3] = parseFloat((phaseUptime.values[3] + (vm.timelineSpeedMs / 1000)).toFixed(1));
                phaseUptime.values[7] = parseFloat((phaseUptime.values[7] + (vm.timelineSpeedMs / 1000)).toFixed(1));
                if (time.getSeconds() % 2) {

                }
            }
            else if (time.getSeconds() < 30) {
                vm.signalEventsWidget.statusData[0] = vm.fakeData[0][0];
                var phaseUptime = vm.signalEventsWidget.statusData.filter(x => x.name == "Phase Up Time")[0];
                phaseUptime.values[1] = parseFloat((phaseUptime.values[1] + (vm.timelineSpeedMs / 1000)).toFixed(1));
                phaseUptime.values[5] = parseFloat((phaseUptime.values[5] + (vm.timelineSpeedMs / 1000)).toFixed(1));
                if (time.getSeconds() % 2) {

                }
            }
        }

        function formatDateTimeEvents(input) {
            //format time to have single millisecond appended as decimal
            var options = {hour: "2-digit", minute: "2-digit", second: "numeric",
            };
            var inputDate = new Date(input);
            var inputMS = inputDate.getMilliseconds().toString().charAt(0);
            if (inputMS === "0") {
                inputMS = "0";
            }
            var time = inputDate.toLocaleTimeString("en-us", options);
            var amPM = time.substr(time.length - 2, time.length);
            time = time.substr(0, time.length - 3);
            return time = time + "." + inputMS + " " + amPM;
        }

        function increaseSpeed() {
            //increase playback speed by 25%
            if (vm.timelineSpeedMs > 0 && (vm.currentSpeedPercentage + 25) <= 200) {
                vm.currentSpeedPercentage = vm.currentSpeedPercentage + 25;
                vm.timelineSpeedMs = parseInt(vm.baselineSpeed * (vm.currentSpeedPercentage / 100));
            }
        }

        function decreaseSpeed() {
            //decrease speed by 25%
            if (vm.timelineSpeedMs > 0 && (vm.currentSpeedPercentage - 25) > 0) {
                vm.currentSpeedPercentage = vm.currentSpeedPercentage - 25;
                vm.timelineSpeedMs = parseInt(vm.baselineSpeed * (vm.currentSpeedPercentage / 100));
            }
        }

        function goForward(timeInMs) {
            vm.stop();
            //get current time and figure out next time based on playback speed
            var currTime = vm.timelineObj.getCustomTime(1);
            var newTime = new Date(new Date(currTime).setMilliseconds(currTime.getMilliseconds() + timeInMs));
            //set current time object back into timeline
            vm.timelineObj.setCustomTime(newTime, 1);
            vm.currentTime = vm.formatDateTimeEvents(newTime);

            vm.resetTimelineInterval();

        }

        function goBack(timeInMs) {
            vm.stop();
            //get current time and figure out next time based on playback speed
            var currTime = vm.timelineObj.getCustomTime(1);
            var newTime = new Date(new Date(currTime).setMilliseconds(currTime.getMilliseconds() - timeInMs));
            //set current time object back into timeline
            vm.timelineObj.setCustomTime(newTime, 1);
            vm.currentTime = vm.formatDateTimeEvents(newTime);

            vm.resetTimelineInterval();
        }

        function addData() {
            vm.fakeData = [];
            var set1 = [
                {
                    name: "Phase Status",
                    values: ["Off", "On", "Off", "Off", "Off", "On", "Off", "Off"]
                },
                {
                    name: "Phase Up Time",
                    values: [0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    name: "Phase Call",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Veh Detector",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Ped Status",
                    values: ["Off", "Off", "Off", "Off", "Off", "Off", "Off", "Off"]
                },
                {
                    name: "Ped Up Time",
                    values: [0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    name: "Overlap Detector",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Overlap Status",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Overlap Uptime",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Set Split",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Actual Split",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },

            ];
            var set2 = [
                {
                    name: "Phase Status",
                    values: ["Off", "Off", "Off", "On", "Off", "Off", "Off", "On"]
                },
                {
                    name: "Phase Up Time",
                    values: [0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    name: "Phase Call",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Veh Detector",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Ped Status",
                    values: ["Off", "Off", "Off", "Off", "Off", "Off", "Off", "Off"]
                },
                {
                    name: "Ped Up Time",
                    values: [0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    name: "Overlap Detector",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Overlap Status",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Overlap Uptime",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Set Split",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },
                {
                    name: "Actual Split",
                    values: ['-', '-', '-', '-', '-', '-', '-', '-']
                },

            ];
            vm.fakeData.push(set1);
            vm.fakeData.push(set2);
            vm.signalEventsWidget.statusData = set1;
        }


        //vm.signalEventsWidget.getEvents();

        //cleanup
        $scope.$on("$destroy", function () {
            vm.signalEventsWidget = null;
            if (vm.timelineObj) {
                vm.timelineObj.destroy();
                vm.timelineObj = undefined;
            }
            vm.stop();
            vm = null;
        });
    }
}());
