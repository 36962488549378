(function ()
{
    'use strict';

    angular
        .module('app.core',
            [
                'ngAnimate',
                'ngAria',
                'ngCookies',
                'ngResource',
                'ngSanitize',
                'ngMaterial',
                // 'ngSegment'
            ]);
})();