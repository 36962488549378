angular
.module("app.spm.core")
.factory('dashboardInsightsService', ['$rootScope', function ($rootScope) {

    var service = {
        model: {
            tabIndex: null,
            approachID: null,
            startTime: null,
            endTime: null,
            toDetectors: false
        },
        SaveState: function () {
            sessionStorage.dashboardInsightsService = angular.toJson(service.model);
        },
        RestoreState: function () {
            service.model = angular.fromJson(sessionStorage.dashboardInsightsService);
        }
    }

    $rootScope.$on("savestate", service.SaveState);
    $rootScope.$on("restorestate", service.RestoreState);

    return service;
}]);