import * as SIDEMENU from 'raw-loader!./insights-sidemenu.html'

(function () {
    'use strict';

    angular.module('app.core')
        .directive('insightsSidemenu', insightsSidemenuDirective);


    function insightsSidemenuDirective() {
        return {
            restrict: 'AE',
            scope: {
                param: '=',
            },
            template: SIDEMENU,
            controller: 'insightsRightMenuController as vm'
        }
    }
})();
