(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.user-settings')
        .controller('DashboardChartsSettingsController', DashboardChartsSettingsController);

    /** @ngInject */
    function DashboardChartsSettingsController($rootScope, $scope, $window, $state, $mdDialog, signalService, insightsService, userSettingsService, $mdToast, $interval) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        $scope.loading = true;
        vm.isExpanded = false;
        vm.getUserSettings = getUserSettings;
        vm.setUserSettings = setUserSettings;
        vm.setGlobalChartComplexity = setGlobalChartComplexity;
        vm.complexityOption = "Simple";
        vm.currentUserSettings = undefined;
        vm.initChart = initChart;

        function setGlobalChartComplexity(option) {
            vm.complexityOption = option;
        }

        vm.close = function close() {
            $mdDialog.cancel();
        };

        function initChart() {

            var canvas = document.getElementById("userSettingsChart");
            var canvas2 = document.getElementById("userSettingsChart2");
            var ctx = canvas.getContext('2d');
            var ctx2 = canvas2.getContext('2d');

            // Chart.defaults.global.defaultFontColor = 'black';
            // Chart.defaults.global.defaultFontSize = 12;

            var data = {
                labels: ["January", "February", "March"],
                datasets: [
                    {
                        data: [10, 30, 20],
                        type: 'line',
                        // Notice the borderColor 
                        strokeColor: 'red',
                        borderColor: 'red',
                        pointBackgroundColor: 'red',
                        fill: false,
                        pointRadius: 4,
                        pointHoverRadius: 4
                    }
                ]
            };
            var data2 = {
                labels: ["January", "February", "March"],
                datasets: [
                    {
                        data: [20, 15, 5],
                        type: 'line',
                        // Notice the borderColor 
                        strokeColor: 'blue',
                        borderColor: 'blue',
                        pointBackgroundColor: 'blue',
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    },
                    {
                        data: [15, 5, 25],
                        type: 'line',
                        // Notice the borderColor 
                        strokeColor: 'green',
                        borderColor: 'green',
                        pointBackgroundColor: 'green',
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    },
                    {
                        data: [10, 30, 20],
                        type: 'line',
                        // Notice the borderColor 
                        strokeColor: 'red',
                        borderColor: 'red',
                        pointBackgroundColor: 'red',
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    },
                    {
                        data: [5, 15, 10],
                        type: 'bar',
                        // Notice the borderColor 
                        strokeColor: 'yellow',
                        borderColor: 'yellow',
                        pointBackgroundColor: 'yellow',
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    }
                ]
            };
            var options = {
                layout: {
                    padding: 5
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: false,
                        ticks: { display: false }
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: false,
                        ticks: { display: false }
                    }]
                },
                responsive: true,
                title: {
                    display: false,
                    position: 'top'
                },
                tooltips: {
                    enabled: false,
                },
                legend: {
                    display: false,
                },
            };
            vm.chartsSettingsChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: options
            });
            vm.chartsSettingsChart2 = new Chart(ctx2, {
                type: 'line',
                data: data2,
                options: options
            });
            $scope.loading = false;

        }

        function getUserSettings() {
            userSettingsService.getUserSettings()
                .then(function (userSettings) {
                    console.log(userSettings);
                    vm.currentUserSettings = userSettings;
                    vm.complexityOption = (userSettings.chartSettings && userSettings.chartSettings.chartComplexity) ? userSettings.chartSettings.chartComplexity : 'Simple';
                    vm.initChart();
                })
        }
        vm.getUserSettings();


        function setUserSettings() {
            vm.currentUserSettings.chartSettings = {
                chartComplexity: vm.complexityOption
            }
            userSettingsService.setUserSettings(vm.currentUserSettings);
        }
    }

})();