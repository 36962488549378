﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("preemptionDetailsChartController", preemptionDetailsChartController);

    function preemptionDetailsChartController($state, $scope, $attrs, $rootScope, $element, environmentConfig, chartsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 3;
        vm.isDataAvailable = false;
        vm.chartServerError = false;
        vm.chartsService = chartsService;
        vm.metricTypeId = 4;

        vm.colors = {
            endCall: '#FF5468',
            delay: '#3182bd',
            timeToService: '#ff8c00',
            trackClear: '#4a1486',
            dwell: '#00b300',
            callMaxOut: '#525252',
            gateDown: '#33ff33'
        };

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input != "{}") {
                    var jsonData = JSON.stringify(input, null, 4);
                    vm.preemptWidget.processPreemptDetails(JSON.parse(jsonData, JSON.dateParser));
                    $scope.loading = false;
                }
            }
        }

        vm.cycleLookup = [];
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        vm.preemptWidget = {
            //logic for taking server events and manipulating into format the chart is expecting
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.preemptWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            addPlanLabels: function (primaryPlans, secondaryPlans, primaryLabel, secondaryLabel) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        var preemptions = "";
                        if (plan) {
                            preemptions = "Preemptions: " + plan.cycleCount.toLocaleString();;
                        }
                        plan.labels.push(planText, preemptions);
                        plansCopy[i] = plan;

                        // plan.labels.push(planText, primaryLabel + " : " + plan.totalVolume, secondaryLabel + " : " + secondaryPlans[i].totalVolume);
                        // getPlanAnnotations(primaryPlans);
                    }
                }
                return plansCopy;
            },
            processPreemptDetails: function (rawData) {
                var data = [];
                var chartData = [];
                if (!$scope.signal.description) {
                    $scope.signal.description = getSignalDescription($scope.signal.customID,$scope.signal.primaryName, $scope.signal.secondaryName);
                }
                var tts = {
                    key: "Time To Service",
                    label: "Time To Service",
                    data: [],
                    type: 'bar',
                    barThickness: 10,
                    yAxisID: '1',
                    backgroundColor: vm.colors.timeToService,
                    borderColor: vm.colors.timeToService
                }, dwl = {
                    key: "Dwell Time",
                    label: "Dwell Time",
                    data: [],
                    type: 'bar',
                    barThickness: 10,
                    yAxisID: '1',
                    backgroundColor: vm.colors.dwell,
                    borderColor: vm.colors.dwell
                }, entdly = {
                    key: "Entry Delay",
                    label: "Entry Delay",
                    data: [],
                    type: 'bar',
                    barThickness: 10,
                    yAxisID: '1',
                    backgroundColor: vm.colors.delay,
                    borderColor: vm.colors.delay
                },
                    trkClr = {
                        key: "Track Clear",
                        label: "Track Clear",
                        data: [],
                        type: 'bar',
                        barThickness: 10,
                        yAxisID: '1',
                        backgroundColor: vm.colors.trackClear,
                        borderColor: vm.colors.trackClear
                    }
                //inputOn = {
                //    key: "Input On",
                //    values: [],
                //    type: 'scatter',
                //    yAxis: 1,
                //    color: vm.colors.dwell
                //},
                //inputOff = {
                //    key: "Input Off",
                //    values: [],
                //    type: 'scatter',
                //    yAxis: 1,
                //    color: vm.colors.endCall
                //};

                if (rawData && rawData.preempts && rawData.preempts.length > 0 && rawData.preempts.filter(x => x.cycles.length > 0).length > 0) {
                    vm.isDataAvailable = true;

                    rawData.preempts.forEach(function (preempt) {
                        //don't show chart if no preemption cycles
                        if (!preempt.cycles || preempt.cycles.length <= 0) return;

                        var planList = preempt.plans;
                        var totalPreemptions = planList.reduce((a, b) => a + b.cycleCount, 0);
                        var preemptNum = preempt.preemptNumber;
                        if (!data[preemptNum]) {
                            data[preemptNum] = [];
                        }
                        var cycleCount = 0;
                        // var maxInputOff = 0;

                        preempt.cycles.forEach(function (cyc) {
                            var cycleId = preemptNum + "-" + cycleCount;
                            vm.cycleLookup[cycleId] = cyc;

                            var cycleStart = new Date(cyc.cycleStart);

                            //delay happens first, then time to server
                            //then trackclear then dwelltime. Must be added in this order
                            if (!data[preemptNum]["delay"]) {
                                data[preemptNum]["delay"] = angular.copy(entdly);
                            }
                            data[preemptNum]["delay"].data.push({
                                x: cycleStart,
                                y: cyc.delay.y,
                                cycleId: cycleId
                            });

                            if (!data[preemptNum]["timeToService"]) {
                                data[preemptNum]["timeToService"] = angular.copy(tts);
                            }
                            data[preemptNum]["timeToService"].data.push({
                                x: cycleStart,
                                y: cyc.timeToService.y,
                                cycleId: cycleId
                            });

                            if (!data[preemptNum]["timeToTrackClear"]) {
                                data[preemptNum]["timeToTrackClear"] = angular.copy(trkClr);
                            }
                            data[preemptNum]["timeToTrackClear"].data.push({
                                x: cycleStart,
                                y: cyc.trackClear.y,
                                cycleId: cycleId
                            });

                            if (!data[preemptNum]["dwellTime"]) {
                                data[preemptNum]["dwellTime"] = angular.copy(dwl);
                            }
                            data[preemptNum]["dwellTime"].data.push({
                                x: cycleStart,
                                y: cyc.dwellTime.y,
                                cycleId: cycleId
                            });

                            // if (!data[preemptNum]["inputOn"]) {
                            //     data[preemptNum]["inputOn"] = angular.copy(inputOn);
                            // }
                            // for(let k = 0; k < cyc.inputOn.length; k++){
                            //     data[preemptNum]["inputOn"].values.push({
                            //         x: new Date(cyc.inputOn[k].x),
                            //         y: 1,
                            //         cycleId: cycleId,
                            //         shape: 'circle'
                            //     });
                            // }
                            // if (!data[preemptNum]["inputOff"]) {
                            //     data[preemptNum]["inputOff"] = angular.copy(inputOff);
                            // }
                            // for(let k = 0; k < cyc.inputOff.length; k++){
                            //     if (cyc.inputOff[k].y > maxInputOff)
                            //         maxInputOff = cyc.inputOff[k].y;
                            //     data[preemptNum]["inputOff"].values.push({
                            //         x: new Date(cyc.inputOff[k].x),
                            //         y: cyc.inputOff[k].y,
                            //         cycleId: cycleId,
                            //         shape: 'circle'
                            //     });
                            // }


                            cycleCount++;
                        });

                        var groupArr = [];
                        var options = searchBarService.getSearchOptions();

                        var from = new Date(options.timeOptions.currentFilter.startDateAndTime);
                        var until = new Date(options.timeOptions.currentFilter.endDateAndTime);

                        for (var pGroup in data[preemptNum]) {
                            var dataset = data[preemptNum][pGroup];

                            //if any of the data sets has all 0 y axis then don't add
                            if (dataset.data.every(x => x.y === 0)) continue;

                            dataset.data.unshift({
                                x: from,
                                y: 0
                            });
                            dataset.data.push({
                                x: until,
                                y: 0
                            });

                            groupArr.push(dataset);
                        }

                        chartData.push({
                            title: "Preemption Details for " + $scope.signal.description,
                            subLine1: "Preempt Number: " + preemptNum + " / Total Preemptions: " + totalPreemptions,
                            setup: clonedChartSetup,
                            plans: vm.preemptWidget.addPlanLabels(planList),
                            dataset: groupArr,
                            plansHidden: false,
                            planColor: "#000",
                            api: {},
                            onDestroy: function () {
                                this.api = {};
                            },
                            onApiInit: function (apiObj) {
                                this.api = apiObj;
                                this.render(apiObj);
                            },
                            render: function (apiObj) {
                                apiObj.render(undefined, this.dataset, this.chartOptions);
                                vm.chart =
                                    vm.chartRendering = false;
                                this.isRendered = true;
                            },
                            hidePlanLabels: function (e, chart){ 
                                chart.plansHidden = !chart.plansHidden;
                                chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                                Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                                this.plans.visible = false;
                                if (chart.plansHidden) {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels2 = plan.labels;
                                        plan.labels = [];
                                    });
                                    chart.planColor = "#fff";
                                } else {
                                    chart.plans.forEach(function (plan) {
                                        plan.labels = plan.labels2;
                                    });
                                    chart.planColor = "#000";
                                }
                                chart.api.update();
                            },
                            isRendered: false,
                            chartOptions: {
                                hidePlans: false,
                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                        type: 'time',
                                        id: 'x-axis-0',
                                        // time: {
                                        //     unit: 'hour'
                                        // },
                                        gridLines: {
                                            display: false
                                        },
                                        ticks: {
                                            autoSkip: true,
                                            autoSkipPadding: 50,
                                            minRotation: 0,
                                            maxRotation: 0,
                                        }
                                    },
                                    ],
                                    yAxes: [{
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'Time (seconds)',
                                            fontFamily: 'Roboto',
                                            fontSize: 14,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                        // ticks: {
                                        //     callback: function (d) {
                                        //         if (d == 0) return null;
                                        //         if (d % 1 != 0) return null;
                                        //         else if (d > vm.maxPhase) return null;
                                        //         else return d3.format('.0f')(d);
                                        //     },
                                        //     max: vm.maxPhase + 1
                                        // },
                                        id: '1',
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        // stacked: true,
                                        // type: 'linear',
                                        position: 'left',

                                    }
                                    ]
                                },
                                legend: {
                                    display: true,
                                    align: 'end',
                                },
                                hover: {
                                    mode: 'nearest'
                                },
                                tooltips: {
                                    enabled: false,
                                    intersect: false,
                                    mode: 'point',
                                    // custom: function (tooltip) {
                                    callbacks: {
                                        afterBody: function (t, d) {
                                            // if (d.point) {
                                            //     planNumber = d.point.planNumber;
                                            //     cycleId = d.datasets[0].data[1].cycleId
                                            // }
                                            // else if (d.data) {
                                            // var planNumber = d.data.planNumber;
                                            var cycleId = d.datasets[0].data[t[0].index].cycleId
                                            // }
                                            // else {
                                            //     return cycleId;
                                            // }
                                            return cycleId
                                        }
                                    },
                                    custom: function (tooltipModel, dtes) {
                                        var tooltipEl = document.getElementById('chartjs-tooltip');
                                        var planNumber = null;
                                        // Create element on first render
                                        if (!tooltipEl) {
                                            tooltipEl = document.createElement('div');
                                            tooltipEl.id = 'chartjs-tooltip';
                                            tooltipEl.innerHTML = '<table></table>';
                                            document.body.appendChild(tooltipEl);
                                        }
                                        // Hide if no tooltip
                                        if (tooltipModel.opacity === 0) {
                                            tooltipEl.style.opacity = 0;
                                            return;
                                        }
                                        // Set caret Position
                                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                                        if (tooltipModel.yAlign) {
                                            tooltipEl.classList.add(tooltipModel.yAlign);
                                        } else {
                                            tooltipEl.classList.add('no-transform');
                                        }

                                        function getBody(bodyItem) {
                                            return bodyItem.lines;
                                        }
                                        var bodyLines = tooltipModel.body.map(getBody);
                                        if (tooltipModel.body) {
                                            var cycleId = tooltipModel.afterBody[0];
                                            var cycleItem = vm.cycleLookup[cycleId];
                                            if (!cycleItem) return;
                                            var rows = "";
                                            var durationRows = "";
                                            if (planNumber && showPlan) {
                                                rows += "<td class='key'>" + 'Plan: ' + "</td>" +
                                                    "<td class='x-value'><strong>" + planNumber + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            if (cycleItem.delay && cycleItem.delay.y > 0) {
                                                rows +=
                                                    "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.delay + ";'></div></td>" +
                                                    "<td class='key'>" + 'Entry Delay Start' + "</td>" +
                                                    "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.delay.x) + "</strong></td>" +
                                                    "</tr>";
                                                durationRows += "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.delay + ";'></div></td>" +
                                                    "<td class='key'>" + 'Entry Delay Duration (sec)' + "</td>" +
                                                    "<td class='x-value'><strong>" + cycleItem.delay.y + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            if (cycleItem.timeToService && cycleItem.timeToService.y > 0) {
                                                rows +=
                                                    "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.timeToService + ";'></div></td>" +
                                                    "<td class='key'>" + 'Time To Service Start' + "</td>" +
                                                    "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.timeToService.x) + "</strong></td>" +
                                                    "</tr>";
                                                durationRows += "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.timeToService + ";'></div></td>" +
                                                    "<td class='key'>" + 'Time To Service Duration (sec)' + "</td>" +
                                                    "<td class='x-value'><strong>" + cycleItem.timeToService.y + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            if (cycleItem.trackClear && cycleItem.trackClear.y > 0) {
                                                rows +=
                                                    "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.trackClear + ";'></div></td>" +
                                                    "<td class='key'>" + 'Track Clear Start' + "</td>" +
                                                    "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.trackClear.x) + "</strong></td>" +
                                                    "</tr>";
                                                durationRows += "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.trackClear + ";'></div></td>" +
                                                    "<td class='key'>" + 'Track Clear Duration (sec)' + "</td>" +
                                                    "<td class='x-value'><strong>" + cycleItem.trackClear.y + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            if (cycleItem.dwellTime && cycleItem.dwellTime.y > 0) {
                                                rows +=
                                                    "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.dwell + ";'></div></td>" +
                                                    "<td class='key'>" + 'Dwell Start' + "</td>" +
                                                    "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.dwellTime.x) + "</strong></td>" +
                                                    "</tr>";
                                                durationRows += "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.dwell + ";'></div></td>" +
                                                    "<td class='key'>" + 'Dwell Duration (sec)' + "</td>" +
                                                    "<td class='x-value'><strong>" + cycleItem.dwellTime.y + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            //if (cycleItem.inputOn && cycleItem.inputOn[0]) {
                                            //    rows +=
                                            //        "<tr>" +
                                            //        "<td class='legend-color-guide'><div style='background-color: " + vm.colors.dwell + ";'></div></td>" +
                                            //        "<td class='key'>" + 'Start Call' + "</td>" +
                                            //        "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.inputOn[0].x) + "</strong></td>" +
                                            //        "</tr>";
                                            //}
                                            if (cycleItem.inputOff && cycleItem.inputOff[0] && cycleItem.inputOff[0].y > 0) {
                                                rows +=
                                                    "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.endCall + ";'></div></td>" +
                                                    "<td class='key'>" + 'End Call' + "</td>" +
                                                    "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.inputOff[0].x) + "</strong></td>" +
                                                    "</tr>";
                                                durationRows += "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.endCall + ";'></div></td>" +
                                                    "<td class='key'>" + 'Call Duration (sec)' + "</td>" +
                                                    "<td class='x-value'><strong>" + cycleItem.inputOff[0].y + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            if (cycleItem.timeToCallMaxOut && cycleItem.timeToCallMaxOut.y > 0) {
                                                rows +=
                                                    "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.callMaxOut + ";'></div></td>" +
                                                    "<td class='key'>" + 'Call Max Out Time' + "</td>" +
                                                    "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.timeToCallMaxOut.x) + "</strong></td>" +
                                                    "</tr>";
                                                durationRows += "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.callMaxOut + ";'></div></td>" +
                                                    "<td class='key'>" + 'Call Max Out Duration (sec)' + "</td>" +
                                                    "<td class='x-value'><strong>" + cycleItem.timeToCallMaxOut.y + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            if (cycleItem.timeToGateDown && cycleItem.timeToGateDown.y > 0) {
                                                rows +=
                                                    "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.gateDown + ";'></div></td>" +
                                                    "<td class='key'>" + 'Gate Down Time' + "</td>" +
                                                    "<td class='x-value'><strong>" + vm.chartsService.formatTooltipDate(cycleItem.timeToGateDown.x) + "</strong></td>" +
                                                    "</tr>";
                                                durationRows += "<tr>" +
                                                    "<td class='legend-color-guide'><div style='width: 10px; height: 10px; background-color: " + vm.colors.gateDown + ";'></div></td>" +
                                                    "<td class='key'>" + 'Gate Down Duration (sec)' + "</td>" +
                                                    "<td class='x-value'><strong>" + cycleItem.timeToGateDown.y + "</strong></td>" +
                                                    "</tr>";
                                            }
                                            // var rows = "<tr>" +
                                            //     "<td class='key'>" + 'Count: ' + "</td>" +
                                            //     "<td class='x-value'><strong>" + tooltipModel.dataPoints[0].yLabel + "</strong></td>" +
                                            //     "</tr>";
                                            // rows += "<tr>" +
                                            //     "<td class='key'>" + 'Time: ' + "</td>" +
                                            //     "<td class='x-value'><strong>" + moment(tooltipModel.dataPoints[0].xLabel).format('MMM DD, YYYY, hh:mm A') + "</strong></td>" +
                                            //     "</tr>";
                                            // tooltipModel.afterBody
                                            //     .forEach(function (toolTipData) {
                                            //         rows += "<tr>" +
                                            //             "<td class='key'>" + toolTipData.title + ":</td>" +
                                            //             "<td class='x-value'><strong>" + toolTipData.value + "</strong></td>" +
                                            //             "</tr>";
                                            //     });
                                            var header =
                                                "<thead>" +
                                                "<tr>" +
                                                "<td class='key'><strong>" + tooltipModel.body[0].lines[0].split(':')[0] + "</strong></td>" +
                                                "</tr>" +
                                                "</thead>";

                                            var innerHTML = "<table>" +
                                                // header +
                                                "<tbody>" +
                                                rows + durationRows +
                                                "</tbody>" +
                                                "</table>";

                                            var tableRoot = tooltipEl.querySelector('table');
                                            var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                            tableStyle += 'color: rgba(255,255,255, 1);';
                                            tableStyle += 'padding: 5px 10px;';

                                            tableRoot.style = tableStyle;
                                            tableRoot.innerHTML = innerHTML;
                                            vm.ttRows = "";
                                        }

                                        // `this` will be the overall tooltip
                                        var position = this._chart.canvas.getBoundingClientRect();
                                        // Display, position, and set styles for font
                                        tooltipEl.style.opacity = 1;
                                        tooltipEl.style.position = 'absolute';
                                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                        if (position.left + window.pageXOffset + tooltipModel.caretX + 255 > window.innerWidth) {
                                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth - 100 + 'px';
                                        }
                                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                        tooltipEl.style.pointerEvents = 'none';
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    zoom: {
                                        pan: {
                                            enabled: false,
                                            mode: 'x',

                                            rangeMin: {
                                                x: options.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: options.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        },
                                        zoom: {
                                            enabled: true,
                                            drag: true,
                                            speed: 0.1,
                                            mode: 'x',

                                            rangeMin: {
                                                x: options.timeOptions.currentFilter.startDateAndTime.getTime()
                                            },
                                            rangeMax: {
                                                x: options.timeOptions.currentFilter.endDateAndTime.getTime()
                                            }
                                        }
                                    }
                                },
                            },
                            flex: 100,
                            isDataAvailable: preempt.cycles.length > 0
                        });
                    });
                }
                else {
                    vm.isDataAvailable = false;
                    var clonedChartSetup = angular.copy(vm.preemptWidget.chart);
                    chartData.push({
                        title: "Preemption Details for " + $scope.signal.description,
                        setup: clonedChartSetup,
                        dataset: [],
                        api: {},
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        flex: 100,
                        isDataAvailable: vm.isDataAvailable
                    });
                }

                vm.chartArray = chartData;
            },

        }
        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            vm.chartServerError = $rootScope.serverChartError;

            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    // var rawData = JSON.stringify(data, null, 4);
                    vm.preemptWidget.processPreemptDetails(data);
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
}());
