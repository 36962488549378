(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.user-settings')
        .controller('DashboardInsightSettingsController', DashboardInsightSettingsController);

    /** @ngInject */
    function DashboardInsightSettingsController($rootScope, $scope, $window, $state, $mdDialog, signalService, msNavigationService, insightsService, userSettingsService, $mdToast, $interval, close) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        $scope.loading = true;
        vm.showInsights = false;
        vm.showDashboardInsights = false;
        vm.maintenanceInsights = false;
        vm.performanceInsights = false
        vm.otherInsights = false;
        vm.changed = changed;
        vm.checkboxChanged = checkboxChanged;
        vm.isExpanded = false;
        vm.saveUserSettings = saveUserSettings;
        vm.getDefinitions = getDefinitions;
        vm.getUserSettings = getUserSettings;
        vm.setUserSettings = setUserSettings;
        vm.insightsCategories = [];
        vm.insightsDashboardCategories = [];
        vm.changedCategories = [];
        vm.close = close;
        vm.clearChangedCategories = clearChangedCategories;

        vm.disabled = true;
        vm.userSettingsViewModel = {
            userID: sessionStorage.getItem('userID'),
            userEmail: sessionStorage.getItem('userEmail'),
            insightSettings: {
                emailsEnabled: false,
                enabledInsightCategories: []
            }
        };


        vm.enabledInsightCategory = {
            insightCategoryID: null,
            name: null,
            insightGroups: []
        }

        vm.insightType = {
            insightTypeID: null,
            name: null
        }

        vm.getDefinitions();

        function changed(category) {
            vm.isExpanded = true;
            vm.disabled = false;
            vm.changedCategories.push(category);
            category.insightGroups.forEach(function (group) {
                group.insightTypes.forEach(function (insType) {
                    if (category.isSelected) {
                        insType.isSelected = true;
                    } else {
                        insType.isSelected = false;
                    }
                })
            })

            if (!vm.showInsights && !vm.showDashboardInsights) vm.isExpanded = false;
        }

        function checkboxChanged(category) {
            vm.isExpanded = true;
            vm.disabled = false;
            var numOfSelectedInsights = 0;
            category.insightGroups.forEach(function (group) {
                group.insightTypes.forEach(function (insType) {
                    if (insType.isSelected && insType.display) numOfSelectedInsights++;
                })
            });
            if (numOfSelectedInsights == 0) {
                vm.changedCategories.push(category);
                category.isSelected = false
            };
            if (!vm.showInsights && !vm.showDashboardInsights) vm.isExpanded = false;
        }

        function clearChangedCategories() {
            vm.changedCategories = [];
        }

        function getUserSettings() {
            userSettingsService.getUserSettings()
                .then(function (userSettings) {
                    if (vm && userSettings.insightSettings && userSettings.insightSettings.emailsEnabled) {
                        userSettings.insightSettings.enabledInsightCategories.forEach(function (category) {
                            vm.showInsights = true;
                            var c = vm.insightsCategories.find(x => x.insightCategoryID === category.insightCategoryID);
                            if (c) c.isSelected = true;
                            vm.insightsCategories = vm.insightsCategories.filter(x => x.display);
                            vm.insightsCategories.forEach(function (insightCategory) {
                                category.insightTypes && category.insightTypes.forEach(function (type) {
                                    insightCategory.insightGroups.forEach(function (insightGroup) {
                                        insightGroup.numOfSelectedInsightTypes = insightGroup.insightTypes.filter(x => x.display).length;
                                        insightGroup.insightTypes.forEach(function (insType) {
                                            if (insType.name == type.name) {
                                                insType.isSelected = true;
                                                vm.isExpanded = true;
                                            }
                                        });
                                    });
                                });
                            });
                        });
                    }
                    else vm.isExpanded = false;

                    userSettings.insightSettings.enabledDashboardInsightCategories.forEach(function (category) {
                        vm.showDashboardInsights = true;
                        var c = vm.insightsDashboardCategories.find(x => x.insightCategoryID === category.insightCategoryID);
                        if (c) c.isSelected = true;
                        vm.insightsDashboardCategories = vm.insightsDashboardCategories.filter(x => x.display);
                        vm.insightsDashboardCategories.forEach(function (insightCategory, j) {
                            category.insightTypes && category.insightTypes.forEach(function (type) {
                                insightCategory.insightGroups.forEach(function (insightGroup) {
                                    insightGroup.numOfSelectedInsightTypes = insightGroup.insightTypes.filter(x => x.display).length;
                                    insightGroup.insightTypes.forEach(function (insType) {
                                        if (insType.insightTypeID == type.insightTypeID) {
                                            insType.isSelected = true;
                                            vm.isExpanded = true;
                                        }
                                    });
                                });
                            });
                        });
                    });
                    vm.insightsDashboardCategories = vm.insightsDashboardCategories.filter(x => x.display);
                    // vm.availableInsightsCategories = vm.insightsDashboardCategories;
                    // vm.availableInsights = vm.insightsCategories.filter(x => x.display);
                    $scope.loading = false;
                })
        }


        function setUserSettings(userSettings) {
            userSettingsService.setUserSettings(userSettings);
            // .then(function (user) {

            // });
        }

        function saveUserSettings() {
            vm.userSettingsViewModel.insightSettings.enabledInsightCategories = [];
            vm.userSettingsViewModel.insightSettings.enabledDashboardInsightCategories = [];
            vm.insightsCategories.forEach(function (category, i) {

                var enabledInsightCategory = {
                    insightCategoryID: null,
                    name: null,
                    insightTypes: []
                }

                if (category.isSelected) {
                    enabledInsightCategory.insightCategoryID = category.insightCategoryID;
                    enabledInsightCategory.name = category.name;

                    category.insightGroups.forEach(function (group, j) {
                        group.insightTypes.forEach(function (type, k) {
                            if (type.isSelected) {
                                vm.insightType.insightTypeID = type.insightTypeID;
                                vm.insightType.name = type.name;
                                enabledInsightCategory.insightTypes.push(vm.insightType);
                                vm.insightType = {};
                            }
                        });
                    })
                    vm.userSettingsViewModel.insightSettings.enabledInsightCategories.push(enabledInsightCategory);
                }
            })

            userSettingsService.categories.clear();
            vm.insightsDashboardCategories.forEach(function (category, i) {

                var enabledDashboardInsightCategory = {
                    insightCategoryID: null,
                    name: null,
                    insightTypes: []
                }

                if (category.isSelected) {
                    var keyString = category.insightCategoryID.toString();
                    userSettingsService.categories.set(keyString, { display: category.isSelected });
                    enabledDashboardInsightCategory.insightCategoryID = category.insightCategoryID;
                    enabledDashboardInsightCategory.name = category.name;
                    enabledDashboardInsightCategory.insightGroups = category.insightGroups;

                    category.insightGroups.forEach(function (group, j) {
                        group.insightTypes.forEach(function (type, k) {
                            if (type.isSelected) {
                                vm.insightType.insightTypeID = type.insightTypeID;
                                vm.insightType.name = type.name;
                                enabledDashboardInsightCategory.insightTypes.push(vm.insightType);
                                vm.insightType = {};
                            }
                        });
                    })
                    vm.userSettingsViewModel.insightSettings.enabledDashboardInsightCategories.push(enabledDashboardInsightCategory);
                }
            })
            vm.changedCategories.forEach(function (category) {

                var categoryName = category.name.replace(/\s+/g, '-').toLowerCase();
                if (category.isSelected) {
                    msNavigationService.saveItem('spm.insights.' + categoryName, {
                        title: category.name,
                        state: 'app.spm.dashboards.insights.' + categoryName,
                        mdIcon: 'mdi-' + category.iconName,
                        weight: category.displayOrder,
                    })
                } else {
                    msNavigationService.deleteItem('spm.insights.' + categoryName);
                }
            });
            msNavigationService.sort();
            vm.changedCategories = [];

            if (vm.userSettingsViewModel.insightSettings.enabledInsightCategories.length > 0) {
                vm.userSettingsViewModel.insightSettings.emailsEnabled = true
            } else {
                vm.userSettingsViewModel.insightSettings.emailsEnabled = false;
            }
            vm.setUserSettings(vm.userSettingsViewModel);
        }

        function getDefinitions() {
            insightsService.getInsightsDefintions()
                .then(function (definitions) {
                    definitions.insightCategories.forEach(function (def, i) {
                        vm.insightsCategories[i] = def;
                        vm.insightsCategories[i].isSelected = false;
                        def.insightGroups.forEach(function (group, j) {
                            group.insightTypes.forEach(function (type, j) {
                                type.isSelected = false;
                            })
                        });
                    })
                })
            insightsService.getInsightsDefintions()
                .then(function (definitions) {
                    definitions.insightCategories.forEach(function (def, i) {
                        vm.insightsDashboardCategories[i] = def;
                        vm.insightsDashboardCategories[i].isSelected = false;
                        def.insightGroups.forEach(function (group, j) {
                            group.insightTypes.forEach(function (type, j) {
                                type.isSelected = false;
                            })
                        });
                    })
                    vm.getUserSettings();
                })
        }
    }

})();