﻿import * as EVENTPLAYERWIDGET from 'raw-loader!./event-player-widget.html'

(function () {
    'use strict';

    angular.module('app.spm.event-player')
        .directive('eventPlayerWidget', eventPlayerWidgetDirective);

    /** @ngInject */
    function eventPlayerWidgetDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'eventPlayerWidgetController as vm',
            template: function (elem, attrs) {
                return EVENTPLAYERWIDGET;
            },
            scope: {
                loading: '=',
                onApiInit:'&',
                api: '='
            },
            link: function (scope, element, attrs) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        update: function (signal, timeOptions) {
                            return scope.update(signal, timeOptions);
                        },

                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();