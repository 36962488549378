(function () {
    "use strict";

    var app = angular
        .module("app.spm.dashboards.signal-trends")
        .service("signalTrendsService", signalTrendsService);

  
        app.constant('TrendsConstants', {
            Filters: {
                baselineAverage: 1,
                baselineDate:2,
                counts: 3,
                dailyChange: 4,
                dowChange: 5,
            },
            ChartTypes:{
                count: 1,
                changePercentage: 2
            }
        }); 

    function signalTrendsService($rootScope, TrendsConstants, signalTrendsResource) {
        var vm = this;
        vm.options = {
            filterState: TrendsConstants.Filters.counts,
            baselineDate: undefined
        };
        vm.selectedIdsApproaches = [];

        var legendTypes = Object.freeze({
            scaleSequential : 1,
            scaleSequentialSqrt : 2,
            scaleDiverging : 3,
            scaleDivergingSqrt : 4,
            scaleSequentialLog : 5,
            scaleSequentialQuantile : 6,
            scaleSqrt : 7,
            scaleQuantize : 8, 
            scaleQuantile : 9, 
            scaleThreshold : 10,
            scaleOrdinal: 11
        });
        

        function notifyChange(optionName, optionValue) {
            $rootScope.$emit('signal-trends-options-change-event', {name: optionName, value: optionValue});
        }

        function setDowPercentage(showDowPercentage){
            return vm.showDowPercentage = showDowPercentage
        }
        function getDowPercentage(){
            return vm.showDowPercentage
        }   

        function getOptions(){
            return vm.options;
        }
        function setOptions(options){
            return vm.options = options;
        }

        function createOptionsObject(signalId, aggType, startDate, endDate, startHour, startMinute, endHour, endMinute,
            daysOfWeekArray, timeOption, binSize,  baselineType, baselineStartDate, baselineEndDate, includeHourly) {
            var opts = {
                signalId: signalId,
                aggregationTypes: aggType,
                startDateTime: startDate,
                endDateTime: endDate,
                timeOfDayStartHour: startHour,
                timeOfDayStartMinute: startMinute,
                timeOfDayEndHour: endHour,
                timeOfDayEndMinute: endMinute,
                daysOfWeek: daysOfWeekArray,
                timeOption: timeOption,
                selectedBinSize: binSize,
                baselineType: baselineType,
                baselineStartDate: baselineStartDate,
                baselineEndDate: baselineEndDate,
                includeHourlyData: includeHourly
            }
            return opts;
        }

        function getMetricForSignal(opts) {
            return signalTrendsResource.getForSignal(opts,
                function (data) {
                    return data;
                }).$promise;
        }

        function getMetricForApproach(options) {
            return signalPerformanceResource.getForSignal({ options: options },
                function (data) {
                    return data;
                }).$promise;
        }

        function getMetricForPhase(options) {
            return signalPerformanceResource.getForSignal({ options: options },
                function (data) {
                    return data;
                }).$promise;
        }

        function getExecutiveInformation(options) {
            return signalPerformanceResource.getExecutiveInformation(options,
                function (data) {
                    return data;
                }).$promise;
        }

        return {
            notifyChange: notifyChange,
            getDowPercentage: getDowPercentage,
            setDowPercentage: setDowPercentage,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('signal-trends-options-change-event', callback);
                scope.$on('$destroy', handler);
            },
            getOptions: getOptions,
            setOptions: setOptions,
            getMetricForSignal: getMetricForSignal,
            getMetricForApproach: getMetricForApproach,
            getMetricForPhase: getMetricForPhase,
            createOptionsObject: createOptionsObject,
            legendTypes : legendTypes
        }
    }
})();
