(function () {
    angular
        .module('app.searchbar')
        .controller('signalSearchController', signalSearchController);

    function signalSearchController($rootScope, $scope, searchBarService, signalService, signal_list, on_signal_set, on_close) {
        var vm = this;
        vm.options = {};
        vm.configuration = {};
        vm.signalService = signalService;
        vm.searchBarService = searchBarService;
        vm.init = init;
        vm.apply = apply;
        vm.close= close;
        vm.signalsList = signal_list;
        vm.filteredSignalList = [];
        vm.selectedSignal = selectedSignal;
        vm.pageData = {
            pageIndex: 1,
            size: 5,
        };
        vm.pageDataAppr = {
            pageIndex: 1,
            size: 5
        };
        vm.selectedApproaches = [];

        vm.signals = {
            selected: [],
            searchText: "",
            search: function () {
                vm.pageData.pageIndex = 1;
                vm.signals.selected = [];
                if (!vm.signals.searchText) {
                    vm.filteredSignalList = vm.signalsList;
                }
                else {
                    let currArray = vm.signalsList;
                    let foundArray = [];
                    let searchParts = vm.signals.searchText.replace(/[-&,]/g, '').split(" ");
                    for (let j = 0; j < searchParts.length; j++) {
                        let searchPart = searchParts[j].toLowerCase();
                        for (let i = 0; i < currArray.length; i++) {
                            if (currArray[i].customID.toLowerCase().includes(searchPart) ||
                                currArray[i].primaryName.toLowerCase().includes(searchPart) ||
                                currArray[i].secondaryName.toLowerCase().includes(searchPart)) {
                                    foundArray.push(currArray[i]);
                            }
                        }
                        currArray = foundArray;
                        foundArray = [];
                    }
                    vm.filteredSignalList = currArray;

                    if (vm.filteredSignalList.length === 1){
                        vm.signals.selected[0] = vm.filteredSignalList[0];
                        selectedApproaches(vm.signals.selected[0].approaches);
                    }
                }

            },
        };


        function selectedSignal(signal){
            selectedApproaches(signal.approaches);
        }

        function init() {
            vm.options = vm.searchBarService.getSearchOptions();
            vm.configuration = vm.searchBarService.getSearchBarConfig();
            vm.filteredSignalList = vm.signalsList;
            //set selected signal
            if (vm.options.signal && vm.options.signal.signalID) {
                if (!vm.signals.selected[0] || vm.signals.selected[0].signalID != vm.options.signal.signalID) {
                    var index = vm.signalsList.map(function (e) { return e.signalID; }).indexOf(vm.options.signal.signalID);
                    vm.signals.selected[0] = vm.signalsList[index];
                    selectedApproaches(vm.signals.selected[0].approaches);
                    vm.pageData.pageIndex = Math.floor((index / vm.pageData.size)+1);
                }
            }

        };

        function selectedApproaches(approaches){
            vm.selectedApproaches = [];
            approaches.forEach(appr => {
                vm.selectedApproaches.push(appr);
            });
        }

        function close(){
            if (on_close)
                on_close()
        }

        function apply() {
            if (vm.signals.selected[0]){
                vm.signals.selected[0].selectedApproaches = vm.selectedApproaches;
                vm.searchBarService.setSignal(vm.signals.selected[0]);
                vm.selectedApproaches=[];
            }
            else{
                vm.close();
                return;
            }
            if (on_signal_set)
                on_signal_set();
        }

        vm.init();
    }
}());
