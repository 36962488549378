﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("splitMonitorChartsController", splitMonitorChartsController);

    function splitMonitorChartsController($state, $scope, searchBarService) {
        var vm = this;
        vm.barChartApi = undefined;
        vm.phaseChartApi = undefined;
        vm.signal = $scope.signal;
        vm.data = undefined;
        vm.lineDataUpdatePending = false;
        vm.countDataUpdatePending = false;
        vm.searchBarService = searchBarService;

        $scope.updateData = function(data){
            vm.data = data;
            if (vm.barChart && vm.barChart.updateData){
                vm.barChart.updateData();
            }
            
            if (vm.phaseChart && vm.phaseChart.updateData){
                vm.phaseChart.updateData();
            }
        }

        vm.barChart = {
            api: {},
            updatePending: false,
            onApiInit: function(apiObj){
                vm.barChart.api = apiObj;
                if (vm.barChart.updatePending){
                    vm.barChart.updateData();
                    vm.barChart.updatePending = false;
                }
            },
            onApiDestroy: function (){
                vm.barChart.api = {};
            },
            updateData: function(){
                if (vm.barChart.api && vm.barChart.api.updateData){
                    var options = searchBarService.getSearchOptions();
                    vm.barChart.api.updateData(vm.data, options.signal);
                }
                else
                    vm.barChart.updatePending = true;
            },
            updateFetchData: function(){
                if (vm.barChart.api && vm.barChart.api.updateFetchData){
                    var options = searchBarService.getSearchOptions();
                    vm.barChart.api.updateFetchData(options.signal, options.timeOptions.currentFilter);
                }
                else
                    vm.barChart.updatePending = true;
            }
        }
        vm.phaseChart = {
            api: {},
            updatePending: false,
            onApiInit: function(apiObj){
                vm.phaseChart.api = apiObj;
                if (vm.phaseChart.updatePending){
                    vm.phaseChart.updateData();
                    vm.phaseChart.updatePending = false;
                }
            },
            onApiDestroy: function (){
                vm.phaseChart.api = {};
            },
            updateData: function(){
                if (vm.phaseChart.api && vm.phaseChart.api.updateData){
                    vm.phaseChart.api.updateData(vm.data);
                    vm.phaseChart.updatePending = false;
                }
                else
                    vm.phaseChart.updatePending = true;
            }
        }


        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;

            if ($scope.onApiDestroy)
                $scope.onApiDestroy();

            // if (cleanup) {
            //     cleanup();
            // }
            // if (cleanupServerError) {
            //     cleanupServerError();
            // }
        });
    }
}());
