﻿import * as d3v5 from '../../../../../custom-plugins/d3v5';
import 'd3';

(function () {
    "use strict";

    angular
        .module("app.spm.charts")
        .controller("hourlyHeatmapController", hourlyHeatmapController);

    function hourlyHeatmapController($state, $interval, $attrs, $scope, $rootScope, $element, signalTrendsService) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        });
        vm.signalTrendsService = signalTrendsService;
        vm.rectHeight = 22;
        vm.width = 950;
        vm.widthMonth = 50;
        vm.cardWidth = 726;
        vm.formatDay = formatDay;
        vm.formatDate = formatDate;
        vm.formatValue = formatValue;
        vm.formatMonth = formatMonth;
        vm.color = color;
        vm.height = height;
        vm.countDay = countDay;
        vm.timeOfWeek = d3v5.utcMonday;
        vm.setTimeOfWeek = setTimeOfWeek;
        vm.getTooltipText = getTooltipText;
        vm.getLegendTooltip = getLegendTooltip;
        vm.getInfoLegend = getInfoLegend;
        vm.data = [];
        vm.getLegend = getLegend;
        vm.tConvert = tConvert;
        vm.daysOfWeek = [];
        //color scheme from good to bad, green to red
        vm.colorScheme = ['#66BB6A', '#9CCC65', '#D4E157', '#FFCA28', '#FFA726', '#FF7043'];
        vm.reversedScheme = vm.colorScheme.slice().reverse()
        vm.transformPosition = transformPosition;
        vm.unselectedDays = [];
        vm.findUnselectedDays = findUnselectedDays;
        vm.getHoursFromData = getHoursFromData;
        vm.removeUnselectedDays = removeUnselectedDays;
        vm.displayOnce = displayOnce;
        vm.formatHoursInYAxis = formatHoursInYAxis;
        vm.formatYAxisHour = formatYAxisHour;
        vm.formatHoursInYAxisText = formatHoursInYAxisText;
        vm.xPositionOfRect = xPositionOfRect;
        vm.yPositionOfRect = yPositionOfRect;
        vm.alignDay = alignDay;
        vm.prefixToDate = prefixToDate;
        vm.formatDateOneHour = formatDateOneHour;
        vm.minutesLeadingZeros = minutesLeadingZeros;
        $scope.update = update;
        vm.isSquareRoot = false;
        vm.reverseColorScale = false;
        vm.getPatternLegend = getPatternLegend;
        vm.baselineOptions = {};

        function update(data, daysOfWeek, chartType, chartText, legendTitle, roundNumbers, widthOfCard, chartName, isSquareRoot, reverseColorScale, chartSubheader, baselineOptions, selectedLegend) {
            if (!vm)
                return;

            vm.selectedLegend = selectedLegend;
            vm.isSquareRoot = isSquareRoot;
            vm.chartSubheader = chartSubheader;
            vm.baselineOptions = baselineOptions;
            vm.chartName = chartName;
            vm.daysOfWeek = daysOfWeek;
            vm.reverseColorScale = reverseColorScale;
            vm.chartType = chartType;
            vm.chartText = chartText;
            vm.roundNumbers = roundNumbers;
            vm.legendTitle = legendTitle;
            vm.monthShow = true;
            vm.daysShow = true;
            vm.firstMonth = true;
            vm.unselectedDays = [];
            vm.hours = [];
            vm.incr = 3;
            vm.previousDay = null;
            vm.dayCounter = 0;
            vm.checkOrder = true;
            vm.cardWidth = widthOfCard - 26;
            vm.setTimeOfWeek();
            vm.data = data;
            vm.noData = vm.data.filter(x => x.dataInsights.noData == true).length > 0;
            vm.getHoursFromData(vm.data);
            vm.findUnselectedDays(vm.daysOfWeek);
            vm.removeUnselectedDays(vm.unselectedDays);
            vm.makeRangeOfDays = makeRangeOfDays;
            vm.dayRange = vm.makeRangeOfDays(vm.data);
            vm.countDays = 0;
            vm.countHours = 0;
            var months = d3.groups(vm.data, d => new Date(d.date).getMonth());
            var rectWidth = vm.dayRange.length > 1 ? ((vm.cardWidth - 86) / vm.dayRange.length) : (vm.cardWidth - 86);
            vm.rectHeight = 167 / vm.hours.length;

            //create the svg
            const svg = d3v5.create("svg")
                .attr("viewBox", [0, 0, vm.cardWidth, 192])
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr('style', 'height:' + 192 + 'px')
                .attr("class", "flex layout-row svg-content");

            const month = svg.selectAll("g")
                .data(months)
                .join("g")
                .attr("transform", (d, i) => vm.transformPosition(d, i));


            month.append("text")
                .attr("x", -5)
                .attr("y", -9)
                .attr("font-weight", "bold")
                .attr("text-anchor", "end")
                .text(([key]) => vm.displayOnce(key))

            month.append("g")
                .attr("text-anchor", "end")
                .selectAll("text")
                .data(vm.hours.map(i => vm.formatHoursInYAxis(i)))
                .join("text")
                .attr("x", -5)
                .attr("y", d => vm.formatYAxisHour(d))
                .attr("dy", "0.17em")
                .text(t => vm.formatHoursInYAxisText(t))
            // .attr('style', 'font-size:' + 8  + 'px')

            month.append("g")
                .selectAll("rect")
                .data(([, values]) => values)
                .join("rect")
                .attr("width", rectWidth)
                .attr("height", vm.rectHeight)
                .attr("x", d => vm.xPositionOfRect(d, rectWidth))
                .attr("y", d => vm.yPositionOfRect(d))
                .attr("fill", d => (d.dataInsights.noData || d.dataInsights.missingData) ? getPattern(d) : vm.color()(d.value))
                .append("title")
                .text(d => vm.getTooltipText(d));

            const day = month.append("g")
                .selectAll("g")
                .data(vm.dayRange)
                .join("g");

            vm.dayCounter = 0;

            day.append("text")
                .attr("x", d => vm.alignDay(d, rectWidth))
                .attr("y", -5)
                .text(t => vm.formatDay(t));

            var newNode = svg.node();
            var parentNode = $element.find('#hourly-heatmap-chart');
            parentNode.empty();
            parentNode.append(newNode);

            var legendParent = $element.find('#hourly-heatmap-legend');
            var legend = vm.getLegend();
            legendParent.empty();
            legendParent.append(legend);

            var patternParent = $element.find('#hourly-heatmap-pattern');

            if (vm.noData) {
                var pattern = vm.getPatternLegend();
                patternParent.empty();
                patternParent.append(pattern);
            }
            else {
                patternParent.empty();
            }

            var parentInfoNode = $element.find('#calendar-heatmap-info');
            var info = vm.getInfoLegend();
            parentInfoNode.empty();
            parentInfoNode.append(info);
        }

        function getPatternLegend() {
            let width = 100;
            let height = 50;

            let rectWitd = 54;
            let rectXPosition = -45;
            if (vm.cardWidth > 750) {
                rectWitd = 116;
                rectXPosition = 15;
            }

            const svg = d3v5.create("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [rectXPosition, 0, width, height])
                .style("overflow", "visible")
                .style("display", "block");

            //Create and append rectangle element
            svg.append("rect")
                .attr("x", 0)
                .attr("y", 18)
                .attr("width", rectWitd)
                .attr("height", 10)
                .attr("fill", 'url(#crosshatch)')
                .attr("stroke", "#000")
                .attr("stroke-width", 0.2)

            svg.append("text")
                .attr("x", 35)
                .attr("y", 42)
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr("text-anchor", "end")
                .text("No data")

            return svg.node();
        }

        function getInfoLegend() {
            var viewBoxX = -40;
            switch (vm.chartText) {
                case 1:
                    viewBoxX = -155;
                    break;
                case 2:
                    viewBoxX = -105;
                    break;
                case 3:
                    switch (vm.legendTitle) {
                        case 'Counts':
                            viewBoxX = -34
                            break;
                        case 'Seconds':
                            viewBoxX = -42;
                            break;
                        case 'Percentage':
                        default:
                            viewBoxX = -54
                            break;
                    }
                    break;
                case 4:
                    viewBoxX = -109;
                    break;
                case 5:
                    viewBoxX = -63;
                    break;
            }
            const svg = d3v5.create("svg")
                .attr("width", 20)
                .attr("height", 20)
                .attr("viewBox", [viewBoxX, 56, 20, 20])
                .style("overflow", "visible")
                .style("display", "block")

            //Create and append rectangle element
            svg.append("image")
                .attr("x", 11)
                .attr("y", 26)
                .attr("width", 17)
                .attr("height", 17)
                .attr("preserveAspectRatio", "none")
                .attr("xlink:href", '../assets/icons/info_icon_trends.svg')
                .append("title")
                .text(() => vm.getLegendTooltip());

            return svg.node();
        }

        function getPattern(d) {
            let res = '';
            if (d.dataInsights.noData) res = 'url(#crosshatch)'; // add also condition if d.value is == 0
            if (d.dataInsights.missingData) res = res = 'url(#lightstripe)';
            if (d.dataInsights.unrelabileData) res = "url(#diagonal-stripe-3)"
            return res;
        }


        function prefixToDate(d) {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
            }
        }

        // Getting range of days that are selected, considering only days (since we the data is hourly)
        // Aim of this function is to create custom range according to time frame selection and unselected days
        function makeRangeOfDays(values) {
            var res = [];
            var previousDay = null;
            values.forEach(function (v) {
                if (previousDay == null || previousDay != (new Date(v.date).getDate())) res.push(new Date(v.date));
                previousDay = new Date(v.date).getDate();
            })

            return res;
        }

        function findUnselectedDays(selectedDays) {
            for (let i = 0; i < 7; i++) {
                let res = selectedDays.filter(n => n == i);
                if (!res.length > 0) vm.unselectedDays.push(i);
            }
        }


        function removeUnselectedDays(unselectedDays) {
            unselectedDays.forEach(function (u) {
                vm.data.forEach(function (d, i) {
                    let count = vm.countDay(new Date(d.date));
                    if (u == count) {
                        vm.data.splice(i, 1);
                    }
                })
            })
        }

        // Getting hours from data and storing into array (from min to max). Aim is to have hours which are going to be on left Y axis
        function getHoursFromData(data) {
            for (let i = 0; i < 100; i++) {
                if (data[i] && new Date(data[i].date).getHours() < 24) {
                    let res = vm.hours.filter(n => n == new Date(vm.data[i].date).getHours());
                    if (!res.length > 0) vm.hours.push(new Date(vm.data[i].date).getHours());
                }
            }
            vm.hours = vm.hours.sort(function (a, b) {
                return a - b;
            });
        }

        // Determining Y position of each rect 
        function yPositionOfRect(d) {
            let date = new Date(d.date)
            let res = 0;
            res = vm.hours.indexOf(date.getHours()) * vm.rectHeight;
            return res;
        }

        //Positioning Y axis depending on height of rect. So depending on selected hours height of rect is changed
        function formatYAxisHour(i) {
            let res = vm.incr;
            vm.incr += vm.rectHeight;
            vm.count++;
            return res;
        }

        // data should be in incremantal order starting by 0 even if the selected hours are [5pm,6pm,7pm] -> data[0,1,2]
        // which will determine positions of rect on  y axis
        function formatHoursInYAxis(i) {
            return i;
        }

        //Printing hours on left Y axis depending on hour selection
        function formatHoursInYAxisText(i) {
            if (vm.countHours < vm.hours.length) {
                let res = "";
                var ampm = i >= 12 ? 'pm' : 'am';
                let hours = i % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                if (vm.hours.length > 8) {
                    if (vm.checkOrder) {
                        res = hours + ' ' + ampm;
                        vm.checkOrder = false;
                    }
                    else {
                        vm.checkOrder = true;
                    }
                }
                else {
                    res = hours + ' ' + ampm;
                }
                vm.countHours++;
                return res;
            }
        }

        // Determining position of each day on top X axis
        function alignDay(d, rectWidth) {
            let res;
            let day = vm.dayCounter;
            res = day * rectWidth + 2;
            vm.dayCounter++;
            return res;
        }

        // Displaying only first month on Y-Axis 
        function displayOnce(y) {
            if (vm.monthShow) {
                vm.monthShow = false;
                return getMonthFormat(y);
            }
            else return null;
        }


        // Determining X position of each rect 
        function xPositionOfRect(d, rectWidth) {
            let startDate = new Date(vm.data[0].date)
            let date = new Date(d.date)
            let res = 0;

            if (vm.previousDay == null) vm.previousDay = startDate.getDate();

            if (vm.previousDay == date.getDate()) res = vm.dayCounter * rectWidth + 0.5;
            else {
                vm.previousDay = date.getDate();
                vm.dayCounter++;
                res = vm.dayCounter * rectWidth + 0.5;
            }
            return res;
        }

        function transformPosition(d, i) {
            let step = i > 0 ? 2 : 0;
            return `translate(${vm.widthMonth + step}, ${18 * 1.5})`;
        }

        function tConvert (time) {
            // Check correct time format and split into components
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
          
            if (time.length > 1) { // If time format correct
              time = time.slice (1);  // Remove full string match value
              time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
              time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            return time.join (''); // return adjusted time or original string
          }
          

        function getTooltipText(d) {
            if (vm.chartText == 2)
                return `${vm.formatDate(d.date)} Hourly baseline date: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 1)
                return `${vm.formatDate(d.date)} Hourly baseline average: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 5)
                return `${vm.formatDate(d.date)} Hourly dow Change: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 4)
                return `${vm.formatDate(d.date)} Hourly percent Change: ${vm.formatValue(d.value)} ${vm.chartName}: ${d.totalValue.toLocaleString()}`
            if (vm.chartText == 3)
                return `${vm.formatDate(d.date)} to ${vm.formatDateOneHour(d.date)} ${vm.chartName}: ${d.value.toLocaleString()}`
        }

        function getLegendTooltip() {
            if (vm.chartText == 2){
                return `Percent change (compared to the baseline date) \nAverage: ${vm.baselineOptions.baselineDateValue.toFixed(2)}`
            }
            if (vm.chartText == 1){
                return `Percent change (compared to the baseline) \nBaseline Average: ${vm.baselineOptions.baselineAverageValue.toFixed(2)}`
            }
            if (vm.chartText == 5){
                return `Percent change (compared to the same day of  previous week)`
            }
            if (vm.chartText == 4){
                return `Percent change (compared to the previous day)`
            }
            if (vm.chartText == 3)
                return `Colors are asigned based on the range of values.`
        }

        function formatDay(t) {
            let day = "";
            let res = "";
            let dayText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][t.getDay()];
            day = t.getDate();
            if (vm.dayRange.length <= 28) {
                if (vm.countDays < vm.dayRange.length && vm.cardWidth < 750) {
                    res = vm.dayRange.length > 10 ? (day + "" + vm.prefixToDate(day)) : (dayText + ", " + day + "" + vm.prefixToDate(day));
                    vm.countDays++;
                    return res;
                }
                if (vm.countDays < vm.dayRange.length && vm.cardWidth > 750) {
                    res = (dayText + ", " + day + "" + vm.prefixToDate(day));
                    vm.countDays++;
                    return res;
                }
            }
            else {
                if (vm.countDays < vm.dayRange.length && vm.cardWidth < 750) {
                    if (vm.checkOrder) {
                        res = day;
                        vm.checkOrder = false;
                        vm.countDays++;
                        return res;
                    }
                    else {
                        vm.checkOrder = true;
                        res = "";
                        vm.countDays++;
                        return res;
                    }
                }
                if (vm.countDays < vm.dayRange.length && vm.cardWidth > 750) {
                    if (vm.checkOrder) {
                        res = (day + "" + vm.prefixToDate(day));
                        vm.checkOrder = false;
                        vm.countDays++;
                        return res;
                    }
                    else {
                        vm.checkOrder = true;
                        res = "";
                        vm.countDays++;
                        return res;
                    }
                }
            }
        }

        function minutesLeadingZeros(dt) {
            return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
        }

        function formatDate(d) {
            var formatedHour = (new Date(d).getHours() < 10 ? '0' : '') + new Date(d).getHours(); 
            return d3v5.timeFormat("%x")(new Date(d)) + " " + vm.tConvert(formatedHour + ":" + vm.minutesLeadingZeros(new Date(d)));
        }

        function formatDateOneHour(d) {
            var formatedHour = ((new Date(d).getHours() + 1) < 10 ? '0' : '') + (new Date(d).getHours() + 1); 
            return vm.tConvert(formatedHour + ":" + vm.minutesLeadingZeros(new Date(d)));
        }

        function formatValue(d) {
            return d3v5.format("+.2%")(d);
        }

        function formatMonth(t) {
            return d3v5.timeFormat("%b")(t);
        }

        function height() {
            return vm.rectHeight * (vm.daysOfWeek.length + 2);
        }

        function countDay(d) {
            let a = new Date(d).getDay() + 6;
            return a % 7;
        }

        function getMonthFormat(t) {
            let res = ""
            switch (t) {
                case 0: res = "Jan"
                    break;
                case 1: res = "Feb"
                    break;
                case 2: res = "Mar"
                    break;
                case 3: res = "Apr"
                    break;
                case 4: res = "May"
                    break;
                case 5: res = "Jun"
                    break;
                case 6: res = "Jul"
                    break;
                case 7: res = "Aug"
                    break;
                case 8: res = "Sep"
                    break;
                case 9: res = "Oct"
                    break;
                case 10: res = "Nov"
                    break;
                case 11: res = "Dec"
                    break;
            }
            return res;
        }

        function setTimeOfWeek() {
            switch (vm.daysOfWeek[0]) {
                case 0: vm.timeOfWeek = d3v5.timeMonday;
                    break;
                case 1: vm.timeOfWeek = d3v5.timeTuesday;
                    break;
                case 2: vm.timeOfWeek = d3v5.timeWednesday;
                    break;
                case 3: vm.timeOfWeek = d3v5.timeThursday;
                    break;
                case 4: vm.timeOfWeek = d3v5.timeFriday;
                    break;
                case 5: vm.timeOfWeek = d3v5.timeSaturday;
                    break;
                case 6: vm.timeOfWeek = d3v5.timeSunday;
                    break;
            }
        }


        function color() {
            var noInsightsData = vm.data.filter(x => x.dataInsights.missingData == false && x.dataInsights.noData == false)
            var max = d3v5.quantile(noInsightsData.map(d => Math.abs(d.value)).sort(d3v5.ascending), 0.9975);
            switch (vm.chartType) {
                case 2:
                    vm.isScaleSequentialSqrt = false;
                    if (max > .20)
                        max = .20;
                    return d3v5.scaleDivergingSqrt([-max, 0, +max], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme))
                case 1:
                    var scheme = vm.colorScheme; //d3v5.schemeRdYlGn[6];
                    var domainData = noInsightsData.map(x => x.value).sort(d3v5.ascending);
                    let minOfDomain = Math.min(...domainData)
                    let maxOfDomain = Math.max(...domainData)
                    // if (vm.reverseColorScale) {
                    //     vm.colorScheme = vm.colorScheme.slice().reverse();
                    //     scheme = scheme.slice().reverse();
                    // }
                    
                    //if (max > .20) max = .20;

                    // switch(vm.selectedLegend){
                    //     case vm.signalTrendsService.legendTypes.scaleSequential:
                    //             vm.isScaleSequentialSqrt = true;
                    //             vm.isSquareRoot = true;
                    //             return d3v5.scaleSequential([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialSqrt:
                    //             vm.isScaleSequentialSqrt = true;
                    //             vm.isSquareRoot = true;
                    //             return d3v5.scaleSequentialSqrt([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleDiverging:
                    //             return d3v5.scaleDiverging([-1, 0, +1], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleDivergingSqrt:
                    //             return d3v5.scaleDivergingSqrt([-1, 0, +1], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialLog:
                    //             vm.isScaleSequentialSqrt = false;
                    //             vm.isSquareRoot = true;
                    //             return d3v5.scaleSequentialLog([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialQuantile:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleSequentialQuantile([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleSqrt:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleSqrt([minOfDomain, ((maxOfDomain+minOfDomain) / 2).toFixed(), maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleQuantize:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleQuantize([minOfDomain, maxOfDomain], d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleQuantile:
                    //             vm.isSquareRoot = false;
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleQuantile(domainData, vm.reverseColorScale ? vm.reversedScheme : scheme);                                
                    //             //return d3v5.scaleLog().domain(d3v5.extent(domainData)).base(10).range(vm.reverseColorScale ? vm.reverseLogScheme: vm.logScheme)        
                    //     case vm.signalTrendsService.legendTypes.scaleThreshold:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleThreshold(domainData, d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme));        
                    //     case vm.signalTrendsService.legendTypes.scaleOrdinal:
                    //             vm.isScaleSequentialSqrt = false;
                    //             return d3v5.scaleOrdinal(["<10", "10-49", "50-69", "70-79", "≥80"], d3v5.schemeSpectral[5]);        
                    // }

                    if (vm.isSquareRoot) {
                        vm.isScaleSequentialSqrt = true;
                        return d3v5.scaleSequentialSqrt().interpolator(d3v5.interpolateRgbBasis(vm.reverseColorScale ? vm.reversedScheme : vm.colorScheme)).domain([minOfDomain, maxOfDomain]);
                    }
                    else {
                        vm.isScaleSequentialSqrt = false;
                        return d3v5.scaleQuantile(domainData, vm.reverseColorScale ? vm.reversedScheme : scheme);
                    }

            }

        }



        function getLegend() {
            vm.isScaleSequentialSqrt = false;
            switch (vm.chartType) {
                case 2:
                    vm.caseTwo = true;
                    if (vm.chartText == 2){
                        vm.legendTitle = 'Hourly baseline Date Change'
                    }
                    if (vm.chartText == 1){
                        vm.legendTitle = 'Hourly Baseline Average Change';
                    }
                    if (vm.chartText == 5){
                        vm.legendTitle = 'DOW Hourly Change';
                    }
                    if (vm.chartText == 4){
                        vm.legendTitle = 'Hourly percent Change'
                    }
                    if (vm.chartText == 3)
                        vm.legendTitle = legendTitle;
                    return d3v5legend({ color: vm.color(), title: vm.legendTitle, tickFormat: "+%" });
                case 1:
                    vm.caseTwo = false;
                    // switch(vm.selectedLegend){
                    //     case vm.signalTrendsService.legendTypes.scaleSequential:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential", tickFormat: "," });
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialSqrt:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential Sqrt", tickFormat: "," });
                    //     case vm.signalTrendsService.legendTypes.scaleDiverging:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Diverging", tickFormat: "+%" });
                    //     case vm.signalTrendsService.legendTypes.scaleDivergingSqrt:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Diverging Sqrt", tickFormat: "+%" });
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialLog:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential Log", ticks:5});
                    //     case vm.signalTrendsService.legendTypes.scaleSequentialQuantile:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sequential Quantile" });
                    //     case vm.signalTrendsService.legendTypes.scaleSqrt:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Sqrt"});
                    //     case vm.signalTrendsService.legendTypes.scaleQuantize:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Quantize", tickFormat: "," });
                    //     case vm.signalTrendsService.legendTypes.scaleQuantile:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Quantile", tickFormat: "," });
                    //     case vm.signalTrendsService.legendTypes.scaleThreshold:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Threshold", tickSize:0 });
                    //     case vm.signalTrendsService.legendTypes.scaleOrdinal:
                    //             return d3v5legend({ color: vm.color(), title: vm.legendTitle + " " + "Scale Ordinal", tickSize:0 });
                    // }
                     return d3v5legend({ color: vm.color(), title: vm.legendTitle, tickFormat: "," });
            }
        }

        function d3v5legend({
            color,
            title,
            tickSize = 6,
            width = 270,
            height = 44 + tickSize,
            marginTop = 18,
            marginRight = 0,
            marginBottom = 16 + tickSize,
            marginLeft = 0,
            ticks = 7,
            tickFormat,
            tickValues
        } = {}) {

            if (vm.cardWidth < 750) {
                vm.isSquareRoot ? width = width + 54 : width;
            }
            else {
                if (vm.caseTwo) {
                    vm.isSquareRoot ? width = 580 + 116 : width = 580 + 62;
                } else {
                    vm.isSquareRoot ? width = 580 + 116 : width = 580;
                }
            }


            const svg = d3v5.create("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [0, 0, width, height])
                .style("overflow", "visible")
                .style("display", "block")

            let tickAdjust = g => g.selectAll(".tick line").attr("y1", marginTop + marginBottom - height);
            let x;

            // Continuous
            if (color.interpolate) {
                const n = Math.min(color.domain().length, color.range().length);

                x = color.copy().rangeRound(d3v5.quantize(d3v5.interpolate(marginLeft, width - marginRight), n));

                svg.append("image")
                    .attr("x", marginLeft)
                    .attr("y", marginTop)
                    .attr("width", width - marginLeft - marginRight)
                    .attr("height", height - marginTop - marginBottom)
                    .attr("preserveAspectRatio", "none")
                    .attr("xlink:href", ramp(color.copy().domain(d3v5.quantize(d3v5.interpolate(0, 1), n))).toDataURL())
                    .append("title")
                    .text(() => vm.getLegendTooltip())
            }

            // Sequential
            else if (color.interpolator) {
                if (vm.isSquareRoot) {
                    x = Object.assign(color.copy()
                        .interpolator(d3v5.interpolateRound(marginLeft, width - marginRight)),
                        { range() { return [marginLeft, width - marginRight]; } });
                    svg.append("image")
                        .attr("x", marginLeft)
                        .attr("y", marginTop)
                        .attr("width", width - marginLeft - marginRight)
                        .attr("height", height - marginTop - marginBottom)
                        .attr("preserveAspectRatio", "none")
                        .attr("xlink:href", ramp(color.interpolator()).toDataURL())
                        .append("title")
                        .text(() => vm.getLegendTooltip())
                } else {
                    x = Object.assign(color.copy()
                        .interpolator(d3v5.interpolateRound(marginLeft, width - marginRight + 54)),
                        { range() { return [marginLeft, width - marginRight]; } });
                    svg.append("image")
                        .attr("x", marginLeft)
                        .attr("y", marginTop)
                        .attr("width", width - marginLeft - marginRight + 54)
                        .attr("height", height - marginTop - marginBottom)
                        .attr("preserveAspectRatio", "none")
                        .attr("xlink:href", ramp(color.interpolator()).toDataURL())
                        .append("title")
                        .text(() => vm.getLegendTooltip())
                }

                // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
                if (!x.ticks) {
                    if (tickValues === undefined) {
                        const n = Math.round(ticks + 1);
                        tickValues = d3v5.range(n).map(i => d3v5.quantile(color.domain(), i / (n - 1)).toFixed());
                    }
                    if (typeof tickFormat !== "function") {
                        tickFormat = d3v5.format(tickFormat === undefined ? ",1f" : tickFormat);
                    }
                }
                if (x.ticks && vm.isScaleSequentialSqrt) {
                    if (tickValues === undefined) {
                        const n = Math.round(ticks + 1);
                        tickValues = d3v5.range(n).map(i => d3v5.quantile(color.domain(), i / (n - 1)).toFixed());
                    }
                    if (typeof tickFormat !== "function") {
                        tickFormat = d3v5.format(tickFormat === undefined ? ",1f" : tickFormat);
                    }
                }
            }

            // Threshold
            else if (color.invertExtent) {
                // var thresholds
                //     = color.thresholds ? color.thresholds() // scaleQuantize
                //         : color.quantiles ? color.quantiles().map(x => vm.roundNumbers ? d3.round(x) : x.toFixed(2)) // scaleQuantile
                //             : color.domain(); // scaleThreshold
                var thresholds
                    = color.thresholds ? color.thresholds() // scaleQuantize
                        : color.quantiles ? color.quantiles().map(x => x.toFixed(2)) // scaleQuantile
                            : color.domain(); // scaleThreshold
                thresholds.unshift(color.domain()[0]);
                thresholds.push(color.domain()[color.domain().length - 1]);

                const thresholdFormat
                    = tickFormat === undefined ? d => d
                        : typeof tickFormat === "string" ? d3v5.format(tickFormat)
                            : tickFormat;

                x = d3v5.scaleLinear()
                    .domain([0, color.range().length - 1])
                    .rangeRound([marginLeft, width - marginRight]);

                svg.append("g")
                    .selectAll("rect")
                    .data(color.range())
                    .join("rect")
                    .attr("x", (d, i) => x(i))
                    .attr("y", marginTop)
                    .attr("width", (d, i) => x(i) - x(i - 1))
                    .attr("height", height - marginTop - marginBottom)
                    .attr("fill", d => d)
                    .append("title")
                    .text(() => vm.getLegendTooltip())

                tickValues = d3v5.range(thresholds.length);
                tickFormat = i => thresholdFormat(thresholds[i], i);
            }

            // Ordinal
            else {
                x = d3v5.scaleBand()
                    .domain(color.domain())
                    .rangeRound([marginLeft, width - marginRight]);

                svg.append("g")
                    .selectAll("rect")
                    .data(color.domain())
                    .join("rect")
                    .attr("x", x)
                    .attr("y", marginTop)
                    .attr("width", Math.max(0, x.bandwidth() - 1))
                    .attr("height", height - marginTop - marginBottom)
                    .attr("fill", color)
                    .append("title")
                    .text(() => vm.getLegendTooltip())

                tickAdjust = () => { };
            }

            svg.append("g")
                .attr("transform", translateLegend(color, height, marginBottom))
                .call(d3v5.axisBottom(x)
                    .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
                    .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
                    .tickSize(tickSize)
                    .tickValues(tickValues))
                .call(tickAdjust)
                .call(g => g.select(".domain").remove())
                .call(g => g.append("text")
                    .attr("x", marginLeft)
                    .attr("y", marginTop + marginBottom - height - 6)
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "start")
                    .attr("font-weight", "bold")
                    .text(title));

            return svg.node();
        }

        function translateLegend(color, height, marginBottom) {
            return `translate(0,${height - marginBottom})`;
        }


        function ramp(color, n = 256) {
            const canvas = getCanvas(n, 1);
            const context = canvas.getContext("2d");
            for (let i = 0; i < n; ++i) {
                context.fillStyle = color(i / (n - 1));
                context.fillRect(i, 0, 1, 1);
            }
            return canvas;
        }

        function getCanvas(e, t) {
            var n = document.createElement("canvas");
            return n.width = e,
                n.height = t,
                n
        }

        function entity(character) {
            return `&#${character.charCodeAt(0).toString()};`;
        }
    }
}());
