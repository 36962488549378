import * as HELPDIALOG from 'raw-loader!./help-dialog.html';

(function ()
{
    'use strict';
    angular
        .module('app.spm.dashboards.executive')
        .controller('DashboardExecutiveController', DashboardExecutiveController);

    /** @ngInject */
    function DashboardExecutiveController($scope, $state, searchBarService, signalService, signalPerformanceService, $mdDialog, $translate, $element, $timeout, chartsService,userSettingsService,helpService )
    {
        var vm = this;
        vm.searchBarService = searchBarService;
        vm.timeOptions = {};
        vm.signal = {};
        vm.setSearchOptions = setSearchOptions;
        vm.setupSearchBar = setupSearchBar;
        vm.updateCards = updateCards;
        vm.onCardApiInit = onCardApiInit;
        vm.getExecutiveInformation = getExecutiveInformation;
        vm.executiveInfo = {};
        vm.helpCardTitle = "";
        vm.showHelpAlert = showHelpAlert;
        vm.checkCompatibility = checkCompatibility;
        vm.chartCompatible = true;
        vm.loading = true;
        vm.chartServerError = false;
        vm.hasData = true;
        vm.areAllCardsInitialized = areAllCardsInitialized;

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })

        vm.performanceCards = [
            {
                metricType: signalPerformanceService.metricTypesEnum.Volume,
                aggregationName: signalPerformanceService.aggregationNames.VehicleDelay,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
            {
                metricType: signalPerformanceService.metricTypesEnum.DelayPerVehicle,
                aggregationName: signalPerformanceService.aggregationNames.VehicleDelay,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData:[],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function() {
                    this.api = {};
                }
            },
        ];
        vm.setupSearchBar();

        function checkCompatibility() {
            chartsService.getChartOptionsForSignal(vm.signal.signalID, "7").then(function (data) {
                vm.chartCompatible = true;
                vm.updateCards();
            })
            .catch(function (err) {
                if (vm)
                    vm.chartCompatible = false;
                return true; 
            });
        }
        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return; 

                switch (changeType) {
                    case "time":
                    case "signal":
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.chartCompatible = true;
                            vm.setSearchOptions();
                            vm.checkCompatibility();
                        }
                        break;
                    case "state":
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Signal Executive Dashboard"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Signals',
                showCurrentFilterDates: true,
                helpJsonPropertyPath: "EXECUTIVE_CARD." + "GENERAL_HELP",
                showExcel: true,
                metricTypeId: userSettingsService.dashboardTypeIds.SignalExecutive,      
                dashboardType: "Signal",  
                showHelp: true,
                showSignalNotificationTabs: true,      
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TW",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "TimePeriod",
                    showWeekdayFilter: true,
                    enableAdvancedTod: false,
                    maxDayRange: 31
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: true,
                    skipStepsPerBin: false,
                    notifyOnBinChange: true,
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function onCardApiInit(apiObj) {
            if (apiObj && apiObj.update) {
                apiObj.expand();
                vm.updateCards();
            }
        }

        function areAllCardsInitialized(){
            let res = true;
            for(let i = 0; i < vm.performanceCards.length; i++){
                let api = vm.performanceCards[i].api;
                if (!api || !api.update){
                    res = false;
                }
            }
            return res;
        }

        function updateCards() {
            vm.hasData = true;
            if (vm.areAllCardsInitialized())
                vm.getExecutiveInformation();
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.signal = {
                signalID: options.signal.signalID,
                description: options.signal.description,
            };
            vm.timeOptions = options.timeOptions;
        };

        function getExecutiveInformation() {
           vm.loading = true;
            
            //setup default parameters
            var daysOfWeek = [" "];
            var startHour = 0, startMinute = 0, endMinute = 0, endHour = 0;
            var start = new Date(vm.timeOptions.currentFilter.startDateAndTime);
            var end = new Date(vm.timeOptions.currentFilter.endDateAndTime);
            var aggregationTypesToQuery = [...new Set(vm.performanceCards.map(x => x.aggregationName))];

            //set query parameters passed into card
            vm.currentBin = vm.timeOptions.bin;
            //focus time frame and today time frame do not send "TimeOption". Send "StartToEnd" for timeOption
            if (vm.timeOptions.timeOption == "TimePeriod" || vm.timeOptions.currentFilter.selectedTodTemplate == "CT") {
                startHour = start.getHours();
                startMinute = start.getMinutes();
                endHour = end.getHours();
                endMinute = end.getMinutes();
                vm.timeOptions.timeOption = "TimePeriod";
                vm.searchBarService.setTimeOptionsNoNotify(vm.timeOptions);
            }
            if (vm.timeOptions.daysOfWeekList) {
                daysOfWeek = vm.timeOptions.daysOfWeekList;
            }
            var opts = signalPerformanceService.createOptionsObject(vm.signal.signalID, aggregationTypesToQuery, start.toLocaleString(), end.toLocaleString(), startHour, startMinute, endHour, endMinute, daysOfWeek, vm.timeOptions.timeOption, vm.timeOptions.bin);
            signalPerformanceService.getExecutiveInformation(opts)
                .then(function (res) {
                    vm.chartServerError = false;
                    if (!res || !res.signalData || !vm.chartCompatible){
                        vm.chartServerError = true;
                        return;
                    }


                    vm.executiveInfo.emissionsTons = res.emissionsTons.toLocaleString();
                    vm.executiveInfo.emissionsWasteGallonsGas = res.emissionsWasteGallonsGas.toLocaleString();
                    vm.executiveInfo.emissionsWasteKgVOC = res.emissionsWasteKgVOC;

                    vm.executiveInfo.emissionsCost = res.emissionsCost.toLocaleString();
                    vm.executiveInfo.totalCost = res.totalCost.toLocaleString();
                    vm.executiveInfo.laborCosts = res.laborCosts.toLocaleString();

                    vm.executiveInfo.levelOfService = res.levelOfService;

                    //extrapolate annually
                    //get total days of filter and divide by 365
                    //then multiple by that to get annual
                    var searchDays = searchBarService.calculateFilterTimeSpanInDays();
                    var dailyMultiplier = 1;
                    if (searchDays < 1 && searchDays > 0) {
                        dailyMultiplier = 1 / searchDays;
                        searchDays = 1;
                    }
                    var annualRatio = 365 / searchDays;
                    if (!vm.executiveInfo.annual)
                        vm.executiveInfo.annual = {};

                    vm.executiveInfo.annual.totalCost = moneyFormat(((parseFloat(vm.executiveInfo.totalCost.replace(/,/g, '')) * dailyMultiplier) * annualRatio));
                    vm.executiveInfo.annual.laborCosts = moneyFormat(((parseFloat(vm.executiveInfo.laborCosts.replace(/,/g, '')) * dailyMultiplier) * annualRatio));
                    vm.executiveInfo.annual.emissionsTons = Math.round(((parseFloat(vm.executiveInfo.emissionsTons.replace(/,/g, '')) * dailyMultiplier) * annualRatio));
                    vm.executiveInfo.annual.emissionsCost = moneyFormat(((parseFloat(vm.executiveInfo.emissionsCost.replace(/,/g, '')) * dailyMultiplier) * annualRatio));

                    if(res.emissionsCost <= 0 && res.totalCost <= 0)
                        vm.hasData = false;
                    else
                        vm.hasData = true;

                    //update performance cards
                    let fullDataSet = res.signalData;
                    //go through each card in array and match with data from server
                    //do any pre-processing of the data that is needed, then pass into each card
                    //for display
                    for(let i = 0; i <  vm.performanceCards.length; i++ ){
                        let thisCard = vm.performanceCards[i];
                        let cardDataSet = fullDataSet[thisCard.aggregationName];
                        if (!cardDataSet) continue;

                        thisCard.data.detectionTypeId = cardDataSet.data.detectionTypeId;
                        thisCard.data.hasNeededDetection = cardDataSet.data.hasNeededDetection;
                        thisCard.data.signalData = cardDataSet.data.binsContainer;
                        thisCard.data.approachData = cardDataSet.approaches;
                        if (thisCard.api && thisCard.api.update)
                            thisCard.api.update(thisCard.data, vm.signal);
                    }
                    vm.loading = false;
                })
                .catch(function (error){
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
            });    
        }

        function moneyFormat(labelValue) {
            // Nine Zeroes for Billions
            return Math.abs(Number(labelValue)) >= 1.0e+9
                ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
                // Six Zeroes for Millions 
                : Math.abs(Number(labelValue)) >= 1.0e+6
                ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
                //// Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3
                ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
                : Math.abs(Number(labelValue));
        }

        function showHelpAlert(cardTitle) {
            vm.helpCardTitle = cardTitle;
            $mdDialog.show({
                controller: HelpController,
                parent: $element,
                clickOutsideToClose: true,
                template: HELPDIALOG,
            });
        };

        function HelpController($scope, $mdDialog) {
            $scope.titleTranslation = "EXECUTIVE_CARD." + vm.helpCardTitle + ".TITLE";
            $scope.descriptionTranslation = "EXECUTIVE_CARD." + vm.helpCardTitle + ".DESCRIPTION";
            $scope.resourceTranslation = "EXECUTIVE_CARD." + vm.helpCardTitle + ".RESOURCES";
            $scope.showResources = vm.helpCardTitle == "Emissions" ? true : false;

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        }
    }

})();