﻿(function () {
    'use strict';

    angular.module('app.spm.charts.signal')
    .directive('splitMonitorCharts', splitMonitorCharts);

    /** @ngInject */
    function splitMonitorCharts($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'splitMonitorChartsController as vm',
            templateUrl: function (elem, attrs) {
                 return 'app/main/spm/charts/signals/directives/split-monitor/split-monitor-charts.html';
            },
            scope: {
                signal: '<',
                onApiInit: '&',
                onApiDestroy: '&',
                api: '=',
                loading: '='
            },
            link: function (scope) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        updateData: function (data) {
                            return scope.updateData(data);
                        },
                        updateFetchData: function (){
                            return scope.updateFetchData();

                        }
                                  
                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();