﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts")
        .controller("approachVolumeChartController", approachVolumeChartController);

    function approachVolumeChartController($state, $attrs, $scope, $rootScope, $element, environmentConfig, chartsService, searchBarService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 7;
        vm.isDataAvailable = false;

        vm.chartLoading = false;
        vm.chartRendering = false;
        vm.chartServerError = false;
        vm.hideButtons = $scope.hideButtons;
        vm.togglePlanFill = "#000";

        vm.searchBarService = searchBarService;
        vm.chartsService = chartsService;
        vm.xaxeFormat = $scope.xaxeFormat ? $scope.xaxeFormat : 'hour';

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input.data && input.data != "{}") {
                    var jsonData = JSON.stringify(input, null, 4);
                    vm.approachVolumeChartWidget.processVolumeData(JSON.parse(jsonData, JSON.dateParser));
                    $scope.loading = false;
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            $scope.rawData = null;
            $scope.chartData = null;
            $scope.api = {};
            $scope.onApiInit = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();
        });

        var planAnnotations = [];
        $scope.planColor = 'rgba(0,0,0, 0.1)';
        function getPlanAnnotations(planList) {
            // if (!$scope.hidePlans) {
            for (let i = 0; i < planList.length; i++) {
                //create annotation for each plan
                var fillStyle;
                var thisPlan = planList[i];

                var odd = i % 2;
                if (odd) {
                    fillStyle = 'rgba(238, 238, 238, 0)';
                }
                else {
                    fillStyle = $scope.planColor;
                }

                var planStartTime = (new Date(thisPlan.startTime)).getTime();
                var planEndTime = (new Date(thisPlan.endTime)).getTime();

                var planAnnotation = {
                    id: 'plan-' + i + "-" + thisPlan.planNumber, // optional
                    type: 'box',
                    drawTime: 'afterDatasetsDraw',
                    // ID of the X scale to bind onto
                    xScaleID: 'x-axis-0',
                    // ID of the Y scale to bind onto
                    yScaleID: '1',
                    xMin: planStartTime,
                    borderColor: 'transparent',
                    xMax: planEndTime,
                    yMin: 0,
                    backgroundColor: fillStyle,
                }
                planAnnotations.push(planAnnotation);
            }
            // } else {
            //     for (let i = 0; i < planList.length; i++) {
            //         //create annotation for each plan
            //         var fillStyle;
            //         var thisPlan = planList[i];

            //         fillStyle = 'rgba(238, 238, 238, 0)';

            //         var planStartTime = thisPlan.startTime.getTime();
            //         var planEndTime = thisPlan.endTime.getTime();

            //         var planAnnotation = {
            //             id: 'plan-' + i + "-" + thisPlan.planNumber, // optional
            //             type: 'box',
            //             drawTime: 'afterDatasetsDraw',
            //             // ID of the X scale to bind onto
            //             xScaleID: 'x-axis-0',
            //             // ID of the Y scale to bind onto
            //             yScaleID: '1',
            //             xMin: planStartTime,
            //             borderColor: 'transparent',
            //             xMax: planEndTime,
            //             yMin: 0,
            //             backgroundColor: fillStyle,
            //         }
            //         planAnnotations.push(planAnnotation);
            //     }
            //     // planAnnotations = [];

            // }
        }

        vm.approachVolumeChartWidget = {
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.approachVolumeChartWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            isRendered: false,
            chartArray: {},
            addPlanLabels: function (primaryPlans, secondaryPlans, primaryLabel, secondaryLabel) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        plan.labels.push(planText, primaryLabel + " : " + plan.totalVolume, secondaryLabel + " : " + secondaryPlans[i].totalVolume);
                        plansCopy[i] = plan;
                        getPlanAnnotations(plansCopy);
                    }
                } else {
                    for (let i = 0; i < plansCopy.length; i++) {
                        plansCopy[i].labels = [];
                        getPlanAnnotations(plansCopy);
                    }
                }
                return plansCopy;
            },
            processVolumeData: function (rawData) {
                var processedData = Object.values(rawData.data);
                processedData.length > 0 ? vm.isDataAvailable = true : vm.isDataAvailable = false;

                var charts = [];

                if (vm.chartsService.getChartComplexity() == 'Simple' || $scope.useSimpleChart) {
                    $scope.hideDfactor = true
                } else {
                    $scope.hideDfactor = false;
                }

                var chartData = [];
                var chartsData = [];
                var planList = [];
                var advNSdata = rawData.data.advancedNorthSouthData;
                var advEWdata = rawData.data.advancedEastWestData;
                var advNwSe = rawData.data.advancedNorthWestSouthEastData;
                var advNeSw = rawData.data.advancedNorthEastSouthWestData;


                if (!charts) {
                    charts = [];
                }


                if (advNSdata) {
                    //holds array of all the specific chart data we need to show
                    var allPhaseChartData = [];
                    var pushChart = false;
                    //Northbound is series 1
                    //Southbound is series 2
                    //D-Factor for each is series 3, 4                 

                    if (advNSdata.series1 && advNSdata.series1.length > 0 && advNSdata.primaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Northbound",
                            type: "LineWithLine",
                            yAxisID: '1',
                            data: advNSdata.series1,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4,
                            borderColor: chartsService.colors.blue,
                            strokeColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            order: 2,
                        });
                    }
                    if (advNSdata.series2 && advNSdata.series2.length > 0 && advNSdata.secondaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Southbound",
                            data: advNSdata.series2,
                            type: "LineWithLine",
                            yAxisID: '1',
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNSdata.dFactorSeries1 && advNSdata.dFactorSeries1.length > 0 && advNSdata.primaryTotal > 0 && advNSdata.secondaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Northbound D-Factor",
                            data: advNSdata.dFactorSeries1,
                            type: "LineWithLine",
                            yAxisID: '2',
                            classed: 'dashed-line',
                            borderDash: [10, 5],
                            strokeColor: chartsService.colors.blue,
                            borderColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNSdata.dFactorSeries2 && advNSdata.dFactorSeries2.length > 0 && advNSdata.secondaryTotal > 0 && advNSdata.primaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Southbound D-Factor",
                            data: advNSdata.dFactorSeries2,
                            type: "LineWithLine",
                            yAxisID: '2',
                            classed: 'dashed-line',
                            borderDash: [10, 5],
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }

                    var searchObj = searchBarService.getSearchOptions();

                    charts.push({
                        title: "Approach Volume for " + $scope.signal.description + " - " + "Northbound and Southbound",
                        primaryHeader: "Northbound",
                        id: "northSouthReport", // add from approach-volume.controller.js
                        primaryLine1: advNSdata.primaryTotal > 0 ? "Volume: " + advNSdata.primaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advNSdata.primaryPeakHour.peakHour : "No detection for this direction",
                        primaryLine2: advNSdata.primaryTotal > 0 ? "PHV = " + advNSdata.primaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advNSdata.primaryPeakHour.kFactor + "; D-Factor = " + advNSdata.primaryPeakHour.dFactor + "; PHF = " + advNSdata.primaryPeakHour.factor : "",
                        secondaryHeader: "Southbound",
                        secondaryLine1: advNSdata.secondaryTotal > 0 ? "Volume: " + advNSdata.secondaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advNSdata.secondaryPeakHour.peakHour : "No detection for this direction",
                        secondaryLine2: advNSdata.secondaryTotal > 0 ? "PHV = " + advNSdata.secondaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advNSdata.secondaryPeakHour.kFactor + "; D-Factor = " + advNSdata.secondaryPeakHour.dFactor + "; PHF = " + advNSdata.secondaryPeakHour.factor : "",
                        combinedHeader: "Combined",
                        combinedLine1: "Volume: " + advNSdata.combinedTotal.toLocaleString() + "; Peak Hour (PH) = " + advNSdata.combinedPeakHour.peakHour,
                        combinedLine2: "PHV = " + advNSdata.combinedPeakHour.volume.toLocaleString() + "; K-Factor = " + advNSdata.combinedPeakHour.kFactor + "; PHF = " + advNSdata.combinedPeakHour.factor,
                        plans: vm.approachVolumeChartWidget.addPlanLabels(advNSdata.primaryPlans, advNSdata.secondaryPlans, "Northbound", "Southbound"),
                        api: {},
                        planColor: "#000",
                        plansHidden: false,
                        showPrimary: advNSdata.primaryTotal > 0,
                        showSecondary: advNSdata.secondaryTotal > 0,
                        showCombined: advNSdata.primaryTotal > 0 && advNSdata.secondaryTotal > 0,
                        isDataAvailable: advNSdata.combinedTotal > 0,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                            // this.plans.visible = false;
                            if (!this.plans.visible) {
                                this.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                this.plans.visible = true;
                                chart.planColor = "#fff";
                            } else {
                                this.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                this.plans.visible = false;
                                chart.planColor = "#000";
                            }
                            this.api.render(undefined, this.dataset, this.chartOptions);
                        },
                        isRendered: false,
                        dataset: chartsData,
                        data: chartsData,
                        hover: {
                            mode: 'nearest'
                        },
                        chartOptions: {
                            useCrosshair: true,
                            animation: false,
                            tooltips: {
                                intersect: false,
                            },
                            hidePlans: false,
                            legend: {
                                display: true,
                                // labels: {
                                //     filter: function (legendItem) {
                                //         if (legendItem.datasetIndex == 0 || legendItem.datasetIndex == 1 || legendItem.datasetIndex == 2) {
                                //             return false;
                                //         }
                                //         return true;
                                //     }
                                // },
                                align: 'end',
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    time: {
                                        unit: vm.xaxeFormat
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        minRotation: 0,
                                        maxRotation: 0,
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Volume (Vehicles Per Hour)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '1',
                                    position: 'left',

                                    gridLines: {
                                        display: false
                                    },
                                },
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Directional Split',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '2',
                                    position: 'right',
                                    gridLines: {
                                        display: false
                                    },
                                }
                                ]
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                            annotation: {
                                annotations: [
                                    ...planAnnotations,
                                    {
                                        type: 'line',
                                        id: 'call-count-4',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: ((averageYellowTime / 1000) + vm.severityLevel),
                                        borderColor: 'blue',
                                        borderWidth: 2,
                                        borderDash: [10, 5],
                                        label: {
                                            backgroundColor: 'rgba(230, 230, 230, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 12,
                                            fontStyle: 'bold',
                                            fontColor: 'blue',
                                            xPadding: 2,
                                            yPadding: 6,
                                            position: "left",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-1',
                                        scaleID: '1',
                                        mode: 'horizontal',
                                        //value: (averageYellowTime / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-2',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: averageRedClearanceTime > 1000 ? ((averageRedClearanceTime + averageYellowTime) / 1000) : ((averageYellowTime + 1000) / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 102, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-3',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        value: 0,
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -12,
                                            enabled: true,
                                            content: ''
                                        }
                                    }

                                ]
                            },
                        }
                    });
                    //reset plan array for each chart
                    planList.length = 0;
                    planAnnotations = [];
                    chartsData = [];
                }

                if (advEWdata) {
                    var allPhaseChartData = [];
                    var pushChart = false;
                    //Westbound is series 1
                    //Eastbound is series 2
                    //D-Factor for each is series 3, 4

                    if (advEWdata.series1 && advEWdata.series1.length > 0 && advEWdata.primaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Eastbound",
                            data: advEWdata.series1,
                            type: "LineWithLine",
                            yAxisID: "1",
                            strokeColor: chartsService.colors.blue,
                            borderColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advEWdata.series2 && advEWdata.series2.length > 0 && advEWdata.secondaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Westbound",
                            data: advEWdata.series2,
                            type: "LineWithLine",
                            yAxisID: "1",
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advEWdata.dFactorSeries1 && advEWdata.dFactorSeries1.length > 0 && advEWdata.primaryTotal > 0 && advEWdata.secondaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Eastbound D-Factor",
                            data: advEWdata.dFactorSeries1,
                            type: "LineWithLine",
                            yAxisID: "2",
                            classed: 'dashed-line',
                            strokeColor: chartsService.colors.blue,
                            borderColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            fill: false,
                            borderDash: [10, 5],
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advEWdata.dFactorSeries2 && advEWdata.dFactorSeries2.length > 0 && advEWdata.secondaryTotal > 0 && advEWdata.primaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Westbound D-Factor",
                            data: advEWdata.dFactorSeries2,
                            type: "LineWithLine",
                            yAxisID: "2",
                            classed: 'dashed-line',
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            borderDash: [10, 5],
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }

                    var searchObj = searchBarService.getSearchOptions();

                    charts.push({
                        title: "Approach Volume for " + $scope.signal.description + " - " + "Eastbound and Westbound",
                        id: "westEastReport",
                        primaryHeader: "Eastbound",
                        primaryLine1: advEWdata.primaryTotal > 0 ? "Volume: " + advEWdata.primaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advEWdata.primaryPeakHour.peakHour : "No detection for this direction",
                        primaryLine2: advEWdata.primaryTotal > 0 ? "PHV = " + advEWdata.primaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advEWdata.primaryPeakHour.kFactor + "; D-Factor = " + advEWdata.primaryPeakHour.dFactor + "; PHF = " + advEWdata.primaryPeakHour.factor : "",
                        secondaryHeader: "Westbound",
                        secondaryLine1: advEWdata.secondaryTotal > 0 ? "Volume: " + advEWdata.secondaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advEWdata.secondaryPeakHour.peakHour : "No detection for this direction",
                        secondaryLine2: advEWdata.secondaryTotal > 0 ? "PHV = " + advEWdata.secondaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advEWdata.secondaryPeakHour.kFactor + "; D-Factor = " + advEWdata.secondaryPeakHour.dFactor + "; PHF = " + advEWdata.secondaryPeakHour.factor : "",
                        combinedHeader: "Combined",
                        combinedLine1: "Volume: " + advEWdata.combinedTotal.toLocaleString() + "; Peak Hour (PH) = " + advEWdata.combinedPeakHour.peakHour,
                        combinedLine2: "PHV = " + advEWdata.combinedPeakHour.volume.toLocaleString() + "; K-Factor = " + advEWdata.combinedPeakHour.kFactor + "; PHF = " + advEWdata.combinedPeakHour.factor,
                        data: allPhaseChartData,
                        plans: vm.approachVolumeChartWidget.addPlanLabels(advEWdata.primaryPlans, advEWdata.secondaryPlans, "Eastbound", "Westbound"),
                        api: {},
                        plansHidden: false,
                        planColor: "#000",
                        //chart: vm.approachVolumeChartWidget.getChart(planList),
                        showPrimary: advEWdata.primaryTotal > 0,
                        showSecondary: advEWdata.secondaryTotal > 0,
                        showCombined: advEWdata.primaryTotal > 0 && advEWdata.secondaryTotal > 0,
                        isDataAvailable: advEWdata.combinedTotal > 0,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },

                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                            if (!this.plans.visible) {
                                this.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                this.plans.visible = true;
                                chart.planColor = "#fff";
                            } else {
                                this.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                this.plans.visible = false;
                                chart.planColor = "#000";;
                            }
                            this.api.render(undefined, this.dataset, this.chartOptions);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },

                        isRendered: false,
                        dataset: chartsData,
                        data: chartsData,
                        hover: {
                            mode: 'nearest'
                        },
                        chartOptions: {
                            useCrosshair: true,
                            animation: false,
                            tooltips: {
                                intersect: false,
                            },
                            hidePlans: false,
                            legend: {
                                display: true,
                                // labels: {
                                //     filter: function (legendItem) {
                                //         if (legendItem.datasetIndex == 0 || legendItem.datasetIndex == 1 || legendItem.datasetIndex == 2) {
                                //             return false;
                                //         }
                                //         return true;
                                //     }
                                // },
                                align: 'end',
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    time: {
                                        unit: vm.xaxeFormat
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        minRotation: 0,
                                        maxRotation: 0,
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Volume (Vehicles Per Hour)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '1',
                                    position: 'left',
                                    gridLines: {
                                        display: false
                                    },
                                },
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Directional Split',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '2',
                                    position: 'right',
                                    gridLines: {
                                        display: false
                                    },
                                }
                                ]
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',


                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',


                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                            annotation: {
                                annotations: [
                                    ...planAnnotations,
                                    {
                                        type: 'line',
                                        id: 'call-count-4',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: ((averageYellowTime / 1000) + vm.severityLevel),
                                        borderColor: 'blue',
                                        borderWidth: 2,
                                        borderDash: [10, 5],
                                        label: {
                                            backgroundColor: 'rgba(230, 230, 230, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 12,
                                            fontStyle: 'bold',
                                            fontColor: 'blue',
                                            xPadding: 2,
                                            yPadding: 6,
                                            position: "left",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-1',
                                        scaleID: '1',
                                        mode: 'horizontal',
                                        //value: (averageYellowTime / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-2',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: averageRedClearanceTime > 1000 ? ((averageRedClearanceTime + averageYellowTime) / 1000) : ((averageYellowTime + 1000) / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 102, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-3',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        value: 0,
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -12,
                                            enabled: true,
                                            content: ''
                                        }
                                    }

                                ]
                            },
                        }
                    });
                    //reset plan array for each chart
                    planList.length = 0;
                    chartsData = [];
                    planAnnotations = [];

                }
                if (advNwSe) {
                    var allPhaseChartData = [];
                    var pushChart = false;
                    //Northbound is series 1
                    //Southbound is series 2
                    //D-Factor for each is series 3, 4


                    if (advNwSe.series1 && advNwSe.series1.length > 0 && advNwSe.primaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Southeast",
                            data: advNwSe.series1,
                            type: "LineWithLine",
                            yAxisID: "1",
                            strokeColor: chartsService.colors.blue,
                            borderColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNwSe.series2 && advNwSe.series2.length > 0 && advNwSe.secondaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Northwest",
                            data: advNwSe.series2,
                            type: "LineWithLine",
                            yAxisID: "1",
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNwSe.dFactorSeries1 && advNwSe.dFactorSeries1.length > 0 && advNwSe.primaryTotal > 0 && advNwSe.secondaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Southeast D-Factor",
                            data: advNwSe.dFactorSeries1,
                            type: "LineWithLine",
                            yAxisID: "2",
                            classed: 'dashed-line',
                            strokeColor: chartsService.colors.blue,
                            borderColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            fill: false,
                            borderDash: [10, 5],
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNwSe.dFactorSeries2 && advNwSe.dFactorSeries2.length > 0 && advNwSe.secondaryTotal > 0 && advNwSe.primaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Northwest D-Factor",
                            data: advNwSe.dFactorSeries2,
                            type: "LineWithLine",
                            yAxisID: "2",
                            classed: 'dashed-line',
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            borderDash: [10, 5],
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });

                    }

                    var searchObj = searchBarService.getSearchOptions();

                    charts.push({
                        title: "Approach Volume for " + $scope.signal.description + " - " + "Southeast and Northwest",
                        id: "southeastNorthwestReport",
                        primaryHeader: "Southeast",
                        primaryLine1: advNwSe.primaryTotal > 0 ? "Volume: " + advNwSe.primaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advNwSe.primaryPeakHour.peakHour : "No detection for this direction",
                        primaryLine2: advNwSe.primaryTotal > 0 ? "PHV = " + advNwSe.primaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advNwSe.primaryPeakHour.kFactor + "; D-Factor = " + advNwSe.primaryPeakHour.dFactor + "; PHF = " + advNwSe.primaryPeakHour.factor : "",
                        secondaryHeader: "Northwest",
                        secondaryLine1: advNwSe.secondaryTotal > 0 ? "Volume: " + advNwSe.secondaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advNwSe.secondaryPeakHour.peakHour : "No detection for this direction",
                        secondaryLine2: advNwSe.secondaryTotal > 0 ? "PHV = " + advNwSe.secondaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advNwSe.secondaryPeakHour.kFactor + "; D-Factor = " + advNwSe.secondaryPeakHour.dFactor + "; PHF = " + advNwSe.secondaryPeakHour.factor : "",
                        combinedHeader: "Combined",
                        combinedLine1: "Volume: " + advNwSe.combinedTotal.toLocaleString() + "; Peak Hour (PH) = " + advNwSe.combinedPeakHour.peakHour,
                        combinedLine2: "PHV = " + advNwSe.combinedPeakHour.volume.toLocaleString() + "; K-Factor = " + advNwSe.combinedPeakHour.kFactor + "; PHF = " + advNwSe.combinedPeakHour.factor,
                        data: allPhaseChartData,
                        plans: vm.approachVolumeChartWidget.addPlanLabels(advNwSe.primaryPlans, advNwSe.secondaryPlans, "Southeast", "Northwest"),
                        //chart: vm.approachVolumeChartWidget.getChart(planList),
                        showPrimary: advNwSe.primaryTotal > 0,
                        showSecondary: advNwSe.secondaryTotal > 0,
                        showCombined: advNwSe.primaryTotal > 0 && advNwSe.secondaryTotal > 0,
                        isDataAvailable: advNwSe.combinedTotal > 0,
                        api: {},
                        planColor: "#000",
                        plansHidden: false,
                        showPrimary: advNwSe.primaryTotal > 0,
                        showSecondary: advNwSe.secondaryTotal > 0,
                        showCombined: advNwSe.primaryTotal > 0 && advNwSe.secondaryTotal > 0,
                        isDataAvailable: advNwSe.combinedTotal > 0,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },

                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                            if (!this.plans.visible) {
                                this.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                this.plans.visible = true;
                                chart.planColor = "#fff";
                            } else {
                                this.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                this.plans.visible = false;
                                chart.planColor = "#000";
                            }
                            this.api.render(undefined, this.dataset, this.chartOptions);
                        },
                        isRendered: false,
                        dataset: chartsData,
                        data: chartsData,
                        hover: {
                            mode: 'nearest'
                        },
                        chartOptions: {
                            useCrosshair: true,
                            animation: false,
                            tooltips: {
                                intersect: false,
                            },
                            hidePlans: false,
                            legend: {
                                display: true,
                                // labels: {
                                //     filter: function (legendItem) {
                                //         if (legendItem.datasetIndex == 0 || legendItem.datasetIndex == 1 || legendItem.datasetIndex == 2) {
                                //             return false;
                                //         }
                                //         return true;
                                //     }
                                // },
                                align: 'end',
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    time: {
                                        unit: vm.xaxeFormat
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        minRotation: 0,
                                        maxRotation: 0,
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Volume (Vehicles Per Hour)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '1',
                                    position: 'left',
                                    gridLines: {
                                        display: false
                                    },
                                },
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Directional Split',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '2',
                                    position: 'right',
                                    gridLines: {
                                        display: false
                                    },
                                }
                                ]
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                            annotation: {
                                annotations: [
                                    ...planAnnotations,
                                    {
                                        type: 'line',
                                        id: 'call-count-4',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: ((averageYellowTime / 1000) + vm.severityLevel),
                                        borderColor: 'blue',
                                        borderWidth: 2,
                                        borderDash: [10, 5],
                                        label: {
                                            backgroundColor: 'rgba(230, 230, 230, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 12,
                                            fontStyle: 'bold',
                                            fontColor: 'blue',
                                            xPadding: 2,
                                            yPadding: 6,
                                            position: "left",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-1',
                                        scaleID: '1',
                                        mode: 'horizontal',
                                        //value: (averageYellowTime / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-2',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: averageRedClearanceTime > 1000 ? ((averageRedClearanceTime + averageYellowTime) / 1000) : ((averageYellowTime + 1000) / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 102, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-3',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        value: 0,
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -12,
                                            enabled: true,
                                            content: ''
                                        }
                                    }

                                ]
                            }
                        }
                    });
                    //reset plan array for each chart
                    planAnnotations = [];
                    planList.length = 0;
                    chartsData = [];

                }

                if (advNeSw) {
                    var allPhaseChartData = [];
                    var pushChart = false;
                    //Westbound is series 1
                    //Eastbound is series 2
                    //D-Factor for each is series 3, 4


                    if (advNeSw.series1 && advNeSw.series1.length > 0 && advNeSw.primaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Northeast",
                            data: advNeSw.series1,
                            type: "LineWithLine",
                            yAxisID: "1",
                            strokeColor: chartsService.colors.blue,
                            borderColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNeSw.series2 && advNeSw.series2.length > 0 && advNeSw.secondaryTotal > 0) {
                        pushChart = true;
                        chartsData.push({
                            label: "Southwest",
                            data: advNeSw.series2,
                            type: "LineWithLine",
                            yAxisID: "1",
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNeSw.dFactorSeries1 && advNeSw.dFactorSeries1.length > 0 && advNeSw.primaryTotal > 0 && advNeSw.secondaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Northeast D-Factor",
                            data: advNeSw.dFactorSeries1,
                            type: "LineWithLine",
                            yAxisID: "2",
                            classed: 'dashed-line',
                            strokeColor: chartsService.colors.blue,
                            borderColor: chartsService.colors.blue,
                            pointBackgroundColor: chartsService.colors.blue,
                            fill: false,
                            borderDash: [10, 5],
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }
                    if (advNeSw.dFactorSeries2 && advNeSw.dFactorSeries2.length > 0 && advNeSw.secondaryTotal > 0 && advNeSw.primaryTotal > 0 && !$scope.hideDfactor) {
                        pushChart = true;
                        chartsData.push({
                            label: "Southwest D-Factor",
                            data: advNeSw.dFactorSeries2,
                            type: "LineWithLine",
                            yAxisID: "2",
                            classed: 'dashed-line',
                            strokeColor: chartsService.colors.signalRed,
                            borderColor: chartsService.colors.signalRed,
                            pointBackgroundColor: chartsService.colors.signalRed,
                            fill: false,
                            borderDash: [10, 5],
                            pointRadius: 1,
                            pointHoverRadius: 4
                        });
                    }

                    var searchObj = searchBarService.getSearchOptions();

                    charts.push({
                        title: "Approach Volume for " + $scope.signal.description + " - " + "Northeast and Southwest",
                        id: "northeastSouthwestReport",
                        primaryHeader: "Southwest",
                        primaryLine1: advNeSw.primaryTotal > 0 ? "Volume: " + advNeSw.primaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advNeSw.primaryPeakHour.peakHour : "No detection for this direction",
                        primaryLine2: advNeSw.primaryTotal > 0 ? "PHV = " + advNeSw.primaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advNeSw.primaryPeakHour.kFactor + "; D-Factor = " + advNeSw.primaryPeakHour.dFactor + "; PHF = " + advNeSw.primaryPeakHour.factor : "",
                        secondaryHeader: "Northeast",
                        secondaryLine1: advNeSw.secondaryTotal > 0 ? "Volume: " + advNeSw.secondaryTotal.toLocaleString() + "; Peak Hour (PH) = " + advNeSw.secondaryPeakHour.peakHour : "No detection for this direction",
                        secondaryLine2: advNeSw.secondaryTotal > 0 ? "PHV = " + advNeSw.secondaryPeakHour.volume.toLocaleString() + "; K-Factor = " + advNeSw.secondaryPeakHour.kFactor + "; D-Factor = " + advNeSw.secondaryPeakHour.dFactor + "; PHF = " + advNeSw.secondaryPeakHour.factor : "",
                        combinedHeader: "Combined",
                        combinedLine1: "Volume: " + advNeSw.combinedTotal.toLocaleString() + "; Peak Hour (PH) = " + advNeSw.combinedPeakHour.peakHour,
                        combinedLine2: "PHV = " + advNeSw.combinedPeakHour.volume.toLocaleString() + "; K-Factor = " + advNeSw.combinedPeakHour.kFactor + "; PHF = " + advNeSw.combinedPeakHour.factor,
                        plans: vm.approachVolumeChartWidget.addPlanLabels(advNeSw.primaryPlans, advNeSw.secondaryPlans, "Northeast", "Southwest"),
                        showPrimary: advNeSw.primaryTotal > 0,
                        showSecondary: advNeSw.secondaryTotal > 0,
                        showCombined: advNeSw.primaryTotal > 0 && advNeSw.secondaryTotal > 0,
                        isDataAvailable: advNeSw.combinedTotal > 0,
                        api: {},
                        planColor: "#000",
                        plansHidden: false,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },

                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                            if (!this.plans.visible) {
                                this.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                this.plans.visible = true;
                                chart.planColor = "#fff";
                            } else {
                                this.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                this.plans.visible = false;
                                chart.planColor = "#000";
                            }
                            this.api.render(undefined, this.dataset, this.chartOptions);
                        },
                        isRendered: false,
                        dataset: chartsData,
                        data: chartsData,
                        hover: {
                            mode: 'nearest'
                        },
                        chartOptions: {
                            useCrosshair: true,
                            animation: false,
                            tooltips: {
                                intersect: false,
                            },
                            hidePlans: false,
                            legend: {
                                display: true,
                                // labels: {
                                //     filter: function (legendItem) {
                                //         if (legendItem.datasetIndex == 0 || legendItem.datasetIndex == 1 || legendItem.datasetIndex == 2) {
                                //             return false;
                                //         }
                                //         return true;
                                //     }
                                // },
                                align: 'end',
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    time: {
                                        unit: vm.xaxeFormat
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        minRotation: 0,
                                        maxRotation: 0,
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Volume (Vehicles Per Hour)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '1',
                                    position: 'left',
                                    gridLines: {
                                        display: false
                                    },
                                },
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Directional Split',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '2',
                                    position: 'right',
                                    gridLines: {
                                        display: false
                                    },
                                }
                                ]
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                            annotation: {
                                annotations: [
                                    ...planAnnotations,
                                    {
                                        type: 'line',
                                        id: 'call-count-4',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: ((averageYellowTime / 1000) + vm.severityLevel),
                                        borderColor: 'blue',
                                        borderWidth: 2,
                                        borderDash: [10, 5],
                                        label: {
                                            backgroundColor: 'rgba(230, 230, 230, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 12,
                                            fontStyle: 'bold',
                                            fontColor: 'blue',
                                            xPadding: 2,
                                            yPadding: 6,
                                            position: "left",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-1',
                                        scaleID: '1',
                                        mode: 'horizontal',
                                        //value: (averageYellowTime / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-2',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        //value: averageRedClearanceTime > 1000 ? ((averageRedClearanceTime + averageYellowTime) / 1000) : ((averageYellowTime + 1000) / 1000),
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 102, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -11,
                                            enabled: true,
                                            content: ''
                                        }
                                    },
                                    {
                                        type: 'line',
                                        id: 'call-count-3',
                                        mode: 'horizontal',
                                        scaleID: '1',
                                        value: 0,
                                        borderColor: 'transparent',
                                        label: {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                            fontFamily: 'sans-serif',
                                            fontSize: 10,
                                            fontStyle: 'bold',
                                            fontColor: 'rgb(255, 255, 102)',
                                            xPadding: 4,
                                            yPadding: 4,
                                            position: "right",
                                            yAdjust: -12,
                                            enabled: true,
                                            content: ''
                                        }
                                    }

                                ]
                            }
                        }
                    });
                    //reset plan array for each chart
                    planAnnotations = [];
                    planList.length = 0;
                    chartsData = [];
                }


                vm.chartRendering = true;
                vm.chartLoading = false;

                //set our chart array back to our binding object
                vm.approachVolumeChartWidget.chartArray = charts;
            },
        }


        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions, true)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    vm.rawData = JSON.stringify(data, null, 4);
                    vm.approachVolumeChartWidget.processVolumeData(JSON.parse(vm.rawData, JSON.dateParser));
                    $scope.loading = false;

                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        }
    }
})();