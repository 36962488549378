﻿(function () {
    "use strict";

    angular
        .module("app.spm.dashboards.insights")
        .controller('AcknowledgeController', AcknowledgeController);

    function AcknowledgeController($state, $scope, on_acknowledge, on_update, on_unacknowledge, selectedItem, insightName, $mdDialog, typeOfAcknowledges) {
        var vm = this;
        vm.title = "Acknowledge Details for " + insightName;
        vm.acknowledgedItem = {};
        vm.typeOfAcknowledges = typeOfAcknowledges;
        vm.acknowledgedItem.selectedAckReasons = [];
        vm.selectedItem = selectedItem;
        vm.heightClass = 'min-height-not-ack';
        var today = new Date();
        
        //set the min date...the calendar UI object won't let users pick a date earlier than this
        vm.minDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + 3
          );
          //set default to one-month
          vm.expiredTimestamp = new Date(
            today.getFullYear(),
            today.getMonth()+1,
            today.getDate()
          );
        
        // make a list of the object of the size of typeOfAcknowledges with id an flag true false
        vm.listOfAckTypes =  new Array();
        vm.typeOfAcknowledges.forEach(function(ack,i){
            vm.listOfAckTypes[i] = {
                insightAcknowledgedActionID : ack.insightAcknowledgedActionID,
                action: ack.action,
                isSelected : false
            }
        });

        $scope.$on("$destroy", function () {
            vm = null;
        })

        if (vm.selectedItem){
            vm.selectedItem.currentAcknowledgment.acknowledgedTimestamp = new Date(vm.selectedItem.currentAcknowledgment.acknowledgedTimestamp).toLocaleString();
            vm.selectedItem.currentAcknowledgment.acknowledgedExpirationTimestamp = new Date(vm.selectedItem.currentAcknowledgment.acknowledgedExpirationTimestamp).toLocaleString();
            vm.selectedItem.previousAcknowledgement.acknowledgedTimestamp = new Date(vm.selectedItem.previousAcknowledgement.acknowledgedTimestamp).toLocaleString();
            vm.selectedItem.previousAcknowledgement.acknowledgedExpirationTimestamp = new Date(vm.selectedItem.previousAcknowledgement.acknowledgedExpirationTimestamp).toLocaleString();
            if (vm.selectedItem.isAcknowledged){
                vm.expiredTimestamp =  new Date(vm.selectedItem.currentAcknowledgment.acknowledgedExpirationTimestamp);
                vm.heightClass = 'min-height-ack'; 
            }
            if (vm.selectedItem.newOccurrenceAfterExpiration){
                vm.heightClass = 'min-height-expired-ack';
            }
            if (vm.selectedItem.acknowledgedComment != '') {
                vm.acknowledgedItem.comment = vm.selectedItem.currentAcknowledgment.acknowledgedComment;
            }
    
            if (vm.selectedItem.currentAcknowledgment.selectedAckReasons && vm.selectedItem.currentAcknowledgment.selectedAckReasons.length > 0) {
                vm.selectedItem.currentAcknowledgment.selectedAckReasons.forEach(function (seletedType, i) {
                    vm.listOfAckTypes.forEach(function(type){
                        if(seletedType && type.insightAcknowledgedActionID == seletedType) type.isSelected = true;
                    })
                });
            }
        }

        vm.cancel = function () {
            $mdDialog.cancel();
        };


        vm.isAckPressed = function () {
            var d = new Date();
            vm.acknowledgedItem.acknowledgedTimestamp = new Date(d).toLocaleString();
            vm.selectedItem.currentAcknowledgment.acknowledgedExpirationTimestamp = new Date(vm.expiredTimestamp).toLocaleString();

            vm.acknowledgedItem.isAcknowledged = true;
            vm.acknowledgedItem.newOccurrenceAfterExpiration = false;
            vm.acknowledgedItem.selectedAckReasons = [];

            vm.listOfAckTypes.forEach(function(type,i){
                if(type.isSelected) {
                    vm.acknowledgedItem.selectedAckReasons.push(type.insightAcknowledgedActionID);
                }
            });
            on_acknowledge(vm.acknowledgedItem);
            $mdDialog.hide();
        };

        vm.updateExistingAcknowledge = function() {
            vm.selectedItem.currentAcknowledgment.acknowledgedExpirationTimestamp = new Date(vm.expiredTimestamp).toLocaleString();
            vm.acknowledgedItem.isAcknowledged = true;
            vm.acknowledgedItem.selectedAckReasons = [];

            vm.listOfAckTypes.forEach(function(type,i){
                if(type.isSelected) {
                    vm.acknowledgedItem.selectedAckReasons.push(type.insightAcknowledgedActionID);
                }
            });
            on_update(vm.acknowledgedItem);
            $mdDialog.cancel();
        }

        vm.unAcknowledge = function() {
            on_unacknowledge();
            $mdDialog.hide();
        }

    }
}());
