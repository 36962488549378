import * as TABLE from 'raw-loader!./insights-maintenance-table.html';

(function () {
    'use strict';

    angular.module('app.spm.charts')
        .directive('insightsMaintenanceTable', insightsMaintenanceTableDirective);

    /** @ngInject */
    function insightsMaintenanceTableDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            controller: 'DashboardInsightsController as vm',
            template: function (elem, attrs) {
                return TABLE;
            },
            scope: {
                onApiInit: '&',
                onApiDestroy: '&',
            },
        };
    }
})();