import auth0 from 'auth0-js';

(function () {
    'use strict';

    angular
        .module('traffop.login')
        .directive('loginv2', function () {
            return {
                restrict: 'AE',
                bindToController: {
                    api: '=',
                },
                scope: {
                    param: '=',
                },
                template: `<div id="login-v2" layout="row" layout-align="start">
                <div id="login-v2-intro" flex hide show-gt-sm>
                </div>
            
                <div id="login-v2-form-wrapper" class="flex-scrollable md-whiteframe-8dp" ms-scroll>
                    <div id="login-v2-form">
                        <div class="padding-bottom-40">
                            <img class="center-img" ng-src="/assets/images/spm/miovision-logo.svg" />
                        </div>
                        <div class="title" translate="LOGIN_V2.TITLE">Log in to your account</div>
                        <!--<div class="description" translate="LOGIN_V2.DESCRIPTION">Sed mollis velit facilisis facilisis viverra</div>-->
                        <form name="vm.loginForm" novalidate ng-disabled="vm.isInternetExplorer">
                            <md-input-container class="md-block" md-no-float>
                                <input type="email" name="email" ng-model="vm.userName" placeholder="Email"
                                       translate translate-attr-placeholder="LOGIN_V2.EMAIL"
                                       ng-pattern="/^.+@.+\..+$/" required ng-disabled="vm.isInternetExplorer">
                                <div ng-messages="vm.loginForm.email.$error" role="alert" multiple>
                                    <div ng-message="required">
                                        <span translate="LOGIN_V2.ERRORS.EMAIL_REQUIRED">Email field is required</span>
                                    </div>
                                    <div ng-message="pattern">
                                        <span translate="LOGIN_V2.ERRORS.EMAIL_MUST_VALID">Email must be a valid e-mail address</span>
                                    </div>
                                </div>
                            </md-input-container>
                            <md-input-container class="md-block" md-no-float>
                                <input type="password" name="password" ng-model="vm.password" placeholder="Password"
                                       translate translate-attr-placeholder="LOGIN_V2.PASSWORD"
                                       required ng-disabled="vm.isInternetExplorer">
                                <div ng-messages="vm.loginForm.password.$error" role="alert">
                                    <div ng-message="required">
                                        <span translate="LOGIN_V2.ERRORS.PASSWORD_REQUIRED">Password field is required</span>
                                    </div>
                                </div>
                            </md-input-container>
            
            
                            <div class="remember-forgot-password" layout="row" layout-sm="column" ng-if="!vm.isInternetExplorer"
                                 layout-align="space-between center">
                                <!--<md-checkbox class="remember-me" ng-model="data.cb1" aria-label="Remember Me">
                            <span translate="LOGIN_V2.REMEMBER_ME">Remember Me</span>
                        </md-checkbox>-->
                                <a ui-sref="forgot-password" class="forgot-password md-accent-color"
                                   translate="LOGIN_V2.FORGOT_PASSWORD">Forgot Password?</a>
                            </div>
                            <md-button type="submit" class="md-raised md-accent submit-button" aria-label="LOG IN"
                                       ng-disabled="vm.loginForm.$invalid || vm.loginForm.$pristine" ng-click="vm.login()"
                                       translate="LOGIN_V2.LOG_IN" translate-attr-aria-label="LOGIN_V2.LOG_IN">
                                LOG IN
                            </md-button>
                        </form>
            
                        <span class="error" ng-show="vm.loginError">{{vm.loginErrorText}}</span>
            
                        <!--<div class="separator">
                    <span class="text" translate="LOGIN_V2.OR">OR</span>
                </div>-->
                        <!--<div layout="column" layout-align="start center">
                    <md-button class="md-raised google">
                        <div layout="row" layout-align="center center">
                            <span>
                                <md-icon md-font-icon="icon-google-plus" class="s16"></md-icon>
                                <span translate="LOGIN_V2.WITH_GOOGLE">Log in with Google</span>
                            </span>
                        </div>
                    </md-button>
            
                    <md-button class="md-raised facebook">
                        <div layout="row" layout-align="center center">
                            <span>
                                <md-icon md-font-icon="icon-facebook" class="s16"></md-icon>
                                <span translate="LOGIN_V2.WITH_FACEBOOK">Log in with Facebook</span>
                            </span>
                        </div>
                    </md-button>
                </div>
            
                <div class="register" layout="row" layout-sm="column" layout-align="center center">
                    <span class="text" translate="LOGIN_V2.DONT_HAVE_ACCOUNT">Don't have an account?</span>
                    <a class="link" ui-sref="app.pages_auth_register-v2" translate="LOGIN_V2.CREATE_ACCOUNT">Create an
                        account</a>
                </div>-->
                    </div>
                </div>
            </div>`,
                controller: 'LoginV2Controller as vm'
            }
        })
        .controller('LoginV2Controller', LoginV2Controller);

    /** @ngInject */

    function LoginV2Controller($state, $rootScope, $scope, $document, environmentConfig, $stateParams) {
        var vm = this;
        //if we have an existing token we should try and navigate to map-overview and keep user logged in
        if (sessionStorage.getItem('id_token')){
            $state.go('app.spm.dashboards.map-overview');
            return;
        }
        vm.login = login;
        vm.userName = "";
        vm.password = "";
        vm.rememberMe = "";
        vm.loginError = false;
        vm.loginErrorText = "";
        vm.isInternetExplorer = false;
        
        // if (sessionStorage.access_token) {
        //     $state.go('app.spm.dashboards.map-overview')
        // }

        var msie = $document[0].documentMode;
        sessionStorage.clear();
        localStorage.clear();
        // if is IE (documentMode contains IE version)
        if (msie) {
            // IE detected. Do not allow login and show unsupported message
            vm.isInternetExplorer = true;
            vm.loginError = true;
            vm.loginErrorText = "Unfortunately, you are using Internet Explorer, which is obsolete. Please switch to Google Chrome, Mozilla Firefox or Microsoft Edge browser."
        }
        else{
            vm.webAuth = new auth0.WebAuth({
                domain: environmentConfig.authDomain,
                clientID: environmentConfig.authClient,
                scope: 'openid email name username',
                redirectUri: environmentConfig.rootUrl,
                responseType: 'token',
            });
        }

       

        function login() {
            vm.webAuth.login({
                username: vm.userName,
                password: vm.password,
                realm: 'Username-Password-Authentication'
            }, function (err, resp) {
                if (err) {
                    vm.loginError = true;
                    switch (err.code) {
                        case "request_error":
                            if (!$rootScope.isOnline)
                                vm.loginErrorText = "No internet connectivity detected. Please ensure that you are connected to the internet and try again.";
                            else
                                vm.loginErrorText = "Login request blocked. Please ensure all IT firewalls and internet filters allow for application traffic.";
                            break;
                        case "too_many_attempts":
                            vm.loginErrorText = "Your account has been locked for too many failed login attempts. Please contact support@traffop.com for assistance.";
                            break;
                        case "access_denied":
                        default:
                            vm.loginErrorText = err.description;
                    }
                    //log the error into sentry
                    if (environmentConfig.environmentName == "development" ||
                        environmentConfig.environmentName == "staging" ||
                        environmentConfig.environmentName == "production") {
                        Sentry.withScope(function (scope) {
                            scope.setUser({ "email": vm.userName });
                            Sentry.captureMessage(err.description);
                        });
                    }
                    vm.loginForm.$setPristine();
                    $scope.$apply();
                }
            });
        }
        // Data

        // Methods

        //////////
    }
})();