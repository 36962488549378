﻿(function () {
    'use strict';

    angular.module('app.spm.charts.signal')
    .directive('pedDelayChart', pedDelayDirective);

    /** @ngInject */
    function pedDelayDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'pedDelayController as vm',
            templateUrl: function (elem, attrs) {
                 return 'assets/templates/generic-chart-widget.html';
            },
            scope: {
                loading: '=',
                fetchData:'<',
                spmChartOptions: '<',
                signal: '<',
                searchDates: '<',
                onApiDestroy: '&',
                api: '=',
                onApiInit: '&',
            },
            link: function (scope) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        updateData: function (data) {
                            return scope.updateData(data);
                        }
                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();