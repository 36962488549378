﻿(function () {
    "use strict";

    angular
        .module("app.spm.signal-events")
        .controller("signalEventListController", signalEventListController);

    function signalEventListController($state, $attrs, $scope, $interval, $mdSidenav, $rootScope, $element, dataExportService, insightsService, controllerEventService, searchBarService) {
        var vm = this;
        vm.loading = $scope.loading;
        vm.formatDateTime = formatDateTime;
        vm.createSlackMessage = createSlackMessage;
        vm.timeOptions = {};
        vm.searchBarService = searchBarService;
        vm.getSearchOptions = getSearchOptions;
        vm.isFiltering = vm.searchBarService.isFiltering();

       
        insightsService.setInsightsSidemenu();

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm || !vm.signalEventsWidget)
                return;

            switch (changeType) {
                case "time":
                case "signal":
                    vm.isFiltering = vm.searchBarService.isFiltering();
                    if (vm.isFiltering) {
                        vm.getSearchOptions();
                        vm.signalEventsWidget.getEvents();
                    }
                    break;
            }
        });

        function getSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.signal = options.signal;
            vm.timeOptions = options.timeOptions;
        };

        //check the directive scope parameters
        //if use focus data is set, then use focusfilter
        if ($scope.useFocusData) {
            vm.getSearchOptions();
        } else {
            vm.signal = $scope.signal;
        }

        vm.bookMark = 0;
        //create the signaleventswidget
        vm.signalEventsWidget = {
            title: "Events",
            data: {},
            eventDescriptions: [],
            selected: [],
            eventsPromise: {},
            totalCount: 0,
            totalCountStr: "",
            filter: {
                debounce: 500
            },
            queryData: {
                orderBy: '-Timestamp',
                pageSize: 10,
                pageIndex: 1,
                filter: '',
                id: ''
            },
            //searches event codes for the given desc
            getEventDescByCode: function (eventCode) {
                var res = "";
                vm.signalEventsWidget.eventDescriptions.find(function (x) {
                    if (x.eventCode === eventCode) {
                        res = x.eventDescription;
                        return;
                    }
                });
                return res;
            },
            //queries the server for events
            getEvents: function () {
                var queryCopy = angular.copy(vm.signalEventsWidget.queryData);
                queryCopy.filter = queryCopy.filter;
                queryCopy.id = vm.signal.signalID;
                queryCopy.start = new Date(vm.timeOptions.currentFilter.startDateAndTime).toLocaleString();
                queryCopy.end = new Date(vm.timeOptions.currentFilter.endDateAndTime).toLocaleString();
                dataExportService.setCurrentQueryObject(queryCopy);
                this.eventsPromise = controllerEventService.getEventsForQuery(queryCopy)
                    .then(function (inData) {
                        vm.signalEventsWidget.data = inData.events;
                        vm.signalEventsWidget.eventDescriptions = inData.eventDescriptions;
                        vm.signalEventsWidget.totalCount = inData.totalCount;
                        vm.signalEventsWidget.totalCountStr = parseInt(inData.totalCount).toLocaleString();
                    });
            },
            //downloads events in a file from server
            downloadEventsFile: function () {
                var queryCopy = angular.copy(vm.signalEventsWidget.queryData);
                queryCopy.filter = queryCopy.filter;
                queryCopy.id = vm.signal.signalID;
                queryCopy.start = new Date(vm.timeOptions.currentFilter.startDateAndTime).toLocaleString();
                queryCopy.end = new Date(vm.timeOptions.currentFilter.endDateAndTime).toLocaleString();
                queryCopy.fileType = "csv";
                this.eventsPromise = dataExportService.getRawCsvFile(queryCopy);
            },
            //any time the search has changed this triggers
            searchChange: function (newValue, oldValue) {
                if (!oldValue) {
                    vm.bookMark = this.queryData.pageIndex;
                }

                if (newValue !== oldValue) {
                    vm.signalEventsWidget.queryData.pageIndex = 1;
                }

                if (!newValue) {
                    vm.signalEventsWidget.queryData.pageIndex = vm.bookMark;
                }
                vm.signalEventsWidget.getEvents();
            }
        };

        vm.signalEventsWidget.getEvents();

        function createSlackMessage(event) {
            var res = "";
            if (event.signal && event.eventDesc) {
                res = "New event for " + event.signal.signalDescription + ".\n";
                res += event.eventDesc.eventDescription + " Param: " + event.eventParam;
                res += "\n Time: " + event.timestamp
            }
            return res;
        }

        function formatDateTime(input) {
            var options = {
                weekday: "long", year: "numeric", month: "short",
                day: "numeric", hour: "2-digit", minute: "2-digit", second: "numeric",
            };
            var inputDate = new Date(input);
            var inputMS = inputDate.getMilliseconds();
            if (inputMS == 0) {
                inputMS = "000";
            }
            var time = inputDate.toLocaleTimeString("en-us", options);
            var amPM = time.substr(time.length - 2, time.length);
            time = time.substr(0, time.length - 3);
            return time = time + "." + inputMS + " " + amPM;
        }

        //cleanup
        $scope.$on("$destroy", function () {
            vm.signalEventsWidget = null;
            vm = null;
            $scope.searchDates = null;
            $scope.signal = null;
        });
    }
}());
