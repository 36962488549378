﻿(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("detectorDurationsController", detectorDurationsController);

    function detectorDurationsController($state, $rootScope, $scope, $stateParams, $interval, $mdSidenav, environmentConfig, searchBarService, signalService, dashboardInsightsService, insightsService, dataExportService, $mdDialog, $translate, $element, $window) {
        var vm = this;
        $scope.$on("$destroy", function () {
            restartTabIndex();
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;

        });

        function restartTabIndex() {
            dashboardInsightsService.model.approachID = null;
            dashboardInsightsService.model.toDetectors = false;
            $rootScope.$broadcast('savestate');
            dashboardInsightsService.SaveState();
        }

        //set events and params
        vm.queryData = {
            orderBy: 'protectedPhaseNumber',
            pageSize: 20,
            pageIndex: 1,
            filter: '',
        };
        vm.searchBarService = searchBarService;
        vm.selectedIndex = 0;
        vm.barChartApis = {};
        vm.donutChartApis = {};
        vm.approaches = null;
        vm.signals = {};
        vm.configuration = {};
        vm.configuration.dates = {};
        vm.getApproaches = getApproaches;
        vm.getFocusFilterData = getFocusFilterData;
        vm.signalIds = [];
        vm.eventsByApproach = [];
        vm.getFocusFilterData();
        vm.getApproaches(false);
        vm.onApiInit = onApiInit;
        vm.timeOptions = {};
        vm.chartServerError = false;
        vm.approachID = null;
        vm.fromInsights = false;
        vm.init = init;

        vm.signals = {
            signalID: "",
            signalName: "",
            selected: {}
        };

        vm.init();

        if (dashboardInsightsService.model.approachID || dashboardInsightsService.model.toDetectors) {
            vm.approachID = dashboardInsightsService.model.approachID;
            vm.fromInsights = dashboardInsightsService.model.toDetectors;
            $rootScope.$broadcast('restorestate'); //let everything know we need to restore state
            dashboardInsightsService.RestoreState();
            sessionStorage.restorestate = false;
        }
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: true,
                text: "Detector Durations",
            },
            //search bar options
            showSearchBar: true,
            searchType: 'Signals',
            showCurrentFilterDates: true,
            helpJsonPropertyPath: "METRIC." + 91,
            metricTypeId: 91,
            showExcel: true,
            showSignalNotificationTabs: true,
            showHelp: true,
            timeFrameConfig: {
                enableWithoutFiltering: false,
                defaultDateTemplate: "TW",
                defaultTodTemplate: "FD",
                dateTemplateMinimumDays: 0,
                timeOptionForCustomTemplate: "StartToEnd",
                showWeekdayFilter: false,
                enableAdvancedTod: false,
                maxDayRange: 7
            },
            //right-side more options menu
            moreOptionsMenu: {
                show: false,
                showBinConfig: false,
                skipStepsPerBin: true,
                help: true,
                getMetricData: true,
            }
        });
        vm.isFiltering = searchBarService.isFiltering();

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
            if (!vm || !vm.barChartApis || !vm.donutChartApis)
                return;

            switch (changeType) {
                case "time":
                    if (vm.isFiltering) {
                        vm.getFocusFilterData();
                        for (var key in vm.barChartApis) {
                            var api = vm.barChartApis[key];
                            if (api && api.update)
                                api.update(null, null, vm.timeOptions.currentFilter);
                        }

                        for (var key in vm.donutChartApis) {
                            var api = vm.donutChartApis[key];
                            if (api && api.update)
                                api.update(null, null, vm.timeOptions.currentFilter);
                        }
                    }
                    break;
                case "signal":
                    if (vm.isFiltering) {
                        vm.getFocusFilterData();
                        vm.getApproaches();
                    }

                    break;
                case "state":
                    vm.isFiltering = searchBarService.isFiltering();
                    break;
            }
        });

        function init() {
            if ($stateParams && $stateParams.inputParameters) {
                vm.focusApproachId = $stateParams.inputParameters.focusApproachId;
            }

            vm.getFocusFilterData();

            
            insightsService.setInsightsSidemenu();
        }

        function onApiInit(apiObj) {
            if (apiObj && apiObj.update) {
                apiObj.update(vm.signalIds, null, vm.timeOptions.currentFilter);
            }
        }

        function getFocusFilterData() {
            //on initial load, we need to populate our data with the focus data from service
            var focusObj = searchBarService.getSearchOptions();
            if (focusObj && focusObj.signal) {
                if (focusObj.signal && focusObj.signal.signalID) {
                    vm.signalIds = [];
                    vm.signalIds.push(focusObj.signal.signalID);
                    vm.signals.signalID = focusObj.signal.signalID;
                    vm.signals.signalName = focusObj.signal.description;
                    vm.signals.selected = focusObj.signal;
                    vm.signals.selected.description = focusObj.signal.description;
                }
                vm.configuration.dates.startDate = new Date(focusObj.timeOptions.currentFilter.startDateAndTime);
                vm.configuration.dates.startTime = new Date(focusObj.timeOptions.currentFilter.startDateAndTime);
                vm.configuration.dates.endDate = new Date(focusObj.timeOptions.currentFilter.endDateAndTime);
                vm.configuration.dates.endTime = new Date(focusObj.timeOptions.currentFilter.endDateAndTime);
                vm.timeOptions = focusObj.timeOptions;
            }
        }

        function getApproaches() {
            vm.queryData.ID = vm.signals.signalID;
            vm.barChartApis = {};
            vm.donutChartApis = {};
            vm.approachPromise = signalService.getApproaches(vm.queryData)
                .then(function (data) {
                    vm.chartServerError = false;
                    vm.approaches = data.approaches;
                    //add an array of all 72 possible detector channels
                    var allDetChannels = [];
                    for (var k = 0; k < 72; k++) {
                        allDetChannels.push(k);
                    }

                    //find the direction type desc from lookups
                    for (var i = 0; i < vm.approaches.length; i++) {

                        if (vm.focusApproachId) {
                            if (vm.approaches[i].approachID == vm.focusApproachId) {
                                vm.selectedIndex = i;
                            }
                        }
                        //show unassigned
                        else if (vm.focusApproachId == 0) {
                            vm.selectedIndex = vm.approaches.length;
                        }

                        // if(vm.fromInsights){
                        //     if(vm.approachID){
                        //         if(vm.approaches[i].approachID == vm.approachID ){ 
                        //             vm.selectedIndex = i;
                        //         }
                        //     }
                        //     else{  
                        //         vm.selectedIndex = vm.approaches.length;
                        //     }
                        // }       

                        vm.approaches[i].detectors.sort(function (a, b) {
                            return a.detChannel - b.detChannel;
                        });

                        if (!vm.approaches[i].legendItems) {
                            vm.approaches[i].legendItems = [];
                        }
                        // init an array even if we don't have detectors
                        if (!vm.approaches[i].eventsByApproach) {
                            vm.approaches[i].eventsByApproach = [];
                        }

                        //go through dets
                        for (var j = 0; j < vm.approaches[i].detectors.length; j++) {
                            if (!vm.approaches[i].eventsByApproach) {
                                vm.approaches[i].eventsByApproach = [];
                            }
                            vm.approaches[i].eventsByApproach.push({
                                eventCode: 82,
                                eventParam: vm.approaches[i].detectors[j].detChannel
                            });
                            vm.approaches[i].legendItems.push(vm.approaches[i].detectors[j].detChannel);

                            //remove det channel from array
                            var removeIndex = allDetChannels.indexOf(vm.approaches[i].detectors[j].detChannel);
                            if (removeIndex > -1) {
                                allDetChannels.splice(removeIndex, 1);
                            }
                        }
                    }

                    //add in the unallocated det channels
                    var unassignedAppr = {
                        description: "Unassigned",
                        eventsByApproach: [],
                        legendItems: [],
                        approachID: "unassigned"
                    };
                    for (var m = 0; m < allDetChannels.length; m++) {
                        unassignedAppr.eventsByApproach.push({
                            eventCode: 82,
                            eventParam: allDetChannels[m]
                        });
                        unassignedAppr.legendItems.push(allDetChannels[m]);
                    }
                    vm.approaches.push(unassignedAppr);
                })

                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });
        };
    }
}());