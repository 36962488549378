(function () {
    'use strict';

    angular
        .module('app.toolbar')
        .controller('ToolbarController', ToolbarController);

    /** @ngInject */
    function ToolbarController($rootScope, authService, $q, $state, $timeout, $mdMenu, $mdSidenav, $translate, msNavigationService, $window, breadcrumbNavigationService, newWindowService) {
        var vm = this;

        // Data
        $rootScope.global = {
            search: ''
        };

        vm.authService = authService;
        vm.username = sessionStorage.getItem('userName');
        vm.userEmail = sessionStorage.getItem('userEmail');

        vm.bodyEl = angular.element('body');
        vm.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];
        vm.languages = {
            en: {
                'title': 'English',
                'translation': 'TOOLBAR.ENGLISH',
                'code': 'en',
                'flag': 'us'
            },
            es: {
                'title': 'Spanish',
                'translation': 'TOOLBAR.SPANISH',
                'code': 'es',
                'flag': 'es'
            },
            tr: {
                'title': 'Turkish',
                'translation': 'TOOLBAR.TURKISH',
                'code': 'tr',
                'flag': 'tr'
            }
        };

        vm.breadCrumbs = [];
        vm.breadcrumbNavigationService = breadcrumbNavigationService;
        // Methods
        vm.toggleSidenav = toggleSidenav;
        vm.logout = logout;
        vm.changeLanguage = changeLanguage;
        vm.setUserStatus = setUserStatus;
        vm.toggleHorizontalMobileMenu = toggleHorizontalMobileMenu;
        vm.toggleMsNavigationFolded = toggleMsNavigationFolded;
        vm.search = search;
        vm.searchResultClick = searchResultClick;
        vm.launchHelpDeskPortal = launchHelpDeskPortal;
        vm.openHelpDeskTicketForm = openHelpDeskTicketForm;
        vm.userSettingsDashboard = userSettingsDashboard;
        vm.getBreadCrumbs = getBreadCrumbs;
        vm.navigate = navigate;
        vm.navigateBackOne = navigateBackOne;
        //////////

        init();

        function launchHelpDeskPortal() {
            $window.open('https://traffop.freshdesk.com', '_blank');
        }

        function openHelpDeskTicketForm() {

            FreshworksWidget('open');
            FreshworksWidget('identify', 'ticketForm', {
                name: vm.username,
                email: vm.userEmail,
            });
            FreshworksWidget('disable', 'ticketForm', ['email'])

        }

        /**
         * Initialize
         */
        function init() {
            // Select the first status as a default
            vm.userStatus = vm.userStatusOptions[0];

            // Get the selected language directly from angular-translate module setting
            vm.selectedLanguage = vm.languages[$translate.preferredLanguage()];
            vm.getBreadCrumbs();
        }


        function getBreadCrumbs() {
            vm.breadCrumbs = vm.breadcrumbNavigationService.getBreadcrumbTrail();
        }

        function navigate(bcIndex) {
            vm.breadcrumbNavigationService.navigateToBreadCrumbIndex(bcIndex);
        }

        function navigateBackOne() {
            vm.breadcrumbNavigationService.navigateBackOneBreadcrumb();
        }

        /**
         * Toggle sidenav
         *
         * @param sidenavId
         */
        function toggleSidenav(sidenavId) {
            $mdSidenav(sidenavId).toggle();
        }
        /**
         * Sets User Status
         * @param status
         */
        function setUserStatus(status) {
            vm.userStatus = status;
        }

        /**
         * Logout Function
         */
        function logout() {
            // Do logout here..
        }

        /**
         * Change Language
         */
        function changeLanguage(lang) {
            vm.selectedLanguage = lang;

            /**
             * Show temporary message if user selects a language other than English
             *
             * angular-translate module will try to load language specific json files
             * as soon as you change the language. And because we don't have them, there
             * will be a lot of errors in the page potentially breaking couple functions
             * of the template.
             *
             * To prevent that from happening, we added a simple "return;" statement at the
             * end of this if block. If you have all the translation files, remove this if
             * block and the translations should work without any problems.
             */
            if (lang.code !== 'en') {

                return;
            }

            // Change the language
            $translate.use(lang.code);
        }

        /**
         * Toggle horizontal mobile menu
         */
        function toggleHorizontalMobileMenu() {
            vm.bodyEl.toggleClass('ms-navigation-horizontal-mobile-menu-active');
        }

        /**
         * Toggle msNavigation folded
         */
        function toggleMsNavigationFolded() {
            msNavigationService.toggleFolded();
        }

        /**
         * Search action
         *
         * @param query
         * @returns {Promise}
         */
        function search(query) {
            var navigation = [],
                flatNavigation = msNavigationService.getFlatNavigation(),
                deferred = $q.defer();

            // Iterate through the navigation array and
            // make sure it doesn't have any groups or
            // none ui-sref items
            for (var x = 0; x < flatNavigation.length; x++) {
                if (flatNavigation[x].uisref) {
                    navigation.push(flatNavigation[x]);
                }
            }

            // If there is a query, filter the navigation;
            // otherwise we will return the entire navigation
            // list. Not exactly a good thing to do but it's
            // for demo purposes.
            if (query) {
                navigation = navigation.filter(function (item) {
                    if (item.title.toLowerCase().search(query.toLowerCase()) > -1) {
                        return true;
                    }
                });
            }

            // Fake service delay
            $timeout(function () {
                deferred.resolve(navigation);
            }, 1000);

            return deferred.promise;
        }

        /**
         * Search result click action
         *
         * @param item
         */
        function searchResultClick(item) {
            // If item has a link
            if (item.uisref) {
                breadcrumbNavigationService.navigateToState(item.state);
            }
        }

        function userSettingsDashboard() {
            breadcrumbNavigationService.navigateToState('app.spm.dashboards.user-settings');
        }
    }

})();