﻿(function () {
    "use strict";

    angular
        .module("app.spm.signals")
        .controller("detectorController", detectorController);

    function detectorController($state, $stateParams, $scope, signalService, searchBarService , $mdDialog, breadcrumbNavigationService) {
        var vm = this;
        vm.dtInstance = {};
        vm.signal = undefined; 
        vm.approach = undefined; 
        vm.detector = undefined;
        vm.lookups = undefined;
        if ($stateParams.inputParameters){
            vm.signal = $stateParams.inputParameters.signal;
            vm.approach = $stateParams.inputParameters.approach;
            vm.detector = $stateParams.inputParameters.detector;
            vm.lookups = $stateParams.inputParameters.lookups;   
        }
        else{
            //go to signal list
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals');
            return;
        }
        vm.isFormValid = isFormValid;
        vm.isFormDirty = isFormDirty;
        vm.enableSaveButton = enableSaveButton;
        vm.deleteDetector = deleteDetector;
        vm.saveDetector = saveDetector;
        vm.setPristine = setPristine;
        vm.checkDetectionType = checkDetectionType;
        vm.isRequired = false;
        vm.isDistanceDisabled = false;
        vm.isDisabled = false;
        vm.messageMinSpeed = "" ;
        vm.messageDecisionPoint = "";
        vm.notAllowed = "default";
        vm.checkLaneDetType = checkLaneDetType;
        vm.laneMessage = "";
        vm.laneDet = false;
        vm.showWarning = showWarning;
        vm.showDetChError = "";
        vm.detectorUpdatedSucceeded = detectorUpdatedSucceeded;
        vm.searchBarService = searchBarService;
        vm.checkDetectionType(vm.detector.detectionTypeID);
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
            },
            //search bar options
            showSearchBar: false,
        });

        if (!vm.detector) {
            vm.addNewdetector = true;
            vm.headerMessage = "Add New detector";
        }
        else {
            vm.addNewdetector = false
            vm.headerMessage = "Edit detector " + vm.detector.detectorID + " - "  + vm.detector.description;
        }

        vm.goToApproach = goToApproach;

       
        function goToApproach() {
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.approaches.edit', { approach: vm.approach, signal: vm.signal, lookups: vm.lookups });
        };

        function isFormValid() {
            if (vm.detectorForm) {
                return vm.detectorForm.$valid;
            }
        }

        function isFormDirty() {
            if (vm.detectorForm) {
                return vm.detectorForm.$dirty;
            }
        }

        function setPristine() {
            vm.detectorForm.$setPristine();
        }

        function enableSaveButton() {
            if (isFormValid() && isFormDirty()) {
                return true;
            }
            return false;
        }

        function showWarning() {
            vm.laneMessage =  "Detector Channel " + vm.checkDetector.detChannel + " has the same detection type (" + vm.checkDetector.description +")"
            + " and is already assigned to this lane number " + vm.checkDetector.laneNumber
            + "\n" + ". Do you still want to save this detector? ";

            vm.laneDet = false;

            var confirm = $mdDialog.confirm()
            .multiple(true)
            .title("Confirm")
            .textContent(vm.laneMessage)
            .ok('Yes')
            .cancel('No');

            $mdDialog.show(confirm)
                .then(function () {
                    saveDetector();
                }, function () { }
            );
        }

        function checkLaneDetType(laneNumber, detectionTypeID,id){
            vm.checkDetector = null;
            vm.approach.detectors.forEach(function(det){
                if(det.id!=id && det.laneNumber == laneNumber && det.detectionTypeID == detectionTypeID ){
                    vm.laneDet = true;
                    vm.checkDetector = det;
                }
            });
            if(!vm.laneDet) vm.saveDetector();
            if(vm.laneDet)  vm.showWarning();
        }


        function saveDetector() {
            vm.detector.approachID = vm.approach.approachID;
            signalService.updateDetector(vm.detector, vm.detectorUpdatedSucceeded);          
        }

        function detectorUpdatedSucceeded(){
            var foundIndex = vm.approach.detectors.findIndex(x => x.detChannel == vm.detector.detChannel);
            if (foundIndex !== -1)
                vm.approach.detectors[foundIndex] = vm.detector;
            vm.setPristine();
        }

        function deleteDetector(obj) {
            signalService.deleteDetector(vm.detector, goToApproach);
        }

        function checkDetectionType(detectionType) {
            switch (detectionType) {
                case 2: //Advanced Count
                    vm.isRequired = true;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = false;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set Min Speed Filter Field";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set Decision Point";
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    break;
                case 6: // Set Bar Presence
                    vm.isRequired = false;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = true;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set  Min Speed Filter";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set  Decision Point";
                    vm.messageDistanceStopBar = "By selecting this Detection Type you are not able to set  Distance from Stop Bar"
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    vm.detector.distanceFromStopBar = "";
                    break;
                case 4: // Lane-by-lane Count
                    vm.isRequired = false;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = true;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set  Min Speed Filter";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set  Decision Point";
                    vm.messageDistanceStopBar = "By selecting this Detection Type you are not able to set  Distance from Stop Bar"
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    vm.detector.distanceFromStopBar ="";
                    break;
                default:
                    vm.isRequired = false;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = true;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set  Min Speed Filter";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set  Decision Point";
                    vm.messageDistanceStopBar = "By selecting this Detection Type you are not able to set  Distance from Stop Bar"
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    vm.detector.distanceFromStopBar ="";
                //code block
            }
        }
    }
}());
