﻿import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.dashboards.insights")
        .controller('InsightDetailsController', InsightDetailsController);

    function InsightDetailsController($state, $scope, $stateParams, insight, insightName, $mdDialog, cardParent, userService) {
        var vm = this;
        $scope.$on("$destroy", function () {
            vm = null;
        })
        vm.cardParent = cardParent;
        vm.insight = insight;
        vm.insightName = insightName;

        vm.restrictedControlls = ($stateParams.insightCategory == "4" && !userService.getUserRoles().some(x => x == 'ADMIN'));

        vm.insight.occurrences.forEach(occurrence => {
            occurrence.startString = new Date(occurrence.start).toLocaleString("en-US");
            occurrence.endString = new Date(occurrence.end).toLocaleString("en-US");
        });

        if (vm.insight.newOccurrenceAfterExpiration) {
            vm.insight.previousAcknowledgement.acknowledgedTimestamp = new Date(vm.insight.previousAcknowledgement.acknowledgedTimestamp).toLocaleString();
            vm.insight.previousAcknowledgement.acknowledgedExpirationTimestamp = new Date(vm.insight.previousAcknowledgement.acknowledgedExpirationTimestamp).toLocaleString();
        }


        vm.pageData = {
            pageIndex: 1,
            size: 5,
        };

        vm.cancel = function () {
            $mdDialog.cancel();
        };

        vm.investigate = function (occurrence, newWindow) {
            occurrence.start = moment(occurrence.start).startOf('day');
            occurrence.end = moment(occurrence.end).endOf('day');
            var investigatingFromInsights = {
                navigatingFromInsights: true,
                categoryToInvestigate: $stateParams.insightCategory
            }

            sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));
            vm.cardParent.investigateOccurrence(vm.insight, occurrence, newWindow);
            $mdDialog.hide();
        };
        vm.tableData = {
            pageData: vm.pageData,
            investigate: vm.investigate,
            insight: vm.insight
        };
    }
}());
