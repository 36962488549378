(function () {
    "use strict";

    angular.module("app.spm.corridors")
        .factory("corridorResource", ["$resource", "environmentConfig", "$cacheFactory", function ($resource, environmentConfig, $cacheFactory) {
            var corridorUrl = environmentConfig.apiUrl + "/api/corridor/";
            var corridorCache = $cacheFactory('corridorResource');
            return $resource(corridorUrl, {}, {
                getPerformance: { method: "GET", url: corridorUrl + "performance?"},
                query: { method: "GET", url: corridorUrl + "query?", isArray: false, cache: false },
                getAll: { method: "GET", url: corridorUrl + "getAll", cache: true },
                getById: { method: "GET", url: corridorUrl + ":id", cache: false },
                createUpsertCorridor: {method: "POST" , url: corridorUrl + "upsert", cache: false },
                delete: { method: "DELETE", url: corridorUrl + "delete?"},
                setCorridorDetails: {method: "POST" , url: corridorUrl + "setCorridorDetails" , isArray: true, cache: false},
                getWazeData: { method: "GET", url: corridorUrl + "getWazeTravelTimes?", isArray: false },
                isWazeEnabled: { method: "GET", url: corridorUrl + "isWazeEnabled", isArray: false },
                getWazeRouteGeometries: { method: "GET", url: corridorUrl + "getWazeRouteGeometries?", isArray: false },
                getWazeRoutes: { method: "GET", url: corridorUrl + "getWazeRoutes?", isArray: true },
                getWazeRouteTravelTimeInfo: { method: "GET", url: corridorUrl + "getWazeRouteTravelTimeInfo?", cache: false, isArray: false },
            });
        }]);
}());
