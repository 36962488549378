import * as HISTORICALTRAVELTIME from 'raw-loader!./dashboard-historical-travel-time.html';

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.historical-travel-time',
            [
                // 3rd Party Dependencies
                // 'nvd3',
                // 'datatables',
                'app.spm.signals',
            ]
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.historical-travel-time', {
                url: '/historical-travel-times',
                views: {
                    'content@app': {
                        template: HISTORICALTRAVELTIME,
                        controller: 'DashboardHistoricalTravelTimeController as vm'
                    }
                }
            });
         $translatePartialLoaderProvider.addPart('travel-time-historical');
    }
})();