﻿(function ()
{
    'use strict';

    angular.module('app.core')
    .directive('landscapeOnly', landscapeOnlyDirective);

    /** @ngInject */
    /*Directive will only show inner contents if landscape mode is detected. If not in landscape mode, it will
    replace content with a cover that says "Content can only be viewed in landscape mode". To use wrap, content in the following: 
     <div landscape-only>
        <div class="landscape-only-content">
            <div> whatever content here </div>
        </div>
    </div>
    */
    function landscapeOnlyDirective($window, $compile) {
        function link(scope, element) {
            //load our cover html template
            scope.templateUrl = 'app/core/directives/landscape-only/landscape-only.html';
            scope.isLandscape = false;

            //create cover html with template and pass through angular element compiler
            var coverTemp = angular.element('<div ng-include="templateUrl" ng-hide="isLandscape"></div>');
            element.prepend(coverTemp);
            $compile(coverTemp)(scope);

            //find our outer div content wrapper
            var landscapeContent = element.find('.landscape-only-content');

            //setup a window resize event that will constantly be checking orientation
            angular.element($window).bind('resize', function () {
                scope.checkOrientation();
            });

            scope.checkOrientation = function () {
                //show and hide desired content and cover based on window width and height.
                //if the height is less than width, we can assume we are in landscape mode.
                //It's safer to just check direct window dimensions than to try and detect mobile 
                //so even if on desktop the user resizes window we are protecting our content
                if (window.innerHeight < window.innerWidth) {
                    scope.isLandscape = true;

                    landscapeContent.addClass("ng-show");
                    landscapeContent.removeClass("ng-hide");
                }
                else {
                    scope.isLandscape = false;

                    landscapeContent.addClass("ng-hide");
                    landscapeContent.removeClass("ng-show");
                }
            }
            scope.checkOrientation();
        }

        return {
            restrict: 'AE',
            link: link,
        };
    }
})();