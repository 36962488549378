import moment from 'moment';

(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.monthly-intersection-report')
        .controller('MonthlyIntersectionReportController', MonthlyIntersectionReportController);

    /** @ngInject */
    function MonthlyIntersectionReportController($scope, $state, $location, $anchorScroll, $window, $stateParams, $mdDateLocale, searchBarService, signalService, $interval, signalPerformanceService, userSettingsService, signalTrendsService, insightsService, TrendsConstants, monthlyIntersectionReportService, chartsService, $translate, systemInsightsService) {
        var vm = this;
        vm.searchBarService = searchBarService;
        vm.timeOptions = {};
        vm.signal = {};
        vm.setSearchOptions = setSearchOptions;
        vm.updateCards = updateCards;
        vm.setupSearchBar = setupSearchBar;
        vm.onCardApiInit = onCardApiInit;
        vm.getData = getData;
        vm.chartServerError = false;
        vm.areAllCardsInitialized = areAllCardsInitialized;
        vm.trendsConstants = TrendsConstants;
        vm.insightsService = insightsService;
        vm.trackDailyInsightsIndexes = [];
        vm.trackHourlyInsightsIndexes = [];
        let insights = insightsService.getInsights("Other");
        vm.insightsLoaded = (insights != undefined && insights.insightGroups != undefined && insights.insightGroups.length > 0 && insights.insightGroups[0].insightTypes.length > 0) ? true : false;
        vm.executiveInfo = {};
        vm.chartUserOptions = {};
        vm.chartsService = chartsService;
        vm.metricTypeId = $stateParams.metricTypeId;
        vm.monthlyIntersectionReportService = monthlyIntersectionReportService;
        vm.pdfExport = pdfExport;
        vm.reportDataBuffer = [];
        vm.reportLoading = true;
        vm.pdfState = "";
        vm.queryData = {
            orderBy: '',
            pageSize: 10,
            pageIndex: 1,
            filter: '',
            start: '',
            end: '',
            signalID: '',
        };

        vm.showFullOccurences = false;

        /////// testing donut chart and calendar
        function setupDateOpts(dates) {
            vm.flatModel = undefined;
            vm.dateOpts = {
                dateFormat: 'Y-m-d',
                placeholder: 'Change date..', // Default: 'Select Date..'
                defaultDate: dates,
                mode: "multiple",
                locale: {
                    firstDayOfWeek: 1
                },
                onReady: function () {
                    // document.querySelector('.flatpickr-calendar').innerHTML += '<span class="tooltiptext" id="date-tooltiptext">Tooltip text</span>';

                },
                onMonthChange: function (dObj, dStr, fp, dayElem) {
                    setupMonthNotification(fp);
                },
                inline: true,
                onDayCreate: function (dObj, dStr, fp, dayElem) {
                    // if (dayElem.classList.contains('selected')) dayElem.innerHTML += '<span md-z-index="999999" class="tooltiptext" id="date-tooltiptext">Tooltip text</span>';

                    // dayElem.addEventListener('mouseover', function (e) {
                    //     if (dayElem.classList.contains('selected')) {
                    //         var tooltip = document.getElementById('occurrences-tooltip');
                    //         var rect = e.target.getBoundingClientRect();
                    //         var y = e.target.offsetTop - 80;
                    //         var moveLeft = e.target.offsetLeft;
                    //         if (moveLeft > 170) {
                    //             moveLeft = 170;
                    //         } else if (moveLeft < 34) {
                    //             moveLeft = 34;
                    //         }
                    //         tooltip.style.top = y + "px";
                    //         tooltip.style.left = moveLeft + "px";
                    //         tooltip.style.visibility = 'visible';
                    //         tooltip.style.opacity = 1;
                    //         vm.dateToShow = moment(dayElem.dateObj).format('MMM DD, YYYY');
                    //         vm.occurrencesToShow = findOccurrencesForDate(dayElem.dateObj);
                    //         $scope.$apply();
                    //     }
                    // });

                    // dayElem.addEventListener('mouseout', function (e) {
                    //     if (dayElem.classList.contains('selected')) {
                    //         var tooltip = document.getElementById('occurrences-tooltip');
                    //         tooltip.style.visibility = 'hidden';
                    //         tooltip.style.opacity = 0;
                    //     }
                    // });

                    dayElem.addEventListener('mousedown', function (e) {
                        e.stopPropagation();
                    });

                    if (dayElem.classList.contains("selected")) {
                        dayElem.style.backgroundColor = findColorForOccurance(dayElem.dateObj);
                        dayElem.style.border = 'none';
                    }

                }
            };
            vm.datePostSetup = function (fpItem) {
                setupMonthNotification(fpItem.fpItem);
            }
        }


        function setupMonthNotification(fp) {
            document.querySelector('.flatpickr-next-month').style.display = 'none';
            document.querySelector('.flatpickr-prev-month').style.display = 'none';
        }


        vm.setupSystemInsights = setupSystemInsights;
        vm.data = {
            "degraded": {
                "color": "#e6511f",
                "count": 3,
                "title": "Degraded",
                "occurrences": ["2021-08-29", "2021-08-28", "2021-08-27"]
            },
            "offline": {
                "color": "#6A7B8B",
                "count": 3,
                "title": "Offline",
                "occurrences": ["2021-08-01", "2021-08-02", "2021-08-03"]
            },
            "optimal": {
                "color": "#00a300",
                "count": 17,
                "title": "Optimal",
                "occurrences": ["2021-08-04", "2021-08-05", "2021-08-06", "2021-08-07", "2021-08-08", "2021-08-09", "2021-08-10", "2021-08-11", "2021-08-12", "2021-08-13", "2021-08-14", "2021-08-15", "2021-08-16", "2021-08-17", "2021-08-18", "2021-08-19", "2021-08-20"]
            },
            "slightlyDegraded": {
                "color": "#B87D1A",
                "count": 4,
                "title": "Slightly Degraded",
                "occurrences": ["2021-08-21", "2021-08-22", "2021-08-23", "2021-08-24"]
            },
            "subOptimal": {
                "color": "#F5A623",
                "count": 2,
                "title": "Sub Optimal",
                "occurrences": ["2021-08-25", "2021-08-26"]
            },
            "underperforming": {
                "color": "#B19ECA",
                "count": 2,
                "title": "Underperforming",
                "occurrences": ["2021-08-30", "2021-08-31"]
            }
        };
        vm.allOccurrances = [];

        function findColorForOccurance(date) {
            // I should know which order of states! 
            if (vm.data.optimal.occurrences.find(x => moment(x).format('MMM DD, YYYY') == moment(date).format('MMM DD, YYYY')) != null) return vm.data.optimal.color;
            if (vm.data.subOptimal.occurrences.find(x => moment(x).format('MMM DD, YYYY') == moment(date).format('MMM DD, YYYY')) != null) return vm.data.subOptimal.color;
            if (vm.data.slightlyDegraded.occurrences.find(x => moment(x).format('MMM DD, YYYY') == moment(date).format('MMM DD, YYYY')) != null) return vm.data.slightlyDegraded.color;
            if (vm.data.degraded.occurrences.find(x => moment(x).format('MMM DD, YYYY') == moment(date).format('MMM DD, YYYY')) != null) return vm.data.degraded.color;
            if (vm.data.underperforming.occurrences.find(x => moment(x).format('MMM DD, YYYY') == moment(date).format('MMM DD, YYYY')) != null) return vm.data.underperforming.color;
            if (vm.data.offline.occurrences.find(x => moment(x).format('MMM DD, YYYY') == moment(date).format('MMM DD, YYYY')) != null) return vm.data.offline.color;
        }

        function mergeSignalSatatesOccurrances() {
            //vm.allOccurrances = [...vm.data.optimal.occurrences, ...vm.data.subOptimal.occurrences, ...vm.data.slightlyDegraded.occurrences, ...vm.data.degraded.occurrences, ...vm.data.underperforming.occurrences, ...vm.data.offline.occurrences];
            vm.allOccurrances = Array.prototype.concat.apply([], [vm.data.optimal.occurrences, vm.data.subOptimal.occurrences, vm.data.slightlyDegraded.occurrences, vm.data.degraded.occurrences, vm.data.underperforming.occurrences, vm.data.offline.occurrences]); // [1, 2, 3, 4, 5, 6, 7, 8, 9]
            vm.showFullOccurences = true;
        }


        function setupSystemInsights() {
            vm.loading = true;

            var canvas = document.getElementById("signalStateChartMIR");
            var ctx = canvas.getContext('2d');

            // Chart.defaults.global.defaultFontColor = 'black';
            // Chart.defaults.global.defaultFontSize = 12;

            mergeSignalSatatesOccurrances();
            var noDuplicateOccDates = vm.allOccurrances.reduce(function (a, b) { if (a.indexOf(b) < 0) a.push(b); return a; }, []);
            setupDateOpts(noDuplicateOccDates);

            var data = {
                labels: [vm.data.optimal.title, vm.data.subOptimal.title, vm.data.underperforming.title, vm.data.slightlyDegraded.title, vm.data.degraded.title, vm.data.offline.title],
                datasets: [
                    {
                        fill: true,
                        backgroundColor: [vm.data.optimal.color, vm.data.subOptimal.color, vm.data.underperforming.color, vm.data.slightlyDegraded.color, vm.data.degraded.color, vm.data.offline.color],
                        data: [vm.data.optimal.count, vm.data.subOptimal.count, vm.data.underperforming.count, vm.data.slightlyDegraded.count, vm.data.degraded.count, vm.data.offline.count,],
                        // Notice the borderColor 
                        borderColor: [vm.data.optimal.color, vm.data.subOptimal.color, vm.data.underperforming.color, vm.data.slightlyDegraded.color, vm.data.degraded.color, vm.data.offline.color,],
                        borderWidth: [2, 2]
                    }
                ]
            };

            var defaultLegendClickHandler = Chart.defaults.doughnut.legend.onClick;

            var newLegendClickHandler = function (e, legendItem) {
                systemInsightsService.pieChartHover(legendItem.text, { shouldHide: !legendItem.hidden, actionType: 'click' });
                defaultLegendClickHandler.call(this, e, legendItem);

            };

            var options = {
                title: {
                    display: true,
                    fontSize: 18,
                    fontColor: '#000',
                    position: 'top',
                    alignment: 'left',
                    text: 'Overall Health',
                    layout: {
                        padding: {
                            right: 50
                        }
                    }
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                        label: function (tooltipItems, data) {
                            var i = tooltipItems.index;
                            return data.labels[i] + " State: " + data.datasets[0].data[i] + " signals";
                        }
                    }
                },
                onHover: function (event, data) {
                    //get the line datasets

                    if (!data || data.length == 0) {
                        systemInsightsService.pieChartHover('Restore State', { actionType: 'hover' });
                        return;
                    };
                    systemInsightsService.pieChartHover(data[0]._model.label, { actionType: 'hover' });
                },
                plugins: {
                    datalabels: {
                        display: function (context) {
                            let sum = 0;
                            let dataArr = context.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage = (context.dataset.data[context.dataIndex] * 100 / sum).toFixed(2);
                            return percentage >= 5; // or >= 1 or ...
                        },
                        formatter: (value, ctx) => {

                            let sum = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                            return percentage;


                        },
                        color: '#FFF',
                    },
                },
                legend: {
                    // display: true,
                    onClick: () => { },
                    reverse: true,
                    display: true,
                    position: 'bottom',
                    align: 'center',
                    fullSize: false,
                    maxWidth: 10,
                    labels: {
                        fontSize: 14,
                        // boxWidth: 5,
                        fontColor: '#000',
                        fontWeight: 500,
                        filter: function (item, chart) {
                            // Logic to remove a particular legend item goes here
                            return chart.datasets[0].data[item.index] != 0
                        },
                    }
                },
            };

            // Chart declaration:
            vm.signalStatesChart = new Chart(ctx, {
                type: 'doughnut',
                data: data,
                options: options
            });
            vm.loading = false;

        }

        ////////

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })

        $mdDateLocale.formatDate = function (date) {
            return date ? moment(date).format('MMM, YYYY') : '';
        };

        //setup options change also
        vm.monthlyIntersectionReportService.subscribe($scope, function onChange(ev, options) {
            if (!vm)
                return;

            switch (options) {
                case "pdfReport":
                    vm.pdfExport();
                    break;
            }
        });

        vm.performanceCards = [
            {
                id: "queueReport",
                metricType: signalPerformanceService.metricTypesEnum.QueueLength,
                aggregationName: signalPerformanceService.aggregationNames.Queue,
                chartType: 'line',
                data: {
                    signalData: [],
                    approachData: [],
                    hasNeededDetection: false,
                },
                api: {},
                onDestroy: function () {
                    this.api = {};
                }
            }
        ];

        vm.trendsCards = [
            {
                id: "volume1", // title and chartType
                chartType: 1,
                isExpanded: false,
                metricType: signalPerformanceService.metricTypesEnum.Volume,
                aggregationType: signalPerformanceService.aggregationNames.Volumes,
                title: "Volume",
                reverseColorScale: false,
                isSquareRoot: false,
                showTotal: true,
                isAverage: false,
                legendTitle: 'Counts',
                api: {},
                data: {
                    signalData: [],
                    approachData: [],
                    hasNeededDetection: false,
                },
                onDestroy: function () {
                    this.api = {};
                }
            },
            {
                id: "volume2", // title and chartType
                chartType: 2,
                isExpanded: false,
                metricType: signalPerformanceService.metricTypesEnum.Volume,
                aggregationType: signalPerformanceService.aggregationNames.Volumes,
                title: "Volume",
                reverseColorScale: false,
                isSquareRoot: false,
                showTotal: true,
                isAverage: false,
                legendTitle: 'Counts',
                api: {},
                data: {
                    signalData: [],
                    approachData: [],
                    hasNeededDetection: false,
                },
                onDestroy: function () {
                    this.api = {};
                }
            },
            {
                id: "splitFailures1", // title and chartType
                chartType: 1,
                isExpanded: false,
                metricType: signalPerformanceService.metricTypesEnum.SplitFails,
                aggregationType: signalPerformanceService.aggregationNames.SplitFails,
                title: "Split Failures",
                reverseColorScale: false,
                isSquareRoot: true,
                showTotal: true,
                isAverage: false,
                legendTitle: 'Counts',
                api: {},
                data: {
                    signalData: [],
                    approachData: [],
                    hasNeededDetection: false,
                },
                onDestroy: function () {
                    this.api = {};
                }
            }
        ];

        vm.setupSearchBar();


        //setup options change also
        vm.insightsService.subscribe($scope, function onChange(ev, options) {
            if (!vm)
                return;

            switch (options) {
                case "InsightsFailed":
                case "InsightsLoaded":
                    vm.setSearchOptions();
                    if (vm.signal.signalID != '') {
                        vm.insightsLoaded = true;
                        vm.trackDailyInsightsIndexes = [];
                        vm.trackHourlyInsightsIndexes = [];
                        vm.getInsightData();
                    }
                    break;
            }
        });

        vm.getInsightData = function () {
            insightsService.getSystemInsightsBySignal(vm.queryData).then(function (data) {

                vm.signalState = data.signalState;
                vm.signalStateUrl = "assets/icons/signal-states/" + data.signalState.state.replace(/\s+/g, '-').toLowerCase() + ".png";
                userSettingsService.getUserSettings()
                    .then(function (userSettings) {
                        vm.categoriesToShow = userSettings.insightSettings.enabledDashboardInsightCategories;

                        vm.briefInsights = [];
                        vm.summedInsightsCount = 0;
                        var investigateInsightsParams;

                        vm.signalState = data.signalState;
                        vm.signalStateUrl = "assets/icons/signal-states/" + data.signalState.state.replace(/\s+/g, '-').toLowerCase() + ".png";
                        // check if there's item in session storage before grabbing the parameters
                        if (sessionStorage && sessionStorage.__investigating_from_insights) {
                            investigateInsightsParams = JSON.parse(sessionStorage.__investigating_from_insights);
                        }



                        data.insightCategories.sort((x, y) => (x.displayOrder > y.displayOrder) ? 1 : -1);
                        data.insightCategories.forEach(function (category) {
                            vm.categoriesToShow.forEach(function (availableCategory) {
                                if (category.insightCategoryID == availableCategory.insightCategoryID) {
                                    vm.currentCategory = availableCategory;
                                    var allInsightsInCategory = [];
                                    category.insightGroups.forEach(function (group) {
                                        group.insightTypes.forEach(function (insType) {
                                            if (insType.display && vm.currentCategory.insightTypes.find(x => x.insightTypeID == insType.insightTypeID)) {
                                                vm.summedInsightsCount += insType.insights.length;
                                                insType.insights.forEach(function (insight) {
                                                    insight.name = insType.name;
                                                    insight.investigateAction = insType.investigateAction;
                                                    insight.acknowledgedActions = insType.acknowledgedActions;
                                                    insight.severity = insType.insightSeverity;
                                                });
                                                allInsightsInCategory.push(...insType.insights);
                                            }
                                        });
                                    });
                                    var isExpanded = false;
                                    var insightsInCat = allInsightsInCategory.filter(x => x.concern);

                                    var allGood = false;

                                    if (insightsInCat.filter(x => !x.isIgnored).length == 0) {
                                        allGood = true;
                                    }

                                    var badgeColor = "#4CAF50";

                                    $translate('INSIGHTS_SIDEPANEL.SIGNAL_STATE_DESCRIPTIONS.' + data.signalState.state)
                                        .then(function (translatedValue) {
                                            if (translatedValue) {
                                                $scope.signalStateTitle = translatedValue;
                                            }
                                        });

                                    if (category.highestConcernSummary) {
                                        switch (category.highestConcernSummary.concern.level) {
                                            case 1:
                                                badgeColor = "#D0021B";
                                                break;
                                            case 2:
                                                badgeColor = "#F5A623";
                                                break;
                                            case 3:
                                                badgeColor = "#6A7B8B";
                                                break;
                                        }
                                    }
                                    // if there's params from session storage,
                                    // that means that the user is investigating insight
                                    // so expand the category he's coming from
                                    var highestConcernCount = category.highestConcernSummary ? category.highestConcernSummary.count : 0;
                                    var singleInsightCategory = {
                                        displayOrder: category.displayOrder,
                                        iconName: 'mdi-' + category.iconName,
                                        allGood: allGood,
                                        badgeColor: badgeColor,
                                        name: category.name,
                                        id: category.insightCategoryID,
                                        count: highestConcernCount,
                                        insights: allInsightsInCategory.filter(x => !x.isIgnored),
                                        expanded: isExpanded
                                    }

                                    if (insightsInCat.filter(x => !x.isIgnored).length > 0 && investigateInsightsParams && investigateInsightsParams.navigatingFromInsights && category.insightCategoryID == investigateInsightsParams.categoryToInvestigate) {
                                        vm.placeInsightItemsInTable(singleInsightCategory);
                                        document.getElementById('insights-sidemenu-list').classList.add('md-active');
                                        vm.currentlySelectedInsightCategory = singleInsightCategory;
                                        var investigatingFromInsights = {
                                            navigatingFromInsights: false,
                                            categoryToInvestigate: null
                                        }
                                        sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));
                                    }

                                    vm.briefInsights.push(singleInsightCategory);
                                }
                            });
                        });

                        vm.briefInsights.sort((x, y) => (x.displayOrder > y.displayOrder) ? 1 : -1);
                        // cleanup session storage after using the params for navigation
                        // should probably remove the item instead of setting properties to false and null
                        // if (investigateInsightsParams && !investigateInsightsParams.navigatingFromInsights) {
                        //     var investigatingFromInsights = {
                        //         navigatingFromInsights: false,
                        //         categoryToInvestigate: null
                        //     }
                        //     sessionStorage.setItem("__investigating_from_insights", JSON.stringify(investigatingFromInsights));
                        // }

                        vm.loading = false;
                        $scope.loading = false;

                    });

                $translate('INSIGHTS_SIDEPANEL.SIGNAL_STATE_DESCRIPTIONS.' + data.signalState.state)
                    .then(function (translatedValue) {
                        if (translatedValue) {
                            $scope.signalStateTitle = translatedValue;
                        }
                        vm.updateCards();
                    })
            })
                .catch(function (e) {
                    console.log("lets see:  " + e)
                })
        }

        function convertImageToDataURI(url, callback, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas = document.createElement('CANVAS'),
                    ctx = canvas.getContext('2d'), dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                vm.miovisionLogo = dataURL;
                // callback(dataURL);
                canvas = null;
            };
            img.src = url;
        }

        function pdfExport() {
            vm.pdfLoading = true;
            $location.hash('mapReport');
            // call $anchorScroll()
            $anchorScroll();
            var estimatedCost = [];
            var co2Cost = [];
            var map = [];
            var queue = [];
            var volumeTrends = [];
            var volumeWeeklyData = [];
            var splitFailTrends = [];
            var approachVolumeNS = [];
            var approachVolumeWE = [];
            var approachVolumeSENW = [];
            var approachVolumeNESW = [];
            var signalState = [];
            var signalStateCalendarMIR = [];
            var signalStateDonutCalendar = [];

            var pdfName = "Signal_" + vm.signal.signalID + "_Time_" + moment(vm.timeOptions.currentFilter.startDateAndTime).format("MMM") + "_" + vm.timeOptions.currentFilter.startDateAndTime.getFullYear() + "_Monthly_Report";
            sessionStorage.setItem('last_clicked_report_path', pdfName);

            console.log(" PATH : " + sessionStorage.getItem("last_clicked_report_path"));
            var url = '/assets/images/spm/miovision-logo.svg';
            convertImageToDataURI(url);



            // html2canvas(document.getElementById("estimatedCostReport"), {
            //     useCORS: true, // MUST
            //     letterRendering: 1,
            //     onrendered: function (canvas) {
            //         var data = canvas.toDataURL();
            //         estimatedCost = [{
            //             image: data,
            //             width: 250,
            //             // height: 350
            //         }];
            //     }
            // });

            // html2canvas(document.getElementById("co2CostReport"), {
            //     useCORS: true, // MUST
            //     letterRendering: 1,
            //     onrendered: function (canvas) {
            //         var data = canvas.toDataURL();
            //         co2Cost = [{
            //             image: data,
            //             width: 250,
            //             // height: 350
            //         }];
            //     }
            // });

            html2canvas(document.getElementById("mapReport"), {
                useCORS: true, // MUST
                letterRendering: 1,
                onrendered: function (canvas) {
                    var data = canvas.toDataURL();
                    map = [{
                        image: data,
                        width: 530,
                    }];
                }
            });
            // html2canvas(document.getElementById("signalStateChartMIR"), {
            //     useCORS: true, // MUST,
            //     letterRendering: 1,
            //     onrendered: function (canvas) {
            //         var data = canvas.toDataURL();
            //         signalStateDonutCalendar = [{
            //             image: data,
            //             width: 250,
            //         }];
            //     }
            // });

            domtoimage.toPng(document.getElementById("signalStateChartMIR"))
                .then(function (dataURL) {
                    signalStateDonutCalendar = [{
                        image: dataURL,
                        width: 250,
                    }];
                })

            domtoimage.toPng(document.getElementById("signalStateCalendarMIR"))
                .then(function (dataURL) {
                    signalStateCalendarMIR = [{
                        image: dataURL,
                        width: 250,
                    }];
                })


            // html2canvas(document.getElementById("signalStateCalendarMIR"), {
            //     useCORS: true, // MUST,
            //     letterRendering: 1,
            //     onrendered: function (canvas) {
            //         var data = canvas.toDataURL();
            //         signalStateCalendarMIR = [{
            //             image: data,
            //             width: 250,
            //             // height: 350
            //         }];
            //     }
            // });

            domtoimage.toPng(document.getElementById("estimatedCostReport"))
                .then(function (dataURL) {
                    estimatedCost = [{
                        image: dataURL,
                        width: 250,
                    }];
                })

            domtoimage.toPng(document.getElementById("co2CostReport"))
                .then(function (dataURL) {
                    co2Cost = [{
                        image: dataURL,
                        width: 250,
                    }];
                })

            // domtoimage.toPng(document.getElementById("mapReport"))
            // .then(function (dataURL) {
            //     map = [{
            //         image: dataURL,
            //         width: 120,
            //     }];
            // })

            // var node = document.getElementById("queueReport");
            // domtoimage.toPng(document.getElementById("signalState"))
            // .then(function (dataURL) {
            //     signalState = [{
            //         image: dataURL,
            //         width: 120,
            //     }];
            // })

            // domtoimage.toPng(document.getElementById("signalInsightCategories"))
            // .then(function (dataURL) {
            //     signalInsightCategories = [{
            //         image: dataURL,
            //         width: 250,
            //     }];
            // })


            domtoimage.toPng(document.getElementById("queueReport"))
                .then(function (dataURL) {
                    queue = [{
                        image: dataURL,
                        width: 530,
                        // absolutePosition: {
                        //     x: 40 + 120 + 10,
                        //     y: 80 + 120
                        // }
                    }];
                })

            domtoimage.toPng(document.getElementById("volume1"))
                .then(function (dataURL) {
                    volumeTrends = [{
                        image: dataURL,
                        width: 530,
                        // absolutePosition: {
                        //     x: 40 + 120 + 120 + 17,
                        //     y: 80 + 110 - 7
                        // }
                    }];
                })

            domtoimage.toPng(document.getElementById("volume2"))
                .then(function (dataURL) {
                    volumeWeeklyData = [{
                        image: dataURL,
                        width: 530,
                    }];
                })

            domtoimage.toPng(document.getElementById("splitFailures1"))
                .then(function (dataURL) {
                    splitFailTrends = [{
                        image: dataURL,
                        width: 530,
                    }];
                })

            var isVolumeNS, isVolumeWE, isVolumeNESW, isVolumeSENW;
            var directions = "";
            if (vm.charts.chartDirectiveData.data.advancedNorthSouthData) {
                domtoimage.toPng(document.getElementById("northSouthReport"))
                    .then(function (dataURL) {
                        approachVolumeNS = [{
                            image: dataURL,
                            width: 530,
                        }];
                        directions += " NB SB "
                        isVolumeNS = true;
                    })
                    .catch(function (e) {
                        isVolumeNS = false;
                    })
            }
            else {
                isVolumeNS = false;
            }

            if (vm.charts.chartDirectiveData.data.advancedEastWestData) {
                domtoimage.toPng(document.getElementById("westEastReport"))
                    .then(function (dataURL) {
                        approachVolumeWE = [{
                            image: dataURL,
                            width: 530,
                        }];
                        directions += " EB WB "
                        isVolumeWE = true;
                    })
                    .catch(function (e) {
                        isVolumeWE = false;
                    })
            }
            else {
                isVolumeWE = false;
            }


            if (vm.charts.chartDirectiveData.data.advancedNorthEastSouthWestData) {
                domtoimage.toPng(document.getElementById("northeastSouthwestReport"))
                    .then(function (dataURL) {
                        approachVolumeNESW = [{
                            image: dataURL,
                            width: 530,
                        }];
                        directions += " NEB SWB "
                        isVolumeNESW = true;
                    })
                    .catch(function (e) {
                        isVolumeNESW = false;
                    })
            }
            else {
                isVolumeNESW = false;
            }

            if (vm.charts.chartDirectiveData.data.advancedNorthWestSouthEastData) {
                domtoimage.toPng(document.getElementById("southeastNorthwestReport"))
                    .then(function (dataURL) {
                        approachVolumeSENW = [{
                            image: dataURL,
                            width: 530,
                        }];
                        directions += " NWB SEB "
                        isVolumeSENW = true;
                    })
                    .catch(function (e) {
                        isVolumeSENW = false;
                    })
            }
            else {
                isVolumeSENW = false;
            }

            if (vm.signals.signalName == "" && vm.signals.signalID) {
                signalService.getSignalById(vm.signals.signalID)
                    .then(function (signal) {
                        vm.signals.signalName = getSignalDescription(signal.signalID, signal.primaryName, signal.secondaryName);
                    });
            }

            var getChartsInterval = $interval(function () {
                if (signalStateCalendarMIR.length > 0 && signalStateDonutCalendar.length > 0 && estimatedCost.length > 0 && co2Cost.length > 0 && queue.length > 0 && volumeTrends.length > 0 && volumeWeeklyData.length > 0
                    && splitFailTrends.length > 0 && (isVolumeSENW != undefined) && (isVolumeNESW != undefined) && (isVolumeWE != undefined) && (isVolumeNS != undefined)) {
                    $interval.cancel(getChartsInterval);
                    var docDefinition = {
                        background: function (currentPage, pageSize) {
                            return {
                                table: {
                                    widths: [pageSize.width - 30],
                                    heights: [pageSize.height - 100],
                                    body: [['']]
                                },
                                layout: 'noBorders',
                                margin: [10, 75]
                            };
                        },
                        pageSize: 'A4',
                        pageMargins: [0, 70, 0, 0],
                        header: {
                            columns: [
                                {
                                    height: 'auto',
                                    width: '70%',
                                    stack: [
                                        { text: vm.signals.signalName + ' \n', fontSize: 14, alignment: 'left', bold: true, color: '#0F75DB', margin: [20, 20, 0, 5] }, //need to add approaches 
                                        { text: moment(vm.timeOptions.currentFilter.startDateAndTime).format("MMMM") + " " + vm.timeOptions.currentFilter.startDateAndTime.getFullYear(), alignment: 'left', margin: [20, 0, 0, 20], fontSize: 12, color: '#0F75DB' }, //need to add approaches 

                                    ]
                                },
                                {
                                    height: 'auto',
                                    width: '30%',
                                    stack: [
                                        {
                                            image: vm.miovisionLogo,
                                            width: 120, height: 21.32,
                                            margin: [20, 20, 0, 0],
                                            alignment: 'right',
                                        },
                                        { text: 'Performance Report\n', style: 'header', color: '#6A7B8B', fontSize: 14, alignment: 'right', margin: [20, 8, 20, 0], },
                                    ]
                                }
                            ]
                        },
                        content: [
                            {
                                alignment: "center",
                                margin: [0, 10, 0, 10],
                                columns: [
                                    map,
                                ]
                            },
                            {
                                alignment: "center",
                                margin: [20, 10, 20, 10],
                                width: 'auto',
                                height: 'auto',
                                columns: [
                                    estimatedCost,
                                    co2Cost,
                                ]
                            },
                            {
                                alignment: "center",
                                margin: [20, 10, 20, 10],
                                columns: [
                                    signalStateDonutCalendar,
                                    signalStateCalendarMIR,
                                ],
                            },
                            {
                                margin: [0, 10, 0, 10],
                                alignment: "center",
                                columns: [
                                    queue,
                                ],
                            },
                            {
                                alignment: "center",
                                columns: [
                                    volumeTrends,
                                ]
                            },
                            {
                                alignment: "center",
                                columns: [
                                    volumeWeeklyData,
                                ],
                            },
                            {
                                alignment: "center",
                                margin: [0, 20, 0, -10],
                                columns: [
                                    splitFailTrends
                                ]
                            },
                            {
                                alignment: "center",
                                columns: [
                                    isVolumeNS ? approachVolumeNS : null
                                ],
                            },
                            {
                                alignment: "center",
                                columns: [
                                    isVolumeWE ? approachVolumeWE : null
                                ],
                            },
                            {
                                alignment: "center",
                                columns: [
                                    isVolumeNESW ? approachVolumeNESW : null
                                ],
                            },
                            {
                                alignment: "center",
                                columns: [
                                    isVolumeSENW ? approachVolumeSENW : null
                                ],
                            },
                            {
                                alignment: "center",
                                columns: [
                                    {
                                        absolutePosition: {
                                            x: 0,
                                            y: 786.9
                                        },
                                        style: "section",
                                        table: {
                                            // headers are automatically repeated if the table spans over multiple pages
                                            // you can declare how many rows should be treated as headers
                                            headerRows: 1,
                                            widths: ['*'],
                                            heights: [50.6],
                                            body: [
                                                [
                                                    {
                                                        margin: [0, 11, 0, 0],
                                                        layout: 'noBorders',
                                                        alignment: "center",
                                                        stack: [
                                                            { text: "Customer Support: 1-855-360-7752    |    137 Glasgow Street, Suite 110, Kitchener, Ontario", color: '#FFF', margin: [0, 0, 0, 3], fontSize: 12 },
                                                            { text: "Copyright © 2021 Miovision Technologies Inc.", color: '#FFF', margin: [0, 0, 0, 3], fontSize: 12 },
                                                        ]
                                                    }
                                                ]
                                            ]
                                        },
                                        layout: 'noBorders',
                                    }
                                    // {
                                    //     backgroundColor: '#0F75DB',
                                    //     fillColor: '#0F75DB',
                                    //     bgColor: '#0F75DB',
                                    //     background: '#0F75DB',
                                    //     stack: [
                                    //         { text: "Customer Support: 1-855-360-7752" },
                                    //         { text: "Copyright © 2021 Miovision Technologies Inc." },
                                    //         { text: "137 Glasgow Street, Suite 110, Kitchener, Ontario" },
                                    //     ]
                                    // }
                                ]
                            }
                        ],
                        defaultStyle: {
                            columnGap: 0,
                        },
                        pageSize: 'A4',
                        styles: {
                            section: {
                                fillColor: "#0F75DB",
                            },
                            header: {
                                fontSize: 10,
                                bold: true,
                                alignment: 'center',
                            }
                        }
                    };

                    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
                    pdfDocGenerator.download(pdfName);
                    pdfDocGenerator.getBuffer(function (databuffer) {
                        vm.reportDataBuffer = databuffer;
                    });

                    monthlyIntersectionReportService.reportOutput(true);
                    vm.pdfLoading = false;
                }
            }, 500);
        }

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                    case "signal":
                        if (searchBarService.isFiltering()) {
                            vm.setSearchOptions();
                            vm.chartServerError = false;
                            vm.insightsLoaded = false;
                            vm.loading = true;
                            vm.reportLoading = true;
                            if (vm.signals)
                                vm.signals.getSearchBarData();
                        }
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.setSearchOptions();
                            vm.chartServerError = false;
                            if (vm.signals)
                                vm.signals.getSearchBarData();
                        }
                        break;
                    case "state":
                        vm.isFiltering = searchBarService.isFiltering();
                        vm.reportLoading = false;
                        vm.chartServerError = false;
                        break;
                    case "filter":
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Signal Performance Dashboard"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Signals',
                //                showMonthlyReportOptions: true,
                //showCurrentFilterDates: true,
                showMonthlyBasedTimeFrame: true,
                helpJsonPropertyPath: "PERFORMANCE_METRIC_CARD." + "GENERAL_HELP",
                showHelp: true,
                showExcel: false,
                showPdf: true,
                showSignalNotificationTabs: true,
                hideSidemenu: true,
                metricTypeId: userSettingsService.dashboardTypeIds.SignalPerformance,
                dashboardType: "Signal",
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TW",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    showWeekdayFilter: true,
                    maxDayRange: 31
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: true,
                    skipStepsPerBin: false,
                    notifyOnBinChange: true,
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function onCardApiInit(apiObj) {
            if (apiObj && apiObj.update && vm.insightsLoaded)
                vm.updateCards();
        }

        function areAllCardsInitialized() {
            let res = true;
            for (let i = 0; i < vm.trendsCards.length; i++) {
                let api = vm.trendsCards[i].api;
                if (!api || !api.update) {
                    res = false;
                }
            }
            return res;
        }

        function updateCards() {
            if (vm.areAllCardsInitialized())
                vm.getData(vm.timeOptions);
        }

        function getData(query) {
            if (!vm.timeOptions || !vm.signal) {
                return;
            }

            //setup default parameters
            var daysOfWeek = [" "];
            var startHour = 0, startMinute = 0, endMinute = 0, endHour = 0;
            var start = new Date(query.currentFilter.startDateAndTime);
            var end = new Date(query.currentFilter.endDateAndTime);

            var aggregationTypesToQuery = [...new Set(vm.performanceCards.map(x => x.aggregationName))];

            //set the day of week list
            if (vm.timeOptions.daysOfWeekList) {
                daysOfWeek = vm.timeOptions.daysOfWeekList;
            }
            vm.loading = true;
            vm.charts.loading = true;
            vm.pdfState = "";



            //set the day of week list
            if (vm.timeOptions.daysOfWeekList) {
                daysOfWeek = vm.timeOptions.daysOfWeekList;
            }

            var filterOptions = signalTrendsService.getOptions();
            var baselineType = vm.trendsConstants.Filters.baselineAverage;

            var baselineStartDate = null;
            var baselineEndDate = null;

            if (filterOptions.filterState == vm.trendsConstants.Filters.baselineAverage) {
                baselineType = filterOptions.filterState;
            }
            else if (filterOptions.filterState == vm.trendsConstants.Filters.baselineDate) {
                baselineType = filterOptions.filterState;
                baselineStartDate = filterOptions.baselineStartDate.toLocaleString();
                baselineEndDate = filterOptions.baselineEndDate.toLocaleString();
            }

            var aggregationTypesTrends = [...new Set(vm.trendsCards.map(x => x.aggregationType))];
            aggregationTypesTrends.push(aggregationTypesToQuery[0]); //checking agregation 
            vm.timeOptions.timeOption = "StartToEnd";
            var optsTrends = signalTrendsService.createOptionsObject(vm.signal.signalID, aggregationTypesTrends, start.toLocaleString(),
                end.toLocaleString(), startHour, startMinute, endHour, endMinute, daysOfWeek, vm.timeOptions.timeOption,
                vm.currentBin, baselineType, baselineStartDate, baselineEndDate, true);
            monthlyIntersectionReportService.getMonhtlyIntersectionData(optsTrends)
                .then(function (res) {
                    vm.chartServerError = false;
                    if (!res || !res.approachVolume || !res.trends) {
                        vm.chartServerError = true;
                        return;
                    }

                    let fullDataSet = res.trends.data;

                    //go through each card in array and match with data from server
                    //do any pre-processing of the data that is needed, then pass into each card
                    //for display
                    for (let i = 0; i < vm.trendsCards.length; i++) {
                        let thisCard = vm.trendsCards[i];
                        let cardDataSet = fullDataSet[thisCard.aggregationType];
                        vm.dataInsights = insightsService.getInsights("Other").insightGroups[0];

                        if (thisCard.aggregationType == "volumes") {
                            if (cardDataSet.dailyBins.length > 0) {
                                let res = findInsightsInData(cardDataSet.dailyBins, vm.dataInsights, true);
                                cardDataSet.dailyBinsWithInsights = res;
                            }
                            if (cardDataSet.hourlyBins.length > 0) {
                                let res = findInsightsInData(cardDataSet.hourlyBins, vm.dataInsights, false);
                                cardDataSet.hourlyBinsWithInsights = res;
                            }
                        }
                        else {
                            if (cardDataSet.dailyBins.length > 0) {
                                let res = trackInsights(cardDataSet.dailyBins, vm.trackDailyInsightsIndexes);
                                cardDataSet.dailyBinsWithInsights = res;
                            }
                            if (cardDataSet.hourlyBins.length > 0) {
                                let res = trackInsights(cardDataSet.hourlyBins, vm.trackHourlyInsightsIndexes);
                                cardDataSet.hourlyBinsWithInsights = res;
                            }

                        }
                        if (!cardDataSet) continue;

                        if (thisCard.api && thisCard.api.update)
                            thisCard.api.update(cardDataSet);

                    }

                    vm.executiveInfo.emissionsTons = res.executive.emissionsTons.toLocaleString();
                    vm.executiveInfo.emissionsWasteGallonsGas = res.executive.emissionsWasteGallonsGas.toLocaleString();
                    vm.executiveInfo.emissionsWasteKgVOC = res.executive.emissionsWasteKgVOC;

                    vm.executiveInfo.emissionsCost = res.executive.emissionsCost.toLocaleString();
                    vm.executiveInfo.totalCost = res.executive.totalCost.toLocaleString();
                    vm.executiveInfo.laborCosts = res.executive.laborCosts.toLocaleString();

                    vm.executiveInfo.levelOfService = res.executive.levelOfService;

                    //extrapolate annually
                    //get total days of filter and divide by 365
                    //then multiple by that to get annual
                    var searchDays = searchBarService.calculateFilterTimeSpanInDays();
                    var dailyMultiplier = 1;
                    if (searchDays < 1 && searchDays > 0) {
                        dailyMultiplier = 1 / searchDays;
                        searchDays = 1;
                    }
                    var annualRatio = 365 / searchDays;
                    if (!vm.executiveInfo.annual)
                        vm.executiveInfo.annual = {};

                    vm.executiveInfo.annual.totalCost = moneyFormat(((parseFloat(vm.executiveInfo.totalCost.replace(/,/g, '')) * dailyMultiplier) * annualRatio));
                    vm.executiveInfo.annual.laborCosts = moneyFormat(((parseFloat(vm.executiveInfo.laborCosts.replace(/,/g, '')) * dailyMultiplier) * annualRatio));
                    vm.executiveInfo.annual.emissionsTons = Math.round(((parseFloat(vm.executiveInfo.emissionsTons.replace(/,/g, '')) * dailyMultiplier) * annualRatio));
                    vm.executiveInfo.annual.emissionsCost = moneyFormat(((parseFloat(vm.executiveInfo.emissionsCost.replace(/,/g, '')) * dailyMultiplier) * annualRatio));

                    if (res.executive.emissionsCost <= 0 && res.executive.totalCost <= 0) {
                        vm.hasData = false;
                        vm.executiveInfo = {};
                    }
                    else
                        vm.hasData = true;

                    vm.charts.chartDirectiveData = res.approachVolume;

                    if (vm.charts.api && vm.charts.api.updateData) {
                        vm.charts.api.updateData(vm.charts.chartDirectiveData);
                    }

                    let fullDataSetPerfromance = res.signalPerformance.signalData;
                    //go through each card in array and match with data from server
                    //do any pre-processing of the data that is needed, then pass into each card
                    //for display
                    for (let i = 0; i < vm.performanceCards.length; i++) {
                        let thisCard = vm.performanceCards[i];
                        let cardDataSet = fullDataSetPerfromance[thisCard.aggregationName];
                        if (!cardDataSet) continue;


                        thisCard.data.detectionTypeId = cardDataSet.data.detectionTypeId;
                        thisCard.data.hasNeededDetection = cardDataSet.data.hasNeededDetection;
                        thisCard.data.signalData = cardDataSet.data.binsContainer;
                        thisCard.data.approachData = cardDataSet.approaches;
                        if (thisCard.api && thisCard.api.update)
                            thisCard.api.update(thisCard.data, vm.signal);

                    }

                    vm.charts.loading = false;
                    vm.loading = false;
                    vm.pdfState = "pdf-ready";

                    monthlyIntersectionReportService.triggerReport('reportLoaded');
                    vm.setupSystemInsights();
                })
                .catch(function (error) {
                    if (vm) {
                        vm.loading = false;
                        vm.chartServerError = true;
                    }

                });

        }
        function moneyFormat(labelValue) {
            // Nine Zeroes for Billions
            return Math.abs(Number(labelValue)) >= 1.0e+9
                ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
                // Six Zeroes for Millions 
                : Math.abs(Number(labelValue)) >= 1.0e+6
                    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
                    //// Three Zeroes for Thousands
                    : Math.abs(Number(labelValue)) >= 1.0e+3
                        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
                        : Math.abs(Number(labelValue));
        }

        function trackInsights(data, indexes) {

            let res = [];

            data.forEach(function (elem, index) {

                let missingData = false;
                let noData = false;
                let unrelabileData = false;
                if (indexes.some(x => x == index)) {
                    noData = true;
                }

                res.push({
                    date: elem.start, value: elem.value,
                    dataInsights: {
                        missingData: missingData,
                        noData: noData,
                        unrelabileData: unrelabileData
                    }
                });

            });
            return res;
        }

        function findInsightsInData(data, dataInsights, isDaily) {
            var res = [];
            data.forEach(function (elem, index) {

                let date = new Date(elem.start.toLocaleString());
                let endDate = new Date(elem.end.toLocaleString());
                let missingData = false;
                let noData = false;
                let unrelabileData = false;

                if (dataInsights != undefined) {
                    dataInsights.insightTypes[1].insights.forEach(function (ins) {
                        ins.occurrences.forEach(function (o) {
                            let oStart = new Date(o.start);
                            let oEnd = new Date(o.end);
                            if (date >= oStart && endDate <= oEnd && elem.value == 0) {
                                noData = true;
                                isDaily ? vm.trackDailyInsightsIndexes.push(index) : vm.trackHourlyInsightsIndexes.push(index);
                            }
                        });
                    });

                    // reapair this
                    dataInsights.insightTypes[0].insights.forEach(function (ins) {
                        ins.occurrences.forEach(function (o) {
                            let oStart = new Date(o.start);
                            let oEnd = new Date(o.end);
                            if (oStart.getDay() != oEnd.getDay() && date >= oStart && endDate <= oEnd && elem.value == 0) {
                                noData = true;
                                isDaily ? vm.trackDailyInsightsIndexes.push(index) : vm.trackHourlyInsightsIndexes.push(index);
                            }
                            // if(vm.isDaily && date>=oStart && endDate<=oEnd) missingData = true;
                            // if(!vm.isDaily && date>=oStart && endDate<=oEnd) missingdata = true;                            
                        });
                    });
                }

                res.push({
                    date: elem.start, value: elem.value,
                    dataInsights: {
                        missingData: missingData,
                        noData: noData,
                        unrelabileData: unrelabileData
                    }
                });

            });
            return res;
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.signal = {
                signalID: options.signal.signalID,
                description: options.signal.description,
            };
            var currentTime = new Date();
            var date = options.timeOptions.currentFilter.startDateAndTime, y = date.getFullYear();
            var m = currentTime.getMonth() == date.getMonth() ? date.getMonth() - 1 : date.getMonth();
            var firstDay = new Date(y, m, 1);
            var lastDay = new Date(y, m + 1, 0, 23, 59, 59);

            vm.timeOptions = options.timeOptions;
            vm.timeOptions.currentFilter.startDateAndTime = firstDay;
            vm.timeOptions.currentFilter.endDateAndTime = lastDay;
            vm.queryData.start = firstDay.toLocaleString();
            vm.queryData.end = lastDay.toLocaleString();
            vm.queryData.signalID = options.signal.signalID;
        };

        vm.signals = {
            signalID: {},
            signalName: {},
            selected: {},
            getSearchBarData: function () {
                //on initial load, we need to populate our data with the focus data from service
                var options = searchBarService.getSearchOptions();
                //vm.timeOptions = options.timeOptions;
                if (options) {
                    if (options.signal && options.signal.signalID && options.signal.signalID) {
                        vm.signals.signalID = options.signal.signalID;
                        vm.signals.signalName = options.signal.description;
                        vm.signals.selected = options.signal;
                        vm.signals.selected.description = options.signal.description;
                    }
                    else {
                        vm.signals.signalID = "";
                        vm.signals.signalName = "";
                        vm.signals.selected = undefined;
                    }

                }
            }
        };
        vm.charts = {
            imageLocations: [],
            loading: true,
            api: {},
            interactiveLoading: false,
            chartDirectiveData: {},
            rawData: {},
            clearChartData: function () {
                vm.charts.chartDirectiveData = {};
                vm.charts.rawData = {};
            },
            isGalleryOpen: false,
            showGallery: function (i) {
                if (angular.isDefined(i)) {
                    this.photoOpts.index = i;
                }
                this.isGalleryOpen = true;
            },
            closeGallery: function () {
                this.isGalleryOpen = false;
            },
            onApiInit: function (apiObj) {
                vm.charts.api = apiObj;
            },
            onApiDestroy: function () {
                vm.charts.api = {};
            },
            photoOpts: {
                index: 0
            },
            resultData: {},
        };

        var inVm = "";
        //put this into service
        if (sessionStorage.getItem("__SPM_MEASURES_NEW_MODEL")) {
            inVm = JSON.parse(sessionStorage.getItem("__SPM_MEASURES_NEW_MODEL"), JSON.dateParser);
            sessionStorage.removeItem("__SPM_MEASURES_NEW_MODEL");
        }

        if (inVm) {
            $.extend(true, vm, inVm);
        }

        if (vm.isFiltering) {
            //initially load the options
            vm.signals.getSearchBarData();
        }

    }

})();