(function () {
    "use strict";

    angular.module("app.spm.core")
        .factory("eventsResource", ["$resource", "environmentConfig", function ($resource, environmentConfig) {
            var eventsUrl = environmentConfig.apiUrl + "/api/events/";
            return $resource(eventsUrl, {}, {
                update: { method: 'PUT' },
                create: { method: "POST" },
                query: { method: "GET", url: eventsUrl + "query?"},
                remove: { method: "DELETE" },
                getDataIntegrity: {
                    method: "POST", url: eventsUrl + "verifydata", isArray: true
                },
                getSystemOverviewData: {
                    method: "POST", url: eventsUrl + "getstatus"
                },
                getDataStatistics: {
                    method: "POST", url: eventsUrl + "datastatistics"
                },
                getHistogram: {
                    method: "POST", url: eventsUrl + "eventhistogram"
                }
            });
        }]);
}());
