﻿import Chart from 'chart.js';
(function () {
    "use strict";

    angular
        .module("app.spm.charts")
        .controller("canvasChartController", canvasChartController);

    function canvasChartController($state, $stateParams, $scope, $rootScope, $element, chartsService) {
        var vm = this;
        if (vm) {
            vm.destroyChart = destroyChart;
        }
        Chart.plugins.register({
            id: 'paddingBelowLegend',
            beforeInit: function (chart, options) {
                if (chart.options.paddingBelowLegend) {
                    chart.legend.afterFit = function () {
                        this.height = this.height + 30;
                    };
                }
            }
        });

        $scope.$on("$destroy", function () {
            if (vm) {
                vm.destroyChart();
                if ($scope.onDestroy) {
                    $scope.onDestroy();
                }
                if (vm.rightClickListener)
                    vm.rightClickListener.off('contextmenu', vm.rightClickChart);

                for (let member in vm) {
                    vm[member] = null;
                }

                vm = null;
            }
            $scope.api = {};
        });

        vm.scopeColors = Chart.defaults.global.colors;

        vm.chartsService = chartsService;
        vm.beforeDraw = beforeDraw;
        vm.findScale = findScale;
        vm.hasData = hasData;
        vm.setTickPixelPosition = setTickPixelPosition;
        vm.beforeChartInit = beforeChartInit;
        vm.afterChartDraw = afterChartDraw;
        vm.togglePan = togglePan;
        vm.zoomEnabled = $scope.zoomEnabled;
        vm.panEnabled = false;
        vm.resetZoom = resetZoom;
        vm.updateOptions = updateOptions;
        vm.updateChartOptionsZoomPan = updateChartOptionsZoomPan;
        vm.onZoom = onZoom;
        vm.currentlyZooming = false;
        vm.panToolTip = panToolTip;
        vm.showZoomControls = $scope.showZoomControls;
        vm.createPlanAnnotations = createPlanAnnotations;
        vm.drawPlanLabel = drawPlanLabel;
        vm.disableZoom = disableZoom;
        vm.rightClickListener = undefined;
        vm.rightClickChart = rightClickChart;
        //use api object to interact with chart from parent controller
        //don't use watchers, has significant performance degradation
        $scope.update = update;
        $scope.render = render;
        vm.formatAMPM = formatAMPM;
        vm.previousTwoClicks = false;
        vm.showAdvancedChart = false;

        function panToolTip() {
            return vm.panEnabled ? "Turn off panning" : "Turn on panning";
        }

        function rightClickChart(e) {
            if (vm.currentlyZooming) {
                if (document.getElementById('chartjs-tooltip')) {
                    document.getElementById('chartjs-tooltip').getElementsByTagName('table')[0].innerHTML = '';
                    document.getElementById('chartjs-tooltip').getElementsByTagName('table')[0].style.backgroundColor = 'transparent';
                }
                vm.resetZoom();
                e.preventDefault()
                $scope.$apply();
                return false;
            }
            return true;
        }

        function resetZoom() {
            if (vm.chart && vm.currentlyZooming) {
                $("#canvasChart").css("cursor", "default");
                vm.chart.resetZoom();
                vm.currentlyZooming = false;
                vm.panEnabled = false;
                vm.zoomEnabled = true;
                vm.updateChartOptionsZoomPan();
            }
        }

        function updateChartOptionsZoomPan() {
            if (vm.chart && vm.chart.options && vm.chart.options.plugins) {
                var zoom = vm.chart.options.plugins.zoom;
                if (zoom) {
                    zoom.pan.enabled = vm.panEnabled;
                    zoom.zoom.enabled = vm.zoomEnabled;
                    vm.updateOptions();
                }
            }
        }

        function disableZoom() {
            if (vm.chart && vm.chart.options && vm.chart.options.plugins) {
                var zoom = vm.chart.options.plugins.zoom;
                if (zoom) {
                    zoom.zoom.enabled = false;
                }
            }
        }

        function togglePan() {
            if (!vm.panEnabled) {
                //toggle to panning enabled
                vm.panEnabled = true;
                //disable zoom
                vm.zoomEnabled = false;
                $("#canvasChart").css("cursor", "default");
            }
            else {
                vm.panEnabled = false;
                vm.zoomEnabled = true;
                $("#canvasChart").css("cursor", "default");

            }
            vm.updateChartOptionsZoomPan();
        }

        function updateOptions() {
            if (vm.chart) {
                var scaleOptions = {};
                for (var scaleKey in vm.chart.scales) {
                    var scale = vm.chart.scales[scaleKey]
                    scaleOptions[scaleKey] = { min: scale.options.ticks.min, max: scale.options.ticks.max };
                }

                vm.chart.update(0);
                //have to preserve the zoom/pan levels
                for (var scaleKey in vm.chart.scales) {
                    var scale = vm.chart.scales[scaleKey]
                    var minMax = scaleOptions[scaleKey];
                    scale.options.ticks.min = minMax.min;
                    scale.options.ticks.max = minMax.max;
                    vm.chart.update(0);
                }
            }
        }

        function pieLegendClick(twoClicks, e, legendItem) {
            if (legendItem.disableUnchecking) return;
            var index = legendItem.datasetIndex;
            var ci = vm.chart;
            var meta = vm.chart.getDatasetMeta(0).data[legendItem.datasetIndex];
            var numOfSelected = [];
            for (let i = 0; i < ci.data.datasets[0].data.length; i++) {
                numOfSelected.push(vm.chart.getDatasetMeta(0).data[i]);
            }

            // See controller.isDatasetVisible comment
            if (twoClicks) {
                for (let i = 0; i < numOfSelected.length; i++) {
                    if (index != i && !numOfSelected[i].disableUnchecking) {
                        numOfSelected[i].hidden = true;
                    } else {
                        numOfSelected[i].hidden = null;
                    }
                }
            }
            if (!twoClicks) {
                if (numOfSelected.filter(x => x.hidden == null).length == 1) {
                    if (numOfSelected.filter(x => x.hidden == null)[0]._index == index) {
                        for (let i = 0; i < numOfSelected.length; i++) {
                            numOfSelected[i].hidden = null;
                        }
                    } else {
                        meta.hidden = !meta.hidden;
                    }
                } else {
                    meta.hidden = !meta.hidden;
                }
            }


            vm.previousIndex = index;

            if (vm.chart.options.customLegendClick) {
                numOfSelected.forEach(function (item) {
                    if (vm.chart.legend.legendItems.find(x => x.datasetIndex == item._index)) {
                        item.text = vm.chart.legend.legendItems.find(x => x.datasetIndex == item._index).text;
                    }
                });

                var systemInsightsOptions = {
                    text: legendItem.text,
                    doubleClick: twoClicks,
                    allItems: numOfSelected
                };

                var currentSession = JSON.parse(sessionStorage.getItem('segmentData'));
                var systemOverviewSelection = {
                    degraded: numOfSelected.find(x => x.text == "Degraded") ? !numOfSelected.find(x => x.text == "Degraded").hidden : undefined,
                    slightlyDegraded: numOfSelected.find(x => x.text == "Slightly Degraded") ? !numOfSelected.find(x => x.text == "Slightly Degraded").hidden : undefined,
                    subOptimal: numOfSelected.find(x => x.text == "Sub-Optimal") ? !numOfSelected.find(x => x.text == "Sub-Optimal").hidden : undefined,
                    optimal: numOfSelected.find(x => x.text == "Optimal") ? !numOfSelected.find(x => x.text == "Optimal").hidden : undefined,
                    offline: numOfSelected.find(x => x.text == "Offline") ? !numOfSelected.find(x => x.text == "Offline").hidden : undefined,
                    underperforming: numOfSelected.find(x => x.text == "Underperforming") ? !numOfSelected.find(x => x.text == "Underperforming").hidden : undefined,
                }
                if (JSON.parse(sessionStorage.getItem('segmentData')).system_state_overview_selection) {
                    delete currentSession.system_state_overview_selection;
                }
                sessionStorage.setItem('segmentData', JSON.stringify(Object.assign({
                    system_state_overview_selection: systemOverviewSelection
                }, currentSession)));
                // telemetryService.insightsDashboardTelemetry("click", "system_state_overview")

                $rootScope.$emit('system-insights-clicked', systemInsightsOptions);
            }
            // We hid a dataset ... rerender the chart
            ci.update();
        }

        function legendClick(twoClicks, e, legendItem) {
            if (legendItem.disableUnchecking) return;
            var index = legendItem.index;
            var ci = vm.chart;
            var meta = ci.getDatasetMeta(index);
            var allUncheckable = ci.data.datasets.filter(item => item.disableUnchecking);
            var numOfSelected = [];
            for (let i = 0; i < ci.data.datasets.length; i++) {
                if (!ci.data.datasets[i].disableUnchecking) {
                    numOfSelected.push(ci.getDatasetMeta(i))
                }
            }
            var filterSelected = numOfSelected.filter(item => item.hidden == null);

            // See controller.isDatasetVisible comment
            if (twoClicks) {
                for (let i = 0; i < ci.data.datasets.length; i++) {
                    if (index != i && !ci.data.datasets[i].disableUnchecking) {
                        ci.getDatasetMeta(i).hidden = true;
                    } else {
                        meta.hidden = null;
                    }
                }
            }
            if (!twoClicks) {
                if (filterSelected.length == 1 && filterSelected[0].index == index) {
                    for (let i = 0; i < ci.data.datasets.length; i++) {
                        ci.getDatasetMeta(i).hidden = null;
                    }
                } else {
                    meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                }
            }


            vm.previousIndex = index;
            // We hid a dataset ... rerender the chart
            ci.update();
        }

        function update() {
            vm.chart.update(0);
        }
        function togglePlans(chart, hidePlans) {
            var afterDraw = chart.title == 'Red Light Runners';
            chart.chartOptions.annotation = vm.createPlanAnnotations(hidePlans, afterDraw);
            vm.resetZoom();
            chart.api.render(undefined, chart.dataset, chart.chartOptions);
            // chart.update();
        }

        function onZoom(chart) {
            vm.currentlyZooming = true;
            vm.togglePan();
            $scope.$apply();
            //ok we just zoomed, so now disable zooming so user cannot "double-zoom"
            if (document.getElementById('chartjs-tooltip')) {
                document.getElementById('chartjs-tooltip').getElementsByTagName('table')[0].innerHTML = '';
                document.getElementById('chartjs-tooltip').getElementsByTagName('table')[0].style.backgroundColor = 'transparent';
            }
            vm.disableZoom();
        }

        //create the chart
        function render(labels, datasets, options, chartType) {
            if (vm) {
                vm.destroyChart();
            }
            if (options.chartType) $scope.type = options.chartType;
            var chartElem = $element.find('#canvasChart');
            if (chartElem && vm) {
                //setup right-click reset zoom listener
                if (vm.zoomEnabled) {
                    vm.rightClickListener = chartElem.on('contextmenu', vm.rightClickChart)
                }
                var ctx = chartElem[0].getContext('2d');
                //call destory before creating a new one, just in case

                //if labels and datasets are passed in, always use those. Otherwise fallback to scope
                var useLabels = $scope.labels;
                var useDatasets = $scope.datasets;
                var useOptions = $scope.options;
                if (labels) useLabels = labels;
                if (datasets) useDatasets = datasets;
                if (options) useOptions = options;

                if (useOptions.plugins && useOptions.plugins.zoom && useOptions.plugins.zoom.zoom) {
                    useOptions.plugins.zoom.zoom.onZoom = vm.onZoom;
                    useOptions.plugins.zoom.zoom.drag = {
                        borderColor: 'rgba(0,0,0,1)',
                        borderWidth: 1,
                        backgroundColor: 'rgba(37,122,253,.5)'
                    }
                }

                var planAnnotations = vm.createPlanAnnotations(options.hidePlans);
                if (planAnnotations) {
                    if (useOptions["annotation"] && useOptions["annotation"].annotations) {
                        useOptions["annotation"].annotations.push(planAnnotations.annotations);
                    }
                    else {
                        useOptions["annotation"] = planAnnotations;
                    }
                }
                //set no animations
                // if (useOptions["hover"]) {
                //     useOptions["hover"].animationDuration = 0;
                // }
                // else {
                //     useOptions["hover"] = {
                //         animationDuration: 0
                //     }
                // }
                useOptions["legend"].cursor = 'pointer';
                vm.legendClicked = false;


                var newLegendClickHandler = function (e, legendItem) {

                    if (vm.legendClicked) {
                        options.chartType ? pieLegendClick(true, e, legendItem) : legendClick(true, e, legendItem);
                        vm.legendClicked = false;
                        clearTimeout(this.executeDoubleClick);
                        return;
                    }

                    this.executeDoubleClick = setTimeout(function () {
                        vm.legendClicked = false;
                        options.chartType ? pieLegendClick(false, e, legendItem) : legendClick(false, e, legendItem);
                    }, 500);//500 is the interval between two click in doubleclick event, change to your convenient one

                    vm.legendClicked = true;
                }

                var newLabelGenerator = function (chart) {
                    var datasets = chart.data.datasets;
                    var options = chart.options.legend || {};
                    var usePointStyle = options.labels && options.labels.usePointStyle;

                    return chart._getSortedDatasetMetas().map(function (meta) {
                        var style = meta.controller.getStyle(usePointStyle ? 0 : undefined);

                        return {
                            text: datasets[meta.index].label,
                            fillStyle: !chart.isDatasetVisible(meta.index) ? 'white' : style.backgroundColor,
                            // hidden: !chart.isDatasetVisible(meta.index),
                            lineCap: style.borderCapStyle,
                            lineDash: style.borderDash,
                            lineDashOffset: style.borderDashOffset,
                            disableUnchecking: datasets[meta.index].disableUnchecking,
                            lineJoin: style.borderJoinStyle,
                            lineWidth: 2,
                            strokeStyle: style.borderColor,
                            pointStyle: style.pointStyle,
                            rotation: style.rotation,

                            // Below is extra data used for toggling the datasets
                            index: meta.index
                        };
                    }, this);
                }

                var doughnutLabelGenerator = function (chart) {
                    var options = chart.options.legend || {};
                    var usePointStyle = options.labels && options.labels.usePointStyle;

                    return chart._getSortedDatasetMetas().map(function (meta) {
                        var style = meta.controller.getStyle(usePointStyle ? 0 : undefined);
                        var legendItems = [];

                        meta.data.forEach(function (set) {
                            legendItems.push({
                                text: chart.data.labels[set._index],
                                fillStyle: set.hidden ? 'white' : chart.data.datasets[0].backgroundColor[set._index],
                                // hidden: !chart.isDatasetVisible(meta.index),
                                // disableUnchecking: datasets[meta.index].disableUnchecking,
                                strokeStyle: chart.data.datasets[0].backgroundColor[set._index],
                                pointStyle: style.pointStyle,
                                rotation: style.rotation,

                                // Below is extra data used for toggling the datasets
                                datasetIndex: set._index
                            });
                        });

                        return legendItems;
                    }, this)[0];
                }

                useOptions["legend"].onClick = newLegendClickHandler;
                Chart.defaults.global.legend.labels.boxWidth = 7;

                Chart.defaults.global.togglePlans = togglePlans;

                Chart.defaults.doughnut.legend.labels.generateLabels = doughnutLabelGenerator;
                Chart.defaults.global.legend.labels.generateLabels = newLabelGenerator;

                // To have a crosshair line, use LineWithLine chart type
                // Y axis HAS TO BE labeled '1'
                // Chart.defaults.LineWithLine = Chart.defaults.line;
                Chart.controllers.LineWithLine = Chart.controllers.line.extend({
                    draw: function (ease) {
                        Chart.controllers.line.prototype.draw.call(this, ease);

                        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
                            var activePoint = this.chart.tooltip._active[0],
                                ctx = this.chart.ctx,
                                x = activePoint.tooltipPosition().x,
                                topY = this.chart.scales['1'].top,
                                bottomY = this.chart.scales['1'].bottom;

                            // draw line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 2;
                            ctx.strokeStyle = '#07C';
                            ctx.stroke();
                            ctx.restore();
                        }
                    }
                });

                // let parentEventHandler = Chart.Controller.prototype.eventHandler;
                // Chart.Controller.prototype.eventHandler = function () {
                //     let ret = parentEventHandler.apply(this, arguments);
                //     if (this.options.useCrosshair) {
                //         let x = arguments[0].x;
                //         let y = arguments[0].y;
                //         this.clear();
                //         this.draw();

                //         let yScale = this.scales[1];
                //         this.ctx.beginPath();
                //         this.ctx.lineWidth = .8;
                //         this.ctx.moveTo(x, yScale.getPixelForValue(yScale.max, 0));
                //         this.ctx.strokeStyle = "#ff0000";
                //         this.ctx.lineTo(x, yScale.getPixelForValue(yScale.min, 0));
                //         this.ctx.stroke();
                //     }
                //     return ret;
                // };

                // useOptions["legend"].labels.generateLabels = newLabelGenerator
                useOptions.animation = false;
                useOptions.responsiveAnimationDuration = 0;

                vm.chart = new Chart(ctx, {
                    type: $scope.type,
                    data: {
                        labels: useLabels,
                        datasets: useDatasets,
                    },
                    options: useOptions,
                    plugins: {
                        beforeDatasetsDraw: vm.beforeDraw,
                        beforeInit: vm.beforeChartInit,
                        // beforeEvent: function (chart, e) {
                        //     if ((e.type === 'mousemove')
                        //         && (e.x >= e.chart.chartArea.left)
                        //         && (e.x <= e.chart.chartArea.right)
                        //     ) {
                        //         chart.options.customLine = {
                        //             x: undefined
                        //         }
                        //         chart.options.customLine.x = e.x;
                        //     }
                        // },
                        afterDraw: function (chart, easing) {
                            //     var ctx = chart.chart.ctx;
                            //     var chartArea = chart.chartArea;
                            //     var x = undefined;

                            //     if (chart.options.customLine && chart.options.customLine.x) {
                            //         x = chart.options.customLine.x;
                            //     }

                            //     if (!isNaN(x)) {
                            //         ctx.save();
                            //         ctx.strokeStyle = chart.options.customLine.color;
                            //         ctx.moveTo(chart.options.customLine.x, chartArea.bottom);
                            //         ctx.lineTo(chart.options.customLine.x, chartArea.top);
                            //         ctx.stroke();
                            //         ctx.restore();
                            //     }
                        },
                    }
                });

                return vm.chart;
            }
        }

        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        function createPlanAnnotations(hidePlans, afterDraw) {
            var res = undefined;

            if ($scope.plans && $scope.plans.length > 0) {
                res = {
                    annotations: []
                };
                //create annotation for each plan
                for (var i = 0; i < $scope.plans.length; i++) {
                    var fillStyle;
                    var thisPlan = $scope.plans[i];

                    var odd = i % 2;
                    if (hidePlans || odd) {
                        fillStyle = afterDraw ? 'rgba(255, 255, 255, 0)'  : 'rgba(255, 255, 255, .6)';
                    } else {
                        fillStyle = afterDraw ? 'rgba(0,0,0, 0.1)' : 'rgba(238, 238, 238, .7)';
                    }



                    var planStartTime = new Date(thisPlan.startTime).getTime()
                    var planEndTime = new Date(thisPlan.endTime).getTime()


                    var planAnnotation = {
                        id: 'plan-' + i + "-" + thisPlan.planNumber, // optional
                        type: 'box',
                        drawTime: afterDraw ? 'afterDatasetsDraw' : 'beforeDatasetsDraw',
                        // ID of the X scale to bind onto
                        xScaleID: 'x-axis-0',
                        // ID of the Y scale to bind onto
                        yScaleID: 'y-axis-0',
                        xMin: planStartTime,
                        xMax: planEndTime,
                        yMin: 0,
                        yMax: 9999999,
                        backgroundColor: fillStyle,
                        borderColor: fillStyle
                    }
                    res.annotations.push(planAnnotation);
                }
            }
            return res;
        }

        function beforeChartInit(chart, options) {
            if ($scope.plans && $scope.plans.length) {
                chart.legend.afterFit = function () {
                    this.height = this.height + 60;
                };
            }
        }

        function afterChartDraw(chart) {
            if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
                // let x = chart.tooltip._active[0]._model.x;
                // let yAxis = chart.scales[1];
                // let ctx = chart.ctx;
                // ctx.restore();
                // ctx.save();
                // ctx.beginPath();
                // ctx.moveTo(x, yAxis.top);
                // ctx.lineTo(x, yAxis.bottom);
                // ctx.lineWidth = 1;
                // ctx.strokeStyle = 'rgba(0, 0, 255, 0.4)';
                // ctx.stroke();
                // ctx.restore();
            }
        }

        function beforeDraw(chart, easing) {
            if ($scope.plans && $scope.plans.length > 0) {
                //get scale and make sure we have data
                // var options = Object.assign({}, chart.config.options);

                // var scale = vm.findScale(chart, options);
                // if (!vm.hasData(chart.config.data) || !scale) {
                //     return;
                // }
                // const ticks = scale.getTicks();
                // if (!ticks || ticks.length === 0) {
                //     return;
                // }

                // // push the current canvas state onto the stack
                const ctx = chart.ctx;
                ctx.save();
                //need to keep track of label positions to make sure they don't overlap
                var lastLabelEndPosition = 0;
                //draw each plan
                for (var i = 0; i < $scope.plans.length; i++) {
                    var thisPlan = $scope.plans[i];
                    if (!thisPlan.labels || thisPlan.labels.length <= 0) continue;

                    var midPoint = (new Date(thisPlan.startTime).getTime() + new Date(thisPlan.endTime).getTime()) / 2;
                    var centerPoint = chart.scales["x-axis-0"].getPixelForValue(midPoint);
                    if (centerPoint <= 0) continue;

                    //get available space for each label width based on last label
                    var availableWidthLabel = (centerPoint - lastLabelEndPosition);

                    let labelWidth = vm.drawPlanLabel(ctx, thisPlan.labels, centerPoint, availableWidthLabel);
                    //update our label end position variable with 5px pad
                    if (labelWidth > 0)
                        lastLabelEndPosition = centerPoint + (labelWidth / 2) + 5;
                }
                // restore the saved canvas state
                ctx.restore();
            }
        }

        function drawPlanLabel(ctx, labels, centerPoint, availableWidthLabel) {
            var startingHeight = 55;
            var lineHeight = 12;
            var maxWidth = 0;

            for (let labelIdx in labels) {
                var label = labels[labelIdx];
                var width = ctx.measureText(label).width;
                if (width > availableWidthLabel) {
                    return 0;
                }
                //keep track of max width
                if (width > maxWidth) maxWidth = width;
            }
            //if text will fit, then draw it
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";
            ctx.fillStyle = "#000000";
            ctx.font = "500 14px Roboto"

            var lineHeightCount = startingHeight;
            var count = 0;
            for (let labelIdx in labels) {
                var label = labels[labelIdx];
                if (count != 0) {
                    ctx.font = "500 12px Roboto"
                }
                ctx.fillText(label, parseInt(centerPoint), lineHeightCount);
                lineHeightCount += lineHeight;
                count++;
            }

            return maxWidth;
        }

        function hasData(data) {
            return data && data.datasets && data.datasets.length > 0;
        }

        function findScale(chart, options) {
            //finds the X axis scale for the chart
            var scales = Object.keys(chart.scales).map((d) => chart.scales[d]);
            if (options.axis === 'category') {
                return scales.find((d) => d.type === 'hierarchical' || d.type === 'category');
            }
            return scales.find((d) => d.id.startsWith('x-axis'));
        }

        function setTickPixelPosition(scale, index, tick) {
            // see core.scale.js -> getLineValue
            //sets the pixel position for the given tick index
            var pixelPosition = scale.getPixelForTick(index);
            tick.position = pixelPosition;

            return pixelPosition;
        }

        function destroyChart() {
            if (vm.chart) {
                //destroy the chart
                vm.chart.clear();
                vm.chart.destroy();

            }
        }
    }
}());
