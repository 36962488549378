import * as CORRIDORPERF from 'raw-loader!./dashboard-corridor-perf.html';

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.corridor-performance',
            []
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.corridor-performance', {
                url: '/corridor-performance',
                views: {
                    'content@app': {
                        template: CORRIDORPERF,
                        controller: 'DashboardCorridorPerformanceController as vm'
                    }
                }
            });
        $translatePartialLoaderProvider.addPart('corridor-performance');
    }
})();