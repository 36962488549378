import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.signals")
        .controller("signalPerformanceCardController", signalPerformanceCardController);

  
    function signalPerformanceCardController($state, $attrs, $scope, $rootScope, $element, $mdDialog, $timeout, $window, $translate, environmentConfig, signalService, signalPerformanceService, helpService, searchBarService) {
        var vm = this;
        $scope.update = update;
        $scope.expand = expand;
        vm.metricType = $scope.metricType;
        vm.signal = $scope.signal;
        vm.showHelpAlert = showHelpAlert;
        vm.setData = setData;
        vm.secondsToHms = secondsToHms;
        vm.secondsToHmsString = secondsToHmsString;
        vm.changeChart = changeChart;
        vm.mainSelectionIndex = "0";
        vm.currentBin = "Hour";
        vm.convertDataToHms = convertDataToHms;
        vm.setTotalsAndBinData = setTotalsAndBinData;
        //default width
        vm.widthClass = $scope.fromReport ? "width-750" : "width-350";
        vm.expand = expand;
        vm.isExpanded = false;
        vm.chartDefinitions = createCharts();
        vm.chartServerError = false;
        vm.translateLocationForMetric = "";
        vm.showAxes = $scope.showAxes;
        vm.hideButtons = $scope.hideButtons;
        vm.searchBarService = searchBarService;

        function addHours (current, h) {
            let currentTime = current.getTime();
            let updatedTime = new Date(currentTime + h * 60 * 60 * 1000);
            return updatedTime
        }
        function addDays (current, days) {
            let currentTime = current.getTime();
            let updatedTime = new Date(currentTime + days * 24 * 60 * 60 * 1000);
            return updatedTime
        };
        function addMinutes (current, m) {
            let currentTime = current.getTime();
            let updatedTime = new Date(currentTime + m * 60 * 1000);
            return updatedTime
        };

        vm.cardWidget = {
            title: "",
            rawData: {},
            chartData: [],
            apprData: [],
            primaryValue: 0,
            secondaryValue: 0,
            primaryHours: 0,
            primaryMinutes: 0,
            primarySeconds: 0,
            secondaryHours: 0,
            secondaryMinutes: 0,
            secondarySeconds: 0,
            health: "good",
            detectionType: 0,
            convertToHms: false,
            round: true,
            showSeconds: false,
            secondsOnly: false,
            unitText: '',
            percentage: false,
            emptyDataText: "No Data",
            badDetectionText: "I Need More Detection!",
            yAxisLabel: "",
            hasNeededDetection: true,
            hasData: false,
            secondaryLabel: "",
            primaryLabel: "",
            showSecondary: false,
            help: {
                metricInfo: "",
                calcInfo: "",
                extraInfo: "",
                downloadLink: "",
            },
            aggregationType: "Sum",
        }

        vm.approachChart = {
            dataset: [],
            //chart specific options
            options: {
                intersect: false,
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 1
                },
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                        },
                        gridLines: {
                            display: false
                        },
                    }],
                    yAxes: [{
                        display: false,
                        scaleLabel: {
                            display: false,
                        },
                        ticks: {
                            display: false,
                        }
                    }],
                },
                tooltips: {
                    enabled: false,
                    intersect: false,

                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var percentHtml = "";
                            var valueText = "";
                            var header = "";
                            if (vm.additionalChartData[tooltipModel.dataPoints[0].index].hasNeededDetection) {
                                var valuetoShow;
                                if (vm.cardWidget.convertToHms) {
                                    valuetoShow = vm.secondsToHmsString(Number(tooltipModel.dataPoints[0].value));
                                }
                                else if (vm.cardWidget.secondsOnly) {
                                    valuetoShow = Number(tooltipModel.dataPoints[0].value) + " seconds";
                                }
                                else {
                                    valuetoShow = (Number(tooltipModel.dataPoints[0].value) ? Number(tooltipModel.dataPoints[0].value).toFixed(2) : 0);
                                    if (vm.cardWidget.round) {
                                        valuetoShow = Math.round(valuetoShow);
                                    }
                                    valuetoShow = valuetoShow.toLocaleString('en');
                                }

                                valueText = "<tr>" +
                                    "<td class='key'>" + vm.cardWidget.title + ': ' + "</td>" +
                                    "<td class='x-value'><strong>" + valuetoShow + "</strong></td>" +
                                    // add to value description of that value / check cardWidget for properties
                                    "</tr>";

                                if (vm.additionalChartData[tooltipModel.dataPoints[0].index].percentage != -1) {
                                    percentHtml = vm.cardWidget.aggregationType !== 'Average' ? "<tr>" +
                                        "<td class='key'>" + 'Percent of total: ' + "</td>" +
                                        "<td class='x-value'><strong>" + vm.additionalChartData[tooltipModel.dataPoints[0].index].percentage + "%</strong></td>" +
                                        "</tr>" : '';
                                }
                                header = "<thead>" +
                                    "<tr>" +
                                    "<td class='key'><strong>" + vm.cardWidget.title + "</strong></td>" +
                                    "</tr>" +
                                    "</thead>";
                            } else {
                                header = "<thead>" +
                                    "<tr>" +
                                    "<td class='key' colspan='2'><strong>This approach requires more detection to calculate " + vm.cardWidget.title + "</strong></td>" +
                                    "</tr>" +
                                    "</thead>"
                            }
                            var rows =
                                "<tr>" +
                                "<td class='key'>" + 'Approach:' + "</td>" +
                                "<td class='x-value'>" + vm.additionalChartData[tooltipModel.dataPoints[0].index].fullName + "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td class='key'>" + 'Direction: ' + "</td>" +
                                "<td class='x-value'>" + vm.additionalChartData[tooltipModel.dataPoints[0].index].description + "</td>" +
                                "</tr>" +
                                valueText + percentHtml;

                            var innerHtml = "<table>" +
                                header +
                                "<tbody>" +
                                rows +
                                "</tbody>" +
                                "</table>";

                            var tableRoot = tooltipEl.querySelector('table');
                            var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                            tableStyle += 'color: rgba(255,255,255, 1);';
                            tableStyle += 'padding: 5px 10px;';

                            tableRoot.style = tableStyle;
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                        // if (position.left + window.pageXOffset + tooltipModel.caretX + 400 > window.innerWidth) {
                        //     tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth + 'px';
                        // }
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                legend: {
                    display: false,
                },
                plugins: {
                    datalabels: {
                        clip: true,
                        display: true,
                        anchor: 'end',
                        align: 'end',
                        color: '#000',
                        formatter: function (d) {

                            if (vm.cardWidget.convertToHms && !vm.cardWidget.showSeconds) {
                                var values = vm.secondsToHms(d);
                                return (values[0] + (values[1] / 60)).toFixed(1);
                            }
                            if (vm.cardWidget.round) {
                                return d3.format(',.0f')(d);

                            }
                            return d3.format(',.2f')(d);

                        },
                    },
                    colorschemes: {

                        scheme: 'brewer.Paired12'

                    }
                },
            },
            chart: {},
            api: undefined,
            fullLabels: [],
            onApiInit: function (apiObj) {
                this.api = apiObj;
                vm.setData();
            },
            onDestroy: function () {
                this.api = {};
                this.chart = {};
                this.dataset = {};
            }
        };

        vm.perfChartWidget = {
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.approachVolumeChartWidget.chartDef);
                clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            isRendered: false,
            chartArray: {},
            processChartData: function () {
                var thisChart = vm.perfChartWidget.chartArray[0];
                if (thisChart && thisChart.api && thisChart.api.render) {
                    thisChart.dataset = vm.cardWidget.chartData;
                    thisChart.api.render(undefined, thisChart.dataset, thisChart.chartOptions);
                }
                else {
                    var charts = [];
                    charts.push({
                        api: {},
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        isRendered: false,
                        type: 'LineWithLine',
                        dataset: vm.cardWidget.chartData,
                        hover: {
                            mode: 'nearest'
                        },
                        chartOptions: {
                            useCrosshair: true,
                            animation: true,
                            scaleShowLabels: false,
                            tooltips: {
                                enabled: false,
                                intersect: false,

                                custom: function (tooltipModel) {
                                    // Tooltip Element
                                    var tooltipEl = document.getElementById('chartjs-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = 0;
                                        return;
                                    }

                                    // Set caret Position
                                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                                    if (tooltipModel.yAlign) {
                                        tooltipEl.classList.add(tooltipModel.yAlign);
                                    } else {
                                        tooltipEl.classList.add('no-transform');
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                        var titleLines = vm.cardWidget.title;

                                        var valuetoShow;
                                        if (vm.cardWidget.convertToHms) {
                                            valuetoShow = vm.secondsToHmsString(Number(tooltipModel.dataPoints[0].value));
                                            if (valuetoShow == "")
                                                valuetoShow = "0";
                                        }
                                        else if (vm.cardWidget.secondsOnly) {
                                            valuetoShow = Number(tooltipModel.dataPoints[0].value) + " seconds";
                                        }
                                        else {
                                            valuetoShow = (Number(tooltipModel.dataPoints[0].value) ? Number(tooltipModel.dataPoints[0].value).toFixed(2) : 0);
                                            if (vm.cardWidget.round) {
                                                valuetoShow = Math.round(valuetoShow);
                                            }
                                            valuetoShow = valuetoShow.toLocaleString('en');
                                        }

                                        var start = new Date(tooltipModel.dataPoints[0].label);
                                        var dataPointStartEnd = vm.dataPointsWithStartEndTime.filter(x => {
                                            return x.start.getTime() === start.getTime()
                                        });

                                        let endFromStart = new Date(start);
                                        endFromStart = addHours(new Date(endFromStart), 1);
                                        var end = dataPointStartEnd.length > 0 ? dataPointStartEnd[0].end : endFromStart;

                                        var binLabel = vm.cardWidget.aggregationType == "Average" ? "Average " + vm.cardWidget.title + " in Bin: " : vm.cardWidget.title + " in Bin: ";

                                        var innerHtml = '<thead>';
                                        innerHtml += '<tr><th>' + titleLines + '</th></tr>';
                                        innerHtml += '</thead><tbody>';

                                        innerHtml += "<tr>" +
                                            "<td class='key'>" + 'Bin Start: ' + "</td>" +
                                            "<td class='x-value'>" + moment(start).format('hh:mm A') + " - " + moment(start).format('dddd, MMMM D, YYYY') + "</td>" +
                                            "</tr>" +
                                            "<tr>" +
                                            "<td class='key'>" + 'Bin End' + "</td>" +
                                            "<td class='x-value'>" + moment(end).format('hh:mm A') + " - " + moment(end).format('dddd, MMMM D, YYYY') + "</td>" +
                                            "</tr>" +
                                            "<tr>" +
                                            "<td class='key'>" + binLabel + "</td>" +
                                            "<td class='x-value'><strong>" + valuetoShow + "</strong></td>" +
                                            "</tr>";

                                        innerHtml += '</tbody>';

                                        var tableRoot = tooltipEl.querySelector('table');
                                        var tableStyle = 'background-color: rgba(0,0,0,0.9);';
                                        tableStyle += 'color: rgba(255,255,255, 1);';
                                        tableStyle += 'padding: 5px 10px;';

                                        tableRoot.style = tableStyle;
                                        tableRoot.innerHTML = innerHtml;
                                    }

                                    // `this` will be the overall tooltip
                                    var position = this._chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.position = 'absolute';
                                    // if (position.left + window.pageXOffset + tooltipModel.caretX + position.width + 80 < window.innerWidth) {
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px';
                                    if (position.left + window.pageXOffset + tooltipModel.caretX + 10 + 388 > window.innerWidth) {
                                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 - 388 + 'px';
                                    }
                                    //                                } 
                                    // else {
                                    //     tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - position.width - 100 + 'px';
                                    // }
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            },
                            legend: {
                                display: false,
                            },
                            scales: {
                                xAxes: [{
                                    display: $scope.showAxes,
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    ticks: {
                                        display: $scope.showAxes
                                    },
                                    time: {
                                        unit: 'day'
                                    },
                                }],
                                yAxes: [{
                                    display: $scope.showAxes,
                                    ticks: {
                                        display: $scope.showAxes
                                    },
                                    id: '1',
                                    position: 'left',

                                }
                                ]
                            },
                        }
                    });

                    //set our chart array back to our binding object
                    vm.perfChartWidget.chartArray = charts;
                }

            }
        }


        function changeChart(index) {
            vm.mainSelectionIndex = index;
        }

        function update(data, inputSignal) {
            if (vm) {
                if (inputSignal) {
                    vm.signal = inputSignal;
                }
                vm.cardWidget.rawData = data;
                vm.cardWidget.hasNeededDetection = data.hasNeededDetection;
                vm.cardWidget.hasData = data.hasNeededDetection ? data.signalData.bins.length > 0 : false;
                if (vm.cardWidget.hasData && vm.cardWidget.hasNeededDetection)
                    vm.setData();
                if (!vm.cardWidget.hasNeededDetection) {
                    switch (vm.metricType) {
                        case signalPerformanceService.metricTypesEnum.Volume:
                            vm.cardWidget.title = 'Approach Volume';
                            break;
                        case signalPerformanceService.metricTypesEnum.SplitFails:
                            vm.cardWidget.title = 'Split Failures';
                            break;
                        case signalPerformanceService.metricTypesEnum.PlatoonRatio:
                            vm.cardWidget.title = 'Platoon Ratio';
                            break;
                        case signalPerformanceService.metricTypesEnum.PedCount:
                            vm.cardWidget.title = 'Ped Actuations';
                            break;
                        case signalPerformanceService.metricTypesEnum.PedDelay:
                            vm.cardWidget.title = 'Ped Delay';
                            break;
                        case signalPerformanceService.metricTypesEnum.TotalDelay:
                            vm.cardWidget.title = 'Total Delay';
                            break;
                        case signalPerformanceService.metricTypesEnum.DelayPerVehicle:
                            vm.cardWidget.title = 'Delay per Vehicle';
                            break;
                        case signalPerformanceService.metricTypesEnum.ArrivalsOnGreen:
                            vm.cardWidget.title = 'Arrivals on Green';
                            break;
                        case signalPerformanceService.metricTypesEnum.QueueLength:
                            vm.cardWidget.title = 'Queue Length';
                            break;

                        case signalPerformanceService.metricTypesEnum.QueueSpillback:
                            vm.cardWidget.title = 'Queue Spillback';
                            break;
                    }
                }
            }
        }


        function showHelpAlert(ev) {
            helpService.showHelpDialog(vm.translateLocationForMetric, ev, "How We Calculate It");
        };

        function expand() {
            var chartWidths = 0;
            var closing = false;
            if (vm.widthClass == "width-735") {
                vm.widthClass = "width-350";
                chartWidths = 350;
                closing = true;
            }
            else if (vm.widthClass == "width-350") {
                vm.widthClass = "width-735";
                chartWidths = 735;
            }

            vm.isExpanded = !closing;
        }

        function setData() {
            var i18TopLocation = "PERFORMANCE_METRIC_CARD.";
            var translateLocation = i18TopLocation + vm.metricType;

            //set data
            var signalData = vm.cardWidget.rawData.signalData;
            //switch for specific chart customizations
            switch (vm.metricType) {
                case signalPerformanceService.metricTypesEnum.Volume:
                    vm.cardWidget.showSecondary = false;
                    if (vm.cardWidget.rawData.detectionTypeId != 1) {
                        vm.cardWidget.detectionType = vm.cardWidget.rawData.detectionTypeId;
                    }
                    switch (vm.cardWidget.detectionType) {
                        case 2:
                            translateLocation += ".APPROACH_DETECTION";
                            break;
                        default:
                        case 4:
                            translateLocation += ".TMC_DETECTION";
                            break;
                    }
                    if (signalData.totalVolume <= 0) {
                        vm.cardWidget.title = 'Approach Volume'
                        vm.cardWidget.hasData = false;
                        return;
                    }
                    vm.setTotalsAndBinData("totalVolume", "", "totalVolume");
                    break;
                case signalPerformanceService.metricTypesEnum.SplitFails:
                    vm.cardWidget.showSecondary = false;
                    if (signalData.totalSplitFails <= 0) {
                        vm.cardWidget.title = 'Split Failures'
                        vm.cardWidget.hasData = false;
                        return;
                    }
                    vm.setTotalsAndBinData("totalSplitFails", "", "totalSplitFails");
                    break;
                case signalPerformanceService.metricTypesEnum.PlatoonRatio:
                    vm.cardWidget.round = false;
                    vm.cardWidget.showSecondary = false;
                    vm.cardWidget.aggregationType = "Average";
                    if (signalData.weightedPlatoonRatio <= 0) {
                        vm.cardWidget.title = 'Platoon Ratio'
                        vm.cardWidget.hasData = false;
                        return;
                    }
                    vm.setTotalsAndBinData("weightedPlatoonRatio", "", "platoonRatio", "platoonRatio");
                    break;
                case signalPerformanceService.metricTypesEnum.PedCount:
                    if (signalData.totalPedCounts <= 0) {
                        vm.cardWidget.hasData = false;
                        vm.cardWidget.title = 'Ped Actuations';
                        return;
                    }
                    vm.cardWidget.showSecondary = false;
                    vm.setTotalsAndBinData("totalPedCounts", "", "totalPedCounts");
                    break;
                case signalPerformanceService.metricTypesEnum.PedDelay:
                    vm.cardWidget.convertToHms = true;
                    vm.cardWidget.showSecondsSecondary = true;
                    vm.cardWidget.showSecondary = true;
                    if (signalData.totalPedDelay <= 0) {
                        vm.cardWidget.hasData = false;
                        vm.cardWidget.title = 'Ped Delay';
                        return;
                    }
                    vm.setTotalsAndBinData("totalPedDelay", "delayPerPed", "delayPerPed");
                    break;
                case signalPerformanceService.metricTypesEnum.TotalDelay:
                    vm.cardWidget.convertToHms = true;
                    vm.cardWidget.showSecondary = false;
                    if (signalData.totalDelay <= 0) {
                        vm.cardWidget.hasData = false;
                        vm.cardWidget.title = 'Total Delay';
                        return;
                    }
                    vm.setTotalsAndBinData("totalDelay", "", "totalDelay");

                    break;
                case signalPerformanceService.metricTypesEnum.DelayPerVehicle:
                    vm.cardWidget.convertToHms = true;
                    vm.cardWidget.showSeconds = true;
                    vm.cardWidget.showSecondary = false;
                    vm.cardWidget.round = false;
                    vm.cardWidget.aggregationType = "Average";
                    if (signalData.delayPerVehicle <= 0) {
                        vm.cardWidget.title = 'Delay per Vehicle';
                        vm.cardWidget.hasData = false;
                        return;
                    }
                    vm.setTotalsAndBinData("delayPerVehicle", "", "delayPerVehicle");
                    break;
                case signalPerformanceService.metricTypesEnum.ArrivalsOnGreen:
                    vm.cardWidget.round = false;
                    vm.cardWidget.percentage = true;
                    vm.cardWidget.aggregationType = "Average";
                    if (signalData.percentArrivalsOnGreen <= 0) {
                        vm.cardWidget.title = 'Arrivals on Green';
                        vm.cardWidget.hasData = false;
                        return;
                    }
                    vm.setTotalsAndBinData("percentArrivalsOnGreen", "", "percentArrivalsOnGreen");
                    break;
                case signalPerformanceService.metricTypesEnum.QueueLength:
                    vm.cardWidget.round = false;
                    vm.cardWidget.unitText = "(ft)";
                    vm.cardWidget.aggregationType = "Average";
                    if (signalData.totalQueueLength <= 0) {
                        vm.cardWidget.title = 'Queue Length'
                        vm.cardWidget.hasData = false;
                        return;
                    }
                    vm.setTotalsAndBinData("totalQueueLength", "", "totalQueueLength");
                    break;

                case signalPerformanceService.metricTypesEnum.QueueSpillback:
                    if (signalData.totalQueueSpillbackCount <= 0) {
                        vm.cardWidget.title = 'Queue Spillback'
                        vm.cardWidget.hasData = false;
                        return;
                    }
                    vm.setTotalsAndBinData("totalQueueSpillbackCount", "", "totalQueueSpillbackCount");
                    break;
            }

            //set chart text and titles
            vm.translateLocationForMetric = translateLocation;
            $translate(translateLocation + '.TITLE')
                .then(function (translatedValue) {
                    if (translatedValue)
                        vm.cardWidget.title = translatedValue;
                });

            $translate(translateLocation + '.SECOND_TITLE')
                .then(function (translatedValue) {
                    if (translatedValue)
                        vm.cardWidget.emptyDataText = translatedValue;
                });
            $translate(translateLocation + '.Y_AXIS_LABEL')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        vm.cardWidget.yAxisLabel = translatedValue;
                    }
                });

            $translate(translateLocation + '.SECONDARY_LABEL')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        vm.cardWidget.secondaryLabel = translatedValue;
                    }
                });
            $translate(translateLocation + '.PRIMARY_LABEL')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        vm.cardWidget.primaryLabel = translatedValue;
                    }
                });

            if (vm.cardWidget.round) {
                vm.cardWidget.primaryValue = Math.round(vm.cardWidget.primaryValue).toLocaleString('en');
                vm.cardWidget.secondaryValue = Math.round(vm.cardWidget.secondaryValue).toLocaleString('en');
            }
            else {
                vm.cardWidget.primaryValue = vm.cardWidget.primaryValue.toLocaleString('en');
                vm.cardWidget.secondaryValue = vm.cardWidget.secondaryValue.toLocaleString('en');
            }

            vm.perfChartWidget.processChartData();
        }

        function setTotalsAndBinData(primaryProperty, secondaryProperty, chartProperty, approachChartProperty) {
            if (!approachChartProperty)
                approachChartProperty = chartProperty;

            var signalData = vm.cardWidget.rawData.signalData;
            var approachData = vm.cardWidget.rawData.approachData;

            if (vm.cardWidget.convertToHms) {
                vm.convertDataToHms(signalData[primaryProperty], signalData[secondaryProperty]);
            }
            else {
                vm.cardWidget.primaryValue = signalData[primaryProperty];

                if (vm.cardWidget.showSecondary)
                    vm.cardWidget.secondaryValue = signalData[secondaryProperty];
            }

            //load bin data
            var dataPoints = [], apprDataValues = [];

            vm.dataPointsWithStartEndTime = [];
            // Set line chart data

            // let's create also datapoints for bins in betweens
            // let's calculate bins betwwen start and end 

            var options = vm.searchBarService.getSearchOptions();
            vm.timeOptions = options.timeOptions;
            var currentBin = vm.timeOptions.bin;

            var binSize = 1;
            switch (currentBin) {
                case "Day":
                    vm.binInMilliseconds = 24 * 60 * 60 * 1000;
                    vm.hoursInBetween = ((new Date(signalData.bins[0].end)).getTime() - (new Date(signalData.bins[0].start)).getTime()) / (60 * 60 * 1000);
                    binSize = 1;
                    break;
                case "FifteenMinute":
                    vm.binInMilliseconds = 15 * 60 * 1000;
                    binSize = 15;
                    break;
                case "ThirtyMinute":
                    vm.binInMilliseconds = 30 * 60 * 1000;
                    binSize = 30;
                    break;
                case "Hour":
                default:
                    vm.binInMilliseconds = 60 * 60 * 1000;
                    binSize = 1;
                    break;
            }

            let binsBetweenStartEnd = ((new Date(signalData.bins[signalData.bins.length - 1].start)).getTime() - (new Date(signalData.bins[0].start)).getTime()) / vm.binInMilliseconds;

            let i = -binSize;
            while (i <= (binsBetweenStartEnd * binSize + binSize)) {

                let xDate = null;
                let xDate2 = null;
                xDate = currentBin == "Day" ? addDays(new Date(signalData.bins[0].start), i) : currentBin == "Hour" ? addHours(new Date(signalData.bins[0].start), i) : currentBin.includes("Minute") ? addMinutes(new Date(signalData.bins[0].start), i) : null;
                xDate2 = currentBin == "Day" ? addHours(new Date(xDate), vm.hoursInBetween) : currentBin == "Hour" ? addHours(new Date(xDate), binSize) : currentBin.includes("Minute") ? addMinutes(new Date(xDate), binSize) : null;
                let yVal = 0;
                let yVal2 = "N/A";

                signalData.bins.forEach(function (bin) {
                    if ((new Date(bin.start)).getTime() == new Date(xDate).getTime()) {
                        yVal = bin[chartProperty];
                        yVal2 = bin[chartProperty];
                    }
                })

                dataPoints.push({
                    x: xDate,
                    y: yVal
                });

                vm.dataPointsWithStartEndTime.push({
                    start: xDate,
                    end: xDate2,
                    y: yVal2
                });

                i = i + binSize;
            }

            vm.cardWidget.chartData = [{
                data: dataPoints,
                label: "Total",
                metric: vm.cardWidget.title,
                type: 'LineWithLine',
                backgroundColor: '#8FBBD9',
                borderColor: '#3283BB',
                pointRadius: 1,
                pointHoverRadius: 4,
                yAxis: 1,
            }];


            // set bar chart data
            var barChartLabels = [];
            var barChartDataset = [{
                showLines: false,
                data: [],
                backgroundColor: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc', '#e5d8bd', '#fddaec', '#e31a1c', '#1f78b4', '#33a02c', '#f2f2f2']
            }];
            vm.additionalChartData = [];
            //set data into approach bar chart
            approachData.forEach(function (appr) {
                var apprData = appr.data.binsContainer;

                var apprTotal = 0;
                var percentTotal = 0;

                apprTotal = apprData[approachChartProperty];
                if (signalData[approachChartProperty] > 0) {
                    percentTotal = Math.round((apprTotal / signalData[approachChartProperty]) * 100);
                }

                apprDataValues.push({
                    label: appr.directionAbbv,
                    value: apprTotal,
                    percentage: percentTotal,
                    description: appr.direction,
                    fullName: appr.description,
                    hasNeededDetection: appr.data.hasNeededDetection
                });

                barChartLabels.push(appr.directionAbbv);
                barChartDataset[0].data.push(apprTotal);
            });

            if (vm.approachChart.api) {
                vm.approachChart.options.scales.yAxes[0].ticks.max = Math.max(...barChartDataset[0].data) * 1.3
                vm.approachChart.chart = vm.approachChart.api.render(barChartLabels, barChartDataset, vm.approachChart.options);
            }

        }

        function convertDataToHms(primary, secondary) {
            var totalTimes = vm.secondsToHms(primary);
            var avgTimes = vm.secondsToHms(secondary);

            vm.cardWidget.primaryHours = totalTimes[0].toLocaleString();
            vm.cardWidget.primaryHoursNumber = totalTimes[0];
            vm.cardWidget.primaryMinutes = totalTimes[1];
            vm.cardWidget.primarySeconds = totalTimes[2];
            vm.cardWidget.secondaryHours = avgTimes[0].toLocaleString();
            vm.cardWidget.secondaryHoursNumber = avgTimes[0];
            vm.cardWidget.secondaryMinutes = avgTimes[1];
            vm.cardWidget.secondarySeconds = avgTimes[2];
            if (vm.cardWidget.primaryHours <= 0 && !vm.cardWidget.secondsOnly) {
                vm.cardWidget.showSeconds = true;
            }
        }

        function secondsToHms(d) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);
            if (s <= 0) s = Number((d % 3600 % 60).toFixed(2))
            return [h, m, s];
        }

        function secondsToHmsString(d) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);
            if (s <= 0) s = Number((d % 3600 % 60).toFixed(2))

            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            return hDisplay + mDisplay + sDisplay;
        }

        function createCharts() {
            return {
                barChart: {
                    config: {
                        refreshDataOnly: true,
                        deepWatchDataDepth: 0
                    },
                    options: {
                        chart: {
                            type: 'discreteBarChart',
                            height: 150,
                            width: 345,
                            duration: 350,
                            margin: {
                                top: 10,
                                right: 10,
                                bottom: 20,
                                left: 0
                            },
                            x: function (d) {
                                return d.label;
                            },
                            y: function (d) {
                                return d.value;
                            },
                            valueFormat: function (d) {

                                if (vm.cardWidget.convertToHms && !vm.cardWidget.showSeconds) {
                                    var values = vm.secondsToHms(d);
                                    return (values[0] + (values[1] / 60)).toFixed(1);
                                }
                                if (vm.cardWidget.round) {
                                    return d3.format(',.0f')(d);

                                }
                                return d3.format(',.2f')(d);

                            },
                            showValues: true,
                            showXAxis: true,
                            showYAxis: false,
                            showLabels: true,
                            tooltip: {
                                contentGenerator: function (e) {
                                    var series = e.series[0];
                                    if (series.value === null) {
                                        return
                                    };

                                    var percentHtml = "";
                                    var valueText = "";
                                    var header = "";
                                    if (e.data.hasNeededDetection) {
                                        var valuetoShow;
                                        if (vm.cardWidget.convertToHms) {
                                            valuetoShow = vm.secondsToHmsString(e.data.value);
                                        }
                                        else if (vm.cardWidget.secondsOnly) {
                                            valuetoShow = e.data.value + " seconds";
                                        }
                                        else {
                                            valuetoShow = e.data.value.toLocaleString('en');
                                        }
                                        valueText = "<tr>" +
                                            "<td class='key'>" + vm.cardWidget.title + ': ' + "</td>" +
                                            "<td class='x-value'><strong>" + valuetoShow + "</strong></td>" +
                                            // add to value description of that value / check cardWidget for properties
                                            "</tr>";
                                        if (e.data.percentage != -1) {
                                            percentHtml = vm.cardWidget.aggregationType !== 'Average' ? "<tr>" +
                                                "<td class='key'>" + 'Percent of total: ' + "</td>" +
                                                "<td class='x-value'><strong>" + e.data.percentage + "%</strong></td>" +
                                                "</tr>" : '';
                                        }
                                        header = "<thead>" +
                                            "<tr>" +
                                            "<td class='key'><strong>" + vm.cardWidget.title + "</strong></td>" +
                                            "</tr>" +
                                            "</thead>";
                                    }
                                    else {
                                        header = "<thead>" +
                                            "<tr>" +
                                            "<td class='key' colspan='2'><strong>This approach requires more detection to calculate " + vm.cardWidget.title + "</strong></td>" +
                                            "</tr>" +
                                            "</thead>"
                                    }
                                    var rows =
                                        "<tr>" +
                                        "<td class='key'>" + 'Approach:' + "</td>" +
                                        "<td class='x-value'>" + e.data.fullName + "</td>" +
                                        "</tr>" +
                                        "<tr>" +
                                        "<td class='key'>" + 'Direction: ' + "</td>" +
                                        "<td class='x-value'>" + e.data.description + "</td>" +
                                        "</tr>" +
                                        valueText + percentHtml;


                                    return "<table>" +
                                        header +
                                        "<tbody>" +
                                        rows +
                                        "</tbody>" +
                                        "</table>";
                                }
                            }
                        }
                    },
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm.cardWidget = null;
            vm = null;
            if ($scope.onApiDestroy) {
                $scope.onApiDestroy();
            }
            //if (cleanupQuery)
            //    cleanupQuery();
        });

    }
}());