(function () {

    "use strict";

    angular
        .module("app.spm.core")
        .service("signalService", signalService);

    function signalService(signalsResource, $state, $rootScope, $mdDialog, $mdToast, $q) {

        const mapOfSignals = new Map();

        const setOfCustomIDs = new Set();
        const mapOfCustomIDs = new Map();

        /*SIGNALS*/
        function getAllSignals() {
            return signalsResource.signals.getAllSignals(function (data) {
                return data;
            }).$promise;
        }

        function checkSignalExists(customID) {
            // Provide O(1) lookup of custom IDs
            return setOfCustomIDs.has(customID)
        }

        function getAllSignalsWithApproaches() {
            // make dic and put all object then function 
            if (mapOfSignals && mapOfSignals.size > 0) {
                var respObj = {
                    signals: []
                }
                respObj.signals = Array.from(mapOfSignals.values());
                return $q.when(respObj);
            }
            return signalsResource.signals.getAllSignalsWithApproaches(function (data) {
                data.signals.forEach(function (signal) {

                    signal.signalName = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
                    mapOfSignals.set(signal.signalID, signal);
                    setOfCustomIDs.add(signal.customID);
                    mapOfCustomIDs.set(signal.signalID, signal.customID)
                })
                return data;
            }).$promise;
        }

        function getSignalDescription(customId, primaryName, secondaryName) {

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '') {
                res += " - " + secondaryName;
            }

            return res;
        }

        // TODO: remove this and replace with getSignalByID
        function getSignalByIDFromDict(signalId) {
            return mapOfSignals.get(signalId);
        }

        function getSignalById(id) {
            // make dic and put all object then function 
            if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(id)) {
                return $q.when(mapOfSignals.get(id));
            }
            return signalsResource.signals.getSignalById({ id: id },
                function (data) {
                    return data;
                }).$promise;
        }

        function createSignal(signal) {
            return signalsResource.signals.create(signal,
                function (data) {
                    showSignalSavedToast();
                    if (mapOfSignals && !mapOfSignals.has(data.signalID)) {
                        data.signalName = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                        mapOfSignals.set(data.signalID, data);
                        setOfCustomIDs.add(data.customID);
                        mapOfCustomIDs.set(data.signalID, data.customID);
                        notifyChange('signalConfiguration');
                    }
                    // broadcast signal configuration change
                    return data;
                }).$promise;
        }

        function updateSignal(signal, callback, isSignalDraged) {
            // Appending dialog to document.body to cover sidenav in docs app
            var signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);
            var confirmText = "Are you sure you want to save changes for " +
                signalDescription + " ?";

            var content = ''
            isSignalDraged ? content = 'Latitude and Longitude are changed. This action cannot be undone.' : content = 'This action cannot be undone';

            var confirm = $mdDialog.confirm()
                .title(confirmText)
                .textContent(content)
                .ok('Yes save changes')
                .cancel('Cancel');
            var selectedChoice = $mdDialog.show(confirm)
                .then(function () {
                    updateSignalNoPrompt(signal).then(function () {
                        if (callback)
                            callback();
                        getAllSignalsWithApproaches();
                    });
                    return true
                }, function () { }
                )
            return selectedChoice
        }

        function updateSignalNoPrompt(signal) {
            return signalsResource.signals.update(signal,
                function (data) {
                    if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(data.signalID)) {
                        // Maintains state of old custom ID
                        const oldCustomID = mapOfCustomIDs.get(data.signalID);
                        if (oldCustomID !== data.customID) {
                            setOfCustomIDs.delete(oldCustomID);
                            setOfCustomIDs.add(data.customID);
                            mapOfCustomIDs.set(data.signalID, data.customID);
                        }
                        var signalDescription = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                        data.signalName = signalDescription;
                        mapOfSignals.set(data.signalID, data);
                        notifyChange('signalConfiguration');
                    }
                    showSignalSavedToast();
                    return data;
                }).$promise;
        }

        function removeSignal(signal, callback) {
            // Appending dialog to document.body to cover sidenav in docs app
            var signalDescription = getSignalDescription(signal.customID, signal.primaryName, signal.secondaryName);

            var confirmText = "Are you sure you want to delete " +
                signalDescription + "?";

            var confirm = $mdDialog.confirm()
                .title(confirmText)
                .textContent('This action cannot be undone')
                .ok('Yes delete this signal')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    removeSignalNoPrompt(signal.signalID, signal.customID).then(function () {
                        if (callback)
                            callback();
                    });
                }, function () { }
                );
        }

        function removeSignalNoPrompt(id, customID) {
            return signalsResource.signals.remove({ id: id },
                function (data) {
                    if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(id)) {
                        setOfCustomIDs.delete(customID);
                        mapOfCustomIDs.delete(id);
                        mapOfSignals.delete(id);
                        notifyChange('signalRemoved', id);
                    }
                    showSignalDeletedToast();
                    return data;
                }).$promise;
        }

        function querySignals(queryData) {
            return signalsResource.signals.query(queryData, function (data) {
                return data;
            }).$promise;
        }

        function getSignalLatency(queryData) {
            return signalsResource.signals.getLatency(queryData, function (data) {
                return data;
            }).$promise;
        }

        /*DETECTORS*/
        function getDetectors(queryData) {
            return signalsResource.detectors.query(queryData, function (data) {
                return data;
            }).$promise;
        }

        function deleteDetector(detector, callback) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirmText = "Are you sure you want to delete " +
                detector.detectorID +
                ": " +
                detector.description;

            var confirm = $mdDialog.confirm()
                .title(confirmText)
                .textContent('This action cannot be undone')
                .ok('Yes delete this detector')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                deleteDetectorNoPrompt(detector.id).then(function () {
                    if (callback)
                        callback();
                });
            }, function () { });
        }

        function deleteDetectorNoPrompt(id) {
            return signalsResource.detectors.remove({ id: id },
                function (data) {
                    showDetectorDeletedToast();
                    if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(data.signalID)) {
                        var signalDescription = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                        data.signalName = signalDescription;
                        mapOfSignals.set(data.signalID, data);
                        notifyChange('signalConfiguration', data.signalID);
                    }
                    return data;
                }).$promise;
        }

        function createDetector(detector) {
            return signalsResource.detectors.create(detector,
                function (data) {
                    showDetectorSavedToast();
                    if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(data.signalID)) {
                        var signalDescription = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                        data.signalName = signalDescription;
                        mapOfSignals.set(data.signalID, data);
                        notifyChange('detectorConfiguration');
                    }
                    return data;
                }).$promise;
        }

        function updateDetector(detector, callback) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirmText = "Are you sure you want to save changes for " +
                detector.detectorID +
                ": " +
                detector.description;

            var confirm = $mdDialog.confirm()
                .title(confirmText)
                .textContent('This action cannot be undone')
                .ok('Yes save changes')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    updateDetectorNoPrompt(detector).then(function () {
                        if (callback)
                            callback();
                    });
                }, function () { }
                );
        }

        function updateDetectorNoPrompt(detector) {
            return signalsResource.detectors.update(detector,
                function (data) {
                    showDetectorSavedToast();
                    if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(data.signalID)) {
                        mapOfSignals.set(data.signalID, data);
                        notifyChange('detectorConfiguration');
                    }
                    return data;
                }).$promise;
        }

        /*APPROACHES*/
        function getApproaches(queryData) {
            return signalsResource.approaches.query(queryData, function (data) {
                return data;
            }).$promise;
        }

        function deleteApproach(approach, callback) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirmText = "Are you sure you want to delete " +
                approach.approachID +
                ": " +
                approach.description;

            var confirm = $mdDialog.confirm()
                .title(confirmText)
                .textContent('This action cannot be undone')
                .ok('Yes delete this approach')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                deleteApproachNoPrompt(approach.approachID).then(function () {
                    if (callback)
                        callback();
                });
            }, function () {
            });
        }

        // TODO: get signal returned when deleting the approach
        function deleteApproachNoPrompt(id) {
            return signalsResource.approaches.remove({ id: id },
                function (data) {
                    showApproachDeletedToast();
                    if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(data.signalID)) {
                        data.signalName = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                        mapOfSignals.set(data.signalID, data);
                        notifyChange('signalConfiguration', data.signalID);
                    }
                    return data;
                }).$promise;
        }

        function createApproach(approach) {
            return signalsResource.approaches.create(approach, function (data) {
                showApproachSavedToast();
                if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(data.signalID)) {
                    data.signalName = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                    mapOfSignals.set(data.signalID, data);
                    notifyChange('approachConfiguration');
                }
                return data;
            }).$promise;
        }

        function updateApproach(approach, callback) {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirmText = "Are you sure you want to save changes for " +
                approach.approachID +
                ": " +
                approach.description;

            var confirm = $mdDialog.confirm()
                .title(confirmText)
                .textContent('This action cannot be undone')
                .ok('Yes save changes')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    updateApproachNoPrompt(approach).then(function () {
                        if (callback)
                            callback();
                        showApproachSavedToast();
                    });
                }, function () { }
                );
        }

        function showApproachSavedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Approach saved successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }

        function showApproachDeletedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Approach deleted successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }

        function showDetectorSavedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Detector saved successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }

        function showDetectorDeletedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Detector deleted successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }


        function showSignalSavedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Signal saved successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }

        function showSignalDeletedToast() {
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#content'))
                    .textContent('Signal deleted successfully')
                    .position("top right")
                    .hideDelay(3000)
            );
        }

        function getAutomatedConfigResults(queryData) {
            return signalsResource.automatedConfiguration.getAutomatedConfigurationResults(queryData, function (data) {
                return data;
            }).$promise;
        }

        function updateApproachNoPrompt(approach) {
            return signalsResource.approaches.update(approach, function (data) {
                if (mapOfSignals && mapOfSignals.size > 0 && mapOfSignals.has(data.signalID)) {
                    mapOfSignals.set(data.signalID, data);
                    notifyChange('approachConfiguration');
                }
                return data;
            }).$promise;
        }

        function notifyChange(typeOfChange, props) {
            $rootScope.$emit('signal-configuration-change', { type: typeOfChange, props: props });
        }

        function signalStatusChanged(id) {
            notifyChange('signalStatusChanged', id)
        }


        return {
            signalStatusChanged: signalStatusChanged,
            getAllSignals: getAllSignals,
            getSignalById: getSignalById,
            querySignals: querySignals,
            removeSignal: removeSignal,
            updateSignal: updateSignal,
            getDetectors: getDetectors,
            createDetector: createDetector,
            deleteDetector: deleteDetector,
            updateDetector: updateDetector,
            getApproaches: getApproaches,
            createSignal: createSignal,
            removeSignalNoPrompt: removeSignalNoPrompt,
            createApproach: createApproach,
            updateApproach: updateApproach,
            deleteApproach: deleteApproach,
            getSignalLatency: getSignalLatency,
            getAutomatedConfigResults: getAutomatedConfigResults,
            getAllSignalsWithApproaches: getAllSignalsWithApproaches,
            getSignalByIDFromDict: getSignalByIDFromDict,
            checkSignalExists: checkSignalExists,
            notifyChange: notifyChange,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('signal-configuration-change', callback);
                scope.$on('$destroy', handler);
            },
        }
    }
})();