﻿(function () {

    'use strict';

    angular
        .module('app.spm.core')
        .service('breadcrumbNavigationService', breadcrumbNavigationService);

    // This service will be responsible for handling and maintaing the breadcrumb navigation history 
    // and also preserving the state for each breadcrumb (time options, selected signal, etc).
    //All navigation should be funneled throug this service instead of directly calling $state.go
    function breadcrumbNavigationService(searchBarService, $state) {
        //this will control how many breadcrumbs we keep track of and are displayed on UI
        const maxBreadcrumbs = 3;
        //this is our array holding breadcrumbs. 
        let breadCrumbTrail = [];
        //navigation can either be windows style or strict
        const strict = "STRICT", windows = "WINDOWS";
        let navStyle = strict;
        //If we have more complex navigation requirements (changing signal, time frame, etc)
        //AFTER we navigate away, those properties are stored in the overrideNavigation object
        //this gets filled from navigateToState() function
        let overrideNavigation = {
            previousTimeOptions: undefined,
            previousSignal: undefined,
            ignoreState: undefined,
        }

        //will add breadcrumb item while user is navigating
        function addBreadcrumbItem(state) {
            //if login callback do not save breadcrumb
            // TODO: match strings
            if (state.url === "/callback" || state.url === '/login' || state.url === "/detail/:id"|| state.url === '/approaches/edit' || state.url === '/detectors/edit')
                return;

            //check if should ignore this state
            // if (overrideNavigation.ignoreState === state.name){
            //     overrideNavigation.ignoreState = undefined;
            //     return;
            // }

            //check if our current breadcrumb is the same as state being navigated too
            if (breadCrumbTrail.length > 0) {
                var current = breadCrumbTrail[breadCrumbTrail.length - 1];
                if (current.isCurrent && current.state.name === state.name)
                    return;
            }

            //let's generate our new breadcrumb item and add it to our breadcrumbTrail array
            let titleCleaned = state.url.replace(/\//g, '').replace(/\-/g, ' ');;
            let titleUpperCased = titleCleaned.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ')

            let breadcrumbItem = {
                state: state,
                title: titleUpperCased,
                isCurrent: true,
                searchOptions: searchBarService.getSearchOptions()
            }
            if (breadCrumbTrail.length >= maxBreadcrumbs) {
                //take off our first breadcrumb
                breadCrumbTrail.shift();
            }
            breadCrumbTrail.forEach(x => x.isCurrent = false);
            //now push our new breadcrumb item to the end
            breadCrumbTrail.push(breadcrumbItem);
        }

        //returns our object containing breadcrumb index and trail name
        function getBreadcrumbTrail() {
            return breadCrumbTrail;
        }

        //This function will navigate users to the previous breadcrumb item
        function navigateBackOneBreadcrumb() {
            let newCurrentBreadCrumb;
            if (navStyle === windows) {
                //pop off the last breadcrumb since we don't need it anymore
                breadCrumbTrail.pop();
                //now our last breadcrumb is current and we should navigate to it
                newCurrentBreadCrumb = breadCrumbTrail[breadCrumbTrail.length - 1];

            }
            else if (navStyle === strict) {
                //just swap our last two indexies
                var previousBc = breadCrumbTrail[breadCrumbTrail.length - 1];
                previousBc.isCurrent = false;
                newCurrentBreadCrumb = breadCrumbTrail[breadCrumbTrail.length - 2];
                breadCrumbTrail[breadCrumbTrail.length - 1] = newCurrentBreadCrumb;
                breadCrumbTrail[breadCrumbTrail.length - 2] = previousBc;
            }
            //set new breadcrumb as our current
            sessionStorage.setItem('main_back_navigation', {});
            newCurrentBreadCrumb.isCurrent = true;
            $state.go(newCurrentBreadCrumb.state.name);
        }

        //This function will navigate users to specific breadcrumb index item
        function navigateToBreadCrumbIndex(index) {
            breadCrumbTrail.forEach(x => x.isCurrent = false);
            let newCurrentBreadCrumb;

            if (navStyle === windows) {
                //first we splice out the breadcrumb we want to navigate to. 
                //this will automatically move all others up one index
                let l = breadCrumbTrail.length - 1;
                for (let i = l; i > index; i--) {
                    breadCrumbTrail.pop();
                }
                newCurrentBreadCrumb = breadCrumbTrail[breadCrumbTrail.length - 1];
                //set new breadcrumb as out current and push to stack
                newCurrentBreadCrumb.isCurrent = true;
            }
            else if (navStyle == strict) {
                //get the breadcrumb to navigate to and then remove it from array
                newCurrentBreadCrumb = breadCrumbTrail[index];
                if (index > -1) {
                    breadCrumbTrail.splice(index, 1);
                }
                //set current now push to end
                newCurrentBreadCrumb.isCurrent = true;
                breadCrumbTrail.push(newCurrentBreadCrumb);
            }

            // if (newCurrentBreadCrumb == 'Insights Map') {
            //     sessionStorage.setItem('')
            // }
            sessionStorage.setItem('breadcrumb_navigation', {});

            $state.go(newCurrentBreadCrumb.state.name);
        }

        //Call this function to navigate to a state that requires modifying the time frame or selected signal after navigation
        function navigateToState(state, inputTimeOptions, inputSignal, params) {
            if (state) {
                var currentOptions = searchBarService.getSearchOptions();
                if (inputTimeOptions && inputSignal) {
                    overrideNavigation.previousTimeOptions = currentOptions.timeOptions;
                    searchBarService.setTimeOptionsNoNotify(inputTimeOptions);
                    overrideNavigation.previousTimeOptions = currentOptions.timeOptions;
                    searchBarService.setSignal(inputSignal);
                }
                else if (inputTimeOptions) {
                    //save our current timeOptions because we need to save those for maintaining 'back' state
                    overrideNavigation.previousTimeOptions = currentOptions.timeOptions;
                    //now we can set our new time options when we navigate away
                    searchBarService.setTimeOptions(inputTimeOptions);
                }
                else if (inputSignal) {
                    overrideNavigation.previousSignal = currentOptions.signal;
                    searchBarService.setSignal(inputSignal);
                }
                //navigate to supplied state
                $state.go(state, { 'inputParameters': params });
                document.getElementById('lefthandmenu-scroll').scrollTop(0);
            }
        }

        //this function will allow for us to navigate to a new state without it being recorded in our breadcrumb trail
        //Currently, used for signal and approach edit screens. We may need to think of better way to handle navigation on those
        function navigateToStateWithoutBreadCrumb(state, params) {
            overrideNavigation.ignoreState = state;
            $state.go(state, { 'inputParameters': params });
        }

        return {
            addBreadcrumbItem: addBreadcrumbItem,
            navigateToBreadCrumbIndex: navigateToBreadCrumbIndex,
            getBreadcrumbTrail: getBreadcrumbTrail,
            navigateBackOneBreadcrumb: navigateBackOneBreadcrumb,
            navigateToState: navigateToState,
            navigateToStateWithoutBreadCrumb: navigateToStateWithoutBreadCrumb
        }
    }
})();
