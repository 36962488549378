﻿import angular from 'angular';
angular
    .module('app.spm.corridors', [])
    .config(['$stateProvider', '$translatePartialLoaderProvider', config]);

/** @ngInject */
function config($stateProvider) {
    $stateProvider
        .state('app.spm.config.corridors', {
            url: '/corridor-list',
            views: {
                'content@app': {
                    templateUrl: 'assets/templates/corridor-list.html',
                    controller: 'corridorListController as vm'
                }
            }
        })
        .state('app.spm.config.waze-routes', {
            url: '/waze-routes',
            views: {
                'content@app': {
                    templateUrl: 'app/main/spm/corridors/waze-config/waze-map.html',
                    controller: 'wazeRoutesController as vm'
                }
            }
        })

    // Translation
    //$translatePartialLoaderProvider.addPart('app/main/apps/spm/signals');
}