﻿import * as ADDDETECTOR from 'raw-loader!./../../detector/view/detector-add.html'

(function () {
    "use strict";

    angular
        .module("app.spm.signals")
        .controller("approachController", approachController);

    function approachController($state, $stateParams, $scope, signalService, $mdDialog, searchBarService, breadcrumbNavigationService) {
        var vm = this;
        vm.dtInstance = {};
        vm.signal = undefined; 
        vm.approach = undefined; 
        vm.lookups = undefined; 

        if ($stateParams.inputParameters){
            vm.signal = $stateParams.inputParameters.signal;
            vm.approach = $stateParams.inputParameters.approach;
            vm.lookups = $stateParams.inputParameters.lookups;
        }
        else{
            //go to signal list
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals');
            return;
        }
        vm.setupDataTable = setupDataTable;
        vm.getDetectors = getDetectors;
        vm.goToSignal = goToSignal;
        vm.searchChange = searchChange;
        vm.totalDetectorCount = 0;
        vm.goToDetectorEdit = goToDetectorEdit;
        vm.deleteDetector = deleteDetector;
        vm.goToAddDetector = goToAddDetector;
        vm.isFormValid = isFormValid;
        vm.isFormDirty = isFormDirty;
        vm.enableSaveButton = enableSaveButton;
        vm.saveApproach = saveApproach;
        vm.detectorPromise = null;
        vm.detectors = [];
        vm.setPristine = setPristine;
        vm.deleteApproach = deleteApproach;
        vm.checkPhases = checkPhases;
        vm.samePhases = false;
        vm.message = "";

        vm.setupDataTable();
        vm.getDetectors();

        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
            },
            //search bar options
            showSearchBar: false,
        });

        if (!vm.approach) {
            vm.addNewApproach = true;
            vm.headerMessage = "Add New Approach";
        }
        else {
            vm.addNewApproach = false
            vm.headerMessage = "Edit Approach " + vm.approach.description;
        }
        
        function checkPhases(protectedPhase, premisivePhase) {
            if(protectedPhase==premisivePhase){
                vm.samePhases = true;
                vm.message ="Protected and Premesive Phase Number are same, please change!"
            } 
            else {
                vm.samePhases= false;
                vm.message =""
            } 
        }

        function getDetectors() {
            vm.detectorPromise = signalService.getDetectors(vm.queryData)
                .then(function (data) {
                    vm.detectors = data.detectors;
                    vm.totalDetectorCount = data.totalCount;
                    //set the lane desc and movement desc
                    for (var i = 0; i < vm.detectors.length; i++) {
                        for (var l = 0; l < vm.lookups.laneTypes.length; l++) {
                            if (vm.lookups.laneTypes[l].id == vm.detectors[i].laneTypeID) {
                                vm.detectors[i].laneTypeDesc = vm.lookups.laneTypes[l].description;
                                break;
                            }
                        }
                        for (var m = 0; m < vm.lookups.movementTypes.length; m++ ) {
                            if (vm.lookups.movementTypes[m].id == vm.detectors[i].movementTypeID) {
                                vm.detectors[i].movementTypeDesc = vm.lookups.movementTypes[m].description;
                                break;
                            }
                        }
                    }
                })
                .catch(function (err){
                })
        };

        function goToSignal() {
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals.edit', { signal: vm.signal, lookups: vm.lookups });
        };


        function deleteApproach() {
            signalService.deleteApproach(vm.approach, vm.goToSignal);
        }

        function goToDetectorEdit(obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.detectors.edit', { signal: vm.signal, approach: vm.approach, detector: obj, lookups: vm.lookups });
        }

        function deleteDetector(obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            signalService.deleteDetector(obj, getDetectors);
            vm.selected = [];

            var foundIndex = vm.approach.detectors.findIndex(x => x.detChannel == obj.detChannel);
            if (foundIndex !== -1) vm.approach.detectors.splice(foundIndex,1);
        }

        function goToAddDetector(ev) {
            var confirm = $mdDialog.show({
                locals: { lookups: vm.lookups, signal: vm.signal, approach: vm.approach },
                controller: 'detectorAddController as vm',
                template: ADDDETECTOR,
                parent: angular.element(document.body),
                targetEvent: ev,
            }).then(function (response) {
                vm.getDetectors();
            });
        }

        function isFormValid() {
            if (vm.approachForm) {
                return vm.approachForm.$valid;
            }
        }

        function isFormDirty() {
            if (vm.approachForm) {
                return vm.approachForm.$dirty;
            }
        }

        function enableSaveButton() {
            if (isFormValid() && isFormDirty()) {
                return true;
            }
            return false;
        }

        function setPristine() {
            vm.approachForm.$setPristine();

        }
        function saveApproach() {
            vm.approach.signalID = vm.signal.signalID;
            signalService.updateApproach(vm.approach, vm.setPristine);
        };

        function setupDataTable() {
            vm.selected = [];
            vm.totalCount = {};
            vm.options = {
                rowSelection: true,
                multiSelect: false,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: true,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };

            vm.queryData = {
                orderBy: 'detectorID',
                pageSize: 10,
                pageIndex: 1,
                filter: '',
                ID: vm.approach.approachID
            };
        }
        var bookmark;
        function searchChange(newValue, oldValue) {
            if (!oldValue) {
                bookmark = vm.queryData.pageIndex;
            }

            if (newValue !== oldValue) {
                vm.queryData.pageIndex = 1;
            }

            if (!newValue) {
                vm.queryData.pageIndex = bookmark;
            }
            vm.getDetectors();
        };
    }
}());
