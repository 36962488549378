﻿import * as CALENDARHEATMAP from 'raw-loader!./calendar-heatmap.html';

(function () {
    'use strict';

    angular.module('app.spm.charts')
        .directive('calendarHeatmap', calendarHeatmapDirective);

    /** @ngInject */
    function calendarHeatmapDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            template: function (elem, attrs) {
                return CALENDARHEATMAP;
            },
            controller: 'calendarHeatmapController as vm',
            bindToController: {
                onDestroy: '&',
            },
            scope: {
                api: '=',
                onApiInit: '&'
            },
            link: function (scope, element, attrs) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        update: function (data, daysOfWeek, chartText, chartType, legendTitle, roundNumbers, widthOfCard, chartName, isSquareRoot, reverseColorScale, chartSubheader, baselineOptions, selectedLegend) {
                            scope.update(data, daysOfWeek, chartText, chartType, legendTitle, roundNumbers, widthOfCard, chartName, isSquareRoot, reverseColorScale, chartSubheader, baselineOptions, selectedLegend);
                        },

                    });
                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }              
            }
        };
    }
})();