﻿import * as SIGNALPERFCARD from 'raw-loader!./signal-performance-card.html'

(function () {
    'use strict';

    angular.module('app.spm.signals')
        .directive('signalPerformanceCard', signalPerformanceCardDirective);

    /** @ngInject */
    function signalPerformanceCardDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'signalPerformanceCardController as vm',
            template: function (elem, attrs) {
                 return SIGNALPERFCARD;
            },
            scope: {
                metricType: '<',
                showAxes: '<',
                hideButtons: '<',
                fromReport: '<',
                chartType: '<',
                signal: '<',
                goodHealthThreshold: '<',
                lowHealthThreshold: '<',
                api: '=',
                onApiInit: '&',
                onApiDestroy: '&',
                loading: '='
            }
            ,
            link: function (scope, element, attrs) {
                if (scope.api) {
                    angular.extend(scope.api, {
                        update: function (data, signal) {
                            scope.update(data, signal);
                        },
                        expand: function () {
                            scope.expand();
                        }
                    });

                    if (scope.onApiInit) {
                        scope.onApiInit({ apiObj: scope.api });
                    }
                }
            }
        };
    }
})();