(function () {
    "use strict";
    angular
        .module("app.spm.dashboards.corridor-performance")
        .controller(
            "DashboardCorridorPerformanceController",
            DashboardCorridorPerformanceController
        );

    /** @ngInject */
    function DashboardCorridorPerformanceController($scope, $rootScope, $state, searchBarService, $interval, $mdDialog, $translate, $element, signalPerformanceService, signalService, corridorService, userSettingsService) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        vm.searchBarService = searchBarService;
        vm.timeOptions = {};
        vm.corridor = undefined;
        vm.signals = [];
        vm.setSearchOptions = setSearchOptions;
        vm.setupSearchBar = setupSearchBar;
        vm.directionDetails = {};
        vm.getMetricDataForCorridor = getMetricDataForCorridor;
        vm.createPerformanceTabs = createPerformanceTabs;
        vm.formatIntToString = formatIntToString;
        vm.goToSignalPerformance = goToSignalPerformance;
        vm.createComparisonDataSet = createComparisonDataSet;
        vm.showHelpAlert = showHelpAlert;
        vm.i18TopLocation = "CORRIDOR_DASHBOARD.";
        vm.tabChange = tabChange;
        vm.loadTab = loadTab;
        vm.createTabObject = createTabObject;
        vm.createDataObjects = createDataObjects;
        vm.resetFiltersBasedOnData = resetFiltersBasedOnData;
        vm.chartServerError = false;
        $scope.loading = false;
        vm.corridorData = {};
        vm.selectedTab = 0;
        vm.destroyTabScope = destroyTabScope;
        vm.noData = false;

        function showHelpAlert(ev, card) {
            $mdDialog.show({
                locals: { card: card },
                controller: HelpController,
                parent: $element,
                clickOutsideToClose: true,
                templateUrl: 'app/main/spm/dashboards/corridor-performance/help-dialog.html',
                targetEvent: ev,
            });
        };

        function HelpController($scope, $mdDialog, card) {
            //$translate('CORRIDOR_DASHBOARD.' + card + '.TITLE')
            //    .then(function (translatedValue) {
            //        if (translatedValue) {
            //            $scope.title = translatedValue;
            //        }
            //    });

            //$translate('CORRIDOR_DASHBOARD.' + card + '.HELP_DEF_TEXT')
            //    .then(function (translatedValue) {
            //        if (translatedValue) {
            //            $scope.metricInfo = translatedValue;
            //        }
            //    });

            //$translate('CORRIDOR_DASHBOARD.' + card + '.HELP_CALC_TEXT')
            //    .then(function (translatedValue) {
            //        if (translatedValue) {
            //            $scope.calcInfo = translatedValue;
            //        }
            //    });
            //$translate('CORRIDOR_DASHBOARD.' + card + '.HELP_EXTRA_TEXT')
            //    .then(function (translatedValue) {
            //        if (translatedValue) {
            //            $scope.extraInfo = translatedValue;
            //        }
            //    });

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        }

        function goToSignalPerformance(signal) {
            searchBarService.setSignal(signal);
            $state.go("app.spm.dashboards.signal-performance");
        }

        //creates the tab objects for binding
        function createTabObject(metricType, aggregationType, invertHeatMap, showTotalCard, showAvgCard, decimalPlaces) {
            var parentObj = {
                title: "",
                metricType: metricType,
                decimalPlaces: decimalPlaces,
                overview: {
                    title: "",
                    average: "",
                    total: "",
                    showTotal: showTotalCard,
                    showAverage: showAvgCard,
                    valueLabel: "",
                },
                allSignalData: [{
                    key: "",
                    values: [],
                    heatMapData: [],
                    average: "",
                    total: "",
                }],
                aggregationType: aggregationType,
                corridorTotal: 0,
                corridorAverage: 0,
                inverseHeatMapColors: invertHeatMap,
                comparisonChart: {
                    dataset: [],
                    api: {},
                    metricType: metricType,
                    leftChartLabels: {
                        xAxis: "",
                        yAxis: "Signal"
                    },
                    rightChartLabels: {
                        xAxis: "Date",
                        yAxis: "",
                    },
                    onApiInit: function (apiObj) {
                        this.api = apiObj;
                        if (parentObj.dataHasBeenLoaded) {
                            vm.loadTab(parentObj);
                        }
                    },
                    onApiDestroy: function () {
                        this.api = {};
                    },
                },
                filters: [{ description: "All Directions", id: -1 }],
                selectedFilter: {},
                onFilterChange: function (filter) {
                    //change selected filter then kick off a tab render
                    this.selectedFilter = filter;
                    vm.createComparisonDataSet(this);
                    vm.loadTab(this);
                },
                title: "",
                isDataAvailable: true,
                dataHasBeenLoaded: false,
            }
            $translate(vm.i18TopLocation + metricType.toUpperCase() + '.TITLE')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        parentObj.title = translatedValue;
                        parentObj.overview.title = translatedValue;
                    }
                });
            $translate(vm.i18TopLocation + metricType.toUpperCase() + '.VALUE_LABEL')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        parentObj.overview.valueLabel = translatedValue;
                    }
                });
            $translate(vm.i18TopLocation + metricType.toUpperCase() + '.AXIS_LABEL')
                .then(function (translatedValue) {
                    if (translatedValue) {
                        parentObj.comparisonChart.leftChartLabels.xAxis = translatedValue;
                        parentObj.comparisonChart.rightChartLabels.yAxis = translatedValue;

                    }
                });
            parentObj.selectedFilter = parentObj.filters[0];
            return parentObj;
        }

        //generates each tab object that we want to show and adds to array.
        //If we ever want to add a new tab metric, we just need to call createTabObject with the metricType and add to performance tab array
        function createPerformanceTabs() {
            vm.selectedTab = signalPerformanceService.metricTypesEnum.Volume;
            vm.volumeTab = vm.createTabObject(signalPerformanceService.metricTypesEnum.Volume, signalPerformanceService.aggregationNames.Volumes, false, true, true, 0);
            vm.splitFailTab = vm.createTabObject(signalPerformanceService.metricTypesEnum.SplitFails, signalPerformanceService.aggregationNames.SplitFails, false, true, true, 0);
            vm.delayTab = vm.createTabObject(signalPerformanceService.metricTypesEnum.DelayPerVehicle, signalPerformanceService.aggregationNames.VehicleDelay, false, true, true, 2);
            vm.platoonTab = vm.createTabObject(signalPerformanceService.metricTypesEnum.PlatoonRatio, signalPerformanceService.aggregationNames.Pcd, true, false, true, 2);
            vm.arrivalsOnGreen = vm.createTabObject(signalPerformanceService.metricTypesEnum.PercentArrivalsOnGreen, signalPerformanceService.aggregationNames.Pcd, true, false, true, 2);
            vm.performanceTabs = [vm.delayTab, vm.volumeTab, vm.arrivalsOnGreen, vm.splitFailTab, vm.platoonTab];
            if (vm.isFiltering) {
                vm.getMetricDataForCorridor();
            }
        }

        function tabChange(metricType) {
            //only check if tab index is changing. If changing time/corridor tab change function will trigger but user isn't changing tab
            if (vm.selectedTab != metricType) {
                //destroy old tab scope
                // vm.destroyTabScope();
                vm.selectedTab = metricType;
            }
        }

        function formatIntToString(input) {
            var noDecimal = parseInt(input.toFixed(0));
            return noDecimal.toLocaleString("en");
        }

        //Sets our direction filters back to default All Directions
        function resetFiltersBasedOnData(metricTab) {
            metricTab.filters = [
                {
                    description: "All Directions",
                    id: -1
                }];
        }

        //this function will be responsible for querying API for data and maniuplating resp data into UI formats
        function getMetricDataForCorridor() {
            vm.setSearchOptions();
            $scope.loading = true;
            if (!vm.timeOptions) {
                return;
            }

            //setup default parameters
            var daysOfWeek = [];
            var startHour = 0, startMinute = 0, endMinute = 0, endHour = 0;
            var start = new Date(vm.timeOptions.currentFilter.startDateAndTime);
            var end = new Date(vm.timeOptions.currentFilter.endDateAndTime);
            //focus time frame and today time frame do not send "TimeOption". Send "StartToEnd" for timeOption
            if (vm.timeOptions.timeOption == "TimePeriod") {
                //commenting this out for now
                startHour = 0;
                startMinute = 0;
                endHour = 23;
                endMinute = 59;
                start =  new Date(start.getFullYear(), start.getMonth(), start.getDate(),startHour,startMinute);
                end =  new Date(end.getFullYear(), end.getMonth(), end.getDate(),endHour,endMinute);
            
            }
            //set the day of week list
            if (vm.timeOptions.daysOfWeekList) {
                daysOfWeek = vm.timeOptions.daysOfWeekList;
            }

            var aggregationTypesToQuery = [...new Set(vm.performanceTabs.map(x => x.aggregationType))];

            var opts = corridorService.createPerformanceOptionsObject(vm.corridor.corridorID, aggregationTypesToQuery, start.toLocaleString(), end.toLocaleString(),
                startHour, startMinute, endHour, endMinute, daysOfWeek, vm.timeOptions.timeOption, vm.timeOptions.bin, false);
            corridorService.getCorridorPerformance(opts)
                .then(function (res) {
                    if (!res || !res.signals || !res.directionalData) {
                        $scope.loading = false;
                        vm.chartServerError = true;
                        return;
                    }
                    //we need to see if all of our signals in corridor are missing data....really should only happen during full comm outages
                    else if ( Object.keys(res.directionalData).length === 0 && res.directionalData.constructor === Object){
                        vm.noData = true;
                        $scope.loading = false;
                        vm.chartServerError = false;
                        return;
                    }
                    vm.noData = false;
                    let signalData = res.signals;
                    let directionalData = res.directionalData;
                    //go through each card in array and match with data from server
                    //do any pre-processing of the data that is needed, then pass into each card
                    //for display
                    for (let i = 0; i < vm.performanceTabs.length; i++) {

                        let thisTab = vm.performanceTabs[i];
                        let tabSignalData = signalData[thisTab.aggregationType];
                        let tabDirectionalData = directionalData[thisTab.aggregationType];
                        if (!tabSignalData || !tabDirectionalData) continue;

                        vm.resetFiltersBasedOnData(thisTab);
                        var queryPropertyData = signalPerformanceService.getValuePropertiesFromType(thisTab.metricType);
                        //setup the directional data
                        thisTab.directionData = [];
                        for (let j = 0; j < tabDirectionalData.length; j++) {
                            //get the parent direction
                            var direction = tabDirectionalData[j];
                            //add to the filter
                            thisTab.filters.push({ description: direction.direction.description, id: direction.direction.directionTypeID });
                            //create the object to add to array later
                            var directionData = {
                                direction: direction.direction.description,
                                directionId: direction.direction.directionTypeID,
                                approaches: [],
                                heatMapData: [],
                                average: 0,//direction.average,
                                total: 0//direction.total
                            };
                            var heatMapData = [];
                            var totalValue = 0;
                            for (var k = 0; k < direction.approaches.length; k++) {
                                //go through each approach and create data objects to add to parent direction obj
                                var approach = direction.approaches[k];
                                var approachData = createDataObjects(approach, queryPropertyData, false);
                                totalValue += approachData.data.value;
                                directionData.approaches.push(approachData.data);
                                directionData.heatMapData.push(approachData.heatMapData);
                            }

                            //calculate totals and averages for summary cards
                            directionData.total = totalValue;
                            if (direction.approaches.length > 0)
                                directionData.average = parseFloat((totalValue / direction.approaches.length).toFixed(thisTab.decimalPlaces));

                            thisTab.directionData.push(directionData);
                        }
                        //setup the signal data
                        var allSignalData = [], heatMapData = [];
                        var totalValue = 0;
                        for (let j = 0; j < tabSignalData.length; j++) {
                            //go through each signal and create data objects and add to metric tab parent
                            var signal = tabSignalData[j];
                            var oneSignalData = createDataObjects(signal, queryPropertyData, true);
                            totalValue += (isNaN(oneSignalData.data.value) || oneSignalData.data.value === undefined || oneSignalData.data.value === null) ? 0 : oneSignalData.data.value;
                            allSignalData.push(oneSignalData.data);
                            heatMapData.push(oneSignalData.heatMapData);
                        }
                        thisTab.allSignalData[0].values = allSignalData;
                        //calculate totals and averages for summary cards
                        if (tabSignalData.length > 0)
                            thisTab.allSignalData[0].average = parseFloat((totalValue / tabSignalData.length).toFixed(thisTab.decimalPlaces));

                        thisTab.allSignalData[0].total = totalValue;

                        thisTab.allSignalData[0].heatMapData = heatMapData;

                        if (thisTab.allSignalData[0].total > 0 || thisTab.allSignalData[0].average > 0) {
                            thisTab.isDataAvailable = true;
                            vm.createComparisonDataSet(thisTab);
                            //render the charts only if we are on the selected index (don't render tabs that aren't being shown)
                            if (thisTab.metricType == vm.selectedTab) {
                                vm.loadTab(thisTab);
                            }
                            thisTab.dataHasBeenLoaded = true;
                        }
                        else {
                            thisTab.isDataAvailable = false;
                            thisTab.dataHasBeenLoaded = true;
                        }
                    }
                    $scope.loading = false;
                    vm.chartServerError = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    if (vm)
                        vm.chartServerError = true;
                });

        }

        //this function will take our data from server and create our objects needed for UI charts
        function createDataObjects(entity, queryPropertyData, useWeightedAvg) {
            var heatMapData = {};

            //depending on if it's signal or approach, set the label
            var label = entity.customID;
            if (entity.directionAbbv) {
                label = entity.directionAbbv + " - " + entity.customID;
            }
            var entityData = {
                label: label,
                customId: entity.customID,
                id: entity.signalId,
                fullName: entity.fullName,
                value: 0,
                data: [],
            };

            var dataPoints = [];
            //need to create our data points. loop through each bin and create X and Y datapoint for charts
            entity.data.binsContainer.bins.forEach(function (bin) {
                var yValue = bin[queryPropertyData.valueProperty];
                dataPoints.push({
                    x: new Date(bin.start),
                    y: yValue
                });
            });
            //summary values are shown on the summary cards. Depending on if this metric uses total or average let's get the correct property
            var summaryValue = 0;
            if (queryPropertyData.useTotal){
                summaryValue = entity.data.binsContainer[queryPropertyData.summaryTotalProperty];
            }
            else{
                if (useWeightedAvg && queryPropertyData.summaryWeightedAverageProperty)
                    summaryValue = entity.data.binsContainer[queryPropertyData.summaryWeightedAverageProperty];
                else
                    summaryValue = entity.data.binsContainer[queryPropertyData.summaryAverageProperty];
            }
            if (summaryValue && summaryValue > 0) {
                entityData.data = dataPoints;
                heatMapData = {
                    id: entityData.id,
                    customId: entityData.customId,
                    fullName: entity.fullName,
                    latitude: entity.latitude,
                    longitude: entity.longitude,
                    count: summaryValue
                };
            }

            entityData.value = summaryValue;
            return {
                data: entityData,
                heatMapData: heatMapData
            };
        }

        //this creates our dataset for chartJS 
        function createComparisonDataSet(metricTab) {
            //todo: check filter and use correct data set

            var dataset = [];
            // "All Directions"
            if (metricTab.selectedFilter.id == -1) {
                metricTab.allSignalData[0].values.forEach(function (sigData) {
                    dataset.push({
                        name: sigData.label,
                        id: sigData.id,
                        fullName: sigData.fullName,
                        total: sigData.value,
                        values: sigData.data
                    })
                });
                metricTab.overview.average = metricTab.allSignalData[0].average;
                metricTab.overview.total = metricTab.allSignalData[0].total;

            }
            else {
                var direction = metricTab.directionData.find(function (d) { return d.directionId == metricTab.selectedFilter.id });
                direction.approaches.forEach(function (apprData) {
                    dataset.push({
                        name: apprData.label,
                        id: apprData.id,
                        total: apprData.value,
                        values: apprData.data
                    })
                });
                metricTab.overview.average = direction.average;
                metricTab.overview.total = direction.total;
            }

            //order dataset based on filter
            //All directions or Side streets is descending order
            if (metricTab.selectedFilter.id == -1 || metricTab.selectedFilter.id == 3) {
                dataset.sort(function (a, b) {
                    return b.total == a.total ? 0 : +(b.total > a.total) || -1;
                });
            }
            //downstream
            else if (metricTab.selectedFilter.id == 1) {
                dataset.sort(function (a, b) {
                    return vm.corridor.downstreamRouteOrderIds.indexOf(parseInt(b.id)) - vm.corridor.downstreamRouteOrderIds.indexOf(parseInt(a.id));
                });
            }
            //upstream
            else if (metricTab.selectedFilter.id == 2) {
                dataset.sort(function (a, b) {
                    return vm.corridor.upstreamRouteOrderIds.indexOf(parseInt(b.id)) - vm.corridor.upstreamRouteOrderIds.indexOf(parseInt(a.id));
                });
            }
            metricTab.comparisonChart.dataset = dataset;
        }

        //this function will take data set with metricTab and update the chartComparison api which will refresh data
        function loadTab(metricTab) {
            //todo: create filter and use create data set for heat map
            var heatMapData = {};
            var inverseHeatMapColors = false;
            if (metricTab.selectedFilter.id == -1) {
                // "All Directions"
                heatMapData = metricTab.allSignalData[0].heatMapData;
                inverseHeatMapColors = metricTab.inverseHeatMapColors;
                // vm.signalServiceExecuted = true;
            }
            else {
                var direction = metricTab.directionData.find(function (d) { return d.directionId == metricTab.selectedFilter.id });
                heatMapData = direction.heatMapData;
                vm.signalServiceExecuted = undefined;
                for (let i = 0; i < heatMapData.length; i++) {
                    if (heatMapData[i].id !== undefined) {
                        var findSignalById = vm.signals.find(x => x.signalID == heatMapData[i].id)
                        if (findSignalById) {
                            heatMapData[i].latitude = findSignalById.latitude.toString();
                            heatMapData[i].longitude = findSignalById.longitude.toString();
                        }
                    }
                }
                inverseHeatMapColors = metricTab.inverseHeatMapColors;

            }

            if (metricTab.comparisonChart.api && metricTab.comparisonChart.api.update) {
                // vm.coordinatesLoaded = $interval(function () {
                //  if (vm.signalServiceExecuted) {
                //    $interval.cancel(vm.coordinatesLoaded);
                metricTab.comparisonChart.api.update(metricTab.comparisonChart.dataset, heatMapData, inverseHeatMapColors);
                // }
                // }, 500);
            }
        }

        //whenever we change data (new corridor, new time frame, etc) our old comparison chart controller scope needs to be destroyed
        function destroyTabScope() {
            //destroy old tab scope
            var tab = vm.performanceTabs.find(x => x.metricType == vm.selectedTab);
            if (tab.comparisonChart.api && tab.comparisonChart.api.forceDestroy)
                tab.comparisonChart.api.forceDestroy();

        }

        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return;

                switch (changeType) {
                    case "time":
                        if (searchBarService.isFiltering() && vm.corridor) {
                            var options = vm.searchBarService.getSearchOptions();
                            vm.timeOptions = options.timeOptions;
                            if (vm.performanceTabs) {
                                $scope.loading = true;
                                vm.corridorData = {};
                                vm.getMetricDataForCorridor();
                            }
                        }
                        break;
                    case "corridor":
                    case "configuration":
                        if (searchBarService.isFiltering() && vm.performanceTabs) {
                            $scope.loading = true;
                            vm.corridorData = {};
                            vm.getMetricDataForCorridor();
                        }
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                    case "state":
                        if (searchBarService.isFiltering() == false)
                            vm.isFiltering = false;
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Corridor Performance Dashboard"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Corridors',
                showCurrentFilterDates: true,
                helpJsonPropertyPath: "CORRIDOR_DASHBOARD." + "GENERAL_HELP",
                showHelp: true,
                metricTypeId: userSettingsService.dashboardTypeIds.CorridorPerformance,
                dashboardType: "Corridor",
                showExcel: true,
                timeFrameConfig: {
                    enableWithoutFiltering: false,
                    defaultDateTemplate: "TW",
                    defaultTodTemplate: "FD",
                    dateTemplateMinimumDays: 0,
                    timeOptionForCustomTemplate: "StartToEnd",
                    showWeekdayFilter: true,
                    enableAdvancedTod: false,
                    maxDayRange: 31
                },
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showBinConfig: true,
                    skipStepsPerBin: false,
                    notifyOnBinChange: true,
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.corridor = options.corridor;
            vm.timeOptions = options.timeOptions;
            vm.signals = [];
            var sigs = [];
            vm.corridor.downstreamRouteOrderIds = [];
            vm.corridor.upstreamRouteOrderIds = [];

            //setup direction string text for upstream/downstream
            let directionStr = "";
            if (vm.corridor.upstreamRoute)
                directionStr += vm.corridor.upstreamRoute.direction;
            if ( vm.corridor.downstreamRoute)
                if (directionStr != "") directionStr + "/";
                directionStr += vm.corridor.downstreamRoute.direction;

            
            vm.corridor.mainDirections = "Main directions: " + directionStr;
            if (vm.corridor.upstreamRoute && vm.corridor.upstreamRoute.approachRouteDetails){
                vm.corridor.upstreamRoute.approachRouteDetails.forEach(function (rt) {
                    rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                    if (sigs.filter(e => e.description === rt.signal.description).length <= 0) {
                        sigs.push(rt.signal);
                    }
                    vm.corridor.upstreamRouteOrderIds[rt.approachOrder] = rt.signal.signalID;
                });
            }
            if (vm.corridor.downstreamRoute && vm.corridor.downstreamRoute.approachRouteDetails){
                vm.corridor.downstreamRoute.approachRouteDetails.forEach(function (rt) {
                    rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                    if (sigs.filter(e => e.description === rt.signal.description).length <= 0) {
                        sigs.push(rt.signal);
                    }
                    vm.corridor.downstreamRouteOrderIds[rt.approachOrder] = rt.signal.signalID;
                });
            }
       
            vm.signals = sigs;
        };

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function checkTime(){
            vm.options = vm.searchBarService.getSearchOptions();

            if(vm.options.timeOptions.bin=="Day"){
                // if binsize is day then we should select all day from 00 to 23
                let startHour = 0;
                let startMinute = 0;
                let endHour = 23;
                let endMinute = 59;
                let start =  new Date(vm.options.timeOptions.currentFilter.startDateAndTime.getFullYear(), vm.options.timeOptions.currentFilter.startDateAndTime.getMonth(), vm.options.timeOptions.currentFilter.startDateAndTime.getDate(),startHour,startMinute);
                let end =  new Date(vm.options.timeOptions.currentFilter.endDateAndTime.getFullYear(), vm.options.timeOptions.currentFilter.endDateAndTime.getMonth(), vm.options.timeOptions.currentFilter.endDateAndTime.getDate(),endHour,endMinute);
                vm.options.timeOptions.currentFilter.startDateAndTime = start;
                vm.options.timeOptions.currentFilter.endDateAndTime = end;
                vm.searchBarService.setTimeOptionsNoNotify(vm.options.timeOptions);
            }
        }

        checkTime();
        vm.setupSearchBar();
        vm.createPerformanceTabs();
    }
})();