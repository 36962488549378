(function ()
{
    'use strict';

    angular
        .module('traffop.forgot_password')
        .controller('ForgotPasswordController', ForgotPasswordController);

    /** @ngInject */
    function ForgotPasswordController($scope, $state, environmentConfig)
    {
        var vm = this;
        vm.sendResetLink = sendResetLink;
        vm.getCurrentMonth = getCurrentMonth;
        vm.email = "";
        vm.error = false;
        vm.success = false;
        vm.errorMsg = "";
        vm.successMsg = "";
        vm.backgroundImageStyle = "url('/assets/images/backgrounds/" + vm.getCurrentMonth() + ".jpg') no-repeat center center fixed";

        vm.webAuth = new auth0.WebAuth({
            domain: environmentConfig.authDomain,
            clientID: environmentConfig.authClient,
            scope: 'openid email name username',
        });

        function sendResetLink() {
            vm.webAuth.changePassword({
                connection: 'Username-Password-Authentication',
                email: vm.email
            }, function (err, resp) {
                if (err) {
                    vm.errorMsg = err;
                    vm.error = true;

                } else {
                    vm.successMsg = resp;
                    vm.success = true;
                }
                $scope.$apply();
            });
        }

        function getCurrentMonth() {
            var d = new Date();
            var month = new Array();
            month[0] = "january";
            month[1] = "february";
            month[2] = "march";
            month[3] = "april";
            month[4] = "may";
            month[5] = "june";
            month[6] = "july";
            month[7] = "august";
            month[8] = "september";
            month[9] = "october";
            month[10] = "november";
            month[11] = "december";
            var n = month[d.getMonth()];
            return n;
        }
    }
})();