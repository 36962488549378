(function () {
    'use strict';

    angular
        .module('traffop.main')
        .controller('MainController', MainController);

    /** @ngInject */
    function MainController($scope, $rootScope, $state, chartsService) {
        // Data

        //////////
        // segmentLoader.load(segment.config.apiKey);


        // userSettingsService.getUserSettings().then(function (userSettings) {
        //     var complexity = (userSettings.chartSettings && userSettings.chartSettings.chartComplexity) ? userSettings.chartSettings.chartComplexity : "Simple";
        //     chartsService.setChartComplexity(complexity);
        // })

        $rootScope.$on('$locationChangeStart', function (e, next, current) {
            var nextArr = next.split('/');
            var nextPage = nextArr[nextArr.length - 1];

            sessionStorage.setItem('segmentData', JSON.stringify({
                referrer: current.includes('access_token') ? 'Login Page' : current
            }))

            // telemetryService.page(current, nextPage, sessionStorage.userID, sessionStorage.userEmail, sessionStorage.userName);
        });

        // Remove the splash screen
        $scope.$on('$viewContentAnimationEnded', function (event) {
            if (event.targetScope.$id === $scope.$id) {
                $rootScope.$broadcast('msSplashScreen::remove');
            }
        });
    }
})();