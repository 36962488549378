﻿import moment from 'moment';

(function () {
    "use strict";

    angular
        .module("app.spm.charts.signal")
        .controller("approachDelayChartController", approachDelayChartController);

    function approachDelayChartController($state, $attrs, $scope, $rootScope, $element, environmentConfig, searchBarService, chartsService) {
        var vm = this;
        vm.getData = getData;
        vm.chartType = 8;
        vm.chartServerError = false;
        vm.secondsToHms = secondsToHms;

        if (!$scope.fetchData) {
            $scope.updateData = function (input) {
                if (input && input.length > 0) {
                    var jsonData = JSON.stringify(input, null, 4);
                    vm.volDelayChartWidget.processVolDelayData(JSON.parse(jsonData, JSON.dateParser));
                    $scope.loading = false;
                }
            }
        }

        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            $scope.spmChartOptions = null;
            $scope.searchDates = null;
            $scope.signal = null;
            $scope.chartData = null;
            if ($scope.onApiDestroy)
                $scope.onApiDestroy();

        });

        vm.volDelayChartWidget = {
            getChart: function (planData) {
                var clonedChartSetup = angular.copy(vm.volDelayChartWidget.chartDef);
                // clonedChartSetup.options.chart.planData = planData;
                return clonedChartSetup;
            },
            //holds each chart data
            chartArray: {},
            addPlanLabels: function (primaryPlans) {
                var plansCopy = Object.create(primaryPlans)
                if (!$scope.hidePlans) {
                    for (let i = 0; i < plansCopy.length; i++) {
                        var plan = Object.create(plansCopy[i]);
                        plan.labels = [];

                        //add plan header
                        var planText = "";
                        switch (plan.planNumber) {
                            case 0:
                            case 254:
                                planText = "Free";
                                break;
                            case 255:
                                planText = "Flash";
                                break;
                            default:
                                planText = "Plan " + plan.planNumber;
                                break;
                        }
                        var delayPerVeh = plan.avgDelay && typeof plan.avgDelay == 'number' ? "Delay Per Vehicle: " + plan.avgDelay + " seconds" : "";
                        var totalDelay = plan.totalDelay && typeof plan.totalDelay == 'number' ? "Total Delay: " + vm.secondsToHms(plan.totalDelay) : "";
                        plan.labels.push(planText, delayPerVeh, totalDelay);
                        plansCopy[i] = plan;
                    }
                    return plansCopy;
                }
            },

            //logic for taking server events and manipulating into format the chart is expecting
            processVolDelayData: function (rawData) {
                var plansInfo = {};
                var phases = [];
                var charts = {};
                var searchObj = searchBarService.getSearchOptions();

                //rawData is an array holding each phase data

                rawData.forEach(function (phaseItem) {
                    var allPhaseChartData = [];
                    var volDelaySummary = phaseItem.delayChartData;

                    var planList = phaseItem.plans;
                    if (!charts[phaseItem.direction]) {
                        charts[phaseItem.direction] = [];
                    }
                    //setup phase info object for later use on tooltips
                    //and to store the green/yellow/red/detector data 
                    var phaseInfo = {
                        direction: phaseItem.direction,
                        totalDelay: volDelaySummary.totalDelay,
                        avgDelay: volDelaySummary.averageDelay,
                    };


                    allPhaseChartData.push({
                        key: "Total Delay per Hour (hours)",
                        label: "Total Delay per Hour (hours)",
                        data: volDelaySummary.delayPerHour,
                        type: "LineWithLine",
                        yAxisID: '1',
                        strokeColor: chartsService.colors.signalRed,
                        borderColor: chartsService.colors.signalRed,
                        pointBackgroundColor: chartsService.colors.signalRed,
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    });
                    allPhaseChartData.push({
                        key: "Delay Per Vehicle (seconds)",
                        label: "Delay Per Vehicle (seconds)",
                        data: volDelaySummary.delayPerVehicle,
                        type: "LineWithLine",
                        yAxisID: '2',
                        strokeColor: chartsService.colors.blue,
                        borderColor: chartsService.colors.blue,
                        pointBackgroundColor: chartsService.colors.blue,
                        fill: false,
                        pointRadius: 1,
                        pointHoverRadius: 4
                    });
                    //push this phase chart back into the main chartArray. Each phase needs it's own chart
                    var phaseTypeText = phaseItem.isPermissive ? "Permissive" : "Protected";
                    charts[phaseItem.direction].push({
                        title: "Approach Delay",
                        subLine1: $scope.signal.description + " - " + phaseInfo.direction + " " + phaseItem.movement + " - " + phaseTypeText + " Phase " + phaseItem.phaseNumber,
                        subLine2: "Delay Per Vehicle: " + volDelaySummary.averageDelay + " seconds",
                        subLine3: "Total Delay: " + vm.secondsToHms(volDelaySummary.totalDelay),
                        plans: vm.volDelayChartWidget.addPlanLabels(planList),
                        dataset: allPhaseChartData,
                        planColor: "#000",
                        isDataAvailable: volDelaySummary.totalDelay > 0,
                        onDestroy: function () {
                            this.api = {};
                        },
                        onApiInit: function (apiObj) {
                            this.api = apiObj;
                            this.render(apiObj);
                        },
                        hidePlanLabels: function (e, chart) {
                            chart.plansHidden = !chart.plansHidden;
                            chart.chartOptions.hidePlans = !chart.chartOptions.hidePlans;
                            Chart.defaults.global.togglePlans(chart, chart.plansHidden);
                            this.plans.visible = false;
                            if (chart.plansHidden) {
                                this.plans.forEach(function (plan) {
                                    plan.labels2 = plan.labels;
                                    plan.labels = [];
                                });
                                chart.planColor = "#fff";
                            } else {
                                this.plans.forEach(function (plan) {
                                    plan.labels = plan.labels2;
                                });
                                chart.planColor = "#000";
                            }
                            this.api.update();
                        },
                        render: function (apiObj) {
                            apiObj.render(undefined, this.dataset, this.chartOptions);
                            vm.chart =
                                vm.chartRendering = false;
                            this.isRendered = true;
                        },
                        isRendered: false,
                        hover: {
                            mode: 'nearest'
                        },
                        chartOptions: {
                            useCrosshair: true,
                            animation: false,
                            hidePlans: false,
                            tooltips: {
                                intersect: false,
                                callbacks: {
                                    afterBody: function (t, d) {
                                        var plan = planList.filter(x => moment(x.startTime).isBefore(moment(t[0].label)) && moment(x.endTime).isAfter(moment(t[0].label)));

                                        var planText = "";
                                        if (!plan || plan.length == 0) return "";
                                        switch (plan[0].planNumber) {
                                            case 0:
                                            case 254:
                                                planText = "Free";
                                                break;
                                            case 255:
                                                planText = "Flash";
                                                break;
                                            default:
                                                planText = plan[0].planNumber;
                                                break;
                                        }

                                        return `\n Plan ${planText}`; //return a string that you wish to append
                                    }
                                }
                            },
                            legend: {
                                display: true,
                                // labels: {
                                //     filter: function (legendItem) {
                                //         if (legendItem.datasetIndex == 0 || legendItem.datasetIndex == 1 || legendItem.datasetIndex == 2) {
                                //             return false;
                                //         }
                                //         return true;
                                //     }
                                // },
                                align: 'end',
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    type: 'time',
                                    id: 'x-axis-0',
                                    time: {
                                        unit: vm.xaxeFormat
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        minRotation: 0,
                                        maxRotation: 0,
                                        autoSkip: true,
                                        autoSkipPadding: 50,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Total Delay per Hour (Hours)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '1',
                                    position: 'left',

                                    gridLines: {
                                        display: false
                                    },
                                },
                                {
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Delay Per Vehicle (seconds)',
                                        fontFamily: 'Roboto',
                                        fontSize: 14,
                                    },
                                    id: '2',
                                    position: 'right',
                                    gridLines: {
                                        display: false
                                    },
                                }
                                ]
                            },
                            plugins: {
                                zoom: {
                                    pan: {
                                        enabled: false,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    },
                                    zoom: {
                                        enabled: true,
                                        drag: true,
                                        speed: 0.1,
                                        mode: 'x',

                                        rangeMin: {
                                            x: searchObj.timeOptions.currentFilter.startDateAndTime.getTime()
                                        },
                                        rangeMax: {
                                            x: searchObj.timeOptions.currentFilter.endDateAndTime.getTime()
                                        }
                                    }
                                }
                            },
                        }
                    });
                });

                //set our chart array back to our binding object
                vm.volDelayChartWidget.chartArray = charts;
            }
        }

        //if fetch data is set, we need to handle populating the data
        if ($scope.fetchData) {
            //if chart options are not passed in, we need to get them from the server
            if (!$scope.spmChartOptions) {
                chartsService.getChartOptions(vm.chartType)
                    .then(function (data) {
                        data.metricTypeID = vm.chartType;
                        $scope.spmChartOptions = chartsService.createOptionsObject(data, $scope.searchDates, $scope.signal.signalID);
                        vm.getData();
                    });
            }
            else {
                vm.getData();
            }
        }

        function getData() {
            //gets data from the server
            vm.chartArray = [];
            $scope.loading = true;
            //pass the chart options object to the server
            chartsService.getChartData($scope.spmChartOptions, true)
                .then(function (data) {
                    //JSON stringify the server data and then process it for the chart
                    vm.chartServerError = false;
                    var rawData = JSON.stringify(data, null, 4);
                    vm.volDelayChartWidget.processVolDelayData(JSON.parse(rawData, JSON.dateParser));
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.loading = false;
                    vm.chartServerError = true;
                });
        }

        function secondsToHms(seconds) {
            var h = Math.floor(seconds / 3600);
            var m = Math.floor(seconds % 3600 / 60);

            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";

            return hDisplay + mDisplay
        }
    }
}());
