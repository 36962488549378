(function ()
{
    'use strict';
    angular
        .module('app.spm.dashboards.travel-time')
        .controller('DashboardTravelTimeController', DashboardTravelTimeController);

    /** @ngInject */
    function DashboardTravelTimeController($scope, $state, searchBarService, $mdDialog, $translate, $element, signalPerformanceService,helpService)
    {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
        })
        vm.searchBarService = searchBarService;
        vm.timeOptions = {};
        vm.corridor = undefined;
        vm.setSearchOptions = setSearchOptions;
        vm.setupSearchBar = setupSearchBar;
        vm.routes = [];

        vm.travelTimesUpdated = travelTimesUpdated;
        vm.travelTimesError = travelTimesError;
        vm.formatIntToString = formatIntToString;
        vm.goToSignalPerformance = goToSignalPerformance;
        vm.selectedPerformanceTabIndex = 0;
        vm.loading = false;
        vm.showHelpAlert = showHelpAlert;
        vm.i18TopLocation = "ROUTE_DASHBOARD.";

        function showHelpAlert(ev, card) {
            helpService.showHelpDialog("ROUTE_DASHBOARD." + card.toString(), ev, "How We Calculate It");  
        };

        function goToSignalPerformance(signal) {
            searchBarService.setSignal(signal);
            $state.go('app.spm.dashboards.signal-performance');
        }


        vm.setupSearchBar();

        function formatIntToString(input) {
            var noDecimal = parseInt(input.toFixed(0));
            return noDecimal.toLocaleString('en');
        }


        function setupSearchBar() {
            //whenever our searchbar has changed update the cards
            vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {
                if (!vm)
                    return; 

                switch (changeType) {
                    case "time":
                        break;
                    case "corridor":
                    case "configuration":
                        if (searchBarService.isFiltering()) {
                            vm.setSearchOptions();
                        }
                        vm.isFiltering = searchBarService.isFiltering();
                        break;
                    case "state":
                        if (searchBarService.isFiltering() == false)
                            vm.isFiltering = false;
                        break;
                }
            });

            //set options for the search bar
            //make sure this gets called after calling subscribe
            vm.searchBarService.setSearchBarConfig({
                //header information
                header: {
                    show: true,
                    text: "Travel Time Dashboard"
                },
                //search bar options
                showSearchBar: true,
                searchType: 'Corridors',
                showCurrentFilterDates: false,
                helpJsonPropertyPath: "ROUTE_DASHBOARD." + "GENERAL_HELP",
                showHelp: true,                
                //right-side more options menu
                moreOptionsMenu: {
                    show: true,
                    showWeekdayFilter: true,
                    showBinConfig: true,
                    skipStepsPerBin: false,
                    notifyOnBinChange: true,
                }
            });
            vm.isFiltering = searchBarService.isFiltering();
        }

        function setSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.corridor = options.corridor;
            vm.timeOptions = options.timeOptions;
            vm.signals = [];
            var upstreamRoute = {
                signals: [],
                direction: vm.corridor.upstreamRoute.direction,
                travelTimesUpdated: function (e, routeIdx, units) {
                    vm.travelTimesUpdated(e, routeIdx, units, this);
                },
                directionDetails: {},
                mapApi: {},
                refreshTravelTimes: function () {
                    if (this.mapApi && this.mapApi.calculateDirections) {
                        this.mapApi.calculateDirections();
                    }
                }
            };
            var downstreamRoute = {
                signals: [],
                travelTimesUpdated: function (e, routeIdx, units) {
                    vm.travelTimesUpdated(e, routeIdx, units, this);
                },
                direction: vm.corridor.downstreamRoute.direction,
                directionDetails: {},
                mapApi: {},
                refreshTravelTimes: function () {
                    if (this.mapApi && this.mapApi.calculateDirections) {
                        this.mapApi.calculateDirections();
                    }
                }
            }
            vm.corridor.upstreamRoute.approachRouteDetails.forEach(function (rt) {
                rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                upstreamRoute.signals.push(rt.signal);
            });
            vm.corridor.downstreamRoute.approachRouteDetails.forEach(function (rt) {
                rt.signal.description = getSignalDescription(rt.signal.customID, rt.signal.primaryName, rt.signal.secondaryName);
                downstreamRoute.signals.push(rt.signal);
            });
            vm.routes = [];
            vm.routes.push(upstreamRoute, downstreamRoute);
        };

        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }

        function travelTimesError(e) {
            //do something with e.message
        }

        function travelTimesUpdated(e, routeIdx, units, route) {
            var directionDetails = {
                distance: 0,
                distanceUnits: "",
                travelTimeMinutes: 0,
                travelTimeMinutesNoTraffic: 0,
                updateTime: ""
            };

            var routeLegs = e.route[routeIdx].routeLegs;
            if (routeLegs) {
                for (var i = 0; i < routeLegs.length; i++) {
                    var leg = routeLegs[i];
                    var signal = route.signals[i + 1];

                    signal.legDistance = Math.round(leg.summary.distance * 100) / 100;
                    var minutes = Math.floor(leg.summary.time / 60);
                    var seconds = leg.summary.time - minutes * 60;
                    if (minutes > 0)
                        signal.travelTimeLeg = minutes + "m" + seconds + "s";
                    else
                        signal.travelTimeLeg = seconds + "s";
                }
            }
            //Get the distance of the route, rounded to 2 decimal places.
            directionDetails.distance = Math.round(e.routeSummary[routeIdx].distance * 100) / 100;
            directionDetails.distanceUnits = units;

            //Time is in seconds, convert to minutes and round off.
            directionDetails.travelTimeMinutes = Math.round(e.routeSummary[routeIdx].timeWithTraffic / 60);
            directionDetails.travelTimeMinutesNoTraffic = Math.round(e.routeSummary[routeIdx].time / 60);
            directionDetails.increasePercentage = Math.round(((directionDetails.travelTimeMinutes - directionDetails.travelTimeMinutesNoTraffic) / directionDetails.travelTimeMinutesNoTraffic).toFixed(2) * 100) + '%';
            directionDetails.updateTime = new Date().toLocaleTimeString();
            route.directionDetails = directionDetails;
            $scope.$apply();
        }

    }
})();