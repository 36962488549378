﻿(function ()
{
    'use strict';

    angular
        .module('app.core')
        .directive('sunburstChart', sunburst);

    function sunburst() {
        return {
            restrict: "AEC",
            scope: {
                data: "=",
            },
            link: sunburstDraw
        };
    }

    function sunburstDraw(scope, element) {
        scope.$watch("data", function () {
            var data = scope.data;
            render(data);
        });

        var width = 350;
        var height = 450;
        var radius = Math.min(width, height) / 2;

        var colors = d3.scale.category20();
        var totalSize = 0;

        var partition = d3.layout.partition()
          .size([2 * Math.PI, radius * radius])
          .value(function (d) {
              return d.size;
          });

        var arc = d3.svg.arc()
          .startAngle(function (d) {
              return d.x;
          })
          .endAngle(function (d) {
              return d.x + d.dx;
          })
          .innerRadius(function (d) {
              return Math.sqrt(d.y);
          })
          .outerRadius(function (d) {
              return Math.sqrt(d.y + d.dy);
          });

        var vis = d3.select(element[0])
          .append("div").classed("vis-container", true);

        var sunburst = vis
             .append('svg:svg')
             .attr("id", "main-chart-svg")
             .attr("preserveAspectRatio", "xMidYMid")
             .attr("viewBox", "0 0 " + width + " " + height)
             .style("max-height", height + 30)
             .append('svg:g')
             .attr("id", "chartGroup")
             .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

        var sequenceTrail = vis.append("div").attr("id", "sequence");

        var summary = vis
          .append("div").classed("summary-container", true)


        function render(data) {
            removeVisualization(); 
            createVisualization(data[0]); 
        }

        function removeVisualization() {
            sunburst.selectAll(".nodePath").remove();
        }

        function createVisualization(json) {
            initializeBreadcrumbTrail()
            drawSunburst(json);
            drawLegend(); 
        };


        function colorMap(d) {
            return colors(d.name);
        }


        function drawSunburst(json) {
            vis.append("svg:circle")
            .attr("r", radius)
            .style("opacity", 0);
            var nodes = partition.nodes(json)
              .filter(function (d) {
                  return (d.dx > 0.005); 
              });

            var uniqueNames = (function (a) {
                var output = [];
                a.forEach(function (d) {
                    if (output.indexOf(d.name) === -1 && d.noLegend == false) output.push(d.name);
                });
                return output;
            })(nodes);
            colors.domain(uniqueNames);

            var path = sunburst.data([json]).selectAll("path")
              .data(nodes).enter()
              .append("path").classed("nodePath", true)
              .attr("display", function (d) {
                  return d.depth ? null : "none";
              })
              .attr("d", arc)
              .attr("fill", colorMap)
              .attr("opacity", 1)
              .attr("stroke", "white")
              .on("mouseover", mouseover);


            vis.on("click", click);

            totalSize = path.node().__data__.value;
        }

        function drawLegend() {
            var legendOrdinal = d3.legend.color()
              .shape("path", d3.svg.symbol().type("circle").size(150)())
              .shapePadding(10)
              .scale(colors);

            d3.select(".legendOrdinal")
              .call(legendOrdinal);
        }


        function mouseover(d) {
            var percentage = (100 * d.value / totalSize).toPrecision(3);
            var percentageString = percentage + "%";
            if (percentage < 1) {
                percentageString = "< 1.0%";
            }

            var ancestors = getAncestors(d);
            updateBreadcrumbs(ancestors, percentageString);

            sunburst.selectAll("path")
              .attr("opacity", 0.3);
            sunburst.selectAll("path")
              .filter(function (node) {
                  return (ancestors.indexOf(node) >= 0);
              })
              .attr("opacity", 1);

            summary.style("visibility", "");
            summary.html(
                "<span class='summary-text'><span class='percentage'>" + d.name + "</span><br />" + 
                d.value + " of " + totalSize + "<br />" + 
              "<span class='percentage'>" + percentageString + "</span><br /></span>" 
            );
        }


        function click(d) {
            sunburst.selectAll("path").on("mouseover", null);
            sunburst.selectAll("path")
              .transition()
              .duration(1000)
              .attr("opacity", 1)
              .each("end", function () {
                  d3.select(this).on("mouseover", mouseover);
              });

            d3.select("#trail").style("visibility", "hidden");
            summary.style("visibility", "hidden");
        }


        function getAncestors(node) {
            var path = [];
            var current = node;

            while (current.parent) {
                path.unshift(current);
                current = current.parent;
            }
            return path;
        }


        function breadcrumbPoints(d, i) {
            var points = [];
            points.push("0,0");
            points.push(b.w + ",0");
            points.push(b.w + b.t + "," + (b.h / 2));
            points.push(b.w + "," + b.h);
            points.push("0," + b.h);

            if (i > 0) { // Leftmost breadcrumb; don't include 6th vertex.
                points.push(b.t + "," + (b.h / 2));
            }
            return points.join(" ");
        }

        function initializeBreadcrumbTrail() {
            var trail = sequenceTrail.append("div")
                .attr("id", "trail");
        }

        function updateBreadcrumbs(ancestors, percentageString) {
            var g = d3.select("#trail")
               .selectAll(".slice")
               .data(ancestors, function (d) { return d.name + d.depth; });

            var breadcrumb = g.enter()
                                .append("div")
                                .attr("class", "slice");

            breadcrumb.append("div")
              .attr("class", "arrow-tail")
              .style("background-color", function (d) { return colors(d.name); });

            breadcrumb.append("div")
                .attr("class", "slice-body")
                .style("background-color", function (d) { return colors(d.name); })
                .text(function (d) { return d.name; });

            breadcrumb.append("div")
                .attr("class", "arrow-head")
                .style("border-left-color", function (d) { return colors(d.name); });

            breadcrumb.append("div").attr("class", "percentage-crumb").text(percentageString);

            g.exit().remove();

            d3.select("#trail")
            .style("visibility", "");
        }
    }
})();