(function () {
    'use strict';
    angular
        .module('app.spm.dashboards.signal-overview')
        .controller('DashboardSignalController', DashboardSignalController);

    /** @ngInject */
    function DashboardSignalController($scope, $rootScope, $interval, $mdSidenav, googleMapService, controllerEventService, insightsService, searchBarService, signalService, helpService) {
        var vm = this;
        $scope.$on("$destroy", function () {
            for (let member in vm) {
                vm[member] = null;
            }
            vm = null;
            // googleMapService.model = {
            //     zoomLevel: null,
            //     mapTypeId: null,
            //     bounds: null,
            //     center: null,
            //     traffic: null,
            // }
        });

        vm.loading = true;
        vm.signal = {};
        vm.timeOptions = {};
        vm.getSearchOptions = getSearchOptions;
        vm.searchBarService = searchBarService;
        // Other setup
        vm.getStatus = getStatus;
        vm.latencyData = {};
        vm.trustedCtrlUrl = "";
        vm.dataIntegrityStatus = {};
        vm.getSignalDataIntegrity = getSignalDataIntegrity;
        vm.signalStatusOnline = true;
        vm.dataIntegrityStatusClass = "success-green";
        vm.dataConfidence = { icon: "icon-verified", bgClass: "success-green" };
        vm.dataIntegrityIcon = "icon-check";
        vm.signalStatusClass = "success-green";
        vm.startStatusTimers = startStatusTimers;
        vm.setConnectionStatusCard = setConnectionStatusCard;
        vm.splitMonitorApi = {};
        vm.greenAllocationApi = {};
        vm.phaseTerminationApi = {};
        vm.onApiInit = onApiInit;
        vm.showHelpAlert = showHelpAlert;
        //hiding IP address now on request of clients. Add into user-role permissions when implemented
        vm.showIpAddress = false;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.isSideMenuOpened = false;

        vm.searchBarService.subscribe($scope, function onChange(ev, changeType) {

            if (!vm)
                return;

            switch (changeType) {
                case "time":
                case "signal":
                    vm.isFiltering = searchBarService.isFiltering();
                    if (vm.isFiltering) {
                        //get data
                        vm.loading = true;
                        vm.getSearchOptions();
                        if (vm.splitMonitorApi.updateFetchData)
                            vm.splitMonitorApi.updateFetchData(vm.signal, vm.timeOptions.currentFilter);

                        if (vm.greenAllocationApi.update)
                            vm.greenAllocationApi.update(vm.signal, vm.timeOptions.currentFilter);

                        if (vm.phaseTerminationApi.updateFetchData)
                            vm.phaseTerminationApi.updateFetchData(vm.signal, vm.timeOptions.currentFilter);
                        vm.getStatus();
                    }
                    break;
                case "state":
                    vm.isFiltering = searchBarService.isFiltering();
                    break;
            }
        });

        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: true,
                text: "Signal Overview",
            },
            //search bar options
            showSearchBar: true,
            searchType: 'Signals',
            showCurrentFilterDates: true,
            helpJsonPropertyPath: "SIGNAL_OVERVIEW." + "GENERAL_HELP",
            showHelp: true,
            showSignalNotificationTabs: true,
            timeFrameConfig: {
                enableWithoutFiltering: false,
                defaultDateTemplate: "TD",
                defaultTodTemplate: "FD",
                dateTemplateMinimumDays: 0,
                timeOptionForCustomTemplate: "StartToEnd",
                showWeekdayFilter: false,
                maxDayRange: 2
            },
            //right-side more options menu
            moreOptionsMenu: {
                show: false,
                showBinConfig: false,
                skipStepsPerBin: true,
            }
        });
        vm.isFiltering = searchBarService.isFiltering();

        //make sure to call this after subscribing and setting search bar configs
        vm.getSearchOptions();
        function getSearchOptions() {
            var options = vm.searchBarService.getSearchOptions();
            vm.signal = options.signal;
            vm.timeOptions = options.timeOptions;
        };

        function onApiInit(apiObj) {
            if (apiObj && apiObj.update) {
                apiObj.update(vm.signal, vm.timeOptions.currentFilter);
            }
            else if (apiObj && apiObj.updateFetchData)
                apiObj.updateFetchData(vm.signal, vm.timeOptions.currentFilter);
        }

        function startStatusTimers() {
            // Set interval
            vm.getStatus();
            var cancelInterval = $interval(getStatus, 900000);
            // Cleanup
            $scope.$on('$destroy', function () {
                $interval.cancel(cancelInterval);
            });
        }

        function getStatus() {
            if (vm.isFiltering && vm.signal && vm.signal.signalID) {
                vm.getSignalDataIntegrity();
                if (vm.latencyWidget && vm.latencyWidget.getData)
                    vm.latencyWidget.getData();
            }
        };

        function showHelpAlert(ev, path) {
            helpService.showHelpDialog("SIGNAL_OVERVIEW." + path.toString(), ev, "How We Calculate It");
        }

        function getSignalDataIntegrity() {
            var queryData = {
                startDate: new Date(vm.timeOptions.currentFilter.startDateAndTime).toLocaleString(),
                endDate: new Date(vm.timeOptions.currentFilter.endDateAndTime).toLocaleString(),
                signals: [vm.signal.signalID],
                //15 mins
                checkThreshold: '900000',
            }
            controllerEventService.getSignalDataIntegrity(queryData)
                .then(function (resp) {
                    vm.dataIntegrityStatus = resp[0];
                    if (!vm.dataIntegrityStatus) {
                        vm.dataIntegrityStatus = {
                            completeness: 0,
                        };
                        vm.dataIntegrityStatusClass = "failed-red";
                        vm.dataIntegrityIcon = "icon-close-network";
                        vm.dataConfidence.bgClass = "failed-red";
                        vm.dataConfidence.icon = "icon-close-network";
                    }
                    else {
                        if (vm.dataIntegrityStatus.completeness == 0 || vm.dataIntegrityStatus.completeness == 1) {
                            vm.dataIntegrityStatusClass = "failed-red";
                            vm.dataIntegrityIcon = "icon-close-network";
                        }
                        else if (vm.dataIntegrityStatus.completeness == 2) {
                            vm.dataIntegrityStatusClass = "problems-yellow";
                            vm.dataIntegrityIcon = "icon-alert";
                        }
                        else {
                            vm.dataIntegrityStatusClass = "success-green";
                            vm.dataIntegrityIcon = "icon-check";
                        }
                        if (!vm.dataIntegrityStatus.dataQualityGrade) {
                            vm.dataConfidence.grade = "F";
                        }
                        else {
                            vm.dataConfidence.grade = vm.dataIntegrityStatus.dataQualityGrade;
                        }
                        vm.dataConfidence.score = vm.dataIntegrityStatus.dataQualityScore;
                        var gradeChar = vm.dataConfidence.grade.charAt(0);
                        switch (gradeChar) {
                            case "A":
                            case "B":
                                vm.dataConfidence.bgClass = "success-green";
                                break;
                            case "C":
                            case "D":
                                vm.dataConfidence.bgClass = "problems-yellow";
                                break;
                            case "F":
                                vm.dataConfidence.bgClass = "failed-red";
                                break;
                        }
                    }

                    var d = new Date();

                    vm.dataIntegrityStatus.lastUpdatedTime = d.toLocaleTimeString() + ' ' + d.toDateString();
                    vm.dataIntegrityStatus.totalEvents = parseInt(vm.dataIntegrityStatus.totalEvents).toLocaleString();
                    vm.setConnectionStatusCard();

                    if (vm.isSideMenuOpened) {
                        insightsService.setInsightsSidemenu();
                        vm.isSideMenuOpened = true;
                    }
                });
        }

        vm.latencyWidget = {
            title: "Latency",
            average: "",
            successPercent: "",
            failedPercent: "",
            detail: "",
            data: [],
            chart: {
                config: {
                    refreshDataOnly: true,
                    deepWatchData: true,
                },
                options: {
                    chart: {
                        type: 'lineChart',
                        color: ['rgba(0, 0, 0, 0.27)'],
                        height: 50,
                        margin: {
                            top: 8,
                            right: 0,
                            bottom: 0,
                            left: 0
                        },
                        duration: 1,
                        clipEdge: true,
                        interpolate: 'cardinal',
                        interactive: true,
                        isArea: true,
                        showLegend: false,
                        showControls: false,
                        showXAxis: false,
                        showYAxis: false,
                        x: function (d) {
                            return new Date(d.timestamp);
                        },
                        y: function (d) {
                            return d.pingLatency;
                        }
                    }
                },
            },
            getData: function () {
                var queryData = {
                    start: new Date(vm.timeOptions.currentFilter.startDateAndTime).toLocaleString(),
                    end: new Date(vm.timeOptions.currentFilter.endDateAndTime).toISOString(),
                    id: vm.signal.signalID,
                }
                signalService.getSignalLatency(queryData)
                    .then(function (resp) {
                        var dataResp = [{
                            "key": "Latency",
                            "values": resp.pingList
                        }];
                        vm.latencyWidget.data = dataResp;
                        vm.latencyWidget.average = resp.averageLatency + "ms";
                        vm.latencyWidget.successPercent = resp.successPercentage + "%";
                        vm.latencyWidget.failedPercent = resp.failedPercentage + "%";
                        vm.signalStatusOnline = resp.wasLastPingSuccessful;
                        vm.setConnectionStatusCard();
                        var d = new Date();
                        vm.signal.lastUpdated = d.toLocaleTimeString() + ' ' + d.toDateString();
                    });
            }
        };

        function setConnectionStatusCard() {
            if (vm.dataIntegrityStatus.completeness == 3) {
                //we have all data for this signal we can assume status is online
                vm.signalStatusOnline = true;
                vm.signalStatusClass = "success-green";
            }
            else {
                //no data or incomplete data for the signal
                //look at latency
                if (vm.signalStatusOnline) {
                    vm.signalStatusClass = "success-green";
                }
                else {
                    vm.signalStatusClass = "failed-red";
                }
            }

        }
        vm.startStatusTimers();
    }
})();
