﻿(function () {
    "use strict";

    angular
        .module("app.spm.core")
        .service("routeService", routeService);

    function routeService(routesResource) {
        function queryRoutes(queryData) {
            return routesResource.routes.query(queryData, function (data) {
                if (data && data.routes) {
                    data.routes.forEach(function (rt) {
                        rt.description = rt.approachRouteId + ": " + rt.routeName;
                    });
                }
                return data;
                }).$promise;
        }

        
        function getSignalDescription(customId,primaryName,secondaryName){

            var res = customId + ": " + primaryName;
            if (secondaryName != null && secondaryName != '')
            {
                res += " - " + secondaryName;
            }
      
            return res;
        }


        function getRouteById(id) {
            return routesResource.routes.get({ id: id },
                function (data) {
                    if (!data.description && data.customID && data.primaryName) {
                        data.description = getSignalDescription(data.customID, data.primaryName, data.secondaryName);
                    }
                    return data;
                }).$promise;
        }

        return {
            queryRoutes: queryRoutes,
            getRouteById: getRouteById,
        }
    }
})();
