(function () {

    'use strict';

    angular
      .module('app.spm.link-pivot')
      .service('purdueLinkPivotService', purdueLinkPivotService);

    function purdueLinkPivotService($state, $rootScope, purdueLinkPivotResource) {
        var vm = this;
        vm.options = null;
        
        function getLinkPivotOptions() {
            if (!vm.options){
                return purdueLinkPivotResource.getOptions(function (data) {
                    vm.options = data;
                    data.startDate = new Date();
                    data.startTime = new Date();
                    data.endDate = new Date();
                    data.endTime = new Date();
                    data.postedDays = {};
                    data.postedDays.DayIDs = [];
                    return data;
                }).$promise;
            }
            return new Promise((resolve) => {
                resolve(vm.options);
            });
            
        }
        
        function notifyChange(options) {
            $rootScope.$emit('link-pivot-options-change-event', options);
        }

        return {
            //creates return obj
            getLinkPivotOptions: getLinkPivotOptions,
            notifyChange: notifyChange,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('link-pivot-options-change-event', callback);
                scope.$on('$destroy', handler);
            },
        }
    }
})();
