﻿import * as SIGNALEVENTSLIST from 'raw-loader!./signal-event-list.html'

(function () {
    'use strict';

    angular.module('app.spm.signal-events')
        .directive('signalEventList', signalEventListDirective);

    /** @ngInject */
    function signalEventListDirective($window) {
        function link(scope, element) {
        }

        return {
            restrict: 'E',
            link: link,
            controller: 'signalEventListController as vm',
            template: function (elem, attrs) {
                return SIGNALEVENTSLIST;
            },
            scope: {
                loading: '=',
                signal: '<',
                useFocusData:'<',
                searchDates: '<',
            }
        };
    }
})();