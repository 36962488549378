(function () {
    "use strict";

    angular
        .module("app.spm.core")
        .controller("insightsOptionsController", insightsOptionsController);

    function insightsOptionsController($state, $rootScope, $scope, insightsService, InsightConstants, searchBarService) {
        var vm = this;
        vm.insightsService = insightsService;
        vm.InsightConstants = InsightConstants;

        var insightStateFilters = ["new", "acknowledged", "ignored", "all"];
        var insightPriorityFilters = ["all", "low", "high"];

        vm.options = vm.insightsService.getOptions();
        vm.currentState = vm.options.filtering.state;
        vm.currentPriority = vm.options.filtering.priority;
        vm.filterStateChange = filterStateChange;
        vm.filterPriorityChange = filterPriorityChange;
        vm.searchBarService = searchBarService;

        function filterStateChange(filterIndex) {
            vm.currentState = filterIndex;
        }

        function filterPriorityChange(filterIndex) {
            vm.currentPriority = filterIndex;
        }

        vm.searchBarService.subscribeToOptionsApply($scope, function () {
            vm.options.filtering.state = vm.currentState;
            vm.options.filtering.priority = vm.currentPriority;
            vm.insightsService.setOptions(vm.options);
            vm.insightsService.notifyChange("Filter", vm.options.filtering);

            var prevSessionData = JSON.parse(sessionStorage.getItem('segmentData'));
            sessionStorage.setItem('segmentData', JSON.stringify(Object.assign(prevSessionData, {
                filter_parameters: {
                    state: insightStateFilters[vm.options.filtering.state - 1],
                    priority: insightPriorityFilters[vm.options.filtering.priority + 1]
                }
            })));
            // telemetryService.insightsDashboardTelemetry("apply", "filters");
        });
    }
}());
