﻿(function () {

    'use strict';

    angular
      .module('app.core')
      .provider('$runtimeStates', runtimeStatesProvider);

    /** @ngInject **/
    function runtimeStatesProvider($stateProvider) {
        var provider = this;

        provider.addState = addState;

        function addState(name, url, templateUrl, controllerAs, resolve, bodyClass) {
            $stateProvider.state(name, {
                url: url,
                views: {
                    'content@app': {
                        templateUrl: templateUrl,
                        controller: controllerAs
                    }
                },
                resolve: resolve,
                bodyClass: bodyClass
            });
            $stateProvider.state(name + "-popout", {
                url: url + "-popout",
                views: {
                    'main@': {
                        templateUrl: templateUrl,
                        controller: controllerAs
                    }
                },
                resolve: resolve,
                bodyClass: bodyClass
            });
        }

        this.$get = function () { // for example
            return {
                addState: function (name, url, templateUrl, controllerAs, resolve, bodyClass) {
                    $stateProvider.state(name, {
                        url: url,
                        views: {
                            'content@app': {
                                templateUrl: templateUrl,
                                controller: controllerAs
                            }
                        },
                        resolve : resolve,
                        bodyClass: bodyClass
                    });
                    $stateProvider.state(name + "-popout", {
                        url: url + "-popout",
                        views: {
                            'main@': {
                                templateUrl: templateUrl,
                                controller: controllerAs
                            }
                        },
                        resolve: resolve,
                        bodyClass: bodyClass
                    });
                }
            }
        }
    };
}());