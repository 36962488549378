import * as SIGNALADD from 'raw-loader!../../signal/view/signal-add.html'

(function () {
    "use strict";

    angular
        .module("app.spm.signals")
        .controller("signalListController", signalListController);

    function signalListController($state, $scope, agencyService, signalService, $mdDialog, searchBarService, dataExportService, breadcrumbNavigationService) {
        var vm = this;
        vm.signals = null;
        setupDataTable();
        vm.excelLoading = false;
        vm.goToSignals = goToSignals;
        vm.searchBarService = searchBarService;
        //set options for the search bar
        vm.searchBarConfig = {};
        vm.totalQueryCount = 0;
        vm.searchBarService.setSearchBarConfig(vm.searchBarConfig = {
            //header information
            header: {
                show: false,
            },
            //search bar options
            showSearchBar: false,
        });
        $state.defaultErrorHandler(function(error) {
            // This is a naive example of how to silence the default error handler.
            console.log(error);
            });
        vm.getAgencySettings = function () {
            var agencySettingsPromise = agencyService.getAgencySettings();
            agencySettingsPromise.then(function(data) {
                vm.agencySettings = data;
            });
        }

        vm.getSignals = function () {
            if(vm.signalDeleted){
                vm.selected = [];
                vm.queryData.filter = "";
            }
            vm.signalsPromise = signalService.querySignals(vm.queryData);
            vm.signalsPromise.then(function(data) {
                getSignalsSuccess(data);
            });
            vm.signalDeleted = false;
        }

        function getSignalsSuccess(data) {
            vm.totalCount = data.totalCount;
            vm.totalQueryCount = data.totalCountInQuery;
            vm.signals = data.signals;
            vm.lookups = data.lookups;
        }

        vm.goToAddSignal = function (ev) {
            var confirm = $mdDialog.show({
                locals: { lookups: vm.lookups, agencySettings: vm.agencySettings }, 
                controller: 'signalAddController as vm',
                template: SIGNALADD,
                parent: angular.element(document.body),
                targetEvent: ev,
            }).then(function () {
            });

        };

        vm.goToEditSignal = function (obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals.edit',
                { signal: obj, lookups: vm.lookups, agencySettings: vm.agencySettings });
        };

        vm.excelExportFromMenu = function (obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            var signalId = obj.signalID;
            vm.excelLoading = true;
            vm.queryData.id = signalId;
            vm.queryData.fileType = "xlsx";
            dataExportService.getSignalConfigExcelFile(vm.queryData).then(function (e) {
                vm.excelLoading = false;
            });
        };

        vm.excelExportAllSignals = function (obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            vm.excelLoading = true;
            vm.queryData.id = "";
            vm.queryData.fileType = "xlsx";
            dataExportService.getSignalConfigExcelFile(vm.queryData).then(function (e) {
                vm.excelLoading = false;
            });
        };

        vm.deleteSignal = function (obj) {
            if (!obj) {
                obj = vm.selected[0];
            }
            vm.signalDeleted = true;
            vm.signalsPromise = signalService.removeSignal(obj, vm.getSignals);
        }
        
        function goToSignals() {
            breadcrumbNavigationService.navigateToStateWithoutBreadCrumb('app.spm.config.signals');
        };

        var bookmark;
        vm.searchChange = function (newValue, oldValue) {
            if (!oldValue) {
                bookmark = vm.queryData.pageIndex;
            }

            if (newValue !== oldValue) {
                vm.queryData.pageIndex = 1;
            }

            if (!newValue) {
                vm.queryData.pageIndex = bookmark;
            }

            vm.getSignals();
        };

        vm.getAgencySettings();
        vm.getSignals();

        function setupDataTable() {
            vm.selected = [];
            vm.totalCount = {};
            vm.options = {
                rowSelection: true,
                multiSelect: false,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: false,
                limitSelect: true,
                pageSelect: true,
                filter: {
                    debounce: 500
                }
            };

            vm.queryData = {
                orderBy: 'customID',
                pageSize: 10,
                pageIndex: 1,
                filter: '',
                id: '',
                fileType: ''
            };
        }
    }
}());
