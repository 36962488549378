import * as USERSETTINGS from 'raw-loader!./user-settings.html'

(function () {
    'use strict';

    angular
        .module('app.spm.dashboards.user-settings',
            []
        )
        .config(config);

    /** @ngInject */
    function config(msApiProvider, $stateProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.user-settings', {
                url: '/user-settings',
                views: {
                    'content@app': {
                        template: USERSETTINGS,
                        controller: 'UserSettingsController as vm'
                    }
                }
            })
    }
})();