import * as LINKPIVOT from 'raw-loader!./view/purdue-link-pivot.html';

(function ()
{
    angular
            .module('app.spm.link-pivot', [])
            .config(config);

    /** @ngInject */
    function config($stateProvider,$translatePartialLoaderProvider) {
        $stateProvider
            .state('app.spm.purdue-link-pivot', {
                url: '/purdue-link-pivot',
                views: {
                    'content@app': {
                        template: LINKPIVOT,
                        controller: 'purdueLinkPivotController as vm'
                    }
                },
                params: {
                    metricTypeId: 1
                },
            });
            $translatePartialLoaderProvider.addPart('purdue-link-pivot');
    }
}());
