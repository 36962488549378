import * as MIR from 'raw-loader!./monthly-intersection-report.html'

(function ()
{
    'use strict';

    angular
        .module('app.spm.dashboards.monthly-intersection-report',
           []
        )
        .config(config);

    function config(msApiProvider, $stateProvider, $translatePartialLoaderProvider) {
        // State
        $stateProvider
            .state('app.spm.dashboards.monthly-intersection-report', {
                url: '/monthly-intersection-report',
                views: {
                    'content@app': {
                        template: MIR,
                        controller: 'MonthlyIntersectionReportController as vm'
                    }
                }
            });
        $translatePartialLoaderProvider.addPart('signal-performance');
    }
})();