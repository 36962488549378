﻿(function () {
    'use strict';

    angular
        .module('app.spm.signals')
        .controller('detectorAddController', detectorAddController);

    /** @ngInject */
    function detectorAddController($rootScope, $scope, $state, $mdDialog, signalService, lookups, approach, signal) {
        var vm = this;
        vm.detector = {};
        vm.approach = approach;
        vm.close = close;
        vm.signal = signal;
        vm.saveDetector = saveDetector;
        vm.lookups = lookups;
        vm.checkDetectionType = checkDetectionType;
        vm.isRequired = false;
        vm.isDistanceDisabled = false;
        vm.isDisabled = false;
        vm.messageMinSpeed = "";
        vm.messageDecisionPoint = "";
        vm.messageDistanceStopBar = "";
        vm.checkLaneDetType = checkLaneDetType;
        vm.laneMessage = "";
        vm.laneDet = false;
        vm.showWarning = showWarning;
        vm.showDetChError = "";

        vm.checkDetectionType(vm.detector.detectionTypeID);

        function close() {
            $mdDialog.cancel(false);
        };

        $scope.ok = function () {
            $mdDialog.hide({ message: 'Success' });
        };

        function checkLaneDetType(laneNumber, detectionTypeID){
            vm.checkDetector = null;
            vm.approach.detectors.forEach(function(det){
                if(det.laneNumber == laneNumber && det.detectionTypeID == detectionTypeID ){
                    vm.laneDet = true;
                    vm.checkDetector = det;
                }
            });
            if(!vm.laneDet) vm.saveDetector();
            if(vm.laneDet)  vm.showWarning();
        }

        function saveDetector() {
            vm.detector.approachID = vm.approach.approachID;

            signalService.createDetector(vm.detector)
                .then(function (respDet) {
                    vm.approach.detectors.push(respDet);
                    $scope.ok();
                })
                .catch(function (err){
                    if (vm){
                        vm.showDetChError = err.data;
                        vm.detector.detChannel = "";
                    }
                });        
        };

        function showWarning() {
            vm.laneMessage =  "Detector Channel " + vm.checkDetector.detChannel + " has the same detection type (" + vm.checkDetector.description +")"
            + " and is already assigned to this lane number " + vm.checkDetector.laneNumber
            + "\n" + ". Do you still want to save this detector? ";

            vm.laneDet = false;

            var confirm = $mdDialog.confirm()
            .multiple(true)
            .title("Confirm")
            .textContent(vm.laneMessage)
            .ok('Yes')
            .cancel('No');

            $mdDialog.show(confirm)
                .then(function () {
                    saveDetector();
                }, function () { }
            );
        }

        function checkDetectionType(detectionType) {
            switch (detectionType) {
                case 2: //Advanced Count
                    vm.isRequired = true;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = false;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set Min Speed Filter Field";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set Decision Point";
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    break;
                case 6: // Set Bar Presence
                    vm.isRequired = false;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = true;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set  Min Speed Filter";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set  Decision Point";
                    vm.messageDistanceStopBar = "By selecting this Detection Type you are not able to set  Distance from Stop Bar"
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    vm.detector.distanceFromStopBar = "";
                    break;
                case 4: // Lane-by-lane Count
                    vm.isRequired = false;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = true;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set  Min Speed Filter";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set  Decision Point";
                    vm.messageDistanceStopBar = "By selecting this Detection Type you are not able to set  Distance from Stop Bar"
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    vm.detector.distanceFromStopBar ="";
                    break;
                default:
                    vm.isRequired = false;
                    vm.isDisabled = true;
                    vm.isDistanceDisabled = true;
                    vm.messageMinSpeed = "By selecting this Detection Type you are not able to set  Min Speed Filter";
                    vm.messageDecisionPoint = "By selecting this Detection Type you are not able to set  Decision Point";
                    vm.messageDistanceStopBar = "By selecting this Detection Type you are not able to set  Distance from Stop Bar"
                    vm.detector.minSpeedFilter = "";
                    vm.detector.decisionPoint = "";
                    vm.detector.distanceFromStopBar ="";
                //code block
            }
        }
    }

})();
