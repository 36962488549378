(function () {

    'use strict';

    angular
        .module('app.spm.charts')
        .service('chartsService', chartsService);

    function chartsService($state, $timeout, $rootScope, chartsResource, msNavigationService, searchBarService) {
        var vm = this;

        vm.options = {
            chartComplexity: 'Simple',
            pcdOptions: {
                carsInPlatoon: 3,
                headwayInSec: 3.5,
                showCarsInQueue: false,
                highlight: 'platoon'
            },
        };

        vm.ynrOptions = {
            yellowRedOptions: {
                severeLevelSeconds: 4,
                forceDetectionTypeId: -1,
                speedFilter: 15
            },
        }

        function getChartComplexity() {
            return vm.options.chartComplexity;
        }

        function setChartComplexity(option) {
            vm.options.chartComplexity = option;
            notifyOptionsChange(option);
        }

        function setPcdOptions(options) {
            vm.options.pcdOptions = options;
            notifyOptionsChange(vm.options.pcdOptions);
        }
        function getPcdOptions() {
            return vm.options.pcdOptions;
        }

        function setYellowRedOptions(options) {
            vm.options.yellowRedOptions = options;
            notifyOptionsChange(vm.ynrOptions.yellowRedOptions)
        }
        function getYellowRedOptions() {
            return vm.ynrOptions.yellowRedOptions
        }

        function notifyOptionsChange(options) {
            $rootScope.$emit('chart-options-change-event', options);
        }

        searchBarService.subscribe($rootScope, function onChange(ev, changeType) {
            if (changeType == "signal") {
                updateChartMenus();
            }
            if (changeType == "state") {
                $timeout(function () {
                    if (searchBarService.isFiltering() == false) {
                        updateChartMenus();
                    }
                }, 500);
            }

        });

        var chartDefinitions = {
            genericMultiChart: {
                config: {
                    refreshDataOnly: true,
                    deepWatchData: true,
                    deepWatchDataDepth: 0,
                },
                options: {
                    chart: {
                        type: 'multiChart',
                        height: 400,
                        // color: d3.scale.category10().range(),
                        bars1: {
                            forceY: [0],
                            forceX: [0]
                        },
                        scatters1: {
                            showDistX: true,
                            showDistY: true,
                            onlyCircles: false,
                            forceY: [0]
                        },
                        tooltipContent: function (key) {
                            return '<h3>' + key + '</h3>';
                        },

                        transitionDuration: 350,
                        yAxis: {
                            axisLabel: 'Phase Duration (seconds)',
                        },
                        xAxis: {
                            axisLabel: 'Day and Hour',
                            tickFormat: function (d) {
                                return d3.time.format('%d %b %H:%M')(new Date(d));
                            },
                            showMaxMin: true,
                            //ticks: 10
                        },
                        enablePlanAxis: true,

                        //zoom: {
                        //    enabled: true,
                        //    scaleExtent: [1, 1000],
                        //    useFixedDomain: false,
                        //    horizontalOff: false,
                        //    useNiceScale: true,
                        //    verticalOff: true,
                        //    unzoomEventType: 'dblclick.zoom'
                        //},
                    },
                },
            },
            genericLineBarFocusChart: {
                config: {
                    refreshDataOnly: true,
                    deepWatchData: false,
                    deepWatchDataDepth: 0,
                },
                options: {
                    chart: {
                        type: 'linePlusBarChart',
                        height: 400,
                        timelineHideScatters1: true,
                        timelineGreyBackground: true,
                        focusEnable: true,
                        showControls: false,
                        groupSpacing: 0.5,
                        stackBars: true,
                        clipEdge: true,
                        showPlanAxis: true,
                        enablePlanAxis: true,
                        planLabelCallback: null,
                        forceY1min: 0,
                        forceY2min: 0,

                        margin: {
                            top: 30,
                            right: 75,
                            bottom: 50,
                            left: 75
                        },
                        color: ['#2ca02c', 'darkred'],
                        y1Axis: {
                            axisLabel: 'Delay (in seconds)',
                            //tickFormat: function(d){
                            //    return d3.format(',f')(d);
                            //},
                        },
                        interactiveLayer: {
                            tooltip: {
                                gravity: 's',
                                classes: 'gravity-s'
                            }
                        },
                    }
                }

            }
        }

        var colors = {
            signalRed: '#FF1744', //'#FF5468',
            signalGreen: '#00E676',
            signalYellow: '#ffd117',
            dark: '#000000',
            gray: '#525252',
            blue: '#3182bd',
            forceOff: '#ff5131',
            maxOut: '#D50000',
            gapOut: '#00C853',
            unknown: '#000000',
            purplePedestrian: '#4a1486',
            redPreemption: '#c51162',
            yellowCheckIn: '#ffd600',
            greenExtend: '#009624',
            greenEarly: '#5efc82'
        }


        function createOptionsObject(data, searchDates, signalId) {
            data.endDate = new Date(searchDates.endDateAndTime).toLocaleString();
            data.startDate = new Date(searchDates.startDateAndTime).toLocaleString();
            data.signalID = signalId;
            delete data['$promise'];
            delete data['$resolved'];
            return data;
        }

        function formatTooltipDate(input) {
            var options = {
                weekday: "short", month: "short",
                day: "numeric", hour: "2-digit", minute: "2-digit", second: "numeric",
            };
            var inputDate = new Date(input);
            var inputMS = inputDate.getMilliseconds();
            if (inputMS != 0) {
                inputMS = inputMS / 100;
            }
            var time = inputDate.toLocaleTimeString("en-us", options);
            var amPM = time.substr(time.length - 2, time.length);
            time = time.substr(0, time.length - 3);
            return time = time + "." + inputMS + " " + amPM;
        }

        function getTerminationFromEvent(event) {
            switch (event) {
                case 4:
                    return "Gap Out";
                case 5:
                    return "Max Out";
                case 6:
                    return "Force Off";
                case 0:
                default:
                    return "Unknown";
            }
        }

        function getDurationSeconds(duration) {
            var a = duration.split(':'); // split it at the colons
            return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        }

        function getChartData(spmChartOptions, respArray) {
            //calls api endpoint via the resource obj
            if (respArray) {
                return chartsResource.getChartOptionsRawArray(spmChartOptions, function (data) {
                    return data;
                }).$promise;
            }
            else {
                return chartsResource.getChartOptionsRaw(spmChartOptions, function (data) {
                    return data;
                }).$promise;
            }
        }

        function getSupportedChartOptions(signalId) {
            //calls api endpoint via the resource obj
            return chartsResource.getSupportedChartOptions({ id: signalId }, function (data) {
                return data;
            }).$promise;
        }

        function getSupportedChartOptionsById(signalId, metricId) {
            //calls api endpoint via the resource obj
            return chartsResource.getSupportedChartOptionsById({ signalId: signalId, metricId: metricId }, function (data) {
                return data;
            }).$promise;
        }

        function getChartOptions(metricType) {
            //calls api endpoint via the resource obj
            return chartsResource.getMetricOptions({ id: metricType }, function (data) {
                return data;
            }).$promise;
        }

        function getChartOptionsForSignal(signalId, metricType) {
            //calls api endpoint via the resource obj
            return chartsResource.getChartOptionsForSignal({ signalId: signalId, metricId: metricType }, function (data) {
                return data;
            }).$promise;
        }


        function getMetricList(signalId) {
            //calls api endpoint via the resource obj
            return chartsResource.getMetrics({ id: signalId }, function (data) {
                return data;
            }).$promise;
        }

        function getRedGreenAllocationData(signalId, searchDates) {
            //calls api endpoint via the resource obj
            return chartsResource.getRedGreenAllocationData({ id: signalId, start: searchDates.startDateAndTime, end: searchDates.endDateAndTime }, function (data) {
                return data;
            }).$promise;
        }

        function updateChartMenus() {
            var signalId = "";

            var searchObj = searchBarService.getSearchOptions();
            if (searchObj && searchObj.signal)
                signalId = searchObj.signal.signalID;

            if (signalId && signalId != "") {
                getSupportedChartOptions(signalId)
                    .then(function (data) {
                        //find all the ones that should be hidden
                        if (data && data.charts) {
                            var chartNames = data.charts.map(function (a) {
                                if (a.isSupportedForSignal)
                                    return a.chartName
                            });
                            msNavigationService.updateMenuAvailability('spm.signal.charts', chartNames);
                            msNavigationService.updateMenuAvailability('spm.detection', chartNames);

                        }
                    });
            }
            else {
                //no signal so clear checkboxes
                msNavigationService.updateMenuAvailability('spm.signal.charts', []);
                msNavigationService.updateMenuAvailability('spm.detection', []);
            }
        }

        var chartColors = [
            "#a6cee3",
            "#1f78b4",
            "#b2df8a",
            "#33a02c",
            "#fb9a99",
            "#e31a1c",
            "#fdbf6f",
            "#ff7f00",
            "#cab2d6",
            "#6a3d9a",
            "#ffff99",
            "#b15928",
            "#007fa8",
            "#ff4e20",
            "#006df5",
            "#13d100",
            "#a513e2",
            "#00ce63",
            "#7800b0",
            "#5ca700",
            "#c856f2",
            "#a1eb6c",
            "#bd6fff",
            "#007b1d",
            "#ff87f1",
            "#00f4bd",
            "#e90000",
            "#00ffff",
            "#e90022",
            "#35e1ff",
            "#ff4f00",
            "#00bfff",
            "#a70000",
            "#00ceff",
            "#9a0000",
            "#00baf9",
            "#98001a",
            "#549fff",
            "#788f0a",
            "#ad0b00",
            "#00fc0e",
            "#8700ed",
            "#00f200",
            "#8239ff",
            "#94ff04",
            "#2e45ff",
            "#4caf00",
            "#003cce",
            "#00da64",
            "#af16b2",
            "#deff6f",
            "#001f88",
            "#00ffcc",
            "#d00053",
            "#00fff7",
            "#e2352a",
            "#00ffff",
            "#ff4663",
            "#00ffff",
            "#790000",
            "#00f2ff",
            "#760000",
            "#00b35e",
            "#00228b",
            "#1b861b",
            "#ff94ff",
            "#00762f",
            "#0056c4",
            "#c49300",
            "#008ffb",
            "#e17400",
            "#009aff",
            "#dbc45a",
            "#001d6e",
            "#66cd99",
            "#530058",
            "#8cb269",
            "#4b418f",
            "#ffd299",
            "#00265f",
            "#ff5d69",
            "#00b9ff",
            "#720000",
            "#00b8ff",
            "#650000",
            "#9ce7ff",
            "#630000",
            "#adedf2",
            "#4a0000",
            "#f1f7ff",
            "#001700",
            "#ffb6ff",
            "#003600",
            "#ebc9ff",
            "#274500",
            "#7154a8",
            "#00642a",
            "#7b0036",
            "#00643f",
            "#f4857b",
            "#003e86",
            "#e8b78f",
            "#004e87",
            "#963c2c",
            "#009ed2",
            "#5a0022",
            "#eee9ff",
            "#2b0f02",
            "#60b3c3",
            "#281700",
            "#007abe",
            "#3f2a00",
            "#ab83bd",
            "#005342",
            "#823249",
            "#007c6d",
            "#c49d7e",
            "#006c88",
            "#9f967f"
        ]
        return {
            //creates return obj
            getChartComplexity: getChartComplexity,
            setChartComplexity: setChartComplexity,
            getTerminationFromEvent: getTerminationFromEvent,
            getDurationSeconds: getDurationSeconds,
            chartDefinitions: chartDefinitions,
            createOptionsObject: createOptionsObject,
            getChartData: getChartData,
            getChartOptions: getChartOptions,
            getRedGreenAllocationData: getRedGreenAllocationData,
            getMetricList: getMetricList,
            getChartOptionsForSignal: getChartOptionsForSignal,
            getSupportedChartOptions: getSupportedChartOptions,
            getSupportedChartOptionsById: getSupportedChartOptionsById,
            colors: colors,
            chartColors: chartColors,
            updateChartMenus: updateChartMenus,
            formatTooltipDate: formatTooltipDate,
            setPcdOptions: setPcdOptions,
            getPcdOptions: getPcdOptions,
            setYellowRedOptions: setYellowRedOptions,
            getYellowRedOptions: getYellowRedOptions,
            subscribe: function (scope, callback) {
                var handler = $rootScope.$on('chart-options-change-event', callback);
                scope.$on('$destroy', handler);
            },
        }
    }
})();
